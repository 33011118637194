<!--<h1 mat-dialog-title>Hi {{data.name}}</h1>-->
<div *ngIf="showCancelBookingPopup" mat-dialog-content>
  <p>Do you really want to cancel booking?</p>
</div>
<div *ngIf="!showCancelBookingPopup && !showUpdateProfilePopup" mat-dialog-content>
  <p>Do you really want to conclude this visit ?</p>
</div>
<div *ngIf="showCancelBookingPopup" mat-dialog-actions>
  <!--<button mat-button (click)="onNoClick()">No </button>
  <button mat-button (click)="onYesClick()">Yes </button>-->
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial>No</button>
  <button mat-button [mat-dialog-close]="true">Yes</button>
</div>
<div *ngIf="!showCancelBookingPopup && !showUpdateProfilePopup" mat-dialog-actions>
  <!--<button mat-button (click)="onNoClick()">No </button>
  <button mat-button (click)="onYesClick()">Yes </button>-->

  <button mat-button [mat-dialog-close]="true"  cdkFocusInitial ><b style="color:forestgreen" class="tab-content ">Save and End Call</b></button>
  <button mat-button [mat-dialog-close]="false"  ><b style="color:red">Cancel Call</b></button>
</div>

<div *ngIf="showUpdateProfilePopup" mat-dialog-content>
  <p>Do you really want to update your profile ?</p>
</div>
<div *ngIf="showUpdateProfilePopup" mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial>No</button>
  <button mat-button [mat-dialog-close]="true">Yes</button>
</div>

