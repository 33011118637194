import { Component } from '@angular/core'
import { Global } from '../../app.global';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { NotificationService } from '../../services/notification.service';
import { PatientService } from '../../services/patient/patient.service';
import { Visit } from '../../models/visit/visit.model';

@Component({
  templateUrl: "./app.paythroughcreditcard.html"
})
export class PayThroughCreditCardComponent {
  VisitId: any;
  public visitInfo: Visit = new Visit();
  constructor(public routing: Router, private route: ActivatedRoute, private alertService: AlertService,
    private notifyService: NotificationService, public patService: PatientService) {
    this.VisitId = this.route.snapshot.queryParamMap.get('id');
  }
  paidstatus() {
    this.visitInfo.VisitId = this.VisitId;
    this.patService.updatePaidStatus(this.VisitId).subscribe(data => {
    },
      error => {
      });
  }
  Submit() {
    this.paidstatus();
    console.log("I am on waiting list");
    this.notifyService.showInfo("Please, wait for your turn doctor in waiting room", "Payment success !!");
    this.routing.navigate(['/WaitingRoom'], { queryParams: { id: this.VisitId } });
  }
}
