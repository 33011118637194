import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IdToken } from '../models/IdToken';
import { JwtHelper } from '../models/JwtHelper';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DecodeTokenService {

  constructor(private authService: AuthenticationService, private router: Router) { }

  decodeToken(token) {
    sessionStorage.removeItem('jwtToken');
    sessionStorage.setItem('jwtToken', token);

    let jwtHelper = new JwtHelper();
    let decodedIdToken = <IdToken>jwtHelper.decodeToken(token);
    //const decoded = jwt_decode(token);
    let jwtToken = decodedIdToken;
    let userType = decodedIdToken.UserType;
    this.authService.setUserType(userType);
    if (userType == "Patient") {
      localStorage.removeItem('loginPat');
      localStorage.setItem('loginPat', "patient");
      this.router.navigate(['/PatDashboard']);
      /*   this.notifyService.showSuccess("Welcome", "login successful");*/

    }
    else if (userType == "Doctor") {
      localStorage.removeItem('loginDoc');
      localStorage.setItem('loginDoc', "doctor");
      this.router.navigate(['/DocDashboard']);
      /* this.notifyService.showSuccess("Welcome", "login successful");*/

    }
    else if (userType == "Admin") {
      localStorage.clear();
      this.router.navigate(['/admin']);
      /*  this.notifyService.showInfo("Info", "Admin Page");*/
      /*   return;*/
    }
    else {
      this.router.navigate(['']);
      return;
    }
  }
}

