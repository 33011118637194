<style>
  .notification-wrapper {
    right: -85px !important;
  }
</style>

<app></app>
<div class="bg-back">
  <button (click)="BackDashBoard()"
          class="back-btn-doc-room">Back To DashBoard </button>

</div>
<div class="patient-highlight mb-2">
  <div class="navbar title">
    <h5>
      <strong><i class="fa fa-comments"
           aria-hidden="true"></i>Send Message to Patient</strong>
    </h5>
  </div>

  <form [formGroup]="ChatForm"
        class="chat-messages chat-admin-messages">
    <div class="admin-chat-container">
      <div class="all-chats">
        <ul>
          <li class="patient-tile"
              *ngFor="let user of ChatUsers"
              [ngClass]="{'active':selectedPatientId == user.PatientId}"
              (click)="onChatUserClicked(user.PatientId)">
            <div>
              {{user.Name}}
              <span *ngIf="user.UnseenMessageCount"
                    class="unseen-msg-count">{{user.UnseenMessageCount}}
              </span>
            </div>

          </li>
        </ul>
      </div>
      <div class="patient-message-container"
           *ngIf="selectedPatientId">
        <div class="alert alert-default p-1"
             style="background: #f9f9f9; border: 1px solid #f9f9f9">
          <p class="text-center">
            <i class="fa fa-info"
               aria-hidden="true">
            </i>
            Start your conversiation
          </p>
        </div>
        <div class="message-box-holder"
             *ngFor="let chat of allChatMessages">
          <div *ngIf="chat.Name !='Me' && chat.PatientId == selectedPatientId"
               class="message-by-doc">
            <span>{{ chat.Message }}</span>
          </div>
          <div *ngIf="chat.Name =='Me' && chat.ReceiverId == selectedPatientId"
               class="message-by-me">
            <span>{{ chat.Message }}</span>
          </div>
          <div class="chat-input-holder message-box">
            <textarea id="chat-input"
                      class="chat-input"
                      placeholder="Type your message here.."
                      formControlName="chatMessage"
                      (keyup)="SendChatMsg($event)">
            </textarea>
            <div *ngIf="hasError('required','chatMessage')"
                 style="color: red; font-size: 12px; margin-bottom: 7px">
              <i class="fa fa-info-circle"
                 aria-hidden="true"></i>Chat message is required
            </div>
            <button type="button"
                    value="Send"
                    class="message-send btn-primary"
                    (click)="SendChatMsg()">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<footer></footer>
