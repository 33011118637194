<app></app>

<section class="nav-buttons d-flex">
  <button (click)="routeToAdminPage()" class="back-btn-doc-room">Back To Admin </button>
  <button (click)="routeToAppointmentListPage()" class="btn btn-primary btn-back-to-appointment-list">Back To Appointment List </button>
</section>

<section class="grid-section">
  <h4 class="title">
    <i class="fa fa-phone"></i>Appointment Over Phone
  </h4>

  <div class="col-md-12 alert alert-default">
    <div class="row d-flex align-items-center">
      <label class="control-label col-md-2">From Date:</label>
      <div class="col-md-3">
        <input type="date" name="date" [(ngModel)]="searchFilter.fromDate" required class="m-0" />
      </div>
      <label class="control-label col-md-2"> To Date:</label>
      <div class="col-md-3">
        <input type="date" name="date" [(ngModel)]="searchFilter.toDate" required class="m-0" />
      </div>
      <button (click)="getAppointmentsBookedOverPhone()">Search</button>
    </div>
  </div>
  <div style="overflow: scroll;">
    <table aria-describedby="appointmentTable" class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">Contact</th>
          <th scope="col">Patient Name</th>
          <th scope="col">Requested By</th>
          <th scope="col">Appointment Requested On</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of appointmentsOverPhone |paginate: { itemsPerPage: 10, currentPage: currentPage  } ; let i=index;">
          <td>{{i+1}}</td>
          <td>{{data.ContactNumber}}</td>
          <td>{{data.Name}}</td>
          <td>{{data.RequestedBy}}</td>
          <td>{{data.CreatedDate | date : 'yyyy-MM-dd hh:mm aa '}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <pagination-controls style="font-size:20px" (pageChange)="currentPage=$event"></pagination-controls>
</section>
