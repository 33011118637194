export class Hospital {
  public HospitalId: string;
  public HospitalName: string;
  public HospitalCode: string;
  public HospitalImage: string;
  public HospitalLogo: string;
  public ImagePath: string;
  public Location: string;
  public IsActive: boolean;
  public PaymentEnable: boolean;
  public AdminId: string = "";
}

export class HospitalEdit {
  public HospitalId: string;
  public HospitalName: string;
  public HospitalCode: string;
  public HospitalImage: string;
  public HospitalLogo: string;
  public ImagePath: string;
  public Location: string;
  public IsActive: boolean;
  public PaymentEnable: boolean;
  public AdminId: string = "";
}

