import { HospitalDoctorMap } from '../HospitalDoctorMap/app.hospitaldoctormap.model';
import { Qualification } from '../Qualification/qualification.model';

export class Doctor {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(DoctorId?: string, UserName?: string, Password?: string, NMC?: string, LongSignature?: string, OldPassword?: string, ConfirmPassword?: string, FirstName?: string, MiddleName?: string, LastName?: string, DoctorName?: string, Department?: string,
    DateOfBirth?: string, FilePath?: string, Gender?: string, PhoneNumber?: string, MailingAddress?: string, HospitalId?: string, IsActive?: boolean, IdentityUserId?: string,
    HospitalDoctorMap?: string, HospitalDepartmentMap?: Array<HospitalDoctorMap>, Qualification?: string, DoctorQualification?: Array<Qualification>, EnablePhNo?: boolean, DoctorRoomName?: string, AdminId?: string, HospitalIdentifier?: string, MeetingLink?: string, FileName?: string) {
    this.DoctorId = DoctorId;
    this.UserName = UserName;
    this.Password = Password;
    this.FirstName = FirstName;
    this.MiddleName = MiddleName;
    this.LastName = LastName;
    this.DoctorName = DoctorName;
    this.NMC = NMC;
    this.LongSignature = LongSignature;
    this.DateOfBirth = DateOfBirth;
    this.FilePath = FilePath;
    this.MailingAddress = MailingAddress;
    this.Gender = Gender;
    this.PhoneNumber = PhoneNumber;
    this.EnablePhNo = EnablePhNo;
    this.Department = Department;
    this.HospitalId = HospitalId;
    this.IsActive = IsActive;
    this.IdentityUserId = IdentityUserId;
    this.OldPassword = OldPassword
    this.ConfirmPassword = ConfirmPassword;
    this.HospitalDoctorMap = HospitalDoctorMap;
    this.HospitalDepartmentMap = HospitalDepartmentMap;
    this.Qualification = Qualification;
    this.DoctorQualification = DoctorQualification;
    this.DoctorRoomName = DoctorRoomName;
    this.AdminId = AdminId;
    this.HospitalIdentifier = HospitalIdentifier;
    this.MeetingLink = MeetingLink;
    this.FileName = FileName;
  }


  public DoctorId: string;
  public UserName: string;
  public Password: string;
  public DoctorName:string;
  public FirstName: string;
  public MiddleName: string;
  public LastName: string;
  public NMC: string;
  public LongSignature: string;
  public DateOfBirth: string;
  public FilePath: string;
  public MailingAddress: string;
  public Gender: string;
  public PhoneNumber: string;
  public EnablePhNo: boolean;
  public Department: string;
  public HospitalId: string;
  public IsActive: boolean;
  public IdentityUserId: string;
  public OldPassword: string;
  public ConfirmPassword: string;
  public DepartmentId: string;
  public HospitalDoctorMap: string;
  public HospitalDepartmentMap:  Array<HospitalDoctorMap>=[];
  public Qualification : string;
  public DoctorQualification: Array<Qualification> = [];
  public DoctorRoomName: string;
  public AdminId: string;
  public HospitalIdentifier: string;
  public MeetingLink: string;
  public FileName: string;
}

export class DoctorList {
  public Designation: string;
  public DoctorName: string;
  public Education: string;
  public Experience: string;
  public FileName: string;
  public FilePath: string;
  public HospitalDoctorMapId: string;
  public Membership: string;
  public PastAffiliation: string;
  public ScheduleList: Array<any> = new Array<any>();
}
