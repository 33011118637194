import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { PatientEndpoint } from './patient-endpoint.service';
import { PatientModel, Patient, PatientDoc, Paidbooking } from '../../models/patient/patient.model';
import { Visit } from '../../models/visit/visit.model';
import * as moment from 'moment';
import { ScheduleInterval } from '../../models/scheduling/scheduling.model';
import { PatientFamily } from 'src/app/models/patient/Pat-family.model';
import { PatientMedicalRecord } from '../../models/patient/Pat-medicalRecord.model';
import { TelemedicineResponse } from 'src/app/models/Shared/TelemedicineResponse.model';
@Injectable()
export class PatientService {
  constructor(private router: Router, private http: HttpClient, private patientEndPoint: PatientEndpoint) {
  }

  public _VisitId: string;
  public _VisitType: string;
  get VisitType(): string {
    return this._VisitType;
  }
  set VisitType(Visittype: string) {
    this._VisitType = Visittype;
  }
  get VisitId(): string {
    return this._VisitId;
  }
  set VisitId(VisitId: string) {
    this._VisitId = VisitId;
  }

  public _PatientId: string;
  get PatientId(): string {
    return this._PatientId;
  }
  set PatientId(PatientId: string) {
    this._PatientId = PatientId;
  }
  public _PatientByVisit: boolean;
  get PatientByVisit(): boolean {
    return this._PatientByVisit;
  }
  set PatientByVisit(PatientByVisit: boolean) {
    this._PatientByVisit = PatientByVisit;
  }

  getPatient(PatientId: string,userType: string) {
    return this.patientEndPoint.getPatientByIdEndpoint<Patient>(PatientId,userType);
  }

  getFinalReportData(VisitId?: string) {
    return this.patientEndPoint.getFinalReportDataEndpoint<Visit>(VisitId);
  }
  getPatientDocuments(VisitId?: string) {
    return this.patientEndPoint.getPatientDocumentsEndpoint<TelemedicineResponse<Visit[]>>(VisitId);
  }
  getPatientByVisitId(VisitId?: string) {
    return this.patientEndPoint.getPatientByVisitIdEndpoint<Visit>(VisitId);
  }
  getPatientList(DoctorId?: string) {
    return this.patientEndPoint.getPatientListEndpoint<Patient[]>(DoctorId);
  }
  getPatientHistory(PatientId?: string) {
    return this.patientEndPoint.getPatientHistoryEndpoint<TelemedicineResponse<Patient[]>>(PatientId);
  }
  getPatientBookingList(PatientId?: string) {
    return this.patientEndPoint.getPatientBookingListEndpoint<TelemedicineResponse<Paidbooking[]>>(PatientId);
  }

  changeStatus(VisitId?: string) {
    return this.patientEndPoint.changeStatusEndpoint<Paidbooking[]>(VisitId);
  }
  changeStatusFromDoc(VisitId?: string) {
    return this.patientEndPoint.changeStatusFromDocEndpoint<Paidbooking[]>(VisitId);
  }
  getPatientDocList(DoctorId?: string) {
    return this.patientEndPoint.getPatientDocListEndpoint<PatientDoc[]>(DoctorId);
  }
  getStatus(VisitId?: string) {
    return this.patientEndPoint.getStatusEndpoint<Visit>(VisitId);
  }
  getStatusByPatientId(VisitId?: string) {
    return this.patientEndPoint.getStatusByPatientIdEndpoint<Visit>(VisitId);
  }
  getStatusByPatientUrl(PatientId?: string) {
    return this.patientEndPoint.getStatusByPatientUrlEndpoint<Visit>(PatientId);
  }
  getStatusByVisitId(VisitId?: string) {
    return this.patientEndPoint.getStatusByVisitIdEndpoint<Visit>(VisitId);
  }
  getPatHistory(PatientId?: string) {
    return this.patientEndPoint.getPatHistoryEndpoint<TelemedicineResponse<Patient[]>>(PatientId);
  }
  getAllPatient() {
    return this.patientEndPoint.getAllPatientEndPoint<Patient>();
  }
  getAdminId() {
    return this.patientEndPoint.getAdminIdEndpoint();
  }
  //for save jitsi room name in db
  PostJitsiRoomName(VisitId, Roomname) {
    return this.patientEndPoint.PostJitsiRoomNameEndPoint<Visit>(VisitId, Roomname)
  }


  //postVisit(filesToUpload, visit: Visit) {
  //  try {
  //    debugger;
  //    let formToPost = new FormData();
  //    var temp = _.omit(visit, ['PatientValidator']);
  //    var temp1 = _.omit(visit.PatientFiles, ['PatientFilesValidator']);
  //     temp.PatientFiles = temp1;
  //    // temp1 = filesToUpload;
  //  var visitDetails = JSON.stringify(temp1)
  //  let uploadedImgCount = 0;
  //  //ImageName can contain names of more than one image seperated by ;
  //  //if (patReport.ImageName)
  //  //    uploadedImgCount = patReport.ImageName.split(";").length;
  //  for (var i = 0; i < filesToUpload.length; i++) {
  //    //to get the imagetype
  //    let splitImagetype = filesToUpload[i].name.split(".");
  //    let imageExtension = splitImagetype[1];
  //    ////fileName = PatientId_ImagingItemName_PatientVisitId_CurrentDateTime_Counter.imageExtension
  //    //visit.PatientFiles.FileName = visit.PatientFiles.FileType + "_" + moment().format('DD-MM-YY') + "." + imageExtension;
  //    temp.PatientFiles.FileType = filesToUpload[i].type;
  //    temp.PatientFiles.FileName = filesToUpload[i].name;
  //    temp.PatientFiles.FileExtention = imageExtension;
  //    //formToPost.append("uploads", filesToUpload[i], fileName);
  //    }
  //    formToPost.append("visitDetails", visitDetails);
  //    return this.patientEndPoint.postVisitEndpoint<Visit>(temp, formToPost);
  //  } catch (exception) {
  //    throw exception;
  //  }
  //  }
  postVisit(visit: Visit, ScheduleIntervalId, SchedulingId) {
    var visit = _.omit(visit, ['PatientValidator']);
    return this.patientEndPoint.postVisitEndpoint<Visit>(visit, ScheduleIntervalId, SchedulingId);
  }
  updatePatient(patient: Patient) {
    if (patient.IdentityUserId) {
      return this.patientEndPoint.getUpdatePatientEndpoint(patient, patient.IdentityUserId);
    }
    else {
      return this.patientEndPoint.getUpdatePatientEndpoint<Patient>(patient.IdentityUserId).pipe(
        mergeMap(patientUser => {
          patient.IdentityUserId = patientUser.IdentityUserId;
          return this.patientEndPoint.getUpdatePatientEndpoint(patient, patient.IdentityUserId)
        }));
    }
  }
  updateTreatmentAdvice(visit: Visit, VisitId) {
    var visit = _.omit(visit, ['PatientValidator', 'PatientFiles.PatientFilesValidator']);
    return this.patientEndPoint.updateTreatmentAdviceEndpoint(visit, VisitId);
  }


  updateStatus(visit: Visit, VisitId) {
    return this.patientEndPoint.updateStatusEndpoint(visit, visit.VisitId);
  }
  updateVisitStatus(visit: Visit, VisitId) {
    return this.patientEndPoint.updateVisitStatusEndpoint(visit, visit.VisitId);
  }
  updatePaidStatus(VisitId) {
    return this.patientEndPoint.updatePaidStatusEndpoint(VisitId);
  }
  updatePaymentStatus(visit: Visit, VisitId) {
    var visit = _.omit(visit, ['PatientValidator', 'PatientFiles.PatientFilesValidator']);
    return this.patientEndPoint.updatePaymentStatusEndpoint(visit, VisitId);
  }
  cancelBooking(visit: Visit, VisitId) {
    var visit = _.omit(visit, ['PatientValidator', 'PatientFiles.PatientFilesValidator']);
    return this.patientEndPoint.cancelBookingEndpoint(visit, VisitId);
  }

  getPaymentCharge(VisitId?: string) {
    return this.patientEndPoint.getPaymentChargeEndpoint<string>(VisitId);
  }

  postVisitToSingleton(VisitId?: string) {
    return this.patientEndPoint.postVisitSingletonEndpoint<Visit>(VisitId);
  }
  getVisitFromWaitingRoom(VisitId?: string) {
    return this.patientEndPoint.getVisitFromWaitingRoomEndpoint<Visit>(VisitId);
  }
  getVisitFromDoctorRoom(VisitId?: string) {
    return this.patientEndPoint.getVisitFromDoctorRoomEndpoint<Visit>(VisitId);
  }
  getPatientsInDoctorRoom(DoctorId?: string) {
    return this.patientEndPoint.getPatientsInDoctorRoomEndpoint<PatientDoc[]>(DoctorId);
  }
  removeVisitIdFromLogout(VisitId?: string) {
    return this.patientEndPoint.removeVisitIdFromLogoutEndpoint<Visit>(VisitId);
  }

  backToDashboardFromDoc(DoctorId?: string) {
    return this.patientEndPoint.backToDashBoardFromDocEndpoint<Paidbooking[]>(DoctorId);
  }

  addFamilyList(familyList: PatientFamily) {
    return this.patientEndPoint.addFamilyListEndpoint<PatientFamily>(familyList);
  }

  getFamilyList(PatientId?: string) {
    return this.patientEndPoint.getFamilyListEndpoint<PatientFamily[]>(PatientId);
  }

  updateFamilyList(familyList: PatientFamily) {
    return this.patientEndPoint.updateFamilyListEndpoint(familyList);
  }

  deleteFamilyList(FamilyMemberId: string) {
    return this.patientEndPoint.deleteFamilyListEndpoint<PatientFamily>(FamilyMemberId);
  }

  addPatMedicalRecord(patMedicalRecord: PatientMedicalRecord) {
    return this.patientEndPoint.addPatMedicalRecordEndpoint<PatientMedicalRecord>(patMedicalRecord);
  }

  getPatMedicalRecords(PatientId?: string) {
    return this.patientEndPoint.getPatMedicalRecordsEndpoint<PatientMedicalRecord[]>(PatientId);
  }

  getOPSummary(patid, vid) {
    return this.patientEndPoint.getOPSummaryEndpoint<TelemedicineResponse<Patient[]>>(patid, vid);

  }

  followUpPostVisit(visit: Visit, ScheduleIntervalId, SchedulingId) {
    var visit = _.omit(visit, ['PatientValidator']);
    return this.patientEndPoint.followUpPostVisitEndpoint<Visit>(visit, ScheduleIntervalId, SchedulingId);
  }


  addOPSummary(OPdata: Visit, VisitId) {
    var OPdata = _.omit(OPdata, ['PatientValidator']);
    return this.patientEndPoint.addOPSummaryEndpoint<Visit>(OPdata, VisitId);
  }

  deletePatMedicalRecord(MedicalRecordId: string) {
    return this.patientEndPoint.deletePatMedicalRecordEndpoint<PatientMedicalRecord>(MedicalRecordId);
  }
  getAllMissedVisit() {
    return this.patientEndPoint.getMissedVisitEndpoint();
  }

  getZoomSignature(doctorId: string) {
    return this.patientEndPoint.getZoomSignatureEndPoint(doctorId);
  }
}
