      <div class="card">
        <div class="patient-login" style="padding: 20px;">
          <h5 class="Doc-form profile-title"><i class="fa fa-user-md"></i>Reach&nbsp;&nbsp;<span>Us Quickly.</span></h5>
          <div class="alert alert-default d-flex"><i class="fa fa-info-circle"></i><p>Fields with * are required.</p></div>
          <form #contactusForm="ngForm" (ngSubmit)="PostContactformdata(contactusForm)">
            <div class="row d-flex">
              <div class="col-md-4">
                <label>Name <span *ngIf="Name.invalid" class="required-sign">*</span></label>
                  <input type="text" name="Name" value=""
                         [(ngModel)]="Contact.Name" size="25" required #Name="ngModel" />
                  <!-- <div *ngIf="hasError('required','patUsrName')" style="color: red;"> Name is required</div> -->
                  <!-- <input type="hidden" name="UserType" value="Patient" /> -->
                 
              </div>
              <div class="col-md-4">
                <label >Email</label>
                  <input type="email" name="Email" [(ngModel)]="Contact.Email">
              </div>
              <div class="col-md-4">
                <label >Mobile Number<span *ngIf="ContactNumber.invalid"class="required-sign">*</span></label><br/>
                <input type="number" name="ContactNumber" value="" [(ngModel)]="Contact.ContactNumber" required #ContactNumber="ngModel" />
                
              </div>
              <div class="col-md-4">
                <label>Company<span *ngIf="Company.invalid"class="required-sign">*</span></label><br/>
                <input type="text" name="Company" value="" [(ngModel)]="Contact.Company" #Company="ngModel" />
                
              </div>
              <div class="col-md-4">
                <label>Message<span *ngIf="Message.invalid"class="required-sign">*</span></label><br/>
                <textarea type="text" name="Message" value="" [(ngModel)]="Contact.Message" #Message="ngModel" ></textarea>
                
              </div>
            </div>           
            <!-- </form> -->
            <button class="btn btn-primary mt-5" value="Submit">Send Message</button>
          </form>
        </div>
      </div>
      <div class="card">
      <div class="grid-section">
        <h4 class="title">
          <i class="fa fa-list"></i>User's Contacts List
        </h4>
      
        <table class="table ">
          <thead>
            <tr>
              <th scope="col">SN</th>
              <th scope="col">User Name</th>
              <th scope="col">Contact Number</th>
              <th scope="col">EmailId</th>
              <th scope="col">Company</th>
              <th scope="col">Message</th>
              <th scope="col">ContactDate</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cont of contactedUsrlist  |paginate: { itemsPerPage: 5, currentPage: p  } ; let i=index ;">
              <td>{{i+1}}</td>
              <td>{{cont.Name}}</td>
              <td>{{cont.ContactNumber}}</td>
              <td>{{cont.Email}}</td>
              <td>{{cont.Company}}</td>
              <td>{{cont.Message }}</td>
              <td>{{cont.CreatedOn }}</td>
             </tr>
          </tbody>
        </table>
        <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>
      
      </div>
    </div>
