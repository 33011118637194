import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Hospital } from '../../models/hospital/hospital.model';
import { DoctorEndpoint } from '../doctor/doctor-endpoint.service';
import { HospitalEndpoint } from './hospital-endpoint.service';
import { Doctor } from 'src/app/app.model';
import { ContactInformation } from 'src/app/models/contactInfo/contactinformation.model';
import * as _ from 'lodash';
import { Patient } from '../../models/patient/patient.model';

@Injectable()
export class HospitalService {
  constructor(private router: Router, private http: HttpClient, private hospitalEndPoint: HospitalEndpoint) {
  }
  public _HospitalId: string;
  public HospitalList: any;

  get HospitalId(): string {
    return this._HospitalId;
  }
  set HospitalId(HospitalId: string) {
    this._HospitalId = HospitalId;
  }

  public _DepartmentId: string;
  get DepartmentId(): string {
    return this._DepartmentId;
  }
  set DepartmentId(DepartmentId: string) {
    this._DepartmentId = DepartmentId;
  }

  public _PaymentEnable: boolean;
  get PaymentEnable(): boolean {
    return this._PaymentEnable;
  }
  set PaymentEnable(PaymentEnable: boolean) {
    this._PaymentEnable = PaymentEnable;
  }

  getHospitalList() {
    return this.hospitalEndPoint.geHospitalListEndpoint<Hospital[]>();
  }
  getHospital(HospitalId?: string) {
    return this.hospitalEndPoint.geHospitalEndpoint<Hospital>(HospitalId);
  }
  getDocHospitalList(doctorId: string) {
    return this.hospitalEndPoint.getDocHospitalListEndpoint<Doctor>(doctorId);
  }
  getDocHospitalListbyvisitytpe(doctorId: string, visitType: string) {
    return this.hospitalEndPoint.getDocHospitalListbyvisittypeEndpoint<Doctor>(doctorId, visitType);
  }
  addHospital(data: Hospital) {
    return this.hospitalEndPoint.addHospitalEndpoint<Hospital[]>(data);
  }
  deleteHospital(HospitalId: string) {
    return this.hospitalEndPoint.deleteHospitalEndpoint<Hospital>(HospitalId);
  }
  updateHospital(hospital: Hospital) {
    if (hospital.HospitalId) {
      return this.hospitalEndPoint.updateHospitalEndpoint(hospital, hospital.HospitalId);
    }
    else {
      return this.hospitalEndPoint.updateHospitalEndpoint<Hospital>(hospital, hospital.HospitalId).pipe(
        mergeMap(map => {
          hospital.HospitalId = hospital.HospitalId;
          return this.hospitalEndPoint.updateHospitalEndpoint(hospital, hospital.HospitalId)
        }));
    }
  }
  getcontactInformation() {
    return this.hospitalEndPoint.getcontactInformationEndpoint<ContactInformation[]>();
  }
  addContactInfo(continfo: ContactInformation) {
    return this.hospitalEndPoint.addContactInfoEndpoint<ContactInformation[]>(continfo);
  }
  updateContactInfo(contactInfo: ContactInformation) {
    if (contactInfo.ContactInformationId) {
      return this.hospitalEndPoint.updateContactInfoEndpoint(contactInfo, contactInfo.ContactInformationId);
    }
    else {
      return this.hospitalEndPoint.updateContactInfoEndpoint<ContactInformation>(contactInfo, contactInfo.ContactInformationId).pipe(
        mergeMap(map => {
          contactInfo.ContactInformationId = contactInfo.ContactInformationId;
          return this.hospitalEndPoint.updateContactInfoEndpoint(contactInfo, contactInfo.ContactInformationId)
        }));
    }
  }

  getAppMasterReport() {
    return this.hospitalEndPoint.getAppMasterReportEndPoint<Patient>();
  }

  getContactInfo() {
    return this.hospitalEndPoint.getContactInfoEndpoint<ContactInformation[]>();
  }

  getHospitalDepartmentDetailsFromVisitType(doctorId, visitType) {
    return this.hospitalEndPoint.getHospitalDepartmentDetailsFromVisitTypeEndpoint(doctorId, visitType);
  }
}
