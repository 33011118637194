import { Component, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../../app.global';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { DoctorService } from '../../../services/doctor/doctor.service';
import { AlertService } from '../../../services/alert.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { HospitalService } from '../../../services/hospital/hospital.service';
import * as moment from 'moment';
import { AnyCnameRecord } from 'dns';

@Component({
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent {
   config: any;
   token: any;
   searchText : any;
  public showAppMasterReport:boolean=false;
  public showDocOrderReport:boolean=false;
  public reportData:any;
  collection = { count: 60, data: [] };
  public filteredpatientList: any;
  public todate = moment().format('YYYY-MM-DD');
  public fromdate = moment().format('YYYY-MM-DD');
  public showPrint: boolean = false;
  public printDetails: any;
  public fromDate: string = null;
  public toDate: string = null;
  public doctorList: Array<any> = new Array<any>();
  public deptList: Array<any> = new Array<any>();
  public selDoctor: any = "";
  public selDept: any = "";
  public selAppointmentType: any = "";
  public selVisitType: any = "";
  public selPaymentStatus: any = "";
  public selPaymentMedium: any = "";
  public selItem: any = "";
  public showDetailView: boolean = false;
  public reportHeader: any;
  public masterReportList:any;
  public p: number = 1;
  constructor(public routing: Router, public hospitalService: HospitalService, public authService: AuthenticationService, public alertService: AlertService,
    public global: Global, public notifyService: NotificationService, public dialog: MatDialog, public changeDetector: ChangeDetectorRef, public docService: DoctorService ) {
    this.token = this.authService.currentUserValue;
    this.AppMasterReport();
    this.getDoctorList();
    this.GetDepartmentList();
  }
  pageChanged(event) {
    this.config.currentPage = event;
  }

  GetReportData(){
    this.hospitalService.getAppMasterReport().subscribe((res: any) => {
      this.reportData = res?.Results;
      for (var i = 0; i < this.reportData.length; i++) {
        this.reportData[i].VisitDate = moment(this.reportData[i].VisitDate).format("YYYY-MM-DD");
        // this.patBookList[i].VisitStartTime = moment(this.patBookList[i].VisitStartTime).format('LT');
      }
      this.collection = { count: this.reportData.length, data: [] }
      for (var i = 0; i < this.collection.count; i++) {
        this.collection.data.push(
          {
            id: i + 1,
            VisitDate: this.reportData[i].VisitDate,
            PatientName: this.reportData[i].PatientName,
            ContactNumber: this.reportData[i].ContactNumber,
            Address: this.reportData[i].Address,
            DoctorName: this.reportData[i].DoctorName,
            Age: this.reportData[i].Age,
            PaymentStatus: this.reportData[i].PaymentStatus,
            BookingTime: this.reportData[i].BookingTime,
            HospitalId: this.reportData[i].HospitalId,
            DepartmentId: this.reportData[i].DepartmentId,
            DoctorId: this.reportData[i].DoctorId,
            VisitId: this.reportData[i].VisitId,
            SchedulingId: this.reportData[i].SchedulingId,
            VisitStatus: this.reportData[i].Status,
            Gender: this.reportData[i].Gender,
            DepartmentName: this.reportData[i].DepartmentName,
            AppointmentType: this.reportData[i].AppointmentType,
            VisitType: this.reportData[i].FollowUpVisitType,
            PaymentMedium: this.reportData[i].PaymentMethod,
            Amount: this.reportData[i].Amount
          }
        );
      }

      this.FilterbyDate();
    },
    error => {
      this.alertService.error(error);
    });
  }

  AppMasterReport(){
    this.showAppMasterReport=true;
    this.showDocOrderReport=false;
    this.todate = moment().format('YYYY-MM-DD');
    this.fromdate = moment().format('YYYY-MM-DD');
    this.selDoctor = "";
    this.selDept = "";
    this.selAppointmentType = "";
    this.selVisitType = "";
    this.selPaymentStatus = "";
    this.selPaymentMedium = "";
    this.p = 1;
    this.GetReportData();
  }
  FilterbyDate() {
    this.filteredpatientList = new Array<any>();
    this.filteredpatientList = JSON.parse(JSON.stringify(this.collection.data));
    this.Search();
  }
  Search() {
    var todatemoment = moment(this.todate, 'YYYY-MM-DD');
    var fromdatemoment = moment(this.fromdate, 'YYYY-MM-DD');
    var diff = moment(todatemoment).diff(fromdatemoment, 'days');
    if (diff >= 0) {
      this.filteredpatientList = new Array<any>();
      this.filteredpatientList = JSON.parse(JSON.stringify(this.collection.data));
      for (var i = 0; i < this.filteredpatientList.length; i++) {
        this.filteredpatientList[i].VisitDate = moment(this.filteredpatientList[i].VisitDate, 'YYYY-MM-DD');
        // this.patBookList[i].VisitStartTime = moment(this.patBookList[i].VisitStartTime).format('LT');
      }
      this.filteredpatientList = this.filteredpatientList.filter(a => a.VisitDate <= todatemoment && a.VisitDate >= fromdatemoment);
      this.masterReportList = this.filteredpatientList;
    } else {
      this.notifyService.showWarning("Sorry", "Please check date interval  !!");
    }
  }

  PrintTxnTable() {
    try {
      this.showPrint = false;
      this.printDetails = null;
      this.changeDetector.detectChanges();
      this.showPrint = true;
      this.printDetails = document.getElementById("printpage").innerHTML;
      this.print();
    } catch (ex) {
      this.ShowCatchErrMessage(ex);
    }
  }
  public ShowCatchErrMessage(exception) {
    if (exception) {
      let ex: Error = exception;
      this.notifyService.showError("error", ["Check error in Console log !"]);
      console.log("Error Messsage =>  " + ex.message);
      console.log("Stack Details =>   " + ex.stack);
    }
  }

  print() {
    var contents = this.printDetails;
    var iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    if(this.showAppMasterReport == true){
      this.reportHeader = "Appointment Master Report";
    }
    else{
      this.reportHeader = "Doctor's Order Report";
    }

    let documentContent = "<html><head>";
    // documentContent += '<link rel="stylesheet" type="text/css" media="print" href="../../../css/PrintStyle.css"/>';
    documentContent += '<link rel="stylesheet" type="text/css" href="../../../../lib/bootstrap/dist/css/bootstrap.min.css"/>';
    documentContent += '<h5 style="text-align: center;"><b>' + this.reportHeader + '</b></h5></br></head>';
    documentContent += '<body onload="window.print()">' + contents + '</body></html>'
    var htmlToPrint = '' + '<style type="text/css">' + '.table_data {' + 'border-spacing:0px' + '}' + '</style>';
    htmlToPrint += documentContent;
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(htmlToPrint);
    iframe.contentWindow.document.close();

    setTimeout(function () {
      document.body.removeChild(iframe);
    }, 500);

    //this.notifyService.showSuccess('Success','Printed Successfully!!');
  }

  ExportToExcel(tableData) {
    var workSheetName;
    var Heading;
    var filename;

    if (tableData) {
      if(this.showAppMasterReport == true){
        workSheetName = 'Appointment Master Report';
        Heading = 'Appointment Master Report';
        filename = 'Appointment_Master_Report';
      }
      else{
        workSheetName = "Doctor's Order Report";
        Heading = "Doctor's Order Report";
        filename = "Doctors_Order_Report";
      }
      
      this.ConvertHTMLTableToExcel(tableData, workSheetName, Heading, filename);
    }
  }
  public ConvertHTMLTableToExcel(table: any, SheetName: string, TableHeading: string, FileName: string) {
    try {
      if (table) {
        let workSheetName = (SheetName.length > 0) ? SheetName : 'Sheet';
        let PrintDate = '<b>Created Date:' + moment().format('YYYY-MM-DD') + '</b><br />'
        let Heading = '<h3 style="text-align: center;">' + TableHeading + '</h3>';
        let filename = (FileName.length > 0) ? FileName : 'Exported_Excel_File';
        filename = filename + '_' + moment().format('YYYYMMMDDhhss') + '.xls';

        let uri = 'data:application/vnd.ms-excel;base64,'
          , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{PrintDate}{DateRange}{Heading}{table}</table></body></html>'
          , base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }
          , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
        if (!table.nodeType) table = document.getElementById(table)
        var ctx = { worksheet: workSheetName, table: table.innerHTML, PrintDate: PrintDate, Heading: Heading }
        //return window.location.href = uri + base64(format(template, ctx))             
        var link = document.createElement('a');
        link.href = uri + base64(format(template, ctx));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  DocOrderReport(){
    this.showDocOrderReport=true;
    this.showAppMasterReport=false;
    this.todate = moment().format('YYYY-MM-DD');
    this.fromdate = moment().format('YYYY-MM-DD');
    this.selItem = "";
    this.selDoctor = "";
    this.selDept = "";
    this.selAppointmentType = "";
    this.selVisitType = "";
    this.p = 1;
    this.GetReportData();
}

getDoctorList() {
  this.docService.getDoctorList().subscribe((res: any) => {
    this.doctorList = Object.assign(this.doctorList, res?.Results);
  },
    error => {
      this.alertService.error(error);
    });

}
GetDepartmentList() {
  this.docService.getDepartmentList().subscribe((res: any) => {
    this.deptList = res?.Results;
  },
    error => {
      this.alertService.error(error);
      //this.loading = false;
    });
}

DetailedView(){
  this.showDetailView = true;
}
SummaryView(){
  this.showDetailView = false;
}

  MainSearch(event:MatSelectChange){
    this.filteredpatientList = this.masterReportList;
    //For doctor search
    if(this.selDoctor != "" && this.selDoctor!="All")
    {
      for(var i=0;i<this.filteredpatientList.length;i++)
      this.filteredpatientList=this.filteredpatientList.filter(d=>d.DoctorId==this.selDoctor)
    }

    //For department search
    if(this.selDept != "" && this.selDept!="All")
    { 
      for(var i=0;i<this.filteredpatientList.length;i++)
      this.filteredpatientList=this.filteredpatientList.filter(d=>d.DepartmentId==this.selDept)
    }

    //For appointment search
    if(this.selAppointmentType != "" && this.selAppointmentType!="All")
    {
      for(var i=0;i<this.filteredpatientList.length;i++)
      this.filteredpatientList=this.filteredpatientList.filter(d=>d.AppointmentType==this.selAppointmentType)
    }

    //For visit search
    if(this.selVisitType != "" && this.selVisitType!="All")
    {
      for(var i=0;i<this.filteredpatientList.length;i++)
      this.filteredpatientList=this.filteredpatientList.filter(d=>d.VisitType==this.selVisitType)
    }
    
    //For payment status search
    if(this.selPaymentStatus != "" && this.selPaymentStatus!="All")
    {
      for(var i=0;i<this.filteredpatientList.length;i++)
      this.filteredpatientList=this.filteredpatientList.filter(d=>d.PaymentStatus==this.selPaymentStatus)
    }

    //For payment medium search
    if(this.selPaymentMedium != "" && this.selPaymentMedium!="All")
    {
      for(var i=0;i<this.filteredpatientList.length;i++)
      this.filteredpatientList=this.filteredpatientList.filter(d=>d.PaymentMedium==this.selPaymentMedium)
    }

    //For item search
    if(this.selItem != "" && this.selItem!="All")
    {
      for(var i=0;i<this.filteredpatientList.length;i++)
      this.filteredpatientList=this.filteredpatientList.filter(d=>d.ItemName==this.selItem)
    }

    if(this.p != 1){
      this.p = 1;
    }
  }
  
  filterReport(){
    if(this.p != 1){
      this.p = 1;
    }
  }
}
