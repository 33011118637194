<style>
  a.input-group-text {
    height: 2.5rem;
  }
</style>
<button *ngIf="typeregister == 'patientregister' && loginpatient != 'patient'" class="back-btn-doc-room" style="background-color: dodgerblue;" (click)="BackToPatient()">
  Back To Patient List

</button>
<button *ngIf="typeregister == 'docregister'" class="back-btn-doc-room" style="background-color: dodgerblue;" (click)="BackToDoctor()">
  Back To Doctor List
</button>
<div class="login-container reg">
  <div class="row d-flex justify-content-center h-100">

    <div class="col-10 col-sm-12 col-md-10">
      <div class="row justify-content-center h-100 login-flex">

        <div class="col-md-8 text-center mb-5">
          <a href="" style="margin-bottom: 25px;"><img src="../assets/img/logo-cap.png" alt="" height="50"></a>
          <div class="card text-left">
            <div class="card-header">

              <div class="info">

                <h2>
                  Register to get started<br />
                  <p>Connect with your <strong>Doctor/Patient</strong> from your home</p>
                </h2>
              </div>
              <!--<div class="d-flex justify-content-end social_icon">
                <span><i class="fab fa-facebook-square"></i></span>
                <span><i class="fab fa-google-plus-square"></i></span>
                <span><i class="fab fa-twitter-square"></i></span>
              </div>-->
            </div>
            <div class="card-body">
              <!--<div class="row" style="margin-bottom: 10px;">

                <div class="col-md-6 text-right">
                  <input id="registerpatient" type="radio" class="" [value]="true" [(ngModel)]="isPatientRegister" name="registerpatient" >
                  <label class="" for="registerpatient">I'm a Patient</label>
                </div>

                <div class="col-md-6 text-left">
                  <input id="registerdoctor" type="radio" class="" [value]="false" [(ngModel)]="isPatientRegister"  name="registerpatient">
                  <label class="" for="registerdoctor">I'm a Doctor</label>
                </div>
              </div>-->
              <form *ngIf="typeregister == 'docregister'" [formGroup]="docRegisterForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="phonenumber">Phone Number<span class="required-sign">*</span></label>
                      <input type="number" formControlName="phonenumber" id="phonenumber" placeholder="Phone Number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" autocomplete="new-phonenumber" />
                      <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                        <div *ngIf="f.phonenumber.errors.required">Phone Number is required</div>

                        <div *ngIf="f.phonenumber.errors.pattern">Phone number should be 10 digits</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input type="text" formControlName="mailingaddress" id="mailingaddress" placeholder="Email" class="form-control" autocomplete="mailing-address" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="firstname">First Name<span class="required-sign">*</span></label>
                      <input type="text" formControlName="firstname" id="firstname" placeholder=" First Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" autocomplete="new-firstname" />
                      <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                        <div *ngIf="f.firstname.errors.required">First Name is required</div>
                        <!--
        <div *ngIf="f.firstname.errors.pattern">Phone number should be 10 digits</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="firstname">Middle Name</label>
                      <input type="text" id="middlename" formControlName="middlename" placeholder=" Middle Name" class="form-control" autocomplete="new-middlename" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="lastname">Last Name<span class="required-sign">*</span></label>
                      <input type="text" formControlName=lastname id="lastname" placeholder=" Last Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" autocomplete="new-lastname" />
                      <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                        <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                        <!--
        <div *ngIf="f.firstname.errors.pattern">Phone number should be 10 digits</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="NMC"> NMC No.<span class="required-sign">*</span></label>
                      <input type="number" name="NMC" value="" id="NMC" formControlName="NMC" [ngClass]="{ 'is-invalid': submitted && f.NMC.errors }" autocomplete="new-nmcno" />
                      <div *ngIf="submitted && f.NMC.errors" class="invalid-feedback">
                        <div *ngIf="f.NMC.errors.required">NMC Number is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="Gender">Gender<span class="required-sign">*</span></label><br>
                      <input type="radio" value="Male" name="Gender" formControlName="Gender" [ngClass]="{ 'is-invalid': submitted && f.Gender.errors }"> M<span class="hide-sm">ale
                        &nbsp;&nbsp;</span>
                      <input type="radio" value="Female" name="Gender" formControlName="Gender" [ngClass]="{ 'is-invalid': submitted && f.Gender.errors }">
                      F<span class="hide-sm">emale&nbsp;&nbsp;</span>
                      <input type="radio" value="Other" name="Gender" formControlName="Gender" [ngClass]="{ 'is-invalid': submitted && f.Gender.errors }"> O<span class="hide-sm">ther&nbsp;&nbsp;</span>
                      <div *ngIf="submitted && f.Gender.errors" class="invalid-feedback">
                        <div *ngIf="f.Gender.errors.required">Gender is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="dateofbirth">Date of Birth</label>
                      <div class="input-group">
                        <input type="text" id="dateofbirth" formControlName="dateofbirth" placeholder=" Date of Birth " class="form-control" [maxDate]="today" bsDatepicker #d="bsDatepicker" placement="top" autocomplete="new-dateofbirth" />
                        <div class="input-group-append">
                          <button class="fa fa-calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <label for="">Designation<span class="required-sign">*</span></label>
                              <input type="text" class="md-textarea form-control" formControlName="Designation" id="Designation" placeholder="Enter Designation" [ngClass]="{ 'is-invalid': submitted && f.Designation.errors }" autocomplete="new-Designation" />
                              <div *ngIf="submitted && f.Designation.errors" class="invalid-feedback">
                                <div *ngIf="f.Designation.errors.required">Designation is required</div>
                              </div>
                            </td>
                            <td>
                              <label for="">Education</label>
                              <input type="text" class="md-textarea form-control" formControlName="Education" id="Education" placeholder="Enter Education" [ngClass]="{ 'is-invalid': submitted && f.Education.errors }" autocomplete="new-Education" />
                              <div *ngIf="submitted && f.Education.errors" class="invalid-feedback">
                                <div *ngIf="f.Education.errors.required">Education is required</div>
                              </div>
                            </td>
                            <td>
                              <label for="">Experience</label>
                              <input type="text" class="md-textarea form-control" formControlName="Experience" id="Experience" placeholder="Enter Experience" [ngClass]="{ 'is-invalid': submitted && f.Experience.errors }" autocomplete="new-Experience" />
                              <div *ngIf="submitted && f.Experience.errors" class="invalid-feedback">
                                <div *ngIf="f.Experience.errors.required">Experience is required</div>
                              </div>
                            </td>
                            <td>
                              <label for="">PastAffiliation</label>
                              <input type="text" class="md-textarea form-control" formControlName="PastAffiliation" id="PastAffiliation" placeholder="Enter PastAffiliation" [ngClass]="{ 'is-invalid': submitted && f.PastAffiliation.errors }" autocomplete="new-PastAffiliation" />
                              <div *ngIf="submitted && f.PastAffiliation.errors" class="invalid-feedback">
                                <div *ngIf="f.PastAffiliation.errors.required">PastAffiliation is required</div>
                              </div>
                            </td>
                            <td>
                              <label for="">Membership</label>
                              <input type="text" class="md-textarea form-control" formControlName="Membership" id="Membership" placeholder="Enter Membership" [ngClass]="{ 'is-invalid': submitted && f.Membership.errors }" autocomplete="new-Membership" />
                              <div *ngIf="submitted && f.Membership.errors" class="invalid-feedback">
                                <div *ngIf="f.Membership.errors.required">Membership is required</div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <label for="">Department<span class="required-sign">*</span></label>
                              <select class="md-textarea form-control" formControlName="Department" id="Department" placeholder="Enter Department" [ngClass]="{ 'is-invalid': submitted && f.Department.errors }" autocomplete="new-Department" placeholder="Department">
                                <option *ngFor="let list of deptList" [value]="list.DepartmentId">
                                  {{list.DepartmentName}}
                                </option>
                              </select>
                              <div *ngIf="submitted && f.Department.errors" class="invalid-feedback">
                                <div *ngIf="f.Department.errors.required">Department is required</div>
                              </div>
                            </td>
                            <td>
                              <label for="">Charge<span class="required-sign">*</span></label>
                              <input type="text" class="md-textarea form-control" formControlName="Charge" id="Charge" placeholder="Enter Charge" [ngClass]="{ 'is-invalid': submitted && f.Charge.errors }" autocomplete="new-Charge" />
                              <div *ngIf="submitted && f.Charge.errors" class="invalid-feedback">
                                <div *ngIf="f.Charge.errors.required">Charge is required</div>
                              </div>
                              <div *ngIf="submitted && f.Charge.errors" class="invalid-feedback">
                                <div *ngIf="f.Charge.errors.pattern">Charge is not valid</div>
                              </div>
                            </td>
                            <td>
                              <label for="">Consultation Type:<span class="required-sign">*</span></label>
                              <select class="md-textarea form-control" formControlName="ConsultationType" id="ConsultationType" placeholder="Enter Consultation Type" [ngClass]="{ 'is-invalid': submitted && f.ConsultationType.errors }" autocomplete="new-ConsultationType" placeholder="Select Consultation Type">
                                <option *ngFor="let c of ConsultationType" [ngValue]="c.name">
                                  {{c.name}}
                                </option>
                              </select>
                              <div *ngIf="submitted && f.ConsultationType.errors" class="invalid-feedback">
                                <div *ngIf="f.ConsultationType.errors.required">Consultation Type is required</div>
                              </div>
                            </td>
                            <td>
                              <label for="area">Long Signature:</label>
                              <textarea id="area" type="text" class="md-textarea form-control" formControlName="LongSignature" placeholder="Enter LongSignature" autocomplete="new-LongSignature"></textarea>
                              <!-- <div *ngIf="submitted && f.LongSignature.errors" class="invalid-feedback">
                                <div *ngIf="f.LongSignature.errors.required">LongSignature is required</div>
                              </div> -->
                            </td>
                            <td>
                              <!-- <img src="{{tempvalue}}" style="width: 180px;"/> -->
                              <!-- <img src="{{tempvalue}}" style="width: 180px;"/> -->
                              <input type="file" #file placeholder="Choose file" (change)="upload(file.files)" name="fileName" formControlName="Filepath" style="display:none;">

                              <button type="button" class="btn btn-primary d-inline" (click)="file.click()" style="font-size: 14px; margin-top: 25px">
                                <i class="fa fa-upload"></i>Upload Image
                              </button>
                              <button type="button" (click)="Removepicture()" class="btn btn-primary d-inline" style="font-size: 14px; margin-top: 25px">
                                Remove
                              </button>
                              <div class="col-md-10 d-inline">
                                <span class="upload" *ngIf="progress > 0" style="font-size: 11px;">
                                  {{progress}}%
                                </span>
                                <span class="upload" *ngIf="message" style="font-size: 11px;">
                                  {{message}}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="password">Password<span class="required-sign">*</span></label>
                    <div class="input-group form-group">
                      <input [type]="showPassword ? 'text' : 'password'" id="password" formControlName="password" class="form-control" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="new-password" />
                      <div id="show-password" class="input-group-append">
                        <a class="input-group-text" (click)="showPass()"><em [ngClass]="{'fa': true, 'fa-eye': !showPassword, 'fa-eye-slash': showPassword}" aria-hidden="true"></em></a>
                      </div>
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="confirmpassword">Confirm Password<span class="required-sign">*</span></label>
                    <div class="input-group form-group">
                      <input [type]="showConfirmPassword ? 'text' : 'password'" id="confirmpassword" formControlName="confirmpassword" class="form-control" placeholder="Confirm Password" [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }" autocomplete="new-confirmpassword" />
                      <div id="show-confirm-password" class="input-group-append">
                        <a class="input-group-text" (click)="showConfirmPass()"><em [ngClass]="{'fa': true, 'fa-eye': !showConfirmPassword, 'fa-eye-slash': showConfirmPassword}" aria-hidden="true"></em></a>
                      </div>
                      <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmpassword.errors.required">Password is required</div>
                        <div *ngIf="f.confirmpassword.errors.minlength">
                          Password must be at least 6 characters.
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Register
                      </button>
                      <label class="pull-right" style="font-size: 0.75rem; margin-top: 15px;">
                        Already have an Account
                        ?&nbsp;&nbsp;<a routerLink="/login" class="text-primary"><strong>Login</strong></a>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
              <form *ngIf="typeregister == 'patientregister'" [formGroup]="patRegisterForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="phonenumber">Phone Number<span class="required-sign">*</span></label>
                      <div class="input-group">
                        <select
                          id="country-code"
                          formControlName="countryCode"
                          class="form-control"
                          style="max-width: fit-content"
                        >
                          <option *ngFor="let country of countries" [value]="country.code">
                            {{ country.name }} ({{ country.code }})
                          </option>
                        </select>
                        <input
                          type="tel"
                          formControlName="phonenumber"
                          id="phonenumber"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                          placeholder="Phone number"
                        />
                      </div>
                      <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                        <div *ngIf="f.phonenumber.errors.required">Phone number is required.</div>
                        <div *ngIf="f.phonenumber.errors.pattern">Invalid phone number.</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input type="text" formControlName="mailingaddress" id="mailingaddress" placeholder="Email" class="form-control" autocomplete="mailing-address" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="firstname">First Name<span class="required-sign">*</span></label>
                      <input type="text" formControlName="firstname" id="firstname" placeholder=" First Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" autocomplete="new-firstname" />
                      <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                        <div *ngIf="f.firstname.errors.required">First Name is required</div>
                        <!--
        <div *ngIf="f.firstname.errors.pattern">Phone number should be 10 digits</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="firstname">Middle Name</label>
                      <input type="text" id="middlename" formControlName="middlename" placeholder=" Middle Name" class="form-control" autocomplete="new-middlename" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="lastname">Last Name<span class="required-sign">*</span></label>
                      <input type="text" formControlName=lastname id="lastname" placeholder=" Last Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" autocomplete="new-lastname" />
                      <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                        <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                        <!--
        <div *ngIf="f.firstname.errors.pattern">Phone number should be 10 digits</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="Age">Age</label>
                      <input type="number" [(ngModel)]="Age" formControlName="Age" id="Age" placeholder="Enter Age" class="form-control" (focusout)="generateDOB()" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="bloodgroup">Blood Group</label>
                      <input type="text" id="bloodgroup" formControlName="BloodGroup" placeholder="Enter Blood Group" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="address">Address<span class="required-sign">*</span></label>
                      <input type="text" formControlName=Address id="address" placeholder="Enter Address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Address.errors }" autocomplete="new-Address" />
                      <div *ngIf="submitted && f.Address.errors" class="invalid-feedback">
                        <div *ngIf="f.Address.errors.required">Address is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="city">City<span class="required-sign">*</span></label>
                      <input type="text" formControlName=city id="city" placeholder="Enter City" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" autocomplete="new-city" />
                      <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                        <div *ngIf="f.city.errors.required">City is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="state">State<span class="required-sign">*</span></label>
                      <input type="text" formControlName=state id="state" placeholder="Enter State" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.state.errors }" autocomplete="new-state" />
                      <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                        <div *ngIf="f.state.errors.required">State is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="postalcode">Postal Code<span class="required-sign">*</span></label>
                      <input type="text" formControlName=postalcode id="postalcode" placeholder="Enter Postal Code" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" autocomplete="new-postalcode" />
                      <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                        <div *ngIf="f.postalcode.errors.required">Postal Code is required</div>   
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="country">Country<span class="required-sign">*</span></label>
                      <input type="text" formControlName=country id="country" placeholder="Enter Country" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.country.errors }" autocomplete="new-country" />
                      <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                        <div *ngIf="f.country.errors.required">Country is required</div>   
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="dateofbirth">Date of Birth</label>
                      <div class="input-group">
                        <input type="text" id="dateofbirth" [(ngModel)]="dateOfBirth" formControlName="dateofbirth" placeholder=" Date of Birth " class="form-control" [maxDate]="today" bsDatepicker #d="bsDatepicker" autocomplete="new-dateofbirth" (ngModelChange)="generateAge()" placement="top" />
                        <div class="input-group-append">
                          <button class="fa fa-calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="Gender">Gender<span class="required-sign">*</span></label><br>
                      <input type="radio" value="Male" name="Gender" formControlName="Gender" [ngClass]="{ 'is-invalid': submitted && f.Gender.errors }"> M<span class="hide-sm">ale&nbsp;&nbsp;</span>
                      <input type="radio" value="Female" name="Gender" formControlName="Gender" [ngClass]="{ 'is-invalid': submitted && f.Gender.errors }"> F<span class="hide-sm">emale&nbsp;&nbsp;</span>
                      <input type="radio" value="Other" name="Gender" formControlName="Gender" [ngClass]="{ 'is-invalid': submitted && f.Gender.errors }"> O<span class="hide-sm">ther&nbsp;&nbsp;</span>
                      <div *ngIf="submitted && f.Gender.errors" class="invalid-feedback">
                        <div *ngIf="f.Gender.errors.required">Gender is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="password">Password<span class="required-sign">*</span></label>
                    <div class="input-group form-group">
                      <input [type]="showPassword ? 'text' : 'password'" formControlName="password" class="form-control" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="new-password" />
                      <div id="show-password" class="input-group-append">
                        <a class="input-group-text" (click)="showPass()"><em [ngClass]="{'fa': true, 'fa-eye': !showPassword, 'fa-eye-slash': showPassword}" aria-hidden="true"></em></a>
                      </div>
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                      </div>
                    </div>
                  </div>
                  <!--</div>-->
                  <div class="col-md-6">
                    <label for="confirmpassword">Confirm Password<span class="required-sign">*</span></label>
                    <div class="input-group form-group">
                      <input [type]="showConfirmPassword ? 'text' : 'password'" id="confirmpassword" formControlName="confirmpassword" class="form-control" placeholder="Confirm Password" [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }" autocomplete="new-confirmpassword" />
                      <div id="show-confirm-password" class="input-group-append">
                        <a class="input-group-text" (click)="showConfirmPass()"><em [ngClass]="{'fa': true, 'fa-eye': !showConfirmPassword, 'fa-eye-slash': showConfirmPassword}" aria-hidden="true"></em></a>
                      </div>
                      <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmpassword.errors.required">Password is required</div>
                        <div *ngIf="f.confirmpassword.errors.minlength">
                          Password must be at least 6 characters.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Register
                      </button>
                      <label class="pull-right" style="font-size: 0.75rem; margin-top: 15px;">
                        Already have an Account
                        ?&nbsp;&nbsp;<a routerLink="/login" class="text-primary"><strong>Login</strong></a>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="powered-section">
    <span>Powered By</span>
    <div class="login-brand">
      <img src="../assets/img/imark-powered.png">
    </div>
  </div>
</div>


<h2>Register</h2>