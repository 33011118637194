<app></app>
<button *ngIf="this.token.PatientIdentifier != undefined" [routerLink]="['/HospitalList']" class="back-btn-doc-room">
  Back
</button>
<button *ngIf="this.token.PatientIdentifier == undefined" [routerLink]="['/admin']" class="back-btn-doc-room">
  Back To Admin
</button>
<div *ngIf="this.token.PatientIdentifier != undefined">
  <h4 class="text-center mt-4">
    Hello!
    <label class="text-primary-green">
      <strong>
        <b *ngIf="patInfo.Gender=='Female'"> Ms. </b>
        <b *ngIf="patInfo.Gender=='Male'"> Mr. </b>{{patInfo.FirstName + ' ' +
        patInfo.LastName}}
      </strong>
    </label>
    Select Doctor from here !!
  </h4>
</div>

<div *ngIf="this.token.PatientIdentifier == undefined">
  <h4 class="text-center mt-4">
    Hello!
    <label class="text-primary">
      <strong> <b> Mr. </b> Admin </strong>
    </label>
    Select Doctor from here !!
  </h4>
</div>
<div *ngIf="isDoctor">
  <div class="row searchbar-card text-center" style="margin-top: 0">
    <div class="col-md-6 col-sm-8 m-auto d-flex">
      <div class="searchbar">
        <input class="search_input" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Hospital.." />
        <a class="search_icon text-white"><i class="fa fa-search"></i>Search</a>
      </div>
    </div>
  </div>
  <div class="row d-flex">
    <div *ngFor=" let list of hospitalList | filter: searchText" class="col-md-3 col-sm-6">
      <div class="hospital-grid" (click)="GoToHospital(list.HospitalId)">
        <div class="hospital-image">
          <img class="pic-1" src="{{list.ImagePath}}" alt="{{list.HospitalName}}" />
        </div>
        <div class="hospital-content">
          <a class="btn text-primary">{{list.HospitalName}}</a>
          <a class="btn" style="padding-top: 0; font-size: 12px"><i class="fa fa-map-marker"></i>{{list.Location}}</a>
          <a class="btn" style="padding-top: 0; font-size: 12px"><i class="fa fa-phone"></i>Your Phone Number</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="row searchbar-card text-center" style="margin-top: 0">
    <div class="col-md-6 col-sm-8 m-auto d-flex">
      <div class="searchbar">
        <input class="search_input" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Doctor.." />
        <a class="search_icon text-white"><i class="fa fa-search"></i>Search</a>
      </div>
    </div>
  </div>
  <div class="row d-flex">
    <div *ngFor=" let list of doctorList | filter: searchText" class="col-md-4 col-sm-6 col-xs-12">
      <div class="hospital-grid" (click)="GoToDoctor(list.DoctorId)">
        <div class="hospital-image">
          <img class="pic-1" src="{{list.FilePath}}" />
        </div>
        <div class="hospital-content">
          <a class="btn text-primary">{{list.DoctorName}}</a>
          <a class="btn" style="padding-top: 0; font-size: 12px">
            <i class="fa fa-map-marker"></i>{{list.LongSignature}}
          </a>
          <a class="btn" style="padding-top: 0"><i class="fa fa-id-card"></i>NMC No:{{list.NMC}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="s-container">
  <img class='photo-slider' src="./assets/img/slider1.jpg" alt="" />
  <img class='photo-slider' src="./assets/img/slider2.jpg" alt="" />
  <img class='photo-slider' src="./assets/img/slider1.jpg" alt="" />
  <img class='photo-slider' src="./assets/img/slider2.jpg" alt="" />
</div> -->
<footer></footer>