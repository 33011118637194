export class PatientMedicalRecord {

  public MedicalRecordId: string;
  public PatientId: string;
  public PatientName: string;
  public TestReportType: string;
  public FileName: string;
  public FilePath: string;
  public FileExtension: string;
  public UploadedBy: string;
  public FamilyMemberId: string;
  // public ClientPatientId: string;
}