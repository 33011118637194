<style>
  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }

  .custom-day.focused {
    background-color: #e6e6e6;
  }

  .custom-day.range,
  .custom-day:hover {
    background-color: rgb(2, 117, 216);
    color: white;
  }

  .custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
  }


  .control-label {
    font-size: 13px;
  }

  .form-control {
    font-size: 13px;
  }


  div.col-xs-12 {
    padding-left: 10px;
    margin-left: 25px;
    margin-right: 15px;
  }
</style>

<app></app>

<div class="d-flex">
  <button (click)="Admin()" class="back-btn-doc-room">Back To Admin </button>
  <mat-chip-list aria-label="appointment over phone">
    <mat-chip color="primary" (click)="routeToAppointmentOverPhone()" selected> <em class="fa fa-phone"></em>&nbsp; Appointment Over Phone</mat-chip>
  </mat-chip-list>
</div>

<div *ngIf="showpatientList" class="grid-section">
  <h4 class="title">
    <i class="fa fa-list"></i>Patient Appointment List
  </h4>
  <button style="background-color: darkred; margin-bottom: 15px;" (click)="ShowCancelledAppointment()">Show Cancelled Appointment </button>
  <div class="col-md-12 alert alert-default">
    <div class="row d-flex align-items-center">
      <!--<form [formGroup]="visitObj.PatientValidator">-->
      <label class="control-label col-md-2">From Date:</label>
      <div class="col-md-3">
        <input type="date" name="date" [(ngModel)]="fromdate" required class="m-0" />
      </div>
      <label class="control-label col-md-2"> To Date:</label>
      <div class="col-md-3">
        <input type="date" name="date" [(ngModel)]="todate" required class="m-0" />
      </div>
      <button (click)="Search()">Search</button>
      <!--<span *ngIf="!visitObj.IsDirty('VisitDate')" [hidden]="visitObj.IsValidCheck('VisitDate','required')"> Appointment Date is required</span>-->
      <!--</form>-->
    </div>
  </div>
  <div style="overflow: scroll;">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">Patient Name</th>
          <th scope="col">Age/Gender</th>
          <th scope="col">Phonenumber</th>
          <th scope="col">Department</th>
          <th scope="col">Doctor </th>
          <th scope="col">Date</th>
          <th scope="col">Time</th>
          <th scope="col">Visit Type</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filteredpatientList |paginate: { itemsPerPage: 10, currentPage: p  } ; let i=index;">
          <td>{{i+1}}</td>
          <td>{{data.PatientName}}</td>
          <td>{{data.Age}}/{{data.Gender}}</td>
          <td>{{data.ContactNumber}}</td>
          <td>{{data.DepartmentName}}</td>
          <td>{{data.DoctorName}}</td>
          <td>{{data.VisitDate | date:'mediumDate'}}</td>
          <td>{{data.BookingTime}}</td>
          <td>{{data.VisitType}}</td>
          <td>{{data.PaymentStatus}}</td>
          <td>
            <button class="btn btn-success btn-sm mr-1" *ngIf="data.VisitDate >= today" (click)="ChangeStatus(data)">Change Status</button>
            <button class="btn btn-info btn-sm mr-1" (click)="Reschedule(data)">Reschedule</button>
            <!--<button *ngIf="data.VisitStatus != 'cancelled' && data.VisitStatus !='completed' "  (click)="CancelBooking(data)">Cancel Booking</button>-->
            <button *ngIf="data.VisitStatus != 'cancelled' && data.VisitStatus!='deleted'&& data.VisitStatus !='completed' " class="btn btn-danger btn-sm" (click)="openDialog(data)">Cancel Booking</button>
            <button class="btn btn-success btn-sm mr-1" (click)="file.click()">Upload Files</button>
            <input #file type="file" (change)="upload(file.files,data.VisitId)" name="fileName" (ngModel)="FilePath" [ngModelOptions]="{standalone: true}" style="display: none;" />
          </td>


        </tr>
      </tbody>
    </table>
  </div>


  <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>
</div>
<div *ngIf="showcancelledAppointmentpatientList" class="grid-section">
  <h4 class="title">
    <i class="fa fa-list"></i>Cancelled Booked List
  </h4>
  <button *ngIf="showcancelledAppointmentpatientList" style="background-color: dodgerblue;margin-bottom: 15px;" (click)="ShowPatientList()"> Show Patient Appointment List </button>
  <div class="col-md-12 alert alert-default">
    <div class="row d-flex align-items-center">
      <!--<form [formGroup]="visitObj.PatientValidator">-->
      <label class="control-label col-md-2">From Date:</label>
      <div class="col-md-3">
        <input type="date" name="date" [(ngModel)]="fromdate" required class="m-0" />
      </div>
      <label class="control-label col-md-2"> To Date:</label>
      <div class="col-md-3">
        <input type="date" name="date" [(ngModel)]="todate" required class="m-0" />
      </div>
      <button style="background-color: #1e1ee2" (click)="Search()">Search</button>
      <!--<span *ngIf="!visitObj.IsDirty('VisitDate')" [hidden]="visitObj.IsValidCheck('VisitDate','required')"> Appointment Date is required</span>-->
      <!--</form>-->
    </div>
  </div>
  <div style="overflow: scroll;">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">Patient Name</th>
          <th scope="col">Age/Gender</th>
          <th scope="col">Phonenumber</th>
          <th scope="col">Department </th>
          <th scope="col">Doctor </th>
          <th scope="col">Date</th>
          <th scope="col">Time</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filteredpatientList |paginate: { itemsPerPage: 10, currentPage: p  } ; let i=index;">
          <td>{{i+1}}</td>
          <td>{{data.PatientName}}</td>
          <td>{{data.Age}}/{{data.Gender}}</td>
          <td>{{data.ContactNumber}}</td>
          <td>{{data.DepartmentName}}</td>
          <td>{{data.DoctorName}}</td>
          <td>{{data.VisitDate | date:'mediumDate'}}</td>
          <td>{{data.CancelledBookingTime}}</td>
          <td>{{data.PaymentStatus}}</td>
          <td>
            <button style="background-color: green" (click)="Reschedule(data)">Book Again</button>
            <!--<button *ngIf="data.VisitStatus != 'cancelled' && data.VisitStatus !='completed' " style="background-color: green" (click)="CancelBooking(data)">Cancel Booking</button>-->
          </td>
        </tr>
      </tbody>
    </table>
  </div>


  <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>
</div>


<div class="modal" id="target1" tabindex="-1" role="dialog" [style.display]="showView ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="card-title" style="width: 100%;">
          Payment Status
          <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
        </h1>
      </div>
      <div class="modal-body" style="background-color:snow;">
        <!-- <form #Form="ngForm" (ngSubmit)="UpdateHospital(Form)"> -->
        <!-- </form> -->
        <div class="form-group">
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <label class="control-label col-md-5">Payment Status<b style="float:right;">:</b></label>
                <div class="col-md-7" *ngIf="!IsDanpheCare">
                  <select class="form-control" placeholder="Select Payment Status" [(ngModel)]="selectedValue" (focusout)="paymentstatusChanged()">
                    <option *ngFor="let v of PaymentStatus" [value]="v.status" [selected]="v.status == selectedValue">
                      {{v.status}}
                    </option>
                  </select>
                </div>
                <div class="col-md-7" *ngIf="IsDanpheCare">
                  <select class="form-control" placeholder="Select Payment Status" [(ngModel)]="selectedValue" (focusout)="paymentstatusChanged()">
                    <option *ngFor="let v of PaymentStatusDanpheCare" [value]="v.status" [selected]="v.status == selectedValue">
                      {{v.status}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" (click)="UpdateStatus()">Update</button>
      </div>
    </div>
  </div>
</div>