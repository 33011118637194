
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EndpointFactory } from '../endpoint-factory.service';
import { catchError } from 'rxjs/operators';
import { Scheduling } from 'src/app/models/scheduling/scheduling.model';

@Injectable()
export class SchedulingEndpoint extends EndpointFactory {

  private readonly _schedulingTimeUrl: string = "/api/doctorScheduling/getSchedulingTime";
  private readonly _schedulingDoctorListUrl: string = "/api/doctorScheduling/getDoctor";
  private readonly _schedulinglUrl: string = "/api/doctorScheduling/getScheduling";
  private readonly _schedulinglIntervalUrl: string = "/api/doctorScheduling/getScheduleIntervalById";
  private readonly _schedulinglIntervalBySchedulingIdUrl: string = "/api/doctorScheduling/getScheduleIntervalBySchedulingId";
  private readonly _schedulingShowUrl: string = "/api/doctorScheduling/getScheduleDate";
  private readonly _schedulingPostUrl: string = "/api/doctorScheduling/postScheduling";
  private readonly _schedulingAddUrl: string = "/api/doctorScheduling/addScheduling";
  private readonly _schedulingUpdateUrl: string = "/api/doctorScheduling/updateScheduling";
  private readonly _schedulingVisitTimeListUrl: string = "/api/doctorScheduling/getVisitTime";
  private readonly _schedulingdeleteUrl: string = "/api/doctorScheduling/deleteSchedule";
  private readonly _schedulingVisitTimeUrl: string = "/api/doctorScheduling/getVisit";
  private readonly _rescheduleUrl: string = "/api/doctorScheduling/postReschedule";
  private readonly _checkForExistingAppointmentUrl: string = "/api/doctorScheduling/CheckForBookedAppointment";
  private readonly _deleteVisitUrl: string = "/api/doctorScheduling/RemoveScheduleCompletely";

  constructor(http: HttpClient, injector: Injector) {
    super(http, injector);
  }
  getSchedulingDoctorList<T>(): Observable<T> {
    let endpointUrl = `${this._schedulingDoctorListUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getSchedulingDoctorList()));
      }));
  }
  getSchedulingTimeEndpoint<T>(mapId?: string): Observable<T> {
    let endpointUrl = `${this._schedulingTimeUrl}/${mapId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getSchedulingTimeEndpoint(mapId)));
      }));
  }
  geSchedulingByIdEndpoint<T>(SchedulingId: string): Observable<T> {
    let endpointUrl = `${this._schedulinglUrl}/${SchedulingId}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.geSchedulingByIdEndpoint(SchedulingId)));
      }));
  }
  getScheduleIntervalByIdEndpoint<T>(SchedulingIntervalId: string): Observable<T> {
    let endpointUrl = `${this._schedulinglIntervalUrl}/${SchedulingIntervalId}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getScheduleIntervalByIdEndpoint(SchedulingIntervalId)));
      }));
  }

  getScheduleIntervalBySchedulingIdEndpoint<T>(SchedulingId: string): Observable<T> {
    let endpointUrl = `${this._schedulinglIntervalBySchedulingIdUrl}/${SchedulingId}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getScheduleIntervalBySchedulingIdEndpoint(SchedulingId)));
      }));
  }
  getSchedulingOfDateEndpoint<T>(hospmapid: string): Observable<T> {
    let endpointUrl = `${this._schedulingShowUrl}/ ${hospmapid}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getSchedulingOfDateEndpoint(hospmapid)));
      }));
  }
  postSchedulingEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this._schedulingPostUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.postSchedulingEndpoint(userObject)));
      }));
  }
  addSchedulingEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this._schedulingAddUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.addSchedulingEndpoint(userObject)));
      }));
  }

  getUpdateSchedulingEndpoint<T>(userObject: any, SchedulingId?: string): Observable<T> {
    let endpointUrl = SchedulingId ? `${this._schedulingUpdateUrl}/${SchedulingId}` : this._schedulingUpdateUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getUpdateSchedulingEndpoint(userObject, SchedulingId)));
      }));
  }
  getTimeVisitList<T>(visitDate: string): Observable<T> {
    let endpointUrl = `${this._schedulingVisitTimeUrl}/${visitDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getTimeVisitList(visitDate)));
      }));
  }
  getVisitTimeList<T>(visitDate: string, docId?: string, deptId?: string, hosId?: string): Observable<T> {
    let endpointUrl = `${this._schedulingVisitTimeListUrl}/${visitDate}/${docId}/${deptId}/${hosId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getVisitTimeList(visitDate, deptId, docId, hosId)));
      }));
  }

  deleteScheduleEndpoint<T>(SchedulingId: string): Observable<T> {
    let endpointUrl = `${this._schedulingdeleteUrl}/${SchedulingId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.deleteScheduleEndpoint(SchedulingId)));
      }));
  }
  postRescheduleEndpoint<T>(userObject: any, id?: string, id1?: string, id2?: string): Observable<T> {
    // let endpointUrl = id || id1 || id2 ?    : `${this._rescheduleUrl}/${0}/${0}/${id2}`;
    let endpointUrl = `${this._rescheduleUrl}/${id}/${id1}/${id2}`;
    return this.http.post<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.postRescheduleEndpoint(userObject, id, id1, id2)));
      }));
  }

  checkExistingAppointmentEndpoint<T>(SchedulingId: string): Observable<T> {
    let endpointUrl = `${this._checkForExistingAppointmentUrl}/${SchedulingId}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.checkExistingAppointmentEndpoint(SchedulingId)));
      }));
  }

  deleteVisitEndpoint<T>(visitingId: string): Observable<T> {
    let endpointUrl = `${this._deleteVisitUrl}/${visitingId}`;
    return this.http.put<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.deleteVisitEndpoint(visitingId)));
      }));
  }
}
