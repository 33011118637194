import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Global } from '../../../app.global';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { PatientService } from '../../../services/patient/patient.service';
import { AlertService } from '../../../services/alert.service';
import * as moment from 'moment';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { Patient } from '../../../models/patient/patient.model';
import { PatientUpdateProfileComponent } from '../../../Patient/patient_updateprofile/app.patient_updateprofile';
import { PasswordComponent } from '../../common/password/password.component';

@Component({
  templateUrl: './patient_list.component.html',
  styleUrls: ['./patient_list.component.css']
})
export class AdminPatientListComponent implements AfterViewInit {
  config: any;
  token: any;
  public searchText: any;
  patientList: Array<any> = new Array<any>();
  filteredPatientList: any;
  public editModal: boolean;
  collection = { count: 60, data: [] };
  p: number = 1;
  @ViewChild('patientEditor', { static: true })
  patientEditor: PatientUpdateProfileComponent;
  editedPatient: Patient;
  @ViewChild('pwdEditor', { static: true })
  pwdEditor: PasswordComponent;
  editedPwdPatient: Patient;
  isPatEditor = false;
  hospital: string;
  patientName: string;
  public pwdModal: boolean;
  public showVisitTypePopup: boolean = false;
  constructor(public routing: Router, public patService: PatientService, public authService: AuthenticationService, public alertService: AlertService,
    public global: Global, public notifyService: NotificationService, public dialog: MatDialog,) {
    this.token = this.authService.currentUserValue;
    this.showPatientList();
    this.editModal = false;
    this.pwdModal = false;
    this.hospital = this.token.HospitalIdentifier;
  }
  pageChanged(event) {
    this.config.currentPage = event;
  }
  ngAfterViewInit() {

    this.patientEditor.changesSavedCallback = () => {
      this.editModal = false;
      this.showPatientList();
    };
    this.patientEditor.changesCancelledCallback = () => {
      this.editModal = false;
    };
    this.pwdEditor.changesSavedCallback = () => {
      this.pwdModal = false;
      this.showPatientList();
    };
    this.pwdEditor.changesCancelledCallback = () => {
      this.pwdModal = false;
    };

  }

  public showPatientList() {
    this.patService.getAllPatient().subscribe((res: any) => {
      this.patientList = Object.assign(this.patientList, res?.Results);
      for (let pat of this.patientList) {
        if (pat.DateOfBirth == null)
          pat.DateOfBirth = "";
        else
          pat.DateOfBirth = moment(pat.DateOfBirth).format('L');
      }
    },


      error => {
        this.alertService.error(error);
      });

  }

  UpdateProfile(row: Patient) {
    this.isPatEditor = true;
    this.editedPatient = this.patientEditor.editAdminPatient(row, this.isPatEditor, this.hospital);
    this.editModal = true;
  }
  ResetPassword(row: Patient) {
    this.patientName = `${row.FirstName + " " + row.MiddleName + " " + row.LastName}`;
    this.editedPwdPatient = this.pwdEditor.resetPwd(row, this.patientName);
    this.pwdModal = true;
  }
  hide() {
    this.editModal = false;
  }
  RegPatient() {
    this.routing.navigate(['register'], { queryParams: { id: "patientregister", hospital: this.hospital } });
  }
  BookAppointment(data) {
    this.patService.PatientId = data.PatientId;
    this.showVisitTypePopup = true;
  }

  CloseVisittypepopup() {
    this.showVisitTypePopup = false;
  }

  SetOnlineAppointment() {
    this.patService.VisitType = "Online Appointment";
    this.routing.navigate(['/HospitalList'], { queryParams: { routefrom: "admin", VisitType: "Online Appointment" } });
  }

  SetTelemedicineAppointment() {
    this.patService.VisitType = "Telemedicine";
    this.routing.navigate(['/HospitalList'], { queryParams: { routefrom: "admin", VisitType: "Telemedicine" } });
  }

}
