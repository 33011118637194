
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EndpointFactory } from '../endpoint-factory.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PatientEndpoint extends EndpointFactory {

  private readonly _problemPostForOnlineAppointmentUrl: string = "/api/patient/PostProblemForOnlineAppointment";
  private readonly _problemPostProblemForTelemedicineUrl: string = "/api/patient/PostProblemForTelemedicine";
  private readonly _patientListUrl: string = "/api/patient/listPatientByProviderId";//--
  private readonly _patientStatusUrl: string = "/api/patient/patientStatus";//--
  private readonly _patientStatusByPatIdUrl: string = "/api/patient/patientStatusByPatId";//--
  private readonly _patientStatusByPatientUrl: string = "/api/patient/patientStatusByPatientUrl";
  private readonly _patientHistoryUrl: string = "/api/patient/patientHistory";//--
  private readonly _patientStatusChangeUrl: string = "/api/patient/patientStatusChange";
  private readonly _patientStatusChangefromDocUrl: string = "/api/patient/patientStatusChangefomDoc";//--
  private readonly _patientLoginUrl: string = "/api/patient/login";
  private readonly _patientRegisterUrl: string = "/api/account/registerPatient";//--
  private readonly _patientTreatmentAdviceUpdateUrl: string = "/api/patient/updateTreatmentAdvice";//
  private readonly _updateStatusUrl: string = "/api/patient/updateStatus";
  private readonly _updateVisitStatusUrl: string = "/api/patient/updateVisitStatus";
  private readonly _patientUpdateUrl: string = "/api/patient/updatePatient";
  private readonly _patientDetailsUrl: string = "/api/patient/getDetails";
  private readonly _patientDetailsUrlByVisitId: string = "/api/patient/getDetailsByVisitId";//--
  private readonly _patientFinalReportByVisitId: string = "/api/patient/getFinalReportData";//--
  private readonly _patientDocumentsByVisitId: string = "/api/patient/getPatientDocuments";
  private readonly _patientDeleteUrl: string = "/api/patient/deletePatient";
  private readonly _updatePaidStatusUrl: string = "/api/patient/updatePaidStatus";//--
  private readonly _updatePaymentStatusUrl: string = "/api/patient/updatePaymentStatus";
  private readonly _cancelBookingUrl: string = "/api/patient/cancelBooking";//--not exist
  private readonly _paymentChargeUrl: string = "/api/patient/paymentChargeByVisitId";
  private readonly _patientStatusByVisitIdUrl: string = "/api/patient/patientStatusByVisitId";//--not exist
  private readonly _patHistoryUrl: string = "/api/patient/viewPrescription";//--
  private readonly _patientAllPatientListUrl: string = "/api/patient/adminPatient";//--not in use
  private readonly _postVisitSingleton: string = "/api/patient/postVisitToSingleton";//--
  private readonly _getVisitFromWaitingRoomUrl: string = "/api/patient/getVisitFromWaitingRoom";//--
  private readonly _getVisitFromDoctorRoomUrl: string = "/api/patient/getVisitFromDoctorRoom";//--
  private readonly _getPatientInDoctorRoomUrl: string = "/api/patient/patientsInDoctorRoom";//--
  private readonly _removeVisitIdFromLogoutUrl: string = "/api/patient/removeLogOutVisitId";//--//--not in use
  private readonly _backToDashboardfromDocUrl: string = "/api/patient/backToDashboardfromDoc";//--
  private readonly _postJitsiRoomNameUrl: string = "/api/patient/postJitsiRoomNameUrl";//--
  private readonly _addFamilyListUrl: string = "/api/patient/addFamilyList";
  private readonly _getFamilyListUrl: string = "/api/patient/getFamilyList";
  private readonly _updateFamilyListUrl: string = "/api/patient/updateFamilyList";
  private readonly _deleteFamilyListUrl: string = "/api/patient/deleteFamilyList";
  private readonly _addPatMedicalRecordUrl: string = "/api/patient/addPatMedicalRecord";
  private readonly _getPatMedicalRecordsUrl: string = "/api/patient/getPatMedicalRecords";
  private readonly _patOPSummaryUrl: string = "/api/patient/getOPsummary";
  private readonly _followUpPostProblemUrl: string = "/api/patient/followUpPostProblem";
  private readonly _postOPSummaryUrl: string = "/api/patient/addOPSummary";
  private readonly _deletePatMedicalRecordUrl: string = "/api/patient/deletePatMedicalRecord";
  private readonly _getMissedVisitUrl: string = "/api/patient/getAllMissedVisit";
  private readonly _getZoomSignature: string = "/api/zoomFeature";

  private readonly _AdminIdUrl: string = "/api/patient/getAdmin";
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector);
  }

  getPatientByIdEndpoint<T>(PatientId: string, userType: string,): Observable<T> {
    let endpointUrl = `${this._patientDetailsUrl}/${PatientId}?userType=${userType}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientByIdEndpoint(PatientId,userType)));
      }));
  }
  getPatientByVisitIdEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._patientDetailsUrlByVisitId}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientByVisitIdEndpoint(VisitId)));
      }));
  }
  t
  getFinalReportDataEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._patientFinalReportByVisitId}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getFinalReportDataEndpoint(VisitId)));
      }));
  }
  getPatientDocumentsEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._patientDocumentsByVisitId}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientDocumentsEndpoint(VisitId)));
      }));
  }
  getPatientListEndpoint<T>(DoctorId: string): Observable<T> {
    let endpointUrl = `${this._patientListUrl}/${DoctorId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientListEndpoint(DoctorId)));
      }));
  }

  getPatientHistoryEndpoint<T>(PatientId: string): Observable<T> {
    let endpointUrl = `${this._patientHistoryUrl}/${PatientId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientHistoryEndpoint(PatientId)));
      }));
  }
  getPatHistoryEndpoint<T>(PatientId: string): Observable<T> {
    let endpointUrl = `${this._patHistoryUrl}/${PatientId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatHistoryEndpoint(PatientId)));
      }));
  }
  getAllPatientEndPoint<T>(): Observable<T> {
    let endpointUrl = `${this._patientAllPatientListUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getAllPatientEndPoint()));
      }));
  }
  getPatientBookingListEndpoint<T>(PatientId: string): Observable<T> {
    let endpointUrl = `${this._patientHistoryUrl}/${PatientId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientBookingListEndpoint(PatientId)));
      }));
  }

  changeStatusEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._patientStatusChangeUrl}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.changeStatusEndpoint(VisitId)));
      }));
  }

  changeStatusFromDocEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._patientStatusChangefromDocUrl}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.changeStatusFromDocEndpoint(VisitId)));
      }));
  }
  getPatientDocListEndpoint<T>(DoctorId: string): Observable<T> {
    let endpointUrl = `${this._patientListUrl}/${DoctorId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientDocListEndpoint(DoctorId)));
      }));
  }
  getStatusEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._patientStatusUrl}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getStatusEndpoint(VisitId)));
      }));
  }

  getStatusByPatientIdEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._patientStatusByPatIdUrl}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getStatusByPatientIdEndpoint(VisitId)));
      }));
  }
  getStatusByPatientUrlEndpoint<T>(PatientId: string): Observable<T> {
    let endpointUrl = `${this._patientStatusByPatientUrl}/${PatientId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getStatusByPatientUrlEndpoint(PatientId)));
      }));
  }

  getStatusByVisitIdEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._patientStatusByVisitIdUrl}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getStatusByVisitIdEndpoint(VisitId)));
      }));
  }


  getAdminIdEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this._AdminIdUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getAdminIdEndpoint()));
      }));
  }

  PostJitsiRoomNameEndPoint<T>(VisitId: string, roomname): Observable<T> {
    let endpointUrl = `${this._postJitsiRoomNameUrl}/${VisitId}/${roomname}`

    return this.http.put<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.PostJitsiRoomNameEndPoint(VisitId, roomname)));
      }));
  }
  //postVisitEndpoint<T>(userObject: any, formToPost): Observable<T> {

  //  return this.http.post<T>(this._problemPostUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
  //    catchError(error => {
  //      return throwError(this.handleError(error, () => this.postVisitEndpoint(userObject, formToPost)));
  //    }));
  //}
  postVisitEndpoint<T>(userObject: any, ScheduleIntervalId?: string, SchedulingId?: string): Observable<T> {
    let endpointUrl = ScheduleIntervalId && SchedulingId ? `${this._problemPostProblemForTelemedicineUrl}/${SchedulingId}/${ScheduleIntervalId}` : `${this._problemPostForOnlineAppointmentUrl}/${SchedulingId}`;
    return this.http.post<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.postVisitEndpoint(userObject, SchedulingId, ScheduleIntervalId)));
      }));
  }

  getUpdatePatientEndpoint<T>(userObject: any, PatientId?: string): Observable<T> {
    let endpointUrl = PatientId ? `${this._patientUpdateUrl}/${PatientId}` : this._patientUpdateUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getUpdatePatientEndpoint(userObject, PatientId)));
      }));
  }
  updateTreatmentAdviceEndpoint<T>(userObject: any, VisitId?: string): Observable<T> {
    let endpointUrl = VisitId ? `${this._patientTreatmentAdviceUpdateUrl}/${VisitId}` : this._patientTreatmentAdviceUpdateUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.updateTreatmentAdviceEndpoint(userObject, VisitId)));
      }));
  }

  updateStatusEndpoint<T>(userObject: any, VisitId?: string): Observable<T> {

    // let endpointUrl = visitId ? `${this._updateStatusUrl}/${visitId}` : this._patientTreatmentAdviceUpdateUrl;
    let endpointUrl =`${this._updateStatusUrl}`; 
    userObject.VisitId = userObject.Results.VisitId;
    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.updateStatusEndpoint(userObject, VisitId)));
      }));
  }
  updateVisitStatusEndpoint<T>(userObject: any, VisitId?: string): Observable<T> {
    let endpointUrl = VisitId ? `${this._updateVisitStatusUrl}/${VisitId}` : this._patientTreatmentAdviceUpdateUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.updateStatusEndpoint(userObject, VisitId)));
      }));
  }

  updatePaidStatusEndpoint<T>(VisitId?: string): Observable<T> {
    let endpointUrl = VisitId ? `${this._updatePaidStatusUrl}/${VisitId}` : this._updatePaidStatusUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(VisitId), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.updatePaidStatusEndpoint(VisitId)));
      }));
  }

  updatePaymentStatusEndpoint<T>(userObject: any, VisitId?: string): Observable<T> {
    let endpointUrl = VisitId ? `${this._updatePaymentStatusUrl}/${VisitId}` : this._updatePaymentStatusUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.updatePaymentStatusEndpoint(userObject, VisitId)));
      }));
  }

  cancelBookingEndpoint<T>(userObject: any, VisitId?: string): Observable<T> {
    let endpointUrl = VisitId ? `${this._cancelBookingUrl}/${VisitId}` : this._cancelBookingUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.cancelBookingEndpoint(userObject, VisitId)));
      }));
  }
  registerPatientEndpoint<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this._patientRegisterUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.registerPatientEndpoint(userObject)));
      }));
  }
  getPaymentChargeEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._paymentChargeUrl}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPaymentChargeEndpoint(VisitId)));
      }));
  }

  postVisitSingletonEndpoint<T>(VisitId?: string): Observable<T> {

    return this.http.post<T>(`${this._postVisitSingleton}/${VisitId}`, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.postVisitSingletonEndpoint(VisitId)));
      }));
  }

  getVisitFromWaitingRoomEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._getVisitFromWaitingRoomUrl}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getVisitFromWaitingRoomEndpoint(VisitId)));
      }));
  }
  getVisitFromDoctorRoomEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._getVisitFromDoctorRoomUrl}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getVisitFromDoctorRoomEndpoint(VisitId)));
      }));
  }
  getPatientsInDoctorRoomEndpoint<T>(DoctorId: string): Observable<T> {
    let endpointUrl = `${this._getPatientInDoctorRoomUrl}/${DoctorId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientsInDoctorRoomEndpoint(DoctorId)));
      }));
  }
  removeVisitIdFromLogoutEndpoint<T>(VisitId: string): Observable<T> {
    let endpointUrl = `${this._removeVisitIdFromLogoutUrl}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.removeVisitIdFromLogoutEndpoint(VisitId)));
      }));
  }

  backToDashBoardFromDocEndpoint<T>(DoctorId: string): Observable<T> {
    let endpointUrl = `${this._backToDashboardfromDocUrl}/${DoctorId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.backToDashBoardFromDocEndpoint(DoctorId)));
      }));
  }

  addFamilyListEndpoint<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this._addFamilyListUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.addFamilyListEndpoint(userObject)));
      }));
  }

  getFamilyListEndpoint<T>(PatientId: string): Observable<T> {
    let endpointUrl = `${this._getFamilyListUrl}/${PatientId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getFamilyListEndpoint(PatientId)));
      }));
  }

  updateFamilyListEndpoint<T>(userObject: any): Observable<T> {

    return this.http.put<T>(this._updateFamilyListUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getUpdatePatientEndpoint(userObject)));
      }));
  }

  deleteFamilyListEndpoint<T>(FamilyMemberId: string): Observable<T> {
    let endpointUrl = `${this._deleteFamilyListUrl}/${FamilyMemberId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.deleteFamilyListEndpoint(FamilyMemberId)));
      }));
  }

  addPatMedicalRecordEndpoint<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this._addPatMedicalRecordUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.addPatMedicalRecordEndpoint(userObject)));
      }));
  }

  getPatMedicalRecordsEndpoint<T>(PatientId: string): Observable<T> {
    let endpointUrl = `${this._getPatMedicalRecordsUrl}/${PatientId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatMedicalRecordsEndpoint(PatientId)));
      }));
  }

  getOPSummaryEndpoint<T>(PatientId: string, VisitId: string): Observable<T> {
    let endpointUrl = `${this._patOPSummaryUrl}/${PatientId}/${VisitId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getOPSummaryEndpoint(PatientId, VisitId)));
      }));
  }

  followUpPostVisitEndpoint<T>(userObject: any, ScheduleIntervalId?: string, SchedulingId?: string): Observable<T> {
    let endpointUrl = ScheduleIntervalId && SchedulingId ? `${this._followUpPostProblemUrl}/${ScheduleIntervalId}/${SchedulingId}` : this._followUpPostProblemUrl;
    return this.http.post<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.followUpPostVisitEndpoint(userObject, ScheduleIntervalId, SchedulingId)));
      }));
  }


  addOPSummaryEndpoint<T>(userObject: any, VisitId: string): Observable<T> {
    let endpointUrl = `${this._postOPSummaryUrl}/${VisitId}`;
    return this.http.post<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.addOPSummaryEndpoint(userObject, VisitId)));
      }));
  }

  deletePatMedicalRecordEndpoint<T>(MedicalRecordId: string): Observable<T> {
    let endpointUrl = `${this._deletePatMedicalRecordUrl}/${MedicalRecordId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.deletePatMedicalRecordEndpoint(MedicalRecordId)));
      }));
  }
  getMissedVisitEndpoint() {
    let endpointUrl = `${this._getMissedVisitUrl}`;
    return this.http.get(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getMissedVisitEndpoint()));
      }));
  }

  getZoomSignatureEndPoint(doctorId: string) {
    let endpointUrl = `${this._getZoomSignature}/GenerateSignature/${doctorId}`;
    return this.http.get(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getZoomSignatureEndPoint(doctorId)));
      }));
  }
}
