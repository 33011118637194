import { Visit } from '../visit/visit.model';
import { Problem } from '../../models/problem/problem.model';

export class Patient {

  constructor(patientId?: string, registrationNumber?: string, firstName?: string, middleName?: string, lastName?: string, dateOfBirth?: string, mailingAddress?: string, gender?: string, contactNumber?: string, address?: string,
    isActive?: boolean, identityUserId?: string, visit?: Array<Visit>, problem?: Array<Problem>, createddate?: Date, FullName?: string,
    AdminId?: string, HospitalIdentifier?: string, Password?: string, OldPassword?: string, ConfirmPassword?: string, age?: string, bloodGroup?: string) {
    this.PatientId = patientId;
    this.RegistrationNumber = registrationNumber;
    this.FirstName = firstName;
    this.MiddleName = middleName;
    this.LastName = lastName;
    this.DateOfBirth = dateOfBirth;
    this.MailingAddress = mailingAddress;
    this.Gender = gender;
    this.ContactNumber = contactNumber;
    this.Address = address;
    this.IsActive = isActive;
    this.IdentityUserId = identityUserId;
    this.Visit = visit;
    this.CreatedDate = createddate;
    this.FullName = FullName;
    this.AdminId = AdminId;
    this.HospitalIdentifier = HospitalIdentifier;
    this.Password = Password;
    this.OldPassword = OldPassword;
    this.ConfirmPassword = ConfirmPassword;
    this.Age = age;
    this.BloodGroup = bloodGroup;
  }


  public PatientId: string;
  public RegistrationNumber: string;
  public FirstName: string;
  public MiddleName: string;
  public LastName: string;
  public DateOfBirth: string;
  public MailingAddress: string;
  public Gender: string;
  public ContactNumber: string;
  public Address: string;
  public IsActive: boolean;
  public IdentityUserId: string;
  public CreatedDate: Date;
  public Visit: Array<Visit> = [];
  public Problem: Array<Problem> = [];
  public FullName: string;
  public AdminId: string;
  public HospitalIdentifier: string;
  public Password: string;
  public OldPassword: string;
  public ConfirmPassword: string;
  public Age: string;
  public BloodGroup: string;
}

export class PatientModel {
  public PatientId: string;
  public RegistrationNumber: number;
  public FirstName: string;
  public MiddleName: string;
  public LastName: string;
  public DateOfBirth: Date;
  public Gender: string;
  public MailingAddress: string;
  public ContactNumber: string;
  public Address: string;
  public IsActive: boolean;
  public VisitId: string;
  public hospitalId: number;
  public IdentityUserId: string;
  public Visit: Array<Visit> = [];
  public Problem: Problem = new Problem();
  public Age: string;
  public BloodGroup: string;
}
export class PatientDoc {
  PatientId: string;
  PatientName: string = "";
  Age: number;
  Sex: string = "";
  Email: string = "";
  VisitId: string;
  MobileNumber: string = "";
  Address: string = "";
  //prob: Array<Problem> = [];
  Fever: boolean = false;
  Cough: boolean = false;
  BreathingDifficulty: boolean = false;
  Tiredness: boolean = false;
  SoreThroat: boolean = false;
  Bodyache: boolean = false;
  ChestPain: boolean = false;
  IdentityUserId: string = "";
  Diarrhea: boolean = false;
  AnyOtherSymptoms: string = "";
  NMC: string = "";

  HeartDisease: boolean = false;
  HighBloodPressure: boolean = false;
  Diabetes: boolean = false;
  Copd: boolean = false;
  Transplant: boolean = false;
  RecentTravel: boolean = false;
  Cancer: boolean = false;
  Exposure: boolean = false;
  PatientMedicalSymptoms: string = "";
  OtherPertientInformation: string = "";
  Status: number = 0;
  Medication: string = "";
  TreatmentAdvice: string = "";
  FollowUp: string = "";
  LastUpdatedTime: Date;
  VisitType: string = "";
}
export class Paidbooking {
  public PatientId: string;
  public DoctorName: string;
  public VisitId: string;
  public VisitDate: Date;
  public VisitStartTime: Date;
  public Status: string;
  public DepartmentName: string;
  public HospitalName: string;
  public ContactNumber: string;
  public Address: string;
  public IsActive: boolean;
  Fever: boolean = false;
  Cough: boolean = false;
  BreathingDifficulty: boolean = false;
  Tiredness: boolean = false;
  SoreThroat: boolean = false;
  Bodyache: boolean = false;
  ChestPain: boolean = false;
  Diarrhea: boolean = false;
  AnyOtherSymptoms: string = "";

  HeartDisease: boolean = false;
  HighBloodPressure: boolean = false;
  Diabetes: boolean = false;
  Copd: boolean = false;
  Transplant: boolean = false;
  RecentTravel: boolean = false;
  Cancer: boolean = false;
  Exposure: boolean = false;
}













