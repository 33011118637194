import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ProductModel } from "src/app/models/Product/product.model";
import { ProductPicture } from "src/app/models/Product/ProductPicture.model";
import { HospitalService } from "../hospital/hospital.service";
import { ProductEndPoint } from "./product-endpoint.service";

@Injectable()

export class ProductService {
  public hospitalList: Array<any> = new Array<any>();
  public ProductTagsList: Array<any> = new Array<any>();
  public ProductTypesList: Array<any> = new Array<any>();
  constructor(
    private productEndPoint: ProductEndPoint,
    private hospitalService: HospitalService) {

  }
  public _productId: number;
  get ProductId(): number {
    return this._productId;
  }
  set ProductId(ProductId: number) {
    this._productId = ProductId;
  }
  //   getPatient(PatientId?: string) {
  //     return this.patientEndPoint.getPatientByIdEndpoint<Patient>(PatientId);
  //   }

  public getProduct(ProductId) {
    return this.productEndPoint.getProductByIdEndpoint<ProductModel>(ProductId);
  }
  getAllProducts() {
    return this.productEndPoint.getAllProducts<ProductModel>();
  }

  public AddProduct(formData: FormData) {
    return this.productEndPoint.AddProduct<FormData>(formData);

  }
  public AddProductType(formData: FormData) {
    return this.productEndPoint.AddProductType<FormData>(formData);

  }
  public AddProductTag(formData: FormData) {
    return this.productEndPoint.AddProductTag<FormData>(formData);

  }
  public getProductImage(productImageId) {
    return this.productEndPoint.getProductImageByIdEndpoint<ProductPicture>(productImageId);
  }

  public getAllProductTypes() {
    return this.productEndPoint.getAllProductTypes<ProductModel>();
  }

  public getAllProductTags() {
    return this.productEndPoint.getAllProductTags<ProductModel>();
  }

  public updateProduct(formData: FormData, productId) {
    return this.productEndPoint.updateProductEndPoint<FormData>(formData, productId);
  }

  public activateDeactivateProductEndpoint(productId, AdminId) {
    return this.productEndPoint.activateDeactivateProductEndpoint<ProductModel>(productId, AdminId);
  }


  public GetAllHospital() {
    this.hospitalService.getHospitalList().subscribe(
      res => {
        this.hospitalList = Object.assign(this.hospitalList, res);
      },
      err => {
        alert(err);
      });

  }
  public GetAllProductTags() {
    this.getAllProductTags().subscribe(
      res => {
        if (res) {
          this.ProductTagsList = Object.assign(this.ProductTagsList, res);
        }
      },
      err => {
        alert(err);
      }
    );
  }

  public GetAllProductTypes() {
    this.getAllProductTypes().subscribe(
      res => {
        if (res) {
          this.ProductTypesList = Object.assign(this.ProductTypesList, res);
        }

      },
      err => {
        alert(err);
      }
    );
  }


}
