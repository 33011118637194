import { Component, OnInit, Inject } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../app.global';
import { DoctorService } from '../../services/doctor/doctor.service';
import { Doctor, DoctorList } from '../../models/doctor/doctor.model';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { AlertService } from '../../services/alert.service';
// import { ThrowStmt } from '@angular/compiler';
import { Observable, concat } from 'rxjs'
import * as moment from 'moment/moment';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AuthenticationService } from '../../services/authentication.service';
import { Departement } from '../../models/Department/app.department.model';
import { DatePipe } from '@angular/common'
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { HospitalService } from '../../services/hospital/hospital.service';
import { Hospital } from '../../models/hospital/hospital.model';
import { ɵAnimationGroupPlayer } from '@angular/animations';
import { HospitalDoctorMap } from '../../models/HospitalDoctorMap/app.hospitaldoctormap.model';
import { Qualification } from '../../models/Qualification/qualification.model';
import { Visit } from 'src/app/models/visit/visit.model';
import { SchedulingService } from 'src/app/services/scheduling/scheduling.service';
import { VisitDate, SchedulingList } from 'src/app/models/scheduling/scheduling.model';
import { PatientService } from '../../services/patient/patient.service';
@Component({
  selector: 'doctor-profile',
  templateUrl: './app.doctor_profile.html'
})
export class DoctorProfileComponent implements OnInit {
  public docInfo: Doctor = new Doctor();
  public departmentId: any;
  public filterDocList: any;
  public selDepartment: any = "";
  public count: number = 0;
  public docHospitalList: Array<any>;
  public HospitalList: Array<any>;
  public dept: Departement = new Departement();
  visitObj: Visit = new Visit();
  public showDept: boolean = false;
  public showUnavailableMsg: boolean = false;
  appointmentTime: Array<any> = new Array<any>();
  scheduleIntervalList: Array<any> = new Array<any>();
  // VisitDate[] = [];
  public deptList: any[];
  public filterdeptList: Array<any> = new Array<any>();
  public filteredDepartmentList: Array<any> = new Array<any>();
  public docList: Array<Doctor> = new Array<Doctor>();
  public showScheduleList: boolean = false;
  loading = false;
  public doc: any;
  public token: any;
  public hospitalList: Array<any>;
  public DeptHostList: any;
  public selHospital: any;
  public VisitList: any;
  public hospitalId: any;
  public EnablePhNo: any;
  public showBookingDate: boolean = false;
  public showTimeSlot: boolean = false;
  public ScheduleTimeDetail: boolean = true;
  public showIntervalSlot: boolean = false;
  public showIntervalButton: boolean = false;
  public showBookingAppointBtn: boolean = false;
  public AppointmentDate: any = "";
  public AppointmentStartTime: any = "";
  public AppointmentEndTime: any = "";
  public doctorId: any;
  public patId: any;
  public rowCount: number = 0;
  public index: number = 0;
  public hospdocMap: Array<HospitalDoctorMap> = new Array<HospitalDoctorMap>();
  public doctorList: Array<any> = new Array<any>();
  public deptId: any;
  public mapId: any;
  DoctorName: any;
  FilePath: any;
  PhoneNumber: any;
  MailingAddress: any;
  todayDate: any;
  public currentDate = moment().format('MM-DD-YYYY');
  QualificationList: Array<any> = new Array<any>();
  SchedulingList: Array<SchedulingList> = new Array<SchedulingList>();
  filteredHospitalDoctorMapping: Array<any> = new Array<any>();
  tempArray: Array<any> = new Array<any>();
  filteredSchedulingList: Array<any> = new Array<any>();
  HospitalDoctorMapping: Array<any> = new Array<any>();
  ScheduleList: Array<any> = new Array<any>();
  showButton: boolean = false;
  VisitType: any;
  HospitalFormGroup: FormGroup;
  public DepartmentId : string;
  public isOnlineAppointment : boolean = false;
  constructor(public global: Global, public docservice: DoctorService, @Inject(MAT_DIALOG_DATA) public data: any, public alertService: AlertService, public route: ActivatedRoute,
    public router: Router, public httpClient: HttpClient, public patService: PatientService, private authenticationService: AuthenticationService, public schedulingService: SchedulingService,
    public http: HttpClient, private datepipe: DatePipe, private formBuilder: FormBuilder, private notifyService: NotificationService, public docService: DoctorService,
    public hospitalService: HospitalService) {
    this.todayDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.token = this.authenticationService.currentUserValue;
    this.doctorId = this.route.snapshot.queryParamMap.get('id1');
    this.DepartmentId = this.route.snapshot.queryParamMap.get('deptId');
    this.VisitType = this.route.snapshot.queryParamMap.get('VisitType');
    if (this.doctorId == "" || this.doctorId == null) {
      this.doctorId = this.docservice.DoctorId;
    }
    this.getDocHospitalList();
    this.checkForVisitType();
  }
  ngOnInit() {
    this.HospitalFormGroup = this.formBuilder.group({
      Department: [""]
    });
  }

  checkForVisitType(){
    if(this.VisitType === "Online Appointment"){
      this.isOnlineAppointment = true;
    }else{
      this.isOnlineAppointment = false;
    }
  }
  getDocHospitalList() {
    this.hospitalService.getDocHospitalList(this.doctorId)
      .subscribe(res => this.SuccessHospitalList(res),
        res => this.Error(res));
  }
  SuccessHospitalList(res) {
    this.hospitalList = Object.assign(res);
    if (this.data.hospitalId) {
      this.hospitalList = this.hospitalList.filter(s => s.HospitalId == this.data.hospitalId)
      this.selHospital = this.hospitalList[0].HospitalId;
      this.hospitalId = this.selHospital;
      this.showDept = true;
      this.GetDepartmentList();
    }
    if (this.hospitalList.length == 1) {
      this.QualificationList = this.hospitalList[0].Qualification;
      this.DoctorName = this.hospitalList[0].DoctorName;
      this.FilePath = this.hospitalList[0].FilePath;
      this.PhoneNumber = this.hospitalList[0].PhoneNumber;
      this.EnablePhNo = this.hospitalList[0].EnablePhNo;
      this.MailingAddress = this.hospitalList[0].MailingAddress;
      this.selHospital = this.hospitalList[0].HospitalId;
      this.hospitalId = this.selHospital;
      this.global.HospitalId = this.hospitalId;
      this.hospitalService.HospitalId = this.hospitalId;
      this.showDept = true;
      this.GetDepartmentList();
      //this.filteredSchedulingList = null;
    }
    else {
      this.QualificationList = this.hospitalList[0].Qualification;
      this.DoctorName = this.hospitalList[0].DoctorName;
      this.FilePath = this.hospitalList[0].FilePath;
      this.PhoneNumber = this.hospitalList[0].PhoneNumber;
      this.EnablePhNo = this.hospitalList[0].EnablePhNo;
      this.MailingAddress = this.hospitalList[0].MailingAddress;
      this.selHospital = "";
    }


  }
  AssignHospital(event: MatSelectChange) {
    //this.hospitalService.HospitalId = event.value;
    this.appointmentTime = [];
    this.visitObj.BookingTime = null;
    this.hospitalId = event.value;
    this.showDept = true;
    this.showTimeSlot = false;
    this.showScheduleList = false;
    this.visitObj.VisitDate = null;
    this.showUnavailableMsg = false;
    this.GetDepartmentList();

  }

  GetDoctorList() {
    this.docservice.getDoc(this.doctorId, this.hospitalId, this.VisitType)
      .subscribe(res => this.SuccessDoctor(res),
        res => this.Error(res));
  }
  SuccessDoctor(res) {
    if (res && res.IsSuccess && res.Results) {
      this.doctorList = res.Results;
      this.SchedulingList = res.Results.SchedulingList;
      this.tempArray = new Array<any>();
      this.tempArray = JSON.parse(JSON.stringify(this.SchedulingList));
      if (this.tempArray.length == 0) {
        this.showUnavailableMsg = true;
      }
      for (var y = 0; y < this.tempArray.length; y++) {
        var tempst = 0;
        tempst = +this.tempArray[y].StartTime.substr(0, 2);
        if (tempst < 12) {
          var stampm = "AM";
          var ST = tempst;
        }
        else {
          stampm = "PM";
          if (tempst == 12) {
            var ST = tempst;
          } else {
            ST = tempst - 12;
          }
        }
        this.tempArray[y].StartTime = ST + this.tempArray[y].StartTime.substr(2, 3) + " " + stampm;
        var tempet = 0;
        tempet = +this.tempArray[y].EndTime.substr(0, 2);
        if (tempet < 12) {
          var etampm = "AM";
          var ET = tempet;
        }
        else {
          etampm = "PM";
          if (tempet == 12) {
            var ET = tempet;
          } else {
            ET = tempet - 12;
          }
        }
        this.tempArray[y].EndTime = ET + this.tempArray[y].EndTime.substr(2, 3) + " " + etampm;
      }
      this.SchedulingList = this.tempArray;
      this.filteredSchedulingList = res.Results.SchedulingList;
      this.tempArray = new Array<any>();
      this.tempArray = JSON.parse(JSON.stringify(this.filteredSchedulingList));
      for (var y = 0; y < this.tempArray.length; y++) {
        var tempst = 0;
        tempst = +this.tempArray[y].StartTime.substr(0, 2);
        if (tempst < 12) {
          var stampm = "AM";
          var ST = tempst;
        }
        else {
          stampm = "PM";
          if (tempst == 12) {
            var ST = tempst;
          } else {
            ST = tempst - 12;
          }
        }
        this.tempArray[y].StartTime = ST + this.tempArray[y].StartTime.substr(2, 3) + " " + stampm;
        var tempet = 0;
        tempet = +this.tempArray[y].EndTime.substr(0, 2);
        if (tempet < 12) {
          var etampm = "AM";
          var ET = tempet;
        }
        else {
          etampm = "PM";
          if (tempet == 12) {
            var ET = tempet;
          } else {
            ET = tempet - 12;
          }
        }
        this.tempArray[y].EndTime = ET + this.tempArray[y].EndTime.substr(2, 3) + " " + etampm;
      }
      this.filteredSchedulingList = this.tempArray;
      this.HospitalDoctorMapping = res.Results.HospitalDoctorMapId;
      if(this.isOnlineAppointment){
        this.AssignDepartment(this.HospitalDoctorMapping); //Rohan: Sending filteredDepartmentList to the AssignDepartment function as filteredHospitalDoctorMapping was being empty first.
      }
    } else {
      this.alertService.error(res.ResponseMessage);
    }

  }
  Error(res) {

  }
  GetDepartmentList() {
    this.docservice.getDepartmentList().subscribe(res => {
      this.deptList = res;
      this.GetDocHosDetail();
    },
      error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }
  GetDocHosDetail() {
    this.hospitalService.getDocHospitalListbyvisitytpe(this.doctorId, this.VisitType)
      .subscribe(res => this.SuccessHospital(res),
        res => this.Error(res));
  }
  SuccessHospital(res) {
    if (res && res.IsSuccess && res.Results) {
      this.filterdeptList = [];
      this.count = 0;
      this.docHospitalList = Object.assign(res.Results);
      this.docHospitalList = this.docHospitalList.filter(s => s.HospitalId == this.hospitalId, s => s.ConsultationType == this.VisitType);
      // for (var i = 0; i < this.deptList.length; i++) {
      //   for (var j = 0; j < this.deptList.length; j++) {
      //     if (this.deptList[i].DepartmentId == this.docHospitalList[0].DepartmentId[j]) {
      //       this.filterdeptList[this.count] = this.deptList[i];
      //       this.count++;
      //     }
      //   }
      // }
      this.filterdeptList = this.docHospitalList;
  
      if (this.filterdeptList.length == 1 && this.isOnlineAppointment) {
        this.filteredDepartmentList = Object.assign(this.filterdeptList);
        this.selDepartment = this.filteredDepartmentList[0];
        this.setDepartmentName();
        this.mapId = this.selDepartment;
        this.deptId = this.filteredDepartmentList[0].DepartmentId;
        this.global.DepartmentId = this.deptId;
        this.hospitalService.DepartmentId = this.deptId;
        // this.showBookingDate = true;
        // this.showScheduleList = true;
        // this.appointmentType = this.filteredDepartmentList[0].AppointmentType;
        //this.filteredSchedulingList = null;
      }
      else {
        this.filteredDepartmentList = this.filterdeptList;
        // this.selDepartment = "";
        if(this.isOnlineAppointment){
          this.setDepartmentName();
        }
      }
      this.GetDoctorList();
    }else{
      this.alertService.error(res.ResponseMessage);
    }

  }
  AssignDepartment(event: any) {
    if(this.isOnlineAppointment){
      // console.log(this.filteredDepartmentList);
      this.showUnavailableMsg = false;
      // this.deptId = this.hospitalService.DepartmentId = event.value ? event.value.DepartmentId : null;
      this.deptId = event?.value;
      this.mapId = event.HospitalDoctorMapId;
      this.showBookingDate = true;
      this.showTimeSlot = false;
      this.showScheduleList = true;
      this.appointmentTime = [];
      this.visitObj.BookingTime = null;
      this.visitObj.VisitDate = null;
      this.showBookingAppointBtn = false;
      this.filteredHospitalDoctorMapping = new Array<any>();
      this.filteredHospitalDoctorMapping = JSON.parse(JSON.stringify(this.HospitalDoctorMapping));//deepcopy
      const departmentId = this.DepartmentId;
      this.filteredHospitalDoctorMapping = this.filteredHospitalDoctorMapping.filter(s => s.DepartmentId == departmentId);
      this.filteredSchedulingList = new Array<any>();
      this.filteredSchedulingList = JSON.parse(JSON.stringify(this.SchedulingList));//deepcopy
      this.filteredSchedulingList = this.filteredSchedulingList.filter(s => s.HospitalDoctorMapId == this.filteredHospitalDoctorMapping[0].HospitalDoctorMapId);
      if (this.filteredSchedulingList.length == 0) {
        this.showUnavailableMsg = true;
      }
    }
    //for (var y = 0; y < this.filteredSchedulingList.length; y++) {
    //  var tempst = 0;
    //   tempst = +this.filteredSchedulingList[y].StartTime.substr(0, 2);
    //  if (tempst < 12) {
    //    var stampm = "AM";
    //    var ST = tempst;
    //  }
    //  else
    //  {
    //    stampm = "PM";
    //    if (tempst == 12) {
    //      var ST = tempst;
    //    } else {
    //      ST = tempst - 12;
    //    }
    //  }
    //  this.filteredSchedulingList[y].StartTime = ST + this.filteredSchedulingList[y].StartTime.substr(2, 3) + " " + stampm;
    //  var tempet = 0;
    //  tempet = +this.filteredSchedulingList[y].EndTime.substr(0, 2);
    //  if (tempet < 12) {
    //    var etampm = "AM";
    //    var ET = tempet;
    //  }
    //  else {
    //    etampm = "PM";
    //    if (tempet == 12) {
    //      var ET = tempet;
    //    } else {
    //      ET = tempet - 12;
    //    }
    //  }
    //  this.filteredSchedulingList[y].EndTime = ET + this.filteredSchedulingList[y].EndTime.substr(2, 3) + " "+ etampm;
    //}
    //for (var b = 0; b < this.filteredSchedulingList.length; b++) {
    //  this.filteredSchedulingList[b].Date = moment(this.filteredSchedulingList[b].Date).format('YYYY-MM-DD');
    //  var todaydate = new Date();
    //  if (this.filteredSchedulingList[b].Date == moment(todaydate).format('YYYY-MM-DD')) {
    //    var currentdate1 = moment(todaydate).format('YYYY/MM/DD');
    //    var timeextract = this.filteredSchedulingList[b].EndTime;
    //    var bookedDateTime = moment(currentdate1 + ' ' + timeextract, 'YYYY/MM/DD HH:mm A');
    //    var formatedBookedDateTime = moment(bookedDateTime, 'YYYY-MM-DD HH:mm A');
    //    var currentTime = moment(todaydate).format('YYYY-MM-DD HH:mm A');
    //    var momentObj = moment(currentTime, 'YYYY-MM-DDLT');
    //    var currentdateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');
    //    var formatedCurrentTime = moment(currentdateTime, 'YYYY-MM-DD HH:mm A');
    //    if (formatedCurrentTime >= formatedBookedDateTime) {
    //      this.filteredSchedulingList.splice(b , 1);
    //    }
    //  }

    //}
  }
  
  AssignTeleDepartment(event: MatSelectChange){
    this.showUnavailableMsg = false;
      this.deptId = this.hospitalService.DepartmentId = event.value.DepartmentId;
      this.mapId = event.value.HospitalDoctorMapId;
      this.showBookingDate = true;
      this.showTimeSlot = false;
      this.showScheduleList = true;
      this.appointmentTime = [];
      this.visitObj.BookingTime = null;
      this.visitObj.VisitDate = null;
      this.showBookingAppointBtn = false;
      this.filteredHospitalDoctorMapping = new Array<any>();
      this.filteredHospitalDoctorMapping = JSON.parse(JSON.stringify(this.HospitalDoctorMapping));//deepcopy
      this.filteredHospitalDoctorMapping = this.filteredHospitalDoctorMapping.filter(s => s.HospitalDoctorMapId == event.value.HospitalDoctorMapId);
      this.filteredSchedulingList = new Array<any>();
      this.filteredSchedulingList = JSON.parse(JSON.stringify(this.SchedulingList));//deepcopy
      this.filteredSchedulingList = this.filteredSchedulingList.filter(s => s.HospitalDoctorMapId == this.filteredHospitalDoctorMapping[0].HospitalDoctorMapId);
      if (this.filteredSchedulingList.length == 0) {
        this.showUnavailableMsg = true;
      }
  }
  GetVisitTime(event) {
    if (this.VisitType != "Telemedicine") {
      this.ScheduleTimeDetail = false;
    }
    else {
      this.ScheduleTimeDetail = true;
    }
    this.showIntervalSlot = false;
    this.showIntervalButton = true;
    this.appointmentTime = [];
    this.visitObj.BookingTime = null;
    this.showBookingAppointBtn = false;
    // this.visitObj.VisitDate = null ;
    //var vDate = moment(event.value);
    var vDate = moment(this.visitObj.VisitDate);
    var visitDate = vDate.format('YYYY-MM-DD');
    if (visitDate != null) {
      this.schedulingService.getVisitTime(visitDate, this.doctorId, this.deptId, this.hospitalId).subscribe(res => this.SuccessAppointmentTime(res),
        res => this.ErrorAppointmentTime(res));
    }
  }
  SuccessAppointmentTime(res) {
    this.appointmentTime = Object.assign(this.appointmentTime, res);
    if (this.appointmentTime.length == 0) {
      this.notifyService.showWarning("Sorry", "There is no schedule for" + " " + "Dr." + " " + `${this.DoctorName}` + " " + "on" + " " + `${moment(this.visitObj.VisitDate).format('YYYY-MM-DD')}`);
      console.log("No Schedule");
      this.showTimeSlot = false;
    }
    if (this.doctorId != null && this.appointmentTime.length > 0) {
      if (this.VisitType != "Telemedicine") {
        this.showBookingAppointBtn = true;
        this.showTimeSlot = true
      }
      else {
        this.showTimeSlot = true;
      }
      this.global.VisitDate = this.appointmentTime[0].VisitDate;
      //this.appointmentTime = this.appointmentTime;
      //this.appointmentTime.filter(x => x.DoctorId == this.doctorId && x.DepartmentId == this.hospitalService.DepartmentId && x.HospitalId == this.hospitalId);
      // this.notifyService.showInfo("Please", "Select the time slot !");
    }
    else if (this.doctorId == null && this.appointmentTime.length > 0) {
      this.appointmentTime = this.appointmentTime.filter(x => x.DoctorId == this.doctorId && x.DepartmentId == this.selDepartment && x.HospitalId == this.hospitalId);
    }
    else {
      this.appointmentTime = [];
    }
  }
  ErrorAppointmentTime(res) {
    this.notifyService.showInfo("Sorry", "There is no schedule for" + " " + "Dr." + " " + `${this.DoctorName}` + " " + "on" + " " + `${moment(this.visitObj.VisitDate).format('YYYY-MM-DD')}`);
  }
  setAppontmentTime(time) {
    var date = new Date();
    var currenttime = moment(date, 'YYYY-MM-DDLT');
    if (time.StartTimeDetail > currenttime) {
      if (time.IsBooked == true) {
        this.ScheduleTimeDetail = true;
        var bookingtime = `${time.StartTime} - ${time.EndTime}`;
        this.notifyService.showWarning("The time slot " + bookingtime + " is already booked!", "Sorry");
      }
      else {
        this.ScheduleTimeDetail = false;
        this.patId = this.token.PatientIdentifier == undefined ? this.patService.PatientId : this.token.PatientIdentifier;
        this.patService.getPatientHistory(this.patId)
          .subscribe(res => this.PatientHistory(res, time),
            res => this.Error(res));
      }
    }
    else {
      this.notifyService.showWarning("You cannot book past Appointment Time ", "Sorry");
    }

  }
  PatientHistory(res, time) {
    this.VisitList = res;
    var count = 0;
    for (var i = 0; i < this.VisitList.length; i++) {
      if (this.VisitList[i].SchedulingId == time.SchedulingId) {
        count++;
      }
    }
    if (count == 0) {
      this.visitObj.BookingTime = `${time.StartTime} - ${time.EndTime}`;
      this.global.BookingTime = this.visitObj.BookingTime;
      this.global.VisitDate = this.visitObj.VisitDate;
      this.docservice.ScheduleIntervalId = time.ScheduleIntervalId;
      this.docservice.SchedulingId = time.SchedulingId;
      this.showBookingAppointBtn = true;
    } else {
      if (this.VisitType != "Telemedicine") {
        this.ScheduleTimeDetail = false;
      }
      else {
        this.ScheduleTimeDetail = true;
      }
      this.notifyService.showWarning("You can't select another schedule on same interval", "Sorry");
    }

  }
  checkTimeSlot(SchId) {
    this.visitObj.BookingTime = null;
    this.showBookingAppointBtn = false;
    if (this.showIntervalSlot == true) {
      this.scheduleIntervalList = [];
      this.showIntervalSlot = false;
    } else {
      this.schedulingService.getScheduleIntervalBySchedulingId(SchId).subscribe(res => this.SuccessScheduleInterval(res),
        res => this.Error(res));
    }
  }
  SuccessScheduleInterval(res) {
    if (res && res.IsSuccess && res.Results) {
      this.scheduleIntervalList = Object.assign(this.scheduleIntervalList, res?.Results);
      for (var c = 0; c < this.scheduleIntervalList.length; c++) {
        var date = moment(this.scheduleIntervalList[c].Date).format('YYYY/MM/DD');
        var timeextract = this.scheduleIntervalList[c].StartTime;
        var bookedStartTime = moment(date + ' ' + timeextract, 'YYYY/MM/DD HH:mm A');
        var momentObj = moment(bookedStartTime, 'YYYY-MM-DDLT');
        var currentdateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');
        var formatedBookedDateTime = moment(currentdateTime, 'YYYY-MM-DD HH:mm A');
        //var formatedBookedDateTime =moment(moment(bookedStartTime, 'YYYY-MM-DDTHH:mm:s')).format('lll');
        //var formatedBookedDateTime = moment(BookedDateTime); 
        this.scheduleIntervalList[c].StartTimeDetail = formatedBookedDateTime;
      }
      // console.log(this.scheduleIntervalList);
  
      for (let k = 0; k < this.scheduleIntervalList.length - 1; k++) {
        for (let l = 0; l < this.scheduleIntervalList.length - k - 1; l++) {
          var diffmin = this.scheduleIntervalList[l + 1].StartTimeDetail.diff((this.scheduleIntervalList[l].StartTimeDetail), 'minutes')
          if (diffmin < 0) {
            let swap = this.scheduleIntervalList[l];
            this.scheduleIntervalList[l] = this.scheduleIntervalList[l + 1];
            this.scheduleIntervalList[l + 1] = swap;
          }
        }
      }
  
      this.showIntervalSlot = true;
      // console.log(this.scheduleIntervalList);
    }else{
      this.alertService.error(res.ResponseMessage);
    }
   
  }
  SelectSchedule(data) {
    if (this.VisitType != "Telemedicine") {
      this.ScheduleTimeDetail = false;
      this.showBookingAppointBtn = true;
      this.docService.ScheduleIntervalId = null;
      this.docService.SchedulingId = data.SchedulingId;
    }
    else {
      this.ScheduleTimeDetail = true;
      this.showBookingAppointBtn = false;
    }
    this.showIntervalButton = false;
    this.showButton = true;
    this.visitObj.VisitDate = data.Date;
    this.scheduleIntervalList = [];
    if (this.showIntervalSlot == true) {
      this.showIntervalSlot = false
    }
    this.appointmentTime = [];
    this.visitObj.BookingTime = null;
    //this.visitObj.VisitDate = null ;
    this.appointmentTime.push({
      VisitDate: data.Date,
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      SchedulingId: data.SchedulingId,
    })
    if (this.VisitType == "Telemedicine") {
      this.checkTimeSlot(data.SchedulingId);
    }

    this.SuccessAppointmentTime(this.appointmentTime);
    //this.setAppontmentTime(time);
  }
  GoToBookAppointment() {
    if (this.appointmentTime.length > 0) {
     if(this.isOnlineAppointment){
      var bookingtime = `${this.appointmentTime[0].StartTime} - ${this.appointmentTime[0].EndTime}`;
      this.global.BookingTime = bookingtime;
      this.router.navigate(['/PatBookAppointment'], { queryParams: { id: this.hospitalId, id1: this.doctorId, dep: this.DepartmentId, status: "true" } });
     }else{
      this.router.navigate(['/PatBookAppointment'], { queryParams: { id: this.hospitalId, id1: this.doctorId, dep: this.deptId, status: "true" } });
     }
    }
    else {
      this.notifyService.showWarning("Sorry", "There is no schedule for" + " " + "Dr." + " " + `${this.DoctorName}` + " " + "on" + " " + `${moment(this.visitObj.VisitDate).format('YYYY-MM-DD')}` + "Please search another Date for Appointment");
    }

  }
  public BackDashBoard() {
    this.hospitalService.DepartmentId = null;
    this.docservice.DoctorId = null;
    this.global.BookingTime = "";
    this.global.VisitDate = null;
    this.visitObj.DepartmentId = null;
    this.global.DepartmentId = null;
    this.router.navigate(['/HospitalList'], { queryParams: { VisitType: this.VisitType } });
  }
  public BackBookAppointment() {
    window.location.reload();
    //this.router.navigate(['/PatDashboard']);
  }
  setDepartmentName(){
    this.HospitalFormGroup.get('Department').setValue(this.selDepartment);
    this.AssignDepartment({value: this.selDepartment.DepartmentId});
  }

}

