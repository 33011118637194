import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LabReportUploadEndpointService } from '../../../services/LabReportUpload/lab-report-upload-endpoint.service';
import { NotificationService } from '../../../services/notification.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-lab-report-upload',
  templateUrl: './lab-report-upload.component.html',
  styleUrls: ['./lab-report-upload.component.css']
})
export class LabReportUploadComponent implements OnInit {
  isPhoneNumberRegistered: boolean = null;
  fileUploadForm: FormGroup = new FormGroup(
    {
      phoneNumber: new FormControl("", [Validators.required, Validators.minLength(7), Validators.maxLength(15), Validators.pattern('^[0-9]*$')]),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      email: new FormControl("", Validators.email)
    }
  )
  loading: boolean;
  token: any;

  constructor(public router: Router, public labReportUploadEndpoint: LabReportUploadEndpointService, private authenticationService: AuthenticationService,
    public notifyService: NotificationService, public ref: ChangeDetectorRef) {
      this.token = this.authenticationService.currentUserValue;

     }

  ngOnInit() {
    this.configureFileUploadForm();
  }
  private configureFileUploadForm() {

    this.fileUploadForm = new FormGroup(
      {
        phoneNumber: new FormControl("", [Validators.required, Validators.minLength(7), Validators.maxLength(15), Validators.pattern('^[0-9]*$')]),
        firstName: new FormControl(""),
        lastName: new FormControl(""),
        email: new FormControl("", Validators.email)
      }
    );
    this.ref.detectChanges();

    this.fileUploadForm.get("firstName").disable();
    this.fileUploadForm.get("lastName").disable();
    this.fileUploadForm.get("email").disable();
    this.isPhoneNumberRegistered = null;

    this.fileUploadForm.get("phoneNumber").valueChanges.subscribe(newPhoneNumber => {

      this.fileUploadForm.get("firstName").disable();
      this.fileUploadForm.get("lastName").disable();
      this.fileUploadForm.get("email").disable();
      this.isPhoneNumberRegistered = null;

      if (this.fileUploadForm.get("phoneNumber").valid) {
        this.labReportUploadEndpoint.IsPhoneNumberRegistered<any>(newPhoneNumber).subscribe(res => {

          this.fileUploadForm.get("firstName").reset();
          this.fileUploadForm.get("lastName").reset();
          this.fileUploadForm.get("email").reset();

          if (res.isPhoneNumberRegistered) {
            this.isPhoneNumberRegistered = true;
            this.fileUploadForm.get("firstName").setValue(res.user.FirstName);
            this.fileUploadForm.get("lastName").setValue(res.user.LastName);
            this.fileUploadForm.get("email").setValue(res.user.Email);
          }
          else {
            this.isPhoneNumberRegistered = false;
            this.fileUploadForm.get("firstName").enable();
            this.fileUploadForm.get("lastName").enable();
            this.fileUploadForm.get("email").enable();
          }
        });
      }
    });
  }

  uploadFileToActivity() {

    this.fileUploadForm.markAllAsTouched();
    this.fileUploadForm.updateValueAndValidity();

    if (this.fileUploadForm.invalid) {
      this.notifyService.showError("Please fill the form correctly.", "Failed");
      return;
    }
    if (this.files.length == 0) {
      this.notifyService.showError("No file found. \nPlease select a file.", "Failed");
      return;
    }

    this.loading = true;

    this.fileUploadForm.get("firstName").enable();
    this.fileUploadForm.get("lastName").enable();
    this.fileUploadForm.get("email").enable();

    const formData: FormData = new FormData();

    for (let i = 0; i < this.files.length; i++) {
      formData.append("Files", this.files[i], this.files[i].name)
    }
    const token = this.authenticationService.currentUserValue;
    let AdminId: string;

    if (token) {
      AdminId = token.unique_name;
    }
    this.labReportUploadEndpoint.uploadFile<any>(this.fileUploadForm.value, formData,AdminId).subscribe(data => {
      this.configureFileUploadForm();

      this.files = [];
      this.notifyService.showSuccess(`Your files has been uploaded successfully.
                                      Sms Alert is ${data.isSmsSent ? '' : 'not '} sent.
                                      Email Alert is ${data.isEmailSent ? '' : 'not '} sent.`
        , "Success");
      this.loading = false;
    }, error => {
      this.notifyService.showError("Failed to upload the files. Please check console for more detail.", "Failed")
      console.log(error);
      this.loading = false;
    });
  }

  public backToAdminPage() {
    this.router.navigate(['/admin']);
  }




  @ViewChild("fileDropRef") fileDropEl: ElementRef;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 20);
      }
    }, 200);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    var acceptedFileTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
    var unsupportedFileFound = false;
    for (const item of files) {
      if (!acceptedFileTypes.includes(item.type)) {
        unsupportedFileFound = true;
        continue;
      }
      this.files.push(item);
      item.progress = 0;
    }
    if (unsupportedFileFound)
      this.notifyService.showError("Unsupported file format.", "Upload Failed");
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
