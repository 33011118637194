import { Component, ViewChild, OnInit, Pipe, HostListener } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../app.global';
//import { Patient, Doctor } from './app.model';
import { timer, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { HospitalService } from '../../services/hospital/hospital.service';
import { Visit } from '../../models/visit/visit.model';
import { PatientService } from '../../services/patient/patient.service';
import { Patient } from '../../models/patient/patient.model';
import { DoctorService } from '../../services/doctor/doctor.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DatePipe } from '@angular/common';
import { PatientFilesUploadModel } from '../../models/patient/pat-fileupload.model';
import * as moment from 'moment';
import { NotificationService } from '../../services/notification.service';
import { Hospital } from '../../models/hospital/hospital.model';
import { AlertService } from '../../services/alert.service';
import { Doctor } from '../../models/doctor/doctor.model';
//import { error } from 'util';
import { HospitalDoctorMap } from '../../models/HospitalDoctorMap/app.hospitaldoctormap.model';
import { Departement } from '../../models/Department/app.department.model';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { SchedulingService } from '../../services/scheduling/scheduling.service';
import { VisitDate, ScheduleInterval } from '../../models/scheduling/scheduling.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { DoctorProfileComponent } from '../../Doctor/doctor_profile/app.doctor_profile';
import { ConsentFormComponent } from '../../ConsentForm/app.consentformcomponent';
import { PatientFamily } from 'src/app/models/patient/Pat-family.model';

@Component({
  //selector: 'app-root',
  templateUrl: './app.patient_bookappointment.html'
})
export class PatientBookAppointmentComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  appointmentTime: VisitDate[] = [];
  meridian: string = "";
  filteredOptions: Observable<string[]>;
  date: { year: number, month: number };
  public bookList: boolean = false;
  public index: number;
  public appointDetail: boolean = false;
  visitObj: Visit = new Visit();
  hospitalList: Array<any> = new Array<any>();
  deptList: Array<Departement> = new Array<Departement>();
  filterDepartmentById: Array<Departement> = new Array<Departement>();
  docList: Array<Doctor> = new Array<Doctor>();
  public todayDate = new Date();
  public selHospital: any;
  public selDoctor: any;
  public files: any;
  public doctorId: string;
  public patId: string;
  public appointDetailStatus: string;
  public departmentId: string;
  public hospitalId: any;
  public docId: string;
  public depId: string;
  patientFrm: FormGroup;
  doctorFrm: FormGroup;
  token: any;
  // todayDate: any;
  deptName: string;
  public patBook: boolean = false;
  patInfo: Patient = new Patient();
  hospital: Hospital = new Hospital();
  patients: Array<Patient> = new Array<Patient>();
  public selDepartment: any;
  public doctList: any;
  public depList: any;
  public doctor: Doctor = new Doctor();
  public DepartmentName: any;
  public hospitalDepartment: any;
  public hospitalDoctor: any;
  public scheduleIntervalList: ScheduleInterval = new ScheduleInterval();
  public hospitalDepartmentIdList: Array<Departement> = new Array<Departement>();
  public hospitalDoctorIdList: Array<Doctor> = new Array<Doctor>();
  public hospitalDoctorMap: Array<HospitalDoctorMap> = new Array<HospitalDoctorMap>();
  public hospitalDoctorList: any;
  visitDate: NgbDateStruct;
  AppointmentType: string;
  fileType = [
    { id: 1, name: "Lab" },
    { id: 1, name: "Clinical" },
    { id: 1, name: "Radiology" }
  ];
  newFile: PatientFilesUploadModel = { PatientFileId: "", PatientId: "", VisitId: "", FileType: "", Title: "", FilePath: "", Comment: "" };
  public progress: number;
  public message: string;
  public hosDocList: any;
  public docDeptList: Array<any>;
  public hospDeptList: any;
  public deptHosList: any;
  public filterhospDeptList: Array<any> = [];
  public filterdeptHosList: Array<any> = [];
  public patFiles: Array<PatientFilesUploadModel> = new Array<PatientFilesUploadModel>();

  public patinfo: Patient = new Patient();

  public bookforOther: boolean = false;
  public patFamilyList: Array<PatientFamily> = new Array<PatientFamily>();
  public selectedFamilyMember: string;

  Relationship = [
    { id: 1, relation: "Father" },
    { id: 2, relation: "Mother" },
    { id: 3, relation: "Brother" },
    { id: 4, relation: "Sister" },
    { id: 5, relation: "Uncle" },
    { id: 6, relation: "Aunty" },
    { id: 7, relation: "Friend" },
    { id: 8, relation: "Grandfather" },
    { id: 9, relation: "Grandmother" },
    { id: 10, relation: "Other" }
  ];
  public familyMember: PatientFamily = new PatientFamily();
  public patFamilydetailenable: boolean = false;
  disableTyping: any;
  familyForm: FormGroup;
  constructor(public httpClient: HttpClient, public dialog: MatDialog,
    public routing: Router, private route: ActivatedRoute,
    public hospitalService: HospitalService, public patService: PatientService, public docService: DoctorService, private authenticationService: AuthenticationService, private notifyService: NotificationService,
    public global: Global, public datePipe: DatePipe, private http: HttpClient, public alertService: AlertService, public schedulingService: SchedulingService, private formBuilder: FormBuilder) {
    this.token = this.authenticationService.currentUserValue;

    // - this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.hospitalId = this.route.snapshot.queryParamMap.get('id');
    this.docId = this.route.snapshot.queryParamMap.get('id1');
    this.hospitalService.DepartmentId = this.route.snapshot.queryParamMap.get('dep');
    this.depId = this.route.snapshot.queryParamMap.get('dep');
    this.appointDetailStatus = this.route.snapshot.queryParamMap.get('status');
    if (this.appointDetailStatus == "true") {
      this.appointDetail = true;
    }
    this.visitObj.BookingTime = this.global.BookingTime;
    this.visitObj.VisitDate = this.global.VisitDate;

    this.newFile = { PatientFileId: "", PatientId: "", VisitId: "", FileType: "", Title: "", FilePath: "", Comment: "" };
    this.patFiles.push(this.newFile);
    this.hospitalService.HospitalId = this.hospitalId;
    if (this.hospitalService.HospitalId != null) {
      this.visitObj.HospitalId = this.hospitalService.HospitalId;
      this.hospitalId = this.hospitalService.HospitalId;
    }
    if (this.docService.DoctorId != null) {
      this.visitObj.ProviderId = this.docService.DoctorId;
      this.visitObj.DoctorId = this.docService.DoctorId;
      this.docId = this.docService.DoctorId;
    }
    if (this.global.DepartmentId != null) {
      this.depId = this.global.DepartmentId;
      this.visitObj.DepartmentId = this.global.DepartmentId;
    }
    if (this.hospitalService.DepartmentId != null) {
      this.depId = this.hospitalService.DepartmentId;
    }
    this.AppointmentType = this.patService.VisitType;
    this.DepartmentName = this.patService;
    this.familyMember.DateOfBirth = moment().format('YYYY-MM-DD');

    this.GetDepartmentList();
  }

  ngOnInit() {
    this.GetHospital();
    this.GetDoctorList();
    this.GetDepartmentList();
    if (this.docId != null) {
      this.visitObj.DoctorId = this.docId;
      this.visitObj.ProviderId = this.docId;
    }
    this.GetHospitalDoctorMap();
    if (this.hospitalService.DepartmentId != null) {
      this.visitObj.DepartmentId = this.hospitalService.DepartmentId;
    }
    this.GetPatient();
    this.GetFamilyDetail();

    this.familyForm = this.formBuilder.group({
      Relationship: ['', [Validators.required]],
      ContactNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      MiddleName: [''],
      DOB: ['', [Validators.required]],
      Email: [''],
      Gender: ['', [Validators.required]],
      BloodGroup: [''],
      Address: ['']
    });
  }
  GetHospital() {
    if (this.visitObj.HospitalId == null) {
      this.visitObj.HospitalId = this.hospitalId;
    }
    this.hospitalService.getHospital(this.visitObj.HospitalId)
      .subscribe(res => this.SuccessHospital(res),
        res => this.Error(res));
  }
  GetDoctorList() {
    this.docService.getDoctorList().subscribe(res => this.SuccessDoc(res),
      res => this.Error(res));
    if (this.docId != null) {
      this.docService.getDoctorHospital(this.docId).subscribe(res => this.SuccessDoctor(res),
        res => this.Error(res));
    }
  }
  GetDepartmentList() {
    this.docService.getDepartmentList().subscribe(res => this.SuccessDepartmentList(res),
      res => this.Error(res));
  };
  SuccessDepartmentList(res) {
    if (res && res.IsSuccess && res.Results) {
      this.deptList = res.Results;
      if (this.docService.DoctorId != null) {
        this.visitObj.DoctorId = this.docService.DoctorId;
        this.visitObj.ProviderId = this.docService.DoctorId;
      }

      if (this.hospitalService.DepartmentId != null) {
        this.visitObj.DepartmentId = this.hospitalService.DepartmentId;
        this.depId = this.hospitalService.DepartmentId;
        this.filterDepartmentById = Array<Departement>();
        this.filterDepartmentById = JSON.parse(JSON.stringify(this.deptList));//deepcopy
        this.filterDepartmentById = this.filterDepartmentById.filter(s => s.DepartmentId == this.hospitalService.DepartmentId);
        this.deptName = this.filterDepartmentById[0].DepartmentName;
        // console.log(this.filterDepartmentById[0].DepartmentName);
      }
    } else {
      this.alertService.error(res.ResponseMessage);
    }
  }
  GetHospitalDoctorMap() {
    this.docService.getHospitalDoctorMapList(this.hospitalId).subscribe(res => {

      this.hosDocList = res;
    },
      error => {
        this.alertService.error(error);
      });
  }
  GetVisitTime(event) {
    var vDate = moment(this.visitObj.VisitDate);
    var visitDate = vDate.format('YYYY-MM-DD');
    if (visitDate != null) {
      this.schedulingService.getVisitTime(visitDate).subscribe(res => this.SuccessAppointmentTime(res),
        res => this.ErrorAppointmentTime(res));
    }


  }
  SuccessAppointmentTime(res) {
    this.appointmentTime = Object.assign(this.appointmentTime, res);

    if (this.appointmentTime.length == 0) {
      this.notifyService.showInfo("Sorry", "There is no schedule for" + " " + "Dr." + " " + `${this.doctor.DoctorName}` + " " + "on" + " " + `${moment(this.visitObj.VisitDate).format('YYYY-MM-DD')}`);
    }
    if (this.docId != null && this.appointmentTime.length > 0) {
      this.appointmentTime = this.appointmentTime.filter(x => x.DoctorId == this.docId && x.DepartmentId == this.selDepartment && x.HospitalId == this.hospitalId);

    }
    else if (this.docId == null && this.appointmentTime.length > 0) {
      this.appointmentTime = this.appointmentTime.filter(x => x.DoctorId == this.doctorId && x.DepartmentId == this.selDepartment && x.HospitalId == this.hospitalId);
    }
    else {
      this.appointmentTime = [];
    }
  }
  ErrorAppointmentTime(res) {
    this.notifyService.showInfo("Sorry", "There is no schedule for" + " " + "Dr." + " " + `${this.doctor.DoctorName}` + " " + "on" + " " + `${moment(this.visitObj.VisitDate).format('YYYY-MM-DD')}`);
  }
  setAppontmentTime(time) {
    this.visitObj.BookingTime = `${time.StartTime}  ${time.StartMeridian} - ${time.EndTime}  ${time.EndMeridian}`
  }
  Error(res) {
    this.notifyService.showError("Warning", "Internal Error")
  }
  SuccessDoc(res) {
    this.docList = res?.Results;
  }
  SuccessDoctor(res) {
    if (res && res.IsSuccess && res.Results) {
      this.doctor = res.Results;
      this.doctor.DoctorName = res.Results[0].DoctorName;
    }else{
      this.alertService.error(res.ResponseMessage);
    }
  }
  SuccessHospital(res) {
    this.hospital = res;
  }

  GetPatient() {
    if (this.token.PatientIdentifier != null || this.patService.PatientId) {
      this.visitObj.HospitalId = this.hospitalId;
      let patId = this.token.PatientIdentifier != null ? this.token.PatientIdentifier : this.patService.PatientId;
      this.patService.getPatient(patId,this.token.UserType)
        .subscribe(res => this.SuccessPatient(res),
          res => this.Error(res));
    }
  }

  SuccessPatient(res) {
    this.patinfo = res;
  }

  GetFamilyDetail() {
    if (this.token.PatientIdentifier != null || this.patService.PatientId) {
      this.visitObj.HospitalId = this.hospitalId;
      let patId = this.token.PatientIdentifier != null ? this.token.PatientIdentifier : this.patService.PatientId;
      this.patService.getFamilyList(patId)
        .subscribe(res => this.SuccessgetFamily(res),
          res => this.Error(res));
    }
  }

  SuccessgetFamily(res) {
    this.patFamilyList = res;
  }
  public departmentChanged() {
    if (this.doctorId != null) {
      this.hospDeptList = new Array<any>();
      this.hospDeptList = JSON.parse(JSON.stringify(this.hosDocList));//deepcopy
      this.filterhospDeptList = this.hosDocList.filter(a => a.DoctorId == this.doctorId);
      this.docDeptList = this.deptList.filter(x =>
        this.filterhospDeptList[0].DepartmentList.some(y =>
          x.DepartmentId === y.DepartmentId
        ));
      this.departmentId = this.selDepartment;
    }
  }


  public DoctorDepartmentChanged() {

    if ((this.selDoctor == null || this.selDoctor == undefined) && (this.selDepartment == null || this.selDepartment == undefined) && this.docId == null) {
      this.hospitalDoctorIdList = new Array<Doctor>();
      this.hospitalDepartmentIdList = new Array<Departement>();
      for (let doc of this.hosDocList) {
        this.hospitalDoctorIdList.push(doc);
        for (let dep of doc.DepartmentList) {
          this.hospitalDepartmentIdList.push(dep);
        }
      }
      this.hospitalDoctor = this.docList.filter(x => this.hospitalDoctorIdList.some(y => x.DoctorId == y.DoctorId));
      this.hospitalDepartment = this.deptList.filter(x => this.hospitalDepartmentIdList.some(y => x.DepartmentId == y.DepartmentId));
    }
    else if (this.selDoctor != null || this.docId != null) {
      if (this.docId == null) {
        this.hospitalDoctorList = this.hosDocList.filter(a => a.DoctorId == this.selDoctor);
      }
      if (this.docId != null) {
        this.hospitalDoctorList = this.hosDocList.filter(a => a.DoctorId == this.docId);
      }
      this.hospitalDepartmentIdList = new Array<Departement>();
      for (let doc of this.hospitalDoctorList) {
        for (let dep of doc.DepartmentList) {

          this.hospitalDepartmentIdList.push(dep);
        }
      }
      this.hospitalDepartment = this.deptList.filter(x => this.hospitalDepartmentIdList.some(y => x.DepartmentId == y.DepartmentId));
      this.departmentId = this.selDepartment;
      this.doctorId = this.selDoctor;
    }
    else if (this.selDepartment != null) {
      this.hospitalDoctorMap = new Array<HospitalDoctorMap>();
      for (let doc of this.hosDocList) {
        for (let dep of doc.DepartmentId) {

          this.hospitalDoctorMap.push(dep);
        }
      }
      this.hospitalDoctorList = this.hospitalDoctorMap.filter(a => a.DepartmentId == this.selDepartment);
      this.hospitalDoctorIdList = new Array<Doctor>();
      for (let dep of this.hospitalDoctorList) {

        this.hospitalDoctorIdList.push(dep);
      }
      this.hospitalDoctor = this.docList.filter(x => this.hospitalDoctorIdList.some(y => x.DoctorId == y.DoctorId));
      this.departmentId = this.selDepartment;
      this.doctorId = this.selDoctor = null;

    }

    else {
      this.selDoctor = null;
      this.selDepartment = null;
    }
    this.departmentId = this.selDepartment;
    this.doctorId = this.selDoctor;

  }
  public PostProblem() {
    // if((this.visitObj.PatientValidator.valid == true ) || (this.visitObj.PatientValidator.dirty ==true))
    // {
    if (this.token.PatientIdentifier == undefined) {
      this.patId = this.patService.PatientId;
    } else {
      this.patId = this.token.PatientIdentifier;
    }
    this.visitObj.PatientId = this.patId;
    this.docService.DoctorId = this.doctorId != undefined ? this.doctorId : this.global.DoctorId;
    this.visitObj.ProviderId = this.docId != null ? this.docId : this.global.DoctorId;
    // this.visitObj.Docy = this.docId  != null? this.docId : this.docService.DoctorId ;
    this.visitObj.DepartmentId = this.depId != null ? this.depId : this.global.DepartmentId;
    this.hospitalService.DepartmentId = this.departmentId;
    //this.visitObj.DepartmentId = this.doctorId  != null? this.doctorId : this.selDoctor ;
    this.visitObj.HospitalId = this.hospitalId;
    if (this.hospital.PaymentEnable == false) {
      this.visitObj.PaymentStatus = "free";
      this.hospitalService.PaymentEnable = false;
    }
    else {
      this.visitObj.PaymentStatus = "unpaid";
    }
    this.visitObj.Status = "initiated";
    this.visitObj.VisitType = this.AppointmentType;
    this.visitObj.PatientFiles = Object.assign(this.visitObj.PatientFiles, this.patFiles);// no family member need to add 1st in db then call postvisit

    if (this.bookforOther == true && this.selectedFamilyMember == undefined) {
      if (this.familyForm.invalid) {
        if (this.familyForm.controls.Relationship.invalid) {
          this.notifyService.showError("", "Relationship is required");
        }
        if (this.familyForm.controls.ContactNumber.invalid) {
          this.notifyService.showError("", "Phone number is required and should be 10 digits");
        }
        if (this.familyForm.controls.FirstName.invalid) {
          this.notifyService.showError("", "First Name is required");
        }
        if (this.familyForm.controls.LastName.invalid) {
          this.notifyService.showError("", "Last Name is required");
        }
        if (this.familyForm.controls.DOB.invalid) {
          this.notifyService.showError("", "Date of Birth is required");
        }
        if (this.familyForm.controls.Gender.invalid) {
          this.notifyService.showError("", "Gender is required");
        }
        return;
      }
      this.familyMember.PatientId = this.visitObj.PatientId;
      this.patService.addFamilyList(this.familyMember).subscribe(res => this.Success(res),
        res => this.ErrorAdd(res));
    }
    else {
      this.PostVisit(); //visit for patient self && existed family member  
    }
    //let mdt = moment([this.visitDate.year, this.visitDate.month, this.visitDate.day]);
    //this.visitObj.VisitDate = new Date(mdt.format('MM/DD/YYYY'));

    // }
    // else{
    //   this.notifyService.showError("Error","Please select the doctor at top and proceed!");
    // }

  }

  Success(res) {
    this.visitObj.FamilyMemberId = res;
    this.PostVisit();
  }
  ErrorAdd(res) {
    this.notifyService.showError("Error", "Internal Error")
  }
  PostVisit() {
    this.patService.postVisit(this.visitObj, this.docService.ScheduleIntervalId, this.docService.SchedulingId).subscribe(res => this.SuccessPostProblem(res),
      res => this.PostProblemError(res));
  }
  SuccessPostProblem(res) {
    if (this.token.PatientIdentifier == undefined) {
      this.notifyService.showSuccess("Appointment has been booked successfully.", "Successful");
      this.routing.navigate(['/PaymentSelection'], { queryParams: { vid: res.Value } });
    } else {
      if (this.hospitalService.PaymentEnable == false) {
        this.routing.navigate(['/PatPaidBookingList'], { queryParams: { vid: res.Value } })
      } else {
        this.routing.navigate(['/PaymentSelection'], { queryParams: { vid: res.Value } });
      }
    }

  }
  PostProblemError(res) {
    this.notifyService.showError("Error", " Please fill up the required field")
  }

  upload(files: File[], i) {
    this.message = null;
    if (files.length === 0)
      return;


    // if(0.00000095367432*FileList[i].siz )
    const formData = new FormData();

    for (let file of files) {
      // This limit the file size to 10 Mb.
      if (file.size * 0.00000095367432 > 10) {
        this.notifyService.showWarning("Error", "File size should be less than 10 MB.");
        return;
      }
      let fileExtension = file.name.split('.').pop().toLowerCase();
      var acceptedFileFormat = ["jpg", "png", "doc", "docx", "pdf"];
      if (!acceptedFileFormat.includes(fileExtension)) {
        this.notifyService.showWarning("Unsupported file format", "Error");
        return;
      }
      formData.append(file.name, file);
      const uploadReq = new HttpRequest('POST', `api/Patient/UploadFile/`, formData, {
        reportProgress: true,
      });

      this.http.request(uploadReq).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
          if (this.progress == 100) {
            this.index = i;
          }
        }
        else if (event.type === HttpEventType.Response)
          this.message = event.body.toString();
      });
    }
  }
  SelectDoctorForViewDetail(event: MatSelect) {
    try {
      this.docService.DoctorId = null;
      this.docService.DoctorId = event.value;
      console.log(event.value);
      this.global.DoctorId = event.value;
      const dialogRef = this.dialog.open(DoctorProfileComponent, { data: { notifytodocprof: "redirectFromPatAppointment", hospitalId: this.hospitalId }, width: '1000px', height: '1000px' });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        dialogRef.close();
        console.log(`Dialog result: ${result}`);
        this.depId = this.hospitalService.DepartmentId;
        this.global.DepartmentId = this.depId;
        this.appointDetail = true;
        this.visitObj.BookingTime = this.global.BookingTime;
        this.visitObj.VisitDate = this.global.VisitDate;
        this.visitObj.DepartmentId = this.hospitalService.DepartmentId;
        this.GetDepartmentList();
      });

    } catch (ex) {
      this.Error(ex);
    }

  }
  addRow(index) {
    this.message = null;
    this.newFile = { PatientFileId: "", PatientId: "", VisitId: "", FileType: "", Title: "", FilePath: "", Comment: "" };
    this.patFiles.push(this.newFile);
    console.log(this.patFiles);
    return true;
  }

  deleteRow(index) {
    if (this.patFiles.length == 1) {
      return false;
    } else {
      this.patFiles.splice(index, 1);
      return true;
    }
  }
  public BookHome() {
    this.patBook = true;
    this.routing.navigate(['/PatDashboard/PatBookHome']);
  }

  //public BookNewVisit() {
  //  this.patBook = true;
  //  this.routing.navigate(['/PatBookAppointment'], { queryParams: { id: this.hospitalId } });
  //}

  //public MyBookList() {
  //  this.bookList = true;
  //  this.routing.navigate(['/PatBookList'], { queryParams: { id: this.hospitalId } });

  //}

  //public MyPaidBooking() {
  //  this.patBook = true;
  //  this.routing.navigate(['/PatPaidBookingList'], { queryParams: { id: this.hospitalId } });
  //}
  public Payment() {
    this.patBook = true;
    this.routing.navigate(['/PaymentSelection'], { queryParams: { id: this.hospitalId, id1: this.docId } });
  }

  public BackDashBoard() {
    this.hospitalService.DepartmentId = null;
    this.patService.VisitType = null;
    this.docService.DoctorId = null;
    this.global.BookingTime = "";
    this.global.VisitDate = null;
    this.visitObj = null;
    this.routing.navigate(['/PatDashboard']);
  }

  BookAppointmentforOther() {
    this.bookforOther = true
    // if (this.patFamilyList.length) {
    //   this.patFamilydetailenable = false;

    // }
    // else {
      this.patFamilydetailenable = true;
    // }

  }

  bookAppointmentforSelf() {
    this.bookforOther = false;
    this.visitObj.FamilyMemberId = null;
    this.familyMember = new PatientFamily();
    this.selectedFamilyMember = null;
  }

 
  SelectedMember(event) {
    let selectedFamilyMemberId = event.value.FamilyMemberId;
    this.familyMember = this.patFamilyList.find(x => x.FamilyMemberId == selectedFamilyMemberId);
    if (this.familyMember && this.familyMember.DateOfBirth != null) {
      this.familyMember.DateOfBirth = moment(this.familyMember.DateOfBirth).format('YYYY-MM-DD');
    }
    else {
      this.familyMember.DateOfBirth = moment().format('YYYY-MM-DD');
    }
   this.visitObj.FamilyMemberId = this.familyMember.FamilyMemberId;
  }


}
