<app></app>
<button [routerLink]="['/DocDashboard']" class="back-btn-doc-room">Back <span>To DashBoard</span> </button>

<div *ngIf="dailyvisit" class="grid-section">
  <h4 class="title">
    <i class="fa fa-list"></i>My Patient List
  </h4>
  <div class="col-md-12 alert alert-default">
    <div class="row d-flex align-items-center">
    <label class="control-label col-md-2">Filter By Date:</label>
    <div class="col-md-4">
    <input  (change)="ChangeDate()" type="date" name="date" [(ngModel)]="filterdate"  required class="m-0"/>
    </div>
    </div>
  </div>
  <!-- for-mobile -->
  <!-- class="d-sm-none d-md-block" -->
  <table class="table table-striped table-hover ">
    <thead >
        <tr class="table-tr-style">
        <th scope="col">SN</th>
        <th scope="col">Visit Date</th>
        <th scope="col">Visit Type</th>
        <th scope="col">Patient Name</th>
        <th scope="col">Age</th>
        <th scope="col">Gender</th>
        <th scope="col">Mobile No.</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of filteredPatientList  |paginate: { itemsPerPage: 10, currentPage: p  } ;let i = index  ">
        <td>{{i+1}}</td>
        <td>{{data.VisitDate}}</td>
        <td>{{data.VisitType}}</td>
        <td>{{data.PatientName }}</td>
        <td>{{data.Age }}</td>
        <td>{{data.Gender }}</td>
        <td>{{data.ContactNumber }}</td>
        <td>
          <a (click)="Reschedule(i)" class="btn btn-success btn-sm text-white"><i class="fa fa-pencil"></i>Re-Schedule </a>
          <a *ngIf="data.ShowViewOPSummary" (click)="ViewOPSummary(data)" class="btn btn-success btn-sm text-white"><i class="fa fa-eye"></i>View </a>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> -->
  <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>



</div>
<footer></footer>
