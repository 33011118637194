<div class="full-body">
  <app *ngIf="token.UserType == 'Doctor'"></app>
  <button (click)="executeCommand('hangup')"
          [routerLink]="['/DocDashboard']"
          [queryParams]="{redirectUrl: 'DoctorUrl'}"
          *ngIf="token.UserType == 'Doctor'"
          class="back-btn-doc-room">
    Back To DashBoard
  </button>
  <div *ngIf="IsDoctor == false"
       class="g-drive-link-container"
       for="selPatient">
    <a type="button"
       target="_blank"
       href="{{MeetingLink}}"
       class="btn btn-lg btn-success">For Iphone User Click Here !</a>
  </div>

  <div class="doc-fixed-room">
    <div [ngClass]="{'patient-in-call': token.UserType == 'Doctor', 'doctor-in-call': token.UserType !=  'Doctor'}">

      <div class="video-frame"
           #fullScreen>

           <div *ngIf="hasCallStarted">
            <div id="patient-info"
               *ngFor="let row of patList">
              <h1>
                <i class="fa fa-user"
                  aria-hidden="true"></i>{{ row.PatientFamilyName ? row.PatientFamilyName : row.PatientName }} - {{ row.PatientFamilyGender ? row.PatientFamilyGender : row.Gender }}
              </h1>
              <div>
                <i class="fa fa-phone"
                  aria-hidden="true"></i>{{ row.FamilyContactNumber ? row.FamilyContactNumber : row.ContactNumber}}
              </div>
              <div>
                <i class="fa fa-map-marker"
                  aria-hidden="true"></i>{{ row.FamilyAddress ? row.FamilyAddress : row.Address }}
              </div>
            </div>
          </div>  

        <!-- <div id="jitsi-iframe"
             [ngClass]="{'doctor-jitsi-frame': token.UserType !=  'Doctor'}"></div> -->

             <!-- For Component View -->
      <div id="meetingSDKElement" >
        <!-- Zoom Meeting SDK Component View Rendered Here -->
      </div>



        <a *ngIf=" isVideoFullscreen"
           class="btn btn-outline"
           style="border:1px solid #666"
           (click)="closeFullscreen()">
          <i class="fa fa-window-restore"
             aria-hidden="true"></i>Exit Full Screen
        </a>
      </div>


      <div class="patient-in-call-data">
        <div>
          <div *ngFor="let row of filteredpat">
            <div>
              <div>
                <div class="p-health-info">
                  <label *ngIf="row.Fever"
                         for=""
                         class="">
                    <span class="label-title">Fever</span>
                  </label>
                  <label *ngIf="row.Cough"
                         for=""
                         class="">
                    <span class="label-title">Cough</span>
                  </label>
                  <label *ngIf="row.BreathingDifficulty"
                         for=""
                         class="">
                    <span class="label-title">
                      Breathing Difficulty
                    </span>
                  </label>
                  <label *ngIf="row.Tiredness"
                         for=""
                         class="">
                    <span class="label-title">Tiredness</span>
                  </label>
                  <label *ngIf="row.SoreThroat"
                         for=""
                         class="">
                    <span class="label-title">Sore Throat</span>
                  </label>
                  <label *ngIf="row.Bodyache"
                         for=""
                         class="">
                    <span class="label-title">Bodyache</span>
                  </label>
                  <label *ngIf="row.ChestPain"
                         for=""
                         class="">
                    <span class="label-title">Chest Pain</span>
                  </label>
                  <label *ngIf="row.Diarrhea"
                         for=""
                         class="">
                    <span class="label-title">Diarrhoea</span>
                  </label>

                  <label *ngIf="row.HeartDisease"
                         for=""
                         class="">
                    <span class="label-title">
                      Heart Disease
                    </span>
                  </label>
                  <label *ngIf="row.HighBloodPressure"
                         for=""
                         class="">
                    <span class="label-title">
                      High Blood Pressure
                    </span>
                  </label>
                  <label *ngIf="row.Diabetes"
                         for=""
                         class="">
                    <span class="label-title">Diabetes</span>
                  </label>
                  <label *ngIf="row.Copd"
                         for=""
                         class="">
                    <span class="label-title">Copd/Asthma</span>
                  </label>
                  <label *ngIf="row.Transplant"
                         for=""
                         class="">
                    <span class="label-title">Transplant</span>
                  </label>
                  <label *ngIf="row.RecentTravel"
                         for=""
                         class="">
                    <span class="label-title">
                      Recent Travel
                    </span>
                  </label>
                  <label *ngIf="row.Cancer"
                         for=""
                         class="">
                    <span class="label-title">Cancer</span>
                  </label>
                  <label *ngIf="row.Exposure"
                         for=""
                         class="">
                    <span class="label-title">
                      Exposure of Covid Patient
                    </span>
                  </label>
                </div>
                <div class="card-header p-que col col-md-12"
                     style="text-align: left !important; border-bottom: none;">
                  <div class="left col-md-12 p-0">
                    <div>
                      <div class="card p-0">
                        <div class="card-body p-0">
                          <h5 class="card-title">
                            Patient Files
                          </h5>
                          <div class="f-flex patient-uploads"
                               *ngIf="showDocument">
                            <span *ngFor="let data of documents; let i=index;">
                              <a (click)="preview(data.FilePath, data.FileExtention, data.Title)"><i class="fa fa-file-o mr-1"
                                   aria-hidden="true"></i>{{data.FileType}}</a>
                            </span>
                          </div>
                          <div *ngIf="!showDocument && clickDocument"
                               style="background-color: #fff">
                            <p class="alert alert-danger">Empty</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div d-block>
                  <label for=""
                         class="col-md-12">
                    <span style="font-size: 14px;font-weight: 700;margin-bottom: 7px;display: inline-block;">
                      <i class="fa fa-ellipsis-v"
                         aria-hidden="true"></i>Other Symptoms</span>
                    <span class="d-block"
                          style="color: #666">{{row.AnyOtherSymptoms}}</span>
                  </label>
                  <label for=""
                         class="col-md-12">
                    <span style="font-size: 14px;font-weight: 700;margin-bottom: 7px;display: inline-block;">
                      <i class="fa fa-ellipsis-v"
                         aria-hidden="true"></i>Other Pertinent
                      Information
                    </span>
                    <span class="d-block"
                          style="color:#666">{{row.OtherPertientInformation}}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #content
                   let-modal>
        <div class="modal-header">
          <button type="button"
                  class="close"
                  aria-label="Close"
                  (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="ChatForm"
                class="doc-chat-section">
            <div class="alert alert-default p-1 mb-2"
                 style="background: #f9f9f9;border: 1px solid #f9f9f9;display: flex;justify-content: center;align-items: center;">
              <p class="text-center small font-weight-normal">
                <i class="fa fa-info"
                   aria-hidden="true"></i> Please select your {{ IsDoctor ? 'Patient' : 'Doctor' }} to start conversiation.
              </p>
            </div>
            <div class="select-for-chat">
              <select *ngIf="IsDoctor == true"
                      id="selPatient"
                      formControlName="Id"
                      (change)="OnChatUserChange()"
                      class="select-select-patient form-control">
                <option disabled
                        value="null">
                  Select {{ IsDoctor ? 'Patient' : 'Doctor' }}
                </option>
                <option *ngFor="let user of patList"
                        [ngValue]="user">
                  {{ user.PatientName }}
                </option>
              </select>
            </div>

            <div class="chat-messages h-300 pr-1">
              <label *ngIf="IsDoctor == false"
                     for="selPatient">
                <i class="fa fa-user-md"
                   aria-hidden="true"></i>Doctor:
                <strong> {{docName}} </strong>
              </label>

              <div *ngIf="hasError('required','Id') && IsDoctor == true"
                   style="color: red; font-size: 12px; margin-bottom: 7px">
                {{ IsDoctor ? 'Patient' : 'Doctor' }} is required
              </div>
              &nbsp; &nbsp;
              <div class="message-box-holder"
                   *ngFor="let msg of ChatMessages">
                <div *ngIf="msg.Name !='Me'"
                     class="message-by-doc">
                  <span>
                    <i class="fa fa-user"
                       aria-hidden="true"></i>{{ msg.Name }}: {{ msg.Message }}
                  </span>
                </div>
                <div *ngIf="msg.Name =='Me'"
                     class="message-by-me">
                  <span style="background: #e7e9f8">{{ msg.Message }} </span>
                </div>
              </div>
            </div>
            <div class="chat-input-holder message-box"
                 style="margin-top: 10px">
              <textarea style="border: 1px solid #ccc"
                        class="chat-input"
                        placeholder="Type your message here.."
                        formControlName="chatMessage"
                        (keyup)="onChatEnter($event)"></textarea>
              <div class="chat-input-holder">
                <div *ngIf="hasError('required','chatMessage')"
                     style="color: red; font-size: 12px; margin-bottom: 7px">
                  Chat message is required
                </div>
                <input type="button"
                       value="Send"
                       class="message-send btn-primary"
                       (click)="SendChatMsg()" />
              </div>
            </div>
          </form>
        </div>

      </ng-template>
    </div>

    <div class="doc-room-right-container"
         *ngIf="token.UserType == 'Doctor'">
      <div class="patient-in-queue">
        <div class=" patient-info">
          <div>
            <h5 class="title">
              <i class="fa fa-user"
                 aria-hidden="true"></i>Patients in Queue
            </h5>
          </div>
          <div>
            <div>
              <table aria-label="Patient In Queue"
                     class="table table-striped">
                <ng-container *ngFor="let temp of patients; let i = index">
                  <tr>
                    <th id="patient-table-head">
                      <div class="patient-icn">
                      </div>
                    </th>
                    <td>
                      <h5 class="user-nm">{{temp.PatientFamilyName ? temp.PatientFamilyName : temp.PatientName}}</h5>
                    </td>
                    <td>
                      <h5 class="user-nm">{{temp.PatientFamilyMemberAge ? temp.PatientFamilyMemberAge : temp.Age}}</h5>
                    </td>
                    <td>
                      <h5 class="user-nm">{{temp.PatientFamilyGender ? temp.PatientFamilyGender : temp.Gender}}</h5>
                    </td>
                    <td>
                      <h5 class="user-nm">{{temp.VisitType}}</h5>
                    </td>
                    <td>
                      <div *ngIf="temp.Status=='initiated' && showCallButton">
                        <a *ngIf="temp.VisitType=='Telemedicine'"
                           (click)="CallPatient(temp.VisitId,temp.PatientId)"
                           [routerLink]="['/DoctorRoom']"
                           class="btn-call">
                          <i class="fa fa-video-camera"
                             aria-hidden="true"></i>Call
                        </a>

                        &nbsp;
                        <i class="fa fa-calendar-times-o"
                           aria-hidden="true"
                           style="color: cadetblue;font-weight:600;font-size:12px"></i>{{temp.BookingTime}}
                      </div>

                      <div *ngIf="temp.Status=='initiated' && !showCallButton">
                        <i class="fa fa-calendar-times-o"
                           aria-hidden="true"
                           style="color: cadetblue;font-weight:600;font-size:12px"></i>{{temp.BookingTime}}
                      </div>
                      <div *ngIf="temp.Status=='ongoing'">
                        <a class="btn-Incall"
                           style="color: #efe9e9"
                           (click)="EndMeeting(temp.VisitId,temp.PatientId)">
                          <i *ngIf="temp.VisitType=='Telemedicine'"
                             aria-hidden="true"
                             class="fa fa-video-camera"></i>End Call
                          <i class="fa fa-calendar-times-o"
                             aria-hidden="true"></i> {{temp.BookingTime}}
                        </a>

                        <a (click)="CallPatient(temp.VisitId,temp.PatientId)"
                           [routerLink]="['/DoctorRoom']"
                           style="padding: 5px 10px; font-size: 13px; color:#009688; text-decoration: none; font-weight: 600; margin-left: 15px;">
                        </a>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div class="card-body pl-0 pr-0">
            <div class="card-body p-0 accordionItemContent">
              <div class="chatbox-holder">
                <div class="chatbox"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="doctor-notes-for-patient">
        <div class="treatment-entry">
          <label for="TreatmentAdvice"> Treatment Advice : </label>
          <textarea id="TreatmentAdvice"
                    [(ngModel)]="visitObj.TreatmentAdvice"></textarea>

          <label for="Medication"> Medication : </label>
          <textarea id="Medication"
                    [(ngModel)]="visitObj.Medication"></textarea>

          <label for="FollowUp"> Follow Up : </label>
          <textarea id="FollowUp"
                    [(ngModel)]="visitObj.FollowUp"></textarea>

          <div>
            <input
              type="button"
              value="Complete Visit"
              [disabled]="!hasCallStarted"
              [ngClass]="{'btn-complete-visit': true, 'btn-disabled': !hasCallStarted}"
              (click)="openDialog(visitId, this.patients[0].PatientId)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal"
     id="myModal"
     [style.display]="showView ? 'block' : 'none'">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header"
           style="background-color: white">
        {{Title}}
        <button type="button"
                class="close"
                data-dismiss="modal"
                (click)="hide()">
          X
        </button>
      </div>
      <div class="modal"
     id="myModal"
     [style.display]="showView ? 'block' : 'none'">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header"
           style="background-color: white">
         {{Title}}
        <button type="button"
                class="close"
                data-dismiss="modal"
                (click)="hide()">
          X
        </button>
      </div>
      
      <div class="modal-body"
           style="background-color: floralwhite">
        <div class="info-heading">
          <img *ngIf="Filetype == 'image'"
          alt="info-image"
          [src]="safeUrl"
          class="patient_report_image"
        />
        <a *ngIf="Filetype == 'image'" 
        [href]="safeUrl" 
        target="_blank" 
          [download]="Title" 
        class="btn btn-sm btn-primary col-md-2" 
        style="float: right;">
      
        <i class="fa fa-download"></i>
        Download
        </a>
        <iframe *ngIf="Filetype == 'pdf'"
        [src]="safeUrl"
          style="display: block; width: 100%; height: 80vh !important;">
        </iframe>
          <div *ngIf="Filetype == 'docfile'">
            <a [href]="safeUrl"
               class="btn btn-primary btn-lg active"
               role="button">Click to Open Doc File</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
