import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    const jwtToken = sessionStorage.getItem("jwtToken");
    //const jwtToken = this.global.token;

    if (jwtToken) {
      const requestWithJwtToken = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + jwtToken)
      });

      //! Ganesh, 6thMarch, 24, Below logic is added to redirect the user to the login page if token is expired.
      //! Here if the token is removed from SessionStorage it gets redirected to login page.
      // return next.handle(requestWithJwtToken);

      return next.handle(requestWithJwtToken).pipe(
        catchError((error: HttpErrorResponse) => {
          // Handle the error here
          if(error.status === 401){
            sessionStorage.removeItem('jwtToken');
            window.location.reload();
          }
          //throw error as per requirement
          return throwError(error);
        })
      );
    }
    else {
      return next.handle(req);
    }

  }


}
