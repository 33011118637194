<style>
  .clickable {
    cursor: pointer;
  }

  .mat-raised-button.mat-success,
  .mat-flat-button.mat-success,
  .mat-fab.mat-success,
  .mat-mini-fab.mat-success {
    color: #f0fff3;
    background-color: #155724;
  }
</style>
<app *ngIf="data.notifytodocprof != 'redirectFromPatAppointment'"></app>
<!--<button (click)="BackDashBoard()" class="back-btn-doc-room">Back To DashBoard </button>-->
<button *ngIf="data.notifytodocprof != 'redirectFromPatAppointment'" class="back-btn-doc-room" (click)="BackDashBoard()">Back </button>

<mat-dialog-actions *ngIf="data.notifytodocprof == 'redirectFromPatAppointment'" align="right">
  <button mat-button color="primary" style="position: absolute; right: -27px; top: -15px;font-size: 20px;" (click)="BackBookAppointment()"><i class="fa fa-times"></i></button>
</mat-dialog-actions>
<div class="row">
  <div class="col-sm-12 col-md-4">
    <div class="doc-profile">
      <div class="hospital-image">
        <img class="pic-1" src="{{FilePath}}" (error)="FilePath = 'assets/img/docProfile.png'"> 
        <!-- Added a default doctor avatar if his/her avatar is not being rendered -->
      </div>
      <div class="doc-prf-content">
        <h1 class="title">Dr. {{DoctorName}}</h1>
        <h5 class="title-small" *ngIf="EnablePhNo == 1"><i class="fa fa-phone"></i>{{PhoneNumber}}</h5>
        <h5 class="title-small"><i class="fa fa-envelope"></i><a href="mailto:"></a>{{MailingAddress}}</h5>
        <hr />
        <h5 class="title-pad"><i class="fa fa-mortar-board"></i>Education</h5>
        <p *ngFor="let list of QualificationList "> {{list.Education}}</p>
        <h5 class="title-pad"><i class="fa fa-briefcase"></i>Experience(Years)</h5>
        <p *ngFor="let list of QualificationList "> {{list.Experience}}</p>
        <h5 class="title-pad"><i class="fa fa-university"></i>Past Affiliation</h5>
        <p *ngFor="let list of QualificationList "> {{list.PastAffiliation}}</p>
        <h5 class="title-pad"><i class="fa fa-user"></i>Membership</h5>
        <p *ngFor="let list of QualificationList " style="margin-bottom:0 !important"> {{list.Membership}}</p>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-4">
    <div class="doc-schedule">
      <div class="row">
        <div class="col-md-12">
          <h1 class="card-title"><i class="fa fa-h-square"></i>Please Select Hospital</h1>
        </div>
        <div class="col-md-12">
          <mat-form-field class="mat-form-field-fluid select-input" appearance="outline" style="width: 100%;">
            <mat-select placeholder="Select Hospital" [(ngModel)]="selHospital" (selectionChange)="AssignHospital($event)">
              <mat-option *ngFor="let row of hospitalList" [value]="row.HospitalId">{{row.HospitalName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="isOnlineAppointment">
        <div class="row">
          <div class="col-md-12">
            <form [formGroup]="HospitalFormGroup">
              <mat-form-field class="mat-form-field-fluid select-input" appearance="outline" style="width: 100%;">
                <mat-select placeholder="Select Department" formControlName="Department" (selectionChange)="AssignDepartment($event)">
                  <mat-option *ngFor="let row of filteredDepartmentList" [value]="row">
                    {{row.DepartmentName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </div>
        </div>
      </div>
      <div *ngIf="!isOnlineAppointment">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="mat-form-field-fluid select-input" appearance="outline" style="width: 100%;">
              <mat-select placeholder="Select Department" [(ngModel)]="selDepartment" (selectionChange)="AssignTeleDepartment($event)">
                <mat-option *ngFor="let row of filteredDepartmentList" [value]="row">
                  {{row.DepartmentName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf=" showBookingDate" class="row">
        <form [formGroup]="visitObj.PatientValidator" style="width: 100%">
          <label class="control-label col-md-12">Booking Date:</label>
          <div class="col-md-12">
            <mat-form-field class="example-full-width">
              <mat-label>Choose a Appointment Date</mat-label>
              <input matInput [min]="todayDate" [matDatepicker]="picker" [(ngModel)]="visitObj.VisitDate" formControlName="VisitDate" (dateChange)="GetVisitTime($event)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <span class="error-msg" *ngIf="visitObj.IsDirty('VisitDate')" [hidden]="visitObj.IsValidCheck('VisitDate','required')"> VisitDate is required</span>
            <span class="error-msg" *ngIf="visitObj.IsDirty('VisitDate')" [hidden]="visitObj.IsValidCheck('VisitDate','invalidDate')">Enter Valid Date</span>
          </div>
        </form>
      </div>
      <div *ngIf="ScheduleTimeDetail">
        <div class="" *ngIf="showTimeSlot">
          <p class="alert alert-info" style="font-size: 12px"> <i class="fa fa-info-circle"></i>Please Select Time Interval To Select Time Slot</p>
        </div>
        <div *ngFor="let time of appointmentTime" style="display:flex; flex-direction: row;">
          <div class="example-button-row">
            <button *ngIf="showIntervalButton" mat-raised-button color="success" (click)="checkTimeSlot(time.SchedulingId)" style="font-size: 12px; width: auto;margin: 7px auto; border-radius: 30px"><i class="fa fa-clock-o"></i>Schedule Interval: {{time.StartTime}} - {{time.EndTime}} </button>
            <div *ngFor="let list of scheduleIntervalList" style="display: inline-flex;align-items: center;justify-content: space-around; margin: 2px">
              <div *ngIf="showIntervalSlot  && time.SchedulingId == list.SchedulingId" class="example-button-row" style="width: 80px">
                <button *ngIf="list.IsBooked == false" mat-raised-button color="primary" (click)="setAppontmentTime(list)" style="font-size: 12px; width: 100%;margin: 7px auto; border-radius: 30px; font-weight:bold"> {{list.StartTime}} </button>
                <button *ngIf="list.IsBooked == true" mat-raised-button color="warn" (click)="setAppontmentTime(list)" style="font-size: 12px;  width: 100%;margin: 7px auto; border-radius: 30px ; font-weight:bold">{{list.StartTime}} </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!ScheduleTimeDetail && appointmentTime.length>0">
        <span>Time for appointmt is between {{appointmentTime[0].StartTime}} - {{appointmentTime[0].EndTime}}</span>
      </div>

      <div>
        <p class="alert alert-success" style="font-size:12px; margin-top: 15px;" *ngIf="visitObj.BookingTime!='' && visitObj.BookingTime != null ">
          <i class="fa fa-check-circle"></i>You have selected
          Appointment time:<br>
          <mat-chip class="btn-sm btn-success" style="border-radius: 22px; margin-top: 5px; display: inline-block;"><strong style="font-size: 12px; color: #fff">{{visitObj.BookingTime}}</strong></mat-chip>
        </p>
      </div>
      <button *ngIf="data.notifytodocprof != 'redirectFromPatAppointment' && showBookingAppointBtn" (click)="GoToBookAppointment()" class="btn-lg btn-block">
        Book <span>Appointment</span>
      </button>
      <mat-dialog-actions *ngIf="data.notifytodocprof == 'redirectFromPatAppointment' && showBookingAppointBtn" align="end">
        <button mat-button mat-dialog-close class="btn btn-primary" style="color: #fff">Save</button>
      </mat-dialog-actions>
    </div>
  </div>
  <div class="col-sm-12 col-md-4" *ngIf="this.SchedulingList!= null  && showScheduleList">
    <div class="doc-schedule less-p">
      <h1 class="card-title"><i class="fa fa-clock-o"></i>Availability of Dr. {{DoctorName}}</h1>
      <div class="col-12" *ngIf="showUnavailableMsg">
        <p style="color:red "><strong>Doctor is not available for this Department </strong> </p>
      </div>
      <div *ngIf="filteredSchedulingList != null  " class="doc-schedule-list">
        <table class="table sc-table table-striped" width="100%" cellspacing="0" cellpadding="3" style="
        margin-bottom: 10px !important;">
          <thead style="display: none;">
            <tr>
              <th valign="top">Date</th>
              <th valign="top">Doctor Available </th>
              <th style="display: none;"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable" *ngFor="let data of filteredSchedulingList" (click)="SelectSchedule(data)">
              <td align="left"> <i class="fa fa-calendar"></i>{{data.Date | date:'mediumDate'}}</td>
              <td align="right"> <span class="btn-schedule">{{data.StartTime }} - {{data.EndTime }}</span></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>