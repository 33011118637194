<app></app>
<div class="bg-back">
<button (click)="Admin()" class="back-btn-doc-room">Back To Admin </button>
<button  *ngIf="showaddbtn" type="button" data-toggle="modal" data-target="#target" class="mat-chip mat-primary mat-standard-chip mat-chip-selected"> + Add Contact Information</button>
</div>
<div class="card">
  <div class="card-body">
    <h1 class="card-title mb-4"><span class="select-title"><i class="fa fa-plus-square"></i>Contact Information</span>
    </h1>
   
    <table class="table">
      <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">Contact Number</th>
          <th scope="col">Email</th>
         <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="vertical-align: middle;"><span *ngIf="!showaddbtn" >1</span></td>
          <td style="vertical-align: middle;">{{ContactInfo.ContactNumber}}</td>
          <td style="vertical-align: middle;">{{ContactInfo.Email}}</td>
    
          <td> 
            <button 
            *ngIf="!showaddbtn" (click)="EditContactInfo()" class="btn btn-primary btn-sm mr-1" data-toggle="modal" data-target="#target1"><i class="fa fa-pencil"></i> Edit </button>
            <!-- <a (click)="DeleteContactInfo(data.HospitalId)" class="btn btn-danger btn-sm text-white"><i class="fa fa-trash"></i>Delete</a> -->
            
          </td>
        </tr>
      </tbody>
    </table>

    <div  class="modal fade " id="target" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
      aria-hidden="true" >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="myLargeModalLabel">Add Contact Information</h5>
            <button type="button" (click)="cleardata()" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="background-color:snow;">
            <div class="info-heading">
              <form [formGroup]="continfoForm" (ngSubmit)="AddContactInfo(continfoForm)">
                <table>
                  <tr class="3-cols">
                    <td>
                      <label>Contact Number<span class="required-sign">*</span></label>
                      <input type="text" name="ContactNumber" value="" [(ngModel)]="ContactInfo.ContactNumber" size="20"
                      formControlName="ContactNumber"/>
                    </td>
                    <td>
                      <label>Email<span class="required-sign">*</span></label>
                      <input type="email" name="email" value="" size="25"  [(ngModel)]="ContactInfo.Email" formControlName="Email"/>
                    </td>                    
                  </tr>                  
                </table>
                <div class="modal-footer">
                  <button class="btn btn-success" value="Submit">Add</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


  <div class="modal" id="target1" tabindex="-1" role="dialog" [style.display]="editModal ? 'block' : 'none'"
  aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="card-title" style="width: 100%;">
          Update Contatct Information
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
        </h1>
      </div>
      <div class="modal-body" style="background-color:snow;">
        <div class="info-heading">         
          <form [formGroup]="continfoForm" (ngSubmit)="UpdateContactInfo(continfoForm)">
            <table>
              <tr class="3-cols">
                <td>
                  <label>Contact Number<span class="required-sign">*</span></label>
                  <input type="text" name="ContactNumber" value="" [(ngModel)]="addeditcontactInfo.ContactNumber"
                  formControlName="ContactNumber"/>
                </td>
                <td>
                  <label>Email<span class="required-sign">*</span></label>
                  <input type="email" name="email" value="" size="25"  [(ngModel)]="addeditcontactInfo.Email" formControlName="Email"/>
                </td>                    
              </tr>                  
            </table>
            <div class="modal-footer">
              <button class="btn btn-success" value="Submit">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
</div>
