<app></app>

<bookingnavigator></bookingnavigator>

<div *ngIf="showZoomJoiningButton && !hideMeetingButton" style="display: flex; justify-content: center;">
  <button (click)="getZoomSignature()" class="join_meeting">Hello, Please Join the Meeting</button>
</div>

<div class="dashboard">
  <div id="meetingSDKElement" >
  </div>
  <div class="row">
    <div class="col-md-4 col-xs-4">
      <div class="patient-highlight mb-2 hi-this">
        <h1 class="text-white">Hello {{patientName}}!</h1>
        <p>
          Your call has been reached to the Doctor. Your Doctor will call you
          <span *ngIf="index == 0" class="counter">shortly,</span>
          <span *ngIf="index == 1" class="counter">after "1" patient,</span>
          <span *ngIf="index > 1" class="counter">after "{{index}}" patients,</span>
          Please stay calm and wait for the doctor to respond. Meanwhile you can
          <a href="https://certify.covid19.gov.np/" target="_blank" class="d-inline-block" style="color: yellow">
            Learn and Certify Yourself
          </a>
          or send message to your doctor below.
        </p>
      </div>
      <img src="./assets/img/Slider11.jpg" alt="slider-image" class="w-100" style="border-radius: 10px" />
    </div>
    <div class="col-md-4 col-xs-4">
      <div class="patient-highlight mb-2">
        <div class="title">
          <h5>
            <strong><i class="fa fa-comments" aria-hidden="true"></i>Send Message to your Doctor</strong>
          </h5>
        </div>
        <form [formGroup]="ChatForm" class="chat-messages">
          <div class="">
            <div class="alert alert-default p-1" style="background: #f9f9f9; border: 1px solid #f9f9f9">
              <p class="text-center">
                <em class="fa fa-info"></em>Start your conversiation
              </p>
            </div>
            <div class="message-box-holder" *ngFor="let msg of ChatMessages">
              <div *ngIf="msg.Name !='Me'" class="message-by-doc">
                <span>{{ msg.Message }}</span>
              </div>
              <div *ngIf="msg.Name =='Me'" class="message-by-me">
                <span>{{ msg.Message }}</span>
              </div>
            </div>
          </div>
          &nbsp; &nbsp; &nbsp;
          <div class="chat-input-holder message-box">
            <textarea class="chat-input" placeholder="Type your message here.." formControlName="chatMessage" (keyup)="onChatEnter($event)"></textarea>
            <div *ngIf="hasError('required','chatMessage')" style="color: red; font-size: 12px; margin-bottom: 7px">
              <i class="fa fa-info-circle" aria-hidden="true"></i>Chat message is required
            </div>
            <input type="button" value="Send" class="message-send btn-primary" (click)="SendChatMsg()" />
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-4 col-xs-4">
      <div class="patient-highlight mb-2">
        <h5>
          <strong><i class="fa fa-exclamation-circle" aria-hidden="true"></i>Your Step by Step
            Guide</strong>
        </h5>
        <ul style="padding-left: 15px; font-size: 13px">
          <li>First update your profile</li>
          <li>Book appointment with available doctors</li>
          <li>Make payment for your appointment</li>
          <li>Proceed to Doctor's Room to start your appointment</li>
        </ul>
      </div>
      <img src="./assets/img/Slider22.jpg" alt="slider-image" class="w-100" style="border-radius: 10px" />
    </div>
  </div>
</div>
<!-- <div id="meetingSDKElement" style="height: 50vh;">
</div> -->
<div class="alert alert-default d-none">
  <div class="row">
    <div class="col-md-5">
      <div class="p-message">
        <p class="mb-0">
          <button type="button" class="btn-send-message" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            <i class="fa fa-comments" aria-hidden="true"></i>Send Message to Dr: {{docName}} &nbsp;
          </button>
        </p>
        <div id="collapseExample" [ngbCollapse]="isCollapsed">
          <div class="card">
            <div class="card-body">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <h5 class="success">
        <strong style="color: blue">
          <i class="fa fa-exclamation-circle" aria-hidden="true" style="color: green; font-size: 50px; float: left; margin: 10px"></i>
          Hello {{patientName}}! Your call has been reached to the Doctor. Your
          Doctor will call you
          <span *ngIf="index == 0" class="counter">shortly,</span>
          <span *ngIf="index == 1" class="counter">after "1" patient,</span>
          <span *ngIf="index > 1" class="counter">after "{{index}}" patients,</span>
          Please stay calm and wait for the doctor to respond. Meanwhile you can
          <a href="https://certify.covid19.gov.np/" target="_blank" style="color: blue">
            Learn and Certify Yourself
          </a>
          or send message to your doctor below.
        </strong>
      </h5>
      <h4 style="margin: 15px auto">
        <strong>Your Step by Step Guide</strong>
      </h4>
      <ul style="padding-left: 15px; font-size: 13px">
        <li style="color: #666">
          First of all update your profile from the Update Profile page.
        </li>
        <li style="color: #666">
          After that go to the Book Appointment to bookthe appointment for your
          doctor
        </li>
        <li style="color: #666">
          Go to the payment window to pay for your booking
        </li>
        <li style="color: #666">
          After the payment, you will be redirected to the waiting room. Please
          wait there until doctor respond
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="loading-container" style="display: none">
  <div class="animated-item">
    <img src="../assets/img/video-camera3.svg" alt="" style="height: 50px; width: 50px" />
    <div class="waiting-info">
      <div class="pi-counter" style="padding-bottom: 10px">
        <span class="name">
          <i class="fa fa-user" aria-hidden="true"></i>{{patientName}}
        </span>
      </div>
      <div></div>
    </div>
  </div>
</div>

<footer></footer>
