import { Component, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Global } from './app.global';
import { MatDialog } from '@angular/material/dialog';
import { PaymentMessageComponent } from './PaymentMessage/app.paymentmessage.component';
import { IdToken } from './models/IdToken';
import { JwtHelper } from './models/JwtHelper';
import { DecodeTokenService } from './services/decodeTtoken.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.clinic.html'
})

export class ClinicComponent {
  public token: any;
  public hospitalId: any;
  constructor(public router: Router,
    public dialog: MatDialog,
    public decodeToken: DecodeTokenService,
    public authService: AuthenticationService,
    private elementRef: ElementRef) {
    let token: string = this.elementRef.nativeElement.getAttribute('loginToken');
    if (token != null) {
      decodeToken.decodeToken(token);
    }
    let paymentMethod: string = this.elementRef.nativeElement.getAttribute('paymentMethod');
    let paymentStatus: string = this.elementRef.nativeElement.getAttribute('paymentStatus');

    this.authService.IsTokenValid.subscribe(() => {
      let dialogMessage: string = "";
      if (paymentStatus == "success") {
        dialogMessage = "Payment through " + paymentMethod + " Successful.";
        this.router.navigate(['/PatPaidBookingList']);
        this.dialog.open(PaymentMessageComponent, { data: { message: dialogMessage, method: paymentMethod, status: paymentStatus } });
      }
      else if (paymentStatus == "failure" || paymentStatus == "cancel") {
        if (paymentStatus == "failure") {
          dialogMessage = "Payment through " + paymentMethod + " Failed.";
          this.dialog.open(PaymentMessageComponent, { data: { message: dialogMessage, method: paymentMethod, status: paymentStatus } });
        }
        else if (paymentStatus == "cancel") {
          dialogMessage = "Payment through " + paymentMethod + " Canceled.";
          this.dialog.open(PaymentMessageComponent, { data: { message: dialogMessage, method: paymentMethod, status: paymentStatus } });
        }
        this.router.navigate(['/PatBookList']);
        this.dialog.open(PaymentMessageComponent, { data: { message: dialogMessage, method: paymentMethod, status: paymentStatus } });
      }
      else {
        //navigate to this route if token in local storage is valild
        //this.router.navigate(['/HospitalList']);
      }
    },
    );
  }

}

