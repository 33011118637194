import { Component, Input } from '@angular/core'
import { Patient } from '../../../models/patient/patient.model';
import { PatientService } from '../../../services/patient/patient.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { AlertService } from '../../../services/alert.service';
import { Doctor } from '../../../models/doctor/doctor.model';
import { DoctorService } from '../../../services/doctor/doctor.service';
import { ChatService } from '../../../services/ChatNotification/chat-notification.service';

@Component({
  selector: 'password-update',
  templateUrl: './password.component.html'
})
export class PasswordComponent {
  @Input('show-old-and-confirm-password') showOldAndConfirmPasswordInput: boolean = false;
  public updatePat: boolean;
  public patInfo: Patient = new Patient();
  public docInfo: Doctor = new Doctor();
  isPatEditor = false;
  patId: any;
  patName: string;
  docId: any;
  docName: string;
  isDoctor = false;
  public hospitalIdentifier: string;
  showPassword = false;
  showConfirmPassword = false;
  showOldPassword = false;
  token: any;
  public changesSavedCallback: () => void;
  public changesCancelledCallback: () => void;
  constructor(public patservice: PatientService, public docservice: DoctorService, private route: ActivatedRoute, private alertService: AlertService,
    public routing: Router, private authenticationService: AuthenticationService, private datePipe: DatePipe,
    private formBuilder: FormBuilder, private notifyService: NotificationService, private notificationService: ChatService) {
      this.token = this.authenticationService.currentUserValue;

  }

  GetPatient() {

    this.patservice.getPatient(this.patId,this.token.UserType).subscribe(res => {
      this.patInfo = res;

    },
      error => {
        this.alertService.error(error);
      });
  }
  GetDoctor() {

    this.docservice.getDoctor(this.docId).subscribe(res => {
      this.docInfo = res;

    },
      error => {
        this.alertService.error(error);
      });
  }

  //UpdatePassword(row:Patient) {

  //  debugger;
  //  this.patservice.updatePatient(row).subscribe(data => {
  //    this.alertService.success('Password Reset successful', true);
  //    this.updatePat = false;
  //    if (this.changesSavedCallback) {
  //      this.changesSavedCallback();
  //    }
  //  },
  //    error => {
  //      this.alertService.error(error);

  //    });
  //}
  UpdatePassword(row: Patient) {
    if (this.isPasswordValid(row.Password, row.ConfirmPassword, row.OldPassword) == false) {
      return;
    }
    this.authenticationService.resetPassword(this.patInfo.IdentityUserId, row.Password, row.OldPassword).subscribe(
      data => {
        if (this.changesSavedCallback) {
          this.changesSavedCallback();
        }
        this.notificationService.LogoutUserWithId(this.patInfo.IdentityUserId);
      },
      error => {
        this.notifyService.showError("Password Reset Failed.", "Failed");
        this.alertService.error(error);
      });

  }
  UpdatePasswordDoctor(row: Doctor) {
    if (this.isPasswordValid(row.Password, row.ConfirmPassword, row.OldPassword) == false) {
      return;
    }
    this.authenticationService.resetPassword(this.docInfo.IdentityUserId, row.Password, row.OldPassword).subscribe(
      data => {
        if (this.changesSavedCallback) {
          this.changesSavedCallback();
        }
        this.notificationService.LogoutUserWithId(this.docInfo.IdentityUserId);
      },
      error => {
        this.notifyService.showError("Password Reset Failed.", "Failed");
        this.alertService.error(error);
      });

  }
  private isPasswordValid(password: string, confirmpassword?: string, oldpassword?: string): boolean {
    if (password.trim().length < 6) {
      this.notifyService.showError("Password must be at least 6 characters", "Failed");
      return false;
    }
    if (this.showOldAndConfirmPasswordInput) {
      if (!oldpassword) {
        this.notifyService.showError("Old password cannot be empty", "Failed");
        return false;
      }

      if (confirmpassword.trim().length < 6) {
        this.notifyService.showError("Confirm Password must be at least 6 characters", "Failed");
        return false;
      }

      if (password !== confirmpassword) {
        this.notifyService.showError("New Password and Confirmed Password must match.", "Failed");
        return false;
      }

      if (password === oldpassword) {
        this.notifyService.showError("New Password cannot be same as the old password", "Failed");
        return false;
      }
    }
    return true;
  }
  //public BackDashBoard() {
  //  this.routing.navigate(['/PatDashboard'], { queryParams: { id: this.hospitalId } });
  //}
  resetPwd(pat: Patient, patName: string) {
    this.patId = pat.PatientId;
    this.patName = patName;
    this.GetPatient();
    return this.patId;
  }
  resetPwdDoctor(pat: Doctor, docName: string, isDoctor: boolean) {
    this.docId = pat.DoctorId;
    this.docName = docName;
    this.isDoctor = isDoctor;
    this.GetDoctor();
    return this.docId;
  }
  cancel() {
    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }
  showPass() {
    this.showPassword = !this.showPassword;
  }
  showConfirmPass() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  showOldPass() {
    this.showOldPassword = !this.showOldPassword;
  }
}
