<app></app>
<div class="back-bg">
  <button [routerLink]="['/admin']" class="back-btn-doc-room">Back <span>To Admin</span> </button>
  <button (click)="AppMasterReport()" [style.background-color]="showAppMasterReport ? '#007bff' : '#009688'" class="mr-2">
    Appointment Master Report</button>
  <button (click)="DocOrderReport()" [style.background-color]="showDocOrderReport ? '#007bff' : '#009688'">
    Doctor's Order Report</button>
</div>

  <div *ngIf="showAppMasterReport" class="grid-section" >
    <h4 class="title">
      <i class="fa fa-list"></i>Appointment Master Report
    </h4>
    <div class="col-md-12 alert alert-default">
      <div class="row d-flex align-items-center">
        <!--<form [formGroup]="visitObj.PatientValidator">-->
        <label class="control-label" style="margin-left: 100px;">From Date:</label>
        <div class="col-md-3">
          <input type="date" name="date" [(ngModel)]="fromdate" required class="m-0" />
        </div>
        <label class="control-label" style="margin-left: 200px;"> To Date:</label>
        <div class="col-md-3">
          <input type="date" name="date" [(ngModel)]="todate" required class="m-0" />
        </div>
        <button (click)="Search()">Search</button>
        <!--<span *ngIf="!visitObj.IsDirty('VisitDate')" [hidden]="visitObj.IsValidCheck('VisitDate','required')"> Appointment Date is required</span>-->
        <!--</form>-->
      </div>
    </div>
    <div class="row searchbar-card text-center" style="margin-top: 0">
      <div class="col-md-6 col-sm-8 m-auto d-flex">
        <div class="searchbar">
          <input class="search_input" type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
                placeholder="Search Patient.." (click)="filterReport()">
          <a class="search_icon text-white"><i class="fa fa-search"></i>Search</a>
        </div>
      </div>
    </div>
    
    <div class="col-md-12">
      <div class="row d-flex align-items-center">
        <!-- <div class="form-group"> -->
        <label class="control-label col-md-2">Doctor:</label>
        <div class="col-md-3">
          <mat-select class="form-control"
          [(ngModel)]="selDoctor"
                  placeholder="Select Doctor Name"
                  display-property-name="FullName"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)"
                  >
                  <!-- (onClick)="this.select()" -->
                  <mat-option value="All" #selected>All</mat-option>
                  <mat-option *ngFor="let doctor of doctorList" [value]="doctor.DoctorId">{{doctor.DoctorName}}</mat-option>
          </mat-select>
        </div>
        <label class="control-label col-md-2"> Department:</label>
        <div class="col-md-3">
          <mat-select class="form-control"
          [(ngModel)]="selDept"
                  placeholder="Select Department Name"
                  display-property-name="FullName"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)"
                  >
                  <!-- (onClick)="this.select()" -->
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option *ngFor="let dept of deptList" [value]="dept.DepartmentId">{{dept.DepartmentName}}</mat-option>
          </mat-select>
        </div>
        <!-- </div> -->
      </div>
      <div class="row d-flex align-items-center mt-2">
        <label class="control-label col-md-2"> Appointment Type:</label>
        <div class="col-md-3">
          <mat-select class="form-control"
          [(ngModel)]="selAppointmentType"
                  placeholder="Select Appointment Type"
                  display-property-name="FullName"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)"
                  >
                  <!-- (onClick)="this.select()" -->
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option value="Telemedicine">Telemedicine</mat-option>
                  <mat-option value="Online Appointment">Online Appointment</mat-option>
          </mat-select>
        </div>
        <label class="control-label col-md-2"> Visit Type:</label>
        <div class="col-md-3">
          <mat-select class="form-control"
          [(ngModel)]="selVisitType"
                  placeholder="Select Visit Type"
                  display-property-name="FullName"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)"
                  >
                  <!-- (onClick)="this.select()" -->
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option value="New">New</mat-option>
                  <mat-option value="Follow-Up">Follow-Up</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row d-flex align-items-center mt-2">
        <label class="control-label col-md-2"> Payment Status:</label>
        <div class="col-md-3">
          <mat-select class="form-control"
          [(ngModel)]="selPaymentStatus"
                  placeholder="Select Payment Status"
                  display-property-name="FullName"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)"
                   >
                    <!-- (onClick)="this.select()" -->
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option value="paid">Paid</mat-option>
                  <mat-option value="unpaid">Unpaid</mat-option>
                  <mat-option value="free">Free</mat-option>
          </mat-select>
        </div>
        <label class="control-label col-md-2"> Payment Medium:</label>
        <div class="col-md-3">
          <mat-select class="form-control"
          [(ngModel)]="selPaymentMedium"
                  placeholder="Select Payment Medium"
                  display-property-name="FullName"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)"
                  >
                  <!-- (onClick)="this.select()" -->
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option value="E-Sewa">E-Sewa</mat-option>
                  <mat-option value="Khalti">Khalti</mat-option>
          </mat-select>
        </div>
      </div>
      
    </div>
    <div class="col-md-12" style="padding: 5px; text-align: right;">
      <div class="form-actions">
        <button class="btn btn-primary hidden-print" (click)="PrintTxnTable()" value="Print" title="print page">
          Print<i class="fa fa-print" style="margin-left:10px;"></i>
        </button>
        <button class="btn btn-primary hidden-export" (click)="ExportToExcel('exportTable')" value=""
          title="export to excel">
          Export<i class="fa fa-file-excel-o" style="margin-left:10px;"></i>
        </button>
      </div>
    </div>
    <!-- for-mobile -->
    <!-- class="d-sm-none d-md-block" -->
    <div style="overflow: scroll;">
      <table class="table table-striped table-hover ">
        <thead>
          <tr class="table-tr-style">
            <th scope="col">SN</th>
            <th scope="col">Date and Time</th>
            <th scope="col">Patient Name</th>
            <th scope="col">Age/Gender</th>
            <th scope="col">Contact Number</th>
            <th scope="col">Address</th>
            <th scope="col">Department</th>
            <th scope="col">Doctor Name</th>
            <th scope="col">Appointment Type</th>
            <th scope="col">Visit Type</th>
            <th scope="col">Payment status</th>
            <th scope="col">Payment medium</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of filteredpatientList | filter: searchText |paginate: { itemsPerPage: 10, currentPage: p  } ;let i = index  ">
            <td>{{10 *(p-1)+(i+1)}}</td>
            <td>{{data.VisitDate | date:'mediumDate'}} and {{data.BookingTime}}</td>
            <td>{{data.FirstName }}&nbsp;{{data.MiddleName}}&nbsp;{{data.LastName}} {{data.PatientName}}</td>
            <td>{{data.Age }}/{{data.Gender }}</td>
            <td>{{data.ContactNumber }}</td>
            <td>{{data.Address }}</td>
            <td>{{data.DepartmentName }}</td>
            <td>{{data.DoctorName }}</td>
            <td>{{data.AppointmentType }}</td>
            <td>{{data.VisitType }}</td>
            <td>{{data.PaymentStatus }}</td>
            <td>{{data.PaymentMedium }}</td>
            <td>{{data.Amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> -->
    <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>

    <div style="overflow: scroll; display: none;" id="printpage">
      <table id="exportTable" class="table table-striped table-hover ">
        <thead>
          <tr class="table-tr-style">
            <th scope="col">SN</th>
            <th scope="col">Date and Time</th>
            <th scope="col">Patient Name</th>
            <th scope="col">Age/Gender</th>
            <th scope="col">Contact Number</th>
            <th scope="col">Address</th>
            <th scope="col">Department</th>
            <th scope="col">Doctor Name</th>
            <th scope="col">Appointment Type</th>
            <th scope="col">Visit Type</th>
            <th scope="col">Payment status</th>
            <th scope="col">Payment medium</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of filteredpatientList | filter: searchText ;let i = index  ">
            <td>{{i+1}}</td>
            <td>{{data.VisitDate | date:'mediumDate'}} and {{data.BookingTime}}</td>
            <td>{{data.FirstName }}&nbsp;{{data.MiddleName}}&nbsp;{{data.LastName}} {{data.PatientName}}</td>
            <td>{{data.Age }}/{{data.Gender }}</td>
            <td>{{data.ContactNumber }}</td>
            <td>{{data.Address }}</td>
            <td>{{data.DepartmentName }}</td>
            <td>{{data.DoctorName }}</td>
            <td>{{data.AppointmentType }}</td>
            <td>{{data.VisitType }}</td>
            <td>{{data.PaymentStatus }}</td>
            <td>{{data.PaymentMedium }}</td>
            <td>{{data.Amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div *ngIf="showDocOrderReport" class="grid-section">
    <h4 class="title">
      <i class="fa fa-list"></i>Doctor's Order Report
    </h4>
    <div class="col-md-12 alert alert-default">
      <div class="row d-flex align-items-center">
        <!--<form [formGroup]="visitObj.PatientValidator">-->
        <label class="control-label" style="margin-left: 100px;">From Date:</label>
        <div class="col-md-3">
          <input type="date" name="date" [(ngModel)]="fromdate" required class="m-0" />
        </div>
        <label class="control-label" style="margin-left: 200px;"> To Date:</label>
        <div class="col-md-3">
          <input type="date" name="date" [(ngModel)]="todate" required class="m-0" />
        </div>
        <button (click)="Search()">Search</button>
        <!--<span *ngIf="!visitObj.IsDirty('VisitDate')" [hidden]="visitObj.IsValidCheck('VisitDate','required')"> Appointment Date is required</span>-->
        <!--</form>-->
      </div>
    </div>
    <div class="row d-flex align-items-center mt-2 ml-2">
      <div class="row col-md-6">
        <label class="control-label col-md-3 mt-1"> Item Name:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selItem"
                  placeholder="Select Item"
                  display-property-name="Item Name"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row col-md-6">
        <label class="control-label col-md-3 mt-1">Doctor Name:</label>
          <div class="col-md-8">
            <mat-select class="form-control"
            [(ngModel)]="selDoctor"
                    placeholder="Select Doctor Name"
                    display-property-name="Doctor Name"
                    value-property-name=null
                    (selectionChange)="MainSearch($event)">
                    <mat-option value="All" #selected>All</mat-option>
                    <mat-option *ngFor="let doctor of doctorList" [value]="doctor.DoctorId">{{doctor.DoctorName}}</mat-option>
            </mat-select>
          </div>
      </div>
    </div>
    <div class="row d-flex align-items-center mt-2 ml-2">
      <div class="row col-md-6">
        <label class="control-label col-md-3 mt-1"> Department Name:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selDept"
                  placeholder="Select Department"
                  display-property-name="Department"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option *ngFor="let dept of deptList" [value]="dept.DepartmentId">{{dept.DepartmentName}}</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row col-md-6">
        <label class="control-label col-md-3 mt-1"> Appointment Type:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selAppointmentType"
                  placeholder="Select Appointment Type"
                  display-property-name="AppointmentType"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option value="Telemedicine">Telemedicine</mat-option>
                  <mat-option value="Online Appointment">Online Appointment</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-center mt-2 ml-2">
      <div class="row col-md-6">
        <label class="control-label col-md-3 mt-1"> Visit Type:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selVisitType"
                  placeholder="Select Visit Type"
                  display-property-name="VisitType"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option value="New">New</mat-option>
                  <mat-option value="Follow-Up">Follow-Up</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="row col-md-12 mt-2">
      <div class="col-md-10" style="margin-top: 20px; text-align: right;">
        <div *ngIf="!showDetailView" >
          <label class="mt-checkbox mt-checkbox-outline" style="color:#777;">
            <input type="radio" value="" [(ngModel)]="showDetailView"
              (change)="DetailedView()">
            <span></span>
            <label style="position: relative; top: -2px;" class="text-primary">Detailed View</label>
          </label>
        </div>
        <div *ngIf="showDetailView" >
          <label class="mt-checkbox mt-checkbox-outline" style="color:#777;">
            <input type="radio" value="" [(ngModel)]="showDetailView"
              (change)="SummaryView()">
            <span></span>
            <label style="position: relative; top: -2px;" class="text-primary">Summary View</label>
          </label>
        </div>
      </div>
      <div class="col-md-2" style="padding: 5px; text-align: right;">
      
        <div class="form-actions">
          <button class="btn btn-primary hidden-print" style="margin-right: 10px;" (click)="PrintTxnTable()" value="Print" title="print page">
            Print<i class="fa fa-print" style="margin-left:10px;"></i>
          </button>
          <button class="btn btn-primary hidden-export" (click)="ExportToExcel('exportTable')" value=""
            title="export to excel">
            Export<i class="fa fa-file-excel-o" style="margin-left:10px;"></i>
          </button>
        </div>
      </div>
    </div>
    
    <div *ngIf="!showDetailView">
      <div style="overflow: scroll;">
        <table class="table table-striped table-hover ">
          <thead>
            <tr class="table-tr-style">
              <th scope="col">SN</th>
              <th scope="col">Date</th>
              <th scope="col">Doctor Name</th>
              <th scope="col">Item Name</th>
              <th scope="col">Department</th>
              <th scope="col">Appointment Type</th>
              <th scope="col">Visit Type</th>
              <th scope="col">Total Quantity</th>
              <th scope="col">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of filteredpatientList |paginate: { itemsPerPage: 10, currentPage: p  } ;let i = index  ">
              <td>{{10 *(p-1)+(i+1)}}</td>
              <td>{{data.VisitDate | date:'mediumDate'}}</td>
              <td>{{data.DoctorName }}</td>
              <td>{{data.ItemName}}</td>
              <td>{{data.DepartmentName }}</td>
              <td>{{data.AppointmentType }}</td>
              <td>{{data.VisitType }}</td>
              <td>{{data.TotalQuantity }}</td>
              <td>{{data.TotalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="overflow: scroll; display: none;" id="printpage">
        <table id="exportTable" class="table table-striped table-hover ">
          <thead>
            <tr class="table-tr-style">
              <th scope="col">SN</th>
              <th scope="col">Date</th>
              <th scope="col">Doctor Name</th>
              <th scope="col">Item Name</th>
              <th scope="col">Department</th>
              <th scope="col">Appointment Type</th>
              <th scope="col">Visit Type</th>
              <th scope="col">Total Quantity</th>
              <th scope="col">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of filteredpatientList ;let i = index  ">
              <td>{{i+1}}</td>
              <td>{{data.VisitDate | date:'mediumDate'}}</td>
              <td>{{data.DoctorName }}</td>
              <td>{{data.ItemName}}</td>
              <td>{{data.DepartmentName }}</td>
              <td>{{data.AppointmentType }}</td>
              <td>{{data.VisitType }}</td>
              <td>{{data.TotalQuantity }}</td>
              <td>{{data.TotalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="showDetailView">
      <div style="overflow: scroll;">
        <table class="table table-striped table-hover ">
          <thead>
            <tr class="table-tr-style">
              <th scope="col">SN</th>
              <th scope="col">Date</th>
              <th scope="col">Doctor Name</th>
              <th scope="col">Item Name</th>
              <th scope="col">Department</th>
              <th scope="col">Appointment Type</th>
              <th scope="col">Visit Type</th>
              <th scope="col">Patient Name</th>
              <th scope="col">Age/Gender</th>
              <th scope="col">Total Quantity</th>
              <th scope="col">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of filteredpatientList |paginate: { itemsPerPage: 10, currentPage: p  } ;let i = index  ">
              <td>{{10 *(p-1)+(i+1)}}</td>
              <td>{{data.VisitDate | date:'mediumDate'}}</td>
              <td>{{data.DoctorName }}</td>
              <td>{{data.ItemName}}</td>
              <td>{{data.DepartmentName }}</td>
              <td>{{data.AppointmentType }}</td>
              <td>{{data.VisitType }}</td>
              <td>{{data.PatientName }}</td>
              <td>{{data.Age }}/{{data.Gender }}</td>
              <td>{{data.TotalQuantity }}</td>
              <td>{{data.TotalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="overflow: scroll; display: none;" id="printpage">
        <table id="exportTable" class="table table-striped table-hover ">
          <thead>
            <tr class="table-tr-style">
              <th scope="col">SN</th>
              <th scope="col">Date</th>
              <th scope="col">Doctor Name</th>
              <th scope="col">Item Name</th>
              <th scope="col">Department</th>
              <th scope="col">Appointment Type</th>
              <th scope="col">Visit Type</th>
              <th scope="col">Patient Name</th>
              <th scope="col">Age/Gender</th>
              <th scope="col">Total Quantity</th>
              <th scope="col">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of filteredpatientList ;let i = index  ">
              <td>{{i+1}}</td>
              <td>{{data.VisitDate | date:'mediumDate'}}</td>
              <td>{{data.DoctorName }}</td>
              <td>{{data.ItemName}}</td>
              <td>{{data.DepartmentName }}</td>
              <td>{{data.AppointmentType }}</td>
              <td>{{data.VisitType }}</td>
              <td>{{data.PatientName }}</td>
              <td>{{data.Age }}/{{data.Gender }}</td>
              <td>{{data.TotalQuantity }}</td>
              <td>{{data.TotalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> -->
    <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>

  </div>

  <footer></footer>
