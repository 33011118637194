
<div class="kt-form">
  <div class="kt-portlet__body">
    <div class="row">
      <label for="type" class="col-sm-4 col-form-label">Appointment type</label>
      <div class="col-sm-8 input-text">
        <mat-form-field class="mat-form-field-fluid select-input" appearance="outline">
          <mat-select placeholder="Choose your Address Appointment">
            <mat-option *ngFor="let appointmentType of listAppointmentType"
                        [value]="appointmentType.id">{{appointmentType.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row ">
      <label for=" type" class="col-sm-4 col-form-label">Select Date and Time</label>
      <div class="col-sm-8 row  select-input mat-form-field-wrapper">
        <div class="col-sm-5 time-picker">
          <input class="form-control" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
                 placeholder="Select Start Time">
          <owl-date-time #dt1></owl-date-time>
        </div>
        <div class="col-sm-1 label-to">
          <label>To</label>
        </div>
        <div class="col-sm-5 time-picker">
          <input class="form-control" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2"
                 placeholder="Select End Time" [(ngModel)]="endtime">
          <owl-date-time #dt2></owl-date-time>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="selecTimeErr">
      <label for="type" class="col-sm-4 col-form-label">
      </label>
      <label for="type" class="col-sm-8 col-form-label time-error">
        {{selecTimeErrText}}
      </label>
    </div>
    </div>
  </div>
