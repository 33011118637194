import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { EndpointFactory } from "../endpoint-factory.service";
import { catchError, map } from "rxjs/operators";
import { Meeting } from "src/app/models/Meeting/meeting.model";

@Injectable()
export class MeetingEndPoint extends EndpointFactory {
  private readonly _meetingsListUrl: string =
    "/api/ZoomFeature/GetZoomMeetingSetUpDetails";
  private readonly _addMeetingUrl: string = "/api/zoomFeature/AddZoomSetUpDetail";
  private readonly _updateMeetingUrl: string =
    "/api/ZoomFeature/UpdateZoomMeetingSetUp";

  constructor(http: HttpClient, injector: Injector) {
    super(http, injector);
  }

  scheduleMeetingEndpoint<T>(userObject: any): Observable<T> {
    return this.http
      .post<T>(
        this._addMeetingUrl,
        JSON.stringify(userObject),
        this.getRequestHeaders()
      )
      .pipe(
        catchError((error) => {
          return throwError(
            this.handleError(error, () =>
              this.scheduleMeetingEndpoint(userObject)
            )
          );
        })
      );
  }
  getMeetingsListEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this._meetingsListUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError((error) => {
        return throwError(
          this.handleError(error, () => this.getMeetingsListEndpoint())
        );
      })
    );
  }
  updateMeetingEndPoint<T>(meetingObject: Meeting, Id: string): Observable<T> {
    let endpointUrl = Id ? `${this._updateMeetingUrl}` : this._updateMeetingUrl;

    return this.http
      .put<T>(
        endpointUrl,
        JSON.stringify(meetingObject),
        this.getRequestHeaders()
      )
      .pipe(
        catchError((error) => {
          return throwError(
            this.handleError(error, () =>
              this.updateMeetingEndPoint(meetingObject, Id)
            )
          );
        })
      );
  }
  // updateHospitalEndpoint<T>(userObject: any, Id: string): Observable<T> {
  //   let endpointUrl = Id ? `${this._hospitalUpdateUrl}/${Id}` : this._hospitalUpdateUrl;

  //   return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
  //     catchError(error => {
  //       return throwError(this.handleError(error, () => this.updateHospitalEndpoint(userObject, Id)));
  //     }));
  // }
}
