<div class="row">
       <div class="col-12">
        <mat-dialog-actions align="end">
          <button mat-button mat-dialog-close style="position: absolute; right: -27px; top: -15px; font-size: 20px;" class="mat-button mat-primary">
            <span>
              <i class="fa fa-times"></i>
            </span>
          </button>
        </mat-dialog-actions>

          <div class="doc-schedule concent-form">
           Patient’s Name: <span class="text-primary-green">{{patInfo.FirstName}} {{ patInfo.MiddleName ? patInfo.MiddleName : ''}}
           {{ patInfo.LastName}} Birthdate: {{patInfo.DateOfBirth | date:'mediumDate'}}</span>
           <ol>
             <li>
               I understand that my health care provider, Dr. {{docInfo.FirstName}}
               {{docInfo.MiddleName ? docInfo.MiddleName : '' }} {{docInfo.LastName}}, has
               recommended to me that I engage in a telehealth appointment with provider.
             </li>
             <li>
               My health care provider has explained to me how the telehealth technology will be used
               to connect me with a provider.
               Telehealth appointments may be conducted by videoconferencing, video images, still
               (high quality photo) images, or by telephone conference.
               I understand that this appointment will not be the same as a direct patient/health
               care provider visit due to the fact that I will not be in the same room as my health
               care provider.
               I understand that the health care provider may use devices such as a stethoscope or
               otoscope or other peripheral devices to assist in the examination.
             </li>
             <li>
               I understand there are potential risks to this technology, including interruptions,
               unauthorized access and technical difficulties.
               I understand that my health care provider or I can discontinue the telehealth
               appointment if it is felt that the videoconferencing connections are not adequate for
               the situation.
               I understand that I can discontinue the telehealth appointment at any time
             </li>
             <li>
               I understand that my healthcare information may be shared with other individuals for
               scheduling and billing purposes.
               Others may also be present during the appointment other than my healthcare provider
               and specialty health care provider in order to operate the equipment. The above
               mentioned people will all maintain confidentiality of the information obtained.
               I further understand that I will be informed of their presence during the
               consultation and thus will have the right to request the following:
               (1) omit specific details of my medical history/physical examination that are
               personally sensitive to me;
               (2) ask non-medical personnel to leave the telehealth examination room; and/or
               (3) terminate the telehealth appointment at any time.
             </li>
             <li>
               I have had the alternatives to a telehealth appointment explained to me, and in
               choosing to participate in a telehealth appointment,
               I understand that some parts of the exam involving physical tests may be conducted by
               individuals at my location at the direction of the specialty health care provider or
               the primary care provider.
             </li>
             <li>
               In an emergency situation, I understand that the responsibility of the telehealth
               specialist or provider may be to direct me to emergency medical services,
               such as emergency room. Or the telehealth provider may discuss with and advise my
               local provider. The telehealth specialist’s or provider’s responsibility will end
               upon the termination of the telehealth connection.
             </li>
             <li>
               I understand that billing for the telehealth consultation may occur from 1) the primary
               care provider and
               2) telehealth provider, and 3) as a facility fee from the site from which I am
               presented. Billing is at the discretion of the provider. Billing procedures will be
               explained to me.
             </li>
             <li>
               I have read this document carefully, and understand the risks and benefits of the
               telehealth appointment and have had my questions regarding the procedure explained
               and I hereby consent to participate in a telehealth appointment visit under the terms
               described herein.
             </li>

           </ol>
           <ol>
            
               Disclaimer
           
             <li>
               {{hosInfo.HospitalName}}, {{hosInfo.Location}} is offering telemedicine or Video consults where patients are not being able to reach the hospital.
             </li>
             <li>
               A video or telemedicine consult can never be compared to a normal in-hospital consult where the doctor is able to physically examine the patient.
                 You are advised to come to the hospital for consults whenever you are in a position for the same. If the consult cannot wait, then only you should opt for a tele-medicine or video consult.
             </li>
             <li>
               You are advised to confirm the diagnosis, treatment and prescription whenever  you are able to come to the hospital for a physical consult.
                 In case your symptoms/conditions does not improve, immediately reach the nearest hospital.
             </li>
             <li>
               By accepting telemedicine consultation, you agree and accept that the tele consultants/ doctors and all personnel directly or indirectly involved with any part of the telemedicine set up shall not be held responsible in the unlikely event of an error in diagnosis or management due to the occurence of different technical conditions.
                 While every attempt will be made to ensure ideal conditions, unforeseen situations may occur.
             </li>
             <li>
               {{hosInfo.HospitalName}} and/or its doctors shall not be responsible for complete accuracy of telemedicine consultation, limited in its scope as it is,
                 with no physical examination of the patient being possible. While every attempt will be made to ensure comprehensiveness of the consultation, unforeseen situations may arise.
                 Your accepting telemedicine consultation will be taken as your consult for a telemedicine consent with its ingrained limitations.
             </li>
           </ol>

           <mat-dialog-actions align="end">
             <button mat-button [mat-dialog-close]="true" class="btn btn-primary btn-sm"> I Agree</button>
           </mat-dialog-actions>

         </div>
       </div>

</div>
