import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { hostname } from 'os';
import { Hospital } from 'src/app/models/hospital/hospital.model';
import { ProductModel } from 'src/app/models/Product/product.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HospitalService } from 'src/app/services/hospital/hospital.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  token: any;
  AllProductsList: Array<ProductModel> = new Array<ProductModel>();
  ParentProducts: Array<ProductModel> = new Array<ProductModel>();
  ProductDetails: ProductModel = new ProductModel();
  editRecord: boolean = false;
  IsParentGroupedProduct: boolean = false;
  editMode: boolean = false;
  showProductAddEditModel: boolean = false;
  showActivateDeactivatePopUp: boolean = false;
  productId: number = 0;
  hospitalList: Array<any> = new Array<any>();
  searchText: any;
  p: number = 1;
  isPreview: boolean = false;
  selectedProduct: ProductModel;
  AdminId: string;

  constructor(public routing: Router, public productService: ProductService, public authService: AuthenticationService, public notificationService: NotificationService,private authenticationService: AuthenticationService,
    public hospitalService: HospitalService) {

    this.token = this.authService.currentUserValue;
    this.getAllProducts();
    this.productService.GetAllHospital();
    this.productService.GetAllProductTags();
    this.productService.GetAllProductTypes();
  }

  ngOnInit() {
    this.hospitalList = this.productService.hospitalList;

  }
  public getAllProducts() {
    this.productService.getAllProducts().subscribe(
      res => {
        if (res)
          this.AllProductsList = Object.assign(this.AllProductsList, res);
        this.ParentProducts = this.AllProductsList.filter(a => a.IsActive == true);
      },
      err => {
        this.notificationService.showWarning(err, 'Error!');
      });
  }


  public EditProduct(productId) {
    this.routing.navigate([`/Product/${productId}/Edit`]);
  }

  public Preview(productId) {
    this.isPreview = true;
    this.productId = productId;
  }

  public ShowActivateDeactivatePopUp(product) {
    this.selectedProduct = product;
    this.showActivateDeactivatePopUp = true;
  }

  public CloseActivateDeactivatePopUp() {
    this.showActivateDeactivatePopUp = false;
    this.selectedProduct = null;
  }

  public ActivateDeactivateProductEndpoint() {
    const token = this.authenticationService.currentUserValue;

    let adminId: string;

    if (token) {
      adminId = token.unique_name;
    }
    this.productService.activateDeactivateProductEndpoint(this.selectedProduct.Id, adminId).subscribe(
      res => {
        this.selectedProduct.IsActive = !this.selectedProduct.IsActive;

        if (this.selectedProduct.IsActive)
          this.notificationService.showSuccess('The Product has been activated sucessfully', 'Activated');
        else
          this.notificationService.showSuccess('The Product has been deactivated sucessfully', 'Deactivated');

        this.CloseActivateDeactivatePopUp();

      },
      err => {
        this.notificationService.showError(err, 'Error');
      });
  }

  public Close() {
    this.isPreview = false;
  }
}
