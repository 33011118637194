<app></app>
<div class="Main">
  <div class="dashboard">
    <div class="card-body pt-2" style="padding: 0px !important">
      <div class="Book-Update" *ngIf="!updatePat">
        <ul class="doc-actions patient-actions p-0">
          <li class="d-inline mr-2">
            <button (click)="BookAppointment()" class="btn">
              <i class="fa fa-calendar" aria-hidden="true"></i>Book Appointment
            </button>
          </li>
          <li class="d-inline mr-2">
            <button class="btn" (click)="UpdateProfile()">
              <i class="fa fa-user" aria-hidden="true"></i>Update
              Profile</button>
          </li>
          <li class="d-inline mr-2">
            <button class="btn" (click)="BookingList()">
              <i class="fa fa-book" aria-hidden="true"></i>My Booking
              List</button>
          </li>
          <!-- <li class="d-inline mr-2">
            <button class="btn" (click)="PaidBookingList()">
              <i class="fa fa-book" aria-hidden="true"></i>My Paid
              Booking List</button>
          </li> -->
          <li class="d-inline mr-2">
            <button class="btn" (click)="PatientHistory()">
              <i class="fa fa-clock-o" aria-hidden="true"></i>History
            </button>
          </li>
          <li class="d-inline mr-2">
            <button class="btn" (click)="PatientMedicalRecord()">
              <i class="fa fa-book" aria-hidden="true"></i>Medical Record
            </button>
          </li>
          <li class="d-inline mr-2">
            <button class="btn" (click)="PatientLabReports()">
              <i class="fa fa-book" aria-hidden="true"></i>Lab Reports
            </button>
          </li>
          <li class="d-inline mr-2">
            <button *ngIf="updatePat">Back To DashBoard

            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xs-6">
        <div class="patient-highlight mb-2">
          <h1>
            Dear, {{patInfo.FirstName +' '+ patInfo.LastName }}
          </h1>
          <p>
            We believe that everyone deserves excellent health care. Our
            dedicated team of doctors provide compassionate care with a personal
            touch.
          </p>
          <div style="display: flex; max-width: fit-content;">
            <a (click)="BookAppointment()" class="btn btn-primary btn-small text-white"><i class="fa fa-calendar"
              aria-hidden="true"></i>Book
              Appointment</a>
            <a (click)="UpdateProfile()" class="btn btn-outline-secondary btn-small ml-2"><i class="fa fa-user"
              aria-hidden="true"></i>Update
              Your Profile</a>
            <div *ngIf="showFollowupVisit" >
              <div >
                <a (click)="Followup(followupVisit)"
                class="btn btn-primary btn-outline-secondary btn-small ml-2 text-white"> FollowUp </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-md-6 col-xs-6" style="color: #059fa8;">
        <div class="patient-highlight mb-2">
          <h5>
            <strong>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>Your Step by Step Guide
            </strong>
          </h5>
          <ul style="padding-left: 15px; font-size: 13px">
            <li>First update your profile</li>
            <li>Book appointment with available doctors</li>
            <li>Make payment for your appointment</li>
            <li>Proceed to Doctor's Room to start your appointment</li>
          </ul>
        </div>
      </div>
      <div *ngIf="showNearestVisit" class="col-md-12 col-xs-12">
        <div class="alert alert-danger" style="padding-left: 60px">
          <i class="fa fa-exclamation-triangle exa-sign" aria-hidden="true"></i>
          Hello <strong> {{patInfo.FirstName +' '+ patInfo.LastName }} </strong> !
          Your doctor is now {{IsDocOnline}}, Please click the link to start the appointment with your doctor.
          <a (click)="GotoWaitingRoom(nearestVisit)" class="btn btn-danger btn-sm ml-2 text-white">
            Enter Waiting Room
          </a>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>My Bookings</h5>
            <ul ngbNav [destroyOnHide]="false" class="booking-tab"  style="overflow: scroll;">

              <li>
                <ng-container ngbNavContent>
                  <table aria-label="My Booking List" class="table table-striped">
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>VisitDate</th>
                        <th>VisitTime</th>
                        <th>PatientName</th>
                        <th>DoctorName</th>
                        <th>Department</th>
                        <th>Payment Medium</th>
                        <th style="text-align: center;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let data of allpatBookList  |paginate: {id:'firstPagination', itemsPerPage: 5, currentPage: p  } ; let i=index ;"
                      >
                        <td>{{i+1}}</td>
                        <td>{{data.VisitDate}}</td>
                        <td>{{data.BookingTime}}</td>
                        <td>{{data.PatientFamilyName ? data.PatientFamilyName : data.PatientName}}</td>
                        <td>{{data.DoctorName}}</td>
                        <td>{{data.DepartmentName}}</td>
                        <td *ngIf="data.PaymentMethod === 0">
                          <label class="text-success">
                            <i class="fa fa-check" aria-hidden="true"></i>Free
                          </label>
                        </td>
                        <td *ngIf="data.PaymentMethod === 1">
                          <label class="text-success">
                            <i class="fa fa-check" aria-hidden="true"></i>Esewa
                          </label>
                        </td>
                        <td *ngIf="data.PaymentMethod === 2">
                          <label class="text-success">
                            <i class="fa fa-check" aria-hidden="true"></i>Khalti
                          </label>
                        </td>
                        <td *ngIf="data.PaymentMethod === 3">
                          <label class="text-success">
                            <i class="fa fa-check" aria-hidden="true"></i>Nabil Card
                          </label>
                        </td>
                        <td *ngIf="data.PaymentMethod === 4">
                          <label class="text-success">
                            <i class="fa fa-check" aria-hidden="true"></i>Connect IPS
                          </label>
                        </td>
                        <td *ngIf="data.Showpaid">
                          <label class="text-success">UnPaid</label>
                        </td>
                        <td *ngIf="data.PaymentStatus == 'unpaid'">
                          <button
                            style="background-color: #1e1ee2"
                            (click)="paymentselection(data)"
                          >
                            Pay
                          </button>
                        </td>
                        <td *ngIf="data.PaymentStatus == 'paid'">
                          <label class="text-success">
                            <i class="fa fa-check" aria-hidden="true"></i>Paid
                          </label>
                        </td>
                        <td *ngIf="data.PaymentStatus == 'free'">
                          <label class="text-info">
                            <i class="fa fa-check-circle" aria-hidden="true"></i
                            >Free
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <pagination-controls style="font-size: 20px" id="firstPagination"
                    (pageChange)="p = $event"></pagination-controls>
                </ng-container>
              </li>

              <li>
                <ng-template ngbNavContent>
                  <table aria-label="Paid Booking List" class="table table-striped">
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Visit Date</th>
                        <th>Doctor</th>
                        <th>Department</th>
                        <th style="text-align: center;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let data of patBookList |paginate: { id:'secondPagination', itemsPerPage: 5, currentPage: q  } ; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{data.VisitDate}}</td>
                        <td>{{data.DoctorName}}</td>
                        <td>{{data.DepartmentName}}</td>
                        <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing')
                                  && data.GoToWaitingRoom == true
                                  && data.AccessPermission  == true
                                  && data.EntryStatus == 'go'
                                  && (data.PaymentStatus == 'paid' || data.PaymentStatus == 'free')
                                  && data.VisitType =='Telemedicine' )">
                          <button (click)="GotoWaitingRoom(data)">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>Go to Waiting Room
                          </button>
                        </td>
                        <td
                          *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') && data.GoToWaitingRoom == false && data.AccessPermission  == false && data.EntryStatus == 'todaypast')">
                          <label class="text-warning">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>Visit time was
                            {{data.BookingTime}}
                          </label>
                        </td>
                        <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') &&
                          data.GoToWaitingRoom == false &&
                          data.AccessPermission  == false &&
                          data.EntryStatus == 'todayupcoming'  &&
                          (data.PaymentStatus == 'paid' || data.PaymentStatus == 'free') )">
                          <label class="text-success">
                            <i class="fa fa-clock-o" aria-hidden="true"></i>Visit time on {{data.BookingTime}}
                          </label>
                        </td>
                        <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') &&
                         data.GoToWaitingRoom == false &&
                         data.AccessPermission  == false  &&
                         data.EntryStatus == 'upcoming' &&
                         (data.PaymentStatus == 'paid' || data.PaymentStatus == 'free') )">
                          <label class="text-success">
                            <i class="fa fa-calendar" aria-hidden="true"></i>Visit date on {{data.VisitDate |
                            date:'mediumDate' }} at
                            {{data.BookingTime}}
                          </label>
                        </td>
                        <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') &&
                          data.GoToWaitingRoom == false &&
                          data.AccessPermission  == false  &&
                          data.EntryStatus == 'missed')">
                          <label class="text-warning">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Visit date was
                            {{data.VisitDate | date:'mediumDate'}}
                          </label>
                        </td>
                        <td *ngIf="data.Status =='completed' &&
                          data.PaymentStatus != 'unpaid' ">
                          <label class="text-success">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>Already Visited</label>
                        </td>
                        <td
                          *ngIf="(data.PaymentStatus == 'unpaid' &&
                         (data.Status == 'initiated' || data.Status == 'ongoing') &&
                         (data.EntryStatus == 'go' || data.EntryStatus == 'todayupcoming' || data.EntryStatus == 'upcoming'))">
                          <button class="btn btn-sm btn-danger">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>Pay First
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <pagination-controls style="font-size: 20px" id="secondPagination"
                    (pageChange)="q = $event"></pagination-controls>
                </ng-template>
              </li>
            </ul>
          </div>
        </div>
        <div class="card mb-4" style="display: none;">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 d-flex justify-content-between">
                <h5 class="text-left pb-3">
                  All Booking List<span>
                  </span>
                </h5>
                <ul class="nav nav-tabs booking-tab" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                      aria-controls="home" aria-selected="true">All</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                      aria-controls="profile" aria-selected="false">Paid</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <table aria-label="Visit Detail" class="table table-striped">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>VisitDate</th>
                      <th>Department</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let data of allcollection.data  |paginate: { id:'thirdPagination', itemsPerPage: 5, currentPage: r  } ; let i=index ;">
                      <td>{{i+1}}</td>
                      <td>{{data.VisitDate}}</td>
                      <td>{{data.DepartmentName}}</td>
                      <td *ngIf="data.PaymentStatus == 'unpaid'">
                        <button style="background-color: #1e1ee2" (click)="paymentselection(data)">
                          Pay
                        </button>
                      </td>
                      <td *ngIf="data.PaymentStatus == 'paid'">
                        <label class="text-success">
                          <i class="fa fa-check" aria-hidden="true"></i>Paid
                        </label>
                      </td>
                      <td *ngIf="data.PaymentStatus == 'free'">
                        <label class="text-info"><i class="fa fa-check-circle" aria-hidden="true"></i>Free</label>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <pagination-controls style="font-size: 20px" id="thirdPagination"
                  (pageChange)="r = $event"></pagination-controls>
              </div>
              <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <table aria-label="History" class="table table-striped">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Visit Date</th>
                      <th>Doctor</th>
                      <th>Department</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let data of patBookList |paginate: {id:'fourthPagination', itemsPerPage: 5, currentPage: s  } ; let i=index;">
                      <td>{{i+1}}</td>
                      <td>{{data.VisitDate}}</td>
                      <td>{{data.DepartmentName}}</td>
                      <td
                        *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') &&
                       data.GoToWaitingRoom == true && data.AccessPermission  == true  && data.EntryStatus == 'go' && (data.PaymentStatus == 'paid' || data.PaymentStatus == 'free'))">
                        <button (click)="GotoWaitingRoom(data)">
                          <i class="fa fa-check-circle" aria-hidden="true"></i>Go to Waiting Room
                        </button>
                      </td>
                      <td
                        *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') && data.PaymentStatus == 'free'&& data.VisitType =='Online Appointment' )">
                        <label class="text-info"><i class="fa fa-check-circle" aria-hidden="true"></i>Free</label>
                      </td>
                      <td
                        *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') && data.PaymentStatus == 'paid' && data.VisitType =='Online Appointment' )">
                        <label class="text-info"><i class="fa fa-check-circle" aria-hidden="true"></i>Paid</label>
                      </td>
                      <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') &&
                      data.GoToWaitingRoom == false &&
                      data.AccessPermission  == false &&
                      data.EntryStatus == 'todaypast' &&
                      (data.PaymentStatus == 'paid' || data.PaymentStatus == 'free') )">
                        <label class="text-warning">
                          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>Visit time was
                          {{data.BookingTime}}
                        </label>
                      </td>
                      <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') &&
                        data.GoToWaitingRoom == false &&
                        data.AccessPermission  == false &&
                        data.EntryStatus == 'todayupcoming' &&
                        (data.PaymentStatus == 'paid' || data.PaymentStatus == 'free') )">
                        <label class="text-success">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>Visit time on {{data.BookingTime}}
                        </label>
                      </td>
                      <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') &&
                        data.GoToWaitingRoom == false &&
                        data.AccessPermission  == false &&
                        data.EntryStatus == 'upcoming' &&
                        (data.PaymentStatus == 'paid' || data.PaymentStatus == 'free') )">
                        <label class="text-success">
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                          Visit date on {{data.VisitDate | date:'mediumDate' }} at {{data.BookingTime}}
                        </label>
                      </td>
                      <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing')
                                  && data.GoToWaitingRoom == false &&
                                  data.AccessPermission  == false  &&
                                    data.EntryStatus == 'missed'  &&
                                  ( data.PaymentStatus == 'paid' || data.PaymentStatus == 'free') )">
                        <label class="text-warning">
                          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Visit date was
                          {{data.VisitDate | date:'mediumDate'}}
                        </label>
                      </td>
                      <td *ngIf="data.Status =='completed' &&
                       data.PaymentStatus != 'unpaid' ">
                        <label class="text-success"><i class="fa fa-check-circle" aria-hidden="true"></i>Already
                          Visited</label>
                      </td>
                      <td *ngIf="data.PaymentStatus == 'unpaid'">
                        <button class="btn btn-sm btn-danger">
                          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>Pay First
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <pagination-controls style="font-size: 20px" id="fourthPagination"
                  (pageChange)="s = $event"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 d-flex justify-content-between">
                <h5 class="text-left pb-3">
                  My History
                </h5>
              </div>
            </div>

            <div style="overflow: scroll;">
              <table aria-label="My History" class="table table-striped">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>VisitDate</th>
                    <th>Hospital</th>
                    <th style="text-align: center;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let item of historycollection.data |paginate: { id:'fifthPagination', itemsPerPage: 5, currentPage: t }">
                    <td>{{item.id}}</td>
                    <td>{{item.VisitDate | date:'shortDate'}}</td>
                    <td>{{item.HospitalName }}</td>
                    <td *ngIf="item.Status =='completed'">
                      <button (click)="ViewNotes(item.VisitId)" class="btn btn-primary btn-sm">View Notes</button>
                    </td>
                    <td *ngIf="item.Status !='completed'">
                      <button class="btn btn-danger btn-sm">
                        Notes<br />
                        <span>Unavailable</span>
                      </button>
                      <button type="button" data-toggle="modal" data-target="#prescription"
                        (click)="ViewPrescription(item.VisitId)" class="btn btn-sm btn-primary">
                        View<br />
                        <span>Prescription</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination-controls style="font-size: 20px" id="fifthPagination"
              (pageChange)="t = $event"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer></footer>
</div>
<table aria-label="Doctor Advice">
  <div class="modal" id="myModal" [style.display]="showViewNotes ? 'block' : 'none'">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">Doctor Advice</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row modal-header text-center" *ngFor="let row of viewList"
            style="border: none; margin-bottom: 35px">
            <div class="w-100" id="Report">
              <div class="img-d" style="margin: auto; text-align:center; margin-bottom: 15px;">
                <img src="assets/img/hospital.png" alt="hospital logo" height="50">
              </div>
              <div class="row modal-header text-center" *ngFor="let data of viewList"
                style="border: none; margin-bottom: 35px">
                <table aria-label="Doctor Advice" style="width: 100%;">
                  <tr>
                    <td style="font-size: 20px; padding: 0; font-weight: 600;">
                      Doctor Name : {{data.DoctorName}}</td>
                  </tr>
                  <tr>
                    <td style="font-size: 20px;  padding: 0;font-weight: 600;">
                      Patient Name : {{data.PatientName}}</td>
                  </tr>
                  <tr>
                    <td style="font-size: 20px; padding: 0;font-weight: 600;">
                      Hospital Name : {{data.HospitalName}}</td>
                  </tr>
                  <tr>
                    <td style="font-size: 15px; padding: 0;font-weight: 600;">
                      Visit Date: {{data.VisitDate | date:'medium'}}</td>
                  </tr>
                  <tr>
                    <td style="font-size: 15px; padding: 0;font-weight: 600;">
                      NMC Number: {{data.NMC }}
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="modal-title w-100 font-weight-bold"
                style="text-align: center; margin-bottom: 20px; font-size: 17px;">Doctor Advice</h4>
              <div class="notes-prescription">
                <fieldset>
                  <legend>Other Pertinent Information:</legend>
                  <p>{{row.OtherPertientInformation}}</p>
                </fieldset>
                <fieldset>
                  <legend>Treatment Advice:</legend>
                  <p>{{row.TreatmentAdvice}}</p>
                </fieldset>
                <fieldset>
                  <legend>Medication given by doctor:</legend>
                  <p>{{row.Medication}}</p>
                </fieldset>
                <fieldset>
                  <legend>Follow up:</legend>
                  <p>{{row.FollowUp}}</p>
                </fieldset>
              </div>
            </div>
            <div class="print-action">
              <input type="button" value="Print Report" (click)="Print()">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="myModal" [style.display]="showfollowup ? 'block' : 'none'">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">Follow Up</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Closefollowup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-5">
          <div class="row d-flex mb-2">
            <form [formGroup]="followupForm" (ngSubmit)="ConfirmFollowup(followupForm)">
              <div class="row d-flex">
                <div class="col-md-4">
                  <label>First Name</label>
                  <input type="text" class="form-control" formControlName="FirstName" disabled
                    [(ngModel)]="patInfo.FirstName" />
                </div>
                <div class="col-md-4">
                  <label>Middle Name</label>
                  <input type="text" class="form-control" formControlName="MiddleName" disabled
                    [(ngModel)]="patInfo.MiddleName" />
                </div>
                <div class="col-md-4">
                  <label>Last Name</label>
                  <input type="text" class="form-control" formControlName="LastName" disabled
                    [(ngModel)]="patInfo.LastName" />
                </div>
                <div class="col-md-4">
                  <label for="lastname">Age</label>
                  <input type="text" class="form-control" formControlName="Age" disabled [(ngModel)]="Age" />
                </div>
                <div class="col-md-4">
                  <label for="lastname">Gender</label>
                  <input type="text" class="form-control" formControlName="Gender" disabled [(ngModel)]="Gender" />
                </div>
                <div class="col-md-4">
                  <label for="lastname">Last Appointment Department</label>
                  <input type="text" class="form-control" formControlName="LastAppointmentDepartment" disabled
                    [(ngModel)]="LastApptDepartmentName" />
                </div>
                <div class="col-md-4">
                  <label for="lastname">Last Appointment Doctor</label>
                  <input type="text" class="form-control" formControlName="LastAppointmentDoctor" disabled
                    [(ngModel)]="LastApptDoctorName" />
                </div>
                <div class="col-md-4">
                  <label for="lastname">Last Appointment Date</label>
                  <input type="text" class="form-control" formControlName="LastAppointmentDate" disabled
                    [(ngModel)]="LastApptVisitDate" />
                </div>
                <div class="col-md-4">
                  <label>Change Department<span class="required-sign">*</span></label>
                  <select class="form-control" formControlName="Department" placeholder="Select Department"
                    [(ngModel)]="selDepartment" (change)="AssignDepartment()">
                    <option *ngFor="let row of deptList" [value]="row.DepartmentId">
                      {{row.DepartmentName}}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <label>Change Doctor<span class="required-sign">*</span></label>
                  <select class="form-control" formControlName="Doctor" placeholder="Select Doctor"
                    [(ngModel)]="selDoctor" (change)="AssignDoctor()">
                    <option *ngFor="let row of filterDocList" [value]="row.DoctorId">
                      {{row.DoctorName}}
                    </option>
                  </select>
                  <div class="col-12" *ngIf="showUnavailableMsg">
                    <p style="color:red "><strong>Doctor is not available for this Department </strong> </p>
                  </div>
                </div>

                <div class="col-md-4">
                  <label>Select Follow-Up<span class="required-sign">*</span></label>
                  <select class="form-control" formControlName="FollowUpdate" placeholder="Select Doctor"
                    [(ngModel)]="selSchedule" (change)="SelectSchedule()">
                    <option *ngFor="let data of filteredSchedulingList" [value]="data.SchedulingId">
                      <td> <i class="fa fa-calendar" aria-hidden="true"></i>{{data.Date | date:'mediumDate'}}</td>
                      <td> <span class="btn-schedule">-{{data.StartTime }} - {{data.EndTime }}</span></td>
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <label>Select Time<span class="required-sign">*</span></label>
                  <select class="form-control" formControlName="Time" placeholder="Select Doctor"
                    (change)="setAppontmentTime($event)" [(ngModel)]="scheduleIntervalId">
                    <option *ngFor="let list of scheduleIntervalList" [value]="list.ScheduleIntervalId">
                      <button *ngIf="list.IsBooked == false" mat-raised-button color="primary"> {{list.StartTime}}
                      </button>
                      <button *ngIf="list.IsBooked == true" mat-raised-button color="warn"
                        (change)="setAppontmentTime(list)">{{list.StartTime}} </button>
                    </option>
                  </select>
                </div>
                <div class="col-md-12">
                  <button style="float: right; margin-top: 30px;" value="Submit">Confirm Follow-Up</button>
                  <button style="float: right; margin-top: 30px;" (click)="Makepayment()">Make Payment</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="myModal" [style.display]="showVisitTypePopup ? 'block' : 'none'">
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="width: 50%;margin-left: 25%;">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">Select Appointment Type</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="CloseVisittypepopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row d-flex mb-2">
            <div class="col-md-12">
              <button style="margin-left: 50px;" value="Online-Appointment" (click)="SetOnlineAppointment()">Online
                Appointment</button>
              <button style="margin-left: 25px;" value="Telemedicine"
                (click)="SetTelemedicineAppointment()">Telemedicine</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</table>
<div *ngIf="showPrescription" class="modal fade " id="prescription" tabindex="-1" role="dialog"
  aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Prescription file from Doctor</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="background-color:floralwhite;">
        <div class="info-heading px-2 py-3 m-auto " *ngFor="let patPrescription of prescriptions">
          <img *ngIf="patPrescription.FileType == 'image'" alt="info image" src="{{patPrescription.FilePath}}"
            style=" width: 100%; align-content: center; object-fit: contain;">
          <ngx-extended-pdf-viewer *ngIf="patPrescription.FileType == 'pdf'" src="{{patPrescription.FilePath}}"
            style="display: block;">
          </ngx-extended-pdf-viewer>
          <div *ngIf="patPrescription.FileType == 'docfile'">
            <a href="{{patPrescription.FilePath}}" class="btn btn-primary btn-lg active" role="button">Click to Open Doc
              File</a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>