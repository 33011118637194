import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductModel } from 'src/app/models/Product/product.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductService } from 'src/app/services/product/product.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatTable } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

function ChildProductsVaidator() {
  return (formGroup: FormGroup) => {
    const childProductFormArray = formGroup.get('displayGroup.childProducts') as FormArray;
    const productTypeControl = formGroup.get('catalogGroup.productType').value;
    childProductFormArray.setErrors(null);
    if (productTypeControl == "Grouped") {
      if (childProductFormArray.length == 0)
        childProductFormArray.setErrors({ required: true });
    }
    return null;
  }
}

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {
  pagesInForm = { catalogAndSales: "Catalog And Sales", display: "Display", seo: "SEO" };
  currentPageInForm: string = this.pagesInForm.catalogAndSales;
  productForm: FormGroup;
  get productNameControl(): FormControl {
    return this.productForm.get('catalogGroup.productName') as FormControl;
  }
  get skuControl(): FormControl {
    return this.productForm.get('catalogGroup.sku') as FormControl;
  }
  get productTypeControl(): FormControl {
    return this.productForm.get('catalogGroup.productType') as FormControl;
  }
  get newPriceControl(): FormControl {
    return this.productForm.get('salesGroup.newPrice') as FormControl;
  }
  get permalinkControl(): FormControl {
    return this.productForm.get('seoGroup.permalink') as FormControl;
  }
  get childProductSearchKeyword(): FormControl {
    return this.productForm.get('displayGroup.childProductSearchKeyword') as FormControl;
  }
  get childProducts(): FormArray {
    return this.productForm.get('displayGroup.childProducts') as FormArray;
  }
  get childProductsValues(): any[] {
    const childProductsControl = <FormArray>this.productForm.get('displayGroup.childProducts');
    return childProductsControl.value as any[];
  }

  @ViewChild('childProductTable') childProductTable: MatTable<any>;
  public AllProductsList: Array<ProductModel> = new Array<ProductModel>();
  public filteredProducts: Array<ProductModel> = new Array<ProductModel>();
  public filteredProductsForMatAutocomplete: Observable<ProductModel[]>;
  public product: ProductModel;
  public loading: boolean = false;
  public ProductTypesList: Array<any> = new Array<any>();
  public ProductTagsList: Array<any> = new Array<any>();
  public editMode: boolean = false;
  public addNewProductTypePopup: boolean = false;

  productId: number;
  public progress: any;
  public message: any;
  public previewImgSrc: any;
  public imageUploaded: boolean = false;
  public productImageExist: boolean = false;
  public file: any;

  constructor(public activatedRoute: ActivatedRoute, public router: Router, public productService: ProductService, public notificationService: NotificationService) {
    this.ProductTagsList = this.productService.ProductTagsList;
    this.ProductTypesList = productService.ProductTypesList;
    this.getAllProducts();
    this.GetAllProductTypes();
    this.GetAllProductTags();
  }

  ngOnInit() {

    const productIdToEdit = this.activatedRoute.snapshot.paramMap.get("id");
    if (productIdToEdit != null) {
      this.editMode = true;
      this.productId = parseInt(productIdToEdit);
      this.AssignProductDetails(this.productId);
    }
    else {
      this.productForm = this.buildProductForm();
      this.setupCustomFilterForChildProductsAutoComplete();
    }
  }
  private buildProductForm(product?: ProductModel): FormGroup {
    const standardProductAddForm = new FormGroup({
      catalogGroup: new FormGroup({
        productName: new FormControl(product ? product.Name : '', Validators.required),
        sku: new FormControl(product ? product.Sku : '', Validators.required),
        manufacturerPartNo: new FormControl(product ? product.ManufacturerPartNumber : ''),
        productType: new FormControl(product ? product.ProductType.Type : 'Single'),
        productTypeId: new FormControl(product ? product.ProductTypeId : ''),
        productTag: new FormControl(product ? product.ProductTags.map(a => a.Name).join(', ') : ''),
        groupingLabel: new FormControl(product ? product.GroupingLabel : ''),
        isRefundable: new FormControl(product ? !product.NotReturnable : false),
        shortDescription: new FormControl(product ? product.ShortDescription : ''),
        fullDescription: new FormControl(product ? product.FullDescription : ''),
        adminComments: new FormControl(product ? product.AdminComment : '')
      }),
      displayGroup: new FormGroup({
        visibleIndividually: new FormControl(product ? product.VisibleIndividually : false),
        showProductOnHomePage: new FormControl(product ? product.ShowOnHomepage : false),
        disableBuyButton: new FormControl(product ? product.DisableBuyButton : false),
        markAsNew: new FormControl(product ? product.MarkAsNew : false),
        markAsNewStartDate: new FormControl(product ? product.MarkAsNewStartDateTime : moment().format("YYYY-MM-DD")),
        markAsNewEndDate: new FormControl(product ? product.MarkAsNewEndDateTime : moment().format("YYYY-MM-DD")),
        displayOrder: new FormControl(product ? product.DisplayOrder : ''),
        childProductSearchKeyword: new FormControl(''),
        childProducts: new FormArray([])
      }),
      salesGroup: new FormGroup({
        newPrice: new FormControl('', Validators.required),
        oldPrice: new FormControl(product ? product.OldPrice : '')
      }),
      seoGroup: new FormGroup({
        permalink: new FormControl(product ? product.PermaLink : '', Validators.required),
        metaTitle: new FormControl(product ? product.MetaTitle : ''),
        metaKeyword: new FormControl(product ? product.MetaKeywords : ''),
        metaDescription: new FormControl(product ? product.MetaDescription : '')
      })
    }, { validators: ChildProductsVaidator() });

    standardProductAddForm.get('catalogGroup.productName').valueChanges.subscribe((newProductName: string) => {
      const whiteSpace = ' ';
      const dash = '-';
      standardProductAddForm.get('seoGroup.permalink').setValue(newProductName.trim().toLowerCase().replace(whiteSpace, dash));
    });
    standardProductAddForm.get('catalogGroup.productType').valueChanges.subscribe((newProductType: string) => {
      const productTypeId = this.ProductTypesList.find(a => a.Type == newProductType).Id;
      standardProductAddForm.get('catalogGroup.productTypeId').setValue(productTypeId);
    });

    return standardProductAddForm;
  }
  private setupCustomFilterForChildProductsAutoComplete() {
    this.filteredProductsForMatAutocomplete = this.childProductSearchKeyword.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterChildProducts(value))
      );
  }

  private _filterChildProducts(value: string): ProductModel[] {
    const filterValue = value.toLowerCase();

    return this.filteredProducts.filter(option => option.Name.toLowerCase().includes(filterValue));
  }
  buildChildProduct(productModel: ProductModel): FormGroup {
    return new FormGroup({
      id: new FormControl(productModel.Id),
      name: new FormControl(productModel.Name),
      sku: new FormControl(productModel.Sku),
      price: new FormControl(productModel.Price)
    });
  }
  addChildProduct(productEvent) {
    // remove the element of the array to disable duplicate items
    this.filteredProducts.splice(this.filteredProducts.indexOf(productEvent.option.value), 1);
    this.childProducts.push(this.buildChildProduct(productEvent.option.value));
    this.productForm.get('displayGroup.childProductSearchKeyword').reset();
  }
  removeChildProductAt(index: number) {
    // add the removed product to the list
    const removedProductId = this.childProducts.controls[index].get('id').value;
    this.filteredProducts.push(this.AllProductsList.find(a => a.Id == removedProductId));

    this.childProducts.removeAt(index);
  }

  goToNextPage() {
    this.productForm.updateValueAndValidity();
    switch (this.currentPageInForm) {

      case this.pagesInForm.catalogAndSales:
        this.productForm.get('catalogGroup').markAllAsTouched();
        this.productForm.get('salesGroup').markAllAsTouched();
        if (this.productForm.get('catalogGroup').valid && this.productForm.get('salesGroup').valid) {
          this.currentPageInForm = this.pagesInForm.display;
        }
        break;

      case this.pagesInForm.display:
        this.productForm.get('displayGroup').markAllAsTouched();
        if (this.productForm.get('catalogGroup').valid) {
          this.currentPageInForm = this.pagesInForm.seo;
        }
        break;
      case this.pagesInForm.seo:
        this.productForm.get('seoGroup').markAllAsTouched();
        if (this.productForm.get('seoGroup').valid) {
          this.addProduct();
        }
    }
  }
  goToPreviousPage() {
    switch (this.currentPageInForm) {
      case this.pagesInForm.seo:
        this.currentPageInForm = this.pagesInForm.display;
        break;
      case this.pagesInForm.display:
        this.currentPageInForm = this.pagesInForm.catalogAndSales;
        break;
      case this.pagesInForm.catalogAndSales:
        this.close();
        break;

    }
  }
  addProduct() {
    this.productForm.updateValueAndValidity();
    this.productForm.markAllAsTouched();
    if (this.productForm.valid) {
      this.loading = true;
      const formData = new FormData();

      if (this.file && this.file.length != 0)
        formData.append("productImageFile", this.file, this.file.name);

      formData.append("ProductDetails", JSON.stringify(this.productForm.value));

      if (this.editMode)
        this.callUpdateProductApi(formData);
      else
        this.callAddProductApi(formData);
    }
  }
  private callAddProductApi(formData: FormData) {
    this.productService.AddProduct(formData).subscribe(res => {
      if (res) {
        this.notificationService.showSuccess('Product has been Added Successfully!', '');
        this.router.navigate(["Product"]);
      }
      else {
        this.notificationService.showError('Something went wrong.', 'Failed');
      }
      this.loading = false;
    });
  }
  private callUpdateProductApi(formData: FormData) {
    this.productService.updateProduct(formData, this.productId).subscribe(
      res => {
        if (res) {
          this.notificationService.showSuccess('The Product is Updated Successfully!', 'Updated');
          this.router.navigate(["Product"]);
        }
        this.loading = false;
      },
      err => {
        console.log(err.toString());
        this.notificationService.showError('Something went wrong.', 'Failed');
        this.loading = false;
      });
  }

  public GetAllProductTypes() {
    this.productService.getAllProductTypes().subscribe(
      res => {
        if (res) {
          this.ProductTypesList = Object.assign(this.ProductTypesList, res);

          if (this.productForm && this.productForm.get('catalogGroup.productType').value == "Single")
            this.productForm.get('catalogGroup.productTypeId').setValue(this.ProductTypesList.find(a => a.Type == "Single").Id);
        }
      },
      err => {
        alert(err);
      }
    );
  }
  public GetAllProductTags() {
    this.productService.getAllProductTags().subscribe(
      res => {
        if (res) {
          this.ProductTagsList = Object.assign(this.ProductTagsList, res);
        }
      },
      err => {
        alert(err);
      }
    );
  }

  uploadFileLocally(files) {
    if (files.length === 0)
      return;
    else {
      // Start: show image preview

      // 1. create img src with fileReader
      var mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        this.notificationService.showError("error", ["Only images are supported."]);
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.previewImgSrc = reader.result;
        this.imageUploaded = true;
      }
      // End: show image preview

      this.file = files[0];

    }

  }
  ClearImage() {
    this.file = null;
    this.previewImgSrc = null;
    this.imageUploaded = false;
  }


  public AssignProductDetails(productId) {
    this.productService.getProduct(productId).subscribe(
      res => {
        if (res) {
          this.product = new ProductModel();
          this.product = Object.assign(this.product, res);
          this.productForm = this.buildProductForm(this.product);
          this.setupCustomFilterForChildProductsAutoComplete();
          if (this.product.ProductType.Type == "Grouped") {
            this.product.ChildProducts.forEach((c: any) => {
              this.childProducts.push(this.buildChildProduct(c.ChildProduct));
            });

            // remove the child products from the options
            const childProductIds: number[] = this.product.ChildProducts.map((c: any) => c.ChildProductId);
            this.filteredProducts = this.filteredProducts.filter(p => !childProductIds.includes(p.Id));
          }
          this.productImageExist = false;
          if (this.product.PictureId) {
            this.productImageExist = true;
            this.GetProductImage(this.product.PictureId);
          }
        }
      }
    );
  }

  public GetProductImage(productImageId) {
    this.productService.getProductImage(productImageId).subscribe(
      res => {
        if (res) {
          this.previewImgSrc = 'data:image/jpeg;base64,' + res;
        }
      },
      err => {
        alert(err);
      }
    );
  }
  public getAllProducts() {
    this.productService.getAllProducts().subscribe(
      res => {
        if (res) {
          this.AllProductsList = Object.assign(this.AllProductsList, res);
          this.filteredProducts = this.AllProductsList.filter(a => a.IsActive && a.ProductType == "Single");
        }
      },
      err => {
        this.notificationService.showWarning(err, 'Error!');
      });
  }
  public close() {
    if (confirm("All unsaved changes will be lost. Do you want to cancel the update?")) {
      this.router.navigate(["Product"]);
    }
  }
  childProductDisplayFunction(product: ProductModel): string {
    return product ? `${product.Name} - SKU : ${product.Sku} - Price: ${product.Price}` : '';
  }

  onListDrop(event: CdkDragDrop<string[]>) {
    const prevIndex = this.childProductsValues.findIndex((d) => d === event.item.data);
    // Swap the elements around
    moveItemInArray(this.childProductsValues, prevIndex, event.currentIndex);
    this.childProductTable.renderRows();
  }

}


