import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { SchedulingService } from '../../../services/scheduling/scheduling.service';
import { Scheduling, SchedulingList } from '../../../models/scheduling/scheduling.model';
import { Router } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';
import * as moment from 'moment';
import { Doctor } from '../../../models/doctor/doctor.model';
import { DoctorUpdateProfileComponent } from '../../../Doctor/doctor_updateprofile/app.doctor_updateprofile';
import { HospitalDoctorMap } from '../../../models/HospitalDoctorMap/app.hospitaldoctormap.model';
import { AuthenticationService } from '../../../services/authentication.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  templateUrl: './doctor-scheduling.component.html',
  styleUrls: ['./doctor-scheduling.component.css']
})
export class DoctorSchedulingComponent implements AfterViewInit {
  public scheduling: Scheduling = new Scheduling();
  selectedValue: any;
  public doctorList: any;
  public schedulingTime: any;
  public start: any;
  public end: any;
  meridian = true;
  showModal: any;
  public editModal: boolean;
  public editProfileModal: boolean;
  showView: any;
  public mapId: any;
  public docName: any;
  hoveredDate: NgbDate | null = null;
  public startTime = { hour: 0, minute: 0 };
  public endTime = { hour: 0, minute: 0, };
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  p: number = 1;
  collection = { count: 0, data: [] };
  schId: any;
  scheduleList: any;
  scheduleDetail: Array<any>;
  editSchdule: boolean;
  public index: number = 0;
  public searchText: any = "";
  public AdminId: string;


  //ime = {hour: 14, minute: 30};
  public schedule: Scheduling = new Scheduling();
  public schedulingList: Array<Scheduling> = new Array<Scheduling>();
  public newScheduling: Scheduling = {
    SchedulingId: "", Date: null, StartDate: null, HospitalDoctorMapId: "", EndDate: null, StartTime: 0, EndTime: 0, DepartmentId: "", HospitalId: "", DoctorId: ""
    , DepatmentName: "", HospitalName: "", DoctorName: "", StartHours: 0, StartMinutes: 0, StartSeconds: 0, EndHours: 0, EndMinutes: 0, EndSeconds: 0, NewScheduleList: [], ScheduleInterval: [], AppointmentType: "", AdminId: ""
  };
  @ViewChild('doctorModalEditor', { static: true })
  doctorModalEditor: DoctorUpdateProfileComponent;
  editedDoctor: HospitalDoctorMap;
  isDocEditor = false;
  isDocScheduleEditor = false;
  token: any;
  hospital: string;
  hospitalName: string;
  doctorName: string;
  accomodatedPatient: number;
  appointmentDate: any;
  time: any;
  public newSchedulingList: SchedulingList = { StartTime: 0, EndTime: 0, StartHours: 0, StartMinutes: 0, StartSeconds: 0, EndHours: 0, EndMinutes: 0, EndSeconds: 0, AccomodatedPatient: 0 };

  public currentDate: any;
  hasAppointment: boolean = false;
  editSchduleModal: boolean = false;
  appointedPatientList: any;
  schedulingId: any;
  //public newScheduling:any={SchedulingId:"",StartTime:"",HospitalDoctorMapId:"",EndTime:""}
  constructor(public calendar: NgbCalendar, public schedulingService: SchedulingService, public routing: Router, private notifyService: NotificationService, public authService: AuthenticationService, public alertService: AlertService) {
    this.getDoctor();
    this.editModal = false;
    this.editProfileModal = false;
    this.editSchdule = false;
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.token = this.authService.currentUserValue;
    this.hospital = this.token.HospitalIdentifier;


  }
  ngAfterViewInit() {

    this.doctorModalEditor.changesSavedCallback = () => {
      this.editProfileModal = false;
      this.getDoctor();
    };
    this.doctorModalEditor.changesCancelledCallback = () => {
      this.editProfileModal = false;
    };

  }
  public Admin() {

    this.routing.navigate(['/admin']);

  }
  public getDoctor() {
    this.schedulingService.getSchedulingDoctorList().subscribe(res => this.SuccessDoctorList(res),
      res => this.ErrorDoctorList(res));

  }
  public getSchedulingTime(mapId) {
    this.schedulingService.getSchedulingTime(mapId).subscribe((res) => this.SucessSchedulingTime(res),
      res => this.ErrorSchedulingTime(res));

  }
  SucessSchedulingTime(res) {
    if (res && res.IsSuccess && res.Results) {

      this.schedulingTime = res.Results;
      this.scheduleList = res.Results;
      for (var i = 0; i < this.schedulingTime.length; i++) {
  
        this.schedulingTime[i].Date = moment(this.schedulingTime[i].Date).format('L');
      }
  
      this.currentDate = moment(Date.now()).format('L');
      this.schedulingTime = this.schedulingTime.filter(a => a.Date >= this.currentDate);
    }else{
      this.alertService.error(res.ResponseMessage);
    }
   
  }

  SuccessDoctorList(res) {
    this.doctorList = res;
    this.collection = { count: this.doctorList.length, data: [] }

    for (var i = 0; i < this.collection.count; i++) {
      this.collection.data.push(
        {
          id: i + 1,
          DoctorName: this.doctorList[i].DoctorName,
          HospitalName: this.doctorList[i].HospitalName,
          DepartmentName: this.doctorList[i].DepartmentName,
          HospitalDoctorMapId: this.doctorList[i].HospitalDoctorMapId,
          DepartmentId: this.doctorList[i].DepartmentId,
          HospitalId: this.doctorList[i].HospitalId,
          DoctorId: this.doctorList[i].DoctorId,
          ConsultationType: this.doctorList[i].ConsultationType

        }
      );
    }
  }
  ErrorDoctorList(res) {
    this.notifyService.showError("Error", "There is no any doctor in the list");
  }
  ErrorSchedulingTime(res) {
    this.notifyService.showError("Error", "There is no scheduling time for this Doctor!");

  }
  Success(res) {
    this.routing.navigate(['/admin']);
  }
  Error(res) {
    this.notifyService.showError("Error", "Internal Error")
  }


  Add(id, hosName, deptName, doctor) {
    if (hosName == null && deptName == null) {
      this.notifyService.showWarning('Update the Doctor with Adding Hospital and Department from Doctor Update profile.', 'Please!');
    }
    else {
      this.showModal = true;
      this.mapId = id;
      this.docName = doctor;
      this.routing.navigate(['addAppointment'], { queryParams: { id: this.mapId, doc: this.docName } });

    }

  }
  hide() {
    this.showModal = false;
    this.showView = false;
    this.editModal = false;
    this.editSchdule = false;
  }
  View(id, hosName, deptName) {
    if (hosName == null && deptName == null) {
      this.notifyService.showInfo('Update the Doctor with Adding Hospital and Department from Doctor Update profile.', 'Please!');
    }
    else {
      this.showView = true;
      this.mapId = id;
      this.getSchedulingTime(this.mapId);
    }

  }
  Edit(id, hosName, deptName) {
    if (hosName == null && deptName == null) {
      this.notifyService.showWarning('Update the Doctor with Adding Hospital and Department from Doctor Update profile.', 'Please!');
    }
    else {
      this.mapId = id;
      this.editSchdule = false;
      this.getSchedulingTime(this.mapId);
      this.editModal = true;
    }
  }
  editScheduling(SchedulingId) {
    this.editSchdule = true;
    this.editModal = false;
    this.showView = false;
    this.CheckForExistingAppointment(SchedulingId, 'fromEdit');
    this.schId = SchedulingId;
    this.EditScheduling();

  }
  DeleteAppointment(SchedulingId) {
    this.CheckForExistingAppointment(SchedulingId, 'fromDelete');
  }
  deleteScheduling() {
    this.schedulingService.deleteSchedule(this.schedulingId).subscribe(res => this.SuccessDelete(res),
      error => this.Error(error))
  }
  public Update() {
    this.scheduling.StartHours = this.startTime.hour;
    this.scheduling.StartMinutes = this.startTime.minute;
    this.scheduling.EndHours = this.endTime.hour;
    this.scheduling.EndMinutes = this.endTime.minute;
    let momentDate = new Date(this.schedule.StartDate);
    this.scheduling.StartDate = momentDate;
    this.scheduling.SchedulingId = this.schId;
    this.scheduling.AppointmentType = this.schedule.AppointmentType;
    this.newSchedulingList = new SchedulingList;
    this.newSchedulingList.AccomodatedPatient = this.accomodatedPatient;
    this.scheduling.NewScheduleList = new Array<SchedulingList>();
    this.scheduling.NewScheduleList.push(this.newSchedulingList);
    if (this.token.UserType === "Admin") {
      this.scheduling.AdminId = this.token.unique_name;
    }
    else {
      this.scheduling.AdminId = "";
    }
    this.schedulingService.updateScheduling(this.scheduling).subscribe(res => this.SuccessUpdate(res),
      res => this.Error(res));
  }
  SuccessUpdate(res) {
    this.editSchdule = false;
    this.notifyService.showSuccess('Sucess', 'Schedule Updated Successfully');
  }
  SuccessDelete(res) {
    this.notifyService.showSuccess('Sucess', 'Schedule Successfully Deleted');
    this.hasAppointment = false;
    this.editModal = false;
    this.editSchdule = false;
    this.showView = false;
  }
  deleteRow(index) {
    if (this.schedulingList.length == 1) {
      return false;
    } else {
      this.schedulingList.splice(index, 1);
      return true;
    }
  }
  addRow(i) {
    this.newScheduling = {
      SchedulingId: "", Date: null, StartDate: null, HospitalDoctorMapId: "", EndDate: null, StartTime: 0, EndTime: 0, DepartmentId: "", HospitalId: "", DoctorId: ""
      , DepatmentName: "", HospitalName: "", DoctorName: "", StartHours: 0, StartMinutes: 0, StartSeconds: 0, EndHours: 0, EndMinutes: 0, EndSeconds: 0, NewScheduleList: [], ScheduleInterval: [], AppointmentType: "", AdminId: ""
    };
    this.schedulingList.push(this.newScheduling);
    console.log(this.schedulingList);
    return true;
  }
  UpdateProfile(row: any) {
    delete row.id;
    let doctormap = new HospitalDoctorMap();
    doctormap = Object.assign(doctormap, row);
    this.isDocEditor = true;
    this.isDocScheduleEditor = true;
    this.editedDoctor = this.doctorModalEditor.editDoctorModal(doctormap, this.isDocEditor, this.isDocScheduleEditor, this.hospital);
    this.editProfileModal = true;
  }

  CheckForExistingAppointment(id, from) {
    this.schedulingId = id;
    this.schedulingService.checkExistingAppointment(this.schedulingId).subscribe((res: any) => {
      if (res && res.IsSuccess && res.Results) {
        this.appointedPatientList = res?.Results;
        if (this.appointedPatientList.length > 0) {
          this.hospitalName = this.appointedPatientList[0].hospitalName;
          this.doctorName = this.appointedPatientList[0].doctorName;
          // this.appointmentDate = moment(this.appointedPatientList[0].appointmentDate, "YYYY-MM-DD").format("YYYY-MM-DD");
          this.appointmentDate = this.appointedPatientList[0].appointmentDate;
          this.hasAppointment = true;
        }
      }else{
        this.alertService.error(res.ResponseMessage);
      }
    });
    if (!this.hasAppointment) {
      if (from == 'editScheduling') {
        this.EditScheduling();
      }
      else if (from == 'fromDelete') {
        this.deleteScheduling();
      }
    }
  }
  CancelDelete() {
    this.hasAppointment = false;
    this.editModal = false;
    this.editSchdule = false;
  }

  EditScheduling() {
    this.editModal = false;
    this.hasAppointment = false;
    this.editSchduleModal = true;
    this.scheduleDetail = this.scheduleList.filter(x => x.SchedulingId == this.schId);
    this.schedule.StartHours = parseInt(this.scheduleDetail[0].StartTime.substring(0, 2));
    if (this.scheduleDetail[0].StartTime.substring(6, 8) == 'PM') {
      var num = this.schedule.StartHours + 12;
      this.schedule.StartHours = num;
    }
    this.schedule.EndHours = parseInt(this.scheduleDetail[0].EndTime.substring(0, 2));
    if ((this.scheduleDetail[0].EndTime.substring(6, 8) == 'PM')) {
      var num = this.schedule.EndHours + 12;
      this.schedule.EndHours = num;
    }
    this.schedule.StartMinutes = parseInt(this.scheduleDetail[0].StartTime.substring(3, 5));
    this.startTime = { hour: this.schedule.StartHours, minute: this.schedule.StartMinutes }

    this.schedule.EndMinutes = parseInt(this.scheduleDetail[0].EndTime.substring(3, 5));
    this.endTime = { hour: this.schedule.EndHours, minute: this.schedule.EndMinutes }
    this.fromDate.year = this.scheduleDetail[0].Date.substring(6, 10);
    this.fromDate.month = this.scheduleDetail[0].Date.substring(0, 2);
    this.fromDate.day = this.scheduleDetail[0].Date.substring(3, 5);
    let mdtFrom = moment([this.fromDate.year, this.fromDate.month - 1, this.fromDate.day]);
    this.schedule.StartDate = new Date(mdtFrom.format('MM/DD/YYYY'));
    this.schedule.AppointmentType = this.scheduleDetail[0].AppointmentType;
    this.accomodatedPatient = this.scheduleDetail[0].AccomodatedPatient;
    // this.schedulingService.postScheduling(this.scheduling).subscribe(res => this.Success(res),
    // res => this.Error(res));
  }
}


