<app></app>

<button *ngIf="!showButton"
        [routerLink]="['/PatDashboard']"
        class="back-btn-doc-room">
  Back To Dashboard
</button>
<!--<button (click)="BackDashBoard()" class="back-btn-doc-room">Back To DashBoard </button>

<div class="payment-section" style="margin-top: 0">
  <ul class="book-actions p-0" style="margin-bottom: 0">
    <li class="d-inline mr-2"> <button (click)="BookNewVisit()" class="btn btn-info"><i class="fa fa-calendar"></i>Book New Visit</button></li>
    <li class="d-inline mr-2"><button (click)="MyBookList()" class="btn btn-info"><i class="fa fa-list"></i>My Booking List</button></li>
    <li class="d-inline mr-2"> <button (click)="MyPaidBooking()" class="btn btn-primary active-btn"><i class="fa fa-calendar-o"></i>My Paid Booking</button></li>

  </ul>
</div>-->

<div *ngIf="patbook"
     class="grid-section"
     style="max-width:100%;overflow:auto">
  <h4 class="title">
    <i class="fa fa-list"></i>My Paid Booking List
  </h4>

  <table class="table">
    <thead>
      <tr>
        <th scope="col">SN</th>
        <th scope="col">VisitDate</th>
        <!--<th scope="col">VisitTime</th>-->
        <th scope="col">HospitalName</th>
        <th scope="col">DoctorName</th>
        <th scope="col">Department</th>
        <th scope="col">VisitType</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of patBookList |paginate: { itemsPerPage: 5, currentPage: p  } ; let i=index;">
        <td>{{i+1}}</td>
        <td>{{data.VisitDate | date:'mediumDate'}}</td>
        <td>{{data.HospitalName}}</td>
        <td>{{data.DoctorName}}</td>
        <td>{{data.DepartmentName}}</td>
        <td>{{data.VisitType }}</td>
        <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') && data.GoToWaitingRoom == true && data.AccessPermission  == true  && data.EntryStatus == 'go' && (data.PaymentStatus == 'paid' || data.PaymentStatus == 'free') && data.VisitType =='Telemedicine' )"
            align="left">
          <button (click)="GotoWaitingRoom(data)">
            <i class="fa fa-check-circle"></i>Go to Waiting Room
          </button>
        </td>
        <!-- <td
                        *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') && data.PaymentStatus == 'free'&& data.VisitType =='Online Appointment' )"
                        align="left">
                        <label class="text-info"><i class="fa fa-check-circle"></i>Free</label>
                      </td>
                      <td
                      *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') && data.PaymentStatus == 'paid' && data.VisitType =='Online Appointment' )"
                      align="left">
                      <label class="text-info"><i class="fa fa-check-circle"></i>Paid</label>
                    </td> -->
        <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') && data.GoToWaitingRoom == false && data.AccessPermission  == false && data.EntryStatus == 'todaypast' ) && (data.BookingTime && data.BookingTime != '')"
            align="left">
          <label class="text-warning">
            <i class="fa fa-exclamation-circle"></i>Visit time was
            {{data.BookingTime}}
          </label>
        </td>
        <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') && data.GoToWaitingRoom == false && data.AccessPermission  == false && data.EntryStatus == 'todayupcoming'  && (data.PaymentStatus == 'paid' || data.PaymentStatus == 'free') )"
            align="left">
          <label class="text-success">
            <i class="fa fa-clock-o"></i>Visit time on {{data.BookingTime}}
          </label>
        </td>
        <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') && data.GoToWaitingRoom == false && data.AccessPermission  == false  && data.EntryStatus == 'upcoming' && (data.PaymentStatus == 'paid' || data.PaymentStatus == 'free') )"
            align="left">
          <label class="text-success">
            <i class="fa fa-calendar"></i>Visit date on {{data.VisitDate |
            date:'mediumDate'}} at {{data.BookingTime}}
          </label>
        </td>
        <td *ngIf="((data.Status == 'initiated' || data.Status == 'ongoing') && data.GoToWaitingRoom == false && data.AccessPermission  == false  &&  data.EntryStatus == 'missed')"
            align="left">
          <label class="text-warning">
            <i class="fa fa-exclamation-circle"></i> Visit date was
            {{data.VisitDate | date:'mediumDate'}}
          </label>
        </td>
        <td *ngIf="data.Status =='completed' && data.PaymentStatus != 'unpaid' "
            align="left">
          <label class="text-success"><i class="fa fa-check-circle"></i>Already Visited</label>
        </td>
        <td *ngIf="(data.PaymentStatus == 'unpaid' && (data.Status == 'initiated' || data.Status == 'ongoing') &&  (data.EntryStatus == 'go' || data.EntryStatus == 'todayupcoming' || data.EntryStatus == 'upcoming'))"
            align="left">
          <!-- <td *ngIf="data.PaymentStatus == 'unpaid'" align="left"> -->
          <button class="btn btn-sm btn-danger">
            <i class="fa fa-exclamation-circle"></i>Pay First
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <pagination-controls style="font-size: 20px"
                       (pageChange)="p = $event"></pagination-controls>
</div>
<footer></footer>
