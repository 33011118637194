import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DoctorEndpoint } from '../doctor/doctor-endpoint.service';
import { Doctor } from 'src/app/app.model';
import { ContactInformation } from 'src/app/models/contactInfo/contactinformation.model';
import * as _ from 'lodash';
import { MeetingEndPoint } from './meeting-endpoint.service';
import { Meeting } from 'src/app/models/Meeting/meeting.model';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {
  constructor(private router: Router, private http: HttpClient, private meetingEndPoint: MeetingEndPoint) {}

  scheduleMeeting(data: Meeting){
    return this.meetingEndPoint.scheduleMeetingEndpoint<Meeting[]>(data);
  }
  getMeetingsList() {
    return this.meetingEndPoint.getMeetingsListEndpoint<Meeting[]>();
  }
  updateMeeting(meeting: Meeting) {
    if (meeting.DoctorId) {
      return this.meetingEndPoint.updateMeetingEndPoint(meeting, meeting.DoctorId)
    }
    else {
      return this.meetingEndPoint.updateMeetingEndPoint<Meeting>(meeting, meeting.DoctorId).pipe(
        mergeMap(map => {
         meeting.DoctorId = meeting.DoctorId;
          return this.meetingEndPoint.updateMeetingEndPoint(meeting, meeting.DoctorId)
        }));
    }
  }
}
