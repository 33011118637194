<app></app>
<button [routerLink]="['/PatDashboard']">Back To DashBoard </button>


<div class="container-fluid">
  <div class="row d-flex justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <div class='BookingHome' *ngIf="!patBook">
            <ul class="book-actions p-0">
              <li  class="btn btn-primary"> <button (click)="BookNewVisit()" class="btn btn-primary"><i class="fa fa-wheelchair"></i>Book New Visit</button></li>
              <li class="d-inline mr-2"><button (click)="MyBookList()" class="btn btn-primary"><i class="fa fa-id-card"></i>My Booking List</button></li>
              <li class="d-inline mr-2"> <button (click)="MyPaidBooking()" class="btn btn-primary"><i class="fa fa-calendar-o"></i>My Paid Booking</button></li>

            </ul>
          </div>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
<footer></footer>

