import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { SchedulingEndpoint } from './scheduling-endpoint.service';
import { Scheduling, VisitDate, ScheduleInterval } from '../../models/scheduling/scheduling.model';
import { Visit } from 'src/app/models/visit/visit.model';


@Injectable()
export class SchedulingService {
  constructor(private router: Router, private http: HttpClient, private schedulingEndPoint: SchedulingEndpoint) {
  }
  getSchedulingDoctorList() {
    return this.schedulingEndPoint.getSchedulingDoctorList<Scheduling[]>();
  }
  getScheduleIntervalById(SchedulingIntervalId?: string) {
    return this.schedulingEndPoint.getScheduleIntervalByIdEndpoint<ScheduleInterval>(SchedulingIntervalId);
  }

  getScheduleIntervalBySchedulingId(SchedulingId?: string) {
    return this.schedulingEndPoint.getScheduleIntervalBySchedulingIdEndpoint<ScheduleInterval>(SchedulingId);
  }
  getSchedulingTime(mapId?: string) {
    return this.schedulingEndPoint.getSchedulingTimeEndpoint<Scheduling>(mapId);
  }
  getScheduling(SchedulingId?: string) {
    return this.schedulingEndPoint.geSchedulingByIdEndpoint<Scheduling>(SchedulingId);
  }

  getSchedulingOfDate(hospmapId?: string) {
    return this.schedulingEndPoint.getSchedulingOfDateEndpoint<Scheduling>(hospmapId);
  }
  postScheduling(scheduling: Scheduling) {
    return this.schedulingEndPoint.postSchedulingEndpoint<Scheduling>(scheduling);
  }
  addScheduling(scheduling: Scheduling) {
    return this.schedulingEndPoint.addSchedulingEndpoint<Scheduling>(scheduling);
  }
  updateScheduling(scheduling: Scheduling) {
    if (scheduling.SchedulingId) {
      return this.schedulingEndPoint.getUpdateSchedulingEndpoint(scheduling, scheduling.SchedulingId);
    }
    else {
      return this.schedulingEndPoint.getUpdateSchedulingEndpoint<Scheduling>(scheduling.SchedulingId).pipe(
        mergeMap(scheduling => {
          scheduling.SchedulingId = scheduling.SchedulingId;
          return this.schedulingEndPoint.getUpdateSchedulingEndpoint(scheduling, scheduling.SchedulingId)
        }));
    }
  }
  getTimeVisit(visitDate: string) {
    return this.schedulingEndPoint.getTimeVisitList<VisitDate[]>(visitDate);
  }
  getVisitTime(visitDate: string, docId?: string, deptId?: string, hosId?: string) {
    return this.schedulingEndPoint.getVisitTimeList<VisitDate[]>(visitDate, docId, deptId, hosId);
  }
  deleteSchedule(SchedulingId: string) {
    return this.schedulingEndPoint.deleteScheduleEndpoint<Scheduling>(SchedulingId);
  }
  postReschedule(visit: Visit, id: string, id1: string, id2: string) {
    var visit = _.omit(visit, ['PatientValidator']);
    return this.schedulingEndPoint.postRescheduleEndpoint<Scheduling>(visit, id, id1, id2);
  }

  checkExistingAppointment(id: string) {
    return this.schedulingEndPoint.checkExistingAppointmentEndpoint<Scheduling>(id);
  }

  deleteVisit(id: string) {
    return this.schedulingEndPoint.deleteVisitEndpoint<Visit>(id);
  }
}
