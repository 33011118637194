
export class DanphecareModel {
  public UserId: string;
  public FirstName: string;
  public MiddleName: string;
  public LastName: string; 
  public ContactNumber: string;
  public Email: string;
  public Service: string;
  public ServiceId : number;
  public Price : number;
  public PaymentProvider : string;
  
 
}