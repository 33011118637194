import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { NotificationService } from '../services/notification.service';
import { ContactUsModel } from '../models/contactUs/contactUs.model';
import { ContactUsService } from '../services/contactUs/contactUs.service';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';


@Component({
  templateUrl: './app.contactUs.html'
})
export class ContactUsComponent  {
  public Contact: ContactUsModel = new ContactUsModel();

  public contactedUsrlist: Array<any> = new Array<any>();
  p:number=1;

  constructor(public routing: Router,public http: HttpClient, public contactUsservice : ContactUsService,private notifyService: NotificationService, private alertService: AlertService,) {
  
   this.GetlistOfContactedUsr();
  }

  GetlistOfContactedUsr(){
    this.contactUsservice.GetlistOfContactedUsr()
    .subscribe(res => this.SuccessGetlistOfContactedUsr(res),
    res => this.GetlistOfContactedUsrError(res));
  }
  SuccessGetlistOfContactedUsr(res) {    
    this.contactedUsrlist = res;
    for (var i = 0; i < this.contactedUsrlist.length; i++) {
      this.contactedUsrlist[i].CreatedOn = moment(this.contactedUsrlist[i].CreatedOn).format("YYYY-MM-DD");
    }
    this.notifyService.showSuccess('Success', ' User Contatc list Load');
  }
  GetlistOfContactedUsrError(res) {
    this.notifyService.showError("Error", " field to load User Contact list")
  } 

  PostContactformdata(form: NgForm){ 
if(this.Contact != null){
  if (form.invalid) {
    this.notifyService.showError("", "Please fill up the required field");
    return;
  }
    this.contactUsservice.PostContactformdata(this.Contact)
    .subscribe(res => this.SuccessPostContactformdata(res),
    res => this.PostContactformdataError(res));
}

  }
  SuccessPostContactformdata(res) {    
    this.Contact = new ContactUsModel();
    this.notifyService.showSuccess('Success', ' Massage send Successfully ');
    this.GetlistOfContactedUsr();
  }
  PostContactformdataError(res) {
    this.notifyService.showError("Error", " Please fill up the required field")
  } 

}
