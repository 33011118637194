
export class HospitalDoctorMap {

  public HospitalDoctorMapId: string;
  public DoctorId: string;
  public HospitalId: string;
  public DepartmentId: string;
  public Charge: number;
  public ConsultationType: string;
  public HospitalName: string;
  public DepartmentName: string;
  public DoctorName: string;
  public AdminId: string;
}
