<div *ngIf="isDocEditor == false">
  <app></app>
  <button [routerLink]="['/DocDashboard']" class="back-btn-doc-room">
    Back <span>To DashBoard</span>
  </button>
</div>

<div class="card">
  <div class="patient-login" style="padding: 20px">
    <button
      type="button"
      style="margin-left: 57rem"
      aria-label="Close"
      (click)="cancel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <h5 *ngIf="isDocEditor == false" class="Doc-form profile-title">
      <i class="fa fa-user-md" aria-hidden="true"></i
      >Doctor&nbsp;&nbsp;|&nbsp;&nbsp;<span> Update Your Profile. </span>
    </h5>
    <h5 *ngIf="isDocEditor == true" class="Doc-form profile-title">
      <i class="fa fa-user-md" aria-hidden="true"></i
      >Doctor&nbsp;&nbsp;|&nbsp;&nbsp;<span> Update {{docName}} Profile. </span>
    </h5>
    <div class="alert alert-default d-flex">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
      <p>Fields with * are required.</p>
    </div>
    <form
      *ngIf="isDocEditor == false"
      #doctorForm="ngForm"
      (ngSubmit)="openDialog(doctorForm)"
    >
      <table>
        <tr class="3-cols">
          <td>
            <label
              >First Name<span class="required-sign" *ngIf="FirstName.invalid"
                >*</span
              ></label
            >
            <input
              type="text"
              name="FirstName"
              value=""
              [(ngModel)]="docInfo.FirstName"
              size="25"
              required
              #FirstName="ngModel"
            />
          </td>
          <td>
            <label>Middle Name </label>
            <input
              type="text"
              name="MiddleName"
              value=""
              size="25"
              [(ngModel)]="docInfo.MiddleName"
            />
          </td>
          <td>
            <label
              >Last Name<span class="required-sign" *ngIf="LastName.invalid"
                >*</span
              >
            </label>
            <input
              type="text"
              name="LastName"
              value=""
              size="25"
              [(ngModel)]="docInfo.LastName"
              required
              #LastName="ngModel"
            />
          </td>
        </tr>
        <tr>
          <td>
            <label for="NMC">
              NMC No.<span class="required-sign" *ngIf="NMC.invalid"
                >*</span
              ></label
            >
            <input
              type="number"
              name="NMC"
              value=""
              [(ngModel)]="docInfo.NMC"
              required
              #NMC="ngModel"
            />
          </td>
          <td style="vertical-align: top">
            <label for="Gender"
              >Gender<span class="required-sign" *ngIf="Gender.invalid"
                >*</span
              ></label
            >
            <input
              type="radio"
              value="Male"
              name="Sex"
              [(ngModel)]="docInfo.Gender"
              required
              #Gender="ngModel"
            />
            M<span class="hide-sm">ale &nbsp;&nbsp;</span>
            <input
              type="radio"
              value="Female"
              name="Sex"
              [(ngModel)]="docInfo.Gender"
              required
              #Gender="ngModel"
            />
            F<span class="hide-sm">emale&nbsp;&nbsp;</span>
            <input
              type="radio"
              value="Other"
              name="Sex"
              [(ngModel)]="docInfo.Gender"
              required
              #Gender="ngModel"
            />
            O<span class="hide-sm">ther&nbsp;&nbsp;</span>
          </td>
          <td>
            <label for="">Date of Birth</label>
            <div class="input-group">
              <input
                class="form-control"
                [maxDate]="today"
                placeholder="Datepicker"
                name="DOB"
                [(ngModel)]="docInfo.DateOfBirth"
                bsDatepicker
                #d="bsDatepicker"
              />
              <div class="input-group-append">
                <button
                  class="fa fa-calendar"
                  (click)="d.toggle()"
                  type="button"
                ></button>
              </div>
            </div>
          </td>
        </tr>
        <tr class="3-cols">
          <td>
            <label for="">Email</label>
            <input
              type="email"
              name="Email"
              [(ngModel)]="docInfo.MailingAddress"
            />
          </td>
          <td>
            <label for=""
              >Mobile Number
              <span class="required-sign" *ngIf="PhoneNumber.invalid"
                >*</span
              ></label
            >
            <input
              type="text"
              name="MobileNumber"
              disabled
              value=""
              [(ngModel)]="docInfo.PhoneNumber "
              #PhoneNumber="ngModel"
            />
            <input
              [(ngModel)]="docInfo.EnablePhNo"
              type="checkbox"
              name="enable"
              id="enable"
              [ngModelOptions]="{standalone: true}"
            />
            <label for="enable">Enable phone Number</label>
          </td>
          <td>
            <input
              type="file"
              #file
              accept=".jpg,.JPG,.png,.PNG"
              placeholder="Choose file"
              (change)="upload(file.files)"
              name="fileName"
              [(ngModel)]="docInfo.FilePath"
              [ngModelOptions]="{standalone: true}"
              style="display: none"
            />
            <button
              type="button"
              class="btn btn-primary d-inline"
              (click)="file.click()"
              style="font-size: 14px; margin-top: 25px"
            >
              <i class="fa fa-upload" aria-hidden="true"></i>Upload Image
            </button>
            <button
              type="button"
              (click)="Removepicture()"
              class="btn btn-primary d-inline"
              style="font-size: 14px; margin-top: 25px"
            >
              Remove
            </button>
            <div class="col-md-10 d-inline">
              <span class="upload" *ngIf="progress > 0" style="font-size: 11px">
                {{progress}}%
              </span>
              <span class="upload" *ngIf="message" style="font-size: 11px">
                {{message}}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div class="md-form">
              <label for="area">Long Signature:</label>
              <textarea
                id="area"
                class="md-textarea form-control"
                rows="2"
                [(ngModel)]="docInfo.LongSignature"
                [ngModelOptions]="{standalone: true}"
              ></textarea>
            </div>
          </td>
        </tr>
      </table>

      <div
        class=""
        *ngIf="docInfo.HospitalDepartmentMap != null"
        style="margin-top: 15px"
      >
        <table
          aria-label="Hospital Doctor Mapping"
          class="table table-bordered doc-hos-list-prf"
        >
          <thead>
            <tr>
              <th style="width: 100px">S. No</th>
              <th>Hospital Name</th>
              <th>Department Name</th>
              <th>Consultation Type</th>
              <th>Charge</th>
              <th *ngIf="allowEdit">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let hospdoc of docInfo.HospitalDepartmentMap;let i = index;"
            >
              <td style="font-weight: 500; margin-bottom: 0">{{i+1}}</td>
              <td>
                <p style="font-weight: 500; margin-bottom: 0">
                  {{hospdoc.HospitalName}}
                </p>
              </td>
              <td style="white-space: break-spaces; line-height: 1.5">
                <p style="font-weight: 500; margin-bottom: 0">
                  {{hospdoc.DepartmentName}}
                </p>
              </td>
              <td>
                <p style="font-weight: 500; margin-bottom: 0">
                  {{hospdoc.ConsultationType}}
                </p>
              </td>
              <td>
                <p style="font-weight: 500; margin-bottom: 0">
                  {{hospdoc.Charge}}
                </p>
              </td>
              <td *ngIf="allowEdit">
                <i
                  class="fa fa-trash-o text-danger"
                  aria-hidden="true"
                  style="font-size: 16px"
                  (click)="deleteHospitalMap(hospdoc.HospitalDoctorMapId)"
                  [attr.disabled]="true"
                ></i>
                &nbsp;&nbsp;
                <i
                  class="fa fa-pencil text-primary"
                  aria-hidden="true"
                  style="font-size: 16px"
                  (click)="editHospitalMap(hospdoc.HospitalDoctorMapId)"
                  [attr.disabled]="true"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class=""
        *ngIf="docInfo.DoctorQualification != null"
        style="margin-top: 15px"
      >
        <table
          aria-label="Pass Affiliation"
          class="table table-bordered doc-hos-list-prf"
        >
          <thead>
            <tr>
              <th style="width: 100px">S. No</th>
              <th>Designation</th>
              <th>Education</th>
              <th>Experience(Years)</th>
              <th>PastAffiliation</th>
              <th>Membership</th>
              <th *ngIf="allowEdit">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let qualf of docInfo.DoctorQualification;let i = index;"
            >
              <td style="font-weight: 500; margin-bottom: 0">{{i+1}}</td>
              <td style="white-space: break-spaces; line-height: 1.5">
                <p style="font-weight: 500; margin-bottom: 0">
                  {{qualf.Designation}}
                </p>
              </td>
              <td style="white-space: break-spaces; line-height: 1.5">
                <p style="font-weight: 500; margin-bottom: 0">
                  {{qualf.Education}}
                </p>
              </td>
              <td style="white-space: break-spaces; line-height: 1.5">
                <p style="font-weight: 500; margin-bottom: 0">
                  {{qualf.Experience}}
                </p>
              </td>
              <td style="white-space: break-spaces; line-height: 1.5">
                <p style="font-weight: 500; margin-bottom: 0">
                  {{qualf.PastAffiliation}}
                </p>
              </td>
              <td style="white-space: break-spaces; line-height: 1.5">
                <p style="font-weight: 500; margin-bottom: 0">
                  {{qualf.Membership}}
                </p>
              </td>
              <td *ngIf="allowEdit">
                <i
                  class="fa fa-pencil text-primary"
                  aria-hidden="true"
                  style="font-size: 16px"
                  (click)="editQualification(qualf.QualificationId)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="alert alert-default" style="margin-top: 15px">
        <h5>Qualification</h5>
        <table aria-label="Experience" class="table table-striped table-hover">
          <thead>
            <tr class="table-tr-style">
              <th>Designation</th>
              <th>Education</th>
              <th>Experience(Years)</th>
              <th>PastAffiliation</th>
              <th>Membership</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let hospdoc of qualification;let i = index;">
              <td>
                <textarea
                  class="md-textarea form-control"
                  [(ngModel)]="qualification[i].Designation"
                  type="text"
                  rows="1"
                  [ngModelOptions]="{standalone: true}"
                >
                </textarea>
              </td>
              <td>
                <textarea
                  type="text"
                  class="md-textarea form-control"
                  [ngModelOptions]="{standalone: true}"
                  rows="1"
                  [(ngModel)]="qualification[i].Education"
                >
                </textarea>
              </td>
              <td>
                <textarea
                  type="text"
                  class="md-textarea form-control"
                  [ngModelOptions]="{standalone: true}"
                  rows="1"
                  [(ngModel)]="qualification[i].Experience"
                ></textarea>
              </td>
              <td>
                <textarea
                  type="text"
                  class="md-textarea form-control"
                  [ngModelOptions]="{standalone: true}"
                  rows="1"
                  [(ngModel)]="qualification[i].PastAffiliation"
                ></textarea>
              </td>
              <td>
                <textarea
                  type="text"
                  class="md-textarea form-control"
                  [ngModelOptions]="{standalone: true}"
                  rows="1"
                  [(ngModel)]="qualification[i].Membership"
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- This is from Doctor -->
      <div class="alert alert-default" style="margin-top: 15px">
        <table
          aria-label="Hospital Doctor Mapping"
          class="table table-striped table-hover for-mobile"
        >
          <thead class="d-sm-none d-md-block">
            <tr class="table-tr-style">
              <th>Hospital</th>
              <th>Department</th>
              <th>Charge</th>
              <th>Consultation Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let hospdoc of hospdocMap;let i = index;">
              <td>
                <label class="d-none d-sm-block d-md-none">Hospital:</label>
                <select
                  class="form-control"
                  [(ngModel)]="hospdocMap[i].HospitalId"
                  placeholder="Hospitall"
                  [ngModelOptions]="{standalone: true}"
                  (focusout)="hospitalChanged(); hospitalDepartmentMap(hospdocMap[i].HospitalId);"
                >
                  <option
                    *ngFor="let list of hospitalList"
                    [value]="list.HospitalId"
                  >
                    {{list.HospitalName}}
                  </option>
                </select>
              </td>
              <td>
                <label class="d-none d-sm-block d-md-none">Department:</label>
                <select
                  class="form-control"
                  [(ngModel)]="hospdocMap[i].DepartmentId"
                  placeholder="Department"
                  name="Department"
                  [ngModelOptions]="{standalone: true}"
                  (focusout)="departmentChanged()"
                  required
                  #Department="ngModel"
                >
                  <option
                    *ngFor="let list of deptList"
                    [value]="list.DepartmentId"
                  >
                    {{list.DepartmentName}}
                  </option>
                </select>
              </td>
              <td>
                <label class="d-none d-sm-block d-md-none">Charge:</label>
                <input
                  class="form-control"
                  [(ngModel)]="hospdocMap[i].Charge"
                  type="number"
                  [ngModelOptions]="{standalone: true}"
                  min="0"
                  oninput="validity.valid||(value='');"
                  placeholder="Charge"
                />
              </td>
              <td>
                <label class="d-none d-sm-block d-md-none"
                  >Consultation Type:</label
                >
                <select
                  class="form-control"
                  [(ngModel)]="hospdocMap[i].ConsultationType"
                  placeholder="Consultation Type"
                  [ngModelOptions]="{standalone: true}"
                >
                  <option *ngFor="let c of ConsultationType" [ngValue]="c.name">
                    {{c.name}}
                  </option>
                </select>
              </td>
              <td>
                <a (click)="addRow(i)" class="round-btn btn-primary">+</a>
                <a
                  style="margin-left: 10px"
                  (click)="deleteRow(i)"
                  class="round-btn btn-danger"
                  >x</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12">
        <button
          *ngIf="isDocEditor == false"
          class="btn btn-success"
          (click)="CancelUpdate()"
        >
          Cancel
        </button>
        &nbsp;&nbsp;
        <button class="btn btn-success" value="Submit">Update</button>
      </div>
    </form>

    <div *ngIf="isDocEditor == true">
      <table aria-label="Doctor Information">
        <tr class="3-cols">
          <td>
            <label
              >First Name<span class="required-sign" *ngIf="FirstName.invalid"
                >*</span
              ></label
            >
            <input
              type="text"
              name="FirstName"
              value=""
              [(ngModel)]="docInfo.FirstName"
              size="25"
              required
              #FirstName="ngModel"
            />
          </td>
          <td>
            <label>Middle Name </label>
            <input
              type="text"
              name="MiddleName"
              value=""
              size="25"
              [(ngModel)]="docInfo.MiddleName"
            />
          </td>
          <td>
            <label
              >Last Name<span class="required-sign" *ngIf="LastName.invalid"
                >*</span
              >
            </label>
            <input
              type="text"
              name="LastName"
              value=""
              size="25"
              [(ngModel)]="docInfo.LastName"
              required
              #LastName="ngModel"
            />
          </td>
        </tr>
        <tr>
          <td>
            <label for="NMC">
              NMC No.<span class="required-sign" *ngIf="NMC.invalid"
                >*</span
              ></label
            >
            <input
              type="number"
              name="NMC"
              value=""
              [(ngModel)]="docInfo.NMC"
              required
              #NMC="ngModel"
            />
          </td>
          <td style="vertical-align: top">
            <label for="Gender"
              >Gender<span class="required-sign" *ngIf="Gender.invalid"
                >*</span
              ></label
            >
            <input
              type="radio"
              value="Male"
              name="Sex"
              [(ngModel)]="docInfo.Gender"
              required
              #Gender="ngModel"
            />
            M<span class="hide-sm">ale &nbsp;&nbsp;</span>
            <input
              type="radio"
              value="Female"
              name="Sex"
              [(ngModel)]="docInfo.Gender"
              required
              #Gender="ngModel"
            />
            F<span class="hide-sm">emale&nbsp;&nbsp;</span>
            <input
              type="radio"
              value="Other"
              name="Sex"
              [(ngModel)]="docInfo.Gender"
              required
              #Gender="ngModel"
            />
            O<span class="hide-sm">ther&nbsp;&nbsp;</span>
          </td>
          <td>
            <label for="">Date of Birth</label>
            <div class="input-group">
              <input
                class="form-control"
                [maxDate]="today"
                placeholder="Datepicker"
                name="DOB"
                [(ngModel)]="docInfo.DateOfBirth"
                bsDatepicker
                #d="bsDatepicker"
              />
              <div class="input-group-append">
                <button
                  class="fa fa-calendar"
                  (click)="d.toggle()"
                  type="button"
                ></button>
              </div>
            </div>
          </td>
        </tr>
        <tr class="3-cols">
          <td>
            <label for="">Email</label>
            <input
              type="email"
              pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
              name="Email"
              [(ngModel)]="docInfo.MailingAddress"
            />
          </td>
          <td>
            <label for=""
              >Mobile Number
              <span class="required-sign" *ngIf="PhoneNumber.invalid"
                >*</span
              ></label
            >
            <input
              type="text"
              name="MobileNumber"
              disabled
              value=""
              [(ngModel)]="docInfo.PhoneNumber "
              #PhoneNumber="ngModel"
            />
            <input
              [(ngModel)]="docInfo.EnablePhNo"
              type="checkbox"
              name="enable"
              id="enable"
              [ngModelOptions]="{standalone: true}"
            />
            <label for="enable">Enable phone Number</label>
          </td>
          <td>
            <input
              type="file"
              #file
              placeholder="Choose file"
              (change)="upload(file.files)"
              name="fileName"
              [(ngModel)]="docInfo.FilePath"
              [ngModelOptions]="{standalone: true}"
              style="display: none"
            />
            <button
              type="button"
              class="btn btn-primary d-inline"
              (click)="file.click()"
              style="font-size: 14px; margin-top: 25px"
            >
              <i class="fa fa-upload"></i>Upload Image
            </button>
            <button
              type="button"
              (click)="Removepicture()"
              class="btn btn-primary d-inline"
              style="font-size: 14px; margin-top: 25px"
            >
              Remove
            </button>
            <div class="col-md-10 d-inline">
              <span class="upload" *ngIf="progress > 0" style="font-size: 11px">
                {{progress}}%
              </span>
              <span class="upload" *ngIf="message" style="font-size: 11px">
                {{message}}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div class="md-form">
              <label for="area">Long Signature:</label>
              <textarea
                id="area"
                class="md-textarea form-control"
                rows="2"
                [(ngModel)]="docInfo.LongSignature"
                [ngModelOptions]="{standalone: true}"
              ></textarea>
            </div>
          </td>
        </tr>
      </table>

      <div
        class=""
        *ngIf="docInfo.HospitalDepartmentMap != null"
        style="margin-top: 15px"
      >
        <table
          aria-label="Hospital Doctor Mapping"
          class="table table-bordered doc-hos-list-prf"
        >
          <thead>
            <tr>
              <th style="width: 100px; font-weight: 900">S. No</th>
              <th style="font-weight: 900">Hospital Name</th>
              <th style="font-weight: 900">Department Name</th>
              <th style="font-weight: 900">Consultation Type</th>
              <th style="font-weight: 900">Charge</th>
              <th style="font-weight: 900">Action specific</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let hospdoc of docInfo.HospitalDepartmentMap;let i = index;"
            >
              <td style="font-weight: 500; margin-bottom: 0">{{i+1}}</td>
              <td class="hover_container_update_doctor">
                <p
                  style="font-weight: 500; margin-bottom: 0"
                  class="doctor_update_table"
                >
                  {{hospdoc.HospitalName}}
                </p>
                <p class="tooltip_doctor_update">{{hospdoc.HospitalName}}</p>
              </td>
              <td style="white-space: break-spaces; line-height: 1.5">
                <p style="font-weight: 500; margin-bottom: 0">
                  {{hospdoc.DepartmentName}}
                </p>
              </td>
              <td>
                <p style="font-weight: 500; margin-bottom: 0">
                  {{hospdoc.ConsultationType}}
                </p>
              </td>
              <td>
                <p style="font-weight: 500; margin-bottom: 0">
                  {{hospdoc.Charge}}
                </p>
              </td>
              <td>
                <i
                  class="fa fa-trash-o text-danger"
                  aria-hidden="true"
                  style="font-size: 16px"
                  (click)="deleteHospitalMap(hospdoc.HospitalDoctorMapId)"
                ></i>
                &nbsp;&nbsp;
                <i
                  class="fa fa-pencil text-primary"
                  aria-hidden="true"
                  style="font-size: 16px"
                  (click)="editHospitalMap(hospdoc)"
                  [attr.disabled]="true"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class=""
        *ngIf="docInfo.DoctorQualification != null"
        style="margin-top: 15px"
      >
        <table
          aria-label="Doctor Experience"
          class="table table-bordered doc-hos-list-prf"
        >
          <thead>
            <tr>
              <th style="width: 100px; font-weight: 900">S. No</th>
              <th style="font-weight: 900">Designation</th>
              <th style="font-weight: 900">Education</th>
              <th style="font-weight: 900">Experience(Years)</th>
              <th style="font-weight: 900">PastAffiliation</th>
              <th style="font-weight: 900">Membership</th>
              <th style="font-weight: 900">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let qualf of docInfo.DoctorQualification;let i = index;"
            >
              <td style="font-weight: 500; margin-bottom: 0">{{i+1}}</td>
              <td style="white-space: break-spaces; line-height: 1.5">
                <p style="font-weight: 500; margin-bottom: 0">
                  {{qualf.Designation}}
                </p>
              </td>
              <td style="white-space: break-spaces; line-height: 1.5">
                <p style="font-weight: 500; margin-bottom: 0">
                  {{qualf.Education}}
                </p>
              </td>
              <td>
                <p style="font-weight: 500; margin-bottom: 0">
                  {{qualf.Experience}}
                </p>
              </td>
              <td style="white-space: break-spaces; line-height: 1.5">
                <p style="font-weight: 500; margin-bottom: 0">
                  {{qualf.PastAffiliation}}
                </p>
              </td>
              <td>
                <p style="font-weight: 500; margin-bottom: 0">
                  {{qualf.Membership}}
                </p>
              </td>
              <td>
                <i
                  class="fa fa-pencil text-primary"
                  aria-hidden="true"
                  style="font-size: 16px"
                  (click)="editQualification(qualf.QualificationId)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="alert alert-default" style="margin-top: 15px">
        <label>Qualification </label>
        <table
          aria-label="Doctor Experience"
          class="table table-striped table-hover"
        >
          <thead>
            <tr class="table-tr-style">
              <th><b>Designation</b></th>
              <th><b>Education</b></th>
              <th>Experience(Years)</th>
              <th>PastAffiliation</th>
              <th>Membership</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let hospdoc of qualification;let i = index;">
              <td>
                <input
                  class="form-control"
                  [(ngModel)]="qualification[i].Designation"
                  type="text"
                  [ngModelOptions]="{standalone: true}"
                />
              </td>
              <td>
                <textarea
                  type="text"
                  class="md-textarea form-control"
                  [ngModelOptions]="{standalone: true}"
                  rows="1"
                  [(ngModel)]="qualification[i].Education"
                >
                </textarea>
              </td>
              <td>
                <textarea
                  type="text"
                  class="md-textarea form-control"
                  [ngModelOptions]="{standalone: true}"
                  rows="1"
                  [(ngModel)]="qualification[i].Experience"
                ></textarea>
              </td>
              <td>
                <textarea
                  type="text"
                  class="md-textarea form-control"
                  [ngModelOptions]="{standalone: true}"
                  rows="1"
                  [(ngModel)]="qualification[i].PastAffiliation"
                ></textarea>
              </td>
              <td>
                <textarea
                  type="text"
                  class="md-textarea form-control"
                  [ngModelOptions]="{standalone: true}"
                  rows="1"
                  [(ngModel)]="qualification[i].Membership"
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- This is from admin update profile. -->
      <div class="alert alert-default" style="margin-top: 15px">
        <table
          aria-label="Hospital Doctor Mapping"
          class="table table-striped table-hover for-mobile"
        >
          <thead class="d-sm-none d-md-block">
            <tr class="table-tr-style">
              <th><b>Hospital</b></th>
              <th><b>Department</b></th>
              <th>Charge</th>
              <th>Consultation Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let hospdoc of hospdocMap;let i = index;">
              <td>
                <label class="d-none d-sm-block d-md-none">Hospital:</label>
                <select
                  class="form-control"
                  [(ngModel)]="hospdocMap[i].HospitalId"
                  placeholder="Hospital"
                  [ngModelOptions]="{standalone: true}"
                  (focusout)="hospitalChanged();hospitalDepartmentMap(hospdocMap[i].HospitalId);"
                >
                  <option
                    *ngFor="let list of hospitalList"
                    [value]="list.HospitalId"
                  >
                    {{list.HospitalName}}
                  </option>
                </select>
              </td>
              <td>
                <label class="d-none d-sm-block d-md-none">Department:</label>
                <select
                  class="form-control"
                  [(ngModel)]="hospdocMap[i].DepartmentId"
                  placeholder="Department"
                  name="Department"
                  [ngModelOptions]="{standalone: true}"
                  (focusout)="departmentChanged()"
                  required
                >
                  <option>Select Department</option>
                  <option
                    *ngFor="let list of deptList"
                    [value]="list.DepartmentId"
                  >
                    {{list.DepartmentName}}
                  </option>
                </select>
              </td>
              <td>
                <label class="d-none d-sm-block d-md-none">Charge:</label>
                <input
                  class="form-control"
                  [(ngModel)]="hospdocMap[i].Charge"
                  type="number"
                  [ngModelOptions]="{standalone: true}"
                  min="0"
                  oninput="validity.valid||(value='');"
                  placeholder="Charge"
                />
              </td>
              <td>
                <label class="d-none d-sm-block d-md-none"
                  >Consultation Type:</label
                >
                <select
                  class="form-control"
                  [(ngModel)]="hospdocMap[i].ConsultationType"
                  placeholder="Consultation Type"
                  [ngModelOptions]="{standalone: true}"
                >
                  <option *ngFor="let c of ConsultationType" [ngValue]="c.name">
                    {{c.name}}
                  </option>
                </select>
              </td>
              <td>
                <a (click)="addRow(i)" class="round-btn btn-primary">+</a>
                <a
                  style="margin-left: 10px"
                  (click)="deleteRow(i)"
                  class="round-btn btn-danger"
                  >x</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12">
        <button *ngIf="isDocEditor" class="btn btn-success" (click)="cancel()">
          Cancel
        </button>
        &nbsp;&nbsp;
        <button class="btn btn-success" (click)="updatedoctor()">Update</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="myModal"
  tabindex="-1"
  role="dialog"
  [style.display]="editModal ? 'block' : 'none'"
  aria-labelledby="myModalLabel"
  aria-hidden="false"
  style="display: block"
>
  <div class="modal-dialog modal-lg">
    <div
      class="modal-content"
      style="background-color: transparent; border: none"
    >
      <div class="doc-schedule">
        <h1 class="card-title" style="width: 100%">
          Edit HospitalDepartmentMap
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="hide()"
          >
            &times;
          </button>
        </h1>
        <div class="alert alert-default" style="margin-top: 15px">
          <table
            aria-label="Hosptial Doctor Mapping"
            class="table table-striped table-hover"
          >
            <thead>
              <tr class="table-tr-style">
                <!-- I am here -->
                <th>Hospital</th>
                <th>Department specific</th>
                <th>Charge</th>
                <th>Consultation Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label class="d-none d-sm-block d-md-none">Hospital:</label>
                  <select
                    class="form-control"
                    [(ngModel)]="selHospital"
                    placeholder="Hospital"
                    [ngModelOptions]="{standalone: true}"
                    (focusout)="hospitalChanged();hospitalDepartmentMap(selHospital);"
                  >
                    <option
                      *ngFor="let list of hospitalList"
                      [value]="list.HospitalId"
                      [selected]="list.HospitalId == HospitalId"
                    >
                      {{list.HospitalName}}
                    </option>
                  </select>
                </td>
                <td>
                  <label class="d-none d-sm-block d-md-none">Department:</label>
                  <!-- (focusout)="departmentChanged();" (click)="hospitalDepartmentMap(selHospital);" -->
                  <select
                    class="form-control"
                    [(ngModel)]="selDepartment"
                    placeholder="Department"
                    [ngModelOptions]="{standalone: true}"
                  >
                    <option
                      *ngFor="let list of deptList"
                      [value]="list.DepartmentId"
                      [selected]="list.DepartmentId == DepartmentId "
                    >
                      {{list.DepartmentName}}
                    </option>
                  </select>
                </td>
                <td>
                  <label class="d-none d-sm-block d-md-none">Charge:</label>
                  <input
                    class="form-control"
                    [(ngModel)]="Charge"
                    type="number"
                    [ngModelOptions]="{standalone: true}"
                    min="0"
                    oninput="validity.valid||(value='');"
                    placeholder="Charge"
                  />
                </td>
                <td>
                  <label class="d-none d-sm-block d-md-none"
                    >Consultation Type:</label
                  >
                  <select
                    class="form-control"
                    [(ngModel)]="selConsultation"
                    placeholder="Consultation Type"
                    [ngModelOptions]="{standalone: true}"
                    (focusout)="consulationChanged()"
                  >
                    <option
                      *ngFor="let c of ConsultationType"
                      [ngValue]="c.name"
                      [selected]="c.name == Consultation"
                    >
                      {{c.name}}
                    </option>
                    <!--  -->
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="print-action mt-5 text-center m-auto d-block mb-5"
          style="margin: 15px auto !important"
        >
          <input type="button" value="Update" (click)="Update()" />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="myModal"
  tabindex="-1"
  role="dialog"
  [style.display]="editQualf ? 'block' : 'none'"
  aria-labelledby="myModalLabel"
  aria-hidden="false"
  style="display: block"
>
  <div class="modal-dialog modal-lg">
    <div
      class="modal-content"
      style="background-color: transparent; border: none"
    >
      <div class="doc-schedule">
        <h1 class="card-title" style="width: 100%">
          Edit Qualification
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="hide()"
          >
            &times;
          </button>
        </h1>

        <div class="alert alert-default" style="margin-top: 15px">
          <table
            aria-label="Doctor Experience"
            class="table table-striped table-hover"
          >
            <thead>
              <tr class="table-tr-style">
                <th>Designation</th>
                <th>Education</th>
                <th>Experience(Years)</th>
                <th>PastAffiliation</th>
                <th>Membership</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of qualificationDetail">
                <td>
                  <input
                    class="form-control"
                    [(ngModel)]="list.Designation"
                    type="text"
                    [ngModelOptions]="{standalone: true}"
                  />
                </td>
                <td>
                  <textarea
                    type="text"
                    class="md-textarea form-control"
                    [ngModelOptions]="{standalone: true}"
                    rows="1"
                    [(ngModel)]="list.Education"
                  >
                  </textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    class="md-textarea form-control"
                    [ngModelOptions]="{standalone: true}"
                    rows="1"
                    [(ngModel)]="list.Experience"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    class="md-textarea form-control"
                    [ngModelOptions]="{standalone: true}"
                    rows="1"
                    [(ngModel)]="list.PastAffiliation"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    class="md-textarea form-control"
                    [ngModelOptions]="{standalone: true}"
                    rows="1"
                    [(ngModel)]="list.Membership"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="print-action mt-5 text-center m-auto d-block mb-5"
          style="margin: 15px auto !important"
        >
          <input type="button" value="Update" (click)="UpdateQualification()" />
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isDocEditor == false">
  <footer></footer>
</div>
