import { Component, OnInit, Inject } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
// import { ThrowStmt } from '@angular/compiler';
import { Observable, concat } from 'rxjs'
import * as moment from 'moment/moment';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { DatePipe } from '@angular/common'
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Global } from '../app.global';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';
import { NotificationService } from '../services/notification.service';
import { PatientService } from '../services/patient/patient.service';
import { Patient } from '../models/patient/patient.model';
import { DoctorService } from '../services/doctor/doctor.service';
import { Doctor } from '../models/doctor/doctor.model';
import { HospitalService } from '../services/hospital/hospital.service';
import { Hospital } from '../models/hospital/hospital.model';
@Component({
  
  templateUrl: './app.consentform.html'
})
export class ConsentFormComponent {
  token: any;
  public patInfo: Patient = new Patient();
  public hosInfo: Hospital = new Hospital();
  public docInfo: Doctor = new Doctor();
  constructor(public global: Global, public docService: DoctorService, public patservice: PatientService, public hosService : HospitalService , @Inject(MAT_DIALOG_DATA) public data: any, public alertService: AlertService, public route: ActivatedRoute,
    public router: Router, public httpClient: HttpClient, private authenticationService: AuthenticationService,
    public http: HttpClient, private datepipe: DatePipe, private formBuilder: FormBuilder, private notifyService: NotificationService,
    ) {
    this.token = this.authenticationService.currentUserValue;
    this.getPatientName();
    this.GetDoctorName();
    this.GetHospitalName();
  }

  public getPatientName() {
    this.patservice.getPatient(this.token.PatientIdentifier,this.token.UserType).subscribe(res => {
      this.patInfo = res;
    },
      error => {
        this.alertService.error(error);
      });
  }
  public GetDoctorName() {
    //var id= this.token.DoctorIdentifier;
    this.docService.getDoctor(this.data.DoctorId).subscribe(res => {
      this.docInfo = res;
    },
      error => {
        this.alertService.error(error);
      }
    );
  }
  public GetHospitalName() {
    //var id= this.token.DoctorIdentifier;
    this.hosService.getHospital(this.data.HospitalId).subscribe(res => {
      this.hosInfo = res;
    
    },
      error => {
        this.alertService.error(error);
      }
    );
  }
}

