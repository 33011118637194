<h2 mat-dialog-title>Payment Message</h2>
<div *ngIf="data.method != 'Card'">
  <mat-dialog-content>{{data.message}}</mat-dialog-content>
</div>
<div *ngIf="data.method == 'Card'">
  <mat-dialog-content>{{data.message}}</mat-dialog-content>
  <div *ngIf="data.status == 'failure'">
    The Transaction failure could be one of the following reasons
    <ul>
      <li>You might have entered wrong card number, expiry date or CVV code.</li>
      <li>Your card issuing bank declined the card processing. Please ask your bank to enable “Online Transaction” in your card.</li>
    </ul>
  </div>
</div>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
