import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user/user.model';
import { HospitalService } from '../services/hospital/hospital.service';
import { PatientService } from '../services/patient/patient.service';
import { DoctorService } from '../services/doctor/doctor.service';
import { Global } from '../app.global';
import { AutoLogoutService } from '../services/autologout.service';
import { AlertService } from '../services/alert.service';
import { Patient } from '../models/patient/patient.model';
import { Doctor } from '../models/doctor/doctor.model';
import { ChatService } from '../services/ChatNotification/chat-notification.service';
import { Observable } from 'rxjs';
import { EventNotificationService } from '../services/EventNotification/event-notification.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { environment } from '../../environments/environment';
import { PasswordComponent } from './common/password/password.component';
@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit {
  websiteUrl = environment.url;
  currentUser: User;
  public token: any;
  public seeAll: boolean = false;
  public allVisitsList: any;
  public patInfo: Patient = new Patient();
  public docInfo: Doctor = new Doctor();
  public notification$: Observable<any>;
  public notificationArray = [];
  public temporaryNotificationArray = [];
  public userNotifications = [];
  public closeResult: string;

  @ViewChild('pwdEditor', { static: true })
  public pwdEditor: PasswordComponent;
  public pwdModal: boolean;

  // public patInfo: Patient = new Patient();
  constructor(public global: Global, private autoLogout: AutoLogoutService, public alertService: AlertService,
    public hospitalService: HospitalService, public patService: PatientService, public docService: DoctorService,
    private router: Router,
    private eventNotify: EventNotificationService,
    private authenticationService: AuthenticationService, private modalService: NgbModal,
    private _chatService: ChatService
  ) {

    this.notification$ = this._chatService.notification$;
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.token = this.authenticationService.currentUserValue;
    if (this.token.UserType == 'Doctor') {
      this.GetDoctorName();
    }
    if (this.token.UserType == 'Patient') {
      this.GetPatientName();
    }
  }

  ngOnInit() {
    this.getUserNotification();
    this.notification$.subscribe(res => {

      if (res[0] === undefined) {
        //initially notice list empty
      }
      else {
        this.temporaryNotificationArray = [];
        for (var i = 0; i < res.length; i++) {
          var temp = {
            "isSeen": res[i].IsSeen,
            "message": res[i].Message,
            "notificationType": res[i].NotificationType,
            "userNotificationId": res[i].UserNotificationId,
            "timeDuration": res[i].TimeDuration,
          };
          this.temporaryNotificationArray.push(temp);
        }
        console.log("notification");
        this.notificationArray = this.temporaryNotificationArray;
        // console.log(this.notificationArray);
        var audio = new Audio(
          '~/../assets/notification-sound/sound1.wav');
        audio.play();
      }
    });
  }

  ngAfterViewInit() {
    this.pwdEditor.changesSavedCallback = () => {
      this.pwdModal = false;
      this.authenticationService.logout();
    };
    this.pwdEditor.changesCancelledCallback = () => {
      this.pwdModal = false;
    };
  }
  open(content) {
    this.modalService.open(content, { windowClass: 'modal-search' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  logout() {
    this.PatStatusChange();
    this.global.apiLogout = true;
    this.authenticationService.logout();
    this.docService.DoctorId = null;
    this.patService.VisitId = null;
    this.hospitalService.HospitalId = null;
    this.hospitalService.DepartmentId = null;
    this.global.DepartmentId = null;
    this.global.DoctorId = null;
    this.global.HospitalId = null;
    this._chatService.Disconnect();
    this.router.navigate(['/login']);
    //this.PatStatusChange();
  }

  public GetDoctorName() {
    //var id= this.token.DoctorIdentifier;
    this.docService.getDoctor(this.token.DoctorIdentifier).subscribe(res => {
      this.docInfo = res;
    });
    error => {
      this.alertService.error(error);
    }
  }

  public GetPatientName() {
    //var id = this.token.PatientIdentifier;
    this.patService.getPatient(this.token.PatientIdentifier,this.token.UserType).subscribe(
      (res) => {
        this.patInfo = res;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }
  public PatStatusChange() {
    if (this.token.UserType == 'Patient') {
      this.patService.getPatientBookingList(this.token.PatientIdentifier).subscribe(res => {
        this.allVisitsList = res;
        for (var i = 0; i < this.allVisitsList.length; i++) {
          if ((this.allVisitsList[i].Status === 'ongoing' || this.allVisitsList[i].Status === 'initiated') && this.allVisitsList[i].IsActive === true) {
            this.patService.changeStatus(this.allVisitsList[i].VisitId)
              .subscribe(res => this.SuccesschangeStatus(res),
                res => this.Error(res));
          }
        }

      },
        error => {
          this.alertService.error(error);
          // this.loading = false;
        });
    }
  }
  Error(res) {

  }
  SuccesschangeStatus(res) {
    this.authenticationService.logout();
    // this.router.navigate(['/login']);
  }
  seeAllStatus() {
    this.seeAll = !this.seeAll;
  }

  markAsRead() {
    if (this.notificationArray.length == 0) {
      return;
    }
    var mappedArray = this.notificationArray.map(x => {
      let object = {};
      object["UserNotificationId"] = x.userNotificationId
      return object
    });
    var object = {
      "markAsReadIdsList": mappedArray
    }

    this.eventNotify.markAsReadStatus(mappedArray).subscribe(res => {
      const newNotificationArray = this.notificationArray.map((notification) => ({
        ...notification,
        isSeen: true,
      }));
      this.notificationArray = newNotificationArray;
    }, err => {
    });
  }

  routeToPage(data) {
    var tempArray = _.cloneDeep(this.notificationArray);
    tempArray = tempArray.filter(x => x.userNotificationId == data.userNotificationId);
    var mappedArray = tempArray.map(x => {
      let object = {};
      object["UserNotificationId"] = x.userNotificationId
      return object
    });
    //var object = {
    //  "markAsReadIdsList": mappedArray
    //}
    this.eventNotify.markAsReadStatus(mappedArray).subscribe(res => {
      const newNotificationArray = this.notificationArray.map((notification) => ({
        ...notification,
        isSeen: data.userNotificationId == notification.userNotificationId ? true : false
      }));
      this.notificationArray = newNotificationArray;
      window.location.reload();
    }, err => {
    });
    //this.router.navigate(['/', data.notificationType]);
  }



  getUserNotification() {
    this.eventNotify.getUserNotification().subscribe(res => {
      this.userNotifications = res;
      // console.log(this.userNotifications);
      if (this.userNotifications.length != 0) {
        //  this.notificationArray = this.userNotifications;
        for (var i = 0; i < this.userNotifications.length; i++) {
          var temp = {
            "isSeen": this.userNotifications[i].IsSeen,
            "message": this.userNotifications[i].Message,
            "notificationType": this.userNotifications[i].NotificationType,
            "userNotificationId": this.userNotifications[i].UserNotificationId,
            "timeDuration": this.userNotifications[i].TimeDuration,
          };
          this.notificationArray.push(temp);
        }
        // console.table(this.notificationArray);
        //alert("notification unread");
        //this.displayRedBellIcon = true;
      }

    }, err => {

    });
  }



  changePassword() {
    var isDoctor = this.token.UserType === 'Doctor';
    if (isDoctor) {
      this.pwdEditor.resetPwdDoctor(this.docInfo, this.docInfo.DoctorName, isDoctor);
    }
    else {
      this.pwdEditor.resetPwd(this.patInfo, this.patInfo.FullName);
    }
    this.pwdModal = true;

  }
}
