<style>
  @media Print {

    #Report,
    #Report h5,
    #Report h6,
    #Report p,
    #Report td,
    #Report legend,
    #Report fieldset {
      font-family: 'Verdana' !important;
    }
  }

  Important part */ .modal-dialog {
    overflow-y: initial !important
  }

  .modal-body {
    height: 600px;
    overflow-y: auto;
    overflow-x: auto;
  }

  */
</style>

<app></app>
<div class="bg-back">
  <button (click)="BackDashBoard()" class="back-btn-doc-room">Back To DashBoard </button>
  <!-- <button (click)="AppointmentHistory()" class="mat-chip mat-primary mat-standard-chip mat-chip-selected"><i class="fa fa-clock-o"></i>Appointment
  History</button> -->
</div>
<div class="card">
  <div class="card-body">

    <div class="Book-Update">

    </div>

    <div>
      <!-- <h4>
        Basic Pagination
      </h4> -->
      <h4 class="title mt-3"><i class="fa fa-clock-o"></i>My Appointment History</h4>

      <label style="margin-right: 10px;" class="mat-chip mat-primary mat-standard-chip mat-chip-selected" for="Upcoming">
        <input name="Appointment" type="radio" id="Upcoming" value="Upcoming Appointment" (click)="showUpcominglist()" class="mat-chip mat-primary mat-standard-chip mat-chip-selected" checked> Upcoming Appointment</label>


      <label style="margin-right: 10px;" class="mat-chip mat-primary mat-standard-chip mat-chip-selected" for="Past">
        <input name="Appointment" type="radio" id="Past" value="Past Appointment" (change)="showPastlist()" class="mat-chip mat-primary mat-standard-chip mat-chip-selected">Past Appointment</label>

      <!-- Rohan Chaulagain: Commented as per requirement. -->
      <!-- <label style="margin-right: 10px;" class="mat-chip mat-primary mat-standard-chip mat-chip-selected" for="Paid">
        <input name="Appointment" type="radio" id="Paid" value="Paid Appointment" (change)="showPaidlist()" class="mat-chip mat-primary mat-standard-chip mat-chip-selected">Paid Appointment</label>


      <label style="margin-right: 10px;" class="mat-chip mat-primary mat-standard-chip mat-chip-selected" for="Unpaid">
        <input name="Appointment" type="radio" id="Unpaid" value="Unpaid Appointment" (change)="showUnpaidlist()" class="mat-chip mat-primary mat-standard-chip mat-chip-selected">Unpaid Appointment</label> 
      <div class="table_history_page">
        <table class="table">
        <input name="Appointment" type="radio" id="Unpaid" value="Unpaid Appointment" (change)="showUnpaidlist()" class="mat-chip mat-primary mat-standard-chip mat-chip-selected">Unpaid Appointment</label> -->
      <div class="table_history_page">
        <table class="table">
        <thead>
          <tr>
            <th scope="col">SN</th>
            <th scope="col">VisitDate</th>
            <th scope="col">VisitTime</th>
            <th scope="col">Department</th>
            <th scope="col">Doctor</th>
            <th scope="col">Booking Type</th>
            <th scope="col">Visit Type/OP Type</th>
            <th scope="col">Payment Type</th>
            <th scope="col">Payment Status</th>
            <th scope="col">Payment Medium</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of collection.data |paginate: { itemsPerPage: 5, currentPage: p }">
            <td>{{item.id}}</td>
            <td>{{item.VisitDate | date:'fullDate'}}</td>
            <td>{{item.BookingTime }}</td>
            <td>{{item.DepartmentName}}</td>
            <td>{{item.DoctorName}}</td>
            <td>{{item.BookingType}}</td>
            <td>{{item.VisitType }}</td>
            <td *ngIf="item.PaymentStatus === 'paid' || item.PaymentStatus === 'unpaid'">Chargable</td>
            <td *ngIf="item.PaymentStatus === 'free'">Free</td>
            <td>{{item.Status}}</td>
            <td *ngIf="item.PaymentMethod === 0">Free</td>
            <td *ngIf="item.PaymentMethod === 1">Esewa</td>
            <td *ngIf="item.PaymentMethod === 2">Khalti</td>
            <td *ngIf="item.PaymentMethod === 3">Nabil Card</td>
            <td *ngIf="item.PaymentMethod === 4">Connect IPS</td>
            <td>{{item.PaymentStatus}}</td>

            <!-- <td *ngIf="item.Status =='completed'"> <button (click)="ViewNotes(item.VisitId)" class="btn btn-primary btn-sm">View Notes</button></td> -->
            <!-- <td *ngIf="item.Status !='completed'"> <button mat-raised-button color="warn" class="btn btn-danger btn-sm" > Notes Unavailable</button></td> -->
            <!-- <td *ngIf="item.Status !='completed'"> <button class="btn btn-danger btn-sm"> Notes
                Unavailable</button></td> -->

            <td>
              <!-- <button *ngIf="Prescription  && item.Status != 'deletedByAdmin'" type="button" data-toggle="modal" data-target="#target" (click)="ViewPrescription(item.VisitId)" class="btn btn-sm btn-primary">
                View Prescription
              </button>
              <button *ngIf="Report  && item.Status != 'deletedByAdmin'" type="button" data-toggle="modal" data-target="#target" (click)="ViewReport(item.VisitId)" class="btn btn-sm btn-primary">
                View Reports
              </button>
              <button *ngIf="OPSummary && item.ShowViewOPSummary  && item.Status != 'deletedByAdmin'" type="button" data-toggle="modal" data-target="#target" (click)="ViewOPSummary(item)" class="btn btn-sm btn-primary">
                OP Summary
              </button><br><br>

              <button *ngIf="Payment  && item.Status != 'deletedByAdmin'" type="button" data-toggle="modal" data-target="#target" (click)="Pay(item)" class="btn btn-sm btn-primary">
                Payment
              </button>
              <button *ngIf="item.Status != 'cancelled' && item.Status !='completed' && item.Status != 'deletedByAdmin' " type="button" data-toggle="modal" data-target="#target" (click)="openDialog(item)" class="btn btn-sm btn-primary">
                Cancel
              </button>
              <button *ngIf="item.Status == 'deletedByAdmin' || item.Status =='cancelled' " type="button" (click)="RemoveCompletely(item.VisitId)" class="btn btn-sm button_Remove">
                Remove
              </button>
              <button *ngIf="Reschedule" type="button" data-toggle="modal" data-target="#target" (click)="RescheduleAppointment(item)" class="btn btn-sm btn-primary">
                Re-schedule
              </button> -->
              <div *ngIf="item.Status === 'completed'">
                <button data-toggle="modal" data-target="#target" (click)="ViewPrescription(item.VisitId)" class="btn btn-sm btn-primary">View Prescription</button>
                <button *ngIf="Report" type="button" data-toggle="modal" data-target="#target" (click)="ViewReport(item.VisitId)" class="btn btn-sm btn-primary">View Reports</button>
                <button type="button" data-toggle="modal" data-target="#target" (click)="ViewOPSummary(item)" class="btn btn-sm btn-primary">OP Summary</button>
              </div>
              <div *ngIf="item.Status === 'missed'">
                <button data-target="#target" (click)="RescheduleAppointment(item)" class="btn btn-sm btn-primary">Reschedule</button>
                <button type="button" data-toggle="modal" data-target="#target" (click)="openDialog(item)" class="btn btn-sm btn-danger">Cancel</button>
              </div>
              <div *ngIf="Payment && item.PaymentStatus !== 'free' && item.PaymentStatus !=='paid'">
                <button  type="button" data-toggle="modal" data-target="#target" (click)="Pay(item)" class="btn btn-sm btn-primary">Payment</button>
                <button data-target="#target" (click)="RescheduleAppointment(item)" class="btn btn-sm btn-primary">Reschedule</button>
                <button type="button" (click)="openDialog(item)" class="btn btn-sm btn-danger">Cancel</button>
              </div>
             </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="!allAppointments">
          <div class="not_available_message"><i class="fa fa-exclamation-circle text-danger fontawesome-icon"></i>
            Sorry! No appointments found!
          </div>
        </div>
    </div>
      <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>

    </div>

  </div>
</div>

<div class="modal" id="myModal" [style.display]="showViewNotes ? 'block' : 'none'">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header text-center">
        <!-- <h4 class="modal-title w-100 font-weight-bold">Doctor Advice</h4> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <div class="row modal-header text-center" *ngFor="let data of viewList" style="border: none; margin-bottom: 35px">
          <table align="center">
            <tr><h5 style="font-weight: 600; margin-bottom: 5px">Doctor Name : {{data.DoctorName}}</h5></tr>
            <tr><h6 style="font-weight: 600; margin-bottom:5px">Hospital Name : {{data.HospitalName }}</h6></tr>
            <tr><p style="font-weight: 600; margin-bottom: 5px;">Visit Date: {{data.VisitEndTime | date:'medium'}}</p></tr>
            <tr><p style="font-weight: 600;">NMC Number: {{data.NMC }}</p></tr>

          </table>
        </div> -->

        <div *ngFor="let row of viewList" class="wait-section" style="height: auto; flex-direction: column;">
          <div class="w-100" id="Report">
            <div class="img-d" style="margin: auto; text-align:center; margin-bottom: 15px;">
              <img src="assets/img/hospital.png" height="50">
            </div>

            <div class="row modal-header text-center" *ngFor="let data of viewList" style="border: none; margin-bottom: 20px">
              <table align="center" style="width: 100%;">
                <tr>
                  <td align="center" style="font-size: 20px; padding: 0; font-weight: 600;">Doctor Name : {{data.DoctorName}}</td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20px;  padding: 0;font-weight: 600;">Patient Name : {{data.PatientName}}</td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20px; padding: 0;font-weight: 600;">Hospital Name : {{data.HospitalName}}</td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 15px; padding: 0;font-weight: 600;">Visit Date: {{data.VisitEndTime | date:'medium'}}</td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 15px; padding: 0;font-weight: 600;">NMC Number: {{data.NMC }}</td>
                </tr>

              </table>
            </div>
            <h4 class="modal-title w-100 font-weight-bold" style="text-align: center; margin-bottom: 20px; font-size: 17px;">Doctor Advice</h4>
            <!--<div class="card report-card">
              <div class="card-title">
                <span class="">Medical Status</span>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Fever :
                    {{row.Fever| yesNoPipe}}
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Cough :
                    {{row.Cough| yesNoPipe}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>BreathingDifficulty :
                    {{row.BreathingDifficulty| yesNoPipe}}
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Tiredness :
                    {{row.Tiredness| yesNoPipe}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Sore Throat :
                    {{row.SoreThroat| yesNoPipe}}
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Bodyache :
                    {{row.Bodyache| yesNoPipe}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>ChestPain :
                    {{row.ChestPain| yesNoPipe}}
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Diarrhea :
                    {{row.Diarrhea| yesNoPipe}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Any Other Symptoms :
                    {{row.AnyOtherSymptoms}}
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Heart Disease :
                    {{row.HeartDisease| yesNoPipe}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>High Blood Pressure :
                    {{row.HighBloodPressure| yesNoPipe}}
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Diabetes :
                    {{row.Diabetes| yesNoPipe}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Copd/Asthma :
                    {{row.Copd| yesNoPipe}}
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Transplant :
                    {{row.Transplant| yesNoPipe}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Recent Travel :
                    {{row.RecentTravel| yesNoPipe}}
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Cancer :
                    {{row.Cancer| yesNoPipe}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                    <i class="fa fa-info-circle"></i>Exposure to Covid
                    patients : {{row.Exposure| yesNoPipe}}
                  </div>
                </div>
              </div>
            </div>-->
            <div class="notes-prescription">
              <fieldset style="margin-bottom: 25px;">
                <legend style="font-weight: 600;">Other Pertinent Information:</legend>
                <p>{{row.OtherPertientInformation}}</p>
              </fieldset>
              <fieldset style="margin-bottom: 25px;">
                <legend style="font-weight: 600;">Treatment Advice:</legend>
                <p>{{row.TreatmentAdvice}}</p>
              </fieldset>
              <fieldset style="margin-bottom: 25px;">
                <legend style="font-weight: 600;">Medication given by doctor:</legend>
                <p>{{row.Medication}}</p>
              </fieldset>
              <fieldset style="margin-bottom: 25px;">
                <legend style="font-weight: 600;">Follow up:</legend>
                <p>{{row.FollowUp}}</p>
              </fieldset>
            </div>
            <!-- <div class="card report-card">
              <div class="card-title">
                <span class="">Other Pertinent Information</span>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">{{row.OtherPertientInformation}}</div>

                </div>
              </div>
            </div> -->
            <!-- <div class="card report-card">
              <div class="card-title">
                <span class="">Treatment Advice</span>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">{{row.TreatmentAdvice}}</div>

                </div>
              </div>
            </div>
            <div class="card report-card">
              <div class="card-title">
                <span class="">Medication given by doctor</span>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">{{row.Medication}}</div>

                </div>
              </div>
            </div>
            <div class="card report-card">
              <div class="card-title">
                <span class="">Follow up</span>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">{{row.FollowUp}}</div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="print-action">
            <input type="button" value="Print Report" (click)="Print()">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showPrescription" class="modal fade " id="target" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Prescription file from Doctor</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="background-color:floralwhite;">
        <div class="info-heading">
          <img *ngIf="Filetype == 'image'" [src]="path" alt="info-image" class="patient_medical_report_image"
          />
            <a *ngIf="Filetype == 'image'" 
            [href]="path" 
            target="_blank" 
            class="btn btn-sm btn-primary col-md-2" 
            style="float: right;">
          
            <i class="fa fa-download"></i>
            Download
          </a>
          <iframe *ngIf="Filetype == 'pdf'"
            [src]="safePath"
            style="display: block; width: 100%; height: 80vh !important;">
          </iframe>

          <div *ngIf="Filetype == 'docfile'">
            <a href="{{path}}" class="btn btn-primary btn-lg active" role="button">Click to Open Doc File</a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showReport" class="modal fade " id="target" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Medical Record File</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mb-lg-5" style="background-color:floralwhite;">
        <div class="info-heading">
          <img *ngIf="Filetype == 'image'" [src]="path" alt="info-image" class="patient_medical_report_image"
          />
            <a *ngIf="Filetype == 'image'" 
            [href]="path" 
            target="_blank" 
            class="btn btn-sm btn-primary col-md-2" 
            style="float: right;">
          
            <i class="fa fa-download"></i>
            Download
          </a>
          <iframe *ngIf="Filetype == 'pdf'"
            [src]="path"
            style="display: block; width: 100%; height: 80vh !important;">
          </iframe>
          <div *ngIf="Filetype == 'docfile'">
            <a href="{{path}}" class="btn btn-primary btn-lg active" role="button">Click to Open Doc File</a>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" data-dismiss="modal">Close</button>
      </div> -->
    </div>
  </div>
</div>
<footer></footer>