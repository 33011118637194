<app></app>
<button [routerLink]="['/DocDashboard']" class="back-btn-doc-room">Back <span>To DashBoard</span> </button>

<div *ngIf="dailyvisit" class="grid-section">
  <h4 class="title">
    <i class="fa fa-list"></i>Daily Visit List
  </h4>
  <div class="col-md-12 alert alert-default">
    <div class="row d-flex align-items-center">
      <!--<form [formGroup]="visitObj.PatientValidator">-->
      <label class="control-label col-md-2">Filter By Date:</label>
      <div class="col-md-4">
        <input (change)="ChangeDate()" type="date" name="date" [(ngModel)]="filterdate" required class="m-0" />
      </div>
      <label class="control-label col-md-2 ml-5"> Appointment Type:</label>
      <div class="col-md-3">
        <mat-select class="form-control pt-1" [(ngModel)]="selAppointmentType" placeholder="Select Appointment Type" display-property-name="FullName" value-property-name=null (selectionChange)="appointmentTypeSearch($event)">
          <mat-option value="All" selected>All</mat-option>
          <mat-option value="Telemedicine">Telemedicine</mat-option>
          <mat-option value="Online Appointment">Online Appointment</mat-option>
        </mat-select>
      </div>
    </div>
  </div>

  <div class="col-md-12 table-responsive">
    <table class="table overflow- table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">Appointment Type</th>
          <th scope="col">Visit Date</th>
          <th scope="col">Patient Name</th>
          <th scope="col">Age</th>
          <th scope="col">Gender</th>
          <th scope="col">Contact</th>
          <th scope="col">Treatment Advice</th>
          <th scope="col">Medication</th>
          <th scope="col">FollowUp(Days)</th>
          <th scope="col">Visit Status</th>
          <th scope="col">Action</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of newFilteredDailyVisitList |paginate: { itemsPerPage: 3, currentPage: p  } ; let i=index;">
          <td>{{i+1}}</td>
          <td>{{data.AppointmentType}}</td>
          <td>{{data.VisitDate}}</td>
          <td>{{data.PatientName }}</td>
          <td>{{data.Age}}</td>
          <td>{{data.Gender }}</td>
          <td>{{data.ContactNumber }}</td>
          <td>{{data.TreatmentAdvice}}</td>
          <td>{{data.Medication }}</td>
          <td>{{data.FollowUp}}</td>
          <td style="text-transform: capitalize;">{{data.Status}}</td>
          <td>
            <input type="file" #file accept=".jpg,.JPG,.png,.PNG,.doc,.DOC,.docx,.DOCX,.pdf,.PDF" placeholder="Choose file" (change)="upload(file.files);function(data.VisitId,data.PatientId)" name="fileName" [(ngModel)]="prescriptionFile.FilePath" [ngModelOptions]="{standalone: true}" style="display:none;">
            <button type="button" class="btn btn-primary d-inline" (click)="file.click()" style="font-size: 14px;"><i class="fa fa-upload"></i>Upload Prescription</button>
            <div class="col-md-10 d-inline" *ngIf=" this.prescriptionFile.VisitId == data.VisitId">
              <span class="upload" *ngIf="progress > 0" style="font-size: 11px;">
                {{progress}}%
              </span>
              <span class="upload" *ngIf="message" style="font-size: 11px;">
                {{message}}
              </span>
            </div>
            <button type="button" *ngIf="data.ShowViewOPSummary" (click)="ViewOPSummary(data)" class="btn btn-primary d-inline" style="font-size: 14px;"><i class="fa fa-eye"></i>View</button>
            <button type="button" *ngIf="!data.ShowViewOPSummary" (click)="AddOPSummary(data)" class="btn btn-primary d-inline" style="font-size: 14px;"><i class="fa fa-list"></i>Add OP Summary</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!isBookingAvailable">
      <p style="text-align: center; margin-top: 10rem; margin-bottom: 1rem; font-size: 2rem; color: rgb(51, 47, 47);">
        <i class="fa fa-exclamation-triangle text-center text-danger" aria-hidden="true"></i> Sorry! No Visit List Found.
      </p>
    </div>
  </div>

  <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>
</div>

<footer></footer>