import { Component, ChangeDetectorRef } from '@angular/core'
import { Patient } from '../../models/patient/patient.model';
import { PatientService } from '../../services/patient/patient.service';
import { AlertService } from '../../services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { HospitalService } from '../../services/hospital/hospital.service';
import * as moment from 'moment/moment';
import { Global } from '../../app.global';
import { PatientFamily } from 'src/app/models/patient/Pat-family.model';
@Component({
  selector: 'patient-update-profile',
  templateUrl: './app.patient_updateprofile.html'
})
export class PatientUpdateProfileComponent {
  public updatePat: boolean;
  public patInfo: Patient = new Patient();
  public today = new Date();
  public patList: Array<Patient> = new Array<Patient>();
  loading = false;
  public pat: any;
  public token: any;
  public hospitalId: any;
  patientUpdateForm: FormGroup;
  bsValue = new Date();
  isPatEditor = false;
  patId: any;
  patName: string;
  public hospitalIdentifier: string;
  public changesSavedCallback: () => void;
  public changesCancelledCallback: () => void;
  public isPersonalDetails: boolean = false;
  public addFamilyDetails: boolean = false;
  public relation: any;
  public familyList: PatientFamily = new PatientFamily();
  public familyInfo: Array<PatientFamily> = new Array<PatientFamily>();
  public collection = { count: 0, data: [] };
  public familyDetails: any;
  Relationship = [
    { id: 1, name: "Father" },
    { id: 2, name: "Mother" },
    { id: 3, name: "Brother" },
    { id: 4, name: "Sister" },
    { id: 5, name: "Uncle" },
    { id: 6, name: "Aunty" },
    { id: 7, name: "Friend" },
    { id: 8, name: "Grandfather" },
    { id: 9, name: "Grandmother" },
    { id: 10, name: "Other" }
  ];

  BloodGroup = [
    { id: 1, name: "A+" },
    { id: 2, name: "A-" },
    { id: 3, name: "B+" },
    { id: 4, name: "B-" },
    { id: 5, name: "AB+" },
    { id: 6, name: "AB-" },
    { id: 7, name: "O+" },
    { id: 8, name: "O-" }
  ];
  public updateFamilyDetails: boolean = false;
  familyListForm: FormGroup;
  constructor(public patservice: PatientService, public alertService: AlertService, private route: ActivatedRoute,
    public hospitalService: HospitalService,
    public routing: Router, private authenticationService: AuthenticationService, private datePipe: DatePipe,
    private formBuilder: FormBuilder, private notifyService: NotificationService, public global: Global, public changeDetector: ChangeDetectorRef) {
    this.token = this.authenticationService.currentUserValue;
    if (this.token.UserType !== "Admin") {
      this.GetPatient();
      this.hospitalId = this.route.snapshot.queryParamMap.get('id');
      this.patInfo.DateOfBirth = moment().format('MM-DD-YYYY');
    }
    this.showPersonalDetails();
  }

  ngOnInit() {
    this.familyListForm = this.formBuilder.group({
      Relationship: ['', [Validators.required]],
      ContactNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      MiddleName: [''],
      DOB: ['', [Validators.required]],
      Email: [''],
      Gender: ['', [Validators.required]],
      BloodGroup: [''],
      Address: ['']
    });
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.changeDetector.detectChanges();
  }

  GetPatientList() {
    this.patservice.getPatientList().subscribe(res => {
      this.patList = res;
    },
      error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }
  GetPatient() {

    //var id = this.token.PatientIdentifier;
    if (this.token.UserType !== "Admin") {
      this.patId = this.token.PatientIdentifier;
    }

    this.patservice.getPatient(this.patId,this.token.UserType).subscribe(res => {
      this.patInfo = res;
      // this.patInfo.DateOfBirth = this.datePipe.transform(res.DateOfBirth, 'yyyy-MM-dd');
      //this.bsValue =this.patInfo.DateOfBirth;
      if (this.patInfo.DateOfBirth != null) {
        this.patInfo.DateOfBirth = moment(res.DateOfBirth).format('MM-DD-YYYY');
      }
      else {
        this.patInfo.DateOfBirth = moment().format('MM-DD-YYYY');
      }

    },
      error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }

  UpdatePatProfile(form: NgForm) {

    var validation = this.checkInputValidity();
    if (validation.failed) {
      this.notifyService.showError(validation.errorMessages, "Invalid Input");
      return;
    }

    if (this.patInfo.ContactNumber == "") {
      this.notifyService.showError("", "Phone Number is required");
      return;
    }
    if (form.invalid) {
      this.notifyService.showError("", "Please fill up the required field");
      return;
    }
    const momentDate = new Date(this.patInfo.DateOfBirth);
    if (this.token.UserType === "Admin") {
      this.patInfo.AdminId = this.token.unique_name;
    }
    else {
      this.patInfo.AdminId = "";
    }

    this.patInfo.DateOfBirth = moment(momentDate).format("YYYY/MM/DD");// converted gmtdate  to moment date
    //this.patInfo.IdentityUserId =this.token.unique_name;
    this.patInfo.HospitalIdentifier = this.hospitalIdentifier;
    this.patservice.updatePatient(this.patInfo).subscribe(data => {
      this.alertService.success('Updated successful', true);
      this.patInfo.PatientId = this.token.PatientIdentifier;;
      this.updatePat = false;
      if (this.token.UserType === "Admin") {
        this.routing.navigate(['/adminpatlist']);
        if (this.changesSavedCallback) {
          this.changesSavedCallback();
        }
      }
      else {
        this.routing.navigate(['/PatDashboard'], { queryParams: { id: this.hospitalId } });
      }
    },
      error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }
  updatepatient() {


    var validation = this.checkInputValidity();
    if (validation.failed) {
      this.notifyService.showError(validation.errorMessages, "Invalid Input");
      return;
    }

    const momentDate = new Date(this.patInfo.DateOfBirth);
    if (this.token.UserType === "Admin") {
      this.patInfo.AdminId = this.token.unique_name;
    }


    this.patInfo.DateOfBirth = moment(momentDate).format("YYYY/MM/DD");// converted gmtdate  to moment date
    //this.patInfo.IdentityUserId =this.token.unique_name;
    this.patInfo.HospitalIdentifier = this.hospitalIdentifier;

    this.patservice.updatePatient(this.patInfo).subscribe(data => {
      this.alertService.success('Updated successful', true);
      if (this.token.UserType === "Admin") {
        this.routing.navigate(['/adminpatlist']);
        if (this.changesSavedCallback) {
          this.changesSavedCallback();
        }
      }
      else {
        this.routing.navigate(['/PatDashboard'], { queryParams: { id: this.hospitalId } });
      }
    },
      error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }


  private checkInputValidity() {

    var validation = { failed: false, errorMessages: [] };

    var namePattern = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
    if (namePattern.test(this.patInfo.FirstName) == false) {
      validation.errorMessages.push("\nFirstName is not valid");
      validation.failed = true;
    }

    if (namePattern.test(this.patInfo.LastName) == false) {
      validation.errorMessages.push("\nLastName is not valid");
      validation.failed = true;
    }

    if (this.patInfo.MiddleName) {
      if (namePattern.test(this.patInfo.MiddleName) == false) {
        validation.errorMessages.push("\nMiddleName is not valid");
        validation.failed = true;
      }
    }

    if (this.patInfo.MailingAddress) {
      var emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (emailPattern.test(this.patInfo.MailingAddress) == false) {
        validation.errorMessages.push("\nEmail is not valid");
        validation.failed = true;
      }
    }
    return validation;
  }

  public BackDashBoard() {
    this.routing.navigate(['/PatDashboard'], { queryParams: { id: this.hospitalId } });
  }
  editAdminPatient(pat: Patient, isPat: boolean, hospital: string) {
    this.isPatEditor = isPat;
    this.patId = pat.PatientId;
    this.patName = pat.FullName;
    this.hospitalIdentifier = hospital;
    this.GetPatient();

    return this.patId;
  }
  cancel() {
    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  showPersonalDetails() {
    this.isPersonalDetails = true;
  }

  showFamilyList() {
    this.isPersonalDetails = false;
    this.getFamilyList();
  }

  AddFamilyDetails() {
    this.addFamilyDetails = true;
    this.updateFamilyDetails = false;
  }

  cleardata() {
    this.familyList = new PatientFamily();
    this.addFamilyDetails = false;
    this.getFamilyList();
  }

  AddFamilyList(form) {

    if (form.invalid) {
      if (form.controls.Relationship.invalid) {
        this.notifyService.showError("", "Relationship is required");
      }
      if (form.controls.ContactNumber.invalid) {
        this.notifyService.showError("", "Phone number is required and should be 10 digits");
      }
      if (form.controls.FirstName.invalid) {
        this.notifyService.showError("", "First Name is required");
      }
      if (form.controls.LastName.invalid) {
        this.notifyService.showError("", "Last Name is required");
      }
      if (form.controls.DOB.invalid) {
        this.notifyService.showError("", "Date of Birth is required");
      }
      if (form.controls.Gender.invalid) {
        this.notifyService.showError("", "Gender is required");
      }
      return;
    }
    this.familyList.PatientId = this.token.PatientIdentifier;
    const momentDate = new Date(this.familyList.DateOfBirth);
    this.familyList.DateOfBirth = moment(momentDate).format("YYYY/MM/DD");// converted gmtdate  to moment date
    this.patservice.addFamilyList(this.familyList).subscribe(res => this.Success(res),
      res => this.ErrorAdd(res));
  }
  Success(res) {
    this.familyList = new PatientFamily();
    this.notifyService.showSuccess('', 'Information Added Successfully!');
    this.addFamilyDetails = false;
    this.getFamilyList();

  }
  ErrorAdd(res) {
    this.notifyService.showError("Error", "Internal Error")
  }

  getFamilyList() {
    this.patservice.getFamilyList(this.token.PatientIdentifier).subscribe(res => this.SuccessGetFamilyList(res),
      res => this.Error(res));
  }

  SuccessGetFamilyList(res) {

    if (res != null) {
      this.familyInfo = res;
      this.collection = { count: this.familyInfo.length, data: [] }
      for (var i = 0; i < this.collection.count; i++) {
        this.collection.data.push(
          {
            id: i + 1,
            FamilyMemberId: this.familyInfo[i].FamilyMemberId,
            PatientId: this.familyInfo[i].PatientId,
            Relationship: this.familyInfo[i].Relationship,
            FirstName: this.familyInfo[i].FirstName,
            MiddleName: this.familyInfo[i].MiddleName,
            LastName: this.familyInfo[i].LastName,
            FullName: `${this.familyInfo[i].FirstName} ${this.familyInfo[i].MiddleName ? this.familyInfo[i].MiddleName : ""} ${this.familyInfo[i].LastName}`,
            Age: moment().diff(this.familyInfo[i].DateOfBirth, 'years'),
            Gender: this.familyInfo[i].Gender,
            DateOfBirth: moment(this.familyInfo[i].DateOfBirth).format('L'),
            ContactNumber: this.familyInfo[i].ContactNumber,
            Email: this.familyInfo[i].Email,
            BloodGroup: this.familyInfo[i].BloodGroup,
            Address: this.familyInfo[i].Address
          }
        );
      }
      this.familyDetails = this.collection.data;
    }
  }
  Error(res) {
    this.notifyService.showError("Error", "Internal Error")
  }

  UpdateFamily(index) {
    this.addFamilyDetails = true;
    this.familyList = this.familyDetails[index];
    this.updateFamilyDetails = true;
  }

  UpdateFamilyList(form) {
    if (form.invalid) {
      if (form.controls.Relationship.invalid) {
        this.notifyService.showError("", "Relationship is required");
      }
      if (form.controls.ContactNumber.invalid) {
        this.notifyService.showError("", "Phone number is required and should be 10 digits");
      }
      if (form.controls.FirstName.invalid) {
        this.notifyService.showError("", "First Name is required");
      }
      if (form.controls.LastName.invalid) {
        this.notifyService.showError("", "Last Name is required");
      }
      if (form.controls.DOB.invalid) {
        this.notifyService.showError("", "Date of Birth is required");
      }
      if (form.controls.Gender.invalid) {
        this.notifyService.showError("", "Gender is required");
      }
      return;
    }
    this.familyList.PatientId = this.token.PatientIdentifier;
    const momentDate = new Date(this.familyList.DateOfBirth);
    this.familyList.DateOfBirth = moment(momentDate).format("YYYY/MM/DD");// converted gmtdate  to moment date
    this.patservice.updateFamilyList(this.familyList).subscribe(res => this.SuccessUpdate(res),
      res => this.ErrorUpdate(res));
  }
  SuccessUpdate(res) {
    this.familyList = new PatientFamily();
    this.notifyService.showSuccess('', 'Information Updated Successfully!');
    this.addFamilyDetails = false;
    this.updateFamilyDetails = false;
    this.getFamilyList();
  }
  ErrorUpdate(res) {
    this.notifyService.showError("Error", "Internal Error")
  }

  RemoveFamily(FamilyMemberId) {
    this.patservice.deleteFamilyList(FamilyMemberId).subscribe(res => this.SuccessDelete(res),
      res => this.ErrorDelete(res));

  }
  SuccessDelete(res) {
    this.notifyService.showSuccess("Success", "Deleted Successfully");
    this.getFamilyList();
  }
  ErrorDelete(res) {
    this.notifyService.showError("Error", "Internal Error");
  }

  generateAge() {
    let dobYear = String(moment(this.patInfo.DateOfBirth).format("YYYY"));
    if (dobYear > '1900') {
      this.patInfo.Age = String(moment().diff(moment(this.patInfo.DateOfBirth), 'years'));
    }
  }

  generateDOB() {
    //var curDate = new Date();
    this.patInfo.DateOfBirth = String(moment(this.today).subtract(this.patInfo.Age, 'year').format("MM/DD/YYYY"));
  }
}
