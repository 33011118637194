import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { EndpointFactory } from "../endpoint-factory.service";

@Injectable({ providedIn: "root" })
export class OrderEndpoint extends EndpointFactory {

  constructor(public http: HttpClient, injector: Injector) {
    super(http, injector);
  }

  getOrders<T>(pageNumber: number): Observable<T> {
    let endpointUrl = `/api/Order`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getOrders(pageNumber)));
      }));
  }
}
