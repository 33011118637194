import { Component, Input, Inject } from '@angular/core';
import { Global } from '../app.global';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'payment-message',
  templateUrl: './app.paymentmessage.html'
})


export class PaymentMessageComponent {
  public message: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data : any) {

  }
}
