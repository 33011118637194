import { Component, OnInit } from '@angular/core'
import { Global } from '../../app.global';
import { Router, ActivatedRoute } from '@angular/router';
import { Patient } from '../../models/patient/patient.model';
import { PatientService } from '../../services/patient/patient.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../services/alert.service';
import { HospitalService } from '../../services/hospital/hospital.service';
import { DoctorService } from '../../services/doctor/doctor.service';
import { NotificationService } from '../../services/notification.service';
import * as moment from 'moment/moment';
import { ConsentFormComponent } from '../../ConsentForm/app.consentformcomponent';
import { MatDialog } from '@angular/material/dialog';
import { ChatService } from '../../services/ChatNotification/chat-notification.service';
import { Scheduling } from 'src/app/models/scheduling/scheduling.model';
import { Visit } from 'src/app/models/visit/visit.model';
import { SchedulingService } from 'src/app/services/scheduling/scheduling.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timer } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { Prescription } from '../../app.model';

@Component({
  templateUrl: "./app.patient_dashboard.html",
})
export class PatientDashboardComponent implements OnInit {
  public updatePat: boolean;
  public showViewNotes: boolean = false;
  public prob: boolean = false;
  filekind: any;
  fileExtension: any;
  Filetype: any;
  p: number = 1;
  q: number = 1;
  r: number = 1;
  s: number = 1;
  t: number = 1;
  nearestVisit: any;
  public showPrescription: boolean = false;
  public showNearestVisit: boolean = false;
  public visitId: string;
  public patInfo: Patient = new Patient();
  public token: any;
  nepaliDate = moment();
  public allpatBookList: any;
  public viewList: any;
  path: any;
  public noteList: any;
  filterPatList: any;
  patientFile: any;
  prescriptions: Array<Prescription> = [];
  public patBookList: any;
  public patHistoryList: any;
  public allVisitsList: any;
  public hospitalId: any;
  public doctordId: any;
  allcollection = { count: 60, data: [] };
  collection = { count: 60, data: [] };
  historycollection = { count: 0, data: [] };
  redUrl: string;
  public PatientName: any;
  public patlist: any;

  followupVisit: any;
  public showFollowupVisit: boolean = false;
  public showfollowup: boolean = false;
  public followupinfo: Visit = new Visit();
  public deptList: any[];
  public selDepartment: any = "";
  public selDoctor: any = "";
  public doctorList: Array<any> = new Array<any>();
  public doctorId: any;
  filterDocList: any;

  SchedulingList: Array<Scheduling> = new Array<Scheduling>();
  filteredHospitalDoctorMapping: Array<any> = new Array<any>();
  tempArray: Array<any> = new Array<any>();
  filteredSchedulingList: Array<any> = new Array<any>();
  HospitalDoctorMapping: Array<any> = new Array<any>();
  ScheduleList: Array<any> = new Array<any>();
  public showUnavailableMsg: boolean = false;
  public ScheduleTimeDetail: boolean = true;
  scheduleIntervalList: Array<any> = new Array<any>();
  visitObj: Visit = new Visit();
  public selSchedule: any;
  scheduleIntervalId: any;
  followupForm: FormGroup;
  Age: any;
  Gender: any;
  LastApptDepartmentName: any;
  LastApptDoctorName: any;
  LastApptVisitDate: any;

  todayDate = moment();

  public showVisitTypePopup: boolean = false;
  timerpat = timer(3000, 10000);
  public IsDocOnline: any = "offline";
  public VisitType: string;
  public doctor: Array<any> = new Array<any>();
  public responseData: any;

  constructor(
    public global: Global,
    public routing: Router,
    public docService: DoctorService,
    public dialog: MatDialog,
    public patservice: PatientService,
    public hospitalService: HospitalService,
    public authservice: AuthenticationService,
    public alertService: AlertService,
    private route: ActivatedRoute,
    private notifyService: NotificationService,
    public notificationService: ChatService,
    public schedulingService: SchedulingService,
    private formBuilder: FormBuilder
  ) {
    this.token = this.authservice.currentUserValue;
    this.patservice.PatientId = this.token.PatientIdentifier;
    this.hospitalId = this.route.snapshot.queryParamMap.get("id");
    this.doctordId = this.route.snapshot.queryParamMap.get("id1");
    this.redUrl = this.route.snapshot.queryParamMap.get("redirectUrl");
    this.VisitType = this.route.snapshot.queryParamMap.get("VisitType");
  }

  ngOnInit() {
    this.notificationService.Connect();
    this.notificationService.EventDocStatus.subscribe((docStatus) => {
      this.IsDocOnline = docStatus;
    });
    this.getPatientName();
    this.GetAllVisits();
    this.ShowAllBookList();
    this.ShowBookList();
    this.AppointmentHistory();
    this.GetMissedAppointment();

    this.followupForm = this.formBuilder.group({
      FirstName: [""],
      MiddleName: [""],
      LastName: [""],
      Age: [""],
      Gender: [""],
      LastAppointmentDepartment: [""],
      LastAppointmentDoctor: [""],
      LastAppointmentDate: [""],
      Department: ["", [Validators.required]],
      Doctor: ["", [Validators.required]],
      FollowUpdate: ["", [Validators.required]],
      Time: ["", [Validators.required]],
    });
  }

  public getPatientName() {
    //var id = this.token.PatientIdentifier;
    this.patservice
      .getPatient(this.token.PatientIdentifier, this.token.UserType)
      .subscribe(
        (res) => {
          this.patInfo = res;
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }
  public BookAppointment() {
    this.updatePat = true;
    //this.patDash = true;
    this.showVisitTypePopup = true;
    //this.routing.navigate(["/HospitalList"]);
  }
  public History() {
    this.updatePat = true;
    this.routing.navigate(["/PatDashboard/PatHistory"], {
      queryParams: { id: this.hospitalId },
    });
  }

  public payment() {
    this.updatePat = true;
    this.routing.navigate(["/PatDashboard/PaymentSelection"], {
      queryParams: { id: this.hospitalId },
    });
  }
  public Update() {
    this.updatePat = true;
    this.routing.navigate(["/PatDashboard/PatUpdateProfile"], {
      queryParams: { id: this.hospitalId },
    });
  }
  public HospitalDashBoard() {
    this.global.DepartmentId = null;
    this.hospitalService.HospitalId = null;
    this.hospitalService.DepartmentId = null;
    this.global.DoctorId = null;
    this.docService.DoctorId = null;
    this.routing.navigate(["/HospitalList"]);
  }
  public UpdateProfile() {
    this.routing.navigate(["/PatUpdateProfile"], {
      queryParams: { id: this.hospitalId },
    });
  }

  public PatientHistory() {
    this.routing.navigate(["/PatHistory"], {
      queryParams: { id: this.hospitalId },
    });
  }
  public BookingList() {
    this.routing.navigate(["/PatBookList"]);
  }
  public PaidBookingList() {
    this.routing.navigate(["/PatPaidBookingList"]);
  }
  public GetAllVisits() {
    if (this.redUrl === null) {
      this.patservice
        .getPatientBookingList(this.token.PatientIdentifier)
        .subscribe(
          (res: any) => {
            if (res && res.IsSuccess && res.Results) {
              this.allVisitsList = res.Results;
              for (var i = 0; i < this.allVisitsList.length; i++) {
                if (
                  (this.allVisitsList[i].Status === "ongoing" ||
                    this.allVisitsList[i].Status === "initiated") &&
                  this.allVisitsList[i].IsActive === true
                ) {
                  this.patservice
                    .changeStatus(this.allVisitsList[i].VisitId)
                    .subscribe(
                      (res) => this.SuccesschangeStatus(res),
                      (res) => this.Error(res)
                    );
                }
              }
            } else {
              this.alertService.error(res.ResponseMessage);
            }
          },
          (error) => {
            this.alertService.error(error);
            // this.loading = false;
          }
        );
    } else if (this.redUrl === "PatientUrl") {
      this.patservice
        .getPatientBookingList(this.token.PatientIdentifier)
        .subscribe(
          (res: any) => {
            if(res && res.IsSuccess && res.Results){
            this.allVisitsList = res.Results;
            for (var i = 0; i < this.allVisitsList.length; i++) {
              if (
                (this.allVisitsList[i].Status === "ongoing" ||
                  this.allVisitsList[i].Status === "initiated") &&
                this.allVisitsList[i].IsActive === true
              ) {
                this.patservice
                  .changeStatus(this.allVisitsList[i].VisitId)
                  .subscribe(
                    (res) => this.SuccesschangeStatus(res),
                    (res) => this.Error(res)
                  );
              }
            }
          }else{
            this.alertService.error(res.ResponseMessage);
          }
          },
          (error) => {
            this.alertService.error(error);
            // this.loading = false;
          }
        );
    } else if (this.redUrl === undefined) {
      this.patservice
        .getPatientBookingList(this.token.PatientIdentifier)
        .subscribe(
          (res: any) => {
            this.allVisitsList = res.Results;
            for (var i = 0; i < this.allVisitsList.length; i++) {
              if (
                (this.allVisitsList[i].Status === "ongoing" ||
                  this.allVisitsList[i].Status === "initiated") &&
                this.allVisitsList[i].IsActive === true
              ) {
                this.patservice
                  .changeStatus(this.allVisitsList[i].VisitId)
                  .subscribe(
                    (res) => this.SuccesschangeStatus(res),
                    (res) => this.Error(res)
                  );
              }
            }
          },
          (error) => {
            this.alertService.error(error);
            // this.loading = false;
          }
        );
    } else {
      return;
    }
  }
  SuccesschangeStatus(res) {
    // this.notifyService.showInfo("Status Changed To Initiated!", "Success")
  }

  // Slick Slider this is added new

  slides = [{ img: "./assets/img/Slider11.jpg" }];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    cssEase: "linear",
    draggable: false,
    autoplaySpeed: 5000,
    arrows: false,
    dots: false,
  };
  // End SLick slider
  public ShowAllBookList() {
    this.patservice
      .getPatientBookingList(this.token.PatientIdentifier)
      .subscribe(
        (res: any) => {
          this.allpatBookList = res.Results;

          for (var i = 0; i < this.allpatBookList.length; i++) {
            var currentDateTime = this.todayDate
              .tz("Asia/Kathmandu")
              .format("YYYY/MM/DD HH:mm z");

            this.allpatBookList[i].VisitDate = moment(
              this.allpatBookList[i].VisitDate,
              "YYYY/MM/DD hh:mm"
            ).format("YYYY/MM/DD");
          }
          this.collection = { count: this.allpatBookList.length, data: [] };
          this.allcollection = { count: this.allpatBookList.length, data: [] };
          for (var i = 0; i < this.allcollection.count; i++) {
            this.allcollection.data.push({
              id: i + 1,
              VisitDate: this.allpatBookList[i].VisitDate,
              BookingTime: this.allpatBookList[i].BookingTime,
              PatientFamilyName: this.allpatBookList[i].PatientFamilyName,
              HospitalName: this.allpatBookList[i].HospitalName,
              DoctorName: this.allpatBookList[i].DoctorName,
              DepartmentName: this.allpatBookList[i].DepartmentName,
              VisitType: this.allpatBookList[i].VisitType,
              PaymentStatus: this.allpatBookList[i].PaymentStatus,
              VisitId: this.allpatBookList[i].VisitId,
              Showpay:
                this.allpatBookList[i].VisitDate >= currentDateTime &&
                this.allpatBookList[i].PaymentStatus != "paid" &&
                this.allpatBookList[i].PaymentStatus != "free" &&
                this.allpatBookList[i].PaymentStatus == "unpaid"
                  ? true
                  : false,
              Showpaid:
                this.allpatBookList[i].VisitDate <= currentDateTime &&
                this.allpatBookList[i].PaymentStatus != "paid" &&
                this.allpatBookList[i].PaymentStatus != "free" &&
                this.allpatBookList[i].PaymentStatus == "unpaid"
                  ? true
                  : false,
            });
          }

          for (var i = 0; i < this.allpatBookList.length; i++) {
            this.allpatBookList[i].VisitDate = moment(
              this.allpatBookList[i].VisitDate
            ).format("ll");
            this.allpatBookList[i].VisitStartTime = moment(
              this.allpatBookList[i].VisitStartTime
            ).format("LT");
          }
          for (var i = 0; i < this.allcollection.data.length; i++) {
            this.allcollection.data[i].VisitDate = moment(
              this.allcollection.data[i].VisitDate
            ).format("ll");
            this.allcollection.data[i].VisitStartTime = moment(
              this.allcollection.data[i].VisitStartTime
            ).format("LT");
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }
  public ShowBookList() {
    //this.patbook = true;
    this.patservice
      .getPatientBookingList(this.token.PatientIdentifier)
      .subscribe(
        (res: any) => {
          if (res && res.IsSuccess && res.Results) {
            this.patBookList = res.Results;

            for (let i = 0; i < this.patBookList.length; i++) {
              let currentNepaliDate = this.nepaliDate
                .tz("Asia/Kathmandu")
                .format("YYYY/MM/DD HH:mm z");
              let currentNepaliDateTime = this.nepaliDate
                .tz("Asia/Kathmandu")
                .format("YYYY/MM/DD HH:mm z");
              let currentdate = moment(currentNepaliDate, "YYYY/MM/DD").format(
                "YYYY/MM/DD"
              );
              let currentDateWithTime = moment(
                currentNepaliDateTime,
                "YYYY/MM/DD hh:mm"
              ).format("YYYY/MM/DD");
              let currentYear = moment(currentNepaliDate, "YYYY/MM/DD").format(
                "YYYY"
              );
              let currentMonth = moment(currentNepaliDate, "YYYY/MM/DD").format(
                "M"
              );
              let currentDay = moment(currentNepaliDate, "YYYY/MM/DD").format(
                "D"
              );
              this.patBookList[i].VisitDate = moment(
                this.patBookList[i].VisitDate
              ).format("YYYY/MM/DD");
              let patlist: Array<any> = new Array<any>();

              if (
                this.patBookList[i].VisitDate ==
                moment(this.nepaliDate).format("YYYY/MM/DD")
              ) {
                var todaydate = new Date();
                var currentdate1 = this.nepaliDate.format("YYYY/MM/DD");
                // var index = this.patBookList[i].BookingTime.indexOf("-");
                // var timeextract = this.patBookList[i].BookingTime.substring(0, index - 1);
                // var bookedDateTime = moment(currentdate + ' ' + timeextract, 'YYYY/MM/DD HH:mm A');
                // var formatedBookedDateTime = moment(bookedDateTime, 'YYYY-MM-DD HH:mm A');
                // var temp = moment(formatedBookedDateTime, 'YYYY-MM-DD HH:mm A').subtract(15, 'minutes').format('YYYY-MM-DD HH:mm A');
                // var formatedBookedDateTime = moment(temp, 'YYYY-MM-DDTHH:mm:s');
                var DocStartTime = moment(
                  currentdate + " " + this.patBookList[i].DocStartTime,
                  "YYYY/MM/DD HH:mm A"
                );
                var formatedreducedStartTime = moment(
                  DocStartTime,
                  "YYYY-MM-DD HH:mm A"
                );
                var decStartTime = moment(
                  formatedreducedStartTime,
                  "YYYY-MM-DD HH:mm A"
                )
                  .subtract(15, "minutes")
                  .format("YYYY-MM-DD HH:mm A");
                var formatedDecStartTime = moment(
                  decStartTime,
                  "YYYY-MM-DDTHH:mm:A"
                );
                var DocEndtime = moment(
                  currentdate + " " + this.patBookList[i].DocEndTime,
                  "YYYY/MM/DD HH:mm A"
                );
                var formattedEndTime = moment(DocEndtime, "YYYY-MM-DD HH:mm A");
                var currentTime1 = moment(todaydate)
                  .tz("Asia/Kathmandu")
                  .format("YYYY-MM-DD HH:mm z");
                var currentTime = moment(
                  currentTime1,
                  "YYYY/MM/DD hh:mm"
                ).format("YYYY-MM-DD HH:mm A");
                var momentObj = moment(currentTime, "YYYY-MM-DDLT");
                var currentdateTime = momentObj.format("YYYY-MM-DDTHH:mm:s");
                var formatedCurrentTime = moment(
                  currentdateTime,
                  "YYYY-MM-DD HH:mm A"
                );
                //  var formatedCurrentTime = moment(currentTime, 'YYYY-MM-DD HH:mm A');
                // var diffmin = formatedBookedDateTime.diff(formatedCurrentTime, 'minutes');
                if (this.patBookList[i].VisitType == "Telemedicine") {
                  if (
                    formatedDecStartTime <= formatedCurrentTime &&
                    formatedCurrentTime <= formattedEndTime &&
                    this.patBookList[i].IsConversationCompleted == 0 &&
                    this.patBookList[i].Status == "initiated" &&
                    this.patBookList[i].PaymentStatus != "unpaid"
                  ) {
                    //if (diffmin <= 15) {
                    //  this.patBookList[i].GoToWaitingRoom = true;
                    //  this.patBookList[i].AccessPermission = true;
                    //  this.patBookList[i].EntryStatus = "go"
                    //} else {
                    //  this.patBookList[i].AccessPermission = false;
                    //  this.patBookList[i].EntryStatus = "todayupcoming";
                    this.patBookList[i].GoToWaitingRoom = true;
                    this.patBookList[i].AccessPermission = true;
                    this.patBookList[i].EntryStatus = "go";
                    this.nearestVisit = new Array<any>();
                    this.nearestVisit = JSON.parse(
                      JSON.stringify(this.patBookList)
                    ); //deepcopy
                    this.nearestVisit = this.patBookList[i];
                    this.showNearestVisit = true;
                    this.DoctorStatusCheck();
                  }
                } else {
                  var diffminwithStarttime = formatedCurrentTime.diff(
                    formatedDecStartTime,
                    "minutes"
                  );
                  var diffminwithEndtime = formatedCurrentTime.diff(
                    formattedEndTime,
                    "minutes"
                  );
                  if (diffminwithStarttime < 0) {
                    this.patBookList[i].AccessPermission = false;
                    this.patBookList[i].EntryStatus = "todayupcoming";
                  }
                  if (diffminwithEndtime > 0) {
                    this.patBookList[i].AccessPermission = false;
                    this.patBookList[i].EntryStatus = "todaypast";
                  }
                }
              }
              this.patlist = this.patBookList.filter(
                (x) => x.IsConversationCompleted == 1 && x.FollowupCreated == 0
              );
              this.patlist.sort(function (a, b) {
                if (a.VisitDate > b.VisitDate) return 1;
                if (a.VisitDate < b.VisitDate) return -1;
                return 0;
              });
              if (this.patlist.length) {
                // this.patlist[i].AccessPermission = true;
                this.followupVisit = new Array<any>();
                this.followupVisit = JSON.parse(
                  JSON.stringify(this.patlist[0])
                ); //deepcopy
                this.hospitalId = this.followupVisit.HospitalId;
                // this.followupVisit = this.patlist[0];
                this.showFollowupVisit = true;
              }
              if (this.patBookList[i].VisitDate < currentDateWithTime) {
                this.patBookList[i].AccessPermission = false;
                this.patBookList[i].GoToWaitingRoom = false;
                this.patBookList[i].EntryStatus = "missed";
              }
              if (this.patBookList[i].VisitDate > currentDateWithTime) {
                this.patBookList[i].AccessPermission = false;
                this.patBookList[i].GoToWaitingRoom = false;
                this.patBookList[i].EntryStatus = "upcoming";
              }
              this.patBookList[i].VisitDate = moment(
                this.patBookList[i].VisitDate
              ).format("ll");

              this.patBookList[i].VisitStartTime = moment(
                this.patBookList[i].VisitStartTime
              ).format("LT");
            }

            this.collection = { count: this.patBookList.length, data: [] };

            for (var i = 0; i < this.collection.count; i++) {
              this.collection.data.push({
                id: i + 1,
                VisitDate: this.patBookList[i].VisitDate,
                HospitalName: this.patBookList[i].HospitalName,
                DoctorName: this.patBookList[i].DoctorName,
                DepartmentName: this.patBookList[i].DepartmentName,
                VisitType: this.patBookList[i].VisitType,
                Status: this.patBookList[i].Status,
                ProviderId: this.patBookList[i].ProviderId,
                HospitalId: this.patBookList[i].HospitalId,
              });
            }
            this.GetDepartmentList();
          } else {
            this.alertService.error(res.ResponseMessage);
          }
        },
        (error) => {
          this.alertService.error(error);
          // this.loading = false;
        }
      );
  }

  public paymentselection(data) {
    this.routing.navigate(["/PaymentSelection"], {
      queryParams: {
        vid: data.VisitId,
        charge: data.Charge,
        id: this.hospitalId,
      },
    });
  }

  GotoWaitingRoom(data) {
    this.docService.DoctorId = data.ProviderId;
    const dialogRef = this.dialog.open(ConsentFormComponent, {
      data: { DoctorId: data.ProviderId, HospitalId: data.HospitalId },
      width: "1300px",
      height: "1000px",
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((result) => {
      dialogRef.close();
      if (result == true) {
        console.log(`Dialog result: ${result}`);
        // this.visitObj.DepartmentId = this.hospitalService.DepartmentId;
        this.routing.navigate(["/WaitingRoom"], {
          queryParams: {
            vid: data.VisitId,
            id: this.hospitalId,
            did: data.ProviderId,
          },
        });
        //this.routing.navigate(['/PatDashboard']);
      } else this.routing.navigate(["/PatDashboard"]);
    });
    //this.patService.VisitId = data.VisitId;
  }
  public AppointmentHistory() {
    this.patservice.getPatHistory(this.token.PatientIdentifier).subscribe(
      (res) => this.Success(res),
      (res) => this.Error(res)
    );
  }

  Success(res) {
    if(res && res.IsSuccess && res.Results){
      this.patHistoryList = res.Results;
      /* console.log(this.patHistoryList );*/
      this.historycollection = { count: this.patHistoryList.length, data: [] };
      for (var i = 0; i < this.historycollection.count; i++) {
        this.historycollection.data.push({
          id: i + 1,
          VisitId: this.patHistoryList[i].VisitId,
          VisitDate: this.patHistoryList[i].VisitDate,
          DoctorName: this.patHistoryList[i].DoctorName,
          HospitalName: this.patHistoryList[i].HospitalName,
          Status: this.patHistoryList[i].Status,
          NMC: this.patHistoryList[i].NMC,
        });
      }
    }
  }
  Error(res) {}

  public ViewNotes(visitId) {
    this.showViewNotes = true;
    this.patservice.getPatientHistory(this.token.PatientIdentifier).subscribe(
      (res) => {
        if(res && res.IsSuccess && res.Results){
          this.viewList = res.Results;
          this.noteList = res.Results;
          this.viewList = this.viewList.filter((s) => s.VisitId == visitId);
          for (var i = 0; i < this.viewList.length; i++) {
            this.viewList[i].VisitDate = moment(
              this.viewList[i].VisitDate
            ).format("lll");
          }
          for (let index = 0; index < this.viewList.length; index++) {
            const element = this.viewList[index];
            if (element == true) {
              this.prob = false;
            }
          }
        }
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }
  ViewPrescription(id) {
    this.visitId = id;
    this.filterPatList = new Array<any>();
    this.filterPatList = JSON.parse(JSON.stringify(this.patHistoryList)); //deepcopy
    this.filterPatList = this.filterPatList.filter(
      (x) => x.VisitId == this.visitId
    );
    this.prescriptions = this.filterPatList[0].PatientFile;
    if (this.prescriptions.length > 0) {
      this.showPrescription = true;
      this.prescriptions.forEach((x) => {
        this.fileExtension = x.FileExtention;
        if (
          this.fileExtension == "jpeg" ||
          this.fileExtension == "JPEG" ||
          this.fileExtension == "png" ||
          this.fileExtension == "PNG" ||
          this.fileExtension == "JPG" ||
          this.fileExtension == "jpg"
        ) {
          x.FileType = "image";
        }
        if (this.fileExtension == "pdf" || this.fileExtension == "PDF") {
          x.FileType = "pdf";
        }
        if (
          this.fileExtension == "docx" ||
          this.fileExtension == "DOCX" ||
          this.fileExtension == "DOC" ||
          this.fileExtension == "doc"
        ) {
          x.FileTypeprescriptions = "docfile";
        }
      });
      console.log(this.prescriptions);
    } else {
      this.showPrescription = false;
      this.notifyService.showError(
        "Error!",
        " Could not found the Prescription file"
      );
    }
  }

  public Close() {
    this.showViewNotes = false;
  }
  Print() {
    let popupWinindow;
    var printContents = document.getElementById("Report").innerHTML;
    popupWinindow = window.open(
      "",
      "_blank",
      "width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
    );
    popupWinindow.document.open();
    let documentContent = "<html><head>";
    documentContent += '<link rel="stylesheet" type="text/css" />';
    documentContent += "</head>";
    documentContent +=
      '<body onload="window.print()" style="margin:8px 0px 0px 50px !important;">' +
      printContents +
      "</body></html>";
    popupWinindow.document.write(documentContent);
    popupWinindow.document.close();

    setTimeout(() => {
      popupWinindow.close();
    }, 500);
  }

  // followup start

  Followup(data) {
    this.Age = data.Age;
    this.Gender = data.Gender;
    this.LastApptDepartmentName = data.DepartmentName;
    this.LastApptDoctorName = data.DoctorName;
    this.LastApptVisitDate = data.VisitDate;
    this.followupinfo = data;
    this.showfollowup = true;
    this.GetDepartmentList();
  }

  public Closefollowup() {
    this.showfollowup = false;
    this.selDoctor = "";
    this.SchedulingList = null;
    this.selSchedule = "";
    this.scheduleIntervalId = "";
    this.filteredSchedulingList = null;
    this.scheduleIntervalList = null;
    this.visitObj = new Visit();
    this.showUnavailableMsg = false;
    this.selDepartment = "";
    this.LastApptDepartmentName = "";
    this.LastApptDoctorName = "";
  }

  GetDepartmentList() {
    this.docService.getDepartmentList().subscribe(
      (res: any) => {
        if (res && res.IsSuccess && res.Results) {
          this.deptList = res.Results;
          if (this.LastApptDepartmentName != null) {
            var selDeptData = this.deptList.filter(
              (d) => d.DepartmentName == this.LastApptDepartmentName
            );
            this.selDepartment = selDeptData[0].DepartmentId;
          } else {
            this.selDepartment = this.deptList[0].DepartmentId;
          }
          this.GetDoctorList();
        }else{
          this.alertService.error(res.ResponseMessage);
        }
        
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  GetDoctorList() {
    this.docService.getDoctorMapList().subscribe(
      (res) => this.SuccessDoctor(res),
      (res) => this.Error(res)
    );
  }
  SuccessDoctor(res) {
    if(res && res.IsSuccess && res.Results){
      this.doctorList = res.Results;
      this.AssignDepartment();
    }else {
      this.alertService.error(res.ResponseMessage);
    }
   
  }

  AssignDepartment() {
    this.filterDocList = this.doctorList.filter(
      (x) => x.DepartmentId == this.selDepartment
    );
    if (this.filterDocList.length) {
      this.showUnavailableMsg = false;
      if (this.LastApptDoctorName != null) {
        var selDocData = this.filterDocList.filter(
          (x) => x.DoctorName == this.LastApptDoctorName
        );
        if (selDocData.length != 0) {
          this.selDoctor = selDocData[0].DoctorId;
        } else {
          this.selDoctor = this.filterDocList[0].DoctorId;
        }
      } else {
        this.selDoctor = this.filterDocList[0].DoctorId;
      }
      this.AssignDoctor();
    } else {
      this.showUnavailableMsg = true;
      this.selDoctor = "";
      this.SchedulingList = null;
      this.selSchedule = "";
      this.scheduleIntervalId = "";
      this.filteredSchedulingList = null;
      this.scheduleIntervalList = null;
    }
  }

  AssignDoctor() {
    this.docService
      .getDoc(this.selDoctor, this.hospitalId, this.VisitType)
      .subscribe(
        (res) => this.SuccessSchedulelist(res),
        (res) => this.Error(res)
      );
  }
  SuccessSchedulelist(res) {
    // if (res && res.IsSuccess && res.Results) {
    // }else{
    //   this.alertService.error(res.ResponseMessage);
    // }
    if (res.Results != null) {
      this.SchedulingList = res.Results.SchedulingList;
      this.tempArray = new Array<any>();
      this.tempArray = JSON.parse(JSON.stringify(this.SchedulingList));
      if (this.tempArray.length == 0) {
        this.showUnavailableMsg = true;
      } else {
        this.showUnavailableMsg = false;
      }
      for (var y = 0; y < this.tempArray.length; y++) {
        var tempst = 0;
        tempst = +this.tempArray[y].StartTime.substr(0, 2);
        if (tempst < 12) {
          var stampm = "AM";
          var ST = tempst;
        } else {
          stampm = "PM";
          if (tempst == 12) {
            var ST = tempst;
          } else {
            ST = tempst - 12;
          }
        }
        this.tempArray[y].StartTime =
          ST + this.tempArray[y].StartTime.substr(2, 3) + " " + stampm;
        var tempet = 0;
        tempet = +this.tempArray[y].EndTime.substr(0, 2);
        if (tempet < 12) {
          var etampm = "AM";
          var ET = tempet;
        } else {
          etampm = "PM";
          if (tempet == 12) {
            var ET = tempet;
          } else {
            ET = tempet - 12;
          }
        }
        this.tempArray[y].EndTime =
          ET + this.tempArray[y].EndTime.substr(2, 3) + " " + etampm;
      }
      this.HospitalDoctorMapping = res.Results.HospitalDoctorMapId.filter(
        (x) => x.DepartmentId == this.selDepartment
      );
      this.filteredSchedulingList = [];
      this.tempArray.forEach((schedule) => {
        if (
          schedule.HospitalDoctorMapId ==
          this.HospitalDoctorMapping[0].HospitalDoctorMapId
        ) {
          this.filteredSchedulingList.push(schedule);
        }
      });
      //this.filteredSchedulingList = this.tempArray;
      if (this.filteredSchedulingList.length) {
        this.selSchedule = this.filteredSchedulingList[0].SchedulingId;
      } else {
        this.selSchedule = "";
        this.showUnavailableMsg = true;
      }
      this.SelectSchedule();
      if (this.selDoctor != null) {
        this.ScheduleTimeDetail = true;
      }
    }
  }

  SelectSchedule() {
    if (this.selSchedule != null) {
      this.scheduleIntervalList = [];
      this.checkTimeSlot(this.selSchedule);
    }
  }

  checkTimeSlot(SchId) {
    this.schedulingService.getScheduleIntervalBySchedulingId(SchId).subscribe(
      (res) => this.SuccessScheduleInterval(res),
      (res) => this.Error(res)
    );
  }
  SuccessScheduleInterval(res) {
    if (res && res.IsSuccess && res.Results) {
      this.scheduleIntervalList = Object.assign(this.scheduleIntervalList, res?.Results);
      for (var c = 0; c < this.scheduleIntervalList.length; c++) {
        var date = moment(this.scheduleIntervalList[c].Date).format("YYYY/MM/DD");
        var timeextract = this.scheduleIntervalList[c].StartTime;
        var bookedStartTime = moment(
          date + " " + timeextract,
          "YYYY/MM/DD HH:mm A"
        );
        var momentObj = moment(bookedStartTime, "YYYY-MM-DDLT");
        var currentdateTime = momentObj.format("YYYY-MM-DDTHH:mm:s");
        var formatedBookedDateTime = moment(
          currentdateTime,
          "YYYY-MM-DD HH:mm A"
        );
        this.scheduleIntervalList[c].StartTimeDetail = formatedBookedDateTime;
      }
      console.log(this.scheduleIntervalList);
  
      for (let k = 0; k < this.scheduleIntervalList.length - 1; k++) {
        for (let l = 0; l < this.scheduleIntervalList.length - k - 1; l++) {
          var diffmin = this.scheduleIntervalList[l + 1].StartTimeDetail.diff(
            this.scheduleIntervalList[l].StartTimeDetail,
            "minutes"
          );
          if (diffmin < 0) {
            let swap = this.scheduleIntervalList[l];
            this.scheduleIntervalList[l] = this.scheduleIntervalList[l + 1];
            this.scheduleIntervalList[l + 1] = swap;
          }
        }
      }
      let nowdatetime: Date = new Date();
      this.scheduleIntervalList = this.scheduleIntervalList.filter(
        (x) => x.StartTimeDetail >= nowdatetime
      );
  
      console.log(this.scheduleIntervalList);
      // if (this.scheduleIntervalList.length) {
      //   this.scheduleIntervalId = this.scheduleIntervalList[0].ScheduleIntervalId;
  
      // }
      // else {
      //   this.scheduleIntervalId = '';
      // }
    }else{
      this.alertService.error(res.ResponseMessage);
    }
   
  }

  setAppontmentTime($event) {
    if (this.scheduleIntervalList.length) {
      let tempdata = this.scheduleIntervalList.filter(
        (x) => x.ScheduleIntervalId == this.scheduleIntervalId
      );
      if (tempdata[0].IsBooked != true) {
        this.scheduleIntervalId = tempdata[0].ScheduleIntervalId;
        this.visitObj.BookingTime = `${tempdata[0].StartTime} - ${tempdata[0].EndTime}`;
        this.visitObj.VisitDate = tempdata[0].Date;
      } else {
        this.visitObj.BookingTime = null;
        this.notifyService.showError(
          "Error",
          "Selected time is already booked!!"
        );
      }
    }
  }
  ConfirmFollowup(form) {
    if (this.visitObj.BookingTime == null) {
      this.notifyService.showError(
        "Error",
        "Selected time is already booked!!"
      );
      return;
    }
    if (form.invalid) {
      if (form.controls.Department.invalid) {
        this.notifyService.showError("", "Department is required");
      }
      if (form.controls.Doctor.invalid) {
        this.notifyService.showError("", "Doctor is required");
      }
      if (form.controls.FollowUpdate.invalid) {
        this.notifyService.showError("", "Follow-Up Date is required");
      }
      if (form.controls.Time.invalid) {
        this.notifyService.showError("", "Time is required");
      }
      return;
    }
    if (form.valid) {
      if (this.visitObj.VisitId == null) {
        this.visitObj.HospitalId = this.followupinfo.HospitalId;
        this.visitObj.DoctorId = this.selDoctor;
        this.visitObj.ProviderId = this.selDoctor;
        this.visitObj.DepartmentId = this.selDepartment;
        this.visitObj.PaymentStatus = "unpaid";
        this.visitObj.Status = "initiated";
        this.visitObj.VisitType = this.followupinfo.VisitType;
        this.visitObj.PatientId = this.followupinfo.PatientId;
        this.visitObj.VisitId = this.followupinfo.VisitId;
        this.patservice
          .followUpPostVisit(
            this.visitObj,
            this.scheduleIntervalId,
            this.selSchedule
          )
          .subscribe(
            (res) => this.SuccessPostProblem(res),
            (res) => this.PostProblemError(res)
          );
      }
    }
  }

  SuccessPostProblem(res) {
    this.notifyService.showSuccess("", "Follow-Up Added Successfully!");
    if (this.token.PatientIdentifier == undefined) {
    } else {
      if (this.hospitalService.PaymentEnable == false) {
        this.routing.navigate(["/PatPaidBookingList"], {
          queryParams: { vid: res.Value },
        });
      } else {
        this.routing.navigate(["/PatPaidBookingList"], {
          queryParams: { vid: res.Value },
        });
      }
    }
  }
  PostProblemError(res) {
    this.notifyService.showError("Error", " Please fill up the required field");
  }

  Makepayment() {
    // if(form.valid){
    this.routing.navigate(["/PaymentSelection"], {
      queryParams: { vid: this.visitObj },
    });
    // }
  }
  // followup end

  CloseVisittypepopup() {
    this.showVisitTypePopup = false;
    this.updatePat = false;
  }

  SetOnlineAppointment() {
    this.patservice.VisitType = "Online Appointment";
    this.routing.navigate(["/HospitalList"], {
      queryParams: { VisitType: "Online Appointment" },
    });
  }

  SetTelemedicineAppointment() {
    this.patservice.VisitType = "Telemedicine";
    this.routing.navigate(["/HospitalList"], {
      queryParams: { VisitType: "Telemedicine" },
    });
  }

  public PatientMedicalRecord() {
    this.updatePat = true;
    this.routing.navigate(["/PatientMedicalRecord"], {
      queryParams: { id: this.hospitalId },
    });
  }
  public PatientLabReports() {
    this.updatePat = true;
    this.routing.navigate(["/PatientLabReports"], {
      queryParams: { id: this.hospitalId },
    });
  }

  DoctorStatusCheck() {
    this.GetDoctorList();
    this.timerpat
      .pipe(takeWhile((x) => this.showNearestVisit === true))
      .subscribe(() => {
        if (this.nearestVisit != null) {
          this.doctor = this.doctorList.filter(
            (d) => d.DoctorId == this.nearestVisit.ProviderId
          );
          const user = {
            PatientUserId: this.patInfo.IdentityUserId,
            DoctorUserId: this.doctor[0].IdentityUserId,
          };
          this.notificationService.DoctorStatusCheck(user);
        }
      });
  }

  public HelpDesk() {
    this.updatePat = true;
    this.routing.navigate(["/PatientHelpDesk"], {
      queryParams: { id: this.hospitalId },
    });
  }

  GetMissedAppointment() {
    this.patservice.getAllMissedVisit().subscribe(
      (res) => {
        console.log("Missed Visit are here");
      }
      // ,
      //   err => {
      //     console.log(err);
    );
  }
}
