<app></app>

<div class="back-bg">
  <button (click)="backToAdminPage()" class="back-btn-doc-room">
    Back To Admin
  </button>
</div>

<section class="Main">
  <div class="row">
    <div class="col-md-12">
      <form class="file-upload-form" [formGroup]="fileUploadForm">
        <div class="p-2">
          <div class="form-group">
            <label for="file">Enter Patient's Phone Number <span class="required-field">*</span> </label>
            <input type="text" formControlName="phoneNumber" />
          </div>
          <div class="user-info" *ngIf="isPhoneNumberRegistered == true">
            <em class="fa fa-check-circle">This phone number is already registered. Upload your files right away.</em>
          </div>
          <div class="user-not-found-info" *ngIf="isPhoneNumberRegistered == false">
            <em class="fa fa-times-circle">This phone number is not registered. Please fill the details.</em>
          </div>
        </div>
        <div class="d-inline-flex p-2">
          <div class="form-group">
            <label for="file">First Name<span class="required-field">*</span> </label>
            <input type="text" formControlName="firstName" />
          </div>
        </div>
        <div class="d-inline-flex p-2">
          <div class="form-group">
            <label for="file">Last Name<span class="required-field">*</span> </label>
            <input type="text" formControlName="lastName" />
          </div>
        </div>
        <div class="d-inline-flex p-2">
          <div class="form-group">
            <label for="file">Email<span class="required-field">*</span> </label>
            <input type="text" formControlName="email" />
          </div>
        </div>
      </form>

      <section id="file-upload">
        <div class="upload-container" appDnd (fileDropped)="onFileDropped($event)">
          <input type="file" accept=".doc, .docx, .pdf, .jpg, .jpeg, .png" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
          <img src="assets/img/dnd/ic-upload-file.svg" alt="" />
          <h3>Drag and drop file here</h3>
          <h3>or</h3>
          <label for="fileDropRef">Browse for file</label>
          <p class="text-muted">Supported File Formats: <em>doc(x), pdf, jpeg, jpg, png.</em></p>
        </div>
        <div class="files-list d-flex">
          <div class="single-file m-2" *ngFor="let file of files; let i = index">
            <div><em class="fa fa-2x fa-file file-icon mr-2"></em></div>
            <div class="info">
              <h4 class="name">
                {{ file?.name }}
              </h4>
              <p class="size">
                {{ formatBytes(file?.size) }}
              </p>
              <div class="progress-cont">
                <div class="progress" [style.width]="file?.progress + '%'"></div>
              </div>
            </div>
            <div>
              <em class="delete-icon fa fa-2x fa-trash-o" (click)="deleteFile(i)"></em>
            </div>
          </div>
        </div>
      </section>

      <div>
        <button class="btn btn-primary" (click)="uploadFileToActivity()" [disabled]="loading">
          Upload File
        </button>
      </div>
    </div>
  </div>
</section>
