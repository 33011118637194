<div class="card">
  <h3>Apply for Home Isolation Care</h3>
  <form>
    <div >
      <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="Dhcare.FirstName" value="" placeholder="Enter your First Name">
    </div>
    <div >
      <input type="text"   [ngModelOptions]="{standalone: true}" [(ngModel)]="Dhcare.MiddleName" value="" placeholder="Enter your Middle Name">
    </div>
    <div >
      <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="Dhcare.LastName" value="" placeholder="Enter your Last Name">
    </div>
    <div >
      <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="Dhcare.ContactNumber" value="" placeholder="Enter your Mobile Number">
    </div>
    <div >
      <input type="text"   [ngModelOptions]="{standalone: true}"  [(ngModel)]="Dhcare.Email" value=""
        placeholder="Enter your email address">
    </div>
    <div >
      <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="Dhcare.ServiceId" value=""
        placeholder="Enter ServiceId ">
    </div> 
    <div >
      <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="Dhcare.Service" value=""
        placeholder="Enter  Service Name ">
    </div>   
    <div >
      <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="Dhcare.Price" value=""
        placeholder="Enter Ptice">
    </div> 
    <div >
      <input type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="Dhcare.PaymentProvider" value=""
        placeholder="Enter PaymentProvider">
    </div> 
    <div >
      <div class="row">
        <div class="col-md-6">
          <button (click)="ApplytoIsolation()" class="btn btn-primary"><strong>Apply Now</strong></button>
        </div>       
      </div>
    </div>
  </form>
</div>
<div class="card">
  <div class="grid-section">
    <h4 class="title">
      <i class="fa fa-list"></i>Applied User's List
    </h4>
  
    <table class="table ">
      <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">User Name</th>
          <th scope="col">Contact Number</th>
          <th scope="col">EmailId</th>
          <th scope="col">Service</th>
          <th scope="col">Price</th>
          <th scope="col">Payment Provider</th>
          <th scope="col">Applied Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let usr of Usrlist  |paginate: { itemsPerPage: 5, currentPage: p  } ; let i=index ;">
          <td>{{i+1}}</td>
          <td>{{usr.UserName}}</td>
          <td>{{usr.ContactNumber}}</td>
          <td>{{usr.Email}}</td>
          <td>{{usr.Service}}</td>
          <td>{{usr.Price }}</td>
          <td>{{usr.PaymentProvider }}</td>
          <td>{{usr.CreatedOn }}</td>
         </tr>
      </tbody>
    </table>
    <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>
  
  </div>
</div>