export class ProductModel {

  Id: any;

  ProductTypeId: number;
  ProductTagId: number;
  VisibleIndividually: boolean;
  Name: string;
  ProductTags: any[] = [];
  ShortDescription: string;
  FullDescription: string;
  AdminComment: string;
  HospitalId: string;
  HospitalName: string;
  Hospital: any;
  ProductType: any;
  PictureId: number;
  ShowOnHomepage; boolean;
  MetaKeywords: string;
  MetaDescription: string;
  MetaTitle: string;
  PermaLink: string;
  Sku: string;
  ManufacturerPartNumber: string;
  DeliveryDateId: number;
  NotReturnable: boolean;
  DisableBuyButton: boolean;
  Price: number;
  OldPrice: number;
  ProductCost: number;
  MarkAsNew: boolean;
  MarkAsNewStartDateTime: Date;
  MarkAsNewEndDateTime: Date;
  DisplayOrder: number;
  IsActive: boolean = true;
  CreatedBy: string;
  CreatedOn: string;
  UpdatedBy: string;
  UpdatedOn: string;
  ChildProducts: ProductModel[] = [];
  GroupingLabel: string = '';
}
