import { Injectable } from "@angular/core";
import { OrderEndpoint } from "./order.endpoint";

@Injectable({ providedIn: "root" })
export class OrderService {

  constructor(public orderEndpoint: OrderEndpoint) {

  }
  getAllProducts<T>(pageNumber: number) {
    return this.orderEndpoint.getOrders<T>(pageNumber);
  }
}
