import {
  NgForm,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  ReactiveFormsModule
} from '@angular/forms';
import * as moment from 'moment/moment'
export class ContactInformation {
  public ContactInformationId: string;
  public ContactNumber: string;
  public Email: string;
  public ContactInfoValidator :FormGroup = null;
  public AdminId: string;

  // constructor() {

  //   var _formBuilder = new FormBuilder();

  //   this.ContactInfoValidator = _formBuilder.group({
      
  //     'ContactNumber':['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
  //     'Email':['', Validators.compose([Validators.required, Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$')])],
  //   });
  
  // }

 
//   public IsDirty(fieldName): boolean {
//     if (fieldName == undefined)
//         return this.ContactInfoValidator.dirty;
//     else
//         return this.ContactInfoValidator.controls[fieldName].dirty;
// }

// public IsValid():boolean{if(this.ContactInfoValidator.valid){return true;}else{return false;}} public IsValidCheck(fieldName, validator): boolean {
//     if (fieldName == undefined) {
//         return this.ContactInfoValidator.valid;
//     }
//     else
//         return !(this.ContactInfoValidator.hasError(validator, fieldName));
// }
}


