<app *ngIf="isPatEditor == false"></app>
<div *ngIf="isPatEditor == false" class="back-bg">
  <button (click)="BackDashBoard()" class="back-btn-doc-room">Back To DashBoard </button>
  <button (click)="showPersonalDetails()" [style.background-color]="isPersonalDetails ? '#007bff' : '#009688'">Update Personal Details </button>
  <button (click)="showFamilyList()" [style.background-color]="isPersonalDetails ? '#009688' : '#007bff'">Family List </button>
</div>
<div class="card">
  <div class="patient-login" style="padding: 20px;">
    <h5 *ngIf="isPatEditor == false && isPersonalDetails == true" class="Doc-form profile-title"><i class="fa fa-user-md"></i>Patient&nbsp;&nbsp;|&nbsp;&nbsp;<span>Update Your Profile.</span></h5>
    <h5 *ngIf="isPatEditor == false && isPersonalDetails == false" class="Doc-form profile-title"><i class="fa fa-user-md"></i>Patient&nbsp;&nbsp;|&nbsp;&nbsp;<span>Update Your Family Details.</span></h5>
    <h5 *ngIf="isPatEditor == true" class="Doc-form profile-title"><i class="fa fa-user-md"></i>Patient&nbsp;&nbsp;|&nbsp;&nbsp;<span>Update {{patName}} Profile.</span></h5>
    <div class="alert alert-default d-flex"><i class="fa fa-info-circle"></i>
      <p>Fields with * are required.</p>
    </div>
    <div *ngIf="isPatEditor == false">
      <form *ngIf="isPersonalDetails == true" #patientForm="ngForm" (ngSubmit)="UpdatePatProfile(patientForm)">
        <div class="row d-flex">
          <div class="col-md-4">
            <label>First Name <span *ngIf="FirstName.invalid" class="required-sign">*</span></label>
            <input type="text" name="FirstName" value="" [(ngModel)]="patInfo.FirstName" size="25" required #FirstName="ngModel" />
            <!-- <div *ngIf="hasError('required','patUsrName')" style="color: red;"> Name is required</div> -->
            <!-- <input type="hidden" name="UserType" value="Patient" /> -->

          </div>
          <div class="col-md-4">
            <label>Middle Name </label>
            <input type="text" name="MiddleName" value="" size="25" [(ngModel)]="patInfo.MiddleName" />
            <!-- <div *ngIf="hasError('required','patUsrName')" style="color: red;"> Name is required</div> -->
            <!-- <input type="hidden" name="UserType" value="Patient" /> -->
          </div>
          <div class="col-md-4">
            <label>Last Name<span *ngIf="LastName.invalid" class="required-sign">*</span> </label>
            <input type="text" name="LastName" value="" size="25" [(ngModel)]="patInfo.LastName" required #LastName="ngModel" />
            <!-- <div *ngIf="hasError('required','patUsrName')" style="color: red;"> Name is required</div> -->
            <!-- <input type="hidden" name="UserType" value="Patient" /> -->

          </div>
          <div class="col-md-4">
            <label>Age</label>
            <input type="text" name="Age" value="" size="25" [(ngModel)]="patInfo.Age" (focusout)="generateDOB()" />
          </div>
          <div class="col-md-4">
            <label>Blood Group </label><br>
            <!-- <input type="text" name="BloodGroup" value="" size="25" [(ngModel)]="patInfo.BloodGroup" /> -->
            <select name="BloodGroup" [(ngModel)]="patInfo.BloodGroup">
              <option *ngFor="let group of BloodGroup" [value]="group.name">
                {{group.name}}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <label>Address</label>
            <input type="text" name="Address" value="" size="25" [(ngModel)]="patInfo.Address" />
          </div>
          <div class="col-md-4">
            <label for="Gender">Gender<span *ngIf="Gender.invalid" class="required-sign">*</span></label><br />
            <input type="radio" value="Male" name="Sex" [(ngModel)]="patInfo.Gender" required #Gender="ngModel"> M<span class="hide-sm">ale &nbsp;&nbsp;</span>
            <input type="radio" value="Female" name="Sex" [(ngModel)]="patInfo.Gender" required #Gender="ngModel"> F<span class="hide-sm">emale&nbsp;&nbsp;</span>
            <input type="radio" value="Other" name="Sex" [(ngModel)]="patInfo.Gender" required #Gender="ngModel"> O<span class="hide-sm">ther&nbsp;&nbsp;</span>

          </div>
          <div class="col-md-4">
            <label for="">Date of Birth<span class="required-sign">*</span></label>
            <div class="input-group">
              <input class="form-control" [maxDate]="today" placeholder="Datepicker" name="DOB" [(ngModel)]="patInfo.DateOfBirth" bsDatepicker #d="bsDatepicker" (ngModelChange)="generateAge()">
              <div class="input-group-append">
                <button class="fa fa-calendar" (click)="d.toggle()" type="button"></button>
              </div>
            </div>
            <!-- <input type="date" name="DOB" value="" [(ngModel)]="patInfo.DateOfBirth " #DateOfBirth="ngModel" /> -->

          </div>
          <div class="col-md-4">
            <label for="">Email</label>
            <input type="email" name="Email" [(ngModel)]="patInfo.MailingAddress">
          </div>
          <div class="col-md-4">
            <label for="">Mobile Number<span *ngIf="ContactNumber.invalid" class="required-sign">*</span></label><br />
            <input type="number" name="ContactNumber" disabled value="" [(ngModel)]="patInfo.ContactNumber" #ContactNumber="ngModel" />

          </div>
        </div>

        <!-- </form> -->
        <!--<button class="btn btn-primary mt-5" value="Submit">Update</button>-->
        <div class="col-md-12 p-0">
          <button *ngIf="isPatEditor == false" class="btn btn-success mr-1" (click)="cancel()">Cancel</button>
          <button class="btn btn-success" value="Submit">Update</button>
        </div>
      </form>
      <div *ngIf="isPersonalDetails == false">
        <button class="btn-primary" (click)="AddFamilyDetails()">Add Family List +</button>
        <div style="overflow: scroll; margin-top: 25px;">
          <table class="table table-striped table-hover table-responsive" style="width: 100%;">
            <thead>
              <tr class="table-tr-style">
                <th scope="col">SN</th>
                <th scope="col">Relationship</th>
                <th scope="col">Full Name</th>
                <th scope="col">Age/Gender</th>
                <th scope="col">Date of Birth</th>
                <th scope="col">Contact Number</th>
                <th scope="col">Email</th>
                <th scope="col">Blood Group</th>
                <th scope="col">Address</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let family of familyDetails; let i = index">
                <td>{{i+1}}</td>
                <td>{{family.Relationship}}</td>
                <td>{{family.FullName}}</td>
                <td>{{family.Age}}/{{family.Gender}}</td>
                <td>{{family.DateOfBirth}}</td>
                <td>{{family.ContactNumber}}</td>
                <td>{{family.Email}}</td>
                <td>{{family.BloodGroup}}</td>
                <td>{{family.Address}}</td>
                <td>
                  <button class="btn btn-success btn-sm mr-1" (click)="UpdateFamily(i)">Update</button>
                  <button class="btn btn-danger btn-sm mr-1" (click)="RemoveFamily(family.FamilyMemberId)">Remove</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="isPatEditor == true">
      <div class="row d-flex">
        <div class="col-md-4">
          <label>First Name<span *ngIf="FirstName.invalid" class="required-sign"></span></label>
          <input type="text" name="FirstName" value="" [(ngModel)]="patInfo.FirstName" size="25" required #FirstName="ngModel" />
          <!-- <div *ngIf="hasError('required','patUsrName')" style="color: red;"> Name is required</div> -->
          <!-- <input type="hidden" name="UserType" value="Patient" /> -->

        </div>
        <div class="col-md-4">
          <label>Middle Name </label>
          <input type="text" name="MiddleName" value="" size="25" [(ngModel)]="patInfo.MiddleName" />
          <!-- <div *ngIf="hasError('required','patUsrName')" style="color: red;"> Name is required</div> -->
          <!-- <input type="hidden" name="UserType" value="Patient" /> -->
        </div>
        <div class="col-md-4">
          <label>Last Name<span *ngIf="LastName.invalid" class="required-sign"></span> </label>
          <input type="text" name="LastName" value="" size="25" [(ngModel)]="patInfo.LastName" required #LastName="ngModel" />
          <!-- <div *ngIf="hasError('required','patUsrName')" style="color: red;"> Name is required</div> -->
          <!-- <input type="hidden" name="UserType" value="Patient" /> -->
        </div>
        <div class="col-md-4">
          <label>Age</label>
          <input type="text" name="Age" value="" size="25" [(ngModel)]="patInfo.Age" (focusout)="generateDOB()" />
        </div>
        <div class="col-md-4">
          <label>Blood Group </label>
          <!-- <input type="text" name="BloodGroup" value="" size="25" [(ngModel)]="patInfo.BloodGroup" /> -->
          <select [(ngModel)]="patInfo.BloodGroup">
            <option *ngFor="let group of BloodGroup" [value]="group.name" [selected]="group.name==patInfo.BloodGroup">
              {{group.name}}
          </select>
        </div>
        <div class="col-md-4">
          <label>Address</label>
          <input type="text" name="Address" value="" size="25" [(ngModel)]="patInfo.Address" />
        </div>
        <div class="col-md-4">
          <label for="Gender">Gender<span *ngIf="Gender.invalid" class="required-sign"></span></label><br />
          <input type="radio" value="Male" name="Sex" [(ngModel)]="patInfo.Gender" required #Gender="ngModel"> M<span class="hide-sm">ale &nbsp;&nbsp;</span>
          <input type="radio" value="Female" name="Sex" [(ngModel)]="patInfo.Gender" required #Gender="ngModel"> F<span class="hide-sm">emale&nbsp;&nbsp;</span>
          <input type="radio" value="Other" name="Sex" [(ngModel)]="patInfo.Gender" required #Gender="ngModel"> O<span class="hide-sm">ther&nbsp;&nbsp;</span>

        </div>
        <div class="col-md-4">
          <label for="">Date of Birth<span class="required-sign"></span></label>
          <div class="input-group">
            <input class="form-control" [maxDate]="today" placeholder="Datepicker" name="DOB" [(ngModel)]="patInfo.DateOfBirth" bsDatepicker #d="bsDatepicker" (ngModelChange)="generateAge()">
            <div class="input-group-append">
              <button class="fa fa-calendar" (click)="d.toggle()" type="button"></button>
            </div>
          </div>
          <!-- <input type="date" name="DOB" value="" [(ngModel)]="patInfo.DateOfBirth " #DateOfBirth="ngModel" /> -->

        </div>
        <div class="col-md-4">
          <label for="">Email</label>
          <input type="email" name="Email" [(ngModel)]="patInfo.MailingAddress">
        </div>
        <div class="col-md-4">
          <label for="">Mobile Number<span *ngIf="ContactNumber.invalid" class="required-sign">*</span></label><br />
          <input type="number" name="ContactNumber" disabled value="" [(ngModel)]="patInfo.ContactNumber" #ContactNumber="ngModel" />

        </div>
      </div>

      <!-- </form> -->
      <!--<button class="btn btn-primary mt-5" value="Submit">Update</button>-->
      <div class="col-md-12">
        <button *ngIf="isPatEditor" class="btn btn-success" (click)="cancel()">Cancel</button> &nbsp;&nbsp;
        <button class="btn btn-success" (click)="updatepatient()">Update</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="target" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [style.display]="addFamilyDetails ? 'block' : 'none'" style="display: block">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="!updateFamilyDetails" class="modal-title" id="myLargeModalLabel">Add Family Details</h5>
        <h5 *ngIf="updateFamilyDetails" class="modal-title" id="myLargeModalLabel">Update Family Details</h5>
        <button type="button" (click)="cleardata()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="background-color:white;">
        <form [formGroup]="familyListForm">
          <div class="row d-flex mb-2">
            <div class="col-md-4">
              <label>Relationship<span class="required-sign">*</span></label>
              <select class="form-control" placeholder="Select type" [(ngModel)]="familyList.Relationship" formControlName="Relationship">
                <option *ngFor="let relation of Relationship" [value]="relation.name">
                  {{relation.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="row d-flex">

            <div class="col-md-4">
              <label>First Name <span class="required-sign">*</span></label>
              <input type="text" name="FirstName" id="FirstName" [(ngModel)]="familyList.FirstName" size="25" formControlName="FirstName" />
            </div>
            <div class="col-md-4">
              <label>Middle Name </label>
              <input type="text" name="MiddleName" value="" size="25" [(ngModel)]="familyList.MiddleName" formControlName="MiddleName" />
            </div>
            <div class="col-md-4">
              <label>Last Name<span class="required-sign">*</span> </label>
              <input type="text" name="LastName" value="" size="25" [(ngModel)]="familyList.LastName" formControlName="LastName" />
            </div>
            <div class="col-md-4">
              <label for="DOB">Date of Birth<span class="required-sign">*</span> </label>
              <div class="input-group">
                <input class="form-control" [maxDate]="today" placeholder="Datepicker" name="DOB" [(ngModel)]="familyList.DateOfBirth" formControlName="DOB" bsDatepicker #d="bsDatepicker">
                <div class="input-group-append">
                  <button class="fa fa-calendar" (click)="d.toggle()" type="button"></button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="ContactNumber">Mobile Number<span class="required-sign">*</span></label><br />
              <input type="number" name="ContactNumber" [(ngModel)]="familyList.ContactNumber" formControlName="ContactNumber" />
            </div>
            <div class="col-md-4">
              <label for="">Email</label>
              <input type="email" name="Email" [(ngModel)]="familyList.Email" formControlName="Email">
            </div>
            <div class="col-md-4">
              <label for="Gender">Gender<span class="required-sign">*</span></label><br />
              <input type="radio" value="Male" name="Gender" [(ngModel)]="familyList.Gender" formControlName="Gender"> M<span class="hide-sm">ale &nbsp;&nbsp;</span>
              <input type="radio" value="Female" name="Gender" [(ngModel)]="familyList.Gender" formControlName="Gender"> F<span class="hide-sm">emale&nbsp;&nbsp;</span>
              <input type="radio" value="Other" name="Gender" [(ngModel)]="familyList.Gender" formControlName="Gender"> O<span class="hide-sm">ther&nbsp;&nbsp;</span>
            </div>
            <div class="col-md-4">
              <label>Blood Group </label><br>
              <select name="BloodGroup" [(ngModel)]="familyList.BloodGroup" formControlName="BloodGroup">
                <option *ngFor="let group of BloodGroup" [value]="group.name">
                  {{group.name}}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <label>Address</label>
              <input type="text" name="Address" value="" size="25" [(ngModel)]="familyList.Address" formControlName="Address" />
            </div>
          </div>
          <div class="col-md-12 p-0">
            <button *ngIf="!updateFamilyDetails" class="btn btn-success" style="float: right;" value="Submit" (click)="AddFamilyList(familyListForm)">Add Family Details</button>
            <button *ngIf="updateFamilyDetails" class="btn btn-success" style="float: right;" value="Submit" (click)="UpdateFamilyList(familyListForm)">Update Family Details</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<footer *ngIf="isPatEditor == false"></footer>