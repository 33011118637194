import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  templateUrl: './app.View_OP_summary.html'
})

  export class ViewOPSummaryComponent {

    public token: any;
    public opsummarydata : any;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,public authenticationService: AuthenticationService,
    public patservice: PatientService,){

      this.token = this.authenticationService.currentUserValue;
     // this.getOPSummarydata();
    }

    // public getOPSummarydata() {
    //   this.patservice.getOPSummary(this.token.PatientIdentifier,this.data.visitId). subscribe(res => this.Success(res),
    //   res => this.Error(res)); }
  
    //   Success(res){
    //    this.opsummarydata = res;
    
    //     }
   
      
    //   Error(res){
  
    //   }
   
    Download(){
      var data = document.getElementById('convertToPdf');
      html2canvas(data).then(canvas => {
        var imgWidth = 190;
        var imgHeight = canvas.height * imgWidth / canvas.width;
  
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
        var position = 10;
        pdf.addImage(contentDataURL, 'PNG', 10, position, imgWidth, imgHeight);
        //pdf.addPage();
        pdf.save('OPD_Summary.pdf'); // Generated PDF  
  
      });
    }
  }
