import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { PatientService } from '../../services/patient/patient.service';
import { Patient } from '../../models/patient/patient.model';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import * as moment from 'moment/moment';
import { Problem } from '../../models/problem/problem.model';
import { HospitalService } from '../../services/hospital/hospital.service';
import { Global } from '../../app.global';
import { NotificationService } from 'src/app/services/notification.service';
import { SelectControlValueAccessor } from '@angular/forms';
import { PopUpDialogComponent } from 'src/app/components/common/popup/pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { Visit } from 'src/app/models/visit/visit.model';
import { RescheduleComponent } from 'src/app/Doctor/doctor_reschedule/app.doctor_reschedule';
import { ViewOPSummaryComponent } from 'src/app/Doctor/OP_Summary/app.View_OP_summary';
import { SchedulingService } from '../../services/scheduling/scheduling.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  templateUrl: './app.patient_history.html',
  styleUrls: ['./app.patient_history.css']

})
export class PatientHistoryComponent {
  public config: any;
  public patInfo: Patient = new Patient();
  // public patList:Array<Patient>= new Array<Patient>();
  patList: any;
  filteredpathistoryList: any;
  respatlist: any;
  filterPatList: any;
  path: any;
  patientFile: any;
  filekind: any;
  fileExtension: any;
  Filetype: any;
  public viewList: any;
  public noteList: any;
  public token: any;
  public prob: boolean = false;
  public hospitalId: any;
  p: number = 1; 
  collection = { count: 0, data: [] };
  public allAppointments : Boolean = false;
  fileList: any;
  visitId: string;
  public showViewNotes: boolean = false;
  public showPrescription: boolean = false;


  nepaliDate = moment();
  currentDateWithTime: any;
  public Prescription: boolean = true;
  public Report: boolean = true;
  public OPSummary: boolean = true;
  public Cancel: boolean = false;
  public Reschedule: boolean = false;
  public Payment: boolean = false;


  isCancelled: boolean;

  visitObj: Visit = new Visit();
  public patRecordFile: any;
  public showReport: boolean = false;
  public currentTime: any;
  showOpSummaryBtn: boolean = false;
  public safePath: any;

  constructor(public routing: Router, private route: ActivatedRoute,
    public patservice: PatientService,
    public alertService: AlertService, public authenticationService: AuthenticationService, public schedulingService: SchedulingService,
    public hospitalService: HospitalService, public global: Global, private notifyService: NotificationService, public dialog: MatDialog, 
    private sanitizer: DomSanitizer) {
    this.token = this.authenticationService.currentUserValue;
    this.AppointmentHistory();
    this.hospitalId = this.route.snapshot.queryParamMap.get('id');

  }
  pageChanged(event) {
    this.config.currentPage = event;

  }
  public AppointmentHistory() {
    this.patservice.getPatHistory(this.token.PatientIdentifier).subscribe(res => this.Success(res),
      res => this.Error(res));
  }

  Success(res) {
    if (res && res.IsSuccess && res.Results) {
    this.respatlist = res?.Results;
    for (var i = 0; i < this.respatlist.length; i++) {
      var currentNepaliDateTime = this.nepaliDate.tz("Asia/Kathmandu").format('YYYY/MM/DD HH:mm z');
      this.currentDateWithTime = moment(currentNepaliDateTime, 'YYYY/MM/DD hh:mm').format('YYYY/MM/DD');
      this.respatlist[i].VisitDate = moment(this.respatlist[i].VisitDate).format('YYYY/MM/DD');
      if (this.respatlist[i].Status != 'cancelled') {
        if (this.respatlist[i].BookingStartTime) {
          var bookingStartTime = this.respatlist[i].BookingStartTime.split('-').slice(0, -1).join('-');
          this.respatlist[i].BookingStartTime = moment(bookingStartTime, 'HH:mm A').format('HH:mm');
        }
        else{
          this.alertService.error("Appointment not found with that booking time!");
        }
      }
      else {
        if (this.respatlist[i].CancelledBookingTime) {
          var cancelledbookingTime = this.respatlist[i].CancelledBookingTime.split('-').slice(0, -1).join('-');
          this.respatlist[i].CancelledBookingTime = moment(cancelledbookingTime, 'HH:mm A').format('HH:mm');
        }else{
          this.alertService.error("Appointment not found with that booking time!");
        }        
      }

      this.currentTime = moment(currentNepaliDateTime, 'YYYY/MM/DD HH:mm').format('HH:mm');
    }
  }else{
    this.notifyService.showError("","Sorry, No appointments found");
  }
    this.showUpcominglist();
  }

  Error(res) {
    this.notifyService.showError("","Sorry, No appointments found");
  }

  showUnpaidlist() {
    this.Prescription = true;
    this.Report = true;
    this.OPSummary = true;
    this.Payment = true;
    this.Reschedule = true;
    this.Cancel = true;
    this.filteredpathistoryList = this.respatlist;
    this.filteredpathistoryList = this.filteredpathistoryList.filter(p => p.PaymentStatus == 'unpaid')
    this.filterlist();
  }

  showPaidlist() {
    this.Prescription = true;
    this.Report = true;
    this.OPSummary = true;
    this.Payment = false;
    this.Reschedule = true;
    this.Cancel = true;
    this.filteredpathistoryList = this.respatlist;
    this.filteredpathistoryList = this.filteredpathistoryList.filter(p => p.PaymentStatus == 'paid' || p.PaymentStatus == 'free')
    this.filterlist();
  }

  showPastlist() {
    this.Prescription = true;

    this.Report = true;
    this.OPSummary = true;
    this.Payment = false;
    this.Reschedule = false;
    this.Cancel = false;
    this.filteredpathistoryList = this.respatlist;

    this.filteredpathistoryList = this.filteredpathistoryList.filter(p => p.VisitDate <= this.currentDateWithTime)
    for (var i = 0; i < this.filteredpathistoryList.length; i++) {
      if (this.filteredpathistoryList[i].Status != 'cancelled') {
        if (this.filteredpathistoryList[i].VisitDate == this.currentDateWithTime && this.filteredpathistoryList[i].BookingStartTime > this.currentTime) {
          this.filteredpathistoryList.splice(i, 1);
          i--;
        }
      }
      else {
        if (this.filteredpathistoryList[i].VisitDate == this.currentDateWithTime && this.filteredpathistoryList[i].CancelledBookingTime > this.currentTime) {
          this.filteredpathistoryList.splice(i, 1);
          i--;
        }
      }
    }
    if(this.filteredpathistoryList.length > 0){
      this.allAppointments = true;
    }
    else{
      this.allAppointments = false;
    }
    if(this.filteredpathistoryList.length > 0){
      this.allAppointments = true;
    }
    else{
      this.allAppointments = false;
    }
    this.filterlist();
  }

  showUpcominglist() {
    this.Prescription = false;
    this.Report = false;
    this.OPSummary = false;
    this.Payment = true;
    this.Reschedule = true;
    this.Cancel = true;
    this.filteredpathistoryList = this.respatlist;

    this.filteredpathistoryList = this.filteredpathistoryList.filter(p => p.VisitDate >= this.currentDateWithTime)
    for (var i = 0; i < this.filteredpathistoryList.length; i++) {
      if (this.filteredpathistoryList[i].Status != 'cancelled' || this.filteredpathistoryList[i].Status != 'deleted') {
        if (this.filteredpathistoryList[i].VisitDate == this.currentDateWithTime && this.filteredpathistoryList[i].BookingStartTime < this.currentTime) {
          this.filteredpathistoryList.splice(i, 1);
          i--;
        }
      }
      else {
        if (this.filteredpathistoryList[i].VisitDate == this.currentDateWithTime && this.filteredpathistoryList[i].CancelledBookingTime < this.currentTime) {
          this.filteredpathistoryList.splice(i, 1);
          i--;
        }
      }
    }
    if(this.filteredpathistoryList.length > 0){
      this.allAppointments = true;
    }
    else{
      this.allAppointments = false;
    }
    this.filterlist();
  }
  filterlist() {

    this.patList = this.filteredpathistoryList;
    this.collection = { count: this.patList.length, data: [] }
    for (var i = 0; i < this.collection.count; i++) {
      if (this.patList[i].Medications != null || this.patList[i].History != null) {
        this.showOpSummaryBtn = this.patList[i].ShowViewOPSummary = true;
      }
      else {
        this.showOpSummaryBtn = this.patList[i].ShowViewOPSummary = false;
      }
      this.collection.data.push(
        {
          id: i + 1,
          VisitId: this.patList[i].VisitId,
          VisitDate: this.patList[i].VisitDate,
          DepartmentName: this.patList[i].DepartmentName,
          DoctorName: this.patList[i].DoctorName,
          HospitalName: this.patList[i].HospitalName,
          VisitType: this.patList[i].VisitType,
          PaymentStatus: this.patList[i].PaymentStatus,
          NMC: this.patList[i].NMC,
          SchedulingId: this.patList[i].SchedulingId,
          DepartmentId: this.patList[i].DepartmentId,
          BookingTime: this.patList[i].BookingTime,
          Status: this.patList[i].Status,
          BookingType: this.patList[i].BookingType,
          DoctorId: this.patList[i].ProviderId,
          HospitalId: this.patList[i].HospitalId,
          Charge: this.patList[i].Charge,
          ShowViewOPSummary: this.showOpSummaryBtn,
          PaymentMethod: this.patList[i].PaymentMethod
        }
      );
    }

  }


  public ViewNotes(visitId) {
    this.showViewNotes = true;
    this.patservice.getPatientHistory(this.token.PatientIdentifier).subscribe(res => {
      this.viewList = res;
      this.noteList = res;
      this.viewList = this.viewList.filter(s => s.VisitId == visitId);
      for (var i = 0; i < this.viewList.length; i++) {

        this.viewList[i].VisitDate = moment(this.viewList[i].VisitDate).format('lll');
      }
      for (let index = 0; index < this.viewList.length; index++) {
        const element = this.viewList[index];
        if (element == true) {
          this.prob = false;
        }
      }

    },
      error => {
        this.alertService.error(error);
      });
  }
  ViewPrescription(id) {
    this.visitId = id;
    this.filterPatList = new Array<any>();
    this.filterPatList = JSON.parse(JSON.stringify(this.patList));//deepcopy
    this.filterPatList = this.filterPatList.filter(x => x.VisitId == this.visitId);
    this.patientFile = this.filterPatList[0].PatientFile;
    this.showPrescription = true;
    this.showReport = false;
    if (this.patientFile.length > 0) {
      this.path = this.patientFile[0].FilePath;
      this.fileExtension = this.patientFile[0].FileExtention;
      if (this.fileExtension == 'jpeg' || this.fileExtension == 'JPEG' || this.fileExtension == 'png' || this.fileExtension == 'PNG' || this.fileExtension == 'JPG' || this.fileExtension == 'jpg') {
        this.Filetype = "image";
      }
      if (this.fileExtension == 'pdf' || this.fileExtension == 'PDF') {
        this.Filetype = "pdf";
        this.safePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.path);
      }
      if (this.fileExtension == 'docx' || this.fileExtension == 'DOCX' || this.fileExtension == 'DOC' || this.fileExtension == 'doc') {
        this.Filetype = "docfile";
      }

    }
    else {
      this.showPrescription = false;
      this.notifyService.showError("Error!", " Could not found the Prescription file");
    }
  }

  Pay(data) {
    this.routing.navigate(['/PaymentSelection'], { queryParams: { vid: data.VisitId, charge: data.Charge, id: this.hospitalId } });
  }

  public CancelBooking(data) {
    if (data.PaymentStatus != "paid") {
      this.visitId = data.VisitId;
      this.visitObj.SchedulingId = data.SchedulingId;
      this.visitObj.BookingTime = data.BookingTime;
      this.patservice.cancelBooking(this.visitObj, this.visitId).subscribe(res => {
        this.notifyService.showSuccess('Booking cancelled !!', 'Success');
        this.AppointmentHistory();
      },
        error => {
          this.alertService.error(error);
          // this.loading = false;
        });

    } else {
      this.notifyService.showError("Paid Booking cannot be cancelled ", "Sorry");
    }
  }

  RescheduleAppointment(data) {
    const dialogRef = this.dialog.open(RescheduleComponent,
      { data: { docId: data.DoctorId, deptId: data.DepartmentId, hosId: data.HospitalId, visitId: data.VisitId, schedullingId: data.SchedulingId, bookingTime: data.BookingTime, appointmentType: data.VisitType }, width: '1000px', height: '1000px' }
    );
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      dialogRef.close();
      console.log(`Dialog result: ${result}`);
     // this.showUpcominglist(); //commented such that it displays the table in which patient initially was. This always rendered the table which was initially being fetched.
    });
  }

  ViewReport(id) {
    this.visitId = id;
    this.filterPatList = new Array<any>();
    this.filterPatList = JSON.parse(JSON.stringify(this.patList));//deepcopy
    this.filterPatList = this.filterPatList.filter(x => x.VisitId == this.visitId);
    this.patRecordFile = this.filterPatList[0].PatRecordFile;
    this.showReport = true;
    this.showPrescription = false;
    if (this.patRecordFile != null) {
      this.path = this.patRecordFile.FilePath;
      this.fileExtension = this.patRecordFile.FileExtension;
      if (this.fileExtension == 'jpeg' || this.fileExtension == 'JPEG' || this.fileExtension == 'png' || this.fileExtension == 'PNG' || this.fileExtension == 'JPG' || this.fileExtension == 'jpg') {
        this.Filetype = "image";
      }
      if (this.fileExtension == 'pdf' || this.fileExtension == 'PDF') {
        this.Filetype = "pdf";
      }
      if (this.fileExtension == 'docx' || this.fileExtension == 'DOCX' || this.fileExtension == 'DOC' || this.fileExtension == 'doc') {
        this.Filetype = "docfile";
      }

    }
    else {
      this.showReport = false;
      this.notifyService.showError("Error!", " Could not found the Record");
    }
  }

  ViewOPSummary(data) {
    this.showPrescription = false;
    this.showReport = false;
    this.patservice.getOPSummary(this.token.PatientIdentifier, data.VisitId).subscribe(res => this.Successsummary(res),
      res => this.ErrorSuccesssummary(res));
  }

  Successsummary(data) {
    if(data && data.IsSuccess && data.Results){
      var summaryData = data.Results;
      const dialogRef = this.dialog.open(ViewOPSummaryComponent,
        {
          data: {
            PatientId: summaryData.PatientId,
            PatientName: summaryData.PatientName,
            Age: summaryData.Age,
            Gender: summaryData.Gender,
            Address: summaryData.Address,
            ContactNumber: summaryData.ContactNumber,
            VisitDate: summaryData.VisitDate,
            BookingTime: summaryData.BookingTime,
            DepartmentName: summaryData.DepartmentName,
            DoctorName: summaryData.DoctorName,
            CreatedDate: summaryData.CreatedDate,
            Height: summaryData.Height,
            Weight: summaryData.Weight,
            Temperature: summaryData.Temperature,
            BMI: summaryData.BMI,
            BP: summaryData.BP,
            Bodypain: summaryData.Bodypain,
            PainScale: summaryData.PainScale,
            LongSignature: summaryData.LongSignature,
            NMC: summaryData.NMC,
            Medication: summaryData.Medication,
            TreatmentAdvice: summaryData.TreatmentAdvice,
            History: summaryData.History,
            ChiefComplaints: summaryData.ChiefComplaints,
            Investigation: summaryData.Investigation,
            FinalDiagnosis: summaryData.FinalDiagnosis,
            Remarks: summaryData.Remarks,
            medicationlist: summaryData.medicationlist,
            VisitId: summaryData.Visitid
          }, width: '1000px', height: '1000px'
        }
      );
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        dialogRef.close();
        console.log(`Dialog result: ${result}`);
        this.showPastlist(); // Updated so that patient is being redirected to the same table i.e Past Appointment table. 
      });
    }
  }


  ErrorSuccesssummary(res) {

  }
  showdata() {

  }
  openDialog(data) {
    const dialogRef = this.dialog.open(PopUpDialogComponent, {
      data: { msg: "fromAdmin" },
      width: '250px',
      height: '300',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isCancelled = result;
      if (this.isCancelled === true) {
        this.CancelBooking(data);
      }
      else{
        error => {
          this.alertService.error(error);
        };
      }
    });

  }

  Print() {
    let popupWinindow;
    var printContents = document.getElementById("Report").innerHTML;
    popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    let documentContent = '<html><head>';
    documentContent += '<link rel="stylesheet" type="text/css" />';
    documentContent += '</head>';
    documentContent += '<body onload="window.print()" style="margin:8px 0px 0px 50px !important;">' + printContents + '</body></html>'
    popupWinindow.document.write(documentContent);
    popupWinindow.document.close();
  }
  public BackDashBoard() {
    //this.updatePat = false;
    this.routing.navigate(['/PatDashboard'], { queryParams: { id: this.hospitalId } });
  }

  RemoveCompletely(visitId) {
    this.schedulingService.deleteVisit(visitId).subscribe(res => {
      this.notifyService.showSuccess('Sucess', 'Schedule Successfully Removed From List');
      window.location.reload();
    });
  }
}

