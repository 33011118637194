import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class LoaderService {
  _isloadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  numberOfHttpRequestLoading: number = 0;
  show() {
    this.numberOfHttpRequestLoading++;
    this._isloadingSubject.next(true);
  }
  hide() {
    this.numberOfHttpRequestLoading--;
    if (this.numberOfHttpRequestLoading == 0)
      this._isloadingSubject.next(false);
  }
  get isLoading() {
    return this._isloadingSubject;
  }
}
