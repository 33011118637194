import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Injectable({ providedIn: 'root' })
export class RoleGuardService implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (!this.auth.isUserAuthenticated)
      return false;

    // Retrieve the allowed roles from `route.data`.
    const allowedUserType = route.data['allowedUserType'];

    const typeOfLoggedInUser = this.auth.userType;
    return isUserInAllowedRoles();


    function isUserInAllowedRoles(): boolean {
      return allowedUserType.indexOf(typeOfLoggedInUser) !== -1;
    }
  }
}
