
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EndpointFactory } from '../endpoint-factory.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ContactUsEndpoint extends EndpointFactory {

  private readonly _GetlistofContactedUsrUrl: string = "/api/contactus/GetlistOfContactedUsr";
  private readonly _PostContactformdataUrl: string = "/api/contactus/PostContactformdata";
  
  


  constructor(http: HttpClient, injector: Injector) {
    super(http, injector);
  }

  GetlistOfContactedUsr<T>(): Observable<T>{
    let endpointUrl = `${this._GetlistofContactedUsrUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetlistOfContactedUsr()));
      }));
  }

  PostContactformdata<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this._PostContactformdataUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.PostContactformdata(userObject)));
      }));
  }
}
