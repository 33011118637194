
<style>
  .pay-d {
    background: #f3f3f3;
    padding: 15px;
    margin: auto;
    font-weight: 600;
    font-size: 14px;
    margin: 15px auto;
  }

  .card-info {
    margin-bottom: 10px;
  }

  .imark-logo {
    height: 51px;
    display: block;
    margin: -60px 40px 7px 295px;
  }

  .nabil-logo {
    height: 89px;
    display: block;
    margin: 5px 0;
  }

  .card-info label {
    display: block;
  }

  .card-info input {
    border-radius: 4px;
    width: 100%;
    border: 1px solid #aaa;
    padding: 5px 10px;
  }

  .card-field {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
  }

  .pay-d ul {
    padding-left: 0;
    font-weight: 500;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
  }

    .pay-d ul li {
      display: block;
      color: #666;
      width: 100%;
      margin: 5px auto;
      font-size: 14px;
    }

      .pay-d ul li span {
        font-weight: 600;
        color: #333;
        float: right
      }

        .pay-d ul li span.big {
          font-size: 16px;
          font-weight: bold;
          color: #000;
        }
</style>
<app></app>
<button [routerLink]="['/PaymentSelection']">Back </button>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-4 pay-d">
      <img src="assets/img/nabilbank.jpg" class="nabil-logo">
      <img src="assets/img/imark-powered.png" class="imark-logo">
      <ul>
        <li>Company<span></span></li>
        <li>Payment Id<span>3456765432</span></li>
        <li>Description<span>9840040040</span></li>
        <li>Amount<span class="big">100 NPR</span></li>
      </ul>
      <p>
        To pay for the order, enter your card details.
        Data communication is secured by TLS protocol.
      </p>
      <div class="card-field">
        <div class="row  card-info">
          <div class="col-md-7">
            <label for="quantity">Card Number:</label>
            <input type="number" id="cardnumber" name="cardnumber" min="1" max="20">
          </div>
          <div class="col-md-5">
            <label for="birthday">Expiry Date:</label>
            <input type="date" id="expirydate" name="expirydate">
          </div>
        </div>
        <div class="row  card-info">
          <div class="col-md-7">
            <label for="quantity">Card Holder Name:</label>
            <input type="text" id="cardholdername" name="cardholdername" min="5" max="30">
          </div>
          <div class="col-md-5">
            <label for="quantity">CVV:</label>
            <input type="text" id="cvv" name="cvv" min="5" max="10">
          </div>
        </div>
        <div class="row  card-info">
          <div class="col-md-12">
            <label for="quantity">Email:</label>
            <input type="email" id="email" name="email" min="5" max="10">
          </div>
        </div>
        <div class="row" style="margin-top: 25px;">
          <div class="col-md-12 text-center">
            <button type="button" (click)="Submit()" class="btn btn-primary">Ok</button>
            &nbsp;   &nbsp;   &nbsp;   &nbsp;
            <button type="button" class="btn btn-danger">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <!-- <div>
    Order Details:
    Company
    Payment Id : 3456765432
    Description : 9840040040
    Amount :100 NPR
  </div> -->
  <!--   <div>
      To pay for the order, enter your card details.
      Data communication is secured by TLS protocol.
    </div>
    <label for="quantity">Card Number:</label>
    <input type="number" id="cardnumber" name="cardnumber" min="1" max="20">
    <label for="birthday">Expiry Date:</label>
    <input type="date" id="expirydate" name="expirydate">

    <label for="quantity">Card Holder Name:</label>
    <input type="text" id="cardholdername" name="cardholdername" min="5" max="30">
    <label for="quantity">CVV:</label>
    <input type="text" id="cvv" name="cvv" min="5" max="10">

    <label for="quantity">Email:</label>
    <input type="email" id="email" name="email" min="5" max="10">


    <button type="button">Cancel</button>

    <button type="button">Ok</button>


  </div> -->
