import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IdToken } from '../../models/IdToken';
import { AuthenticationService } from '../../services/authentication.service';
import { LabReportUploadEndpointService } from '../../services/LabReportUpload/lab-report-upload-endpoint.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NgxExtendedPdfViewerService, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-patient-lab-reports',
  templateUrl: './app.patient_lab_reports.html',
  styleUrls: ['./app.patient_lab_reports.css']
})
export class PatientLabReportsComponent implements OnInit {
  token: IdToken;
  p = 1;
  hospitalId: string;
  collection = { data: [] }
  showView: boolean;
  title: any;
  fileName: any;
  filePath: any;
  fileExtension: any;
  Filetype: string;
  public safeUrl: any;

  constructor(public routing: Router, private route: ActivatedRoute, public labReportEndpoint: LabReportUploadEndpointService,
    public authenticationService: AuthenticationService,public notifyService: NotificationService, private pdfService: NgxExtendedPdfViewerService, public sanitizer: DomSanitizer) {
    this.token = this.authenticationService.currentUserValue;
    this.hospitalId = this.route.snapshot.queryParamMap.get('id');
  }

  ngOnInit() {
    this.labReportEndpoint.GetAllLabReports<any>()
      .subscribe(res => {
        if (res.success) {
          this.collection = res;
        }
      });
  }
  public BackDashBoard() {
    this.routing.navigate(['/PatDashboard'], { queryParams: { id: this.hospitalId } });
  }



  ViewFile(fileDetails, filePath, fileExt, fileName) {
    if (fileDetails && fileDetails.PatientFileId) {
      this.title = `${fileDetails.PatientName} (Lab Report)`;
      this.labReportEndpoint.GetLabReportPreview<any>(fileDetails.PatientFileId)
        .subscribe(res => {
          if (res.success) {
            if (res.data) {
              this.showView = true;
              const base64Result = res.data.base64Result;
              this.fileExtension = res.data.FileExtension;
              this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.filePath)
              if (this.isImageFile(this.fileExtension)) {
                this.Filetype = "image";
                this.filePath = this.sanitizeBase64Image(base64Result, this.fileExtension);
              } else if (this.isFileSupported(this.fileExtension)) {
                if (this.fileExtension.toLowerCase() === 'pdf') {
                  this.Filetype = "pdf";
                } else {
                  this.Filetype = "docfile";
                }
                this.filePath = this.sanitizeBase64File(base64Result, this.fileExtension);
              } else {
                this.notifyService.showError("Error", "Unsupported file type");
              }
            }
          }
        });
    } else {
      this.notifyService.showError("Error", "PatientFileId is required to Preview File");
    }
  }
  
  isImageFile(extension: string): boolean {
    const imageExtensions = ["jpeg", "jpg", "png"];
    return imageExtensions.includes(extension.toLowerCase());
  }
  
  isFileSupported(extension: string): boolean {
    const supportedExtensions = ["pdf", "docx", "doc"];
    return supportedExtensions.includes(extension.toLowerCase());
  }
  
  sanitizeBase64Image(base64String: string, extension: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/${extension};base64,${base64String}`);
  }
  
  sanitizeBase64File(base64String: string, extension: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/${extension};base64,${base64String}`);
  }  base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

  hide() {
    this.showView = false;
  }
}
