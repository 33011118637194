export class Departement{
    public DepartmentId: string;
    public DepartmentName:string;
    public DepartmentShortName:string;
    public Description:string;
    public IsActive:boolean;

}

export class HospitalDoctorMap {
  public HospitalDoctorMapId: string;
  public DepartmentId: string;
  public DoctorId: string;
  public HospitalId: string;
}
