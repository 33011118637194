<style>
  Important part */ .modal-dialog {
    overflow-y: initial !important
  }

  .modal-body {
    height: 100;
    border-style: groove;
  }

  .main-title {
    text-align: center;
    border-style: groove;
    text-align: center;
    padding: 6px;

  }

  table {
    border-style: groove;

  }

  */
</style>
<div class="modal" id="target1" tabindex="-1" role="dialog" [style.display]="editModal ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="card-title" style="width: 100%;">
          <!-- Update Hospital -->
          <button type="button" class="close" OPdata-dismiss="modal" (click)="hide()">&times;</button>
        </h1>
      </div>
      <div class="modal-body" style="margin: 25px; margin-top: 0" id="convertToPdf" #convertToPdf>
        <div style="text-align: center;">
          <h3><strong class="main-title">OPD Visit</strong></h3>
        </div>

        <div style="height: 190px; border-style: groove;">
          <table style=" border-style: none;" align="left">
            <tr>
              <td>Patient Id:</td>
              <td>{{OPdata.PatientId}}</td>
            </tr>

            <tr>
              <td>Name:</td>
              <td>{{OPdata.PatientName}}</td>
            </tr>
            <tr>
              <td>Age/Sex:</td>
              <td>{{OPdata.Age}}/{{OPdata.Gender}}</td>
            </tr>
            <tr>
              <td>Address:</td>
              <td>{{OPdata.Address}}</td>
            </tr>
            <tr>
              <td>contact Number:</td>
              <td>{{OPdata.ContactNumber}}</td>
            </tr>
          </table>
          <table style=" border-style: none;" align="right">
            <tr>
              <td>Visit Date and Time:</td>
              <td>{{OPdata.VisitDate | date:'mediumDate'}}:{{OPdata.BookingTime}}</td>
            </tr>

            <tr>
              <td>Department:</td>
              <td>{{OPdata.DepartmentName}}</td>
            </tr>
            <tr>
              <td>Doctor:</td>
              <td>{{OPdata.DoctorName}}</td>
            </tr>
          </table>
        </div>

        <div class="main-title">
          <div>
            <label>History:<span class="required-sign">*</span></label>
            <textarea type="text" name="date" style="  margin-left: 200px;" [(ngModel)]="Vistdata.Problem.History" class="m-0" required></textarea>
          </div>
          <div>
            <label>Chief Complaints:</label>
            <textarea type="text" name="date" style="  margin-left: 200px;" [(ngModel)]="Vistdata.Problem.ChiefComplaints" class="m-0"></textarea>
          </div>
        </div>
        <div class="main-title">
          <label>Investigations:</label>
          <textarea type="text" name="date" style="  margin-left: 200px;" [(ngModel)]="Vistdata.Problem.Investigation" class="m-0"></textarea>
        </div>
        <div class="main-title">
          <label>Medication:</label>
          <table class="table">
            <thead>
              <tr>
                <th>S.N</th>
                <th>Name of drugs</th>
                <th>Dose</th>
                <th>Frequency</th>
                <th>Remarks</th>
                <th></th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let med of medicationlist;let i = index;">
                <td> {{i+1}}</td>
                <td> <input type="text" name="date" [(ngModel)]="medicationlist[i].DrugName" class="m-0" /></td>
                <td> <input type="text" name="date" [(ngModel)]="medicationlist[i].Dose" class="m-0" /></td>
                <td> <input type="text" name="date" [(ngModel)]="medicationlist[i].Frequency" class="m-0" /></td>
                <td> <input type="text" name="date" [(ngModel)]="medicationlist[i].Remarks" class="m-0" /></td>
                <td>
                  <a (click)="addRow(i)" class="round-btn btn-primary text-white">+</a>
                  <a style="margin-left: 10px" (click)="deleteRow(i)" class="round-btn btn-danger text-white">x</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="height: 248px; border-style: groove;">
          <label style="display: block;font-size: x-large;text-align: center;">Vitals:</label>
          <table style=" border-style: none;margin-left: 120px;" align="left">
            <!-- <tr>
                      <td>Recorded On:</td>
                        <td>{{OPdata.CreatedDate | date:'mediumDate'}}</td>
                  </tr> -->
            <tr>
              <td>Height:</td>
              <td><input type="text" name="date" [(ngModel)]="Vistdata.Problem.Height" class="m-0" /></td>
            </tr>

            <tr>
              <td>Weight:</td>
              <td><input type="text" name="date" [(ngModel)]="Vistdata.Problem.Weight" class="m-0" /></td>
            </tr>
            <tr>
              <td>Tempreture:</td>
              <td><input type="text" name="date" [(ngModel)]="Vistdata.Problem.Temperature" class="m-0" /></td>
            </tr>


          </table>
          <table style=" border-style: none; margin-right: 120px;" align="right">
            <tr>
              <td>BMI:</td>
              <td><input type="text" name="date" [(ngModel)]="Vistdata.Problem.BMI" class="m-0" /></td>
            </tr>
            <tr>
              <td>Blood Pressure:</td>
              <td><input type="text" name="date" [(ngModel)]="Vistdata.Problem.BP" class="m-0" /></td>
            </tr>

            <tr>
              <td>Body Pain:</td>
              <td><input type="text" name="date" [(ngModel)]="Vistdata.Problem.Bodypain" class="m-0" /></td>
            </tr>
            <tr>
              <td>Body Scale:</td>
              <td><input type="text" name="date" [(ngModel)]="Vistdata.Problem.Painscale" class="m-0" /></td>
            </tr>
          </table>
        </div>

        <div class="main-title">
          <label>Remarks: <textarea type="text" name="date" [(ngModel)]="Vistdata.Problem.Remarks" class="m-0"></textarea></label>
        </div>
        <div class="main-title">
          <label>Final Diagnosis: <textarea type="text" name="date" [(ngModel)]="Vistdata.Problem.FinalDiagnosis" class="m-0"></textarea></label>
        </div>
        <div class="main-title">
          <div class="col-md-12">
            <!-- <label class="col-md-3">Signature:<input type="text" name="date" [(ngModel)]="OPdata.LongSignature"   class="m-0" /></label> -->
            <label class="col-md-3">Signature:{{OPdata.LongSignature}}</label>
            <label class="col-md-3">Name:{{OPdata.DoctorName}}</label>
            <label class="col-md-3">NMC:{{OPdata.NMC}}</label>
            <label class="col-md-3">Department:{{OPdata.DepartmentName}}</label>
          </div>
        </div>



      </div>
      <button style="margin-left: 40%; margin-right: 44%;" (click)="AddOPdSUmmary()">Submit</button>
      <!-- <div class="modal-body" style="background-color:snow;">
        <div class="info-heading">
          <form #Form="ngForm" (ngSubmit)="UpdateHospital(Form)">
            <table>
              <tr class="3-cols">
                <td>
                  <label>Hospital Name<span class="required-sign" >*</span></label>
                  <input type="text" name="HospitalName" value="" [(ngModel)]="getHospitalInfo.HospitalName" size="25" required #HospitalName="ngModel"
                   />
                </td>
                <td>
                  <label>Hospital Code<span class="required-sign" >*</span> </label>
                  <input type="text" name="HospitalCode" value="" size="25" [(ngModel)]="getHospitalInfo.HospitalCode" required #HospitalCode="ngModel"/>
                </td>
                <td>
                  <label>Location<span class="required-sign" >*</span> </label>
                  <input type="text" name="Location" value="" size="25" [(ngModel)]="getHospitalInfo.Location" required #Location="ngModel"
                     />
                </td>
              </tr>
              <tr class="3-cols">
                <td >
                  <p>
                    <input [(ngModel)]="getHospitalInfo.IsActive"  [value]="getHospitalInfo.IsActive"  type="checkbox" name="IsActive1" id="IsActive1"
                     />
                      <label for="IsActive1">Is Active</label>
                  </p>
                </td>
                <td>
                  <p>
                    <input [(ngModel)]="getHospitalInfo.PaymentEnable"   [value]="getHospitalInfo.PaymentEnable" type="checkbox" name="Payment1" id="Payment1"
                    />
                    <label for="Payment1">Is Payment Enabled</label>
                  </p>
                </td>
                <td *ngIf="edit" >
                  <input type="file" #file placeholder="Choose file" (change)="uploadEdit(file.files)" name="fileName"
                    [(ngModel)]="getHospitalInfo.ImagePath" [ngModelOptions]="{standalone: true}"
                    style="display:none;">
                  <button type="button" class="btn btn-primary d-inline" (click)="file.click()"
                    style="font-size: 14px; margin-top: 25px"><i class="fa fa-upload"></i>Upload Image</button>
                  <div class="col-md-10 d-inline">
                    <span class="upload" *ngIf="progress > 0" style="font-size: 11px;">
                      {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message" style="font-size: 11px;">
                      {{message}}
                    </span>
                  </div>
                </td>
              </tr>
            </table>
            <div class="modal-footer">
              <button class="btn btn-success">Update</button>
            </div>
          </form>
        </div>
      </div> -->
    </div>
  </div>
</div>