import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { PatientService } from '../../services/patient/patient.service';
import * as moment from 'moment/moment'
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  templateUrl: './app.booking_home.html'
})
export class PatientBookingHomeComponent {
  public patBook: boolean = false;
  public bookList: boolean = false;
  public token: any;
  public patBookList: any;
  public HospitalId: any;
  constructor(public routing: Router, public patService: PatientService, public route: ActivatedRoute,
    public alertService: AlertService, public authService: AuthenticationService) {
    this.HospitalId = this.route.snapshot.queryParamMap.get('id');
  
    this.token = this.authService.currentUserValue;
  }
  public BookNewVisit() {
    this.patBook = true;
    this.routing.navigate(['/PatBookAppointment']);
  }

  public MyBookList() {
    this.bookList = true;
    this.routing.navigate(['/PatDashboard/PatBookHome/PatBookList']);

  }
  public Payment() {
    this.patBook = true;
    this.routing.navigate(['/PatDashboard/PaymentSelection']);
  }
  public MyPaidBooking() {
    this.patBook = true;
    this.routing.navigate(['/PatDashboard/PatBookHome/PaidBookedList']);
  }

}
