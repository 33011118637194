<meta name="viewport" content="width=device-width, initial-scale=1">
<style>
  .tem-action {
    padding-left: 0px;
    margin: 25px auto;
    text-align: center;
  }

    .tem-action li {
      list-style: none;
      display: inline-block;
      margin: auto 5px;
    }

  .control-label {
    font-size: 13px;
  }

  .form-control {
    font-size: 13px;
  }

  */
  /*div.col-md-7 {
    padding: 0px;
  }

  div.col-md-5 {
    padding: 0px;
  }

  div.col-md-4 {
    padding: 0px;
  }

  div.col-md-3 {
    padding: 0px;
  }*/
  .mt-checkbox {
    font-size: 13px;
  }

  div.col-xs-12 {
    padding-left: 10px;
    margin-left: 25px;
    margin-right: 15px;
  }

  /*This class for background border red */
  .bg-on {
    font-weight: bold;
    border: solid 1px red;
  }

  /*This class for */
  .bg-off {
    font-weight: bold;
  }

  #bookappttable {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    background-color: #66e0ff;
    width: 100%;
  }

    #bookappttable th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #00b3b3;
      color: white;
    }

    #bookappttable tr:nth-child(even) {
      background-color: #ccffff
    }

    #bookappttable tr:hover {
      background-color: #e6ffff
    }
  /*New stylesheet*/

  /*.payment-section {
    border-radius: 10px;
    width: 60%;
    margin: 50px auto;
    border: 1px solid #eee;
    padding: 25px;
    box-shadow: 0px 0px 20px #eee;
  }*/

  .select-status {
    display: contents;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    flex-direction: row;
  }

  .one-third {
    display: inline-block;
    width: 29%;
    margin: 10px 10px;
    background: #ebecee;
    padding: 15px;
    border-radius: 10px;
  }

  .select-status .one-third label {
    margin-bottom: 5px;
    display: block;
    color: #333;
    font-weight: 600;
  }

  .select-status-main .select-title {
    position: absolute;
    top: -15px;
    left: 15px;
    background: #fff;
    border: 1px solid #ccc;
    padding: 4px 15px;
    border-radius: 23px;
    font-weight: 700;
    letter-spacing: 0.3px
  }

  .select-status-main {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 7px;
    padding: 20px 0px;
    padding-bottom: 10px;
    margin: 30px auto;
    background: #fff;
  }



    .select-status-main .select-status .radio-button {
      margin-bottom: 1rem;
    }

    .select-status-main .select-status .radio-button__label-wrapper {
      border-radius: 50%;
      cursor: pointer;
      display: block;
      padding-left: 2.5rem;
      position: relative;
      top: 0.25rem;
    }

      .select-status-main .select-status .radio-button__label-wrapper:hover .radio-button__input ~ .radio-button__custom-indicator {
        border-color: black;
      }

    .select-status-main .select-status .radio-button__label-title {
      margin-bottom: 0.25rem;
      font-size: 18px;
      font-weight: bold;
      margin-top: 0;
      color: #111;
    }

    .select-status-main .select-status .radio-button__label-subtext {
      display: block;
      font-size: 0.85rem;
      font-weight: 600;
      color: #666;
    }

    .select-status-main .select-status .radio-button__custom-indicator {
      background-color: white;
      border: 1px solid #999;
      border-radius: 50%;
      height: 24px;
      left: 0;
      position: absolute;
      top: 0;
      width: 24px;
    }

      .select-status-main .select-status .radio-button__custom-indicator::after {
        background-color: mediumseagreen;
        border-radius: 50%;
        content: "";
        display: none;
        height: 16px;
        left: 12%;
        position: absolute;
        top: 12%;
        width: 16px;
      }

    .select-status-main .select-status .radio-button__input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

      .select-status-main .select-status .radio-button__input:focus ~ .radio-button__custom-indicator {
        border-color: black;
      }

      .select-status-main .select-status .radio-button__input:checked ~ .radio-button__custom-indicator::after {
        display: block;
      }

      .select-status-main .select-status .radio-button__input:disabled ~ .radio-button__custom-indicator {
        opacity: 0.6;
        pointer-events: none;
      }

  .one-one.ml-42 {
    margin-left: 0px;
  }

  input[type="text"], input[type="password"], input[type="email"], select, input[type="number"] {
    height: 30px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #bbb;
    background: #fff;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 7px;
    display: block;
    max-width: 100%;
    padding: 0 2px;
  }

  .cc-selector input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .visa {
    background-image: url("./assets/img/visacard.jpg");
  }

  .mastercard {
    background-image: url("./assets/img/mastercard.jpg");
  }

  .paypal {
    background-image: url("./assets/img/paypal.jpg");
  }

  .american-express {
    background-image: url("./assets/img/americanexpress.jpg");
  }

  .khalti {
    background-image: url("./assets/img/khalti.png");
  }

  .cc-selector input:active + .drinkcard-cc {
    opacity: .5;
  }

  .cc-selector input:checked + .drinkcard-cc {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  .drinkcard-cc {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block !important;
    width: 50px;
    height: 50px;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    background-color: transparent;
    background-position: center;
    margin-right: 7px;
    background-size: contain;
    align-items: center;
  }

    .drinkcard-cc:hover {
      -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
      -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
      filter: brightness(1.2) grayscale(.5) opacity(.9);
    }

  .payment-section.npl-pay {
    border-radius: 10px;
    width: 70%;
    margin: 50px auto;
    border: none;
    padding: 0;
    box-shadow: none;
  }

  .payment-logo {
    height: 50px;
    display: block;
    margin: 5px 0;
    background-color: #fff;
  }

  .payment-info-card {
    background: #009688;
    padding: 10px 15px;
    color: #fff;
    margin: 25px 20px;
    border-radius: 10px;
  }

    .payment-info-card p.small {
      font-size: 70%;
      margin-top: -10px;
      margin-bottom: 10px;
    }

    .payment-info-card p {
      margin-bottom: 5px;
      line-height: 7px;
      color: #fff;
      font-weight: 600;
    }

      .payment-info-card p label {
        background: #fff;
        line-height: initial;
        color: #009688;
        padding: 5px 15px;
        font-weight: 900;
        margin-left: 4px;
        border-radius: 25px;
        font-size: 16px;
      }

  @media (max-width: 768px) {
    .payment-section {
      width: 90%;
      padding: 0;
    }

    .select-status {
      flex-direction: column;
    }

      .select-status .one-third {
        width: 90%;
      }
  }

  /*New Stylesheet End*/
</style>

<!-- <div>
  <li class="d-inline mr-2"><button *ngIf="!patBook"  (click)="BookHome()">Back To Book</button></li>
</div> -->
<app></app>
<bookingnavigator></bookingnavigator>
<!--<button [routerLink]="['/PatDashboard']" class="back-btn-doc-room">Back To DashBoard </button>
<div class="payment-section" style="margin-top: 0">
  <ul class="book-actions p-0" style="margin-bottom: 0">
    <li class="d-inline mr-2">
      <button (click)="BookNewVisit()" class="btn btn-primary active-btn">
        <i class="fa fa-calendar"></i>Book New Visit
      </button>
    </li>
    <li class="d-inline mr-2">
      <button (click)="MyBookList()" class="btn btn-info">
        <i class="fa fa-list"></i>My
        Booking List
      </button>
    </li>
    <li class="d-inline mr-2">
      <button (click)="MyPaidBooking()" class="btn btn-info">
        <i class="fa fa-calendar-o"></i>My Paid Booking
      </button>
    </li>

  </ul>
</div>-->

<div class="payment-section">
  <div class="full-width">
    <div class="select-status-main">
      <span class="select-title"><i class="fa fa-calendar"></i>&nbsp;&nbsp;Payment Method</span>
      <div class="payment-info-card">
        <p>Amount to be paid for this appointment: <label for="">{{this.charge}}</label></p>
        <p class="small">Please select payment method below to proceed.</p>
      </div>
      <div class="select-status">
        <div class="one-third">
          <h3 class="radio-button__label-title">
            Esewa

          </h3>
          <span class="radio-button__label-subtext">
            Click to make payment via your Esewa account
          </span>
          <img src="assets/img/esewa.jpg" class="payment-logo">
          <div class="print-action">
            <input type="button" value="Pay" (click)="PayThroughEsewa()">
          </div>
        </div>
        <!-------------Khalti---------------->
        <div class="one-third">
          <h3 class="radio-button__label-title">
            Khalti

          </h3>
          <span class="radio-button__label-subtext">
            Click to make payment via your Khalti account
          </span>
          <img src="assets/img/khalti.png" class="payment-logo">

          <div class="print-action">
            <input type="button" value="Pay" (click)="PayThroughKhalti()">
          </div>
        </div>
        <!-------------Khalti--------------
        <!-------------Debit/Credit/Prepaid Cards---------------->
        <div class="one-third">
          <h3 class="radio-button__label-title">
            Debit/Credit/Prepaid Cards

          </h3>
          <span class="radio-button__label-subtext">
            Click to make payment by your Card (Debit/Credit/Prepaid)
          </span>
          <img src="assets/img/nabil.png" class="payment-logo">

          <div class="print-action">
            <input type="button" value="Pay" (click)="PayThroughNabil()">
          </div>
        </div>

        <div class="one-third">
          <h3 class="radio-button__label-title">
            Connect IPS

          </h3>
          <span class="radio-button__label-subtext">
            Click to make payment via Connect IPS
          </span>
          <img src="assets/img/ConnectIPSlogo.png" class="payment-logo">

          <div class="print-action">
            <input type="button" value="Pay" (click)="PayThroughConnectIPS()">
          </div>
        </div>

        <div *ngIf="showpaybyVisiting" class="one-third">
          <h3 class="radio-button__label-title">


          </h3>
          <span class="radio-button__label-subtext">
           Pay By Visiting In Hospital
          </span>
          <!-- <img src="assets/img/esewa.jpg" class="payment-logo"> -->
          <div class="print-action">
            <input type="button" value="Click Here" (click)="paybyVisiting()">
          </div>
        </div>
        <!-------------Debit/Credit/Prepaid Cards---------------->
        <!--<div class="one-third">
          <h3 class="radio-button__label-title">
            Credit/Debit Card
          </h3>
          <span class="radio-button__label-subtext">
            Click to make payment via your credit card
          </span>
          <div class="one-one ml-42">
            <div class="select-card">
              <div class="cc-selector">
                <input id="visa" type="radio" name="credit-card" value="visa" />
                <label class="drinkcard-cc visa" for="visa"></label>
                <input id="mastercard" type="radio" name="credit-card" value="mastercard" />
                <label class="drinkcard-cc mastercard" for="mastercard"></label>
                <input id="paypal" type="radio" name="credit-card" value="paypal" />
                <label class="drinkcard-cc paypal" for="paypal"></label>
                <input id="american-express" type="radio" name="credit-card" value="american-express" />
                <label class="drinkcard-cc american-express" for="american-express"></label>
              </div>
            </div>
            <div class="print-action">
              <button [disabled]="IsCardPayButtonDisabled" (click)="PayThroughCreditCard()">Pay  <i class="fa fa-spinner fa-spin" *ngIf="IsCardPayButtonDisabled"></i></button>
              </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</div>

<!--<footer></footer>-->


<div class="row">
  <div class="col-lg-4 col-md-12 col-sm-12">
    <p class="copyright">
      Copyright &copy; 2020 <a href="{{websiteUrl}}" target="_blank">Danphe Telehealth<img src="~/../assets/img/logo-cap.png" class="f-img" /></a>
    </p>
  </div>
  <div class="col-lg-2 col-md-12 col-sm-12 text-center">
    <ul class="social-icons">
      <li class="d-inline"><a href="https://www.facebook.com/dignityinternatinal" target="_blank"><i class="fa fa-facebook"></i></a></li>
      <li class="d-inline"><a href="https://www.facebook.com/DapheHealth/" target="_blank"><i class="fa fa-twitter"></i></a></li>
      <li class="d-inline"><a href="https://www.facebook.com/DapheHealth/" target="_blank"><i class="fa fa-instagram"></i></a></li>
    </ul>
  </div>
  <div class="col-lg-2 col-md-12 col-sm-12 text-right text-sm-center">
    <p class="copyright">
       <img src="~/../assets/img/footer.png" class="f-img" />
    </p>
  </div>
  <div class="col-lg-4 col-md-12 col-sm-12 text-right text-sm-center">
    <p class="copyright">
      Powered by <a href="https://imarkdigital.com/" target="_blank">Imark<img src="~/../assets/img/imark-powered.png" class="f-img" /></a>
    </p>
  </div>
</div>






