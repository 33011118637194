import { Component } from '@angular/core';
import { Router } from '@angular/router';


import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user/user.model';
import { environment } from '../../../environments/environment';

@Component({ selector: 'footer', templateUrl: 'footer.component.html' })
export class FooterComponent {
  websiteUrl = environment.url;
  currentUser: User;
  public token: any;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.token = this.authenticationService.currentUserValue;
  }

}
