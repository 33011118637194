
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EndpointFactory } from '../endpoint-factory.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class DoctorEndpoint extends EndpointFactory {

  private readonly _doctorRegisterUrl: string = "/api/account/registerDoctor";
  private readonly _patientRegisterUrl: string = "/api/account/registerPatient";
  private readonly _hosdocListUrl: string = "/api/doctor/getHosDocList";
  private readonly _doctorLoginUrl: string = "/api/account/login";
  private readonly _doctorUpdateUrl: string = "/api/doctor/updateDoctor";
  private readonly _doctorDetailsUrl: string = "/api/doctor/getDetails";
  private readonly _doctorUrl: string = "/api/doctor/getDoc";
  private readonly _doctorhospitalDetailsUrl: string = "/api/doctor/getDoctorHospital";
  private readonly _patientListoFSameProvider: string = "/api/doctor/getPatientListByProviderId";
  private readonly _patientListforStatusChange: string = "/api/doctor/listofPatientForStatusUpdateByProviderId";
  private readonly _availableDoctorlistUrl: string = "/api/doctor/GetAvailableDoctorsList";
  private readonly _doctorlistUrl: string = "/api/doctor/getList";

  private readonly _patientList: string = "/api/doctor/getPatientList";
  private readonly _patientListbyAdmin: string = "/api/doctor/getPatientListByAdmin";
  private readonly _cancelledAppointmmentpatientListbyAdmin: string = "/api/doctor/getCancelledAppointmentPatientListByAdmin";
  private readonly _appointmentsBookedOverPhone: string = "/api/doctor/getAppointmentsBookedOverPhone";
  private readonly _departmentlistUrl: string = "/api/doctor/getDeptList";
  private readonly _doctorDeleteUrl: string = "/api/account/deleteDoctor";
  private readonly _hospitalMapdeleteUrl: string = "/api/doctor/deleteHospitalMap";
  private readonly _hosdocUpdateUrl: string = "/api/doctor/updateHosDocMap";
  private readonly _qualificationUpdateUrl: string = "/api/doctor/updateQualification";
  private readonly _deactivateDocUrl: string = "/api/doctor/deactivate";
  private readonly _doctorNameUrl: string = "/api/doctor/getDocName";
  private readonly _uploadPrescriptionUrl: string = "/api/doctor/UploadPrescription";
  private readonly _hosdocUrl: string = "/api/doctor/GetDoctorMapDetail";
  private readonly _doctorMapListUrl: string = "/api/doctor/getDocMapList";
  private readonly _doctorListByDepartmentUrl: string = "/api/doctor/DoctorByDepartment?DepartmentId=";



  constructor(http: HttpClient, injector: Injector) {
    super(http, injector);
  }

  getDoctorByIdEndpoint<T>(doctorId: string): Observable<T> {
    let endpointUrl = `${this._doctorDetailsUrl}/${doctorId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDoctorByIdEndpoint(doctorId)));
      }));
  }

  getDoctorNameByIdEndpoint<T>(doctorId: string): Observable<T> {
    let endpointUrl = `${this._doctorNameUrl}/${doctorId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDoctorNameByIdEndpoint(doctorId)));
      }));
  }
  getDocByIdEndpoint<T>(doctorId: string, hospitalId: string, appointmentType: string): Observable<T> {
    let endpointUrl = `${this._doctorUrl}/${doctorId} /${hospitalId}/${appointmentType}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDocByIdEndpoint(doctorId, hospitalId, appointmentType)));
      }));
  }

  getDoctorHospitalByIdEndpoint<T>(doctorId: string): Observable<T> {
    let endpointUrl = `${this._doctorhospitalDetailsUrl}/${doctorId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDoctorHospitalByIdEndpoint(doctorId)));
      }));
  }

  getPatientListByProviderIdEndpoint<T>(doctorId: string): Observable<T> {
    let endpointUrl = `${this._patientListoFSameProvider}/${doctorId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientListByProviderIdEndpoint(doctorId)));
      }));
  }

  getPatientListForStatusChangeEndpoint<T>(doctorId: string): Observable<T> {
    let endpointUrl = `${this._patientListforStatusChange}/${doctorId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientListForStatusChangeEndpoint(doctorId)));
      }));
  }
  getPatientList<T>(doctorId: string): Observable<T> {
    let endpointUrl = `${this._patientList}/${doctorId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientList(doctorId)));
      }));
  }

  getPatientListByAdminEndPoint<T>(): Observable<T> {
    let endpointUrl = `${this._patientListbyAdmin}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getPatientListByAdminEndPoint()));
      }));
  }

  getCancelledAppointmentPatientByAdminEndPoint<T>(): Observable<T> {
    let endpointUrl = `${this._cancelledAppointmmentpatientListbyAdmin}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getCancelledAppointmentPatientByAdminEndPoint()));
      }));
  }
  getAppointmentsBookedOverPhone<T>(fromDate, toDate): Observable<T> {
    let endpointUrl = `${this._appointmentsBookedOverPhone}?FromDate=${fromDate}&ToDate=${toDate}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getAppointmentsBookedOverPhone(fromDate, toDate)));
      }));
  }

  getDoctorListEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this._doctorlistUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDoctorListEndpoint()));
      }));
  }
  getHospitalDoctorMapListEndpoint<T>(hospitalId: string): Observable<T> {
    let endpointUrl = `${this._hosdocListUrl}/${hospitalId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getHospitalDoctorMapListEndpoint(hospitalId)));
      }));
  }
  getDepartmentListEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this._departmentlistUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDepartmentListEndpoint()));
      }));
  }
  getDoctorMapDetailEndpoint<T>(hosDocMapId: string): Observable<T> {
    let endpointUrl = `${this._hosdocUrl}/${hosDocMapId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDoctorMapDetailEndpoint(hosDocMapId)));
      }));
  }
  registerDoctorEndpoint<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this._doctorRegisterUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.registerDoctorEndpoint(userObject)));
      }));
  }
  registerPatientEndpoint<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this._patientRegisterUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.registerPatientEndpoint(userObject)));
      }));
  }
  loginDoctorEndpoint<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this._doctorLoginUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.loginDoctorEndpoint(userObject)));
      }));
  }


  UpdateDoctorEndpoint<T>(userObject: any, doctorId?: string): Observable<T> {
    let endpointUrl = doctorId ? `${this._doctorUpdateUrl}/${doctorId}` : this._doctorUpdateUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.UpdateDoctorEndpoint(userObject, doctorId)));
      }));
  }

  getDeleteDoctorEndpoint<T>(doctorId: string): Observable<T> {
    let endpointUrl = `${this._doctorDeleteUrl}/${doctorId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDeleteDoctorEndpoint(doctorId)));
      }));
  }
  deleteHospitalMapEndpoint<T>(HospitalDoctorMapId: string): Observable<T> {
    let endpointUrl = `${this._hospitalMapdeleteUrl}/${HospitalDoctorMapId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.deleteHospitalMapEndpoint(HospitalDoctorMapId)));
      }));
  }
  UpdateHosDocMapEndpoint<T>(userObject: any, mapId: string): Observable<T> {
    let endpointUrl = mapId ? `${this._hosdocUpdateUrl}/${mapId}` : this._hosdocUpdateUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.UpdateHosDocMapEndpoint(userObject, mapId)));
      }));
  }
  updateQualificationEndpoint<T>(userObject: any, Id: string): Observable<T> {
    let endpointUrl = Id ? `${this._qualificationUpdateUrl}/${Id}` : this._qualificationUpdateUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.updateQualificationEndpoint(userObject, Id)));
      }));
  }

  deactivateDoctorEndpoint<T>(userObject: any, Id: string): Observable<T> {
    let endpointUrl = Id ? `${this._deactivateDocUrl}/${Id}` : this._deactivateDocUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.deactivateDoctorEndpoint(userObject, Id)));
      }));
  }

  uploadPrescriptionEndpoint<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this._uploadPrescriptionUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.uploadPrescriptionEndpoint(userObject)));
      }));
  }

  getDoctorMapListEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this._doctorMapListUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDoctorMapListEndpoint()));
      }));
  }
  getAvailableDoctorListEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this._availableDoctorlistUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getAvailableDoctorListEndpoint()));
      }));
  }
  getDoctorByDepartmentEndPoint<T>(departementId: string): Observable<T> {
    let endpointUrl = `${this._doctorListByDepartmentUrl}${departementId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders());
  }

}
