<app></app>
<button [routerLink]="['/PatDashboard']" class="back-btn-doc-room">
  Back To Dashboard
</button>

<!--<button [routerLink]="['/PatDashboard']" class="back-btn-doc-room">Back To DashBoard </button>

<div class="payment-section" style="margin-top: 0">
  <ul class="book-actions p-0" style="margin-bottom: 0">
    <li class="d-inline mr-2">
      <button (click)="BookNewVisit()" class="btn btn-info">
        <i class="fa fa-calendar"></i>Book New Visit
      </button>
    </li>
    <li class="d-inline mr-2">
      <button (click)="MyBookList()" class="btn btn-primary active-btn">
        <i class="fa fa-list"></i>My
        Booking List
      </button>
    </li>
    <li class="d-inline mr-2">
      <button (click)="MyPaidBooking()" class="btn btn-info">
        <i class="fa fa-calendar-o"></i>My Paid Booking
      </button>
    </li>

  </ul>
</div>-->

<div *ngIf="patbook" class="grid-section">
  <h4 class="title"><i class="fa fa-list"></i>My Booking List</h4>

  <div class="bookings_table">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">VisitDate</th>
          <th scope="col">VisitTime</th>
          <th scope="col">PatientName</th>
          <th scope="col">HospitalName</th>
          <th scope="col">DoctorName</th>
          <th scope="col">Department</th>
          <th scope="col">VisitType</th>
          <th scope="col">Action</th>
          <th scope="col">Print Report</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of collection.data  |paginate: { itemsPerPage: 5, currentPage: p  } ; let i=index ;"
        >
          <td>{{i+1}}</td>
          <td>{{data.VisitDate}}</td>
          <td>{{data.VisitTime}}</td>
          <td>{{data.PatientFamilyName ? data.PatientFamilyName : data.PatientName}}</td>
          <td>{{data.HospitalName}}</td>
          <td>{{data.DoctorName}}</td>
          <td>{{data.DepartmentName}}</td>
          <td>{{data.VisitType}}</td>
          <td *ngIf="data.Showpaid" align="left">
            <label class="text-success">UnPaid</label>
          </td>
          <td *ngIf="data.Showpay" align="left">
            <button
              style="background-color: #1e1ee2"
              (click)="paymentselection(data)"
            >
              Pay
            </button>
          </td>
          <td *ngIf="data.PaymentStatus == 'paid'" align="left">
            <label class="text-success"><i class="fa fa-check"></i>Paid</label>
          </td>
          <td *ngIf="data.PaymentStatus == 'free'" align="left">
            <label class="text-info"
              ><i class="fa fa-check-circle"></i>Free</label
            >
          </td>
          <td>
            <button class="btn btn-primary hidden-print" (click)="PrintTxnTable(data)" value="Print" title="print page">
            <i class="fa fa-print" style="margin-left:10px;"></i>
          </button></td>
        </tr>
      </tbody>
    </table>
    <div class="text-center not_available_message" *ngIf="!isBookingAvailable"><i class="fa fa-exclamation-circle text-danger fontawesome-icon"></i>
      Sorry! No bookings found!
    </div>
  </div>
  <pagination-controls
    style="font-size: 20px"
    (pageChange)="p = $event"
  ></pagination-controls>
</div>
<footer></footer>
