import { Component, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from './app.global';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { PatientDoc ,Patient} from './models/patient/patient.model';
import { PatientService } from './services/patient/patient.service';
import * as moment from 'moment/moment'
@Component({
    templateUrl: './app.finalreport.html'
  })
export class FinalReportComponent implements OnDestroy{
  patients: Array<PatientDoc> = new Array<PatientDoc>();
  patient:Patient = new Patient();
  pat:PatientDoc = new PatientDoc;
  public age: number;
  public IsDanpheCare  = false;
  //timerpat: any = timer(this.global.TimerValue, this.global.TimerValue);
  public visitId: string;
  constructor(public httpClient: HttpClient,
    public patService: PatientService,
    public routing: Router,
    public global: Global)
  {
    if (this.patService.VisitId != null) {
      this.visitId = this.patService.VisitId;
      this.ShowReport(this.visitId);
    }
  }

   ShowReport(VisitId){
   this.patService.getFinalReportData(VisitId)
    .subscribe(res => this.Success(res),
     res => this.Error(res));
      }

  Success(res) {
    this.patients = res;
    this.patService.VisitId = null;
    this.patient.DateOfBirth = res[0].DateOfBirth;
    //this.CalculateAge();
    this.pat.Age= moment().diff(this.patient.DateOfBirth,'years');
    this.patients[0].Age = this.pat.Age;
  }

  ngOnDestroy() {
    this.patService.VisitId = null;
  }
  Error(res) {
  }

  Print(){
  let popupWinindow;
      var printContents = document.getElementById("Report").innerHTML;
      popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
       popupWinindow.document.open();
       let documentContent = '<html><head>';
       documentContent += '<link rel="stylesheet" type="text/css" />';
       documentContent += '</head>';
       documentContent += '<body onload="window.print()" style="margin:8px 0px 0px 50px !important;">' + printContents + '</body></html>'
       popupWinindow.document.write(documentContent);
       popupWinindow.document.close();
      }

  }
