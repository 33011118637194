<app></app>
<div class="back-bg">
  <button [routerLink]="['/admin']" class="back-btn-doc-room">Back <span>To Admin</span> </button>
  <mat-chip-list aria-label="Add Product">
    <mat-chip color="primary" [routerLink]="['/Product/Add']" selected>+ Add Product</mat-chip>
  </mat-chip-list>
</div>

<div class="grid-section">
  <h4 class="title">
    <i class="fa fa-list" aria-hidden="true"></i>Product List
  </h4>

  <div class="row searchbar-card text-center" style="margin-top: 0">
    <div class="col-md-6 col-sm-8 m-auto d-flex">
      <div class="searchbar">
        <input class="search_input" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Product..">
        <a class="search_icon text-white"><i class="fa fa-search" aria-hidden="true"></i>Search</a>
      </div>
    </div>
  </div>

  <table class="table table-striped table-hover" style="margin-right: 5%;" aria-label="Product Table">
    <thead>
      <tr class="table-tr-style">
        <th scope="col">#</th>
        <th scope="col">Product Name</th>
        <th scope="col">Product Type</th>
        <th scope="col">Hospital Name</th>
        <th scope="col">Short Description</th>
        <th scope="col">Price(Rs)</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of AllProductsList | filter: searchText |paginate: { itemsPerPage: 10, currentPage: p  };  let i = index ">
        <td>{{i+1}}</td>
        <td>{{data.Name }}</td>
        <td>{{data.ProductType }}</td>
        <td>{{data.HospitalName }}</td>
        <td>
          <div class="crop">{{data.ShortDescription }}</div>
        </td>
        <td>{{data.Price }}</td>
        <td>
          <a (click)="EditProduct(data.Id)" class="btn btn-success btn-sm text-white"><i class="fa fa-pencil" aria-hidden="true"></i>Edit</a>
          <a (click)="ShowActivateDeactivatePopUp(data)" class="btn btn-danger btn-sm text-white"><i class="fa fa-trash" aria-hidden="true"></i>{{data.IsActive ? "Deactivate" : "Activate"}} </a>
        </td>
      </tr>

    </tbody>
  </table>

  <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>



</div>

<div class="modal" id="myModal" *ngIf="showActivateDeactivatePopUp">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Are You Sure you want to {{selectedProduct.IsActive ? "deactivate" : "activate"}} this product??</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="CloseActivateDeactivatePopUp()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="margin: auto;">
        <button class="btn-danger mr-2" value="Yes" (click)="ActivateDeactivateProductEndpoint()">Yes</button>
        <button value="No" (click)="CloseActivateDeactivatePopUp()">No</button>
      </div>
    </div>
  </div>
</div>

<footer></footer>


<div *ngIf="isPreview" class="modal" id="ProductModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" style="background-color: aqua;">
        <h5 class="modal-title" id="exampleModalLabel">Product Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">
            <h4>x</h4>
          </span>
        </button>
      </div>

      <div class="modal-body">

        <div class="card-body card-body-wider">
          <app-view-product [ProductId]="this.productId"></app-view-product>
        </div>

        <div class="modal-footer">
          <button *ngIf="editMode" type="button" class="btn btn-secondary" data-dismiss="modal">Cancle</button>
          <button *ngIf="!editMode" type="button" class="btn btn-secondary" data-dismiss="modal" (click)="Close()">Close</button>

        </div>
      </div>
    </div>
  </div>
</div>
