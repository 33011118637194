export class  Qualification{
    public QualificationId : string;
    public Designation : string ;
    public Education : string ;
    public PastAffiliation : string ;
    public Experience : string ;
    public Membership : string ;
    public DoctorId : string ;

}
