<!--Slider End-->
<div class="login-container">
  <div class="row d-flex justify-content-center h-100">
    <div class="col-10 col-sm-12 col-md-10">
      <div class="row justify-content-center h-100 login-flex">
        <div class="col-md-5 text-center">
          <a href="" style="margin-bottom: 25px"><img src="../assets/img/logo.png" alt="" height="50" /></a>
          <div class="card text-left">
            <div class="card-header">
              <div class="info">
                <!-- <h2>
                  Stay Home Stay Safe<br />
                  <p>
                    Connect with your <strong>Doctor</strong> from your home
                  </p>
                </h2> -->
                <h6>
                  <b>
                    <label>Contact : {{contactInfo.ContactNumber}}</label><br />
                    <label>Email : {{contactInfo.Email}}</label>
                  </b>
                </h6>
              </div>

              <!--<div class="d-flex justify-content-end social_icon">
                <span><i class="fab fa-facebook-square"></i></span>
                <span><i class="fab fa-google-plus-square"></i></span>
                <span><i class="fab fa-twitter-square"></i></span>
              </div>-->
            </div>
            <div class="card-body" style="padding-top: 10px">
              <div class="row" style="margin-bottom: 10px">
                <!--<div class="col-md-6 text-right">
                  <div>
                    <input id="checkbox-2" class="radio-custom" type="radio">
                    <label for="checkbox-2" class="radio-custom-label">I'm a Patient</label>
                  </div>

                </div>
                <div class="col-md-6 text-left">
                  <div>
                    <input id="checkbox-3" class="radio-custom" type="radio">
                    <label for="checkbox-3" class="radio-custom-label">I'm a Doctor</label>
                  </div>
                </div>-->
                <!--<div class="col-md-6 text-right">
                  <input id="patient" type="radio" class=""   [value]="true" [(ngModel)]="isPatient" name="patient">
                  <label class="" for="patient">I'm a Patient</label>
                </div>

                  <div class="col-md-6 text-left">
                    <input id="doctor" type="radio" class=""  [value]="false" [(ngModel)]="isPatient"  name="patient" >
                    <label class="" for="doctor">I'm a Doctor</label>
                  </div>-->
              </div>
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="input-group form-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-mobile"></i></span>
                  </div>
                  <input type="text" formControlName="phonenumber" placeholder="Username" class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && f.phonenumber.errors
                    }" autocomplete="new-phonenumber" />
                  <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                    <div *ngIf="f.phonenumber.errors.required">
                      Phone Number is required
                    </div>

                    <div *ngIf="loginForm.get('phoneNumber').hasError('minlength')">
                      Phone number must be at least 7 digits.
                    </div>
                  
                    <div *ngIf="loginForm.get('phoneNumber').hasError('maxlength')">
                      Phone number cannot exceed 15 digits.
                    </div>
                  </div>
                </div>
                <div class="input-group form-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-lock"></i></span>
                  </div>
                  <input [type]="showPassword ? 'text' : 'password'" formControlName="password" placeholder="Password"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                    autocomplete="new-password" />
                  <div id="show-password" class="input-group-append">
                    <a class="input-group-text" (click)="showPass()"><em
                        [ngClass]="{'fa': true, 'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"
                        aria-hidden="true"></em></a>
                  </div>
                  <!-- <div class="input-group-append">
                    <span class="input-group-text">.00</span>
                  </div> -->
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">
                      Password is required
                    </div>
                    <div *ngIf="f.password.errors.minlength">
                      Password must be at least 6 characters
                    </div>
                    <div *ngIf="f.password.errors.pattern">
                      Password should contains atleast one special charater,one
                      upper case, one lower case and one number
                    </div>
                  </div>
                </div>
                <div class="row d-flex align-items-center" style="margin-top: 35px">
                  <div class="col">
                    <div class="form-check form-check-flat">
                      <label for="remember-me" class="form-check-label">
                        <input id="remember-me" type="checkbox" [(ngModel)]="rememberUser"
                          [ngModelOptions]="{standalone: true}" class="form-check-input" />
                        Remember Me
                        <i class="input-helper" aria-hidden="true"></i>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col text-center">
                  <button class="btn justify-content-center btn-primary" type="submit">
                    <span *ngIf="loading" class="spinner-border" role="status"></span>
                    <span *ngIf="!loading">Login</span>
                  </button>
                </div>
                <div class="div">
                  <div class="d-flex justify-content-center mb-2">
                    <a class="text-primary" (click)="ForgotPassword()">Forgot Password?</a>
                  </div>
                </div>

                <div>
                  <div class="d-block justify-content-center links text-center">
                    No account yet?
                    <a class="text-primary" (click)="patientregister()">Sign up</a>
                  </div>
                </div>
              </form>
            </div>

            <div class="card-footer d-block">
              <!--Slider-->
              <!-- <div class="s-content">
                <div class="gallery-item slider-container">
                  <div class="slide-holder">
                    <img src="../assets/img/slider1.jpg" alt="" />
                    <img src="../assets/img/slider2.jpg" alt="" />
                    <img src="../assets/img/slider1.jpg" alt="" />
                    <img src="../assets/img/slider2.jpg" alt="" />
                  </div>
                </div>
              </div> -->
              <!-- Old Slider -->
              <!-- <div
                class="s-container"
                style="height: 175px; margin-top: 0; margin-bottom: 0"
              >
                <img
                  class="photo-slider"
                  src="./assets/img/slider1.jpg"
                  alt=""
                />
                <img
                  class="photo-slider"
                  src="./assets/img/slider2.jpg"
                  alt=""
                />
                <img
                  class="photo-slider"
                  src="./assets/img/slider1.jpg"
                  alt=""
                />
                <img
                  class="photo-slider"
                  src="./assets/img/slider2.jpg"
                  alt=""
                />
              </div> -->
              <!--slide end-->
              <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                  <img src="{{ slide.img }}" alt="" width="100%" />
                </div>
              </ngx-slick-carousel> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="powered-section">
    <div class="login-brand">
      <img src="../assets/img/imark-powered.png" />
    </div>
  </div>
</div>

<style>
  @media (min-width: 768px) {
    .full-body {
      -webkit-box-flex: 0;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }

  .full-body {
    -webkit-box-flex: 0;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
</style>