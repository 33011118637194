import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  msg: string;
  cancel: string;
}
@Component({
  //selector: 'dialog-overview-example-dialog',
  templateUrl: 'pop-up.component.html',
})

export class PopUpDialogComponent {
  showCancelBookingPopup: boolean;
  showUpdateProfilePopup: boolean;
  msg: string;

  constructor(
    public dialogRef: MatDialogRef<PopUpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    dialogRef.disableClose = true;
    if (this.data.msg == "fromAdmin" ) {
      this.showCancelBookingPopup = true;
    }
    if (this.data.msg == "fromDocRoom") {
      this.showCancelBookingPopup = false;
      this.showUpdateProfilePopup = false;
    } 
    if (this.data.msg == "updateProfile" ) {
      this.showUpdateProfilePopup = true;    
    } 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick() {
    this.data.cancel = "yes";
    this.dialogRef.close();
   
  }
}
