
import { Injectable, EventEmitter } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { Doctor } from '../../models/doctor/doctor.model';
import { Patient } from '../../models/patient/patient.model';
import { AuthenticationService } from '../authentication.service';
import { Global } from 'src/app/app.global';
import { Subject } from 'rxjs';
import { EventNotificationService } from '../EventNotification/event-notification.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  EventConnectionEstablished = new EventEmitter<Boolean>();
  EventGetAllPatients = new EventEmitter<Patient>();
  EventCallPatient = new EventEmitter<Patient>();
  EventCompletePatient = new EventEmitter<Patient>();
  EventChatMessage = new EventEmitter<any>();
  EventGetAllDoctors = new EventEmitter<Doctor>();
  private connectionIsEstablished = false;
  private _hubConnection: HubConnection;
  private notificationSubject = new Subject<Array<string>>();
  public notification$ = this.notificationSubject.asObservable();
  token: any;
  EventDocStatus = new EventEmitter<string>();

  constructor(private authenticationService: AuthenticationService,
    public global: Global,
    public authService: AuthenticationService,
    private _eventNotify: EventNotificationService,
    private router: Router) {
    this.token = this.authenticationService.currentUserValue;
  }


  public CallPatient(callPatient) {
    console.log('callPatient');
    this._hubConnection.invoke('CallToPatient', callPatient)
      .catch(function (err) {
        console.log(err);
      });
  }

  //public PatientAttended(attendPatient: PatientsAttendedModel) {
  //  console.log(attendPatient);
  //  this._hubConnection.invoke('PatientAttended', attendPatient)
  //    .catch(function (err) {
  //      console.log(err);
  //    });
  //}

  public SendChatMessage(chatMessage) {
    this._hubConnection.invoke('SendChatMessage', chatMessage)
      .catch(err => {
        console.log(err);
      });
  }

  public SendReport(VisitId) {
    this._hubConnection.invoke('SendReport', VisitId)
      .catch(err => {
        console.log(err);
      });
  }

  public DoctorStatusCheck(user) {
    this._hubConnection.invoke('DoctorStatusCheck', user)
      .catch(err => {
        console.log(err);
      });
  }

  public Disconnect() {
    this._hubConnection.stop();
  }

  // public LoadActiveDoctors() {
  //   this._hubConnection.invoke('GetActiveDoctors').catch(err => {
  //     console.log(err);
  //   });
  // }

  public Connect() {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }
  private createConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(window.location.origin + '/NotificationHub?token=' + sessionStorage.getItem('jwtToken'))
      .build();
    this._hubConnection.serverTimeoutInMilliseconds = 432000000; // 4 hrs
  }

  private startConnection(): void {

    this._hubConnection.on('NotifyDoctor', (data) => {
      //console.log(data);
      this._eventNotify.getUserNotification().subscribe(res => {
        var userNotifications = res;
        this.notificationSubject.next(userNotifications);
      },
        err => { });

    });

    this._hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        console.log('Hub connection started');
        this.EventConnectionEstablished.emit(true);
      })
      .catch(err => {
        console.log('Error while establishing connection, retrying...');
        setTimeout(function () { this.startConnection(); }, 432000000);
      });

    this._hubConnection.onclose(function (e) {
      console.log('Connection Closed');
      setTimeout(function () { this.startConnection(); }, 432000000);

    });

  }

  private registerOnServerEvents(): void {

    this._hubConnection.on('GetAllPatients', (data: any) => {
      var obj: any = JSON.parse(data)
      this.EventGetAllPatients.emit(obj);
    });

    this._hubConnection.on('CallPatient', (data: any) => {
      var obj: any = JSON.parse(data)

      this.EventCallPatient.emit(obj);
    });


    this._hubConnection.on('CompletePatient', (data: any) => {
      console.log('Complete Patient' + data);
      var obj: any = JSON.parse(data)
      this.EventCompletePatient.emit(obj);
    });

    this._hubConnection.on('ChatMessage', (data: any) => {
      // console.log('Message' + data);
      const msg: any = JSON.parse(data);
      this.EventChatMessage.emit(msg);
    });

    this._hubConnection.on('GetAllDoctors', (data: any) => {
      const jsonData: any = JSON.parse(data);
      this.EventGetAllDoctors.emit(jsonData);
    });

    this._hubConnection.on('DoctorStatus', (data: any) => {
      // console.log('Message' + data);
      const status: any = data;
      this.EventDocStatus.emit(status);
    });

    this._hubConnection.on('LogOutUser', (data: any) => {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
    })

  }
  public LogoutUserWithId(userId) {
    this._hubConnection.invoke('LogOutUser', userId)
      .catch(err => {
        console.log(err);
      });
  }
}
