
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EndpointFactory } from '../endpoint-factory.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HospitalEndpoint extends EndpointFactory {

  private readonly _hospitalListUrl: string = "/api/hospital/hospitalDetails";
  private readonly _hospitalUrl: string = "/api/hospital/getHospital";
  private readonly _hospitalDocUrl: string = "/api/hospital/getDocHospital";
  private readonly _hospitalDocbyvisitUrl: string = "/api/hospital/GetDocHospitalbyvisittype"
  private readonly _addHospitalUrl: string = "/api/hospital/addHospital";
  private readonly _hospitaldeleteUrl: string = "/api/hospital/deleteHospital";
  private readonly _hospitalUpdateUrl: string = "/api/hospital/updateHospital";
  private readonly _getcontactInformationUrl: string = "/api/hospital/getcontactInformation";
  private readonly _addContactInfolUrl: string = "/api/hospital/addContactInfo";
  private readonly _contactInfoUpdateUrl: string = "/api/hospital/updateContactInfo";
  private readonly _appointmentMasterReportUrl: string = "/api/hospital/GetAppMasterReportData";
  private readonly _getContactInfoUrl: string = "/api/account/getContactInfo";
  private readonly _getHospDeptDetails: string = "/api/hospital/getHospitalDepartmentDetails";

  constructor(http: HttpClient, injector: Injector) {
    super(http, injector);
  }
  geHospitalListEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this._hospitalListUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.geHospitalListEndpoint()));
      }));
  }
  geHospitalEndpoint<T>(HospitalId: string): Observable<T> {
    let endpointUrl = `${this._hospitalUrl}/${HospitalId}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.geHospitalEndpoint(HospitalId)));
      }));
  }
  getDocHospitalListEndpoint<T>(doctordId: string): Observable<T> {
    let endpointUrl = `${this._hospitalDocUrl}/${doctordId}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDocHospitalListEndpoint(doctordId)));
      }));
  }
  getDocHospitalListbyvisittypeEndpoint<T>(doctordId: string, visitType: string): Observable<T> {
    let endpointUrl = `${this._hospitalDocbyvisitUrl}/${doctordId} /${visitType}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getDocHospitalListbyvisittypeEndpoint(doctordId, visitType)));
      }));
  }
  addHospitalEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this._addHospitalUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.addHospitalEndpoint(userObject)));
      }));
  }
  deleteHospitalEndpoint<T>(HospitalId: string): Observable<T> {
    let endpointUrl = `${this._hospitaldeleteUrl}/${HospitalId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.deleteHospitalEndpoint(HospitalId)));
      }));
  }
  updateHospitalEndpoint<T>(userObject: any, Id: string): Observable<T> {
    let endpointUrl = Id ? `${this._hospitalUpdateUrl}/${Id}` : this._hospitalUpdateUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.updateHospitalEndpoint(userObject, Id)));
      }));
  }

  getcontactInformationEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this._getcontactInformationUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.geHospitalListEndpoint()));
      }));
  }

  addContactInfoEndpoint<T>(userObject: any): Observable<T> {
    return this.http.post<T>(this._addContactInfolUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.addContactInfoEndpoint(userObject)));
      }));
  }

  updateContactInfoEndpoint<T>(userObject: any, Id: string): Observable<T> {
    let endpointUrl = Id ? `${this._contactInfoUpdateUrl}/${Id}` : this._contactInfoUpdateUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.updateContactInfoEndpoint(userObject, Id)));
      }));
  }

  getAppMasterReportEndPoint<T>(): Observable<T> {
    let endpointUrl = `${this._appointmentMasterReportUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getAppMasterReportEndPoint()));
      }));
  }

  getContactInfoEndpoint<T>(): Observable<T> {
    let endpointUrl = `${this._getContactInfoUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getContactInfoEndpoint()));
      }));
  }
  getHospitalDepartmentDetailsFromVisitTypeEndpoint<T>(doctorId: string, visitType: string): Observable<T> {
    let endpointUrl = `${this._getHospDeptDetails}/${doctorId}/${visitType}`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.getHospitalDepartmentDetailsFromVisitTypeEndpoint(doctorId, visitType)));
      }));
  }
}
