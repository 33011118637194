<app></app>
<div class="bg-back">
  <button (click)="BackDashBoard()" class="back-btn-doc-room">Back To DashBoard </button>
  <button (click)="UploadMedicalRecord()" [style.background-color]="viewMedicalRecord == false ? '#007bff' : '#009688'" class="mr-2">
    Upload Medical Record</button>
  <button (click)="ViewMedicalRecord()" [style.background-color]="viewMedicalRecord == true ? '#007bff' : '#009688'">
    View Medical Record</button>
</div>
<div class="card">
  <div class="card-body">
    <div *ngIf="!viewMedicalRecord">
      <form #medicalReportForm="ngForm" (ngSubmit)="UploadRecord(medicalReportForm)">
        <div class="row d-flex">
          <div class="col-md-4">
            <label>Patient Name<span class="required-sign">*</span></label>
            <select name="PatientName" class="form-control" placeholder="Select patient name" [(ngModel)]="selectedPatId" required>
              <option *ngFor="let pat of patData" [value]="pat.PatientId">
                {{pat.FirstName}} (Self)
              </option>
              <option *ngFor="let family of patFamilyList" [value]="family.FamilyMemberId">
                {{family.FirstName}}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <label>Test Report<span class="required-sign">*</span></label>
            <select name="TestReportType" class="form-control" placeholder="Select test report" [(ngModel)]="patMedicalRecord.TestReportType" required>
              <option *ngFor="let report of TestReportType" [value]="report.type">
                {{report.type}}
              </option>
            </select>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col-md-4">
            <input type="file" #file accept=".jpg,.JPG,.png,.PNG,.doc,.DOC,.docx,.DOCX,.pdf,.PDF" placeholder="Choose file" (change)="upload(file.files)" name="fileName" [(ngModel)]="patMedicalRecord.FilePath" [ngModelOptions]="{standalone: true}" style="display: none" />
            <button type="button" class="btn btn-primary d-inline" (click)="file.click()" style="font-size: 14px; margin-top: 25px">
              <i class="fa fa-upload"></i>Select File
            </button>
            <div class="col-md-5 d-inline">
              <span class="upload" *ngIf="progress > 0" style="font-size: 11px">
                {{progress}}%
              </span>
              <span class="upload" *ngIf="message" style="font-size: 11px">
                {{message}}
              </span>
            </div>
          </div>

        </div>
        <div class="col-md-8">
          <button class="btn btn-success" style="float: right;" value="Submit">Upload</button>
        </div>
      </form>
    </div>
    <div *ngIf="viewMedicalRecord" style="overflow: scroll;">
      <!-- <h4 class="title mt-3"><i class="fa fa-clock-o"></i>My Appointment History</h4> -->
      <table class="table">
        <thead>
          <tr>
            <th scope="col">S.N.</th>
            <th scope="col">Patient Name</th>
            <th scope="col">Test Report</th>
            <th scope="col">Uploaded By</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let record of collection.data |paginate: { itemsPerPage: 5, currentPage: p }">
            <td>{{record.id}}</td>
            <td>{{record.PatientName}}</td>
            <td>{{record.TestReportType}}</td>
            <td>{{record.UploadedBy }}</td>
            <td><button type="button" data-toggle="modal" data-target="#target" (click)="ViewFile(record.FilePath,record.FileExtension,record.FileName)" class="btn btn-sm btn-primary">
                <i class="fa fa-eye"></i>
                View
              </button>
              <button type="button" data-toggle="modal" data-target="#target" (click)="DeleteRecord(record.MedicalRecordId)" class="btn btn-sm btn-danger">
                <i class="fa fa-trash"></i>
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>

    </div>

  </div>
</div>

<div class="modal" id="myModal" [style.display]="showView ? 'block' : 'none'">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="background-color: white">
        {{title}}
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">
          X
        </button>
      </div>
      <!-- Modal body -->
      <div class="modal-body" style="background-color: floralwhite">
        <div class="info-heading">
          <img *ngIf="Filetype == 'image'" [src]="safeUrl" alt="info-image" style="
              width: 700px;
              height: 339px;
              align-content: center;
              margin: 100px 100px 100px 100px;
            " />
            <a *ngIf="Filetype == 'image'"
              [href]="safeUrl"
              target="_blank"
              [download]="title + '.' + fileExtension"
              class="btn btn-sm btn-primary col-md-2 mt-2"
              style="float: right;">
            
              <i class="fa fa-download"></i>
            Download
            </a>
              <iframe *ngIf="Filetype == 'pdf'"
          [src]="safeUrl"
                                   style="display: block; width: 100%; height: 80vh !important;">
          </iframe>
          <div *ngIf="Filetype === 'docfile'">
            <a [href]="safeUrl" class="btn btn-primary btn-lg active" role="button">Click to Open Doc File</a>
          </div>        
        </div>
        <!-- Rohan: 3/1/2024: We are now using iFrame to render pdf documents which have inbuilt feature to download the pdf. Hence, we won't be needing the separate action to download the pdf. -->
          <!-- <a href="safeUrl" target="_blank" [download]="fileName" class="btn btn-sm btn-primary col-md-3" style="float: right;">
          <i class="fa fa-download"></i>
          Download
        </a> -->
      </div>

    </div>
  </div>
</div>

<footer></footer>/