import { Component } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DoctorService } from '../../../services/doctor/doctor.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { PatientService } from '../../../services/patient/patient.service';
import { AlertService } from '../../../services/alert.service';
import { NotificationService } from '../../../services/notification.service';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Global } from '../../../app.global';
import { RescheduleComponent } from '../../../Doctor/doctor_reschedule/app.doctor_reschedule';
import { MatDialog } from '@angular/material/dialog';
import { Visit } from '../../../models/visit/visit.model';
import { PopUpDialogComponent } from '../../common/popup/pop-up.component';
export interface DialogData {
  cancel: string;
}
@Component({
  //selector: 'admin-root',
  templateUrl: './admin.patientappointment.html'
})
export class AdminPanelPatientAppointment {
  public today = new Date();
  config: any;
  token: any;
  public todate = moment().format('YYYY-MM-DD');
  public fromdate = moment().format('YYYY-MM-DD');
  public filterdate = moment().format('YYYY-MM-DD');
  patientList: any[] = [];
  patientDanpheCareList: any;
  filteredDailyVisitList: any;
  filteredpatientList: any;
  public showpatientList: boolean;
  public showcancelledAppointmentpatientList: boolean;
  public showView: boolean = false;
  visitObj: Visit = new Visit();
  collection = { count: 60, data: [] };
  progress: number;
  message: string;
  public visitId: string;
  p: number = 1;
  selectedValue = "";
  isCancelled: boolean;
  IsDanpheCare = false;
  PaymentStatus = [
    { "status": "paid" },
    { "status": "free" },
    { "status": "unpaid" },
  ];
  PaymentStatusDanpheCare = [
    { "status": "paid" },
    { "status": "free" },
    { "status": "unpaid" },
    { "status": "credit" },
  ];
  public index: number;
  public FilePath: String = "";
  private readonly _UploadFileUrl: string = "api/Patient/UploadPatFile";

  constructor(public routing: Router, public docService: DoctorService, public authService: AuthenticationService, public patService: PatientService, public alertService: AlertService,
    public global: Global, public notify: NotificationService, public http: HttpClient, public dialog: MatDialog) {
    this.token = this.authService.currentUserValue;
    this.ShowPatientList();
    this.ShowData();

  }
  pageChanged(event) {
    this.config.currentPage = event;
  }
  public ShowData() {
    for (var i = 0; i < this.collection.count; i++) {
      this.collection.data.push(
        {
          id: i + 1,
          value: "items number " + (i + 1)
        }
      );
    }

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.collection.count
    };
  }

  public ShowPatientList() {
    this.showcancelledAppointmentpatientList = false;
    this.showpatientList = true;
    //var id = this.token.PatientIdentifier;
    this.docService.getPatientListByAdmin().subscribe(res => {
      if(res.IsSuccess && res?.Results){
        this.patientList = res.Results;
        this.patientDanpheCareList = (this.patientList != null && this.patientList.length > 0) ? this.patientList[0].HospitalIdentifier : "NA";
        if (this.patientDanpheCareList === "DanpheTeleHealth") {
          this.IsDanpheCare = true;
        }
        else {
          this.IsDanpheCare = false;
        }

        this.collection = { count: this.patientList.length, data: [] }
        this.collection.data = this.patientList.map((pat, index) => {
          return {
            id: index + 1,
            VisitDate: moment(pat.VisitDate).format("YYYY-MM-DD"),
            PatientName: pat.PatientName,
            ContactNumber: pat.ContactNumber,
            DoctorName: pat.DoctorName,
            Age: pat.Age != null ? pat.Age : moment().diff(pat.DateOfBirth, 'years'),
            PaymentStatus: pat.PaymentStatus,
            BookingTime: pat.BookingTime,
            HospitalId: pat.HospitalId,
            DepartmentId: pat.DepartmentId,
            DoctorId: pat.DoctorId,
            VisitId: pat.VisitId,
            SchedulingId: pat.SchedulingId,
            VisitStatus: pat.Status,
            Gender: pat.Gender,
            DepartmentName: pat.DepartmentName,
            VisitType: pat.VisitType
          }
        });
      }



      this.FilterbyDate();
    },
      error => {
        this.alertService.error(error);
        // this.loading = false;
      });

  }
  //ChangeFromDate() {
  //  // moment().format("DD/MM/YYYY");
  //  this.filteredDailyVisitList = new Array<any>();
  //  this.filteredDailyVisitList = JSON.parse(JSON.stringify(this.collection.data));//deepcopy
  //  this.filteredDailyVisitList = this.filteredDailyVisitList.filter(a => a.VisitDate == this.filterdate);
  //  //console.log(this.filterdate);
  //}
  //ChangeToDate() {
  //  // moment().format("DD/MM/YYYY");
  //  this.filteredDailyVisitList = new Array<any>();
  //  this.filteredDailyVisitList = JSON.parse(JSON.stringify(this.collection.data));//deepcopy
  //  this.filteredDailyVisitList = this.filteredDailyVisitList.filter(a => a.VisitDate == this.filterdate);
  //  //console.log(this.filterdate);
  //}
  FilterbyDate() {
    this.filteredpatientList = new Array<any>();
    this.filteredpatientList = JSON.parse(JSON.stringify(this.collection.data));
    // console.log(this.filteredpatientList);
    this.Search();
  }

  Search() {
    var todatemoment = moment(this.todate, 'YYYY-MM-DD');
    var fromdatemoment = moment(this.fromdate, 'YYYY-MM-DD');
    var diff = moment(todatemoment).diff(fromdatemoment, 'days');
    if (diff >= 0) {
      this.filteredpatientList = new Array<any>();
      this.filteredpatientList = JSON.parse(JSON.stringify(this.collection.data));
      for (var i = 0; i < this.filteredpatientList.length; i++) {
        this.filteredpatientList[i].VisitDate = moment(this.filteredpatientList[i].VisitDate, 'YYYY-MM-DD');
        // this.patBookList[i].VisitStartTime = moment(this.patBookList[i].VisitStartTime).format('LT');
      }
      this.filteredpatientList = this.filteredpatientList.filter(a => a.VisitDate <= todatemoment && a.VisitDate >= fromdatemoment);

    } else {
      this.notify.showWarning("Sorry", "Please check date interval  !!");
    }
  }

  Error(res) {

  }

  ChangeStatus(data) {
    this.selectedValue = data.PaymentStatus;
    this.visitId = data.VisitId;
    this.showView = true;

  }
  paymentstatusChanged() {
    this.visitObj.PaymentStatus = this.selectedValue;
  }
  hide() {
    this.showView = false;
  }

  Reschedule(data) {
    const dialogRef = this.dialog.open(RescheduleComponent,
      { data: { docId: data.DoctorId, deptId: data.DepartmentId, hosId: data.HospitalId, visitId: data.VisitId, schedullingId: data.SchedulingId, bookingTime: data.BookingTime, appointmentType: data.VisitType }, width: '1000px', height: '1000px' }
    );
    console.log(data.SchedulingId);
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      dialogRef.close();
      // console.log(`Dialog result: ${result}`);
      this.routing.navigate(['/PatientAppointmentList']);
      this.ShowPatientList();
    });
  }

  UpdateStatus() {
    this.patService.updatePaymentStatus(this.visitObj, this.visitId).subscribe(res => {
      this.showView = false;
      this.SuccessUpdate();
    },
      error => {
        this.alertService.error(error);
        // this.loading = false;
      });
  }
  SuccessUpdate() {
    this.notify.showSuccess('Updated successfully', 'Success');
    this.ShowPatientList();
  }

  public Admin() {
    this.routing.navigate(['/admin']);
  }
  routeToAppointmentOverPhone() {
    this.routing.navigate(['/PatientAppointmentList/ByPhone']);
  }
  CancelBooking(data) {
    if (data.PaymentStatus != "paid") {
      this.visitId = data.VisitId;
      this.visitObj.SchedulingId = data.SchedulingId;
      this.visitObj.BookingTime = data.BookingTime;
      this.patService.cancelBooking(this.visitObj, this.visitId).subscribe(res => {
        this.notify.showSuccess('Booking cancelled !!', 'Success');
        this.ShowPatientList();
      },
        error => {
          this.alertService.error(error);
          // this.loading = false;
        });

    } else {
      this.notify.showError("Paid Booking cannot be cancelled ", "Sorry");
    }
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(PopUpDialogComponent, {
      data: { msg: "fromAdmin" },
      width: '250px',
      height: '300',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isCancelled = result;
      if (this.isCancelled === true) {
        this.CancelBooking(data);
      }
    });

  }
  ShowCancelledAppointment() {
    this.showpatientList = false;
    this.showcancelledAppointmentpatientList = true;
    this.docService.getCancelledAppointmentPatientByAdmin().subscribe(res => {
      this.patientList = res;

      this.collection = { count: this.patientList.length, data: [] }
      this.collection.data = this.patientList.map((pat, index) => {
        return {
          id: index + 1,
          VisitDate: moment(pat.VisitDate).format("YYYY-MM-DD"),
          PatientName: pat.PatientName,
          ContactNumber: pat.ContactNumber,
          DoctorName: pat.DoctorName,
          Age: moment().diff(pat.DateOfBirth, 'years'),
          PaymentStatus: pat.PaymentStatus,
          BookingTime: pat.BookingTime,
          HospitalId: pat.HospitalId,
          DepartmentId: pat.DepartmentId,
          DoctorId: pat.DoctorId,
          VisitId: pat.VisitId,
          SchedulingId: pat.SchedulingId,
          VisitStatus: pat.Status,
          Gender: pat.Gender,
          DepartmentName: pat.DepartmentName,
          CancelledBookingTime: pat.CancelledBookingTime,
          AppointmentType: pat.VisitType
        };
      })

      this.FilterbyDate();
    },
      error => {
        this.alertService.error(error);
      });

  }

  upload(files, visitId) {
    this.message = null;
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files) {
      formData.append(file.name, file);
      console.log('file', formData);
    }

    const uploadReq = new HttpRequest('POST', `${this._UploadFileUrl}/${visitId}`, formData);

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
        // if (this.progress == 100) {
        //   this.index = i;
        // }
      }
      else if (event.type === HttpEventType.Response) {
        this.message = event.body.toString();
      }
      if (this.message != null) {
        this.notify.showSuccess('Uploaded Successfully', 'Success');
      }
    });

  }

}
