import { Component } from "@angular/core";
import { OrderService } from "../../../services/order/order.service";

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent {
  orders: any[] = [];
  p: number = 1;
  public searchText: any;
  constructor(public orderService: OrderService) {
    this.orderService.getAllProducts<any>(this.p)
      .subscribe(res => {
        console.log(res);
        this.orders = res;
      });
  }
}
