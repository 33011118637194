import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
//import { LoginComponent } from './app.login';
import { WaitingRoom } from './Patient/patient_waitingroom/app.waitingroomcomponent';
import { DoctorRoomComponent } from './Doctor/doctor_doctorroom/app.doctorroomcomponent';
import { FinalReportComponent } from './app.finalreportcomponent';
import { DoctorDashboardComponent } from './Doctor/doctor_dashboard/app.doctor-dashboard';
import { DoctorUpdateProfileComponent } from './Doctor/doctor_updateprofile/app.doctor_updateprofile';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { PatientDashboardComponent } from './Patient/patient_dashboard/app.patient_dashboard';
import { PatientUpdateProfileComponent } from './Patient/patient_updateprofile/app.patient_updateprofile';
import { PatientBookAppointmentComponent } from './Patient/patient_booking/app.patient_bookappointment';
import { PatientHistoryComponent } from './Patient/patient_history/app.patient_history';
import { DailyVisitComponent } from './Doctor/doctor_dailyvisit/app.dailyvisitcomponent';
import { MyBooKListComponent } from './Patient/patient_booking/app.mybooklist';
import { PayThroughCreditCardComponent } from './Patient/patient_payment/app.paythroughcreditcardcomponent';
import { PaidBookingListComponent } from './Patient/patient_booking/app.paidbookinglistcomponent';
import { PaymentSelectionComponent } from './Patient/patient_payment/app.paymentselection.component';
import { HospitalComponent } from './app.hospitalcomponent';
import { AdminComponent } from './components/admin/admin.component';
import { DoctorSchedulingComponent } from './components/admin/doctorscheduling/doctor-scheduling.component';
import { AddAppointmentComponent } from './components/admin/doctorscheduling/add-appointment/add-appointment.component';
import { DoctorProfileComponent } from './Doctor/doctor_profile/app.doctor_profile';
import { PatientListComponent } from './Doctor/doctor_patientlist/app.patientlistcomponent';
import { SetUpComponent } from './components/admin/setup/app.set-up';
import { AdminPanelPatientAppointment } from './components/admin/patientappointment/admin.patientappointment.component';
import { DoctorListComponent } from './Doctor/doctor_list/doctor_list.component';
import { DanphecareComponent } from './Danphecare/app.danphecare';
import { ContactUsComponent } from './Contact_Us/app.contactUs';

import { AdminPatientListComponent } from './components/admin/patient_list/patient_list.component';
import { HospitalSpecificDoctorListComponent } from './app.hospitalspecificdoclist.component';
import { ContactInfoComponent } from './components/admin/contact_Info/contact_information';
import { ReportComponent } from './components/admin/report/report.component';
import { DoctorReportComponent } from './Doctor/doctor_report/doctor_report.component';
import { PatientMedicalRecordComponent } from './Patient/patient_medical_record/app.patient_medical_record';
import { AddOPSummaryComponent } from './Doctor/OP_Summary/app.Add_OP_summary';
import { LabReportUploadComponent } from './components/admin/lab-report-upload/lab-report-upload.component';
import { PatientLabReportsComponent } from './Patient/patient_lab_reports/app.patient_lab_reports';
import { AddProductComponent } from './components/admin/product/add-product/add-product.component';
import { ProductListComponent } from './components/admin/product/product-list/product-list.component';
import { ViewProductComponent } from './components/admin/product/view-product/view-product.component';
import { AppointmentsOverPhoneComponent } from './components/admin/patientappointment/appointments-over-phone/appointments-over-phone.component';
import { OrderComponent } from './components/admin/order/order.component';
import { RoleGuardService } from './services/route-guards/role-guard.service';
import { MeetingsetupComponent } from './components/admin/meetingsetup/meetingsetup.component';


@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      {
        path: 'DocDashboard', component: DoctorDashboardComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Doctor'] }
      },
      {
        path: 'DoctorRoom', component: DoctorRoomComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Doctor', 'Patient'] }
      },
      {
        path: 'DocUpdateProfile', component: DoctorUpdateProfileComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Doctor'] }
      },
      {
        path: 'DailyVisit', component: DailyVisitComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Doctor'] }
      },
      {
        path: 'DocPatientList', component: PatientListComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Doctor'] }
      },
      {
        path: 'DocReport', component: DoctorReportComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Doctor'] }
      },
      {
        path: 'PatDashboard', component: PatientDashboardComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient'] }
      },
      {
        path: 'HospitalList', component: HospitalComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin', 'Patient'] }
      },
      {
        path: 'HospitalSpecificDoctorList', component: HospitalSpecificDoctorListComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient'] }
      },
      {
        path: 'DocProfile', component: DoctorProfileComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin', 'Patient'] }
      },
      {
        path: 'PatUpdateProfile', component: PatientUpdateProfileComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient'] }
      },
      {
        path: 'PatBookAppointment', component: PatientBookAppointmentComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient', 'Admin'] }
      },
      {
        path: 'PaymentSelection', component: PaymentSelectionComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient', 'Admin'] }
      },
      {
        path: 'PatBookList', component: MyBooKListComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient'] }
      },
      {
        path: 'PatPaidBookingList', component: PaidBookingListComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient'] }
      },
      {
        path: 'PatHistory', component: PatientHistoryComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient'] }
      },
      {
        path: 'PatientMedicalRecord', component: PatientMedicalRecordComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient'] }
      },
      {
        path: 'PatientLabReports', component: PatientLabReportsComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient'] }
      },
      {
        path: 'PayThroughCreditCard', component: PayThroughCreditCardComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient'] }
      },
      {
        path: 'WaitingRoom', component: WaitingRoom,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient', 'Doctor'] }
      },
      {
        path: 'FinalReport', component: FinalReportComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Patient'] }
      },
      {
        path: 'admin', component: AdminComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'scheduling', component: DoctorSchedulingComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'labreportupload', component: LabReportUploadComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'doclist', component: DoctorListComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'addAppointment', component: AddAppointmentComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'Setup', component: SetUpComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'MeetingSetup', component: MeetingsetupComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'Danphecare', component: DanphecareComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'ContactUs', component: ContactUsComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'PatientAppointmentList',
        children: [
          { path: 'ByPhone', component: AppointmentsOverPhoneComponent },
          { path: '', component: AdminPanelPatientAppointment }
        ],
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'adminpatlist', component: AdminPatientListComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'ContatctInformation', component: ContactInfoComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'Report', component: ReportComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'AddOPDSummary', component: AddOPSummaryComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Doctor'] }
      },
      {
        path: 'Product',
        children: [
          { path: '', component: ProductListComponent },
          { path: 'Add', component: AddProductComponent },
          { path: ':id', component: ViewProductComponent },
          { path: ':id/Edit', component: AddProductComponent },
        ],
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      {
        path: 'Order', component: OrderComponent,
        canActivate: [RoleGuardService], data: { allowedUserType: ['Admin'] }
      },
      // otherwise redirect to login
      { path: '**', redirectTo: '' }

    ])
  ],
  exports: [
    RouterModule
  ]
})
export class TeleAppRoutingModule { }

