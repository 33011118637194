import { Component, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../app.global';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { DoctorService } from '../../services/doctor/doctor.service';
import { AlertService } from '../../services/alert.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { HospitalService } from '../../services/hospital/hospital.service';
import * as moment from 'moment';
import { PatientService } from '../../services/patient/patient.service';
import { ViewOPSummaryComponent } from 'src/app/Doctor/OP_Summary/app.View_OP_summary';

@Component({
  templateUrl: './doctor_report.component.html',
  styleUrls: ['./doctor_report.component.css']
})
export class DoctorReportComponent {
   config: any;
   token: any;
   public showOPSummaryReport:boolean=false;
   public OPSummaryData: any;
   public collection = { count: 60, data: [] };
   public todate = moment().format('YYYY-MM-DD');
   public fromdate = moment().format('YYYY-MM-DD');
   public filteredOPSummaryList: any;
   public selAppointmentType: any = "";
   public selVisitType: any = "";
   public selDiagnosis: any = "";
   public showPrint: boolean = false;
   public printDetails: any;
   public showDetailView: boolean = false;
   public searchText: any;
   p: number = 1;
   public selItem: any = "";
   public selDepartment: any = "";
   public reportHeader:any;
   public showActions: boolean = true;
   public deptList: Array<any> = new Array<any>();
   public reportList:any;

  constructor(public routing: Router, public hospitalService: HospitalService, public authService: AuthenticationService, public alertService: AlertService,
    public global: Global, public notifyService: NotificationService, public dialog: MatDialog, public changeDetector: ChangeDetectorRef, public docService: DoctorService, public patService: PatientService ) {
    this.token = this.authService.currentUserValue;
    this.OPSummaryReport();
    this.GetDepartmentList();
  }
  pageChanged(event) {
    this.config.currentPage = event;
  }

  GetReportData(){
    this.hospitalService.getAppMasterReport().subscribe((res: any) => {
      this.OPSummaryData = res?.Results;
      for (var i = 0; i < this.OPSummaryData.length; i++) {
        this.OPSummaryData = this.OPSummaryData.filter(x => x.DoctorId == this.token.DoctorIdentifier);
       // this.OPSummaryData[i].VisitDate = moment(this.OPSummaryData[i].VisitDate).format("YYYY-MM-DD");
      }
      this.collection = { count: this.OPSummaryData.length, data: [] }
      for (var i = 0; i < this.collection.count; i++) {
        this.collection.data.push(
          {
            id: i + 1,
            VisitDate: moment(this.OPSummaryData[i].VisitDate).format("YYYY-MM-DD"),
            PatientName: this.OPSummaryData[i].PatientName,
            ContactNumber: this.OPSummaryData[i].ContactNumber,
            Address: this.OPSummaryData[i].Address,
            DoctorName: this.OPSummaryData[i].DoctorName,
            Age: this.OPSummaryData[i].Age,
            BookingTime: this.OPSummaryData[i].BookingTime,
            HospitalId: this.OPSummaryData[i].HospitalId,
            DepartmentId: this.OPSummaryData[i].DepartmentId,
            DoctorId: this.OPSummaryData[i].DoctorId,
            PatientId: this.OPSummaryData[i].PatientId,
            VisitId: this.OPSummaryData[i].VisitId,
            SchedulingId: this.OPSummaryData[i].SchedulingId,
            Gender: this.OPSummaryData[i].Gender,
            DepartmentName: this.OPSummaryData[i].DepartmentName,
            AppointmentType: this.OPSummaryData[i].AppointmentType,
            VisitType: this.OPSummaryData[i].FollowUpVisitType,
            ShowViewOPSummary: this.OPSummaryData[i].ShowViewOPSummary
          }
        );
      }
      this.FilterbyDate();
    },
    error => {
      this.alertService.error(error);
    });
  }

  OPSummaryReport(){
    this.showOPSummaryReport = true;
    this.todate = moment().format('YYYY-MM-DD');
    this.fromdate = moment().format('YYYY-MM-DD');
    this.selAppointmentType = "";
    this.selVisitType = "";
    this.selDiagnosis = "";
    this.p = 1;
    this.GetReportData();
  }
  FilterbyDate() {
    this.filteredOPSummaryList = new Array<any>();
    this.filteredOPSummaryList = JSON.parse(JSON.stringify(this.collection.data));
    this.Search();
  }
  Search() {
    var todatemoment = moment(this.todate, 'YYYY-MM-DD');
    var fromdatemoment = moment(this.fromdate, 'YYYY-MM-DD');
    var diff = moment(todatemoment).diff(fromdatemoment, 'days');
    if (diff >= 0) {
      this.filteredOPSummaryList = new Array<any>();
      this.filteredOPSummaryList = JSON.parse(JSON.stringify(this.collection.data));
      for (var i = 0; i < this.filteredOPSummaryList.length; i++) {
        this.filteredOPSummaryList[i].VisitDate = moment(this.filteredOPSummaryList[i].VisitDate, 'YYYY-MM-DD');
        // this.patBookList[i].VisitStartTime = moment(this.patBookList[i].VisitStartTime).format('LT');
      }
      this.filteredOPSummaryList = this.filteredOPSummaryList.filter(a => a.VisitDate <= todatemoment && a.VisitDate >= fromdatemoment);
      this.reportList = this.filteredOPSummaryList;
    } else {
      this.notifyService.showWarning("Sorry", "Please check date interval  !!");
    }
  }

  
  ViewOPSummary(data){  
    this.patService.getOPSummary(data.PatientId ,data.VisitId). subscribe(res => this.Successsummary(res),
    res => this.ErrorSuccesssummary(res)); 
  }

  Successsummary(data){
    if(data && data.IsSuccess && data.Results){
      const dialogRef = this.dialog.open(ViewOPSummaryComponent,
        { data: { 
          PatientId: data.Results.PatientId,
          PatientName: data.Results.PatientName,
          Age: data.Results.Age,
          Gender: data.Results.Gender,
          Address: data.Results.Address,
          ContactNumber: data.Results.ContactNumber,
          VisitDate: data.Results.VisitDate,
          BookingTime: data.Results.BookingTime,
          DepartmentName: data.Results.DepartmentName,
          DoctorName: data.Results.DoctorName,
          CreatedDate: data.Results.CreatedDate,
          Height: data.Results.Height,
          Weight: data.Results.Weight,
          Temperature: data.Results.Temperature,
          BMI: data.Results.BMI,
          BP: data.Results.BP,
          Bodypain: data.Results.Bodypain,
          PainScale: data.Results.PainScale,
          LongSignature: data.Results.LongSignature,
          NMC: data.Results.NMC,
          Medication: data.Results.Medication,
          TreatmentAdvice: data.Results.TreatmentAdvice,
          History: data.Results.History,
          ChiefComplaints: data.Results.ChiefComplaints,
          Investigation: data.Results.Investigation,
          FinalDiagnosis: data.Results.FinalDiagnosis,
          Remarks: data.Results.Remarks,
          medicationlist :data.Results.medicationlist,
          VisitId: data.Results.Visitid
        }, width: '1000px', height: '1000px' }
        );
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {
          dialogRef.close();
          console.log(`Dialog result: ${result}`);
          //this.AppointmentHistory();
        });
    }
    else{
      this.notifyService.showError("", "OPSummary report is empty.")
    }
  }

  ErrorSuccesssummary(res){

  }

  PrintTxnTable() {
    try {
      this.showPrint = false;
      this.printDetails = null;
      this.showActions = false;
      this.changeDetector.detectChanges();
      this.showPrint = true;
      this.printDetails = document.getElementById("printpage").innerHTML;
      this.print();
    } catch (ex) {
      this.ShowCatchErrMessage(ex);
    }
  }
  public ShowCatchErrMessage(exception) {
    if (exception) {
      let ex: Error = exception;
      this.notifyService.showError("error", ["Check error in Console log !"]);
      console.log("Error Messsage =>  " + ex.message);
      console.log("Stack Details =>   " + ex.stack);
    }
  }

  print() {
    var contents = this.printDetails;
    var iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    
    if(this.showOPSummaryReport == true){
      this.reportHeader = "OP Summary Report";
    }
    else{
      this.reportHeader = "Order Wise Report";
    }
    let documentContent = "<html><head>";
    // documentContent += '<link rel="stylesheet" type="text/css" media="print" href="../../../css/PrintStyle.css"/>';
    documentContent += '<link rel="stylesheet" type="text/css" href="../../../../lib/bootstrap/dist/css/bootstrap.min.css"/>';
    documentContent += '<h5 style="text-align: center;"><b>' + this.reportHeader + '</b></h5></br></head>';
    documentContent += '<body onload="window.print()">' + contents + '</body></html>'
    var htmlToPrint = '' + '<style type="text/css">' + '.table_data {' + 'border-spacing:0px' + '}' + '</style>';
    htmlToPrint += documentContent;
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(htmlToPrint);
    iframe.contentWindow.document.close();

    setTimeout(function () {
      document.body.removeChild(iframe);
    }, 500);

    //this.notifyService.showSuccess('Success','Printed Successfully!!');
    this.showActions = true;
  }

  ExportToExcel(tableData) {
    var workSheetName;
    var Heading;
    var filename; 
    var ReportDateRange;
    if (tableData) {
      if(this.showOPSummaryReport == true){
        workSheetName = 'OP Summary Report';
        Heading = 'OP Summary Report';
        filename = 'OP_Summary_Report';
        ReportDateRange = this.fromdate +' To '+ this.todate;
      }
      else{
        workSheetName = 'Order Wise Report';
        Heading = 'Order Wise Report';
        filename = 'Order_Wise_Report';
        ReportDateRange = this.fromdate +' To '+ this.todate;
      }
      
      this.ConvertHTMLTableToExcel(tableData, workSheetName, Heading, filename,ReportDateRange);
    }
  }
  public ConvertHTMLTableToExcel(table: any, SheetName: string, TableHeading: string, FileName: string, ReportDateRange:string) {
    try {
      if (table) {
        this.showActions = false;
        this.changeDetector.detectChanges();
        let workSheetName = (SheetName.length > 0) ? SheetName : 'Sheet';
        let PrintDate = '<b>Created Date:' + moment().format('YYYY-MM-DD') + '</b><br />'
        let DateRange = '<b>Date Range :' + ReportDateRange + '</b><br />'
        let Heading = '<h3 style="text-align: center;" >' + TableHeading + '</h3>';
        let filename = (FileName.length > 0) ? FileName : 'Exported_Excel_File';
        filename = filename + '_' + moment().format('YYYYMMMDDhhss') + '.xls';

        let uri = 'data:application/vnd.ms-excel;base64,'
          , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{PrintDate}{DateRange}{Heading}{table}</table></body></html>'
          , base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }
          , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
        if (!table.nodeType) table = document.getElementById(table)
        var ctx = { worksheet: workSheetName, table: table.innerHTML, PrintDate: PrintDate,DateRange:DateRange, Heading: Heading }
        //return window.location.href = uri + base64(format(template, ctx))             
        var link = document.createElement('a');
        link.href = uri + base64(format(template, ctx));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.showActions = true;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  OrderWiseReport(){
    this.showOPSummaryReport = false;
    this.todate = moment().format('YYYY-MM-DD');
    this.fromdate = moment().format('YYYY-MM-DD');
    this.selItem = "";
    this.selDepartment = "";
    this.selAppointmentType = "";
    this.selVisitType = "";
    this.p = 1;
    this.GetReportData();
  }

  DetailedView(){
    this.showDetailView = true;
  }
  SummaryView(){
    this.showDetailView = false;
  }

  GetDepartmentList() {
    this.docService.getDepartmentList().subscribe(res => {
      this.deptList = res;
    },
      error => {
        this.alertService.error(error);
        //this.loading = false;
      });
  }
  
  MainSearch(event:MatSelectChange){
    this.filteredOPSummaryList = this.reportList;

    //For item search
    if(this.selItem != "" && this.selItem!="All")
    {
      for(var i=0;i<this.filteredOPSummaryList.length;i++)
      this.filteredOPSummaryList=this.filteredOPSummaryList.filter(d=>d.ItemName==this.selItem)
    }

    //For department search
    if(this.selDepartment != "" && this.selDepartment!="All")
    { 
      for(var i=0;i<this.filteredOPSummaryList.length;i++)
      this.filteredOPSummaryList=this.filteredOPSummaryList.filter(d=>d.DepartmentId==this.selDepartment)
    }

    //For appointment search
    if(this.selAppointmentType != "" && this.selAppointmentType!="All")
    {
      for(var i=0;i<this.filteredOPSummaryList.length;i++)
      this.filteredOPSummaryList=this.filteredOPSummaryList.filter(d=>d.AppointmentType==this.selAppointmentType)
    }

    //For visit search
    if(this.selVisitType != "" && this.selVisitType!="All")
    {
      for(var i=0;i<this.filteredOPSummaryList.length;i++)
      this.filteredOPSummaryList=this.filteredOPSummaryList.filter(d=>d.VisitType==this.selVisitType)
    }

    //For diagnosis search
    if(this.selDiagnosis != "" && this.selDiagnosis!="All")
    {   
      for(var i=0;i<this.filteredOPSummaryList.length;i++)
      this.filteredOPSummaryList=this.filteredOPSummaryList.filter(d=>d.FinalDiagnosis==this.selDiagnosis)
    }

    if(this.p != 1){
      this.p = 1;
    }
  }

  filterReport(){
    if(this.p != 1){
      this.p = 1;
    }
  }
  
}
