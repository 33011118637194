<app></app>
<div class="back-bg">
  <button [routerLink]="['/admin']" class="back-btn-doc-room">
    Back <span>To Admin</span>
  </button>

  <mat-chip-list aria-label="doctor selection">
    <mat-chip color="primary" (click)="regDoc()" selected>+ Register Doctor</mat-chip>
  </mat-chip-list>
</div>

<div class="grid-section" style="padding: 15px 25px ;">
  <div class="row searchbar-card text-center" style="margin-top: 0">
    <div class="col-md-3 text-left mt-auto">
      <h4 class="title">
        <i class="fa fa-list"></i>Doctor List
      </h4>
    </div>

    <div class="col-md-6 col-sm-8 d-flex">
      <div class="searchbar">
        <input class="search_input" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Doctor..">
        <a class="search_icon text-white"><i class="fa fa-search"></i>Search</a>
      </div>
    </div>
  </div>
  <!-- for-mobile -->
  <!-- class="d-sm-none d-md-block" -->
  <div *ngIf="searchText == ''">
    <table class="table table-striped table-hover ">
      <thead>
        <tr class="table-tr-style">
          <th scope="col">SN</th>
          <th scope="col">Full Name</th>
          <th scope="col">Contact Number</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of doctorList | paginate: { itemsPerPage: itemsPerPage, currentPage: p}  ;let i = index  ">
          <td>{{itemsPerPage *(p-1)+(i+1)}}</td>
          <td>{{data.DoctorName}}</td>
          <td>{{data.PhoneNumber }}</td>
          <td>
            <a (click)="UpdateProfile(data)" class="btn btn-success btn-sm text-white"><i class="fa fa-pencil"></i>Update Profile </a>
            <a (click)="ResetPassword(data)" data-toggle="modal" data-target="#passworddocModal" class="btn btn-success btn-sm text-white"><i class="fa fa-paperclip"></i>Reset Password </a>
            <a *ngIf="data.IsActive == true" (click)="Deactivate(data)" class="btn btn-success btn-sm text-white"><i class="fa fa-remove"></i>Deactivate </a>
            <a *ngIf="data.IsActive == false" (click)="Deactivate(data)" class="btn btn-success btn-sm text-white"><i class="fa fa-angle-right"></i>Activate </a>
          </td>

        </tr>
      </tbody>
    </table>

    <!-- <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> -->
    <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>
  </div>
  <div *ngIf="searchText != ''">
    <table class="table table-striped table-hover ">
      <thead>
        <tr class="table-tr-style">
          <th scope="col">SN</th>
          <th scope="col">Full Name</th>
          <th scope="col">Contact Number</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of doctorList | filter:searchText  ;let i = index  ">
          <td>{{i+1}}</td>
          <td>{{data.DoctorName}}</td>
          <td>{{data.PhoneNumber }}</td>
          <td>
            <a (click)="UpdateProfile(data)" class="btn btn-success btn-sm text-white"><i class="fa fa-pencil"></i>Update Profile </a>
            <a (click)="ResetPassword(data)" data-toggle="modal" data-target="#passworddocModal" class="btn btn-success btn-sm text-white"><i class="fa fa-paperclip"></i>Reset Password </a>
            <a *ngIf="data.IsActive == true" (click)="Deactivate(data)" class="btn btn-success btn-sm text-white"><i class="fa fa-remove"></i>Deactivate </a>
            <a *ngIf="data.IsActive == false" (click)="Deactivate(data)" class="btn btn-success btn-sm text-white"><i class="fa fa-angle-right"></i>Activate </a>
          </td>

        </tr>
      </tbody>
    </table>
  </div>

</div>
<div class="modal" id="myModal" tabindex="-1" role="dialog" [style.display]="editModal ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="background-color: transparent; border: none">
      <!-- Modal body -->
      <doctor-update-profile #doctorEditor></doctor-update-profile>
    </div>
  </div>
</div>
<div class="modal" id="myModal" tabindex="-1" role="dialog" [style.display]="pwdModal ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="background-color: transparent; border: none;">

      <!-- Modal body -->
      <password-update #pwdEditor></password-update>

    </div>
  </div>
</div>
<footer></footer>