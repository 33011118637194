import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { LocationStrategy, HashLocationStrategy, DatePipe, CommonModule } from "@angular/common";
import { DoctorReportComponent } from "./Doctor/doctor_report/doctor_report.component";
import { OrderComponent } from "./components/admin/order/order.component";
import { DoctorSchedulingComponent } from "./components/admin/doctorscheduling/doctor-scheduling.component";
import { DoctorListComponent } from "./Doctor/doctor_list/doctor_list.component";
import { PatientHistoryComponent } from "./Patient/patient_history/app.patient_history";
import { ClinicComponent } from "./app.cliniccomponent";
import { AdminComponent } from "./components/admin/admin.component";
import { ContactUsComponent } from "./Contact_Us/app.contactUs";
import { LoginComponent } from "./components/login/login.component";
import { ViewOPSummaryComponent } from "./Doctor/OP_Summary/app.View_OP_summary";
import { PatientMedicalRecordComponent } from "./Patient/patient_medical_record/app.patient_medical_record";
import { AddOPSummaryComponent } from "./Doctor/OP_Summary/app.Add_OP_summary";
import { DailyVisitComponent } from "./Doctor/doctor_dailyvisit/app.dailyvisitcomponent";
import { FinalReportComponent } from "./app.finalreportcomponent";
import { LabReportUploadComponent } from './components/admin/lab-report-upload/lab-report-upload.component';
import { PatientLabReportsComponent } from './Patient/patient_lab_reports/app.patient_lab_reports';
import { AddProductComponent } from "./components/admin/product/add-product/add-product.component";
import { ProductListComponent } from './components/admin/product/product-list/product-list.component';
import { AdminHelpDeskComponent } from "./components/admin/admin_helpdesk/app.admin_helpdesk";
import { PatientUpdateProfileComponent } from "./Patient/patient_updateprofile/app.patient_updateprofile";
import { AddAppointmentComponent } from "./components/admin/doctorscheduling/add-appointment/add-appointment.component";
import { PatientBookingHomeComponent } from "./Patient/patient_booking/app.booking_home";
import { AppComponent } from "./components/app.component";
import { DoctorDashboardComponent } from "./Doctor/doctor_dashboard/app.doctor-dashboard";
import { DanphecareComponent } from "./Danphecare/app.danphecare";
import { PayThroughCreditCardComponent } from "./Patient/patient_payment/app.paythroughcreditcardcomponent";
import { PaymentSelectionComponent } from "./Patient/patient_payment/app.paymentselection.component";
import { ReportComponent } from "./components/admin/report/report.component";
import { FooterComponent } from "./components/common/footer.component";
import { MyBooKListComponent } from "./Patient/patient_booking/app.mybooklist";
import { PaidBookingListComponent } from "./Patient/patient_booking/app.paidbookinglistcomponent";
import { DoctorUpdateProfileComponent } from "./Doctor/doctor_updateprofile/app.doctor_updateprofile";
import { PatientHelpDeskComponent } from "./Patient/patient_helpdesk/app.patient_helpdesk";
import { HospitalComponent } from "./app.hospitalcomponent";
import { BookingNavigatorComponent } from "./components/common/booking_navigator/booking.navigator.component";
import { AppointmentsAddComponent } from './components/admin/doctorscheduling/appointmentadd/appointment-add.component';
import { DoctorProfileComponent } from './Doctor/doctor_profile/app.doctor_profile';
import { PaymentMessageComponent } from "./PaymentMessage/app.paymentmessage.component";
import { DoctorRoomComponent } from "./Doctor/doctor_doctorroom/app.doctorroomcomponent";
import { PatientListComponent } from "./Doctor/doctor_patientlist/app.patientlistcomponent";
import { RescheduleComponent } from "./Doctor/doctor_reschedule/app.doctor_reschedule";
import { SetUpComponent } from "./components/admin/setup/app.set-up";
import { AdminPatientListComponent } from "./components/admin/patient_list/patient_list.component";
import { PopUpDialogComponent } from "./components/common/popup/pop-up.component";
import { HospitalSpecificDoctorListComponent } from "./app.hospitalspecificdoclist.component";
import { PasswordComponent } from "./components/common/password/password.component";
import { PatientBookAppointmentComponent } from "../app/Patient/patient_booking/app.patient_bookappointment";
import { PatientDashboardComponent } from "./Patient/patient_dashboard/app.patient_dashboard";
import { ContactInfoComponent } from "./components/admin/contact_Info/contact_information";
import { AppointmentsOverPhoneComponent } from "./components/admin/patientappointment/appointments-over-phone/appointments-over-phone.component";
import { ViewProductComponent } from './components/admin/product/view-product/view-product.component';
import { WaitingRoom } from "./Patient/patient_waitingroom/app.waitingroomcomponent";

//import { LoginComponent } from './app.login';

import { Global } from "./app.global";
import { ProductEndPoint } from "./services/product/product-endpoint.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TeleAppRoutingModule } from "./app.routing";
import { YesNoPipe } from "src/common/YesNo.pipe";
import "zone.js";
import { ConfigService } from "src/common/common.appconfig";
import { SafePipe } from "src/common/common.safe";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RegisterComponent } from "./components/register/register.component";
import { DoctorService } from "../../src/app/services/doctor/doctor.service";
import { DoctorEndpoint } from "./services/doctor/doctor-endpoint.service";
import { HospitalEndpoint } from "./services/hospital/hospital-endpoint.service";
import { HospitalService } from "./services/hospital/hospital.service";
import { PatientService } from "./services/patient/patient.service";
import { MeetingService } from "./services/Meeting/meeting.service";
import { PatientEndpoint } from "./services/patient/patient-endpoint.service";
import { ToastrModule } from "ngx-toastr";
import { NgxPaginationModule } from "ngx-pagination";
import { NgbModalModule, NgbModule, } from "@ng-bootstrap/ng-bootstrap";
import { FullCalendarModule } from "@fullcalendar/angular";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime-ex";
import { TokenInterceptorService } from "./services/tokeninterceptor.service";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { SchedulingService } from "./services/scheduling/scheduling.service";
import { SchedulingEndpoint } from "./services/scheduling/scheduling-endpoint.service";
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EqualValidator } from "./components/directives/equal-validator.directive";
import { OnlyNumber } from "./components/directives/numbers-only.directive";
import { ConsentFormComponent } from "./ConsentForm/app.consentformcomponent";
import { TimeFormat } from "./components/common/date_pipe/date_pipe";
import { ChatService } from "./services/ChatNotification/chat-notification.service";
import { AutoLogoutService } from "./services/autologout.service";
import { AdminPanelPatientAppointment } from "./components/admin/patientappointment/admin.patientappointment.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { LoginEndpoint } from "./services/login/login-endpoint.service";
import { LoginService } from "./services/login/login.service";
import { EventNotificationService } from "./services/EventNotification/event-notification.service";
import { ProductService } from "./services/product/product.service";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { LoaderComponent } from "./components/common/loader/loader.component";
import { LoaderInterceptor } from "./services/loader-interceptor/loader-interceptor.service";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatRadioModule } from "@angular/material/radio";
import { MatInputModule } from "@angular/material/input";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MatChipsModule } from "@angular/material/chips";
import { MeetingsetupComponent } from './components/admin/meetingsetup/meetingsetup.component';
import { MeetingEndPoint } from "./services/Meeting/meeting-endpoint.service";


const initializerConfigFn = (config: ConfigService) => {
  return () => {
    var ret: any = config.loadAppConfig();
    return ret;
  };
};
@NgModule({
  declarations: [
    LoginComponent,
    DoctorRoomComponent,
    OnlyNumber,
    PaymentMessageComponent,
    DoctorProfileComponent,
    WaitingRoom,
    ClinicComponent,
    FinalReportComponent,
    PatientListComponent,
    YesNoPipe,
    SafePipe,
    DoctorDashboardComponent,
    DoctorUpdateProfileComponent,
    EqualValidator,
    PatientDashboardComponent,
    PatientUpdateProfileComponent,
    PatientBookAppointmentComponent,
    PaymentSelectionComponent,
    PatientBookingHomeComponent,
    AppComponent,
    RegisterComponent,
    PatientHistoryComponent,
    DailyVisitComponent,
    PatientBookingHomeComponent,
    MyBooKListComponent,
    PayThroughCreditCardComponent,
    PaidBookingListComponent,
    HospitalComponent,
    AdminComponent,
    DoctorSchedulingComponent,
    AppointmentsAddComponent,
    FooterComponent,
    AddAppointmentComponent,
    ConsentFormComponent,
    BookingNavigatorComponent,
    TimeFormat,
    RescheduleComponent,
    SetUpComponent,
    DanphecareComponent,
    ContactUsComponent,
    DoctorListComponent,
    AdminPanelPatientAppointment,
    AdminPatientListComponent,
    PopUpDialogComponent,
    HospitalSpecificDoctorListComponent,
    PasswordComponent,
    ContactInfoComponent,
    ReportComponent,
    DoctorReportComponent,
    PatientMedicalRecordComponent,
    AddOPSummaryComponent,
    ViewOPSummaryComponent,
    LabReportUploadComponent,
    PatientLabReportsComponent,
    AddProductComponent,
    ProductListComponent,
    ViewProductComponent,
    AppointmentsOverPhoneComponent,
    PatientHelpDeskComponent,
    AdminHelpDeskComponent,
    OrderComponent,
    LoaderComponent,
    MeetingsetupComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxDocViewerModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxExtendedPdfViewerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
        positionClass: 'toast-bottom-right',
    }),
    TeleAppRoutingModule,
    BrowserAnimationsModule,
    NgxDocViewerModule,
    NgbModule,
    NgbModalModule,
    FullCalendarModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDividerModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatListModule,
    MatCardModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTabsModule,
    MatNativeDateModule,
    MatChipsModule,
    MatTableModule,
    MatMomentDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbModule,
    Ng2SearchPipeModule,
    BsDatepickerModule.forRoot(),
    SlickCarouselModule,
    DragDropModule
  ],
  providers: [
    {
      //provide: APP_BASE_HREF,
      ////useValue: '/' + (window.location.pathname.split('/')[1] || ''),
      //useValue: '/',
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerConfigFn,
      multi: true,
      deps: [ConfigService],
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: [],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    Global,
    DoctorService,
    DoctorEndpoint,
    HospitalEndpoint,
    HospitalService,
    PatientEndpoint,
    PatientService,
    MeetingService,
    MeetingEndPoint,
    DatePipe,
    SchedulingService,
    SchedulingEndpoint,
    ChatService,
    AutoLogoutService,
    TokenInterceptorService,
    LoginService,
    LoginEndpoint,
    EventNotificationService,
    ProductService,
    ProductEndPoint
  ],
  bootstrap: [ClinicComponent],
})
export class AppModule {
  constructor(g: Global) {
    // alert(g.config.videoUrl);
  }
}
