import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { SchedulingService } from 'src/app/services/scheduling/scheduling.service';
import { NotificationService } from 'src/app/services/notification.service';
import { HospitalService } from 'src/app/services/hospital/hospital.service';
import { Hospital, HospitalEdit } from 'src/app/models/hospital/hospital.model';
import { HttpRequest, HttpClient, HttpEventType } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  templateUrl: './app.set-up.html',
  styleUrls: ['./app.set-up.css']
})
export class SetUpComponent implements OnInit {
  public edit: boolean = false;
  public editModal: boolean = false;
  public hospException: boolean = false;
  public hospitalList: Array<Hospital> = new Array<Hospital>();
  public hospitalInfo: Hospital = new Hospital();
  public selectedHospital: HospitalEdit = new HospitalEdit();
  public hospitalData: any;
  public viewhospital: any;
  public progress: any;
  public message: any;
  public tempvalue: string = "";
  constructor(public routing: Router, public http: HttpClient, public hospitalService: HospitalService, public schedulingService: SchedulingService, public notifyService: NotificationService, private _authenticationService: AuthenticationService) {

  }
  ngOnInit() {
    this.getHospital();
  }

  public Admin() {
    this.routing.navigate(['/admin']);
  }
  getHospital() {
    this.hospitalService.getHospitalList().subscribe(res => this.SuccessHospitalList(res),
      res => this.Error(res));
  }
  SuccessHospitalList(res) {
    this.hospitalList = Object.assign(this.hospitalList, res)
  }
  Error(res) {
    this.notifyService.showError("Error", "Internal Error")
  }
  ErrorAdd(res) {
    this.notifyService.showError("Error", "Internal Error")
  }
  ErrorEdit(res) {
    this.notifyService.showError("Error", "Internal Error")
  }


  AddHospital(form: NgForm) {

    this.edit = false;
    this.hospException = false;
    if (form.invalid) {
      if (form.controls.HospitalName.invalid) {
        this.notifyService.showError("", "Hospital name is required");
      }
      if (form.controls.HospitalCode.invalid) {
        this.notifyService.showError("", "Hospital code is required");
      }
      if (form.controls.Location.invalid) {
        this.notifyService.showError("", "Location is required");
      }
      return;
    }
    if (this.hospitalList.length != 0) {
      this.hospException = this.hospitalList.some(a => a.HospitalCode.trim() == this.hospitalInfo.HospitalCode.trim());
    }
    if (this.hospException) {
      this.notifyService.showError("Sorry", "Hospital code is already exists!");
      return;
    }
    const token = this._authenticationService.currentUserValue;
    const adminId = token.unique_name;
    this.hospitalInfo.AdminId = adminId;
    this.hospitalService.addHospital(this.hospitalInfo).subscribe(res => this.Success(res),
      res => this.ErrorAdd(res));


  }
  Success(res) {
    window.location.reload();
    this.notifyService.showSuccess('', 'Hospital Added Successfully!')

  }
  EditHospital(id) {
    this.edit = true;
    this.editModal = true;
    this.hospitalService.getHospital(id).subscribe(res => this.SuccessHospital(res),
      res => this.ErrorEdit(res));
  }
  SuccessHospital(res) {
    this.selectedHospital = res;
    this.tempvalue = this.selectedHospital.ImagePath;
    this.selectedHospital.ImagePath = null;
  }
  UpdateHospital(form: NgForm) {
    this.edit = true;
    this.hospException = false;
    if (form.invalid) {
      if (form.controls.HospitalName.invalid) {
        this.notifyService.showError("", "Hospital name is required");
      }
      if (form.controls.HospitalCode.invalid) {
        this.notifyService.showError("", "Hospital code is required");
      }
      if (form.controls.Location.invalid) {
        this.notifyService.showError("", "Location is required");
      }
      return;
    }

    if (this.selectedHospital.ImagePath == null) {
      if (this.tempvalue != null) {
        this.tempvalue = this.tempvalue.slice(10);
      }
      this.selectedHospital.ImagePath = this.tempvalue;
    }

    this.hospException = this.hospitalList.some(a => a.HospitalCode.trim() == this.selectedHospital.HospitalCode.trim() && a.HospitalId != this.selectedHospital.HospitalId);
    if (this.hospException) {
      this.notifyService.showError("Sorry", "Hospital code already exists!");
      return;
    }
    const token = this._authenticationService.currentUserValue;
    const adminId = token.unique_name;
    this.selectedHospital.AdminId = adminId;
      this.hospitalService.updateHospital(this.selectedHospital)
      .subscribe(res => this.SuccessUpdate(res), err => this.Error(err));
  }
  SuccessUpdate(res) {
    this.notifyService.showSuccess('', 'Successfully Updated');
    window.location.reload();
  }

  DeleteHospital(id) {
    this.hospitalService.deleteHospital(id).subscribe(res => this.SuccessDelete(res),
      res => this.Error(res));
  }
  SuccessDelete(res) {
    this.getHospital();
    this.notifyService.showSuccess('', 'Successfully Deleted!');
    window.location.reload();
  }
  hide() {
    this.editModal = false;
  }

  upload(files) {

    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);


    const uploadReq = new HttpRequest('POST', `api/Patient/UploadFile/`, formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response)
        this.message = event.body.toString();
    });

  }
  uploadEdit(files) {

    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);


    const uploadReq = new HttpRequest('POST', `api/Patient/UploadFile/`, formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response)
        this.message = event.body.toString();
    });

  }
}
