<style>
  Important part */ .modal-dialog {
    overflow-y: initial !important
  }

  .modal-body {
    height: 100;
    border-style: groove;
  }

  .main-title{
    text-align: center;
    border-style: groove;
    text-align: center;
    padding: 6px;
  }

  table{
    border-style: groove;

  }

  */
</style>
<mat-dialog-actions align="left">
    <button mat-button color="primary" style="position: absolute; right: -27px; top: -15px;font-size: 20px;"
      matDialogClose><i class="fa fa-times"></i></button>
  </mat-dialog-actions>
  <div class="modal-body" style="margin: 25px; margin-top: 0" id="convertToPdf" #convertToPdf>
    <div style="text-align: center;">
    <h3><strong class="main-title">OPD Visit</strong></h3>
    </div>

    <div style="height: 190px; border-style: groove;">
        <table style=" border-style: none;" align="left">
          <tr>
              <td>Patient Id:</td>
                <td>{{data.PatientId}}</td>
          </tr>
         
          <tr>
            <td>Name:</td>
            <td>{{data.PatientName}}</td>
          </tr>
          <tr>
            <td>Age/Sex:</td>
            <td>{{data.Age}}/{{data.Gender}}</td>
          </tr>
          <tr>
            <td>Address:</td>
            <td>{{data.Address}}</td>
          </tr>
          <tr>
            <td>contact Number:</td>
            <td>{{data.ContactNumber}}</td>
          </tr>
        </table>
        <table style=" border-style: none;" align="right">
          <tr>
              <td>Visit Date and Time:</td>
                <td>{{data.VisitDate | date:'mediumDate'}}:{{data.BookingTime}}</td>
          </tr>
         
          <tr>
            <td>Department:</td>
            <td>{{data.DepartmentName}}</td>
          </tr>
          <tr>
            <td>Doctor:</td>
            <td>{{data.DoctorName}}</td>
          </tr>
        </table>             
     </div>

      <div class="main-title">
        <div>
        <label>History:{{data.History}}</label>
        </div>
        <div>
          <label>Chief Complaints:{{data.ChiefComplaints}}</label>
        </div>
        </div>
        <div class="main-title">
          <label>Investigations:{{data.Investigation}}</label>
          </div>
          <div class="main-title">
            <label>Medication:</label>            
            <table class="table">
              <thead>
              <tr>
                <th>S.N</th>
                <th>Name of drugs</th>
                <th>Dose</th>
                <th>Frequency</th>
                <th>Remarks</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let med of data.medicationlist;let i = index;">
                <td> {{i+1}}</td>
                <td> {{med.DrugName}}</td>
                <td> {{med.Dose}}</td>
                <td> {{med.Frequency}}</td>
                <td> {{med.Remarks}}</td>               
              </tr>
              </tbody>
            </table>
            </div>
            <div style="height: 190px; border-style: groove;">
              <label style="display: block;font-size: x-large;text-align: center;">Vitals:</label>
              <table style=" border-style: none;margin-left: 120px;" align="left"> 
                <tr>
                  <td>Recorded On:</td>
                    <td>{{data.CreatedDate | date:'mediumDate'}}</td>
              </tr>
                <tr *ngIf="data.Height != null">
                    <td>Height:</td>
                      <td>{{data.Height}}</td>
                </tr>               
                <tr *ngIf="data.Weight != null">
                  <td>Weight:</td>
                  <td>{{data.Weight}}</td>
                </tr>
                <tr *ngIf="data.Tempreture != null">
                  <td>Temperature:</td>
                  <td>{{data.Temperature}}</td>
                </tr>
               
              </table>
              <table style=" border-style: none; margin-right: 120px;" align="right">
                <tr *ngIf="data.BMI != null">
                  <td>BMI:</td>
                  <td>{{data.BMI}}</td>
                </tr>
                <tr *ngIf="data.BP != null">
                    <td>Blood Pressure:</td>
                      <td>{{data.BP}}</td>
                </tr>
               
                <tr *ngIf="data.Bodypain != null">
                  <td>Body Pain:</td>
                  <td>{{data.Bodypain}}</td>
                </tr>
                <tr *ngIf="data.PainScale != null">
                  <td>Body Scale:</td>
                  <td>{{data.PainScale}}</td>
                </tr>
              </table>             
           </div>
            
            <div class="main-title">
              <label>Remarks: {{data.Remarks}}</label>
              </div>
              <div class="main-title">
                <label>Final Diagnosis:{{data.FinalDiagnosis}} </label>
                </div>
                <div class="main-title">
                  <div class="col-md-12">
                  <label class="col-md-3">Signature:{{data.LongSignature}}</label>
                  <label class="col-md-3">Name:{{data.DoctorName}}</label>
                  <label class="col-md-3">NMC:{{data.NMC}}</label>
                  <label class="col-md-3">Department:{{data.DepartmentName}}</label>
                  </div>
                  </div> 
        

    
  </div>
  <button (click)="Download()">Download</button>
