<app></app>
<div class="bg-back">
  <button (click)="BackDashBoard()"
          class="back-btn-doc-room">Back To DashBoard </button>
</div>



<section class="grid">
  <div style="overflow: scroll;">
    <h4 class="title mt-3"><em class="fa fa-book"></em>My Lab Reports</h4>
    <table class="table"
           aria-describedat="Grid to Show Lab Reports">
      <thead>
        <tr>
          <th scope="col">Patient Name</th>
          <th scope="col">Test Report</th>
          <th scope="col">Uploaded By</th>
          <th scope="col">Uploaded On</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let record of collection.data | paginate: { itemsPerPage: 10, currentPage: p }">
          <td>{{record.FamilyFullName ? record.FamilyFullName : record.PatientName}}</td>
          <td>{{record.TestReportType}}</td>
          <td>{{record.UploadedBy }}</td>
          <td>{{record.UploadedDate | date: 'yyyy-MM-dd' }}</td>
          <td>
            <button type="button"
                    data-toggle="modal"
                    data-target="#target"
                    (click)="ViewFile(record,record.FilePath,record.FileExtention,record.FileName)"
                    class="btn btn-sm btn-primary">
              <em class="fa fa-eye"></em>
              View
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <pagination-controls style="font-size:20px"
                         (pageChange)="p = $event"></pagination-controls>

  </div>
</section>




<div class="modal"
     id="myModal"
     [style.display]="showView ? 'block' : 'none'">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header"
           style="background-color: white">
         {{title}}
        <button type="button"
                class="close"
                data-dismiss="modal"
                (click)="hide()">
          X
        </button>
      </div>
      
      <div class="modal-body"
           style="background-color: floralwhite">
        <div class="info-heading">
          <img *ngIf="Filetype == 'image'"
          alt="info-image"
          [src]="filePath"
          class="patient_lab_report_image"/>
              <!-- Rohan: 3/1/2024: Added button for downloading the image. -->
        <a *ngIf="Filetype == 'image'" 
        [href]="filePath" 
        target="_blank" 
          [download]="title + '.' + fileExtension" 
        class="btn btn-sm btn-primary col-md-2 mt-2" 
        style="float: right;">
      
        <i class="fa fa-download"></i>
        Download
        </a>
          <iframe *ngIf="Filetype == 'pdf'"
          [src]="filePath"
          class="patient_lab_report_pdf">
          </iframe>
          <!-- <div *ngIf="Filetype == 'docfile'">
            <a [href]="filePath"
               class="btn btn-primary btn-lg active"
               role="button">Click to Open Doc File</a>
          </div> -->
          <div *ngIf="Filetype == 'docfile'">
            <a [href]="filePath" [download]="title + '.' + fileExtension"
               class="btn btn-primary btn-lg active"
               role="button">Click to Download Doc File</a>
          </div>

        </div>
        <!-- Rohan: 2/29/2024: We are now using iFrame to render pdf documents which have inbuilt feature to download the pdf. Hence, we won't be needing the separate action to download the pdf. -->
        <!-- <a href="filePath"
           target="_blank"
           [download]="fileName"
           class="btn btn-sm btn-primary col-md-2 mt-2"
           style="float: right;">
          <i class="fa fa-download"></i>
          Download
        </a> -->

      </div>

    </div>
  </div>
</div>

<footer></footer>
