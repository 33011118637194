import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { Scheduling } from '../../../../models/scheduling/scheduling.model';

@Component({
  selector: 'appointments-add',
  templateUrl: './appointment-add.component.html',
  styleUrls: ['./appointment-add.component.css']
})
export class AppointmentsAddComponent implements OnInit {

  @ViewChild('picker', { static: true }) datePicker: MatDatepicker<Date>;

  appointment: Scheduling;
  hasFormErrors: boolean = false;
  private subscriptions: Subscription[] = [];
  appointment$: Observable<Scheduling>;
  //public exampleData: Array<Select2OptionData>;
  //public options: Select2Options;
  meridian = true;
  startDateTime;
  currentTime: any;
  endDateTime;
  selectedDate;
  selecTimeErr: boolean = false;
  selecTimeErrText: string;
  participantList: any;
  buttonText: string;
  reschedule: boolean = false;
  headerTitle: string = "New Appointment";
  endtime: any;
  endtimeHour: any;
  endtimeMinute: any;
  calendarView: any;
  viewLoading: boolean = false;
  loadingAfterSubmit: boolean = false;
  practitionerList: any;
  practitionerData: any;
  addressResult: any;
  selectedAddress: string;
  selectedParticipant: any;
  showAddress: boolean = false;
  inputDisabled: boolean = false;
  hasSchoolAddress: boolean = false;
  addressOptions: string[];
  teamList: any;
  teamOptions: any;
  selectedTeam = new FormControl();
  filteredTeamOptions: Observable<string[]>;
  selectedTeamID: string;
  listAppointmentType: any;
  participantName: string;
  showParticipant: boolean = true;

  constructor(public dialogRef: MatDialogRef<AppointmentsAddComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.appointment = new Scheduling;
    this.buttonText = "Create Appointment";
    this.appointment.StartTime = this.data.date;
    var outputTime = moment(this.appointment.StartTime, 'MMMM Do YYYY, h:mm:ss a').add(30, 'minutes').format();
    this.endtime = moment(outputTime).format();
    //update appointment
    if (this.data.appointment && this.data.reschedule) {
      // this.appointment = this.data.appointment;
      this.buttonText = "Update Appointment";
      this.headerTitle = "Edit Appointment";
      this.appointment.SchedulingId = this.data.appointment.SchedulingId;
      this.appointment.StartTime = new Date(this.data.appointment.startTime);
      this.endtime = new Date(this.data.appointment.endTime);
      //this.appointment.addressType = this.data.appointment.addressType;
      //this.appointment.appointmentType = this.data.appointment.appointmentTypeId;
      this.showAddress = true;
      //this.appointment.location = this.data.appointment.location;
      //this.appointment.note = this.data.appointment.note;
    }
    //navigated from profile
    if (this.data.participantDetails) {
      this.participantName = this.data.participantDetails.firstName + ' ' + this.data.participantDetails.lastName;
      //this.appointment.location = this.data.participantDetails.address;
      //this.appointment.patientId = this.data.participantDetails.id;
      this.headerTitle = "New Appointment for " + this.participantName;
      this.showParticipant = false;
    }

    //this.loadParticipants();
    ////this.loadTeams();
    //this.loadAppointmentType();
    if (this.data.practitioner) {
      this.practitionerList = this.data.practitioner;
      //this.appointment.practitioner = this.practitionerList[0].id;
    } else {
      //this.loadPractitioner();
    }
  }


  //loadParticipants() {
  //  this.auth.listParticipant().subscribe(res => {
  //    this.participantList = res;
  //    this.appointment.participant = this.participantList[0].id;
  //    if (this.participantList[0] && this.participantList[0].address && this.participantList[0].schoolAddress) {
  //      this.addressOptions = ["Client Address", "School Address", "Other"];
  //    } else {
  //      this.addressOptions = ["Client Address", "Other"];
  //    }
  //    if (this.selectedAddress && this.selectedAddress == "Client Address") {
  //      this.appointment.location = this.selectedParticipant.address;
  //    } else if (this.selectedAddress && this.selectedAddress == "School Address") {
  //      this.appointment.location = this.selectedParticipant.schoolAddress;
  //    }

  //  })
  //}

  filterConfiguration(): any {
    const filter: any = {};
    return filter;
  }

  //public changed(value: any): void {
  //  this.appointment.participant = value.data[0].id;
  //  this.selectedParticipant = value.data[0];
  //  if (this.selectedAddress && this.selectedAddress == "Client Address") {
  //    this.appointment.location = this.selectedParticipant.address;
  //  } else if (this.selectedAddress && this.selectedAddress == "School Address") {
  //    this.appointment.location = this.selectedParticipant.schoolAddress;
  //  }
  //  if (this.selectedParticipant && this.selectedParticipant.address && this.selectedParticipant.schoolAddress) {
  //    this.addressOptions = ["Client Address", "School Address", "Other"];
  //  } else {
  //    this.addressOptions = ["Client Address", "Other"];
  //  }
  //}

  //therapistChanged(value) {
  //  this.appointment.practitioner = value.data[0].id;
  //}

  //loadAppointmentType() {
  //  this.auth.listAppointmentType().subscribe(res => {
  //    this.listAppointmentType = res;
  //  })
  //}



  //submit() {
  //  this.hasFormErrors = false;
  //  const newAppointment = new Appointment();
  //  newAppointment.clear();
  //  const preparedAppointment = this.prepareAppointment();
  //  const formattedStartTime = moment(this.appointment.starttimeObj).format('HH:mm');
  //  const formattedEndTime = moment(this.endtime).format('HH:mm');
  //  this.currentTime = moment();
  //  const prevDate = moment(this.appointment.starttimeObj).format('YYYY-MM-DD') + " ";
  //  const startTime = formattedStartTime;
  //  const endTime = formattedEndTime;
  //  this.startDateTime = moment(prevDate + startTime);
  //  this.endDateTime = moment(prevDate + endTime);
  //  if (this.startDateTime.isBefore(this.currentTime)) {
  //    this.selecTimeErr = true;
  //    this.selecTimeErrText = "The time is of the past";
  //    return;
  //  } else {
  //    this.selecTimeErr = false;
  //  }
  //  if (this.endDateTime.isBefore(this.startDateTime)) {
  //    this.selecTimeErr = true;
  //    this.selecTimeErrText = "End time should be greater than start time";
  //    return;
  //  } else {
  //    this.selecTimeErr = false;
  //  }
  //  //if (preparedAppointment.id) {
  //  //  this.updateAppointment(preparedAppointment);
  //  //} else {
  //  //  this.addAppointment(preparedAppointment);
  //  //}
  //}


  //prepareAppointment(): Appointment {
  //  const _appointment = new Appointment();
  //  _appointment.clear();
  //  _appointment.id = this.appointment.id;
  //  _appointment.practitionerId = this.appointment.practitioner;
  //  _appointment.AppointmentTypeId = this.appointment.appointmentType;
  //  _appointment.address = this.appointment.address;
  //  _appointment.location = this.appointment.location;
  //  _appointment.patientId = this.appointment.participant;
  //  _appointment.addressType = this.appointment.addressType;
  //  _appointment.startTime = moment(this.appointment.starttimeObj).format('YYYY-MM-DDTHH:mm:ss.SSS');
  //  _appointment.endTime = moment(this.endtime).format('YYYY-MM-DDTHH:mm:ss.SSS');;
  //  _appointment.appointmentDate = moment(this.appointment.starttimeObj).format('YYYY-MM-DDTHH:mm:ss.SSS');
  //  _appointment.note = this.appointment.note;
  //  return _appointment;
  //}

  //addAppointment(_appointment: Appointment) {
  //  this.store.dispatch(new AppointmentOnServerCreated({ appointment: _appointment }));
  //  const addSubscription = this.store.pipe(select(selectLastCreatedAppointmentId)).subscribe(newId => {
  //    if (!newId) {
  //      return;
  //    }
  //    this.dialogRef.close({
  //      _appointment,
  //    });
  //  })
  //}

  //updateAppointment(_appointment: Appointment) {
  //  this.auth.updateAppointment(_appointment).subscribe(res => {
  //    if (res.success) {
  //      this.dialogRef.close({
  //        _appointment,
  //        isEdit: true
  //      });
  //    }
  //  })

  //}

  /**
     * Returns is title valid
     */
  //isFormValid(): boolean {
  //  return (this.appointment && this.appointment.type && this.appointment.type.length > 0 && (this.appointment.practitioner && this.appointment.practitioner.length > 0) && (this.appointment.starttimeObj && moment(this.appointment.starttimeObj).isValid() && (this.endtime && moment(this.endtime).isValid())));
  //}

  //ngOnDestroy() {
  //  if (this.subscriptions) {
  //    this.subscriptions.map(item => item && item.unsubscribe());
  //  }
  //}

  //onChangeTime(time) {
  //  var outputTime = moment(time.value).add(30, 'minutes').format();
  //  this.endtime = outputTime;
  //}

  //prepareNotification(notificationType) {
  //  const practitioner = this.appointment.practitioner;
  //  const _notification = new Notification();
  //  _notification.clear();
  //  _notification.id = undefined;
  //  _notification.notify = "participant";
  //  _notification.message = practitioner + " has " + notificationType + " an appointment";
  //  _notification.read = false;
  //  _notification.time = new Date();
  //  _notification.redirectLink = "participant-appointments/view";
  //  _notification.appointmentDate = new Date(this.appointment.date);
  //  _notification.type = "appointment";
  //  return _notification;
  //}

  //loadPractitioner() {
  //  let queryParams = new QueryParamsModel(
  //    this.filterConfiguration(),
  //  );
  //  this.store.dispatch(new PractitionerPageRequested({ page: queryParams }));
  //  this.store.pipe(select(selectPractitionerInStore)).subscribe(res => {
  //    if (res.items.length == 0) {
  //      return;
  //    }
  //    this.practitionerList = res.items;
  //    this.appointment.practitioner = this.practitionerList[0].id;
  //  })
  //}

  //loadPractitionerWithTeam(teamId) {
  //  this.auth.findPractitionerWithTeam(teamId).subscribe(res => {
  //    if (res.length == 0) {
  //      return;
  //    }
  //    this.practitionerList = res;
  //    this.appointment.practitioner = this.practitionerList[0].id;
  //  })

  //}

  //handleAddressChange(address) {
  //  // console.log("address", address.geometry.location.lat());
  //  // console.log("Address", address);
  //  let streetNumber = "";
  //  let streetName = "";
  //  let city = "";
  //  let state = "";
  //  let postCode = "";
  //  let country = "";
  //  address.address_components.forEach(res => {
  //    res.types.includes("street_number") ? streetNumber = res.long_name : "";
  //    res.types.includes("route") ? streetName = res.long_name : "";
  //    res.types.includes("administrative_area_level_2") ? city = res.long_name : "";
  //    res.types.includes("administrative_area_level_1") ? state = res.long_name : "";
  //    res.types.includes("postal_code") ? postCode = res.long_name : "";
  //    res.types.includes("country") ? country = res.long_name : "";

  //  })


  //  this.appointment.address = {
  //    name: address.formatted_address, //prev location
  //    streetName: streetName,
  //    streetNumber: streetNumber,
  //    unit: null,
  //    city: city,
  //    state: state,
  //    postalCode: postCode,
  //    country: country,
  //    latitude: address.geometry.location.lat(),
  //    longitude: address.geometry.location.lng(),
  //    observations: null,
  //    addressType: null
  //  }
}

  //selectAddress(selectedAddress) {
  //  this.selectedAddress = selectedAddress;
  //  this.showAddress = true;
  //  if (this.selectedAddress == "Client Address") {
  //    this.appointment.location = this.selectedParticipant.address;
  //    this.inputDisabled = true;
  //  } else if (this.selectedAddress == "School Address") {
  //    this.inputDisabled = true;
  //    this.appointment.location = this.selectedParticipant.schoolAddress ? this.selectedParticipant.schoolAddress : "";
  //  } else {
  //    this.inputDisabled = false;
  //    this.appointment.location = "";
  //  }
  //}

  //closeDialogBox() {
  //  this.dialogRef.close();
  //}

  //loadTeams() {
  //  let queryParams = new QueryParamsModel(
  //    this.filterConfiguration(),
  //  );
  //  this.store.dispatch(new TeamsPageRequested({ page: queryParams }));
  //  this.store.pipe(select(selectTeamsInStore)).subscribe(res => {

  //    if (res.items.length == 0) {
  //      return;
  //    }

  //    this.teamList = res.items;
  //    this.teamOptions = this.teamList.map(team => {
  //      return team;
  //    })
  //    this.filteredTeamOptions = this.selectedTeam.valueChanges
  //      .pipe(
  //        startWith(''),
  //        map(value => this._filter(value))
  //      );
  //  })
  //}

  //private _filter(value: string): string[] {
  //  const filterValue = value;
  //  if (value == "") {
  //    // this.selectedClientId = "";
  //    // let calendarView = this.calendarComponent.getApi();
  //    // this.handleDatesRender(calendarView);
  //  }
  //  return this.teamOptions.filter(teamOption =>
  //    (teamOption.name).toLowerCase().includes(filterValue));
  //}

  //teamSelected(event) {
  //  this.selectedTeam.setValue(event.option.value.name);
  //  this.selectedTeamID = event.option.value.id;
  //  this.loadPractitionerWithTeam(this.selectedTeamID)
  //}

  //teamInputClick() {
  //  this.selectedTeam.setValue("");
  //  this.selectedTeamID = "";
  //  this.loadPractitioner();
  //}


