import { format } from "path";

export class Scheduling {
  public SchedulingId: string;
  public StartDate: Date;
  public Date: Date = new Date();
  public EndDate: Date;
  public StartTime: any;
  public EndTime: any;
  public HospitalDoctorMapId: string;
  public DepartmentId: string;
  public HospitalId: string;
  public DoctorId: string;
  public DepatmentName: string;
  public HospitalName: string;
  public DoctorName: string;
  public StartHours: number;
  public StartMinutes: number;
  public StartSeconds: number;
  public EndHours: number;
  public EndMinutes: number;
  public EndSeconds: number;
  public NewScheduleList: Array<SchedulingList> = new Array<SchedulingList>();
  public ScheduleInterval: Array<ScheduleInterval> = new Array<ScheduleInterval>();
  public AppointmentType: string;
  public AdminId: string;
}
export class SchedulingList {
  public StartHours: number;
  public StartMinutes: number;
  public StartSeconds: number;
  public EndHours: number;
  public EndMinutes: number;
  public EndSeconds: number;
  public StartTime: any;
  public EndTime: any;
  public AccomodatedPatient: number;

}
export class VisitDate {
  public VisitDate: Date;
  public StartTime: string;
  public EndTime: string;
  public StartHours: number;
  public StartMinutes: number;
  public EndHours: number;
  public EndMinutes: number;
  public HospitalId: string;
  public DepartmentId: string;
  public DoctorId: string;
  public StartMeridian: string;
  public EndMeridian: string;
}
export class ScheduleInterval {
  public ScheduleIntervalId: string;
  public SchedulingId: string;
  public StartTime: string;
  public Date: Date;
  public EndTime: string;
  public IsBooked: boolean;
  public IsActive: boolean;
  public VisitId: string;
  public HospitalDoctorMapId: string;
}

