
        <div class="row" >
          <div class="col-lg-5 col-md-12 col-sm-12">
            <p class="copyright">
              Copyright &copy; 2023 <a href="{{websiteUrl}}" target="_blank">lumbini provincial hospital<img src="~/../assets/img/logo-cap.png" class="f-img" /></a>
            </p>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12 text-center">
            <ul class="social-icons">
              <li class="d-inline"><a href="https://www.facebook.com/lumbinizonalhospital?mibextid=ZbWKwL" target="_blank"><i class="fa fa-facebook"></i></a></li>
              <li class="d-inline"><a href="#" target="_blank"><i class="fa fa-twitter"></i></a></li>
              <li class="d-inline"><a href="#" target="_blank"><i class="fa fa-instagram"></i></a></li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 text-right text-sm-center">
            <p class="copyright">
              Powered by <a href="https://imarkdigital.com/" target="_blank">Imark<img src="~/../assets/img/imark-powered.png" class="f-img" /></a>
            </p>
          </div>
        </div>

