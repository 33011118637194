import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../app.global';
import { Router } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationService } from '../../services/authentication.service';
import { PatientService } from '../../services/patient/patient.service';
import { AlertService } from '../../services/alert.service';
import { DoctorService } from '../../services/doctor/doctor.service';
import * as moment from 'moment';
import { Visit } from 'src/app/models/visit/visit.model';
import { SchedulingService } from 'src/app/services/scheduling/scheduling.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RescheduleComponent } from '../doctor_reschedule/app.doctor_reschedule';
import { MatDialog } from '@angular/material/dialog';
import { DoctorUpdateProfileComponent } from '../doctor_updateprofile/app.doctor_updateprofile';
import { Doctor } from '../../models/doctor/doctor.model';
import { PasswordComponent } from '../../components/common/password/password.component';

@Component({
  templateUrl: './doctor_list.component.html'
})
export class DoctorListComponent implements AfterViewInit {
  config: any;
  token: any;
  public searchText: any = "";
  doctorList: Array<any> = new Array<any>();
  filteredPatientList: any;
  public editModal: boolean;
  collection = { count: 60, data: [] };
  p: number = 1;
  @ViewChild('doctorEditor', { static: true })
  doctorEditor: DoctorUpdateProfileComponent;
  editedDoctor: Doctor;
  isDocEditor = false;
  hospital: string;
  public pwdModal: boolean;
  @ViewChild('pwdEditor', { static: true })
  pwdEditor: PasswordComponent;
  editedPwdDoctor: Doctor;
  public isDoctor = false;
  public itemsPerPage: number = 10;
  constructor(public routing: Router, public docService: DoctorService, public authService: AuthenticationService, public alertService: AlertService,
    public global: Global, public notifyService: NotificationService, public dialog: MatDialog, private authenticationService: AuthenticationService) {
    this.token = this.authService.currentUserValue;
    this.hospital = this.token.HospitalIdentifier;
    this.showDoctorList();
    this.editModal = false;
    this.pwdModal = false;

  }
  pageChanged(event) {
    this.config.currentPage = event;
  }
  ngAfterViewInit() {

    this.doctorEditor.changesSavedCallback = () => {
      this.editModal = false;
      this.showDoctorList();
    };
    this.doctorEditor.changesCancelledCallback = () => {
      this.editModal = false;
    };

    this.pwdEditor.changesSavedCallback = () => {
      this.pwdModal = false;
      this.showDoctorList();
    };
    this.pwdEditor.changesCancelledCallback = () => {
      this.pwdModal = false;
    };

  }

  public showDoctorList() {
    this.docService.getDoctorList().subscribe((res: any) => {
      this.doctorList = Object.assign(this.doctorList, res?.Results);


      this.collection = { count: this.doctorList.length, data: [] }

      for (var i = 0; i < this.collection.count; i++) {
        this.collection.data.push(
          {
            id: i + 1,
            FullName: this.doctorList[i].DoctorName,
            PhoneNumber: this.doctorList[i].Phonenumber,

          }
        );
      }

    },


      error => {
        this.alertService.error(error);
      });

  }

  regDoc() {
    this.routing.navigate(['register'], { queryParams: { id: "docregister", hospital: this.hospital } });
  }
  UpdateProfile(row: Doctor) {
    this.isDocEditor = true;
    this.editedDoctor = this.doctorEditor.editDoctor(row, this.isDocEditor, this.hospital);
    this.editModal = true;
  }
  ResetPassword(row: Doctor) {
    this.isDoctor = true;
    this.editedPwdDoctor = this.pwdEditor.resetPwdDoctor(row, row.DoctorName, this.isDoctor);
    this.pwdModal = true;
  }
  Deactivate(row: Doctor) {
    const token = this.authenticationService.currentUserValue;
    if (token) {
      row.AdminId = token.unique_name;
    }
    this.docService.deactivateDoctor(row).subscribe(res => this.SuccessDeactivate(res),
      res => this.Error(res));
  }
  SuccessDeactivate(res) {
    if (res[0].IsActive == true) {
      this.notifyService.showSuccess("Info", "Activate successfully");
      this.showDoctorList();
    }
    else {
      this.notifyService.showInfo("Info", "Deactivate successfully");
      this.showDoctorList();
    }
  }
  Error(res) {
    this.notifyService.showSuccess("Error", "cannot deactivate");
  }
  hide() {
    this.editModal = false;
  }


}
