import { Component } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { DoctorService } from "../../../../services/doctor/doctor.service";
import { NotificationService } from "../../../../services/notification.service";

@Component({
  selector: 'appointments-over-phone-list',
  templateUrl: './appointments-over-phone.component.html',
  styleUrls: ['./appointments-over-phone.component.css']
})
export class AppointmentsOverPhoneComponent {
  searchFilter = {
    fromDate: moment().subtract(7, "days").format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
  }
  currentPage: number = 1;
  appointmentsOverPhone: any[] = [];
  constructor(public routing: Router, public notify: NotificationService, public docService: DoctorService) {
    this.getAppointmentsBookedOverPhone();
  }
  routeToAdminPage() {
    this.routing.navigate(['/admin']);
  }
  routeToAppointmentListPage() {
    this.routing.navigate(['/PatientAppointmentList']);
  }
  getAppointmentsBookedOverPhone() {
    this.docService.getAppointmentsBookedOverPhone(this.searchFilter.fromDate, this.searchFilter.toDate)
      .subscribe(res => this.appointmentsOverPhone = res, err => {
        console.log(err);
        this.notify.showError("Something went wrong.", "Failed")
      });
  }
}
