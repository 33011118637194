import { Problem } from '../problem/problem.model';
import {
  NgForm,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  ReactiveFormsModule
} from '@angular/forms';
import { PatientFilesUploadModel } from '../patient/pat-fileupload.model';
import * as moment from 'moment/moment'
import { ScheduleInterval } from '../scheduling/scheduling.model';
import { Medication } from '../Medications/Medications.model';

export class Visit {
  public VisitId: string;
  public VisitDate: Date;
  public VisitStartTime: Date;
  public VisitEndTime: Date;
  public ProviderId: string;
  public IsActive: boolean;
  public PatientId: string;
  public Status: string;
  public TreatmentAdvice: string;
  public Medication: string;
  public FollowUp: string;
  public VisitType:string;
  public HospitalId: string;
  public SchedulingId: string;
  public DepartmentId: string;
  public PaymentStatus: string;
  public BookingTime: string="";
  public Problem: Problem = new Problem();
  public medications: Array<Medication> = [];
  public DoctorId: string;
  public FollowupCreated : boolean;
  //public PatientFiles: PatientFilesUploadModel = new PatientFilesUploadModel();
  public PatientFiles: Array<PatientFilesUploadModel>=[];
  public PatFile: string = null;
 // public PatientFiles: Array<PatientFilesUploadModel> = [];
 public FamilyMemberId: String;

 //jitsi room name
 public roomname: string; 
  public PatientValidator :FormGroup = null;
  
  public CancelledBookingTime: string="";
  public ParentVisitId: string="";
  
  constructor() {

    var _formBuilder = new FormBuilder();

    this.PatientValidator = _formBuilder.group({
      'VisitDate':['',Validators.compose([Validators.required, this.dateValidators]),]
    });
  
  }

  public IsDirty(fieldname): boolean {
    if (fieldname == undefined) {
        return this.PatientValidator.dirty;
    }
    else {
        return this.PatientValidator.controls[fieldname].dirty;
    }
   
}
public IsValidCheck(fieldname, validator): boolean {
  // this is used to check for patient form is valid or not 
  if (this.PatientValidator.valid) {
      return true;
  }

  if (fieldname == undefined) {
      return this.PatientValidator.valid;
  }
  else {

      return !(this.PatientValidator.hasError(validator, fieldname));
  }
}
public dateValidators(control: FormControl): { [key: string]: boolean } {

  if (control) {
    let _date = control.value;
    var _currDate = moment().format('YYYY-MM-DD');
    //if positive then selected time is of future else it of the past
    if (moment(_date).diff(_currDate) < 0)
      return { 'invalidDate': true };
  }
}
    hasError(typeofvalidator:string,
    controlname:string) : boolean{
      return this.PatientValidator
            .controls[controlname]
            .hasError(typeofvalidator);}
}
export class visitdata{
    public IsSuccess: boolean;
    public ResponseMessage: string;
    public Results:Visit = new Visit();
    public StatusCode: number;
}