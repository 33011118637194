import { Component } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Global } from '../../app.global';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { PatientService } from '../../services/patient/patient.service';
import { AlertService } from '../../services/alert.service';
import { DoctorService } from '../../services/doctor/doctor.service';
import * as moment from 'moment';
import { Doctor } from 'src/app/app.model';
import { PatientFilesUploadModel } from 'src/app/models/patient/pat-fileupload.model';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ViewOPSummaryComponent } from '../OP_Summary/app.View_OP_summary';

@Component({
  templateUrl: './app.dailyvisit.html'
})
export class DailyVisitComponent {
  config: any;
  token: any;
  public filterdate = moment().format('YYYY-MM-DD');
  // public filterdate : Date;
  dailyVisitList: any;
  filteredDailyVisitList: any;
  public dailyvisit: boolean;
  collection = { count: 60, data: [] };
  progress: number;
  message: string;
  p: number = 1;
  public docInfo: Doctor = new Doctor();
  public prescriptionFile: PatientFilesUploadModel = new PatientFilesUploadModel();
  public selAppointmentType: any = "";
  public newFilteredDailyVisitList: any;
  public isBookingAvailable : boolean = false;
  constructor(public routing: Router, public docService: DoctorService, public authService: AuthenticationService, public patService: PatientService, public alertService: AlertService,
    public global: Global, public notify: NotificationService, public http: HttpClient, public dialog: MatDialog) {
    this.token = this.authService.currentUserValue;
    this.ShowDailyVisitList();
    this.DailyVisit();

  }
  pageChanged(event) {
    this.config.currentPage = event;
  }
  public DailyVisit() {
    for (var i = 0; i < this.collection.count; i++) {
      this.collection.data.push(
        {
          id: i + 1,
          value: "items number " + (i + 1)
        }
      );
    }

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.collection.count
    };
  }

  public ShowDailyVisitList() {
    this.dailyvisit = true;
    this.docService.getPatientListByProviderId(this.token.DoctorIdentifier).subscribe(res => {
      if(res && res.IsSuccess && res.Results){
        this.dailyVisitList = res.Results;

        this.dailyVisitList = this.dailyVisitList.map(a => {
          a.VisitDate = moment(a.VisitDate).format("YYYY-MM-DD");
          return a;
        })

        this.collection = { count: this.dailyVisitList.length, data: [] }

        for (var i = 0; i < this.collection.count; i++) {
          this.collection.data.push(
            {
              id: i + 1,
              VisitDate: this.dailyVisitList[i].VisitDate,
              PatientName: this.dailyVisitList[i].PatientName,
              Gender: this.dailyVisitList[i].Gender,
              ContactNumber: this.dailyVisitList[i].ContactNumber,
              TreatmentAdvice: this.dailyVisitList[i].TreatmentAdvice,
              Medication: this.dailyVisitList[i].Medication,
              FollowUp: this.dailyVisitList[i].FollowUp,
              VisitId: this.dailyVisitList[i].VisitId,
              PatientId: this.dailyVisitList[i].PatientId,
              AppointmentType: this.dailyVisitList[i].AppointmentType,
              Age: this.dailyVisitList[i].Age,
              ShowViewOPSummary: this.dailyVisitList[i].ShowViewOPSummary,
              Status: this.dailyVisitList[i].Status
            }
          );
        }

        this.ChangeDate();
      }else{
        this.notify.showError("", "No Patient List found.");
      }
    },
    error => {
      this.alertService.error(error);
    });

  }
  ChangeDate() {
    this.filteredDailyVisitList = new Array<any>();
    this.filteredDailyVisitList = JSON.parse(JSON.stringify(this.collection.data));//deepcopy
    this.filteredDailyVisitList = this.filteredDailyVisitList.filter(a => a.VisitDate == this.filterdate);
    this.newFilteredDailyVisitList = this.filteredDailyVisitList;
    if(this.newFilteredDailyVisitList.length < 0){
      this.isBookingAvailable = false;
    }else{
      this.isBookingAvailable = true;
    }
  }
  function(visitId, patientId) {
    this.prescriptionFile.VisitId = visitId;
    this.prescriptionFile.PatientId = patientId;

  }

  upload(files) {
    this.message = null;
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files) {
      if (file.size * 0.00000095367432 > 10) {
        this.notify.showWarning("Error", "File size should be less than 10 MB.");
        return;
      }
      let fileExtension = file.name.split('.').pop().toLowerCase();
      var acceptedFileFormat = ["jpg", "png", "doc", "docx", "pdf"];
      if (!acceptedFileFormat.includes(fileExtension)) {
        this.notify.showWarning("Unsupported file format", "Error");
        return;
      }
      formData.append(file.name, file);



      const uploadReq = new HttpRequest('POST', `api/Doctor/UploadFile/`, formData, {
        reportProgress: true,
      });

      this.http.request(uploadReq).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response)
          this.message = event.body.toString();
        if (this.message == "Upload Successful.") {
          this.docService.uploadPrescription(this.prescriptionFile).subscribe(res => this.Success(res),
            res => this.Error(res));
        }
      });
    }
  }
  Success(res) {
    this.notify.showSuccess("Sucess", "Upload Sucessfully!")

  }
  Error(res) {

  }

  appointmentTypeSearch(event: MatSelectChange) {
    if (this.selAppointmentType != "" && this.selAppointmentType != "All") {
      for (var i = 0; i < this.filteredDailyVisitList.length; i++) {
        this.newFilteredDailyVisitList = this.filteredDailyVisitList.filter(d => d.AppointmentType == this.selAppointmentType);
      }
    }
    else {
      this.newFilteredDailyVisitList = this.filteredDailyVisitList;
    }
  }
  AddOPSummary(data) {
    this.patService.PatientId = data.PatientId;
    this.patService.VisitId = data.VisitId
    this.routing.navigate(['/AddOPDSummary']);
  }

  ViewOPSummary(data) {
    this.patService.getOPSummary(data.PatientId, data.VisitId).subscribe(res => this.Successsummary(res),
      res => this.ErrorSuccesssummary(res));
  }

  Successsummary(data) {
    if(data && data.IsSuccess && data.Results){
      var summaryData = data.Results;
      const dialogRef = this.dialog.open(ViewOPSummaryComponent,
        {
          data: {
            PatientId: summaryData.PatientId,
            PatientName: summaryData.PatientName,
            Age: summaryData.Age,
            Gender: summaryData.Gender,
            Address: summaryData.Address,
            ContactNumber: summaryData.ContactNumber,
            VisitDate: summaryData.VisitDate,
            BookingTime: summaryData.BookingTime,
            DepartmentName: summaryData.DepartmentName,
            DoctorName: summaryData.DoctorName,
            CreatedDate: summaryData.CreatedDate,
            Height: summaryData.Height,
            Weight: summaryData.Weight,
            Temperature: summaryData.Temperature,
            BMI: summaryData.BMI,
            BP: summaryData.BP,
            Bodypain: summaryData.Bodypain,
            PainScale: summaryData.PainScale,
            LongSignature: summaryData.LongSignature,
            NMC: summaryData.NMC,
            Medication: summaryData.Medication,
            TreatmentAdvice: summaryData.TreatmentAdvice,
            History: summaryData.History,
            ChiefComplaints: summaryData.ChiefComplaints,
            Investigation: summaryData.Investigation,
            FinalDiagnosis: summaryData.FinalDiagnosis,
            Remarks: summaryData.Remarks,
            medicationlist: summaryData.medicationlist,
            VisitId: summaryData.Visitid
          }, width: '1000px', height: '1000px'
        }
      );
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        dialogRef.close();
        console.log(`Dialog result: ${result}`);
        //this.AppointmentHistory();
      });
    }else{
      this.notify.showError("", "No OP Summary found")
    }
  }

  ErrorSuccesssummary(res) {

  }
}
