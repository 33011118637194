<app></app>
<div class="bg-back">
<button (click)="Admin()" class="back-btn-doc-room">Back To Admin </button>
<button type="button" data-toggle="modal" data-target="#target" class="mat-chip mat-primary mat-standard-chip mat-chip-selected"> + Add Hospital</button>
</div>
<div class="card">
  <div class="card-body">
    <h1 class="card-title mb-4"><span class="select-title"><i class="fa fa-plus-square"></i>Set-Up Hospital</span>
    </h1>

    <table class="table">
      <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">HospitalName</th>
          <th scope="col">HospitalCode</th>
          <th scope="col">Location</th>
          <th scope="col">Is-Active</th>
          <th scope="col">PaymentEnable</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of hospitalList;let i = index;">
          <td style="vertical-align: middle;">{{i+1}}</td>
          <td style="vertical-align: middle;">{{data.HospitalName}}</td>
          <td style="vertical-align: middle;">{{data.HospitalCode}}</td>
          <td style="vertical-align: middle;">{{data.Location}}</td>
          <td style="vertical-align: middle;">{{data.IsActive}}</td>
          <td style="vertical-align: middle;">{{data.PaymentEnable}}</td>
          <td>
            <button
            (click)="EditHospital(data.HospitalId)" class="btn btn-primary btn-sm mr-1" data-toggle="modal" data-target="#target1"><i class="fa fa-pencil"></i> Edit </button>
            <a (click)="DeleteHospital(data.HospitalId)" class="btn btn-danger btn-sm text-white"><i class="fa fa-trash"></i>Delete</a>

          </td>
        </tr>
      </tbody>
    </table>

    <div  class="modal fade " id="target" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
      aria-hidden="true" >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="myLargeModalLabel">Add New Hospital</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="background-color:snow;">
            <div class="info-heading">
              <form #hospitaladdForm="ngForm" (ngSubmit)="AddHospital(hospitaladdForm)">
                <table>
                  <tr class="3-cols">
                    <td>
                      <label>Hospital Name<span class="required-sign">*</span></label>
                      <input type="text" name="HospitalName" value="" [(ngModel)]="hospitalInfo.HospitalName" size="25"
                       required  #HospitalName="ngModel"  />
                    </td>
                    <td>
                      <label>Hospital Code<span class="required-sign">*</span></label>
                      <input type="text" name="HospitalCode" value="" size="25"  [(ngModel)]="hospitalInfo.HospitalCode"
                      required #HospitalCode="ngModel" />
                    </td>
                    <td>
                      <label>Location<span class="required-sign">*</span> </label>
                      <input type="text" name="Location" value="" size="25"  [(ngModel)]="hospitalInfo.Location"
                     required  #Location="ngModel" />
                    </td>
                  </tr>
                  <tr class="3-cols">
                    <td>
                      <p class="IsActive-checkbox">
                        <input [(ngModel)]="hospitalInfo.IsActive" type="checkbox" name="IsActive" id="IsActive"
                        [ngModelOptions]="{standalone: true}" >
                        <label for="IsActive">Is Active</label>
                      </p>
                    </td>
                    <td>
                      <p class="Pay-checkbox">
                        <input [(ngModel)]="hospitalInfo.PaymentEnable" type="checkbox" name="Payment" id="Payment"
                        [ngModelOptions]="{standalone: true}"  >
                        <label for="Payment">Is Payment Enabled</label>
                      </p>
                    </td>
                    <td>
                      <input type="file" #file placeholder="Choose file" (change)="upload(file.files)" name="fileName"
                        [(ngModel)]="hospitalInfo.ImagePath" [ngModelOptions]="{standalone: true}"
                        style="display:none;">
                      <button type="button" class="btn btn-primary d-inline" (click)="file.click()"
                        style="font-size: 14px; margin-top: 25px"><i class="fa fa-upload"></i>Upload Image</button>
                      <div class="col-md-10 d-inline">
                        <span class="upload" *ngIf="progress > 0" style="font-size: 11px;">
                          {{progress}}%
                        </span>
                        <span class="upload" *ngIf="message" style="font-size: 11px;">
                          {{message}}
                        </span>
                      </div>
                    </td>
                  </tr>
                </table>
                <div class="modal-footer">
                  <button class="btn btn-success" value="Submit">Add</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


  <div class="modal" id="target1" tabindex="-1" role="dialog" [style.display]="editModal ? 'block' : 'none'"
  aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="card-title" style="width: 100%;">
          Update Hospital
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
        </h1>
      </div>
      <div class="modal-body" style="background-color:snow;">
        <div class="info-heading">
          <form #Form="ngForm" (ngSubmit)="UpdateHospital(Form)">
            <table>
              <tr class="3-cols">
                <td>
                  <label>Hospital Name<span class="required-sign" >*</span></label>
                  <input type="text" name="HospitalName" value="" [(ngModel)]="selectedHospital.HospitalName" size="25" required #HospitalName="ngModel"
                   />
                </td>
                <td>
                  <label>Hospital Code<span class="required-sign" >*</span> </label>
                  <input type="text" name="HospitalCode" value="" size="25" [(ngModel)]="selectedHospital.HospitalCode" required #HospitalCode="ngModel"/>
                </td>
                <td>
                  <label>Location<span class="required-sign" >*</span> </label>
                  <input type="text" name="Location" value="" size="25" [(ngModel)]="selectedHospital.Location" required #Location="ngModel"
                     />
                </td>
              </tr>
              <tr class="3-cols">
                <td >
                  <p>
                    <input [(ngModel)]="selectedHospital.IsActive"  [value]="selectedHospital.IsActive"  type="checkbox" name="IsActive1" id="IsActive1"
                     />
                      <label for="IsActive1">Is Active</label>
                  </p>
                </td>
                <td>
                  <p>
                    <input [(ngModel)]="selectedHospital.PaymentEnable"   [value]="selectedHospital.PaymentEnable" type="checkbox" name="Payment1" id="Payment1"
                    />
                    <label for="Payment1">Is Payment Enabled</label>
                  </p>
                </td>
                <td *ngIf="edit" >
                  <input type="file" #file placeholder="Choose file" (change)="uploadEdit(file.files)" name="fileName"
                    [(ngModel)]="selectedHospital.ImagePath" [ngModelOptions]="{standalone: true}"
                    style="display:none;">
                  <button type="button" class="btn btn-primary d-inline" (click)="file.click()"
                    style="font-size: 14px; margin-top: 25px"><i class="fa fa-upload"></i>Upload Image</button>
                  <div class="col-md-10 d-inline">
                    <span class="upload" *ngIf="progress > 0" style="font-size: 11px;">
                      {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message" style="font-size: 11px;">
                      {{message}}
                    </span>
                  </div>
                </td>
              </tr>
            </table>
            <div class="modal-footer">
              <button class="btn btn-success">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

    <!-- <div class="modal fade " id="target1" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel1"
    aria-hidden="true" >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="myLargeModalLabel1">Update Hospital</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="background-color:snow;">
          <div class="info-heading">
            <form #Form="ngForm" (ngSubmit)="UpdateHospital(Form)">
              <table>
                <tr class="3-cols">
                  <td>
                    <label>Hospital Name<span class="required-sign" >*</span></label>
                    <input type="text" name="HospitalName" value="" [(ngModel)]="selectedHospital.HospitalName" size="25"
                     />
                  </td>
                  <td>
                    <label>Hospital Code </label>
                    <input type="text" name="HospitalCode" value="" size="25" [(ngModel)]="selectedHospital.HospitalCode" />
                  </td>
                  <td>
                    <label>Location<span class="required-sign" >*</span> </label>
                    <input type="text" name="Location" value="" size="25" [(ngModel)]="selectedHospital.Location"
                       />
                  </td>
                </tr>
                <tr class="3-cols">
                  <td>
                    <p class="IsActive-checkbox">
                      <input [(ngModel)]="selectedHospital.IsActive" type="checkbox" name="" id="IsActive"
                        [ngModelOptions]="{standalone: true}"/>
                        <label for="IsActive">Is Active</label>
                    </p>
                  </td>
                  <td>
                    <p class="Pay-checkbox">
                      <input [(ngModel)]="selectedHospital.PaymentEnable" type="checkbox" name="" id="Payment"
                        [ngModelOptions]="{standalone: true}"/>
                      <label for="Payment">Is Payment Enabled</label>
                    </p>
                  </td>
                  <td *ngIf="edit">
                    <input type="file" #file placeholder="Choose file" (change)="uploadEdit(file.files)" name="fileName"
                      [(ngModel)]="selectedHospital.ImagePath" [ngModelOptions]="{standalone: true}"
                      style="display:none;">
                    <button type="button" class="btn btn-primary d-inline" (click)="file.click()"
                      style="font-size: 14px; margin-top: 25px"><i class="fa fa-upload"></i>Upload Image</button>
                    <div class="col-md-10 d-inline">
                      <span class="upload" *ngIf="progress > 0" style="font-size: 11px;">
                        {{progress}}%
                      </span>
                      <span class="upload" *ngIf="message" style="font-size: 11px;">
                        {{message}}
                      </span>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="modal-footer">
                <button class="btn btn-success" (click)="UpdateHospital()">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div> -->

    <!-- <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls> -->


  </div>
</div>
