<style>
  div#update-password.card {
    margin: 1rem 15rem;
  }

  div#update-password div.patient-login {
    padding: 1rem;
  }

  #show-password a.input-group-text,
  #show-password a.input-group-text em.fa {
    margin: 0;
  }

  @media (max-width: 1000px) {
    div#update-password.card {
      margin: 0;
    }
  }
</style>
<div id="update-password"
     class="card">
  <div class="patient-login">
    <h5 class="text-center">{{patName}}</h5>
    <div>
      <ng-container *ngIf="showOldAndConfirmPasswordInput">
        <label for="oldpassword">Old Password</label>
        <div *ngIf="!isDoctor"
             class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><em class="fa fa-lock"></em></span>
          </div>
          <input id="oldpassword"
                 [type]="showOldPassword ? 'text' : 'password'"
                 [(ngModel)]="patInfo.OldPassword"
                 class="form-control"
                 placeholder="OldPassword"
                 autocomplete="new-password" />
          <div id="show-password"
               class="input-group-append">
            <a class="input-group-text"
               (click)="showOldPass()"><em
                  [ngClass]="{'fa': true, 'fa-eye': !showOldPassword, 'fa-eye-slash': showOldPassword}"
                  aria-hidden="true"></em></a>
          </div>
        </div>
      </ng-container>
      
      <label for="password">Password</label>
      <div *ngIf="!isDoctor"
           class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><em class="fa fa-lock"></em></span>
        </div>
        <input id="password"
               [type]="showPassword ? 'text' : 'password'"
               [(ngModel)]="patInfo.Password"
               class="form-control"
               placeholder="Password"
               validateEqual="confirmpassword"
               autocomplete="new-password" />
        <div id="show-password"
             class="input-group-append">
          <a class="input-group-text"
             (click)="showPass()"><em [ngClass]="{'fa': true, 'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"
                aria-hidden="true"></em></a>
        </div>
      </div>
      <ng-container *ngIf="showOldAndConfirmPasswordInput">
        <label for="confirmpassword">Confirm Password</label>
        <div *ngIf="!isDoctor"
             class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><em class="fa fa-lock"></em></span>
          </div>
          <input id="confirmpassword"
                 [type]="showConfirmPassword ? 'text' : 'password'"
                 [(ngModel)]="patInfo.ConfirmPassword"
                 class="form-control"
                 placeholder="ConfirmPassword"
                 validateEqual="password"
                 autocomplete="new-password" />
          <div id="show-password"
               class="input-group-append">
            <a class="input-group-text"
               (click)="showConfirmPass()"><em
                  [ngClass]="{'fa': true, 'fa-eye': !showConfirmPassword, 'fa-eye-slash': showConfirmPassword}"
                  aria-hidden="true"></em></a>
          </div>
        </div>
      </ng-container>


      <ng-container *ngIf="showOldAndConfirmPasswordInput">
        <div *ngIf="isDoctor"
             class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><em class="fa fa-lock"></em></span>
          </div>
          <input id="oldpassword"
                 [type]="showOldPassword ? 'text' : 'password'"
                 [(ngModel)]="docInfo.OldPassword"
                 class="form-control"
                 placeholder="OldPassword"
                 autocomplete="new-password" />
          <div id="show-password"
               class="input-group-append">
            <a class="input-group-text"
               (click)="showOldPass()"><em
                  [ngClass]="{'fa': true, 'fa-eye': !showOldPassword, 'fa-eye-slash': showOldPassword}"
                  aria-hidden="true"></em></a>
          </div>
        </div>
      </ng-container>


      <div *ngIf="isDoctor"
           class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><em class="fa fa-lock"></em></span>
        </div>
        <input [type]="showPassword ? 'text' : 'password'"
               [(ngModel)]="docInfo.Password"
               class="form-control"
               placeholder="Password"
               validateEqual="confirmpassword"
               autocomplete="new-password" />
        <div id="show-password"
             class="input-group-append">
          <a class="input-group-text"
             (click)="showPass()"><em [ngClass]="{'fa': true, 'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"
                aria-hidden="true"></em></a>
        </div>
      </div>

      <ng-container *ngIf="showOldAndConfirmPasswordInput">
        <div *ngIf="isDoctor"
             class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><em class="fa fa-lock"></em></span>
          </div>
          <input id="confirmpassword"
                 [type]="showConfirmPassword ? 'text' : 'password'"
                 [(ngModel)]="docInfo.ConfirmPassword"
                 class="form-control"
                 placeholder="ConfirmPassword"
                 validateEqual="password"
                 autocomplete="new-password" />
          <div id="show-password"
               class="input-group-append">
            <a class="input-group-text"
               (click)="showConfirmPass()"><em
                  [ngClass]="{'fa': true, 'fa-eye': !showConfirmPassword, 'fa-eye-slash': showConfirmPassword}"
                  aria-hidden="true"></em></a>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="right">
      <button class="btn btn-success"
              (click)="cancel()">Cancel</button> &nbsp;&nbsp;
      <button *ngIf="!isDoctor"
              class="btn btn-success"
              (click)="UpdatePassword(patInfo)">Update</button>
      <button *ngIf="isDoctor"
              class="btn btn-success"
              (click)="UpdatePasswordDoctor(docInfo)">Update</button>
    </div>
  </div>
</div>