import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { AuthenticationService } from '../../../services/authentication.service';
import { User } from '../../../models/user/user.model';
import { HospitalService } from '../../../services/hospital/hospital.service';
import { DoctorService } from '../../../services/doctor/doctor.service';
import { Global } from '../../../app.global';

@Component({ selector: 'bookingnavigator', templateUrl: 'booking.navigator.component.html' })
export class BookingNavigatorComponent {
  currentUser: User;
  public token: any;
  public hospitalId: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public hospitalService: HospitalService,
    public docService: DoctorService,
    public global: Global
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.token = this.authenticationService.currentUserValue;
    this.hospitalId = this.route.snapshot.queryParamMap.get('id');
  }

  public BookNewVisit() {
    this.hospitalService.DepartmentId = null;
    this.global.DepartmentId = null;
    this.docService.DoctorId = null;
    this.global.BookingTime = "";
    this.global.VisitDate = null;
   
      this.hospitalId = this.hospitalService.HospitalId;
    
    this.router.navigate(['/PatBookAppointment'], { queryParams: { id: this.hospitalId } });
  }

  public MyBookList() {
    this.hospitalService.DepartmentId = null;
    this.docService.DoctorId = null;
   
    this.router.navigate(['/PatBookList'], { queryParams: { id: this.hospitalId } });

  }

  public MyPaidBooking() {
    this.hospitalService.DepartmentId = null;
    this.docService.DoctorId = null;
    this.router.navigate(['/PatPaidBookingList'], { queryParams: { id: this.hospitalId } });
  }
  public BackDashBoard() {
    this.hospitalService.DepartmentId = null;
    this.docService.DoctorId = null;
    this.global.BookingTime = "";
    this.global.VisitDate = null;
    this.router.navigate(['/PatDashboard']);
  }

}
