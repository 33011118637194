
export class ContactUsModel {
 public UserContactId: Number;
  public Name: string;
  public ContactNumber: string;
  public Email: string;
  public Company: string;
  public Message : number;
  public CreatedOn: Date;

}