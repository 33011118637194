import { Component } from '@angular/core'
import { Global } from '../../app.global';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from 'rxjs';

import * as KhaltiCheckout from 'khalti-web'
import { PatientService } from '../../services/patient/patient.service';
import { Visit } from '../../models/visit/visit.model';
import { AlertService } from '../../services/alert.service';
import { HospitalService } from '../../services/hospital/hospital.service';
import { MatDialog } from '@angular/material/dialog';
import { PaymentMessageComponent } from '../../PaymentMessage/app.paymentmessage.component';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
// import { tokenName } from '@angular/compiler';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  templateUrl: "./app.paymentselection.html"
})
export class PaymentSelectionComponent {
  websiteUrl = environment.url;
  IsCardPayButtonDisabled: boolean = false;
  VisitId: string;
  charge: string;
  Visitpaydata: any;
  public hospitalId: string;
  public docId: string;
  public visitInfo: Visit = new Visit();
  public showpaybyVisiting: boolean = false;
  constructor(public routing: Router, private route: ActivatedRoute,
    public hospitalService: HospitalService,
    public patService: PatientService, public alertService: AlertService, private dialog: MatDialog, private authService: AuthenticationService) {
    this.hospitalId = this.hospitalService.HospitalId;
    this.VisitId = this.route.snapshot.queryParamMap.get('vid');
    this.docId = this.route.snapshot.queryParamMap.get('id1');
    this.GetHospitalIdAndVisitIdFromLocalStorage();
    this.PaymentCharge();
  }

  paidstatus() {
    this.visitInfo.VisitId = this.VisitId;
    this.patService.updatePaidStatus(this.VisitId).subscribe(data => {
    },
      error => {
        this.alertService.error(error);
      });
  }
  PaymentCharge() {
    this.patService.getPaymentCharge(this.VisitId ? this.VisitId : this.patService.VisitId).subscribe(res => {
      this.Visitpaydata = res
      this.charge = this.Visitpaydata.Charge;
      if (this.Visitpaydata.VisitType == "Online Appointment") {
        this.showpaybyVisiting = true;
      }
    },
      error => {
        this.alertService.error(error);
      }
    );
  }

  //for client side integration of Khalti wallet
  public PayThroughKhalti() {

    const khaltiConfig = {
      // replace the publicKey with yours
      publicKey: environment.khalti_key,
      productIdentity: this.VisitId,
      productName: "Telemed",
      productUrl: environment.url,
      eventHandler: {
        onSuccess(payload) {
          console.log(payload);

          // call our Khalti Success Api to save the transaction
          window.location.href = `${window.origin}/Payment/KhaltiSuccess` +
            `?token=${payload.token}` +
            `&amount=${payload.amount}` +
            `&product_identity=${payload.product_identity}` +
            `&mobile=${payload.mobile}`;

        },
        onError(error) {
          // call our Khalti Success Api to save the transaction
          window.location.href = `${window.origin}/Payment/KhaltiFailure`
        },
        onClose() {
          // call our Khalti Success Api to save the transaction
          window.location.href = `${window.origin}/Payment/KhaltiFailure`
        }
      },
      paymentPreference: ["KHALTI"]

    }

    const checkout = new KhaltiCheckout(khaltiConfig);
    checkout.show({ amount: parseInt(this.charge) * 100 }); // amt in paisa
  }

  public someFunction($event) {
    console.log($event);
  }
  public PayThroughNabil() {
    this.IsCardPayButtonDisabled = true;
    this.SaveHospitalIdAndVisitIdInLocalStorage(this.hospitalId, this.VisitId);
    const formData = new FormData();
    formData.append("visitId", this.VisitId);
    fetch('/Payment/NabilCard', {
      method: 'POST',
      headers: new Headers({ 'Authorization': 'Bearer ' + sessionStorage.getItem("jwtToken") }),
      body: formData
    })
      .then(response => {
        if (response.status == 200) {
          return response.text();
        }
        else if (response.status == 400) {
          this.IsCardPayButtonDisabled = false;
          this.dialog.open(PaymentMessageComponent, { data: { message: "Payment through Card Failed.", method: "Card", status: "failure" } });
        }
      })
      .then(compassPlusURL => {
        // send the User to Nabil PG to enter Card Details and Credentials
        if (compassPlusURL != undefined) {
          window.location.href = compassPlusURL;
        }
      });
  }

  public PayThroughCreditCard() {

  }

  public PayThroughEsewa() {
    this.SaveHospitalIdAndVisitIdInLocalStorage(this.hospitalId, this.VisitId);
    let path = "https://esewa.com.np/epay/main";
    let params = {
      amt: parseInt(this.charge),
      psc: 0,
      pdc: 0,
      txAmt: 0,
      tAmt: parseInt(this.charge),
      pid: this.VisitId,
      scd: "NP-ES-IMARK",
      su: `${window.location.origin}/Payment/EsewaSuccess`,
      fu: `${window.location.origin}/Payment/EsewaFailure`
    }


    let form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", path);

    for (let key in params) {
      let hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
  }

  public async PayThroughConnectIPS() {
    this.SaveHospitalIdAndVisitIdInLocalStorage(this.hospitalId, this.VisitId);
    let path = "https://uat.connectips.com:7443/connectipswebgw/loginpage";

    //let path = '/Payment/Foo';

    let TXNID = await this.GetConnetIPSTXNID();
    let params = {
      MERCHANTID: '497',
      APPID: 'MER-497-APP-1',
      APPNAME: 'iMark',
      TXNID: TXNID, // this.VisitId, since GUID of visitID exceeds 20 character length required by connectIPS
      TXNDATE: moment().format('DD-MM-YYYY'),
      TXNCRNCY: 'NPR',
      TXNAMT: parseInt(this.charge) * 100, //Transaction amount in paisa.
      REFERENCEID: TXNID,
      REMARKS: 'test payment Imark',
      PARTICULARS: 'test payment Imark',
      TOKEN: ''
    };

    let connectIPSForm = document.createElement("form");
    connectIPSForm.setAttribute("action", path);
    connectIPSForm.setAttribute("method", "post");

    for (let key in params) {
      let hiddenInputElement = document.createElement("input");
      hiddenInputElement.setAttribute("type", "hidden");
      hiddenInputElement.setAttribute("name", key);
      hiddenInputElement.setAttribute("value", params[key]);
      connectIPSForm.appendChild(hiddenInputElement);
    }
    document.body.appendChild(connectIPSForm);

    let formData = new FormData(connectIPSForm);

    let transactionDetailString = 'MERCHANTID=' + formData.get('MERCHANTID') + ',APPID=' + formData.get('APPID') + ',APPNAME='
      + formData.get('APPNAME') + ',TXNID=' + formData.get('TXNID') + ',TXNDATE=' + formData.get('TXNDATE')
      + ',TXNCRNCY=' + formData.get('TXNCRNCY') + ',TXNAMT=' + formData.get('TXNAMT') + ',REFERENCEID='
      + formData.get('REFERENCEID') + ',REMARKS=' + formData.get('REMARKS') + ',PARTICULARS='
      + formData.get('PARTICULARS') + ',TOKEN=TOKEN';


    fetch(`/Payment/GetConnetIPSToken?input=${transactionDetailString}`, {
      method: 'GET',
      headers: new Headers({ 'Authorization': 'Bearer ' + sessionStorage.getItem("jwtToken") })
    })
      .then(res => {
        if (res.status == 200) {
          return res.text();
        }
        else if (res.status == 400 || res.status == 500) {
          this.dialog.open(PaymentMessageComponent, { data: { message: "Payment through Connect IPS Failed.", method: "Card", status: "failure" } });
        }
      })
      .then(token => {
        let inputToken: any = connectIPSForm.elements.namedItem('TOKEN');
        inputToken.value = token;
        connectIPSForm.submit();
      })
      .catch(err => {
        console.log(err);
        this.dialog.open(PaymentMessageComponent, { data: { message: "Payment through Connect IPS Failed.", method: "Card", status: "failure" } });
      });

  }

  public MyBookList() {
    this.routing.navigate(['/PatBookList'], { queryParams: { id: this.hospitalId } });

  }

  public MyPaidBooking() {
    this.routing.navigate(['/PatPaidBookingList'], { queryParams: { id: this.hospitalId } });
  }

  public BookNewVisit() {
    this.routing.navigate(['/PatBookAppointment'], { queryParams: { id: this.hospitalId } });
  }

  public GetHospitalIdAndVisitIdFromLocalStorage(): void {
    if (this.hospitalId == null) {
      this.hospitalId = localStorage.getItem("hospitalId");
      localStorage.removeItem("hospitalId");
    }
    if (this.VisitId == null) {
      this.VisitId = localStorage.getItem("visitId");
      localStorage.removeItem("visitId");
    }
  }

  public GetConnetIPSTXNID(): Promise<string> {
    return fetch(`/Payment/GetTXNIDForVisit?visitId=${this.VisitId}`, {
      method: 'GET',
      headers: new Headers({ 'Authorization': 'Bearer ' + sessionStorage.getItem("jwtToken") })
    })
      .then(res => res.text());
  }

  public SaveHospitalIdAndVisitIdInLocalStorage(hospitalId: string, visitId: string): void {
    // save hospitalId and visitId in local storage to retrieve when
    // angular app is reloaded after payment
    localStorage.setItem("visitId", visitId);
    localStorage.setItem("hospitalId", hospitalId);
  }


  //public foo() {
  //  let amount = 100;
  //  let txnid = '00008';
  //  let paymentMetod = 'connectIPS';
  //  fetch(`https://imarkpaymentgate&amout=${amount}?txid=${txnid}?method=${paymentMetod}`)
  //    .then(res => {
  //      var form = res.form;
  //      form.submit();
  //    })
  //}

  paybyVisiting() {
    if (this.authService.userType == 'Admin') {
      this.routing.navigate(['/adminpatlist']);
    }
    else {
      this.routing.navigate(['/PatHistory']);
    }
  }

}
