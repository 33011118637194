<app></app>
<div class="Main">
  <div class="card">
    <div class="card-header pb-2" style="border: none;">
      <h3 class="card-title">
        Hello! <label class="text-primary-green"><strong>Admin </strong></label>
        <span class="d-block text-small">Choose actions below to proceed.</span>
      </h3>
    </div>
    <div class="card-body pt-2">
      <div class="Book-Update">
        <ul class="doc-actions p-0">
          <li class="d-inline mr-2">
            <button [routerLink]="['/doclist']" class="btn"><i aria-hidden="true" class="fa fa-users"></i>Doctor
            </button>
          </li>
          <li class="d-inline mr-2">
            <button [routerLink]="['/adminpatlist']" class="btn"><i aria-hidden="true" class="fa fa-users"></i>Patient
            </button>
          </li>
          <li class="d-inline mr-2">
            <button [routerLink]="['/scheduling']" class="btn"><i aria-hidden="true" class="fa fa-id-card"></i>Doctor Scheduling
            </button>
          </li>
          <li class="d-inline mr-2">
            <button [routerLink]="['/labreportupload']" class="btn"><i aria-hidden="true" class="fa fa-upload"></i>Lab Report Upload
            </button>
          </li>
          <li class="d-inline mr-2">
            <button [routerLink]="['/Setup']" class="btn"><i aria-hidden="true" class="fa fa-cogs"></i>Set Up
            </button>
          </li>
          <li class="d-inline mr-2">
            <button [routerLink]="['/MeetingSetup']" class="btn"><i aria-hidden="true" class="fa fa-cogs"></i>Meeting Set Up
            </button>
          </li>
          <li class="d-inline mr-2">
            <button [routerLink]="['/PatientAppointmentList']" class="btn"><i aria-hidden="true" class="fa fa-list"></i>Appointment List
            </button>
          </li>
          <li class="d-inline mr-2">
            <button [routerLink]="['/ContatctInformation']" class="btn"><i aria-hidden="true" class="fa fa-list"></i>Contact Information
            </button>
          </li>
          <li class="d-inline mr-2">
            <button [routerLink]="['/Report']" class="btn"><i aria-hidden="true" class="fa fa-list"></i>Report
            </button>
          </li>
          <li class="d-inline mr-2">
            <button [routerLink]="['/Product']" class="btn"><i aria-hidden="true" class="fa fa-gift"></i>Product
            </button>
          </li>
          <li class="d-inline mr-2">
            <button [routerLink]="['/Order']" class="btn"><i aria-hidden="true" class="fa fa-first-order"></i>Orders
            </button>
          </li>
        </ul>
      </div>
      <div>
        <a href="https://learning.covid19.gov.np/" target="_blank" style="color:white !important; text-decoration: none !important; float: right;" class="btn btn-primary btn-learn">
          Learn more during free time..
        </a>
      </div>
    </div>

  </div>
</div>