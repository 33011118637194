import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { MeetingService } from "src/app/services/Meeting/meeting.service";
import { NotificationService } from "src/app/services/notification.service";
import { Meeting } from "src/app/models/Meeting/meeting.model";
import { DoctorService } from "src/app/services/doctor/doctor.service";
import { Doctor } from "src/app/app.model";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: "app-meetingsetup",
  templateUrl: "./meetingsetup.component.html",
  styleUrls: ["./meetingsetup.component.css"],
})
export class MeetingsetupComponent implements OnInit {
  public edit: boolean = false;
  public editModal: boolean = false;
  public meetingException: boolean = false;
  public meetingInfo: Meeting = new Meeting();
  public isDoctorAvailable: boolean = false;
  public doctorList: Array<Doctor> = new Array<Doctor>();
  public docId: string;
  meetingScheduleForm: FormGroup;
  meetingForm = new Meeting();
  public meetingList: Array<Meeting> = new Array<Meeting>();
  public meetingLength: number;
  public isMeetingAvailable: boolean = false;

  constructor(
    public routing: Router,
    public meetingService: MeetingService,
    public notifyService: NotificationService,
    public docService: DoctorService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.meetingScheduleForm = this.formBuilder.group({
      MeetingNumber: [null, [Validators.required]],
      ApiKey: [null, [Validators.required]],
      ApiSecret: [null, [Validators.required]],
      Password: [null, [Validators.required]],
      DoctorId: [null, [Validators.required]],
      IsActive: [null, [Validators.required]],
      ZoomMeetingId: [null],
    });

    this.getMeetings();
    this.GetDoctorList();
  }
  public Admin() {
    this.routing.navigate(["/admin"]);
  }
  getMeetings() {
    this.meetingService.getMeetingsList().subscribe(
      (res) => this.SuccessMeetingList(res),
      (res) => this.Error(res)
    );
  }
  SuccessMeetingList(res) {
    if (res && res.IsSuccess && res.Results) {
      this.meetingList = res.Results;
      this.meetingLength = this.meetingList.length;
      if (this.meetingList.length >= 1) {
        this.isMeetingAvailable = true;
      } else {
        this.isMeetingAvailable = false;
      }
    }
  }
  Error(res) {
    this.notifyService.showError("Error", "Internal Error");
  }
  ErrorEdit(res) {
    this.notifyService.showError("Error", "Internal Error");
  }
  get f() {
    return this.meetingScheduleForm.controls;
  }
  ScheduleMeeting() {
    const form = this.meetingScheduleForm;

    if (form.invalid) {
      if (form.controls.MeetingNumber.invalid) {
        this.notifyService.showError("", "Meeting Number is required");
      }
      if (form.controls.ApiKey.invalid) {
        this.notifyService.showError("", "Api Key is required");
      }
      if (form.controls.ApiSecret.invalid) {
        this.notifyService.showError("", "Api Secret is required");
      }
      if (form.controls.Password.invalid) {
        this.notifyService.showError("", "Password is required");
      }
      if (form.controls.DoctorId.invalid) {
        this.notifyService.showError("", "Doctor is required");
      }
      if (form.controls.IsActive.invalid) {
        this.notifyService.showError("", "Is Active is required");
      }
      return;
    }
    this.meetingForm.MeetingNumber = form.value.MeetingNumber;
    this.meetingForm.ApiKey = form.value.ApiKey;
    this.meetingForm.ApiSecret = form.value.ApiSecret;
    this.meetingForm.Password = form.value.Password;
    this.meetingForm.DoctorId = form.value.DoctorId;
    this.meetingForm.IsActive = form.value.IsActive;

    this.meetingService.scheduleMeeting(this.meetingForm).subscribe(
      (res) => this.SuccessSchedule(res),
      (error) => this.Error(error)
    );
  }

  SuccessSchedule(res) {
    this.notifyService.showSuccess("", "Meeting Scheduled Successfully!");
    window.location.reload();
  }
  GetDoctorList() {
    this.docService.getDoctorList().subscribe(
      (res) => this.SuccessDoctor(res),
      (res) => this.Error(res)
    );
  }
  SuccessDoctor(res) {
    let doclist;
    doclist = res?.Results;
    doclist.forEach((doc) => {
      for (var i = 0; i < doc.ConsultationType.length; i++) {
        this.isDoctorAvailable = true;
        this.doctorList.push(doc);
        break;
      }
    });
    if (this.doctorList.length < 1) {
      this.isDoctorAvailable = false;
    }
  }
  EditSchedule(meeting) {
    const form = this.meetingScheduleForm;
    this.edit = true;
    this.editModal = true;
    this.meetingScheduleForm
      .get("MeetingNumber")
      .setValue(meeting.MeetingNumber);
    this.meetingScheduleForm.get("ApiKey").setValue(meeting.ApiKey);
    this.meetingScheduleForm.get("ApiSecret").setValue(meeting.ApiSecret);
    this.meetingScheduleForm.get("Password").setValue(meeting.Password);
    this.meetingScheduleForm
      .get("IsActive")
      .setValue(JSON.parse(meeting.IsActive));
    this.meetingScheduleForm.get("DoctorId").setValue(meeting.DoctorId);
    this.meetingScheduleForm
      .get("ZoomMeetingId")
      .setValue(meeting.ZoomMeetingId);

    if (form.invalid) {
      if (form.controls.MeetingNumber.invalid) {
        this.notifyService.showError("", "Meeting Number is required");
      }
      if (form.controls.ApiKey.invalid) {
        this.notifyService.showError("", "Api Key is required");
      }
      if (form.controls.ApiSecret.invalid) {
        this.notifyService.showError("", "Api Secret is required");
      }
      if (form.controls.Password.invalid) {
        this.notifyService.showError("", "Password is required");
      }
      if (form.controls.DoctorId.invalid) {
        this.notifyService.showError("", "Doctor is required");
      }
      if (form.controls.IsActive.invalid) {
        this.notifyService.showError("", "Is Active is required");
      }
      return;
    }
  }
  EditScheduleMeeting(form) {
    var meeting = form.value;
    this.meetingService.updateMeeting(meeting).subscribe(
      (res) => this.SuccessEdit(res),
      (err) => this.Error(err)
    );
  }
  SuccessEdit(res) {
    this.notifyService.showSuccess("", "Meeting Edited Successfully!");
    window.location.reload();
  }
  clearForm() {
    this.meetingScheduleForm.reset();
  }
}
