<nav class="navbar navbar-expand-lg navbar-light bg-white navbar telehealth-nav fixed-top"
     *ngIf="
    token.UserType == 'Patient' ||
    token.UserType == 'Doctor' ||
    token.UserType == 'Admin'
  ">
  <!--<a class="navbar-brand ml-0" href="#">
      <img src="~/../assets/img/logo-cap.png" class="f-img" />
    </a>-->
  <a class="navbar-brand ml-0"
     href="{{websiteUrl}}"
     target="_blank">
    <img src="~/../assets/img/logo.png"
         class="f-img" />
  </a>

  <h4 *ngIf="token.UserType == 'Admin'"
      class="text-center welcome-top">
    Welcome Admin !!
  </h4>
  <h4 *ngIf="token.UserType == 'Patient'"
      class="text-center welcome-top">
    Welcome {{patInfo.FirstName +' '+ patInfo.LastName }} !!
  </h4>
  <h4 *ngIf="token.UserType == 'Doctor'"
      class="text-center welcome-top">
    Welcome Dr. {{docInfo.FirstName +' '+ docInfo.LastName }} !!
  </h4>
  <button class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse"
       id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto mr-0">
      <li class="nav-item">
        <div class="nav-link logged-info">
          <i class="fa fa-user"></i>Welcome
        </div>
      </li>
      <li class="nav-item notification-container"
          ngbDropdown>
        <a class="nav-link"
           data-toggle="dropdown"
           href="javascript:void(0)"
           id="notifications-dropdown"
           ngbDropdownToggle>
          <div class="notification">
            <i class="fa fa-bell"></i>
            <span>{{notificationArray.length}}</span>
          </div>
        </a>
        <ul class=" dropdown-menu-right dropdown-navbar notification-wrapper"
            ngbDropdownMenu>
          <li (click)="$event.stopPropagation()">
            <div class="notification-dropdown-menu"
                 aria-labelledby="notifications-dropdown">
              <h6 class="dropdown-header">You have {{notificationArray.length}} Notification <a (click)="markAsRead()"
                   class="float-right text-light">Mark all as read</a></h6>
              <div class="notification-list-wrapper mt-2">
                <div class="notification-list pb-1"
                     *ngFor="let notice of notificationArray ; index as i"
                     (click)="routeToPage(notice)">
                  <div [ngClass]="notice.isSeen ? 'notification-read py-2 pl-2' : 'notification-unread py-2 pl-2'">
                    <a id="notificationAucune"
                       class="dropdown-item dropdown-notification pl-3">
                      <p class="notification-solo">{{notice.message}}</p>
                      <div>
                        <div class="notification-date pull-right">
                          <p class="p-0 m-0"><i class="far fa-clock"></i>{{notice.timeDuration}}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <a class="dropdown-item dropdown-notification-all text-center pb-0 pt-2">
                See all
              </a>

            </div>
          </li>
        </ul>

      </li>
      <li *ngIf="token.UserType != 'Admin'" class="nav-item security-container"
          ngbDropdown>
        <a class="nav-link"
           data-toggle="dropdown"
           href="javascript:void(0)"
           id="security-dropdown"
           ngbDropdownToggle>
          <div class="security">
            <img title="Security"
                 src="assets/img/security-icon.png"
                 alt="S" />
          </div>
        </a>
        <ul class=" dropdown-menu-right dropdown-navbar"
            ngbDropdownMenu>
          <li>
            <div aria-labelledby="security-dropdown">
              <button class="dropdown-item dropdown-security-all text-center p-0"
                      (click)="changePassword()">
                Change Password
              </button>
            </div>
          </li>
        </ul>

      </li>
      <li class="nav-item">
        <a class="nav-link"
           (click)="logout()">
          <i class="fa fa-sign-out"
             aria-hidden="true"></i>Logout
        </a>
      </li>
    </ul>


  </div>
</nav>


<!-- Password Update Modal -->
<div class="modal"
     id="myModal"
     tabindex="-1"
     role="dialog"
     [style.display]="pwdModal ? 'block' : 'none'"
     aria-labelledby="myModalLabel"
     aria-hidden="false"
     style="display: block;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content"
         style="background-color: transparent; border: none;">

      <!-- Modal body -->
      <password-update #pwdEditor
                       [show-old-and-confirm-password]="true"></password-update>

    </div>
  </div>
</div>