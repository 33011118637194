import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { DanphecareModel } from '../../models/danphecare/danphecare.model';
import { ContactUsEndpoint } from './contactUs-endpoint.service';
import { ContactUsModel } from 'src/app/models/contactUs/contactUs.model';



@Injectable()
export class ContactUsService {
  constructor(private router: Router, private http: HttpClient, private contactUsEndPoint: ContactUsEndpoint) {
  }

  GetlistOfContactedUsr(){
    return this.contactUsEndPoint.GetlistOfContactedUsr<ContactUsModel[]>();
  }
  PostContactformdata(contact: ContactUsModel) {
    return this.contactUsEndPoint.PostContactformdata<ContactUsModel>(contact);
  }


}
