import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AuthenticationService } from "../../services/authentication.service";
import { NotificationService } from "../../services/notification.service";
import { HttpClient } from "@angular/common/http";
import { Login, User } from "../../models/user/user.model";
/*import * as jwt_decode from "jwt-decode";*/
import { BehaviorSubject, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { LoginService } from "../../services/login/login.service";
import { JwtHelper } from '../../models/JwtHelper';
import { IdToken } from "../../models/IdToken";
import { HospitalService } from 'src/app/services/hospital/hospital.service';
import { ContactInformation } from 'src/app/models/contactInfo/contactinformation.model';
import { DecodeTokenService } from "../../services/decodeTtoken.service";

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPatient = false;
  jwtToken: any;
  loginVal: any;
  userType: string;
  showPassword = false;
  loginTele = new Login();
  rememberUser: boolean = false;

  loginLocalStorageDataKey = "login";
  public contactInfo: ContactInformation = new ContactInformation();
  constructor(private http: HttpClient,
    private formBuilder: FormBuilder, private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private notifyService: NotificationService, private decodeTokenService: DecodeTokenService,
    public routing: Router, public loginService: LoginService, public hospitalService: HospitalService
  ) { }
  //public get currentUserValue(): User {
  //  let token = sessionStorage.getItem("jwtToken");
  //  //if (token != null || token != undefined) {
  //  var decoded = jwt_decode(token);
  //  return (this.jwtToken = decoded);
  //  //}
  //  //else {
  //  //  return "NoToken";
  //  //}

  //  //let token = this.currentUserSubject.value.token;
  //  //let token = this.currentUserSubject.value.token;
  //  //var decoded = jwt_decode(token);
  //}
  ngOnInit() {

    var phoneNumber = localStorage.getItem(this.loginLocalStorageDataKey);
    if (phoneNumber != null) this.rememberUser = true;

    this.loginForm = this.formBuilder.group({
      phonenumber: [
        phoneNumber || "",
        [Validators.required, Validators.minLength(7), Validators.maxLength(15), Validators.pattern('^[0-9]*$')]
      ],
      //password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}')]]
      password: ["", [Validators.required, Validators.minLength(6)]],
    });

    this.getcontactInformation();
    // get return url from route parameters or default to '/'
    //this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }
  patientregister() {
    this.routing.navigate(["/register"], {
      queryParams: { id: "patientregister", login: "patient" },
    });
  }
  //onSubmit1() {
  //  this.submitted = true;
  //  // stop here if form is invalid
  //  if (this.loginForm.invalid) {
  //    return;
  //  }
  //  this.loading = true;

  //  this.login(this.f.phonenumber.value, this.f.password.value).subscribe(
  //    data => {
  //      if (data === "Admin") {
  //        this.router.navigate(['/admin']);
  //        this.notifyService.showInfo("Info", "Admin Page");

  //      }
  //      else if (data === "Patient") {
  //        this.router.navigate(['/PatDashboard']);
  //        this.notifyService.showSuccess("Welcome", "login successful");
  //      }
  //      else if (data === "Doctor") {
  //        this.router.navigate(['/DocDashboard']);
  //        this.notifyService.showSuccess("Welcome", "login successful");
  //      }
  //      else {
  //        this.router.navigate(['']);
  //      }
  //        //this.notifyService.showSuccess("Welcome", "login successful");
  //      },
  //      error => {
  //        this.notifyService.showError("Sorry", "Phone Number and Password do not match")
  //        this.loading = false;
  //      });

  //}
  //login(phonenumber: string, password: string) {
  //  return  this.http.post<any>(`/api/account/login`, { phonenumber, password })
  //    .pipe(map(user => {
  //      // login successful if there's a jwt token in the response
  //      if (user && user.token) {
  //        sessionStorage.removeItem('jwtToken');
  //        sessionStorage.setItem('jwtToken', user.token);
  //        const token = user.token;
  //        const decoded = jwt_decode(token);
  //        this.jwtToken = decoded;
  //        this.userType = decoded.UserType;
  //        if (this.userType == "Patient") {
  //          localStorage.removeItem('loginPat');
  //          localStorage.setItem('loginPat', "patient");
  //        }
  //        else if (this.userType == "Doctor") {
  //          localStorage.removeItem('loginDoc');
  //          localStorage.setItem('loginDoc', "doctor");
  //        }
  //      }
  //      return this.userType;
  //    }));
  //}
  // Slick Slider this is added new

  slides = [
    { img: "./assets/img/Slider11.jpg" },
    { img: "./assets/img/Slider22.jpg" },
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    cssEase: "linear",
    draggable: false,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
  };
  // End SLick slider
  showPass() {
    this.showPassword = !this.showPassword;
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      if (this.loginForm.controls.phonenumber.invalid) {
        this.notifyService.showError("Error", "Phone number should be greater than 6 digits and less than 16 digits");
      }
      if (this.loginForm.controls.password.invalid) {
        this.notifyService.showError("Error", "Password length should be atleast 6");
      }
      return;
    }
    this.loading = true;
    this.loginTele.PhoneNumber = this.f.phonenumber.value;
    this.loginTele.Password = this.f.password.value;

    localStorage.removeItem(this.loginLocalStorageDataKey);
    if (this.rememberUser)
      localStorage.setItem(this.loginLocalStorageDataKey, this.loginTele.PhoneNumber);

    this.loginService.loginTele(this.loginTele)
      .subscribe(res => this.SuccessLogin(res),
        res => this.ErrorLogin(res));
  }
  SuccessLogin(res) {
    if (res && res.token) {
      this.decodeTokenService.decodeToken(res.token);
    }
  }

  ErrorLogin(res) {
    this.loading = false;
    this.notifyService.showError("Sorry", "Phone Number and Password do not match")
  }

  getcontactInformation() {
    this.hospitalService.getContactInfo().subscribe(res => this.SuccessgetcontactInfo(res),
      res => this.Error(res));
  }
  SuccessgetcontactInfo(res) {
    if (res != null) {
      this.contactInfo = res;
    }
    else {
      this.contactInfo.ContactNumber = "NA";
      this.contactInfo.Email = "NA"
    }
  }
  Error(res) {
    this.notifyService.showError("Error", "Internal Error")
  }

  ForgotPassword() {
    window.location.href = '/api/ForgotPassword/ForgotPassword';
  }
}
