import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../app.global';
import { Router } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationService } from '../../services/authentication.service';
import { PatientService } from '../../services/patient/patient.service';
import { AlertService } from '../../services/alert.service';
import { DoctorService } from '../../services/doctor/doctor.service';
import * as moment from 'moment';
import { Visit } from 'src/app/models/visit/visit.model';
import { SchedulingService } from 'src/app/services/scheduling/scheduling.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RescheduleComponent } from '../doctor_reschedule/app.doctor_reschedule';
import { MatDialog } from '@angular/material/dialog';
import { ViewOPSummaryComponent } from 'src/app/Doctor/OP_Summary/app.View_OP_summary';
@Component({
  templateUrl: './app.patientlist.html'
})
export class PatientListComponent  {
  config: any;
  token: any;
  public filterdate = moment().format('YYYY-MM-DD');
  public deptId: string ;
  public hosId: string;
  public visitId:string;
  public DepartmentId: any;
  public HospitalId: string;
  patientList: Array<any> = new Array<any>();
  filteredPatientList: any;
  public dailyvisit: boolean;
  collection = { count: 60, data: [] };
  p: number = 1;
  public reschedule: boolean = false ;
  public  visitObj: Visit = new Visit();
  constructor(public routing: Router,public schedulingService: SchedulingService , public docService: DoctorService, public authService: AuthenticationService, public patService: PatientService, public alertService: AlertService,
  public global: Global , public notifyService: NotificationService , public dialog: MatDialog,) {
    this.token = this.authService.currentUserValue;
    this.showPatientList();

  }
  pageChanged(event) {
    this.config.currentPage = event;
  }


  public showPatientList() {
    this.dailyvisit = true;
    this.docService.getPatientList(this.token.DoctorIdentifier).subscribe((res: any) => {
      this.patientList = Object.assign(this.patientList ,res?.Results);


      for (var i = 0; i < this.patientList.length; i++) {
        this.patientList[i].VisitDate = moment(this.patientList[i].VisitDate).format("YYYY-MM-DD");
      }

      this.collection = {count: this.patientList.length,data:[]}

      for (var i = 0; i < this.collection.count; i++) {
          this.collection.data.push(
           {
              id: i + 1,
              VisitId:this.patientList[i].VisitId,
              VisitDate: this.patientList[i].VisitDate,
              PatientName:this.patientList[i].PatientName,
              Age:this.patientList[i].Age,
              Gender:this.patientList[i].Gender,
              ContactNumber:this.patientList[i].ContactNumber,
              HospitalId:this.patientList[i].HospitalId,
              DepartmentId:this.patientList[i].DepartmentId,
              SchedulingId: this.patientList[i].SchedulingId,
              BookingTime: this.patientList[i].BookingTime,
              PatientId: this.patientList[i].PatientId,
              VisitType: this.patientList[i].VisitType,
              ShowViewOPSummary: this.patientList[i].ShowViewOPSummary
            }
          );
        }
        this.ChangeDate();

    },


      error => {
        this.alertService.error(error);
      });

  }
  ChangeDate() {
    this.filteredPatientList = new Array<any>();
    this.filteredPatientList = JSON.parse(JSON.stringify(this.collection.data));//deepcopy
    this.filteredPatientList = this.filteredPatientList.filter(a => a.VisitDate == this.filterdate);
  }

  Reschedule(j:number){
    this.deptId = this.filteredPatientList[j].DepartmentId;
    this.hosId = this.filteredPatientList[j].HospitalId;
    this.visitId=  this.filteredPatientList[j].VisitId;
    const dialogRef = this.dialog.open(RescheduleComponent,
      { data: { docId: this.token.DoctorIdentifier, deptId: this.deptId, hosId: this.hosId, visitId: this.visitId, schedullingId: this.filteredPatientList[j].SchedulingId, bookingTime: this.filteredPatientList[j].BookingTime, appointmentType: this.filteredPatientList[j].VisitType } ,width: '1000px', height: '1000px'}
      );
    dialogRef.disableClose = true;
   dialogRef.afterClosed().subscribe(result => {
     dialogRef.close();
     console.log(`Dialog result: ${result}`);
     this.routing.navigate(['/DocPatientList']);
   });
  }

  hide(){
    this.reschedule = false ;
  }

  ViewOPSummary(data){
    this.patService.getOPSummary(data.PatientId ,data.VisitId). subscribe(res => this.Successsummary(res),
    res => this.ErrorSuccesssummary(res));
  }

  Successsummary(data){
    if(data && data.IsSuccess && data.Results){
      const dialogRef = this.dialog.open(ViewOPSummaryComponent,
        { data: {
          PatientId: data.Results.PatientId,
          PatientName: data.Results.PatientName,
          Age: data.Results.Age,
          Gender: data.Results.Gender,
          Address: data.Results.Address,
          ContactNumber: data.Results.ContactNumber,
          VisitDate: data.Results.VisitDate,
          BookingTime: data.Results.BookingTime,
          DepartmentName: data.Results.DepartmentName,
          DoctorName: data.Results.DoctorName,
          CreatedDate: data.Results.CreatedDate,
          Height: data.Results.Height,
          Weight: data.Results.Weight,
          Temperature: data.Results.Temperature,
          BMI: data.Results.BMI,
          BP: data.Results.BP,
          Bodypain: data.Results.Bodypain,
          PainScale: data.Results.PainScale,
          LongSignature: data.Results.LongSignature,
          NMC: data.Results.NMC,
          Medication: data.Results.Medication,
          TreatmentAdvice: data.Results.TreatmentAdvice,
          History: data.Results.History,
          ChiefComplaints: data.Results.ChiefComplaints,
          Investigation: data.Results.Investigation,
          FinalDiagnosis: data.Results.FinalDiagnosis,
          Remarks: data.Results.Remarks,
          medicationlist :data.Results.medicationlist,
          VisitId: data.Results.Visitid
        }, width: '1000px', height: '1000px' }
        );
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {
          dialogRef.close();
          console.log(`Dialog result: ${result}`);
          //this.AppointmentHistory();
        });
    }
    else{
      this.notifyService.showError("", "No OP Summary report found")
    }
  }

  ErrorSuccesssummary(res){

  }

}
