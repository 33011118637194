
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EndpointFactory } from '../endpoint-factory.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class DanphecareEndpoint extends EndpointFactory {
  
  private readonly _GetAppliedUsrlistUrl: string = "/api/danphecare/GetAppliedUsrlist";
  private readonly _applytoIsolationUrl: string = "/api/danphecare/ApplyToIsolation";  

  constructor(http: HttpClient, injector: Injector) {
    super(http, injector);
  }

  GetAppliedUsrlist<T>(): Observable<T>{
    let endpointUrl = `${this._GetAppliedUsrlistUrl}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetAppliedUsrlist()));
      }));
  }

  applytoIsolation<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this._applytoIsolationUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.applytoIsolation(userObject)));
      }));
  }
}
