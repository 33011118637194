<section class="row mb-2" *ngIf="productImageExist">
  <div class="col-md-4">
    <div class="col-md-2">
      <img [src]="previewImgSrc" width="120" height="85" style=" margin-left: 2500%; margin-bottom: 50px;" />
      <label style=" margin-left: 2500%; margin-bottom: 50px;"> Product image</label>
    </div>
  </div>
</section>
<section class="row mb-2">
  <div class="col-md-4">

    <label for="firstname">Product Name </label>
    <input type="text" id="productName" placeholder=" Product Name" [(ngModel)]="ProductDetails.Name"
      [attr.disabled]="!editMode?true:null" />
  </div>
  <div class="col-md-4">

    <label for="price"> Price<span class="required-sign">*</span></label>
    <input type="number" id="price" placeholder="Price of Product" [(ngModel)]="ProductDetails.Price"
      [attr.disabled]="!editMode?true:null" />


  </div>
  <div class="col-md-4">

    <label for="Old Price">Old Price</label>
    <input type="number" placeholder=" Old Price" [(ngModel)]="ProductDetails.OldPrice"
      [attr.disabled]="!editMode?true:null" />

  </div>
  <div class="col-md-4">
    <label for="SKU"> SKU No.<span class="required-sign">*</span></label>
    <!-- <input type="string" name="SKU" value="" id="SKU" [(ngModel)]="ProductDetails.Sku"
                [attr.disabled]="!editMode?true:null" /> -->
    <input type="text" id="SKU" [(ngModel)]="ProductDetails.Sku" [attr.disabled]="!editMode?true:null" />

  </div>
  <div class="col-md-4">

    <label for="ManufacturerPartNumber"> Manufacturer Part No.<span class="required-sign">*</span></label>
    <input type="text" id="ManufacturerPartNumber" [(ngModel)]="ProductDetails.ManufacturerPartNumber"
      [attr.disabled]="!editMode?true:null" />
  </div>
  <div class="col-md-4">
    <label for="">Hospital Name<span class="required-sign">*</span></label>

    <input type="text" id="MrtNumber" [(ngModel)]="hospitalName" [attr.disabled]="!editMode?true:null" />
  </div>
</section>

<hr>
<section class="row">
  <div class="col-md-4">
    <label for="">Product Type<span class="required-sign">*</span></label>

    <input type="text" id="MrtNumber" [(ngModel)]="ProductDetails.ProductType" [attr.disabled]="!editMode?true:null" />
  </div>

  <div class="col-md-4">

    <label for="">Product Type<span class="required-sign">*</span></label>

    <input type="text" id="MrtNumber" [(ngModel)]="this.ProductTagDetails.Name" [attr.disabled]="!editMode?true:null" />


  </div>

  <div class="col-md-4">
    <label for="">Permalink<span class="required-sign">*</span></label>
    <input type="text" [(ngModel)]="this.ProductDetails.PermaLink" [attr.disabled]="!editMode?true:null" />
  </div>


  <div class="col-md-4">

    <label for="NotReturnable">Refundable ?</label>
    <select class="md-textarea " id="NotReturnable" [attr.disabled]="!editMode?true:null"
      [(ngModel)]="ProductDetails.NotReturnable">
      <option value="false">Yes</option>
      <option value="True">No</option>
    </select>

  </div>
  <div class="col-md-4">

    <label for="visibleIndividually">Visible Individually ?</label>
    <select class="md-textarea" id="visibleIndividually" [attr.disabled]="!editMode?true:null"
      [(ngModel)]="ProductDetails.VisibleIndividually">
      <option value="false">No</option>
      <option value="true">Yes</option>
    </select>

  </div>
</section>

<hr>

<section class="row">
  <div class="col-md-6">

    <!-- <input type="checkbox" id="ParentGroup" [(ngModel)]="ProductDetails.ParentGroupedProductId"
      [attr.disabled]="!editMode?true:null">
    <label for="ParentGroup">Is Group To Parent ?</label> -->

<!--
    <label for="">Select the Group<span class="required-sign">*</span></label>
    <input type="text" class="md-textarea " id="ParentGroupedProductId" placeholder="Please Select Group Product"
      [(ngModel)]="ProductDetails.ParentGroupedProductId" autocomplete="on" /> -->


  </div>

  <div class="col-md-6">

    <input type="checkbox" id="showOnHomePage" [value]="true" [attr.disabled]="!editMode?true:null"
      [(ngModel)]="ProductDetails.ShowOnHomepage">
    <label for="showOnHomePage">Show Product On the Homepage?</label>


    <input type="checkbox" id="disableBuyButton" [value]="true" [attr.disabled]="!editMode?true:null"
      [(ngModel)]="ProductDetails.DisableBuyButton">
    <label for="disableBuyButton">Disable buy button ?</label>

  </div>
</section>

<hr>

<section class="row">
  <div class="col-md-4">

    <label for="">MetaKeywords<span class="required-sign">*</span></label>
    <input type="text" class="md-textarea " [attr.disabled]="!editMode?true:null" id="metaKeywords"
      [(ngModel)]="ProductDetails.MetaKeywords" placeholder="Meta Key words">


  </div>

  <div class="col-md-4">

    <label for="">Meta Title<span class="required-sign">*</span></label>
    <input type="text" class="md-textarea " id="metaTitle" placeholder="metaTitle"
      [(ngModel)]="ProductDetails.MetaTitle" [attr.disabled]="!editMode?true:null" />


  </div>

  <div class="col-md-4">

    <label for="">Meta Description <span class="required-sign">*</span></label>
    <textarea type="text" class="md-textarea " id="MetaDescription" placeholder="metaDescription"
      [(ngModel)]="ProductDetails.MetaDescription" [attr.disabled]="!editMode?true:null"></textarea>


  </div>

</section>

<section class="row">
  <div class="col-md-4">

    <label for="Description">Product Full Description<span class="required-sign">*</span></label>
    <textarea id="productDescription" placeholder=" Product Full Description"
      [(ngModel)]="ProductDetails.FullDescription" [attr.disabled]="!editMode?true:null"></textarea>


  </div>

  <div class="col-md-4">

    <label for="shortDescription">Product Short Description<span class="required-sign">*</span></label>
    <textarea id="productShortDescription" placeholder=" Product Short Description"
      [(ngModel)]="ProductDetails.ShortDescription" [attr.disabled]="!editMode?true:null"></textarea>


  </div>

  <div class="col-md-4">

    <label for="AdminComment">Admin Comment</label>
    <textarea id="AdminComment" placeholder=" Admin Comment Description" [attr.disabled]="!editMode"
      [(ngModel)]="ProductDetails.AdminComment"></textarea>

  </div>
</section>
<!-- </form> -->
