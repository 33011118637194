
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EndpointFactory } from '../endpoint-factory.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class LoginEndpoint extends EndpointFactory {

  private readonly _loginTeleUrl: string = "/api/account/login";

  ;


  constructor(http: HttpClient, injector: Injector) {
    super(http, injector);
  }

 

  loginTeleEndpoint<T>(userObject: any): Observable<T> {

    return this.http.post<T>(this._loginTeleUrl, JSON.stringify(userObject), this.getRequestHeaders()).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.loginTeleEndpoint(userObject)));
      }));
  }


  
}
