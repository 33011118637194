import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { PatientService } from '../../services/patient/patient.service';
//import * as moment from 'moment/moment'
import { AlertService } from '../../services/alert.service';
import { HospitalService } from '../../services/hospital/hospital.service';
import { Paidbooking } from '../../models/patient/patient.model';
import { DoctorService } from '../../services/doctor/doctor.service';
import { ConsentFormComponent } from '../../ConsentForm/app.consentformcomponent';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import * as moment from 'moment-timezone';

@Component({
  templateUrl: './app.paidbookinglist.html'
})
export class PaidBookingListComponent {
  public token: any;
  config: any;
  public patBookList: any;
  public patbook: boolean;
  public hospitalId: string;
  public showButton: boolean = true;
  public VisitId: string;
  p: number = 1;
  public goToWaitingRoom: boolean;
  collection = { count: 60, data: [] };
  nepaliDate = moment();
  constructor(public routing: Router, public dialog: MatDialog,
    private route: ActivatedRoute
    , public authService: AuthenticationService,
    public patService: PatientService
    , public alertService: AlertService,
    public hospitalService: HospitalService,
    public docService: DoctorService) {
    this.token = this.authService.currentUserValue;
    this.ShowBookList();
    this.hospitalId = this.route.snapshot.queryParamMap.get('id');
    if (this.hospitalId == null) {
      this.showButton = false;
    }
    this.VisitId = this.route.snapshot.queryParamMap.get('vid');
    this.GetHospitalIdAndVisitIdFromLocalStorage();

  }
  pageChanged(event) {
    this.config.currentPage = event;
  }
  public ShowBookList() {
    this.patbook = true;
    this.patService.getPatientBookingList(this.token.PatientIdentifier).subscribe((res: any) => {
      if (res && res.IsSuccess && res.Results) {
      this.patBookList = res.Results;

      for (let patBook of this.patBookList) {
        var currentNepaliDate = this.nepaliDate.tz("Asia/Kathmandu").format('YYYY/MM/DD HH:mm z');
        var currentNepaliDateTime = this.nepaliDate.tz("Asia/Kathmandu").format('YYYY/MM/DD HH:mm z');
        var currentdate = moment(currentNepaliDate, 'YYYY/MM/DD').format('YYYY/MM/DD');
        var currentDateWithTime = moment(currentNepaliDateTime, 'YYYY/MM/DD hh:mm').format('YYYY/MM/DD');
        var currentYear = moment(currentNepaliDate, 'YYYY/MM/DD').format('YYYY');
        var currentMonth = moment(currentNepaliDate, 'YYYY/MM/DD').format('M');
        var currentDay = moment(currentNepaliDate, 'YYYY/MM/DD').format('D');
        patBook.VisitDate = moment(patBook.VisitDate).format('YYYY/MM/DD');
        if (patBook.VisitDate == moment(this.nepaliDate).format('YYYY/MM/DD')) {
          var todaydate = new Date();
          var currentdate1 = this.nepaliDate.format('YYYY/MM/DD');
          var index = patBook.BookingTime ? patBook.BookingTime.indexOf("-") : -1;
          var timeextract = patBook.BookingTime ? patBook.BookingTime.substring(0, index - 1) : "";
          var bookedDateTime = moment(currentdate + ' ' + timeextract, 'YYYY/MM/DD HH:mm A');
          var formatedBookedDateTime = moment(bookedDateTime, 'YYYY-MM-DD HH:mm A');
          // var temp = moment(formatedBookedDateTime, 'YYYY-MM-DD HH:mm A').subtract(15, 'minutes').format('YYYY-MM-DD HH:mm A');
          // var formatedBookedDateTime = moment(temp, 'YYYY-MM-DDTHH:mm:s');
          var DocStartTime = moment(currentdate + ' ' + patBook.DocStartTime, 'YYYY/MM/DD HH:mm A');
          var formatedreducedStartTime = moment(DocStartTime, 'YYYY-MM-DD HH:mm A');
          var decStartTime = moment(formatedreducedStartTime, 'YYYY-MM-DD HH:mm A').subtract(15, 'minutes').format('YYYY-MM-DD HH:mm A');
          var formatedDecStartTime = moment(decStartTime, 'YYYY-MM-DDTHH:mm:A');
          var DocEndtime = moment(currentdate + ' ' + patBook.DocEndTime, 'YYYY/MM/DD HH:mm A');
          var formattedEndTime = moment(DocEndtime, 'YYYY-MM-DD HH:mm A');
          var currentTime1 = moment(todaydate).tz("Asia/Kathmandu").format('YYYY-MM-DD HH:mm z');
          var currentTime = moment(currentTime1, 'YYYY/MM/DD hh:mm').format('YYYY-MM-DD HH:mm A');
          var momentObj = moment(currentTime, 'YYYY-MM-DDLT');
          var currentdateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');
          var formatedCurrentTime = moment(currentdateTime, 'YYYY-MM-DD HH:mm A');
          //  var formatedCurrentTime = moment(currentTime, 'YYYY-MM-DD HH:mm A');
          // var diffmin = formatedBookedDateTime.diff(formatedCurrentTime, 'minutes');
          if (formatedDecStartTime <= formatedCurrentTime && formatedCurrentTime <= formattedEndTime) {
            //if (diffmin <= 15) {
            //  patBook.GoToWaitingRoom = true;
            //  patBook.AccessPermission = true;
            //  patBook.EntryStatus = "go"
            //} else {
            //  patBook.AccessPermission = false;
            //  patBook.EntryStatus = "todayupcoming";
            patBook.GoToWaitingRoom = true;
            patBook.AccessPermission = true;
            patBook.EntryStatus = "go"
          }
          else {
            var diffminwithStarttime = formatedCurrentTime.diff(formatedDecStartTime, 'minutes');
            var diffminwithEndtime = formatedCurrentTime.diff(formattedEndTime, 'minutes');
            if (diffminwithStarttime < 0) {
              patBook.AccessPermission = false;
              patBook.EntryStatus = "todayupcoming";
            }
            if (diffminwithEndtime > 0) {
              patBook.AccessPermission = false;
              patBook.EntryStatus = "todaypast";
            }
          }


        }
        if (patBook.VisitDate < currentDateWithTime) {
          patBook.AccessPermission = false;
          patBook.GoToWaitingRoom = false;
          patBook.EntryStatus = "missed";
        }
        if (patBook.VisitDate > currentDateWithTime) {
          patBook.AccessPermission = false;
          patBook.GoToWaitingRoom = false;
          patBook.EntryStatus = "upcoming";
        }
        patBook.VisitDate = moment(patBook.VisitDate).format('ll');

        patBook.VisitStartTime = moment(patBook.VisitStartTime).format('LT');
      }
      this.collection = { count: this.patBookList.length, data: [] }

      for (var i = 0; i < this.collection.count; i++) {
        this.collection.data.push(
          {
            id: i + 1,
            VisitDate: this.patBookList[i].VisitDate,
            HospitalName: this.patBookList[i].HospitalName,
            DoctorName: this.patBookList[i].DoctorName,
            DepartmentName: this.patBookList[i].DepartmentName,
            VisitType: this.patBookList[i].VisitType,
            Status: this.patBookList[i].Status,
            ProviderId: this.patBookList[i].ProviderId,
            HospitalId: this.patBookList[i].HospitalId,
          }
        );
      }
    }else {
      this.alertService.error(res.ResponseMessage);
    }
    },
      error => {
        this.alertService.error(error);
        // this.loading = false;
      });

  }


  GotoWaitingRoom(data) {
    this.docService.DoctorId = data.ProviderId;
    const dialogRef = this.dialog.open(ConsentFormComponent,
      { data: { DoctorId: data.ProviderId, HospitalId: data.HospitalId }, width: '1300px', height: '1000px' }
    );
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      dialogRef.close();
      if (result == true) {
        console.log(`Dialog result: ${result}`);
        // this.visitObj.DepartmentId = this.hospitalService.DepartmentId;
        this.routing.navigate(['/WaitingRoom'], { queryParams: { vid: data.VisitId, id: this.hospitalId, did: data.ProviderId } });
      }
      else
        this.routing.navigate(['/PatPaidBookingList']);
    });
    //this.patService.VisitId = data.VisitId;
  }

  public BookNewVisit() {
    this.hospitalService.DepartmentId = null;
    this.docService.DoctorId = null;
    this.routing.navigate(['/PatBookAppointment'], { queryParams: { id: this.hospitalId } });
  }

  public MyBookList() {
    this.routing.navigate(['/PatBookList'], { queryParams: { id: this.hospitalId } });

  }

  public MyPaidBooking() {
    this.routing.navigate(['/PatPaidBookingList'], { queryParams: { id: this.hospitalId } });
  }
  public BackDashBoard() {
    //this.updatePat = false;
    this.routing.navigate(['/PatDashboard']);
  }

  GetHospitalIdAndVisitIdFromLocalStorage(): void {
    if (this.hospitalId == null) {
      this.hospitalId = localStorage.getItem("hospitalId");
      localStorage.removeItem("hospitalId");
    }
    if (this.VisitId == null) {
      this.VisitId = localStorage.getItem("visitId");
      localStorage.removeItem("visitId");
    }
  }
  UploadFile(data) {

  }

}
