<app></app>
<div class="bg-back">
  <button (click)="Admin()" class="back-btn-doc-room">Back To Admin</button>
  <button
    type="button"
    data-toggle="modal"
    data-target="#target"
    class="mat-chip mat-primary mat-standard-chip mat-chip-selected"
    (click)="clearForm()"
  >
    + Schedule Meeting
  </button>
</div>
<div class="card">
  <div class="card-body">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">Meeting Number</th>
          <th scope="col">Api Key</th>
          <th scope="col">Api Secret</th>
          <th scope="col">Is-Active</th>
          <th scope="col">Doctor Name</th>
          <th scope="col">Doctor Id</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let meeting of meetingList; let i = index">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ meeting.MeetingNumber }}</td>
          <td>{{ meeting.ApiKey }}</td>
          <td>{{ meeting.ApiSecret }}</td>
          <td>{{ meeting.IsActive }}</td>
          <td>{{ meeting.DoctorName }}</td>
          <td>{{ meeting.DoctorId }}</td>
          <td>
            <button
              class="btn btn-primary btn-sm mr-1"
              data-toggle="modal"
              data-target="#target1"
              (click)="EditSchedule(meeting)"
            >
              <i class="fa fa-pencil"></i> Edit
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!isMeetingAvailable">
      <div class="not_available_message"><i class="fa fa-exclamation-circle text-danger fontawesome-icon"></i>
        Sorry! No Meetings found!
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="target"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
>
  <div id="target" class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel" data-target="#target1">
          Schedule New Meeting
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="background-color: snow">
        <div class="info-heading">
          <form
            [formGroup]="meetingScheduleForm"
          >
            <table>
              <tr class="col">
                <td>
                  <label
                    >Meeting Number<span class="required-sign">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="MeetingNumber"
                    name="MeetingNumber"
                    value=""
                    size="25"
                    required
                  />
                </td>
                <td>
                  <label>Api Key<span class="required-sign">*</span></label>
                  <input
                    type="text"
                    formControlName="ApiKey"
                    name="ApiKey"
                    value=""
                    size="25"
                    required
                  />
                </td>
                <td>
                  <label>Api Secret<span class="required-sign">*</span></label>
                  <input
                    type="text"
                    formControlName="ApiSecret"
                    name="ApiSecret"
                    value=""
                    size="25"
                    required
                  />
                </td>
              </tr>
              <tr class="3-cols">
                <td>
                  <label>Password<span class="required-sign">*</span></label>
                  <input
                    type="password"
                    name="Password"
                    formControlName="Password"
                    value=""
                    size="25"
                    required
                  />
                </td>
                <td>
                  <div>
                    <label>Doctor<span class="required-sign">*</span></label>
                    <select class="form-control" formControlName="DoctorId">
                      <option value="" disabled selected>Select Doctor</option>
                      <option *ngFor="let doctor of doctorList" [value]="doctor.DoctorId">
                        {{ doctor.DoctorName }}
                      </option>
                    </select>                 
                  </div>
                </td>
              </tr>
              <tr class="3-cols">
                <td>
                  <p class="IsActive-checkbox">
                    <input
                      type="checkbox"
                      formControlName="IsActive"
                      id="IsActive"
                    />
                    <label for="IsActive">Is Active</label>
                  </p>
                </td>
              </tr>
            </table>
            <div class="modal-footer">
              <button class="btn btn-success" value="Submit" (click)="ScheduleMeeting()">
                Schedule Meeting
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="target1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
  [style.display]="editModal ? 'block' : 'none'"
>
  <div id="target1" class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel" data-target="#target1">
          Update Meeting
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="background-color: snow">
        <div class="info-heading">
          <form
            [formGroup]="meetingScheduleForm"
          >
            <table>
              <tr class="col">
                <td>
                  <label
                    >Meeting Number<span class="required-sign">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="MeetingNumber"
                    name="MeetingNumber"
                    value=""
                    size="25"
                    required
                  />
                </td>
                <td>
                  <label>Api Key<span class="required-sign">*</span></label>
                  <input
                    type="text"
                    formControlName="ApiKey"
                    name="ApiKey"
                    value=""
                    size="25"
                    required
                  />
                </td>
                <td>
                  <label>Api Secret<span class="required-sign">*</span></label>
                  <input
                    type="text"
                    formControlName="ApiSecret"
                    name="ApiSecret"
                    value=""
                    size="25"
                    required
                  />
                </td>
              </tr>
              <tr class="3-cols">
                <td>
                  <label>Password<span class="required-sign">*</span></label>
                  <input
                    type="password"
                    name="Password"
                    formControlName="Password"
                    value=""
                    size="25"
                    required
                  />
                </td>
                <td>
                  <div>
                    <label>Doctor<span class="required-sign">*</span></label>
                    <select class="form-control" formControlName="DoctorId">
                      <option value="" disabled selected>Select Doctor</option>
                      <option *ngFor="let doctor of doctorList" [value]="doctor.DoctorId">
                        {{ doctor.DoctorName }}
                      </option>
                    </select>                 
                  </div>                
                </td>
              </tr>
              <tr class="3-cols">
                <td>
                  <input
                  type="checkbox"
                  formControlName="IsActive"
                  id="IsActive_Box"
                />
                <label for="IsActive_Box">Is Active</label>
                </td>
              </tr>
            </table>
            <div class="modal-footer">
              <button class="btn btn-success" value="Submit" (click)="EditScheduleMeeting(meetingScheduleForm)">
                Schedule Meeting
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
