import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first, takeWhile } from 'rxjs/operators';

import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../services/alert.service';
import { DoctorService } from '../../services/doctor/doctor.service';
import { NotificationService } from '../../services/notification.service';
import { Qualification } from 'src/app/models/Qualification/qualification.model';
import { Doctor } from 'src/app/models/doctor/doctor.model';
import { HospitalDoctorMap } from 'src/app/models/HospitalDoctorMap/app.hospitaldoctormap.model';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Subscription, timer } from 'rxjs';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'register',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  docRegisterForm: FormGroup;
  patRegisterForm: FormGroup;
  loading = false;
  public today = new Date();
  submitted = false;
  isPatientRegister = false;
  public token: any;
  typeregister: string = "";
  loginpatient: string = "";
  showPassword = false;
  showConfirmPassword = false;
  hospitalIdentifier: string;
  public deptList: Array<any> = new Array<any>();
  public qualification: Array<Qualification> = new Array<Qualification>();
  public hospdocMap: Array<HospitalDoctorMap> = new Array<HospitalDoctorMap>();
  public doc = new Doctor();
  ConsultationType = [
    { id: 1, name: "Online Appointment" },
    { id: 2, name: "Telemedicine" }
  ];
  public progress: number;
  public message: string;
  public tempvalue: string = "";

  timermsg = timer(2000, 6000);
  public Age: string = "0";
  public dateOfBirth: string = String(moment(this.today).format("MM/DD/YYYY"));
  public registerComponentSubscription = new Subscription();
  public countries: { name: string; code: string }[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: DoctorService,
    private alertService: AlertService, private notifyService: NotificationService, private route: ActivatedRoute,
    public http: HttpClient, public changeDetector: ChangeDetectorRef

  ) {
    this.typeregister = this.route.snapshot.queryParamMap.get('id');
    this.loginpatient = this.route.snapshot.queryParamMap.get('login');
    if (this.typeregister != "patientregister" && this.loginpatient != "patient") {
      this.hospitalIdentifier = this.route.snapshot.queryParamMap.get('hospital');
      this.GetDepartmentList();
    }
  }

  ngOnInit() {
    this.countries = [
      { name: 'Nepal', code: '+977'},
      { name: 'United States', code: '+1' },
      { name: 'United Kingdom', code: '+44' },
      { name: 'Canada', code: '+1' },
      { name: 'Australia', code: '+61' },
      { name: 'India', code: '+91' },
      { name: 'Sri Lanka', code: '+94'},
      { name: 'Afghanistan', code: '+93' },
      { name: 'Bangladesh', code: '+880' },
      { name: 'Bhutan', code: '+975' },
      { name: 'Maldives', code: '+92' },
    ];
    
    if (this.typeregister == "docregister") {
      this.docRegisterForm = this.formBuilder.group({
        phonenumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        firstname: ['', [Validators.required]],
        lastname: ['', [Validators.required]],
        middlename: [''],
        dateofbirth: [''],
        mailingaddress: [''],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmpassword: ['', [Validators.required, Validators.minLength(6)]],
        hospitalIdentifier: this.hospitalIdentifier != null ? this.hospitalIdentifier : "NA",
        NMC: ['', [Validators.required]],
        Gender: ['', [Validators.required]],
        Designation: ['', [Validators.required]],
        Education: [''],
        Experience: [''],
        PastAffiliation: [''],
        Membership: [''],
        Department: ['', [Validators.required]],
        Charge: ['', [Validators.required, Validators.pattern('[0-9]*')]],
        ConsultationType: ['', [Validators.required]],
        LongSignature: [''],
        Filepath: [''],
        Age: [''],
        BloodGroup: [''],
        Address: ['']
        //password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}')]],
        //confirmpassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}')]]
      });
    }
    if (this.typeregister == "patientregister") {
      this.patRegisterForm = this.formBuilder.group({
        countryCode: [this.countries[0].code],
        phonenumber: new FormControl(
          '',
          [
            Validators.required, 
            Validators.pattern("^[+]?[0-9-]+$"), 
            Validators.minLength(7),
            Validators.maxLength(15) 
          ]
        ),
        firstname: ['', [Validators.required]],
        lastname: ['', [Validators.required]],
        middlename: [''],
        dateofbirth: [''],
        mailingaddress: ['', [Validators.required]],
        Address: ['', [Validators.required]],
        city: ['', [Validators.required]],
        state: ['', [Validators.required]],
        postalcode: ['', [Validators.required]],
        country: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmpassword: ['', [Validators.required, Validators.minLength(6)]],
        Gender: ['', [Validators.required]],
        Filepath: [''],
        Age: [''],
        BloodGroup: ['']
      });
    }
  }
  
  ngOnDestroy(): void{
    this.registerComponentSubscription.unsubscribe();
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.changeDetector.detectChanges();
  }

  // convenience getter for easy access to form fields
  get f() {
    if (this.typeregister == "docregister") {
      return this.docRegisterForm.controls;
    }
    else {
      return this.patRegisterForm.controls;
    }
  }

  onSubmit() {
    this.submitted = true;
    //stop here if form is invalid
    // if (this.registerForm.invalid) {
    //  return;
    // }

    this.loading = true;

    if (this.typeregister == "docregister") {
      if (this.docRegisterForm.value.password != this.docRegisterForm.value.confirmpassword) {
        this.notifyService.showError("", "Password and Confirm  Password do not match");
        this.loading = false;
      }
      if (this.docRegisterForm.controls['phonenumber'].invalid) {
        this.notifyService.showError("Please enter correct Phone Number.", "Invalid Input");
        this.loading = false;
        return;
      }
      if (this.docRegisterForm.value.phonenumber == "") {
        this.notifyService.showError("", "Please enter  Phone Number");
        this.loading = false;
        return;
      }
      if (this.docRegisterForm.value.firstname == "") {
        this.notifyService.showError("", "First Name is required");
        this.loading = false;
        return;
      }
      if (this.docRegisterForm.value.lastname == "") {
        this.notifyService.showError("", "Last Name is required");
        this.loading = false;
        return;
      }
      if (this.docRegisterForm.value.NMC == "") {
        this.notifyService.showError("", "NMC number is required");
        this.loading = false;
        return;
      }
      if (this.docRegisterForm.value.lastname == "") {
        this.notifyService.showError("", "Last Name is required");
        this.loading = false;
        return;
      }
      if (this.docRegisterForm.value.Gender == "") {
        this.notifyService.showError("", "Gender is required");
        this.loading = false;
        return;
      }

    }
    if (this.typeregister == "patientregister") {
      if (this.patRegisterForm.value.password != this.patRegisterForm.value.confirmpassword) {
        this.notifyService.showError("", "Password and Confirm  Password do not match");
        this.loading = false;
      }
    }

    if (this.typeregister == "docregister" && this.docRegisterForm.value.password == this.docRegisterForm.value.confirmpassword) {
      if (this.docRegisterForm.valid) {
        let newQualification = new Qualification();
        let newHospitalDoctorMap = new HospitalDoctorMap();

        newQualification.Designation = this.docRegisterForm.controls['Designation'].value;
        newQualification.Education = this.docRegisterForm.controls['Education'].value;
        newQualification.Experience = this.docRegisterForm.controls['Experience'].value;
        newQualification.PastAffiliation = this.docRegisterForm.controls['PastAffiliation'].value;
        newQualification.Membership = this.docRegisterForm.controls['Membership'].value;
        newHospitalDoctorMap.DepartmentId = this.docRegisterForm.controls['Department'].value;
        newHospitalDoctorMap.ConsultationType = this.docRegisterForm.controls['ConsultationType'].value;
        if (this.docRegisterForm.value.Charge != 0) {
          newHospitalDoctorMap.Charge = this.docRegisterForm.controls['Charge'].value;
        }
        else {
          this.notifyService.showError("", "Charge should not be 0");
          this.loading = false;
          return;
        }

        this.qualification.push(newQualification);
        this.hospdocMap.push(newHospitalDoctorMap);

        this.doc.UserName = this.doc.PhoneNumber = this.docRegisterForm.controls['phonenumber'].value;
        this.doc.FirstName = this.docRegisterForm.controls['firstname'].value;
        this.doc.LastName = this.docRegisterForm.controls['lastname'].value;
        this.doc.MiddleName = this.docRegisterForm.controls['middlename'].value;
        this.doc.DateOfBirth = this.docRegisterForm.controls['dateofbirth'].value;
        this.doc.MailingAddress = this.docRegisterForm.controls['mailingaddress'].value;
        this.doc.Password = this.docRegisterForm.controls['password'].value;
        this.doc.ConfirmPassword = this.docRegisterForm.controls['confirmpassword'].value;
        this.doc.HospitalIdentifier = this.docRegisterForm.controls['hospitalIdentifier'].value;
        this.doc.NMC = this.docRegisterForm.controls['NMC'].value;
        this.doc.Gender = this.docRegisterForm.controls['Gender'].value;
        this.doc.LongSignature = this.docRegisterForm.controls['LongSignature'].value;
        this.doc.FilePath = this.docRegisterForm.controls['Filepath'].value;
        this.doc.Qualification = JSON.stringify(this.qualification);
        this.doc.HospitalDoctorMap = JSON.stringify(this.hospdocMap);

        this.userService.registerDoctor(this.doc)
          .pipe(first())
          .subscribe(
            data => {
              this.alertService.success('Registration successful', true);
              this.router.navigate(['/doclist']);
            },
            error => {
              console.error("Register Doctor Response" + error);
              this.alertService.error(error);
              this.notifyService.showError("NMC, Email and Phone number should be unique.", "Duplicate user");
              this.loading = false;
            });
      }
      else {
        this.notifyService.showError("", "Please fill all required fields properly");
        this.loading = false;
        return;
      }
    }

    if (this.typeregister == "patientregister" && this.patRegisterForm.value.password == this.patRegisterForm.value.confirmpassword) {
      if (this.patRegisterForm.valid) {
        // const fullPhoneNumber = `${this.f.countryCode.value}-${this.f.phonenumber.value}`;
        // this.patRegisterForm.controls['phonenumber'].setValue(fullPhoneNumber);
        this.userService.registerPatient(this.patRegisterForm.value)
          .pipe(first())
          .subscribe(
            data => {

              this.alertService.success('Registration successful', true);
              if (this.loginpatient === "patient") {
                this.router.navigate(['/login']);
              }
              else {
                this.router.navigate(['/adminpatlist']);
              }

            },
            error => {
              this.alertService.error(error);
              this.notifyService.showError("", "Phone Number is already taken")
              this.loading = false;
            });
      }
      else {
        this.notifyService.showError("", "Please fill all required fields properly")
        this.loading = false;
        return;
      }
    }
  }
  showPass() {
    this.showPassword = !this.showPassword;
  }
  showConfirmPass() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  BackLogin() {
    this.router.navigate(['/login']);
  }
  BackAdmin() {
    this.router.navigate(['/admin']);
  }
  BackToDoctor() {
    this.router.navigate(['/doclist']);
  }
  BackToPatient() {
    this.router.navigate(['/adminpatlist']);
  }
  GetDepartmentList() {
    this.userService.getDepartmentList().subscribe((res: any) => {
      if (res && res.IsSuccess && res.Results) {
        this.deptList = res?.Results;
      }
    },
      error => {
        this.alertService.error(error);
        this.loading = false;
      });
  }

  upload(files) {

    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    const uploadReq = new HttpRequest('POST', `api/Doctor/UploadFile/`, formData, {
      reportProgress: true,
    });


    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response)
        this.message = event.body.toString();

    });

  }
  Removepicture() {
    this.doc.FilePath = null;
    this.tempvalue = '';
    this.message = 'Profile removed'

    this.registerComponentSubscription.add(this.timermsg.pipe(
      takeWhile(x => this.message != null)
    ).subscribe(
      () => {
        this.message = null;

      }
    ));
  }

  generateAge() {
    let dobYear = String(moment(this.dateOfBirth).format("YYYY"));
    if (dobYear > '1900') {
      this.Age = String(moment().diff(moment(this.dateOfBirth), 'years'));
    }
  }

  generateDOB() {
    //var curDate = new Date();
    this.dateOfBirth = String(moment(this.today).subtract(this.Age, 'year').format("MM/DD/YYYY"));
  }

}
