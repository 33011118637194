import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from "../../app.global";
import { Subscription, timer } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { PatientService } from "../../services/patient/patient.service";
import { AuthenticationService } from "../../services/authentication.service";
import { PatientDoc, Patient } from "../../models/patient/patient.model";
import { Visit, visitdata } from "../../models/visit/visit.model";
import { AlertService } from "../../services/alert.service";
import { NotificationService } from "../../services/notification.service";
import { DoctorService } from '../../services/doctor/doctor.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChatService } from 'src/app/services/ChatNotification/chat-notification.service';
import { Doctor } from '../../models/doctor/doctor.model';
import { PopUpDialogComponent } from '../../components/common/popup/pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { UUID } from 'angular2-uuid';
// import '../../../Vendor/jitsi/external_api.js';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// declare var JitsiMeetExternalAPI: any;


@Component({
  templateUrl: "./app.doctorroom.html",
  styleUrls: ["./app.doctorroom.css"]
})
export class DoctorRoomComponent implements OnInit {
  domain: string = "meet.jit.si"; // For self hosted use your domain
  room: any;
  options: any;
  api: any;
  user: any;
  isAudioMuted = false;
  isVideoMuted = false;

  changeDetectorRef: ChangeDetectorRef;

  public showPatDetail: boolean = false;
  istimer = "";
  apiDestroy: boolean = false;
  IsDoctor: boolean;
  showDocument: boolean = false;
  clickDocument: boolean = false;
  public isCollapsed = true;
  token: any;
  public path: any;


  public Title: any;
  public Filetype: string;
  public docName: string;
  public Ext: boolean;
  public showChat: boolean;
  isCancelled: boolean;
  public showCallButton: boolean = true;
  visitObj: Visit = new Visit();
  public visitdata: visitdata = new visitdata();
  public visitId: string;
  public chatPatName: string;
  public chatPatId: string;
  public patId: string;
  public fileExtension: any;
  public docInfo: Doctor = new Doctor();
  patients: Array<PatientDoc> = new Array<PatientDoc>();
  docList: Array<any> = new Array<any>();
  public patInfo: Patient = new Patient();
  patList: Array<PatientDoc> = new Array<PatientDoc>();
  filteredpat: Array<PatientDoc> = new Array<PatientDoc>();
  fileData: File = null;
  filedoc: any;
  timerpat = timer(4000, 10000);
  timerdoc = timer(4000, 10000);
  timerlogout = timer(2000, 6000);
  loginVal: any;
  previewUrl: any = null;
  public documents: any;

  public patientDocument: any;

  public showView: boolean = false;
  ChatForm: FormGroup;
  ChatMessages: Array<any> = new Array<any>();
  ChatReceivedMessages: Array<any> = new Array<any>();
  docId: string;
  doctorRoomName: string;
  MeetingLink: string;
  public AllUserChats: any;
  apiStatusDestroy = false;

  startcall: boolean = false;
  roomName: any;
  remoteUserDisplayName: any;
  closeResult = '';
  VisitId: any;
  DoctorId: any;
  isVideoFullscreen = false;
  isDoctor = false;
  vid: any;
  loginP: any;
  logout = false;
  idreportnotification: any;
  @ViewChild('fullScreen', { static: true }) divRef;
  doctorRoomSubscription = new Subscription();
  public safeUrl : any;
  public hasCallStarted: boolean = false;

  constructor(public httpClient: HttpClient,
    private modalService: NgbModal,
    public routing: Router, private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    public global: Global, public patService: PatientService, public dialog: MatDialog,
    private alertService: AlertService, public docService: DoctorService,
    private notifyService: NotificationService, public notificationService: ChatService,
    private formBuilder: FormBuilder, private ref: ChangeDetectorRef,
    private ngZone: NgZone, private sanitizer: DomSanitizer) {
    this.token = this.authenticationService.currentUserValue;
    this.global.apiLogout = false;
    this.initForm();
    if (this.route.snapshot.queryParams['id']) {
      this.VisitId = this.route.snapshot.queryParamMap.get('id');
    }
    if (this.route.snapshot.queryParams['did']) {
      this.DoctorId = this.route.snapshot.queryParamMap.get('did');
    }
    if (this.token.UserType == "Doctor") {
      this.GetDoctorName();
      this.IsDoctor = true;
    } else {
      this.getPatientName();
      this.GetDoctorName();
      this.IsDoctor = false;
      // this.getjitsiroomname();
    }

    this.notificationService.Connect();
    this.notificationService.EventChatMessage.subscribe((chatData) => {
      if (this.ChatForm.controls["Id"].value != chatData.Name) {
        this.OnChatUserChange();
      }

      const chatMsg = {
        Name: chatData.Name,
        Message: chatData.Message,
        Class: "receiver-msg",
      };
      this.ChatMessages.push(chatMsg);
      this.pushChatMsgUserwise(chatData.Name, chatMsg);

    });
    this.TimerDocandPat();
    this.checkpatinlist();
    this.changeDetectorRef = ref;
    //generate jitsi roomname
    this.room = UUID.UUID();
  }

  //jitsi start
  // ngAfterViewInit(): void {
  //   if (this.token.UserType != "Patient") {
  //     this.options = {
  //       roomName: this.room,
  //       width: "100%",
  //       height: "100%",
  //       configOverwrite: { prejoinPageEnabled: false },
  //       interfaceConfigOverwrite: {
  //         // overwrite interface properties
  //       },
  //       parentNode: document.querySelector('#jitsi-iframe'),
  //       userInfo: {
  //         displayName: this.global.doctorObj.DoctorName,
  //       }
  //     }

  //     this.api = new JitsiMeetExternalAPI(this.domain, this.options);

  //     // Event handlers
  //     this.api.addEventListeners({
  //       readyToClose: this.handleClose,
  //       participantLeft: this.handleParticipantLeft,
  //       participantJoined: this.handleParticipantJoined,
  //       videoConferenceJoined: this.handleVideoConferenceJoined,
  //       videoConferenceLeft: this.handleVideoConferenceLeft,
  //       audioMuteStatusChanged: this.handleMuteStatus,
  //       videoMuteStatusChanged: this.handleVideoStatus
  //     });
  //   }
  // }


  // handleClose = () => {
  //   console.log("handleClose");
  // }

  // handleParticipantLeft = async (participant) => {
  //   console.log("handleParticipantLeft", participant);
  //   const data = await this.getParticipants();
  // }

  // handleParticipantJoined = async (participant) => {
  //   console.log("handleParticipantJoined", participant);
  //   const data = await this.getParticipants();
  // }

  // handleVideoConferenceJoined = async (participant) => {
  //   console.log("handleVideoConferenceJoined", participant);
  //   const data = await this.getParticipants();
  // }

  // handleVideoConferenceLeft = () => {
  //   console.log("handleVideoConferenceLeft");
  //   localStorage.setItem("istimer", "false");
  //   if (this.token.UserType == "Doctor") {
  //     this.routing.navigate(['/DocDashboard']);
  //   }
  //   else {
  //     this.apiDestroy = true;
  //     this.apiStatusDestroy = true;
  //     this.routing.navigate(['/PatDashboard']);
  //   }

  // }

  // handleMuteStatus = (audio) => {
  //   console.log("handleMuteStatus", audio); // { muted: true }
  // }

  // handleVideoStatus = (video) => {
  //   console.log("handleVideoStatus", video); // { muted: true }
  // }

  // getParticipants() {
  // }

  executeCommand(command: string) {
    this.api.executeCommand(command);
    if (command == 'hangup') {
      localStorage.setItem("istimer", "false");
      if (this.token.UserType == "Doctor") {
        this.routing.navigate(['/DocDashboard']);
      }
      else {
        this.apiDestroy = true;
        this.apiStatusDestroy = true;
        this.routing.navigate(['/PatDashboard']);
      }
      return;
    }

    if (command == 'toggleAudio') {
      this.isAudioMuted = !this.isAudioMuted;
    }

    if (command == 'toggleVideo') {
      this.isVideoMuted = !this.isVideoMuted;
    }
  }
  //jitsi end


  TimerDocandPat() {
    this.loginVal = this.authenticationService.loginValue;
    this.loginP = localStorage.getItem('loginPat');
    localStorage.setItem("istimer", "true");
    this.istimer = localStorage.getItem("istimer");
    if (this.token.UserType === "Patient" && this.istimer === "true" && this.apiDestroy === false && this.loginP === "patient") {

      this.doctorRoomSubscription.add(this.timerpat.pipe(
        takeWhile(x => this.istimer === "true" && this.apiStatusDestroy === false && this.loginP === "patient")
      ).subscribe(
        () => {
          this.patService.getVisitFromDoctorRoom(this.VisitId).subscribe(res =>
            this.StatusSuccess(res),
            res => this.Error(res));
        }
      ));

    }
    else {

      this.doctorRoomSubscription.add(this.timerdoc.pipe(
        takeWhile(x => this.istimer === "true")
      ).subscribe(
        () => {
          this.RefreshPatients();

        }));
    }
  }

  ngOnInit() {
    this.GetPatientFiles(this.VisitId);
  }
  ngOnDestroy() {
    this.doctorRoomSubscription.unsubscribe();
    if (this.visitId != null) {
      this.patService.changeStatusFromDoc(this.visitId)
      this.patService.changeStatusFromDoc(this.visitId).subscribe(
        (res) => this.SuccesschangeStatusFromDoc(res),
        (res) => this.ErrorPatAtt(res)
      );

      // const callMsg = {
      //   SenderId: this.IsDoctor
      //     ? this.docInfo.IdentityUserId
      //     : this.patInfo.IdentityUserId,
      //   ReceiverId: this.idreportnotification,
      //   VisitId: this.visitId,
      // };

      // this.notificationService.CallPatient(callMsg);
    }
  }
  SuccesschangeStatusFromDoc(res) {

  }
  private initForm() {
    this.ChatForm = this.formBuilder.group({
      Id: [null, Validators.required],
      chatMessage: ['', Validators.required]
    });
  }
  hasError(typeofvalidator: string, controlname: string): boolean {
    const control = this.ChatForm.controls[controlname];
    return control.hasError(typeofvalidator) && control.dirty;
  }

  public GetDoctorName() {
    this.docService.getDoctor(this.token.DoctorIdentifier ? this.token.DoctorIdentifier : this.DoctorId).subscribe(res => this.SuccessDoctorNamme(res),
      res => this.Error(res));
  }
  SuccessDoctorNamme(res) {
    this.docInfo = res;
    this.docName = `${res.FirstName} ${res.MiddleName ? res.MiddleName : ""} ${res.LastName}`;
    this.docId = res?.DoctorId;
    this.doctorRoomName = res.DoctorRoomName;
    this.MeetingLink = res.MeetingLink;
    this.docList.push({
      DoctorName: this.docName,
      IdentityUserId: ""
    });
    if (this.token.UserType == "Doctor") {
      this.vid = this.authenticationService.visitid;
      if (this.vid !== null) {
        this.patService.getPatientByVisitId(this.vid).subscribe(
          (res) => this.DoctorRoomBrowserRefreshPatient(res),
          (res) => this.ErrorPatAtt(res)
        );
      }
    }
    const url = this.global.config.videourl;
    const urlParams = new URL(`${url}`);
    //const roomName = urlParams.set('room', `${this.doctorRoomName}`);
    // const roomName = urlParams.searchParams.set('room',`${this.doctorRoomName}`);
    this.global.config.videourl = urlParams.toString();

    //if (this.token.UserType === "Doctor" || this.token.UserType === "Patient") {
    //this.domain = "meet.jit.si";
    //this.options = {
    //  roomName: `${this.doctorRoomName}_${this.docName}`,
    //  //width: 850,
    //  //height: 570,
    //  parentNode: document.querySelector('#meet'),
    //  configOverwrite: {
    //    doNotStoreRoom: true,
    //    disableInviteFunctions: true,
    //    startWithVideoMuted: true,
    //    startWithAudioMuted: true,
    //    enableWelcomePage: false,
    //    disableRemoteMute: true,
    //    prejoinPageEnabled: false,
    //    remoteVideoMenu: {
    //      // If set to true the 'Kick out' button will be disabled.
    //      disableKick: true
    //    },
    //  },
    //  interfaceConfigOverwrite: {
    //    SHOW_BRAND_WATERMARK: false,
    //    SHOW_CHROME_EXTENSION_BANNER: false,
    //    SHOW_JITSI_WATERMARK: false,
    //    SHOW_POWERED_BY: false,
    //    SHOW_WATERMARK_FOR_GUESTS: false,
    //    TOOLBAR_ALWAYS_VISIBLE: false,
    //    GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
    //    DISPLAY_WELCOME_PAGE_CONTENT: false,
    //    DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
    //    DEFAULT_REMOTE_DISPLAY_NAME: `${this.patInfo.PatientId}`,
    //    filmStripOnly: false,
    //    TOOLBAR_BUTTONS: ['microphone', 'camera', 'fullscreen', 'videoquality']
    //  }
    //}
    //this.api = new JitsiMeetExternalAPI(this.domain, this.options);

  }


  public getPatientName() {
    this.patService.getPatient(this.token.PatientIdentifier,this.token.UserType).subscribe(
      (res) => {
        this.patInfo = res;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  StatusSuccess(res) {
    /* var status = new Array<any>();*/
    let status = new Visit();
    status = res;
    this.loginP = localStorage.getItem('loginPat');
    if (status.Status === "completed") {
      this.apiDestroy = true;
      this.patService.VisitId = status.VisitId;
      this.apiStatusDestroy = true;
      this.routing.navigate(['/FinalReport']);
    }
    if (status.Status == "initiated" && status.IsActive === true) {
      this.apiDestroy = true;
      this.apiStatusDestroy = true;
      this.routing.navigate(['/WaitingRoom'], { queryParams: { redirectUrl: 'PatientUrl', did: this.DoctorId, vid: this.VisitId } });
    }
    if (status.Status === "initiated" && status.IsActive === false && this.loginP === "patient") {
      this.apiDestroy = true;
      this.apiStatusDestroy = true;
      this.routing.navigate(['/PatDashboard']);
    }
  }

  RefreshPatients() {
    var DoctorId = this.token.DoctorIdentifier;

    this.patService.getPatientsInDoctorRoom(DoctorId).subscribe(
      (res) => this.SuccessRefreshPatients(res),
      (res) => this.Error(res)
    );
  }
  SuccessRefreshPatients(res) {
    if (res != null) {
      if (res.length > 0) {
        this.patients = res;
        if (this.patList.length == 0) {
          this.patList = res;
          this.patList = this.patList.filter(
            (s) => s.VisitType == 'Telemedicine'
          );
        }
        if (this.patList.length !== this.patients.length) {
          this.showCallButton = true;
          if (this.patients.length === 1) {
            this.chatPatId = this.patients[0].IdentityUserId;
            this.chatPatName = this.patients[0].PatientName;
          }
          this.patList = res;
          this.patList = this.patList.filter(
            (s) => s.VisitType == 'Telemedicine'
          );
        }
      } else if (res.length === 0) {
        localStorage.removeItem("istimer");
        localStorage.setItem('istimer', "false");
        this.istimer = localStorage.getItem('istimer');

        this.patients = [];
        this.routing.navigate(['/DocDashboard'], { queryParams: { redirectUrl: 'PatientUrl' } });
        this.notifyService.showInfo("Info", "There are no patients in Doctor Room ");
      }

      else {
        this.patients = [];
      }
    }

  }
  DoctorRoomBrowserRefreshPatient(res) {
    this.visitObj = res;
    //this.visitObj.Status = "ongoing";
    //this.visitObj.IsActive = true;
    if (this.visitObj.Status === 'ongoing') {
      const DoctorId = this.token.DoctorIdentifier;

      this.patService.getPatientDocList(DoctorId).subscribe(
        (res) => this.SuccessBrowserRefreshPatients(res),
        (res) => this.Error(res)
      );
    }
  }
  SuccessBrowserRefreshPatients(res) {
    if (res.length > 0) {
      this.patients = res;
      if (this.patList.length === 0) {
        this.patList = res;
        this.patList = this.patList.filter(
          (s) => s.VisitType == 'Telemedicine'
        );
      }
      if (this.patList.length !== this.patients.length) {
        this.showCallButton = true;
        if (this.patients.length === 1) {
          this.chatPatId = this.patients[0].IdentityUserId;
          this.chatPatName = this.patients[0].PatientName;
        }
        this.patList = res;
        this.patList = this.patList.filter(
          (s) => s.VisitType == 'Telemedicine'
        );
      }
    } else if (res.length === 0) {
      localStorage.removeItem("istimer");
      localStorage.setItem('istimer', "false");
      this.istimer = localStorage.getItem('istimer');

      this.patients = [];
      this.routing.navigate(['/DocDashboard']);


      this.startcall = false;


      this.routing.navigate(['/DocDashboard'], { queryParams: { redirectUrl: 'PatientUrl' } });
      this.notifyService.showInfo("Info", "There are no patients in Doctor Room ");
    }

    else {
      this.patients = [];
    }
    this.filteredpat = new Array<any>();
    this.filteredpat = JSON.parse(JSON.stringify(this.patients)); //deepcopy
    this.filteredpat = this.filteredpat.filter(
      (s) => s.VisitId == this.vid
    );
    this.showPatDetail = true;
  }
  CallPatient(VisitId: string, IdentityUserId: string) {
    //call to pat and pass jitsi room name
    // this.patService.PostJitsiRoomName(VisitId, this.room).subscribe(
    //   (res) => this.SuccessCreateSession(res),
    //   (res) => this.ErrorCreateSessionfailed(res)
    // );
    if(VisitId && IdentityUserId){
      this.getZoomSignature();
      this.visitId = VisitId;
      this.vid = this.authenticationService.visitIdToken(VisitId);
      this.showCallButton = false;
      //this.showPatDetail = true;
      this.idreportnotification = IdentityUserId;
      this.GetPatientFiles(VisitId);
      this.hasCallStarted = true;
    }
    else{
      this.hasCallStarted = false;
      this.notifyService.showError("", "Failed to Join the call");
    }
  }

  SuccessCreateSession(res) {
    this.patService.getPatientByVisitId(this.visitId).subscribe(
      (res) => this.SuccessCallPatient(res),
      (res) => this.ErrorPatAtt(res)
    );
  }



  ErrorCreateSessionfailed(res) {

  }

  SuccessCallPatient(res) {
    this.visitObj = res;
    this.visitObj.Status = "ongoing";
    this.visitObj.IsActive = true;
    this.visitObj.VisitStartTime = new Date();

    //this.patService.updateStatus(this.visitObj, this.visitId)
    //  .subscribe(res =>
    //    this.alertService.success('Patient Called', true));
    this.filteredpat = new Array<any>();
    this.filteredpat = JSON.parse(JSON.stringify(this.patients)); //deepcopy
    this.filteredpat = this.filteredpat.filter(
      (s) => s.VisitId == this.visitId
    );
    this.showPatDetail = true;
    this.UpdateStatus();

  }



  UpdateStatus() {
    this.patService.updateStatus(this.visitObj, this.visitId)
      .subscribe(res => this.SuccessUpdateStaus(res),
        res => this.ErrorPatAtt(res));
  }
  SuccessUpdateStaus(res) {

    console.log(`call to pat`);
    this.alertService.success('Patient Called', true);
    this.patService.PatientByVisit = false;
    this.RefreshPatients();
    this.logout = false;
    
    this.doctorRoomSubscription.add(this.timerlogout.pipe(
      takeWhile(x => this.logout === false)
    ).subscribe(
      () => {
        this.patService.getVisitFromWaitingRoom(this.visitId).subscribe(res =>
          this.StatusLogoutSuccess(res),
          res => this.Error(res));

      }
    ));
  }
  StatusLogoutSuccess(res) {
    let status = new Visit();
    status = res;
    if (status != null) {
      /*if (status[0].Status === "initiated" && status[0].IsActive === false) {*/
      if (status.Status === "initiated" && status.IsActive === false) {
        this.logout = true;
        this.RefreshPatients();
        this.showCallButton = false;
        this.apiStatusDestroy = true;

      }

    }
    else {
      this.logout = true;
    }


  }
  GetPatientFiles(VisitId) {
    //this.visitId = this.visitId;
    this.clickDocument = true;
    this.patService.getPatientDocuments(VisitId).subscribe(
      (res) => {
        if (res && res.IsSuccess && res.Results) {
          this.documents = res?.Results;
          this.patientDocument = this.documents;
          if (this.patientDocument.length > 0) {
            this.showDocument = true;
          }
        } else {
          this.alertService.error(res.ResponseMessage);
        }
      },
      (error) => {
        this.alertService.error(error);
        //this.loading = false;
      });
  }

  openDialog(VisitId, PatientId) {
    this.EndMeeting(VisitId, PatientId); // Rohan: Calling End Meeting Function as the functionality is same. 
    // const dialogRef = this.dialog.open(PopUpDialogComponent, {
    //   data: { msg: "fromDocRoom" }, disableClose: true,
    //   width: '250px',
    //   height: '300',
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   this.isCancelled = result;
    //   if (this.isCancelled === true) {
    //     this.PatientAttended(VisitId);
    //   }
    //   else {
    //     this.patService.changeStatus(VisitId)
    //       .subscribe(res => this.SuccesschangeStatus(res),
    //         res => this.Error(res));
    //   }
    // });

  }
  SuccesschangeStatus(res) {
    this.showCallButton = true;
  }

  PatientAttended(VisitId, PatientId) {
    this.showPatDetail = false;
    this.showCallButton = true;
    this.patService.getPatientByVisitId(VisitId).subscribe(
      (res) => this.SuccessPatientAttend(res, VisitId, PatientId),
      (res) => this.ErrorPatAtt(res)
    );
  }

  SuccessPatientAttend(res, VisitId, PatientId) {
    //this.patService.VisitId = VisitId;
    this.visitdata = Object.assign(this.visitdata, res)
    this.visitdata.Results.IsActive = false;
    this.visitdata.Results.Status = "completed";
    this.visitdata.Results.TreatmentAdvice = this.visitObj.TreatmentAdvice;
    this.visitdata.Results.Medication = this.visitObj.Medication;
    this.visitdata.Results.FollowUp = this.visitObj.FollowUp;
    localStorage.removeItem('vid');
    this.patService.updateTreatmentAdvice(this.visitdata.Results, PatientId).subscribe(
      (res) =>this.notifyService.showInfo("Treatment Advice Added !", "Success"),
      (res) => this.Error(res)
    );
    const DoctorId = this.token.DoctorIdentifier;
    this.patService.getPatientDocList(DoctorId).subscribe(
      (res) => this.SuccessRefreshPatients(res),
      (res) => this.Error(res)
    );

    const chatMsg = {
      SenderId: this.IsDoctor
        ? this.docInfo.IdentityUserId
        : this.patInfo.IdentityUserId,
      ReceiverId: this.idreportnotification,
      Name: this.IsDoctor
        ? `${this.docInfo.FirstName} ${this.docInfo.MiddleName ? this.docInfo.MiddleName : ""
        } ${this.docInfo.LastName}`
        : `${this.patInfo.FirstName} ${this.patInfo.MiddleName ? this.patInfo.MiddleName : ""
        } ${this.patInfo.LastName}`,
      ReceiverName: this.IsDoctor ? this.chatPatName : this.docName,
      VisitId: VisitId,
    };

    // this.notificationService.SendReport(chatMsg);
    this.PatientCompleted();
  }
  PatientCompleted() {
    //this.patService.VisitId = null;
    this.filteredpat = new Array<any>();
    this.RefreshPatients();
  }
  ErrorPatAtt(res) { }

  Error(res) {
    alert(res.status);
  }

  preview(filePath, fileExt, Title) {
    this.showView = true;
    this.Title = Title + "." + fileExt;;
    //window.open(filePath);
    this.path = filePath;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.path)
    this.fileExtension = fileExt;
    if (
      this.fileExtension == "jpeg" ||
      this.fileExtension == "JPEG" ||
      this.fileExtension == "png" ||
      this.fileExtension == "PNG" ||
      this.fileExtension == "JPG" ||
      this.fileExtension == "jpg"
    ) {
      this.Filetype = "image";
    }
    if (this.fileExtension == "pdf" || this.fileExtension == "PDF") {
      this.Filetype = "pdf";
    }
    if (
      this.fileExtension == "docx" ||
      this.fileExtension == "DOCX" ||
      this.fileExtension == "DOC" ||
      this.fileExtension == "doc"
    ) {
      this.Filetype = "docfile";
    }
  }
  hide() {
    this.showView = false;
  }
  SendChatMsg() {
    if (this.token.UserType == "Patient") {
      this.ChatForm.controls["Id"].setValue(this.docName);
    }
    try {
      for (const i in this.ChatForm.controls) {
        this.ChatForm.controls[i].markAsDirty();
        this.ChatForm.controls[i].updateValueAndValidity();
      }

      if (this.ChatForm.valid) {
        const chatMsg = {
          SenderId: this.IsDoctor
            ? this.docInfo.IdentityUserId
            : this.patInfo.IdentityUserId,
          ReceiverId: this.IsDoctor
            ? this.chatPatId
            : this.docInfo.IdentityUserId,
          Name: this.IsDoctor
            ? `${this.docInfo.FirstName} ${this.docInfo.MiddleName ? this.docInfo.MiddleName : ""
            } ${this.docInfo.LastName}`
            : `${this.patInfo.FirstName} ${this.patInfo.MiddleName ? this.patInfo.MiddleName : ""
            } ${this.patInfo.LastName}`,
          ReceiverName: this.IsDoctor ? this.chatPatName : this.docName,
          Message: this.ChatForm.controls["chatMessage"].value,
        };
        const chatmsgObj = {
          Name: "Me",
          Message: chatMsg.Message,
          Class: "sender-msg",
          ChatPatName: this.chatPatName
        };
        this.ChatMessages.push(chatmsgObj);
        this.pushChatMsgUserwise(
          this.ChatForm.controls["Id"].value.PatientName,
          chatmsgObj
        );

        // this.cdr.detectChanges();
        // this.scrollBottom.nativeElement.lastElementChild.scrollIntoView(); // scroll to bottom

        this.notificationService.SendChatMessage(chatMsg);

        // this.ChatForm.reset();
        this.ChatForm.controls["chatMessage"].reset();
        if (this.token.UserType == "Doctor") {
          this.ChatForm.controls["Id"].value.setValue(chatMsg.Name);
        } else {
          this.ChatForm.controls["Id"].setValue(this.chatPatName);
        }
      }
    } catch (e) { }
  }

  OnChatUserChange() {
    try {
      const id = this.ChatForm.controls["Id"].value.PatientName;
      this.chatPatId = this.ChatForm.controls["Id"].value.IdentityUserId;
      this.chatPatName = this.ChatForm.controls["Id"].value.PatientName;
      if (this.AllUserChats.hasOwnProperty(id)) {
        this.ChatMessages = this.AllUserChats[id].slice();
        this.ChatReceivedMessages = this.AllUserChats[id].slice();
      } else {
        this.ChatMessages = new Array<any>();
        this.ChatReceivedMessages = new Array<any>();
      }
    } catch (e) { }
  }

  onChatEnter(event) {
    if (event.keyCode === 13) {
      this.SendChatMsg();
    }
  }
  pushChatMsgUserwise(user, messageObj) {
    try {
      if (!this.AllUserChats.hasOwnProperty(user)) {
        this.AllUserChats[user] = new Array<any>();
      }
      this.AllUserChats[user].push(messageObj);
    } catch (e) { }
  }

  checkpatinlist() {
    var DoctorId = this.token.DoctorIdentifier;

    this.patService.getPatientDocList(DoctorId).subscribe(
      (res) => this.Successcheckpatlist(res),
      (res) => this.Error(res)
    );
  }

  Successcheckpatlist(res) {
  }




  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'modal-send-message' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  exitFullscreen() {
    this.isVideoFullscreen = false;
  }
  openFullscreen() {
    this.isVideoFullscreen = true;
    // Use this.divRef.nativeElement here to request fullscreen
    const elem = this.divRef.nativeElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }
  closeFullscreen() {
    this.isVideoFullscreen = false;
    //const elem = this.divRef.nativeElement;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  //openFullscreen() {
  //  this.isVideoFullscreen = true;
  //  // Trigger fullscreen
  //  const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
  //    mozRequestFullScreen(): Promise<void>;
  //    webkitRequestFullscreen(): Promise<void>;
  //    msRequestFullscreen(): Promise<void>;
  //  };

  //  if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
  //    docElmWithBrowsersFullScreenFunctions.requestFullscreen();
  //  } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
  //    docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
  //  } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
  //    docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
  //  } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
  //    docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
  //  }
  //}

  //closefullscreen() {
  //  this.isVideoFullscreen = false;;
  //  const docWithBrowsersExitFunctions = document as Document & {
  //    mozCancelFullScreen(): Promise<void>;
  //    webkitExitFullscreen(): Promise<void>;
  //    msExitFullscreen(): Promise<void>;
  //  };
  //  if (docWithBrowsersExitFunctions.exitFullscreen) {
  //    docWithBrowsersExitFunctions.exitFullscreen();
  //  } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
  //    docWithBrowsersExitFunctions.mozCancelFullScreen();
  //  } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
  //    docWithBrowsersExitFunctions.webkitExitFullscreen();
  //  } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
  //    docWithBrowsersExitFunctions.msExitFullscreen();
  //  }
  //}
  // getjitsiroomname() {
  //   this.patService.getVisitFromWaitingRoom(this.VisitId).subscribe(res =>
  //     this.getjitsiSuccess(res),
  //     res => this.Error(res));
  // }

  // getjitsiSuccess(res) {

  //   if (!res.PostJitsiRoomName) {
  //     this.options = {
  //       roomName: res.JitsiRoomName,
  //       width: "100%",
  //       height: "100%",
  //       configOverwrite: { prejoinPageEnabled: false },
  //       interfaceConfigOverwrite: {
  //         // overwrite interface properties
  //       },
  //       parentNode: document.querySelector('#jitsi-iframe'),
  //       userInfo: {
  //         displayName: this.patInfo.FirstName,
  //       }
  //     }

  //     this.api = new JitsiMeetExternalAPI(this.domain, this.options);

  //     // Event handlers
  //     this.api.addEventListeners({
  //       readyToClose: this.handleClose,
  //       participantLeft: this.handleParticipantLeft,
  //       participantJoined: this.handleParticipantJoined,
  //       videoConferenceJoined: this.handleVideoConferenceJoined,
  //       videoConferenceLeft: this.handleVideoConferenceLeft,
  //       audioMuteStatusChanged: this.handleMuteStatus,
  //       videoMuteStatusChanged: this.handleVideoStatus
  //     });
  //   }
  // }

  download() {
    window.open(this.path);
  }

  client = ZoomMtgEmbedded.createClient();
  // meetingNumber = "2278567480";
  // apiKey = "OkIXXMhwSbaiZnvWsy_rVQ";
  // apiSecret = "tUmpngwqqL9KqHTDC8HmKghFBe41Er8k";
  // password = "i9iXuV";
  getZoomSignature(): void{
  //call to pat and pass jitsi room name
  this.patService.getZoomSignature(this.docId).subscribe(
    (res) => this.successGetZoomSignature(res),
    (res) => this.errorGettingZoomSignature(res)
  );
  }

  successGetZoomSignature(res): void{

    this.SuccessCreateSession(res);

    if(res?.Results){
      this.startMeeting(res?.Results);
    }
  }

startMeeting(signature): void {
  let meetingSDKElement = document.getElementById('meetingSDKElement');
  const [headerEncoded, payloadEncoded, lwtSignature] = signature.split('.');
  const payload = JSON.parse(atob(payloadEncoded));
    
    this.ngZone.runOutsideAngular(async () => {
      try {
        await this.client.init({ zoomAppRoot: meetingSDKElement, language: 'en-US' });
        await this.client.join({
          signature: signature,
          sdkKey: payload.appKey, // Fixed: Use apiKey instead of sdkKey
          meetingNumber: payload.mn,
          password: payload.password,
          userName: `Dr. ${this.global.doctorObj.DoctorName}`,
          tk: "",
          zak: ""
        });

        console.log('Joined successfully');
        this.client.on('connection-change', (status) => {
          console.log('Meeting status changed:', status);
        });
      } catch (error) {
        await this.client.leaveMeeting(); 
        console.log('Left the meeting successfully');
        console.error(error);

      }
    });
  }

  errorGettingZoomSignature(res): void{
    console.log(res);
  }

  EndMeeting(visitId, patientId): void{
    const dialogRef = this.dialog.open(PopUpDialogComponent, {
      data: { msg: "exitMeeting" }, disableClose: true,
      width: '250px',
      height: '300',
    });
     dialogRef.afterClosed().subscribe(result => {
      this.isCancelled = result;
      if (this.isCancelled === true) {
        this.PatientAttended(visitId, patientId); //Rohan: Calling PatientAttend function to add medications, followup and advice for patient.
        //update status of visit.
        this.visitdata.Results.Status = "completed";
        this.visitdata.Results.IsActive = false;
        this.visitdata.Results.VisitStartTime = new Date();
        this.visitdata.Results.VisitId = visitId;
        this.visitdata.Results.PatientId = patientId;
        this.UpdateVisitStatus();
        localStorage.setItem("istimer", "false");
        if (this.token.UserType == "Doctor") {
          this.routing.navigate(['/DocDashboard']);
          window.location.reload();
        }
        // else {
        //   this.apiDestroy = true;
        //   this.apiStatusDestroy = true;
        //   this.routing.navigate(['/PatDashboard']);
        // }
      }
      else {
        // dialogRef.close();
      }
    }); 
  }

  UpdateVisitStatus():void{
    this.patService.updateVisitStatus(this.visitdata.Results, this.visitId)
    .subscribe(res => this.SuccessUpdateVisitStaus(res),
      res => this.ErrorPatAtt(res));
  }
  SuccessUpdateVisitStaus(res): void{

  }
}
