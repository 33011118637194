<app></app>
<div class="back-bg">
  <button [routerLink]="['/admin']" class="back-btn-doc-room">Back <span>To Admin</span> </button>
</div>

<div class="grid-section">
  <h4 class="title">
    <i class="fa fa-list" aria-hidden="true"></i>Order List
  </h4>

  <div class="row searchbar-card text-center" style="margin-top: 0">
    <div class="col-md-6 col-sm-8 m-auto d-flex">
      <div class="searchbar">
        <input class="search_input" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Product..">
        <a class="search_icon text-white"><em class="fa fa-search"></em>Search</a>
      </div>
    </div>
  </div>

  <table aria-label="Order List" class="table table-striped table-hover" style="margin-right: 5%;">
    <thead>
      <tr class="table-tr-style">
        <th scope="col">#</th>
        <th scope="col">Id</th>
        <th scope="col">Order Number</th>
        <th scope="col">Date</th>
        <th scope="col">Amt</th>
        <th scope="col">Order Status</th>
        <th scope="col">Payment Status</th>
        <th scope="col">Payment Method</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of orders | filter: searchText |paginate: { itemsPerPage: 10, currentPage: p  };  let i = index ">
        <td>{{p+i+1}}</td>
        <td>{{data.OrderGuid }}</td>
        <td>{{data.CustomOrderNumber }}</td>
        <td>{{data.OrderedDate | date:'yyyy-MM-dd hh:mm' }}</td>
        <td>{{data.OrderTotal }}</td>
        <td>{{data.OrderStatus }}</td>
        <td>{{data.PaymentStatus }}</td>
        <td>{{data.PaymentMethodSystem }}</td>
      </tr>
    </tbody>
  </table>

  <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>



</div>

<footer></footer>
