import { Component, OnInit } from '@angular/core'
import { Global } from '../../app.global';
import { Router, ActivatedRoute } from '@angular/router';
import { Doctor } from '../../models/doctor/doctor.model';
import { AuthenticationService } from '../../services/authentication.service';
import { DoctorService } from '../../services/doctor/doctor.service';
//import { error } from 'util';
import { AlertService } from '../../services/alert.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ChatService } from 'src/app/services/ChatNotification/chat-notification.service';
import { PatientService } from '../../services/patient/patient.service';

@Component({
  templateUrl: "./app.doctor-dashboard.html"
})
export class DoctorDashboardComponent implements OnInit {
  public updateDoc: boolean;
  public docInfo:Doctor = new Doctor();
  public token: any;
  public allPatList: any;
  public ChatMessages: Array<any> = new Array<any>();
  public AllUserChats: Array<any> = new Array<any>();
  redUrl: string;
  constructor(public global: Global, public routing: Router, public patService: PatientService, private authenticationService: AuthenticationService, 
    public docService: DoctorService, public alertService: AlertService, public notificationService: ChatService, private route: ActivatedRoute) {
    
    this.token = this.authenticationService.currentUserValue;
    this.docService.DoctorId = this.token.DoctorIdentifier;
    this.redUrl = this.route.snapshot.queryParamMap.get('redirectUrl');
   
  }
  ngOnInit() {
    this.notificationService.Connect();
    this.GetDoctorName();
    this.GetPatList();
  }

    public GetDoctorName(){
      //var id= this.token.DoctorIdentifier;
      this.docService.getDoctor(this.token.DoctorIdentifier).subscribe(res =>
        {
          this.docInfo = res;
        },
        error=>{
          this.alertService.error(error);
        }
        );
    }
  // public Update(){
  //  this.updateDoc= true; 
  //  this.routing.navigate(['/DocDashboard/DocUpdateProfile']);
  //}
  public DashBoard() {
    this.updateDoc = false;
    this.routing.navigate(['/DocDashboard']);    
  }

  public GetPatList() {

    this.patService.backToDashboardFromDoc(this.token.DoctorIdentifier).subscribe(res => {
       /* this.allPatList = res;*/
     /*   console.log(this.allPatList);*/

        //for (var i = 0; i < this.allPatList.length; i++) {
        //  if ((this.allPatList[i].Status === 'ongoing' || this.allPatList[i].Status === 'initiated') && this.allPatList[i].IsActive === true) {
        //    this.patService.changeStatusFromDoc(this.allPatList[i].VisitId)
        //      .subscribe(res => this.SuccessChangeStatus(res),
        //        res => this.Error(res));
        //  }
        //}
      },
        error => {
          this.alertService.error(error);
        }
      );
    }

    //this.patService.getPatientList(this.token.DoctorIdentifier)
    //  .subscribe(res => {
    //    this.allPatList = res;
    //    console.log(this.allPatList);
    //    for (var i = 0; i < this.allPatList.length; i++) {
    //      if (this.allPatList[i].Status == 'ongoing') {
    //        this.allPatList.changeStatus(this.allPatList[i].VisitId)
    //          .subscribe(res => this.SuccessChangeStatus(res),
    //            res => this.Error(res));
    //      }
    //    }
    //  },
    //    error => {
    //      this.alertService.error(error);
    //      // this.loading = false;
    //    });
    //if (this.redUrl === null) {
    //  this.docService.getPatientListForStatusChange(this.token.DoctorIdentifier).subscribe(res => {
    //    this.allPatList = res;
    //    console.log(this.allPatList);

    //    for (var i = 0; i < this.allPatList.length; i++) {
    //      if ((this.allPatList[i].Status === 'ongoing' || this.allPatList[i].Status === 'initiated') && this.allPatList[i].IsActive === true) {
    //        this.patService.changeStatusFromDoc(this.allPatList[i].VisitId)
    //          .subscribe(res => this.SuccessChangeStatus(res),
    //            res => this.Error(res));
    //      }
    //    }
    //  },
    //    error => {
    //      this.alertService.error(error);
    //    }
    //  );
    //}
    //else if (this.redUrl === "DoctorUrl") {
    //  this.docService.getPatientListForStatusChange(this.token.DoctorIdentifier).subscribe(res => {
    //    this.allPatList = res;
    //    console.log(this.allPatList);

    //    for (var i = 0; i < this.allPatList.length; i++) {
    //      if ((this.allPatList[i].Status === 'ongoing' || this.allPatList[i].Status === 'initiated') && this.allPatList[i].IsActive === true) {
    //        this.patService.changeStatusFromDoc(this.allPatList[i].VisitId)
    //          .subscribe(res => this.SuccessChangeStatus(res),
    //            res => this.Error(res));
    //      }
    //    }
    //  },
    //    error => {
    //      this.alertService.error(error);
    //    }
    //  );
    //}
    //else {
    //  return;
    //}
  //}

  SuccessChangeStatus(res) {
   
  }

  Error(res) {

  }
  DoctorRoom() {
    this.global.IsDoctor = true;
    this.global.doctorObj.DoctorName= this.docInfo.FirstName;
    this.routing.navigate(['/DoctorRoom']);
  }
}
