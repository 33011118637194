<app></app>
<div>
  <button [routerLink]="['/DocDashboard']" class="back-btn-doc-room">
    Back <span>To DashBoard</span>
  </button>
  <button (click)="OPSummaryReport()" [style.background-color]="showOPSummaryReport ? '#007bff' : '#009688'" class="mr-2">
    OP Summary Report</button>
  <button (click)="OrderWiseReport()" [style.background-color]="!showOPSummaryReport ? '#007bff' : '#009688'">
    Order wise Report</button>
</div>

  <div *ngIf="showOPSummaryReport" class="grid-section" >
    <h4 class="title">
      <i class="fa fa-list"></i>OP Summary Report
    </h4>
    <div class="col-md-12 alert alert-default">
      <div class="row d-flex align-items-center">
        <!--<form [formGroup]="visitObj.PatientValidator">-->
        <label class="control-label" style="margin-left: 100px;">From Date:</label>
        <div class="col-md-3">
          <input type="date" name="date" [(ngModel)]="fromdate" required class="m-0" />
        </div>
        <label class="control-label" style="margin-left: 200px;"> To Date:</label>
        <div class="col-md-3">
          <input type="date" name="date" [(ngModel)]="todate" required class="m-0" />
        </div>
        <button (click)="Search()">Search</button>
        <!--<span *ngIf="!visitObj.IsDirty('VisitDate')" [hidden]="visitObj.IsValidCheck('VisitDate','required')"> Appointment Date is required</span>-->
        <!--</form>-->
      </div>
    </div>
    <div class="row searchbar-card text-center" style="margin-top: 0">
      <div class="col-md-6 col-sm-8 m-auto d-flex">
        <div class="searchbar">
          <input class="search_input" type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
                placeholder="Search Patient.." (click)="filterReport()">
          <a class="search_icon text-white"><i class="fa fa-search"></i>Search</a>
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-center mt-2 ml-2">
      <div class="row col-md-4">
        <label class="control-label mt-1"> Appointment Type:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selAppointmentType"
                  placeholder="Select Appointment Type"
                  display-property-name="AppointmentType"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option value="Telemedicine">Telemedicine</mat-option>
                  <mat-option value="Online Appointment">Online Appointment</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row col-md-4">
        <label class="control-label mt-1"> Visit Type:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selVisitType"
                  placeholder="Select Visit Type"
                  display-property-name="VisitType"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option value="New">New</mat-option>
                  <mat-option value="Follow-Up">Follow-Up</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row col-md-4">
        <label class="control-label mt-1"> Final Diagnosis:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selDiagnosis"
                  placeholder="Select Final Diagnosis"
                  display-property-name="FinalDiagnosis"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
                  <!-- <mat-option *ngFor="let dept of deptList" [value]="dept.DepartmentId">{{dept.DepartmentName}}</mat-option> -->
          </mat-select>
        </div>
      </div>
    </div>
    <div class="col-md-12" style="padding: 5px; text-align: right;">
      <div class="form-actions">
        <button class="btn btn-primary hidden-print" style="margin-right: 10px;" (click)="PrintTxnTable()" value="Print" title="print page">
          Print<i class="fa fa-print" style="margin-left:10px;"></i>
        </button>
        <button class="btn btn-primary hidden-export" (click)="ExportToExcel('exportTable')" value=""
          title="export to excel">
          Export<i class="fa fa-file-excel-o" style="margin-left:10px;"></i>
        </button>
      </div>
    </div>
    <div style="overflow: scroll;">
      <table class="table table-striped table-hover ">
        <thead>
          <tr class="table-tr-style">
            <th scope="col">SN</th>
            <th scope="col">Date and Time</th>
            <th scope="col">Patient Name</th>
            <th scope="col">Age/Gender</th>
            <th scope="col">Contact Number</th>
            <th scope="col">Address</th>
            <th scope="col">Department</th>
            <th scope="col">Doctor Name</th>
            <th scope="col">Appointment Type</th>
            <th scope="col">Visit Type</th>
            <th scope="col">Final Diagnosis</th>
            <th scope="col" *ngIf="showActions">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of filteredOPSummaryList | filter: searchText |paginate: { itemsPerPage: 10, currentPage: p  } ;let i = index  ">
            <td>{{10 *(p-1)+(i+1)}}</td>
            <td>{{data.VisitDate | date:'mediumDate'}} and {{data.BookingTime}}</td>
            <td>{{data.PatientName}}</td>
            <td>{{data.Age }}/{{data.Gender }}</td>
            <td>{{data.ContactNumber }}</td>
            <td>{{data.Address }}</td>
            <td>{{data.DepartmentName }}</td>
            <td>{{data.DoctorName }}</td>
            <td>{{data.AppointmentType }}</td>
            <td>{{data.VisitType }}</td>
            <td>{{data.FinalDiagnosis }}</td>
            <td *ngIf="showActions">
              <button *ngIf="data.ShowViewOPSummary" class="btn btn-sm btn-primary" (click)="ViewOPSummary(data)">View OP Summary</button>
              <label *ngIf="!data.ShowViewOPSummary" class="text-warning"><i class="fa fa-warning"></i>Please Add OP Summary </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> -->
    <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>

    <div style="overflow: scroll; display: none;" id="printpage">
      <table id="exportTable" class="table table-striped table-hover ">
        <thead>
          <tr class="table-tr-style">
            <th scope="col">SN</th>
            <th scope="col">Date and Time</th>
            <th scope="col">Patient Name</th>
            <th scope="col">Age/Gender</th>
            <th scope="col">Contact Number</th>
            <th scope="col">Address</th>
            <th scope="col">Department</th>
            <th scope="col">Doctor Name</th>
            <th scope="col">Appointment Type</th>
            <th scope="col">Visit Type</th>
            <th scope="col">Final Diagnosis</th>
            <th scope="col" *ngIf="showActions">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of filteredOPSummaryList | filter: searchText ;let i = index  ">
            <td>{{i+1}}</td>
            <td>{{data.VisitDate | date:'mediumDate'}} and {{data.BookingTime}}</td>
            <td>{{data.PatientName}}</td>
            <td>{{data.Age }}/{{data.Gender }}</td>
            <td>{{data.ContactNumber }}</td>
            <td>{{data.Address }}</td>
            <td>{{data.DepartmentName }}</td>
            <td>{{data.DoctorName }}</td>
            <td>{{data.AppointmentType }}</td>
            <td>{{data.VisitType }}</td>
            <td>{{data.FinalDiagnosis }}</td>
            <td *ngIf="showActions">
              <button *ngIf="data.ShowViewOPSummary" class="btn btn-sm btn-primary" (click)="ViewOPSummary(data)">View OP Summary</button>
              <label *ngIf="!data.ShowViewOPSummary" class="text-warning"><i class="fa fa-warning"></i>Please Add OP Summary </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!showOPSummaryReport" class="grid-section" >
    <h4 class="title">
      <i class="fa fa-list"></i>Order wise Report
    </h4>
    <div class="col-md-12 alert alert-default">
      <div class="row d-flex align-items-center">
        <!--<form [formGroup]="visitObj.PatientValidator">-->
        <label class="control-label" style="margin-left: 100px;">From Date:</label>
        <div class="col-md-3">
          <input type="date" name="date" [(ngModel)]="fromdate" required class="m-0" />
        </div>
        <label class="control-label" style="margin-left: 200px;"> To Date:</label>
        <div class="col-md-3">
          <input type="date" name="date" [(ngModel)]="todate" required class="m-0" />
        </div>
        <button (click)="Search()">Search</button>
        <!--<span *ngIf="!visitObj.IsDirty('VisitDate')" [hidden]="visitObj.IsValidCheck('VisitDate','required')"> Appointment Date is required</span>-->
        <!--</form>-->
      </div>
    </div>
    <div class="row d-flex align-items-center mt-2 ml-2">
      <div class="row col-md-6">
        <label class="control-label col-md-3 mt-1"> Item Name:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selItem"
                  placeholder="Select Item"
                  display-property-name="Item Name"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
                  <!-- <mat-option value="Telemedicine">Telemedicine</mat-option>
                  <mat-option value="Online Appointment">Online Appointment</mat-option> -->
          </mat-select>
        </div>
      </div>
      <div class="row col-md-6">
        <label class="control-label col-md-3 mt-1"> Department Name:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selDepartment"
                  placeholder="Select Department"
                  display-property-name="Department"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option *ngFor="let dept of deptList" [value]="dept.DepartmentId">{{dept.DepartmentName}}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-center mt-2 ml-2">
      <div class="row col-md-6">
        <label class="control-label col-md-3 mt-1"> Appointment Type:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selAppointmentType"
                  placeholder="Select Appointment Type"
                  display-property-name="AppointmentType"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option value="Telemedicine">Telemedicine</mat-option>
                  <mat-option value="Online Appointment">Online Appointment</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row col-md-6">
        <label class="control-label col-md-3 mt-1"> Visit Type:</label>
        <div class="col-md-8">
          <mat-select class="form-control"
          [(ngModel)]="selVisitType"
                  placeholder="Select Visit Type"
                  display-property-name="VisitType"
                  value-property-name=null
                  (selectionChange)="MainSearch($event)">
                  <mat-option value="All" selected>All</mat-option>
                  <mat-option value="New">New</mat-option>
                  <mat-option value="Follow-Up">Follow-Up</mat-option>
                  <!-- <mat-option *ngFor="let dept of deptList" [value]="dept.DepartmentId">{{dept.DepartmentName}}</mat-option> -->
          </mat-select>
        </div>
      </div>
    </div>
    <div class="row col-md-12 mt-2">
      <div class="col-md-10" style="margin-top: 20px; text-align: right;">
        <div *ngIf="!showDetailView" >
          <label class="mt-checkbox mt-checkbox-outline" style="color:#777;">
            <input type="radio" value="" [(ngModel)]="showDetailView"
              (change)="DetailedView()">
            <span></span>
            <label style="position: relative; top: -2px;" class="text-primary">Detailed View</label>
          </label>
        </div>
        <div *ngIf="showDetailView" >
          <label class="mt-checkbox mt-checkbox-outline" style="color:#777;">
            <input type="radio" value="" [(ngModel)]="showDetailView"
              (change)="SummaryView()">
            <span></span>
            <label style="position: relative; top: -2px;" class="text-primary">Summary View</label>
          </label>
        </div>
      </div>
      <div class="col-md-2" style="padding: 5px; text-align: right;">
      
        <div class="form-actions">
          <button class="btn btn-primary hidden-print" style="margin-right: 10px;" (click)="PrintTxnTable()" value="Print" title="print page">
            Print<i class="fa fa-print" style="margin-left:10px;"></i>
          </button>
          <button class="btn btn-primary hidden-export" (click)="ExportToExcel('exportTable')" value=""
            title="export to excel">
            Export<i class="fa fa-file-excel-o" style="margin-left:10px;"></i>
          </button>
        </div>
      </div>
    </div>
    
    <div *ngIf="!showDetailView">
      <div style="overflow: scroll;">
        <table class="table table-striped table-hover ">
          <thead>
            <tr class="table-tr-style">
              <th scope="col">SN</th>
              <th scope="col">Date</th>
              <th scope="col">Item Name</th>
              <th scope="col">Department</th>
              <th scope="col">Appointment Type</th>
              <th scope="col">Visit Type</th>
              <th scope="col">Total Quantity</th>
              <th scope="col">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of filteredOPSummaryList | filter: searchText |paginate: { itemsPerPage: 10, currentPage: p  } ;let i = index  ">
              <td>{{10 *(p-1)+(i+1)}}</td>
              <td>{{data.VisitDate | date:'mediumDate'}}</td>
              <td>{{data.ItemName}}</td>
              <td>{{data.DepartmentName }}</td>
              <td>{{data.AppointmentType }}</td>
              <td>{{data.VisitType }}</td>
              <td>{{data.TotalQuantity }}</td>
              <td>{{data.TotalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="overflow: scroll; display: none;" id="printpage">
        <table id="exportTable" class="table table-striped table-hover ">
          <thead>
            <tr class="table-tr-style">
              <th scope="col">SN</th>
              <th scope="col">Date</th>
              <th scope="col">Item Name</th>
              <th scope="col">Department</th>
              <th scope="col">Appointment Type</th>
              <th scope="col">Visit Type</th>
              <th scope="col">Total Quantity</th>
              <th scope="col">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of filteredOPSummaryList | filter: searchText ;let i = index  ">
              <td>{{i+1}}</td>
              <td>{{data.VisitDate | date:'mediumDate'}}</td>
              <td>{{data.ItemName}}</td>
              <td>{{data.DepartmentName }}</td>
              <td>{{data.AppointmentType }}</td>
              <td>{{data.VisitType }}</td>
              <td>{{data.TotalQuantity }}</td>
              <td>{{data.TotalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="showDetailView">
      <div style="overflow: scroll;">
        <table class="table table-striped table-hover ">
          <thead>
            <tr class="table-tr-style">
              <th scope="col">SN</th>
              <th scope="col">Date</th>
              <th scope="col">Item Name</th>
              <th scope="col">Department</th>
              <th scope="col">Appointment Type</th>
              <th scope="col">Visit Type</th>
              <th scope="col">Patient Name</th>
              <th scope="col">Age/Gender</th>
              <th scope="col">Total Quantity</th>
              <th scope="col">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of filteredOPSummaryList | filter: searchText |paginate: { itemsPerPage: 10, currentPage: p  } ;let i = index  ">
              <td>{{10 *(p-1)+(i+1)}}</td>
              <td>{{data.VisitDate | date:'mediumDate'}}</td>
              <td>{{data.ItemName}}</td>
              <td>{{data.DepartmentName }}</td>
              <td>{{data.AppointmentType }}</td>
              <td>{{data.VisitType }}</td>
              <td>{{data.PatientName }}</td>
              <td>{{data.Age }}/{{data.Gender }}</td>
              <td>{{data.TotalQuantity }}</td>
              <td>{{data.TotalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="overflow: scroll; display: none;" id="printpage">
        <table id="exportTable" class="table table-striped table-hover ">
          <thead>
            <tr class="table-tr-style">
              <th scope="col">SN</th>
              <th scope="col">Date</th>
              <th scope="col">Item Name</th>
              <th scope="col">Department</th>
              <th scope="col">Appointment Type</th>
              <th scope="col">Visit Type</th>
              <th scope="col">Patient Name</th>
              <th scope="col">Age/Gender</th>
              <th scope="col">Total Quantity</th>
              <th scope="col">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of filteredOPSummaryList | filter: searchText ;let i = index  ">
              <td>{{i+1}}</td>
              <td>{{data.VisitDate | date:'mediumDate'}}</td>
              <td>{{data.ItemName}}</td>
              <td>{{data.DepartmentName }}</td>
              <td>{{data.AppointmentType }}</td>
              <td>{{data.VisitType }}</td>
              <td>{{data.PatientName }}</td>
              <td>{{data.Age }}/{{data.Gender }}</td>
              <td>{{data.TotalQuantity }}</td>
              <td>{{data.TotalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> -->
    <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>
  </div>

  
  <footer></footer>
