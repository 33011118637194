import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class EventNotificationService {
  private baseUrl: string;
  constructor(
    private http: HttpClient,) {
    this.baseUrl = '/api/notification/';
  }
  getUserNotification() {
    return this.http.get<any>(this.baseUrl);
  }
  markAsReadStatus(data: any) {
    return this.http.put(this.baseUrl, data);
  }
}
