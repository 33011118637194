<div class="Main">
  <app></app>
  <button *ngIf="updateDoc" (click)="DashBoard()">Back To DashBoard </button>

  <div class="card" *ngIf="!updateDoc">
    <div class="card-header pb-2" style="border: none;">
      <h3 class="card-title">
        Hello! <label class="text-primary-green"><strong>Dr. {{docInfo.FirstName}}</strong></label>
        <span class="d-block text-small">Choose actions below to proceed.</span>
      </h3>
    </div>
    <div class="card-body pt-2">
      <div class="Book-Update" *ngIf="!updateDoc">
        <!-- <button (click) ="Book Appointment()">Book Appointment</button><br>
      <button (click) ="History()">History</button><br> -->
        <ul class="doc-actions p-0">
          <li class="d-inline mr-2"> <button (click)="DoctorRoom()" class="btn active"><i class="fa fa-users"></i> Patient in Queue</button></li>
          <!-- <li class="d-inline mr-2"> <button [routerLink]="['/DoctorRoom']" class="btn btn-primary"><i class="fa fa-wheelchair"></i>My Patient</button></li>-->
          <li class="d-inline mr-2"><button [routerLink]="['/DocUpdateProfile']" class="btn"><i class="fa fa-id-card"></i>Update Profile</button></li>
          <li class="d-inline mr-2"> <button [routerLink]="['/DailyVisit']" class="btn"><i class="fa fa-calendar-o"></i>Daily Visit List</button></li>
          <li class="d-inline mr-2"> <button [routerLink]="['/DocPatientList']" class="btn"><i class="fa fa-user-circle-o"></i>My patient List</button></li>
          <li class="d-inline mr-2"><button [routerLink]="['/DocReport']" class="btn"><i class="fa fa-list"></i>Reports</button></li>
          <li class="d-inline mr-2"><button *ngIf="updateDoc" (click)="DashBoard()">Back To DashBoard </button></li>
        </ul>
      </div>

      <div>
        <a href="https://learning.covid19.gov.np/" target="_blank" style="color:white !important; text-decoration: none !important; float: right;" class="btn btn-primary btn-learn">
          Learn more during free time..
        </a>
      </div>
    </div>
    <!--Slider-->
    <!-- <div id="myCarousel" class="carousel slide carousel-doc-dash" data-ride="carousel" data-interval="5000">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="mask flex-center">
                  <div class="container">
                    <div class="row align-items-center">

                      <div class="col-md-12 col-12 order-md-2 order-1"><img src="../assets/img/slider2.jpg" class="mx-auto" alt="slide"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="mask flex-center">
                  <div class="container">
                    <div class="row align-items-center">

                      <div class="col-md-12 col-12 order-md-2 order-1"><img src="../assets/img/slider1.jpg" class="mx-auto" alt="slide"></div>


                    </div>
                  </div>
                </div>
              </div>

            </div>
            <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a> <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a>

           slide end-->
    <!-- <div class="s-container" style="margin-bottom:0">
            <img class='photo-slider'  src="./assets/img/slider1.jpg" alt="" />
            <img class='photo-slider'  src="./assets/img/slider2.jpg" alt="" />
            <img class='photo-slider'  src="./assets/img/slider1.jpg" alt="" />
            <img class='photo-slider'  src="./assets/img/slider2.jpg" alt="" />

          </div> -->
  </div>
  <div *ngIf="updateDoc">
    <router-outlet></router-outlet>
  </div>
</div>

<footer></footer>
