import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { Global } from "../../app.global";
import { DoctorService } from "../../services/doctor/doctor.service";
import { Doctor } from "../../models/doctor/doctor.model";
import { HttpClient, HttpRequest, HttpEventType } from "@angular/common/http";
import { AlertService } from "../../services/alert.service";
import * as moment from "moment/moment";
import { AuthenticationService } from "../../services/authentication.service";
import { Departement } from "../../models/Department/app.department.model";
import { DatePipe } from "@angular/common";
import { FormBuilder, FormGroup, NgForm } from "@angular/forms";
import { NotificationService } from "../../services/notification.service";
import { HospitalService } from "../../services/hospital/hospital.service";
import { Hospital } from "../../models/hospital/hospital.model";
import { HospitalDoctorMap } from "../../models/HospitalDoctorMap/app.hospitaldoctormap.model";
import { Qualification } from "../../models/Qualification/qualification.model";
import { MatDialog } from "@angular/material/dialog";
import { PopUpDialogComponent } from "../../components/common/popup/pop-up.component";
@Component({
  selector: "doctor-update-profile",
  templateUrl: "./app.doctor_updateprofile.html",
  styleUrls: ["./app.doctor_updateprofile.css"],
})
export class DoctorUpdateProfileComponent implements OnInit {
  public updateDoc: boolean;
  public today = new Date();
  public docInfo: Doctor = new Doctor();
  public departmentId: string;
  public tempvalue: string = "";
  public selDepartment: any;
  public selConsultation: any;
  public dept: Departement = new Departement();
  public hosp: Hospital = new Hospital();
  public deptList: Array<any> = new Array<any>();
  public docList: Array<Doctor> = new Array<Doctor>();
  public progress: number;
  public message: string;
  public hospitalDoctorDetail: Array<any>;
  public qualificationDetail: Array<any>;

  mapId: string;
  ConsultationType = [
    { id: 1, name: "Online Appointment" },
    { id: 2, name: "Telemedicine" },
  ];
  loading = false;
  public doc: any;
  public HospitalName: any;
  public docName: string;
  public DepartmentName: any;
  public HospitalId: string;
  public DepartmentId: string;
  public Charge: any;
  public Consultation: string;
  public token: any;
  public hospitalList: Array<any>;
  public DeptHostList: any;
  doctorUpdateForm: FormGroup;
  public selHospital: any;
  public hospitalId: string;
  public rowCount: number = 0;
  public index: number = 0;
  public addDept: boolean = false;
  public exceptionOccur: boolean = false;
  public intraExceptionOccur: boolean = false;
  public editModal: boolean = false;
  public editQualf: boolean = false;
  public hosdocMap: HospitalDoctorMap = new HospitalDoctorMap();
  public Qualification: Qualification = new Qualification();
  public hospdocMap: Array<HospitalDoctorMap> = new Array<HospitalDoctorMap>();
  public qualification: Array<Qualification> = new Array<Qualification>();
  isDocEditor = false;
  isDocScheduleEditor = false;
  public docId: any;
  public hospitalIdentifier: string;
  public changesSavedCallback: () => void;
  public changesCancelledCallback: () => void;
  newQualification: Qualification = {
    QualificationId: "",
    Designation: "",
    Education: "",
    PastAffiliation: "",
    Experience: "",
    Membership: "",
    DoctorId: "",
  };

  newHospitalMap: HospitalDoctorMap = {
    HospitalDoctorMapId: "",
    DoctorId: "",
    HospitalId: "",
    DepartmentId: "",
    Charge: 0,
    ConsultationType: "",
    HospitalName: "",
    DepartmentName: "",
    DoctorName: "",
    AdminId: "",
  };

  public isConfirmed: boolean;
  allowEdit: boolean = false;
  constructor(
    public global: Global,
    public docservice: DoctorService,
    public alertService: AlertService,
    public router: Router,
    public httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    public http: HttpClient,
    private notifyService: NotificationService,
    public hospitalService: HospitalService,
    public dialog: MatDialog,
    public changeDetector: ChangeDetectorRef
  ) {
    this.token = this.authenticationService.currentUserValue;
    if (this.token.UserType !== "Admin") {
      this.allowEdit = false;
      this.GetDepartmentList();
    }
    if (this.token.UserType == "Admin") {
      this.allowEdit = true;
    }
    this.GetHospitalList();
  }


  ngOnInit() {
    this.newHospitalMap = {
      HospitalDoctorMapId: "",
      DoctorId: "",
      HospitalId: "",
      DepartmentId: "",
      Charge: 0,
      ConsultationType: "",
      HospitalName: "",
      DepartmentName: "",
      DoctorName: "",
      AdminId: "",
    };
    this.hospdocMap.push(this.newHospitalMap);
    this.newQualification = {
      QualificationId: "",
      Designation: "",
      Education: "",
      PastAffiliation: "",
      Experience: "",
      Membership: "",
      DoctorId: "",
    };
    this.qualification.push(this.newQualification);
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.changeDetector.detectChanges();
  }

  GetHospitalList() {
    this.hospitalService.getHospitalList().subscribe(
      (res) => {
        this.hospitalList = res;
      },
      (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }
  GetDepartmentList() {
    this.docservice.getDepartmentList().subscribe(
      (res: any) => {
        if (res && res.IsSuccess && res.Results) {
          this.deptList = res?.Results;
          this.GetDoctor();
          //this.DeptHostList= concat(this.deptList);
        }
      },
      (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }
  hospitalChanged() {
    this.hospitalId = this.selHospital;
  }
  departmentChanged() {
    this.departmentId = this.selDepartment;
  }
  consulationChanged() {
    this.Consultation = this.selConsultation;
  }

  GetDoctorList() {
    this.docservice.getDoctorList().subscribe(
      (res: any) => {
        this.docList = res?.Results;
      },
      (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }
  GetDoctor() {
    if (this.token.UserType !== "Admin") {
      this.docId = this.token.DoctorIdentifier;
    }
    this.docservice.getDoctor(this.docId).subscribe(
      (res) => {
        this.docInfo = res;
        if (this.docInfo.DateOfBirth != null) {
          this.docInfo.DateOfBirth = moment(res.DateOfBirth).format(
            "MM-DD-YYYY"
          );
        } else {
          this.docInfo.DateOfBirth = moment().format("MM-DD-YYYY");
        }
        this.docInfo.FileName = null;
        this.tempvalue = this.docInfo.FilePath;
        this.docInfo.FilePath = null;
        this.departmentChanged();
      },
      (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }
  UpdateDocProfile(form: NgForm) {
    var validation = this.checkInputValidity();
    if (validation.failed) {
      this.notifyService.showError(validation.errorMessages, "Invalid Input");
      return;
    }

    this.exceptionOccur = false;
    for (var i = 0; i < this.hospdocMap.length; i++) {
      for (var x = 0; x < this.docInfo.HospitalDepartmentMap.length; x++) {
        // cmt for wecare
        // if ((this.hospdocMap[i].HospitalId == this.docInfo.HospitalDepartmentMap[x].HospitalId) && (this.hospdocMap[i].DepartmentId == this.docInfo.HospitalDepartmentMap[x].DepartmentId)) {
        //   this.exceptionOccur = true;
        // }
      }
    }
    var depcount = 0;
    if (this.hospdocMap.length > 1) {
      for (var z = 0; z < this.hospdocMap.length; z++) {
        for (var y = 0; y < this.hospdocMap.length; y++) {
          if (
            this.hospdocMap[z].HospitalId == this.hospdocMap[y].HospitalId &&
            this.hospdocMap[z].DepartmentId == this.hospdocMap[y].DepartmentId
          ) {
            depcount++;
          }
        }
      }
    } else {
      this.intraExceptionOccur = false;
    }

    if (depcount <= this.hospdocMap.length) {
      this.intraExceptionOccur = false;
    }
    if (this.exceptionOccur == false && this.intraExceptionOccur == false) {
      // if (this.docInfo.FilePath == null) {
      //    if (this.tempvalue != null) {
      //      this.tempvalue = this.tempvalue.slice(10);
      //    }
      //   this.docInfo.FilePath = this.tempvalue;
      // }
      this.docInfo.DepartmentId = this.departmentId;
      this.docInfo.HospitalDoctorMap = JSON.stringify(this.hospdocMap);
      this.docInfo.Qualification = JSON.stringify(this.qualification);

      const momentDate = new Date(this.docInfo.DateOfBirth);
      this.docInfo.DateOfBirth = moment(momentDate).format("YYYY/MM/DD");
      if (this.token.UserType === "Admin") {
        this.docInfo.AdminId = this.token.unique_name;
      } else {
        this.docInfo.AdminId = "";
      }
      this.docInfo.HospitalIdentifier = this.token.hospitalIdentifier;
      this.docservice.updateDoctor(this.docInfo).subscribe(
        (data) => {
          this.alertService.success("Updated successful", true);

          this.updateDoc = false;
          if (
            this.token.UserType == "Admin" &&
            this.isDocScheduleEditor == false
          ) {
            this.router.navigate(["/doclist"]);
            if (this.changesSavedCallback) {
              this.changesSavedCallback();
            }
          } else if (
            this.token.UserType == "Admin" &&
            this.isDocScheduleEditor == true
          ) {
            this.router.navigate(["/scheduling"]);
            if (this.changesSavedCallback) {
              this.changesSavedCallback();
            }
          } else {
            this.router.navigate(["/DocDashboard"]);
          }
        },
        (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
    } else {
      this.notifyService.showError(
        "Error",
        " Same Department with Same Hospital can't be taken"
      );
    }
  }

  updatedoctor() {
    this.exceptionOccur = false;
    var depcount = 0;
    if (this.hospdocMap.length > 1) {
      for (var z = 0; z < this.hospdocMap.length; z++) {
        for (var y = 0; y < this.hospdocMap.length; y++) {
          if (
            this.hospdocMap[z].HospitalId == this.hospdocMap[y].HospitalId &&
            this.hospdocMap[z].DepartmentId == this.hospdocMap[y].DepartmentId
          ) {
            depcount++;
          }
        }
      }
    } else {
      this.intraExceptionOccur = false;
    }

    if (depcount <= this.hospdocMap.length) {
      this.intraExceptionOccur = false;
    }

    var validation = this.checkInputValidity();
    if (validation.failed) {
      this.notifyService.showError(validation.errorMessages, "Invalid Input");
      return;
    }

    if (this.exceptionOccur == false && this.intraExceptionOccur == false) {
      if (this.docInfo.FilePath == null) {
        if (this.tempvalue != null) {
          this.tempvalue = this.tempvalue.slice(10);
        }
        this.docInfo.FilePath = this.tempvalue;
      }
      this.docInfo.DepartmentId = this.departmentId;
      this.docInfo.HospitalDoctorMap = JSON.stringify(this.hospdocMap);
      this.docInfo.Qualification = JSON.stringify(this.qualification);

      const momentDate = new Date(this.docInfo.DateOfBirth);
      this.docInfo.DateOfBirth = moment(momentDate).format("YYYY/MM/DD");
      if (this.token.UserType === "Admin") {
        this.docInfo.AdminId = this.token.unique_name;
      } else {
        this.docInfo.AdminId = "";
      }
      this.docInfo.HospitalIdentifier = this.hospitalIdentifier;
      this.docservice.updateDoctor(this.docInfo).subscribe(
        (data) => {
          this.notifyService.showSuccess(
            "Successful",
            "Doctor Details has been Successfully Updated."
          );

          if (
            this.token.UserType == "Admin" &&
            this.isDocScheduleEditor == false
          ) {
            this.router.navigate(["/doclist"]);
            if (this.changesSavedCallback) {
              this.changesSavedCallback();
            }
          } else if (
            this.token.UserType == "Admin" &&
            this.isDocScheduleEditor == true
          ) {
            this.router.navigate(["/scheduling"]);
            if (this.changesSavedCallback) {
              this.changesSavedCallback();
            }
          } else {
            this.router.navigate(["/DocDashboard"]);
          }
          this.hospdocMap = new Array<HospitalDoctorMap>();
          this.newHospitalMap = {
            HospitalDoctorMapId: "",
            DoctorId: "",
            HospitalId: "",
            DepartmentId: "",
            Charge: 0,
            ConsultationType: "",
            HospitalName: "",
            DepartmentName: "",
            DoctorName: "",
            AdminId: "",
          };
          this.hospdocMap.push(this.newHospitalMap);
          this.qualification = new Array<Qualification>();
          this.newQualification = {
            QualificationId: "",
            Designation: "",
            Education: "",
            PastAffiliation: "",
            Experience: "",
            Membership: "",
            DoctorId: "",
          };
          this.qualification.push(this.newQualification);
          this.updateDoc = false;
        },
        (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
    } else {
      this.notifyService.showError(
        "Error",
        " Same Department with Same Hospital can't be taken"
      );
    }
  }

  private checkInputValidity() {
    var validation = { failed: false, errorMessages: [] };

    var namePattern = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
    if (namePattern.test(this.docInfo.FirstName) == false) {
      validation.errorMessages.push("\nFirstName is not valid");
      validation.failed = true;
    }

    if (namePattern.test(this.docInfo.LastName) == false) {
      validation.errorMessages.push("\nLastName is not valid");
      validation.failed = true;
    }

    if (this.docInfo.MiddleName) {
      if (namePattern.test(this.docInfo.MiddleName) == false) {
        validation.errorMessages.push("\nMiddleName is not valid");
        validation.failed = true;
      }
    }

    if (this.docInfo.MailingAddress) {
      var emailPattern =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (emailPattern.test(this.docInfo.MailingAddress) == false) {
        validation.errorMessages.push("\nEmail is not valid");
        validation.failed = true;
      }
    }
    var nmcPattern = /\d+[0-9]*$/;
    if (nmcPattern.test(this.docInfo.NMC) == false) {
      validation.errorMessages.push("\nNMC number is required.");
      validation.failed = true;
    }
    return validation;
  }

  addRow(index) {
    if (this.docInfo.HospitalDepartmentMap.length == 0) {
      this.addDept = true;
    }
    for (var i = 0; i < this.docInfo.HospitalDepartmentMap.length; i++) {
      // cmt for wecare
      //  if ((this.newHospitalMap.HospitalId == this.docInfo.HospitalDepartmentMap[i].HospitalId) && (this.newHospitalMap.DepartmentId == this.docInfo.HospitalDepartmentMap[i].DepartmentId)) {

      //   this.notifyService.showError("Error", " Same Department with Same Hospital can't be taken");
      //   this.newHospitalMap.HospitalId = null;
      //   this.newHospitalMap.DepartmentId = null;
      //   this.newHospitalMap.Charge = null;
      //   this.newHospitalMap.ConsultationType = null;
      //   return true;
      // }
      // else {
      this.addDept = true;
      // }
    }
    if (this.addDept) {
      var count = 0;
      this.intraExceptionOccur = false;
      this.newHospitalMap = {
        HospitalDoctorMapId: "",
        DoctorId: "",
        HospitalId: "",
        DepartmentId: "",
        Charge: 0,
        ConsultationType: "",
        HospitalName: "",
        DepartmentName: "",
        DoctorName: "",
        AdminId: "",
      };
      if (this.hospdocMap.length > 1) {
        for (var z = 0; z < this.hospdocMap.length; z++) {
          for (var y = 0; y < this.hospdocMap.length; y++) {
            if (
              this.hospdocMap[z].HospitalId == this.hospdocMap[y].HospitalId &&
              this.hospdocMap[z].DepartmentId == this.hospdocMap[y].DepartmentId
            ) {
              count++;
            }
          }
        }
      } else {
        this.intraExceptionOccur = false;
      }
      if (count <= this.hospdocMap.length) {
        this.intraExceptionOccur = false;
        this.hospdocMap.push(this.newHospitalMap);
        console.log(this.hospdocMap);
        return true;
      } else {
        this.intraExceptionOccur = true;
        this.notifyService.showError(
          "Error",
          " Same Department with Same Hospital can't be taken"
        );
        this.newHospitalMap.HospitalId = null;
        this.newHospitalMap.DepartmentId = null;
        this.newHospitalMap.Charge = null;
        this.newHospitalMap.ConsultationType = null;
      }
    }
  }

  deleteRow(index) {
    if (this.hospdocMap.length == 1) {
      return false;
    } else {
      this.hospdocMap.splice(index, 1);
      return true;
    }
  }
  deleteHospitalMap(HospitalDoctorMapId) {
    this.docservice.deleteHospitalMap(HospitalDoctorMapId).subscribe(
      (res) => this.SuccessDelete(res),
      (error) => this.Error(error)
    );
  }
  SuccessDelete(res) {
    this.GetDoctor();
    this.notifyService.showSuccess("Success", " Deleted Successfully ");
  }
  Error(res) {
    this.notifyService.showError(
      "Schedules are available",
      "This can't be delete"
    );
  }
  editHospitalMap(hosdoc) {
    this.editModal = true;
    this.mapId = hosdoc.HospitalDoctorMapId;
    this.hospitalDoctorDetail = this.docInfo.HospitalDepartmentMap.filter(
      (x) => x.HospitalDoctorMapId == this.mapId
    );
    this.selHospital = this.HospitalId =
      this.hospitalDoctorDetail[0].HospitalId;
    this.selDepartment = this.DepartmentId =
      this.hospitalDoctorDetail[0].DepartmentId;
    this.HospitalName = this.hospitalDoctorDetail[0].HospitalName;
    this.DepartmentName = this.hospitalDoctorDetail[0].DepartmentName;
    this.Charge = this.hospitalDoctorDetail[0].Charge;
    this.selConsultation = this.Consultation =
      this.hospitalDoctorDetail[0].ConsultationType;
    let existingDepartment = this.deptList.find(
      (x) => x.DepartmentId == this.DepartmentId
    );
    this.hospitalDepartmentMap(this.HospitalId);
    this.deptList[0] = existingDepartment;
  }

  hide() {
    this.editModal = false;
    this.editQualf = false;
  }
  public Update() {
    this.hosdocMap.HospitalId =
      this.selHospital != undefined ? this.selHospital : this.HospitalId;
    this.hosdocMap.DepartmentId =
      this.selDepartment != undefined ? this.selDepartment : this.DepartmentId;
    this.hosdocMap.Charge = this.Charge;
    this.hosdocMap.ConsultationType = this.Consultation;
    this.hosdocMap.HospitalDoctorMapId = this.mapId;
    if (this.token.UserType === "Admin") {
      this.hosdocMap.AdminId = this.token.unique_name;
    } else {
      this.hosdocMap.AdminId = "";
    }
    this.docservice.updateHosDocMap(this.hosdocMap).subscribe(
      (res) => this.SuccessHospitalDoctorMap(res),
      (res) => this.Error(res)
    );
  }
  SuccessHospitalDoctorMap(res) {
    this.editModal = false;
    this.GetDoctor();
    this.notifyService.showSuccess("", " Updated Successfully");
  }
  editQualification(QualificationId) {
    this.editQualf = true;
    this.mapId = QualificationId;
    this.qualificationDetail = this.docInfo.DoctorQualification.filter(
      (x) => x.QualificationId == this.mapId
    );
  }
  UpdateQualification() {
    this.Qualification.Designation = this.qualificationDetail[0].Designation;
    this.Qualification.Education = this.qualificationDetail[0].Education;
    this.Qualification.PastAffiliation =
      this.qualificationDetail[0].PastAffiliation;
    this.Qualification.Experience = this.qualificationDetail[0].Experience;
    this.Qualification.Membership = this.qualificationDetail[0].Membership;
    this.Qualification.QualificationId = this.mapId;
    this.docservice.updateQualification(this.Qualification).subscribe(
      (res) => this.SuccessQualification(res),
      (res) => this.Error(res)
    );
  }
  SuccessQualification(res) {
    this.editQualf = false;
    this.notifyService.showSuccess("", "Successfully Updated");
  }

  upload(files) {
    if (files.length === 0) return;

    const formData = new FormData();

    for (let file of files) {
      if (file.size * 0.00000095367432 > 5) {
        this.notifyService.showWarning(
          "Error",
          "File size should be less than 5 MB."
        );
        return;
      }
      let fileExtension = file.name.split(".").pop().toLowerCase();
      var acceptedFileFormat = ["jpg", "png", "doc", "docx", "pdf"];
      if (!acceptedFileFormat.includes(fileExtension)) {
        this.notifyService.showWarning("Unsupported file format", "Error");
        return;
      }
      formData.append(file.name, file);
      const uploadReq = new HttpRequest(
        "POST",
        `api/Doctor/UploadFile/`,
        formData,
        {
          reportProgress: true,
        }
      );

      this.http.request(uploadReq).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round((100 * event.loaded) / event.total);
        else if (event.type === HttpEventType.Response)
          this.message = event.body.toString();
      });
    }
  }
  addRowQualification(index) {
    this.newQualification = {
      QualificationId: "",
      Designation: "",
      Education: "",
      PastAffiliation: "",
      Experience: "",
      Membership: "",
      DoctorId: "",
    };
    this.qualification.push(this.newQualification);
    console.log(this.qualification);
    return true;
  }
  deleteRowQualification(index) {
    this.intraExceptionOccur = false;
    if (this.qualification.length == 1) {
      return false;
    } else {
      this.qualification.splice(index, 1);
      return true;
    }
  }
  editDoctor(doc: Doctor, isDoc: boolean, hospital: string) {
    this.isDocEditor = isDoc;
    this.docId = doc.DoctorId;
    this.docName = doc.DoctorName;
    this.hospitalIdentifier = hospital;
    this.GetDepartmentList();
    return this.docId;
  }
  editDoctorModal(
    doc: HospitalDoctorMap,
    isDoc: boolean,
    isDocSchedule: boolean,
    hospital: string
  ) {
    this.isDocEditor = isDoc;
    this.isDocScheduleEditor = isDocSchedule;
    this.docId = doc.DoctorId;
    this.docName = doc.DoctorName;
    this.hospitalIdentifier = hospital;
    this.GetDepartmentList();
    return this.docId;
  }
  cancel() {
    if (this.changesCancelledCallback) {
      this.hospdocMap.length = 1;
      this.hospdocMap.forEach((x) => {
        x.HospitalId = "";
        x.DepartmentId = "";
        x.Charge = 0;
        x.ConsultationType = "";
      });
      this.changesCancelledCallback();
    }
  }

  Removepicture() {
    this.tempvalue = "";
    this.docInfo.FilePath = this.tempvalue;
    this.message = "Profile removed";
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(PopUpDialogComponent, {
      data: { msg: "updateProfile" },
      width: "250px",
      height: "300",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.isConfirmed = result;
      if (this.isConfirmed === true) {
        this.UpdateDocProfile(data);
      }
    });
  }
  CancelUpdate() {
    this.router.navigate(["/DocDashboard"]);
  }
  hospitalDepartmentMap(hospitalId) {
    var selectedHospitalDepartmentMap =
      this.docInfo.HospitalDepartmentMap.filter(
        (x) => x.HospitalId == hospitalId
      );
    if (selectedHospitalDepartmentMap.length > 0) {
      selectedHospitalDepartmentMap.forEach((x) => {
        let valueIndex = this.deptList.filter(
          (y) => y.DepartmentId != x.DepartmentId
        );
        this.deptList = valueIndex;
      });
    } else this.GetDepartmentList();
  }
}
