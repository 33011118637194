import { Component } from "@angular/core";
import { LoaderService } from "../../../services/loader-interceptor/loader.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  constructor(private _loader: LoaderService) {

  }

  get isLoading() {
    return this._loader.isLoading;
  }
}
