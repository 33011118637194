import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Login } from '../../models/user/user.model';
import { LoginEndpoint } from './login-endpoint.service';

@Injectable()
export class LoginService {
  constructor(private router: Router, private http: HttpClient, private loginEndPoint: LoginEndpoint) {
  }
 

  loginTele(login: Login) {
    return this.loginEndPoint.loginTeleEndpoint<Login>(login);
  }
 
}
