<app></app>
<div class="bg-back">
  <button (click)="BackDashBoard()"
          class="back-btn-doc-room">
    Back To DashBoard
  </button>
</div>
<div class="patient-highlight mb-2">
  <div class="title">
    <h5>
      <i class="fa fa-comments"
         aria-hidden="true">
      </i>
      <strong>
        Put your queries here.
      </strong>
    </h5>
  </div>

  <form [formGroup]="ChatForm"
        (ngSubmit)="SendChatMsg()">
    <div class="chat-messages">
      <div class="alert alert-default p-1"
           style="background: #f9f9f9; border: 1px solid #f9f9f9">
        <p class="text-center">
          <i class="fa fa-info"
             aria-hidden="true"></i>Start your conversiation
        </p>
      </div>

      <div class="message-box-holder">
        <div *ngFor="let msg of ChatMessages">
          <div *ngIf="msg.Name !='Me'"
               class="message-by-doc">
            <span>{{ msg.Message }}</span>
          </div>
          <div *ngIf="msg.Name =='Me'"
               class="message-by-me">
            <span>{{ msg.Message }}</span>
          </div>
        </div>
      </div>
      <div class="chat-input-holder message-box">

        <textarea class="chat-input"
                  placeholder="Type your message here.."
                  formControlName="chatMessage"
                  (keyup)="onChatEnter($event)">
          </textarea>

        <div *ngIf="hasError('required','chatMessage')"
             style="color: red; font-size: 12px; margin-bottom: 7px">
          <i class="fa fa-info-circle"
             aria-hidden="true">
          </i>
          Chat message is required
        </div>
        <button class="message-send btn-primary"> Send
        </button>
      </div>
    </div>
  </form>
</div>
<footer></footer>
