import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { PatientService } from '../../services/patient/patient.service';
import { AuthenticationService } from '../../services/authentication.service';
import { HospitalService } from '../../services/hospital/hospital.service';
import { Global } from '../../app.global';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { PatientMedicalRecord } from '../../models/patient/Pat-medicalRecord.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  templateUrl: './app.patient_medical_record.html'
})
export class PatientMedicalRecordComponent {
  public config: any;
  public token: any;
  public hospitalId: any;
  p: number = 1;
  public viewMedicalRecord: boolean = false;
  public TestReportType = [
    { id: 1, type: "OPD Prescription" },
    { id: 2, type: "Lab Test Report" },
    { id: 3, type: "X-Ray Report" },
    { id: 4, type: "USG Report" },
    { id: 5, type: "ECG Report" },
    { id: 6, type: "CT-Scan Report" },
    { id: 7, type: "MRI Report" },
    { id: 8, type: "IP Discharge Summary" },
    { id: 9, type: "Others" }
  ];
  public progress: number;
  public message: string;
  public patMedicalRecord: PatientMedicalRecord = new PatientMedicalRecord();
  public medicalRecordList: Array<PatientMedicalRecord> = new Array<PatientMedicalRecord>();
  public showView: boolean = false;
  public title: string;
  public filePath: string;
  public fileExtension: string;
  public Filetype: string;
  public fileName: string;
  public collection = { count: 0, data: [] };
  public patInfo: any;
  public patFamilyList: any;
  public patData = [];
  public selectedPatId: any; 
  public safeUrl : any;

  constructor(public routing: Router, private route: ActivatedRoute, public patservice: PatientService,
    public authenticationService: AuthenticationService, public http: HttpClient,
    public hospitalService: HospitalService, public global: Global, private notifyService: NotificationService, private sanitizer: DomSanitizer) {
    this.token = this.authenticationService.currentUserValue;
    this.hospitalId = this.route.snapshot.queryParamMap.get('id');
    this.GetPatientName();
    this.GetFamilyDetail();
  }
  pageChanged(event) {
    this.config.currentPage = event;

  }

  public BackDashBoard() {
    //this.updatePat = false;
    this.routing.navigate(['/PatDashboard'], { queryParams: { id: this.hospitalId } });
  }

  UploadMedicalRecord() {
    this.viewMedicalRecord = false;
    this.patMedicalRecord = new PatientMedicalRecord();
    this.message = null;
    this.progress = null;
  }

  ViewMedicalRecord() {
    this.viewMedicalRecord = true;
    this.getPatMedicalRecords();
  }

  upload(patFile) {
    if (patFile.length === 0)
      return;

    const formData = new FormData();

    for (let file of patFile) {
      // This limit the file size to 10 Mb.
      if (file.size * 0.00000095367432 > 10) {
        this.notifyService.showWarning("Error", "File size should be less than 10 MB.");
        return;
      }
      let fileExtension = file.name.split('.').pop().toLowerCase();
      var acceptedFileFormat = ["jpg", "png", "doc", "docx", "pdf"];
      if (!acceptedFileFormat.includes(fileExtension)) {
        this.notifyService.showWarning("Unsupported file format", "Error");
        return;
      }
      formData.append(file.name, file);
    }
    const uploadReq = new HttpRequest('POST', `api/Patient/UploadFile/`, formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response)
        this.message = event.body.toString();
    });
  }

  UploadRecord(form: NgForm) {
    if (form.invalid) {
      this.notifyService.showError("Sorry", "Please fill all required fields");
      return;
    }
    this.patMedicalRecord.PatientId = this.token.PatientIdentifier;
    this.patMedicalRecord.UploadedBy = this.token.PatientIdentifier;

    if (this.selectedPatId == this.patInfo.PatientId) {
      this.patMedicalRecord.PatientName = this.patInfo.FirstName;
    }
    else {
      let selectedPatient = this.patFamilyList.find(x => x.FamilyMemberId == this.selectedPatId);
      this.patMedicalRecord.PatientName = selectedPatient.FirstName;
      this.patMedicalRecord.FamilyMemberId = selectedPatient.FamilyMemberId;
    }
    if (this.token.UserType === "Patient") {
      this.patMedicalRecord.PatientId = this.token.PatientIdentifier;
    }
    else {
      this.patMedicalRecord.PatientId = "";
    }
    this.patservice.addPatMedicalRecord(this.patMedicalRecord).subscribe(res => this.Success(res),
      res => this.ErrorAdd(res));
  }
  Success(res) {
    this.patMedicalRecord = new PatientMedicalRecord();
    this.message = null;
    this.progress = null;
    this.notifyService.showSuccess('', 'Record Added Successfully!');
  }
  ErrorAdd(res) {
    this.notifyService.showError("Error", "File not selected")
  }

  getPatMedicalRecords() {
    this.patservice.getPatMedicalRecords(this.token.PatientIdentifier).subscribe(res => this.SuccessGetMedicalRecords(res),
      res => this.Error(res));
  }

  SuccessGetMedicalRecords(res) {
    this.medicalRecordList = res;
    for (var i = 0; i < this.medicalRecordList.length; i++) {
      if (this.medicalRecordList[i].UploadedBy == this.token.PatientIdentifier) {
        this.medicalRecordList[i].UploadedBy = "Self";
      }
      else {
        this.medicalRecordList[i].UploadedBy = "Hospital";
      }
    }
    this.collection = { count: this.medicalRecordList.length, data: [] }
    for (var i = 0; i < this.collection.count; i++) {
      this.collection.data.push(
        {
          id: i + 1,
          MedicalRecordId: this.medicalRecordList[i].MedicalRecordId,
          PatientId: this.medicalRecordList[i].PatientId,
          PatientName: this.medicalRecordList[i].PatientName,
          TestReportType: this.medicalRecordList[i].TestReportType,
          FileName: this.medicalRecordList[i].FileName,
          FilePath: this.medicalRecordList[i].FilePath,
          FileExtension: this.medicalRecordList[i].FileExtension,
          UploadedBy: this.medicalRecordList[i].UploadedBy
        });
    }

  }
  Error(res) {
    this.notifyService.showError("Error", "Internal Error")
  }

  ViewFile(filePath, fileExt, fileName) {
    this.showView = true;
    this.title = fileName.split('.').slice(0, -1).join('.');
    this.fileName = fileName;
    this.filePath = filePath;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.filePath)
    this.fileExtension = fileExt;
    if (
      this.fileExtension == "jpeg" ||
      this.fileExtension == "JPEG" ||
      this.fileExtension == "png" ||
      this.fileExtension == "PNG" ||
      this.fileExtension == "JPG" ||
      this.fileExtension == "jpg"
    ) {
      this.Filetype = "image";
    }
    if (this.fileExtension == "pdf" || this.fileExtension == "PDF") {
      this.Filetype = "pdf";
    }
    if (
      this.fileExtension == "docx" ||
      this.fileExtension == "DOCX" ||
      this.fileExtension == "DOC" ||
      this.fileExtension == "doc"
    ) {
      this.Filetype = "docfile";
    }
  }

  hide() {
    this.showView = false;
  }

  GetPatientName() {
    //var id = this.token.PatientIdentifier;
    this.patservice.getPatient(this.token.PatientIdentifier,this.token.UserType).subscribe(
      (res) => {
        this.patInfo = res;
        this.patData.push(this.patInfo);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  GetFamilyDetail() {
    if (this.token.PatientIdentifier != null) {
      // this.visitObj.HospitalId = this.hospitalId;  
      let patId = this.token.PatientIdentifier;
      this.patservice.getFamilyList(patId)
        .subscribe(res => this.SuccessgetFamily(res),
          res => this.Error(res));
    }
  }

  SuccessgetFamily(res) {
    this.patFamilyList = res;
  }

  DeleteRecord(recordId) {
    this.patservice.deletePatMedicalRecord(recordId).subscribe(res => this.SuccessDelete(res),
      res => this.ErrorDelete(res));
  }

  SuccessDelete(res) {
    this.getPatMedicalRecords();
    this.notifyService.showSuccess('', 'Successfully Deleted!');
  }

  ErrorDelete(res) {
    this.notifyService.showError("Error", "Internal Error");
  }

}

