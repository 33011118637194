import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/ChatNotification/chat-notification.service';



@Component({
  //selector: 'admin-root',
  templateUrl: './admin.component.html'
})
export class AdminComponent {
  constructor(public routing: Router,private _chatService: ChatService) {
    this._chatService.Connect();
  }
  // Slick Slider this is added new

  slides = [
    { img: "./assets/img/Slider11.jpg" },
    { img: "./assets/img/Slider22.jpg" },
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    cssEase: "linear",
    draggable: false,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
  };
  // End SLick slider
}


