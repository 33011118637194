import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core'
import { Visit } from 'src/app/models/visit/visit.model'
import { Global } from 'src/app/app.global';
import { DoctorService } from 'src/app/services/doctor/doctor.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AlertService } from 'src/app/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import * as moment from 'moment';
import { SchedulingService } from 'src/app/services/scheduling/scheduling.service';
import { HospitalService } from 'src/app/services/hospital/hospital.service';
import { Scheduling, SchedulingList } from 'src/app/models/scheduling/scheduling.model';
import { Doctor } from '../../app.model';
import { Departement } from '../../models/Department/app.department.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
@Component({
  templateUrl: './app.doctor_reschedule.html',
  encapsulation: ViewEncapsulation.None,
})

export class RescheduleComponent {
  public visitObj: Visit = new Visit();
  public appointmentTime: Array<any> = new Array<any>();
  public showRescheduleBtn: boolean = false;
  public todayDate = moment().format('YYYY-MM-DD');
  public showIntervalButton: boolean = false;
  public showIntervalSlot: boolean = false;
  scheduleIntervalList: Array<any> = new Array<any>();
  public doctorList: any;
  public filteredDoctorList: Array<Doctor> = [];
  public deptList: any;
  public filterdeptList: Array<any> = new Array<any>();
  public docHospitalList: Array<any>;
  public filteredDepartmentList: Array<any> = new Array<any>();
  public count: number = 0;
  public deptId: any;
  filteredHospitalDoctorMapping: Array<any> = new Array<any>();
  filteredSchedulingList: Array<any> = new Array<any>();
  HospitalDoctorMapping: Array<any> = new Array<any>();
  SchedulingList: Array<SchedulingList> = new Array<SchedulingList>();
  public selDepartment: any = "";
  public selAppointmentType: any;
  public selHospitalId: any;
  public selDoctor: any = "";
  filteredDepartmentTemp: Array<any> = [];
  isDoctorAvailable: boolean = false;
  availableDate: Date | Date;
  isSchedulingAvailable: boolean = true;
  oldSchedulingId: any;
  HospitalDoctorMapId: any;
  scheduleIntervalId: any = 0;
  ;
  // public docId: any;
  constructor(public global: Global, public docService: DoctorService, public patservice: PatientService, @Inject(MAT_DIALOG_DATA) public data: any, public alertService: AlertService, public route: ActivatedRoute,
    public router: Router, public schedulingService: SchedulingService, public httpClient: HttpClient, private authenticationService: AuthenticationService,
    public http: HttpClient, private datepipe: DatePipe, private formBuilder: FormBuilder, private notifyService: NotificationService, public hospitalService: HospitalService
  ) {
    this.selAppointmentType = this.data.appointmentType;
    this.selHospitalId = this.data.hosId;
    this.selDepartment = this.data.deptId;
    this.selDoctor = this.data.docId;
    this.visitObj.VisitId = this.data.visitId;
    this.oldSchedulingId = this.data.schedullingId;
    this.GetDoctorList();
    // this.GetDocHosDetail();
    // this.GetHospitalList();
    // this.GetDocHospitalList();
  }



  GetVisitTime(event) {
    this.showIntervalButton = true;
    var vDate = moment(this.visitObj.VisitDate);
    var visitDate = vDate.format('YYYY-MM-DD');
    if (visitDate != null) {
      // if (this.selAppointmentType != "Telemedicine") {
      //   this.GetSchedulingDetails();
      // }
      // else {
      this.schedulingService.getVisitTime(visitDate, this.selDoctor, this.selDepartment, this.selHospitalId).subscribe(res => this.SuccessAppointmentTime(res),
        res => this.ErrorAppointmentTime(res));

    }
  }
  SuccessAppointmentTime(res) {
    this.appointmentTime = [];
    this.appointmentTime = Object.assign(this.appointmentTime, res);

    if (this.appointmentTime.length == 0) {
      this.notifyService.showInfo("Sorry", "There is no schedule for" + " " + "Dr." + " " + `` + " " + "on" + " " + `${moment(this.visitObj.VisitDate).format('YYYY-MM-DD')}`);
    }
    // if (this.data.docId != null && this.appointmentTime.length > 0) {
    //   this.appointmentTime = this.appointmentTime.filter(x => x.DoctorId == this.data.docId && x.DepartmentId == this.selDepartment && x.HospitalId == this.selHospitalId);

    // }

    else {
      for (var c = 0; c < this.appointmentTime.length; c++) {
        var date = moment(this.appointmentTime[c].Date).format('YYYY/MM/DD');
        var timeextract = this.appointmentTime[c].StartTime;
        var bookedStartTime = moment(date + ' ' + timeextract, 'YYYY/MM/DD HH:mm A');
        var momentObj = moment(bookedStartTime, 'YYYY-MM-DDLT');
        var currentdateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');
        var formatedBookedDateTime = moment(currentdateTime, 'YYYY-MM-DD HH:mm A');
        this.appointmentTime[c].StartTimeDetail = formatedBookedDateTime;
      }

      for (let k = 0; k < this.appointmentTime.length - 1; k++) {
        for (let l = 0; l < this.appointmentTime.length - k - 1; l++) {
          var diffmin = this.appointmentTime[l + 1].StartTimeDetail.diff((this.appointmentTime[l].StartTimeDetail), 'minutes')
          if (diffmin < 0) {
            let swap = this.appointmentTime[l];
            this.appointmentTime[l] = this.appointmentTime[l + 1];
            this.appointmentTime[l + 1] = swap;
          }
        }
      }

      this.appointmentTime = this.appointmentTime;
    }
  }

  ErrorAppointmentTime(res) {
    // this.notifyService.showInfo("Sorry", "There is no schedule for" + " " + "Dr." + " " + `${this.doctor.DoctorName}` + " " + "on" + " " + `${moment(this.visitObj.VisitDate).format('YYYY-MM-DD')}`);
  }
  checkTimeSlot(SchId) {
    this.visitObj.VisitType = this.selAppointmentType;
    this.visitObj.ProviderId = this.selDoctor;
    this.visitObj.HospitalId = this.selHospitalId;
    this.visitObj.DepartmentId = this.selDepartment;
    this.visitObj.SchedulingId = SchId;
    this.global.VisitDate = this.visitObj.VisitDate;
    var hospitalDetials = this.docHospitalList.filter(x => x.HospitalId == this.selHospitalId);
    if (hospitalDetials[0].isPaymentEnablle == true) {
      this.visitObj.PaymentStatus = "unpaid";
    }
    else {
      this.visitObj.PaymentStatus = "free";
    }
    this.visitObj.ProviderId = this.selDoctor;
    this.visitObj.DepartmentId = this.deptId;
    if (this.selAppointmentType == "Telemedicine") {
      this.visitObj.BookingTime = null;
      //this.showBookingAppointBtn = false;
      // this.showIntervalSlot = true;
      if (this.showIntervalSlot == true) {
        this.scheduleIntervalList = [];
        this.showIntervalSlot = false;
        this.showRescheduleBtn = false;
      } else {
        this.schedulingService.getScheduleIntervalBySchedulingId(SchId).subscribe(res => this.SuccessScheduleInterval(res),
          res => this.Error(res));
      }
    }
    else {
      this.showRescheduleBtn = true;
      this.visitObj.ProviderId = this.selDoctor;
      this.visitObj.DepartmentId = this.deptId;
    }
  }
  SuccessScheduleInterval(res) {
    if (res && res.IsSuccess && res.Results) {
      this.scheduleIntervalList = Object.assign(this.scheduleIntervalList, res?.Results);
      for (var c = 0; c < this.scheduleIntervalList.length; c++) {
        var date = moment(this.scheduleIntervalList[c].Date).format('YYYY/MM/DD');
        var timeextract = this.scheduleIntervalList[c].StartTime;
        var bookedStartTime = moment(date + ' ' + timeextract, 'YYYY/MM/DD HH:mm A');
        var momentObj = moment(bookedStartTime, 'YYYY-MM-DDLT');
        var currentdateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');
        var formatedBookedDateTime = moment(currentdateTime, 'YYYY-MM-DD HH:mm A');
        this.scheduleIntervalList[c].StartTimeDetail = formatedBookedDateTime;
        this.scheduleIntervalList[c].selectedTime = false;
      }
      // console.log(this.scheduleIntervalList);
  
      for (let k = 0; k < this.scheduleIntervalList.length - 1; k++) {
        for (let l = 0; l < this.scheduleIntervalList.length - k - 1; l++) {
          var diffmin = this.scheduleIntervalList[l + 1].StartTimeDetail.diff((this.scheduleIntervalList[l].StartTimeDetail), 'minutes')
          if (diffmin < 0) {
            let swap = this.scheduleIntervalList[l];
            this.scheduleIntervalList[l] = this.scheduleIntervalList[l + 1];
            this.scheduleIntervalList[l + 1] = swap;
          }
        }
      }
  
      this.showIntervalSlot = true;
      // console.log(this.scheduleIntervalList);
    }else{
      this.alertService.error(res.ResponseMessage);
    }
   
  }
  setAppointmentTime(time) {
    if (time.IsBooked == true) {
      var bookingtime = `${time.StartTime} - ${time.EndTime}`;
      this.notifyService.showWarning("The time slot " + bookingtime + " was the previous booked time", "Sorry");
    }
    else {

      this.scheduleIntervalId = time.ScheduleIntervalId;
      this.docService.SchedulingId = time.SchedulingId;
      this.visitObj.BookingTime = `${time.StartTime} - ${time.EndTime}`;
      this.global.BookingTime = this.visitObj.BookingTime;
      this.showRescheduleBtn = true;
      for (var i = 0; i < this.scheduleIntervalList.length; i++) {
        if (this.scheduleIntervalList[i].StartTime == time.StartTime && this.scheduleIntervalList[i].IsBooked == false) {
          this.scheduleIntervalList[i].selectedTime = true;
        }
        else {
          this.scheduleIntervalList[i].selectedTime = false;
        }
      }
    }

  }
  Reschedule() {
    this.schedulingService.postReschedule(this.visitObj, this.scheduleIntervalId, this.oldSchedulingId, this.HospitalDoctorMapId).subscribe(res => this.Success(res),
      res => this.Error(res));


  }
  Success(res) {
    this.appointmentTime = [];
    this.showRescheduleBtn = false;
    //this.visitObj.VisitDate = moment().format('YYYY-MM-DD');;
    this.notifyService.showSuccess('Updated successfully', 'Success');

  }
  Error(res) {
    this.notifyService.showError("Error", " Error in Re-Scheduling")

  }

  GetDoctorList() {
    this.docService.getAvailableDoctorList().subscribe((res: any) => {
      if (res) {
        this.doctorList = res?.Results;
        this.SuccessDoctor();
      }
    },
      err => {
        this.notifyService.showError("Error", "Something Went Wrong.");
      })
  }
  SuccessDoctor() {
    this.filteredDoctorList = [];
    if (this.doctorList && this.doctorList.length) {
      this.doctorList.forEach(doc => {
        for (var i = 0; i < doc.ConsultationType.length; i++) {
          if (doc.ConsultationType[i] == this.selAppointmentType) {
            this.filteredDoctorList.push(doc);
            break;
          }
        }
      });
    }
    if (this.filteredDoctorList, length > 0) {
      this.isDoctorAvailable = true;
    }
    // this.selHospitalId = "";
    // this.docHospitalList();
    this.getDocHospitalList();
  }
  getDocHospitalList() {
    this.hospitalService.getDocHospitalList(this.selDoctor)
      .subscribe((res) => {
        if (res) {
          this.docHospitalList = Object.assign(res);
          if (this.selHospitalId && this.selHospitalId != 0)
            this.GetHosDepartmentList();
        }
      }, err => {
        this.alertService.error("Unable to get hospital list. Please try again later.");
        console.log(err);
      })
  }


  GetHosDepartmentList() {
    this.hospitalService.getDocHospitalListbyvisitytpe(this.selDoctor, this.selAppointmentType).subscribe((res: any) => {
      if (res && res.IsSuccess && res.Results) {
        var tempFilterDeptList = Object.assign(res.Results);
        this.filterdeptList = tempFilterDeptList.filter(x => x.HospitalId == this.selHospitalId);
        this.GetSchedulingDetails();
      }else{
        this.alertService.error(res.ResponseMessage);
      }
    })
  }

  // SuccessHospitalList(res) {
  //   this.filteredH = Object.assign(res);
  //   if (this.data.hospitalId) {
  //     this.hospitalList = this.hospitalList.filter(s => s.HospitalId == this.data.hospitalId)
  //     this.selHospital = this.hospitalList[0].HospitalId;
  //     this.hospitalId = this.selHospital;
  //     this.showDept = true;
  //     this.GetDepartmentList();
  //   }
  // }

  GetDocHosDetail() {
    this.hospitalService.getDocHospitalList(this.selDoctor).subscribe(
      res => {
        if (res) {
          this.docHospitalList = Object.assign(res);
          console.log(res);
          this.SuccessHospital();
        }
      }),
      err => {
        this.notifyService.showWarning("Error", "Unable to get Hospital List");
        console.log(err);
      }

  }

  SuccessHospital() {
    this.deptList;
    this.docHospitalList.forEach(docHos => {
      if (docHos.HospitalId = this.selHospitalId) {
        for (var i = 0; i < docHos.DepartmentId.length; i++) {
          // var j = 0;
          this.filteredDepartmentTemp = this.deptList.filter(x => x.DepartmentId.includes(docHos.DepartmentId[i]));
          if (this.filteredDepartmentTemp) {
            var departmentDetails = this.filteredDepartmentTemp[0];
            this.filterdeptList.push(departmentDetails);
            this.filteredDepartmentTemp = [];

          }
        }
      }
    })
  }



  AssignDepartment(event: MatSelectChange) {
    //this.showUnavailableMsg = false;
    this.hospitalService.DepartmentId = event.value;
    this.deptId = event.value;
    this.appointmentTime = [];
    this.visitObj.BookingTime = null;
    this.visitObj.VisitDate = null;
    this.filteredHospitalDoctorMapping = new Array<any>();
    this.filteredHospitalDoctorMapping = JSON.parse(JSON.stringify(this.HospitalDoctorMapping));//deepcopy
    this.filteredHospitalDoctorMapping = this.filteredHospitalDoctorMapping.filter(s => s.DepartmentId == event.value);
    this.filteredSchedulingList = new Array<any>();
    this.filteredSchedulingList = JSON.parse(JSON.stringify(this.SchedulingList));//deepcopy
    this.filteredSchedulingList = this.filteredSchedulingList.filter(s => s.HospitalDoctorMap.DepartmentId == this.deptId);
    if (this.filteredSchedulingList.length < 1) {
      this.isSchedulingAvailable = false;
    }
    else {
      this.isSchedulingAvailable = true;
      this.HospitalDoctorMapId = this.filteredSchedulingList[0].HospitalDoctorMap.HospitalDoctorMapId;
    }

  }
  AssignDoctor(event: MatSelectChange) {
    this.selDoctor = event.value;
    this.appointmentTime = [];
    this.visitObj.BookingTime = null;
    this.visitObj.VisitDate = null;
    this.getDocHospitalList();
  }

  AssignAppointmentType(event) {
    this.selAppointmentType = event.value;
    this.selDepartment = this.selDoctor = this.selHospitalId = "";
    this.filterdeptList = this.docHospitalList = [];
    this.SuccessDoctor();
  }
  AssignHospital(event) {
    this.selHospitalId = event.value;
    // this.AssignDepartment();
    this.GetHosDepartmentList();

  }

  GetSchedulingDetails() {
    this.docService.getDoc(this.selDoctor, this.selHospitalId, this.selAppointmentType)
      .subscribe(res => this.SuccessDetails(res),
        res => this.Error(res));
  }
  SuccessDetails(res) {
    
// if (res && res.IsSuccess && res.Results) {
  // this.doctorList = res;
  this.SchedulingList = res.Results.SchedulingList;
  this.filteredSchedulingList = Object.assign(this.SchedulingList);
  if (this.filteredSchedulingList.length < 1) {
    this.isSchedulingAvailable = false;
  }
  else {
    this.isSchedulingAvailable = true;
  }
// }else{
//   this.alertService.error(res.ResponseMessage);
// }
   
    // this.SchedulingList.forEach(x=> )
    // this.tempArray = new Array<any>();
    // this.tempArray = JSON.parse(JSON.stringify(this.SchedulingList));
    // if (this.tempArray.length == 0) {
    //   this.showUnavailableMsg = true;
    // }
    // for (var y = 0; y < this.tempArray.length; y++) {
    //   var tempst = 0;
    //   tempst = +this.tempArray[y].StartTime.substr(0, 2);
    //   if (tempst < 12) {
    //     var stampm = "AM";
    //     var ST = tempst;
    //   }
    //   else {
    //     stampm = "PM";
    //     if (tempst == 12) {
    //       var ST = tempst;
    //     } else {
    //       ST = tempst - 12;
    //     }
    //   }
    //   this.tempArray[y].StartTime = ST + this.tempArray[y].StartTime.substr(2, 3) + " " + stampm;
    //   var tempet = 0;
    //   tempet = +this.tempArray[y].EndTime.substr(0, 2);
    //   if (tempet < 12) {
    //     var etampm = "AM";
    //     var ET = tempet;
    //   }
    //   else {
    //     etampm = "PM";
    //     if (tempet == 12) {
    //       var ET = tempet;
    //     } else {
    //       ET = tempet - 12;
    //     }
    //   }
    //   this.tempArray[y].EndTime = ET + this.tempArray[y].EndTime.substr(2, 3) + " " + etampm;
    // }
    // this.SchedulingList = this.tempArray;
    // this.filteredSchedulingList = res.SchedulingList;
    // this.tempArray = new Array<any>();
    // this.tempArray = JSON.parse(JSON.stringify(this.filteredSchedulingList));
    // for (var y = 0; y < this.tempArray.length; y++) {
    //   var tempst = 0;
    //   tempst = +this.tempArray[y].StartTime.substr(0, 2);
    //   if (tempst < 12) {
    //     var stampm = "AM";
    //     var ST = tempst;
    //   }
    //   else {
    //     stampm = "PM";
    //     if (tempst == 12) {
    //       var ST = tempst;
    //     } else {
    //       ST = tempst - 12;
    //     }
    //   }
    //   this.tempArray[y].StartTime = ST + this.tempArray[y].StartTime.substr(2, 3) + " " + stampm;
    //   var tempet = 0;
    //   tempet = +this.tempArray[y].EndTime.substr(0, 2);
    //   if (tempet < 12) {
    //     var etampm = "AM";
    //     var ET = tempet;
    //   }
    //   else {
    //     etampm = "PM";
    //     if (tempet == 12) {
    //       var ET = tempet;
    //     } else {
    //       ET = tempet - 12;
    //     }
    //   }
    //   this.tempArray[y].EndTime = ET + this.tempArray[y].EndTime.substr(2, 3) + " " + etampm;
    // }
    // this.filteredSchedulingList = this.tempArray;
    // this.HospitalDoctorMapping = res.HospitalDoctorMapId;
  }
  // doctorAvailableListClass = (d: Date) => {

  // const date = moment(d).format('YYYY-MM-DD');
  // // Highlight the 1st and 20th day of each month.
  // this.filteredSchedulingList.forEach(x => {
  //   // return (d.equals(x.Date)) ? 'doctorAvailableList' : undefined;
  //   return (date == moment(x.Date).format('YYYY-MM-DD')) ? 'doctorAvailableList' : undefined;
  // })
  doctorAvailableListClass: MatCalendarCellClassFunction<Date> = (cellDate: any, view) => {
    // var newDate = this.filteredSchedulingList[1].Date;
    const date = cellDate.format('YYYY-MM-DD');
    for (var i = 0; i < this.filteredSchedulingList.length; i++) {
      this.availableDate = this.filteredSchedulingList[i].Date;
      var newDate = moment(this.availableDate).format('YYYY-MM-DD')
      if (newDate == date) {
        return date == newDate ? 'doctorAvailableListClass' : '';
      }
    }
  };
}
// }
