import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DanphecareModel } from '../models/danphecare/danphecare.model';
import { DanphecareService } from '../services/danphecare/danphe.service';
import { AlertService } from '../services/alert.service';
import { NotificationService } from '../services/notification.service';
import * as moment from 'moment';


@Component({
  templateUrl: './app.danphecare.html'
})
export class DanphecareComponent  {
  public Dhcare: DanphecareModel = new DanphecareModel();

  public  Usrlist: Array<any> = new Array<any>();
  p:number=1;
  
  constructor(public routing: Router,public http: HttpClient, public danphecareservice : DanphecareService,private notifyService: NotificationService, private alertService: AlertService,) {
      this.GetAppliedUsrlist();
  }

  GetAppliedUsrlist(){
    this.danphecareservice.GetAppliedUsrlist()
    .subscribe(res => this.SuccessGetAppliedUsrlist(res),
    res => this.GetAppliedUsrlistError(res));

  }

  SuccessGetAppliedUsrlist(res) {    
    this.Usrlist = res;
    for (var i = 0; i < this.Usrlist.length; i++) {
      this.Usrlist[i].CreatedOn = moment(this.Usrlist[i].CreatedOn).format("YYYY-MM-DD");
    }
    this.notifyService.showSuccess('Success', ' User list Load');
  }
  GetAppliedUsrlistError(res) {
    this.notifyService.showError("Error", " field to load User list")
  } 

  ApplytoIsolation(){ 
if(this.Dhcare != null){
    this.danphecareservice.applytoIsolation(this.Dhcare)
    .subscribe(res => this.SuccessPostapplyIso(res),
    res => this.PostapplyIsoError(res));
}

  }
  SuccessPostapplyIso(res) {
    this.Dhcare = new DanphecareModel();
    this.GetAppliedUsrlist();
    this.notifyService.showSuccess('Success', ' Apply Successfully ');
  }
  PostapplyIsoError(res) {
    this.notifyService.showError("Error", " Please fill up the required field")
  } 

}
