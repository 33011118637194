<app></app>
<div class="back-bg">
  <button [routerLink]="['/admin']" class="back-btn-doc-room">Back <span>To Admin</span> </button>
  <mat-chip-list aria-label="doctor selection">
    <mat-chip color="primary" (click)="RegPatient()" selected>+ Register Patient</mat-chip>
  </mat-chip-list>
</div>
<div class="grid-section">
  <h4 class="title">
    <i class="fa fa-list"></i>Patient List
  </h4>

  <div class="row searchbar-card text-center" style="margin-top: 0">
    <div class="col-md-6 col-sm-8 m-auto d-flex">
      <div class="searchbar">
        <input class="search_input" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Patient..">
        <a class="search_icon text-white"><i class="fa fa-search"></i>Search</a>
      </div>
    </div>
  </div>
  <!-- for-mobile -->
  <!-- class="d-sm-none d-md-block" -->
  <table class="table table-striped table-hover patient-list-table">
    <thead>
      <tr class="table-tr-style">
        <th scope="col">SN</th>
        <th scope="col">Full Name</th>
        <th scope="col">Age/Gender</th>
        <th scope="col">Contact Number</th>
        <th scope="col">Address</th>
        <th scope="col">Email</th>
        <th scope="col">DOB</th>
        <th scope="col">Blood Group</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of patientList | filter: searchText |paginate: { itemsPerPage: 10, currentPage: p  } ;let i = index  ">
        <td>{{i+1}}</td>
        <td>{{data.FirstName }}&nbsp;{{data.MiddleName}}&nbsp;{{data.LastName}} {{data.PatientName}}</td>
        <td>{{data.Age }}<span *ngIf="data.Age != null && data.Gender != null">/</span>{{data.Gender }}</td>
        <td>  
          <span *ngIf="data.CountryCode">{{ data.CountryCode }}</span>
          <span *ngIf="data.CountryCode">-</span>{{data.ContactNumber}}
        </td>
        <td>{{data.Address }}</td>
        <td>{{data.MailingAddress ? data.MailingAddress : "" }}</td>
        <td>{{data.DateOfBirth ? data.DateOfBirth : "" }}</td>
        <td>{{data.BloodGroup }}</td>
        <td>
          <a (click)="UpdateProfile(data)" title="Update Profile" class="btn btn-success btn-sm text-white">
            <i class="fa fa-user" aria-hidden="true"></i>
            <span class="label-btn-pat-list-action">Update Profile</span> </a>
          <!-- <a (click)="BookAppointment(data)" title="Book Appointment" class="btn btn-success btn-sm text-white">
            <i class="fa fa-calendar" aria-hidden="true"></i>
            <span class="label-btn-pat-list-action">Book Appointment</span> </a> -->
          <a (click)="ResetPassword(data)" title="Reset Password" data-toggle="modal" data-target="#passwordModal" class="btn btn-success btn-sm text-white">
            <i class="fa fa-lock" aria-hidden="true"></i>
            <span class="label-btn-pat-list-action">Reset Password</span> </a>
        </td>
      </tr>
    </tbody>
  </table>

  <pagination-controls style="font-size:20px" (pageChange)="p = $event"></pagination-controls>



</div>
<div class="modal" id="myModal" tabindex="-1" role="dialog" [style.display]="editModal ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="background-color: transparent; border: none;">

      <!-- Modal body -->
      <patient-update-profile #patientEditor></patient-update-profile>

    </div>
  </div>
</div>
<div class="modal " id="myModal" tabindex="-1" role="dialog" [style.display]="pwdModal ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="background-color: transparent; border: none;">
      <div class="modal-body">
        <!-- Modal body -->
        <password-update #pwdEditor></password-update>
      </div>


    </div>

  </div>
</div>
<div class="modal" id="myModal" [style.display]="showVisitTypePopup ? 'block' : 'none'">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="width: 50%;margin-left: 25%;">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Select Appointment Type</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="CloseVisittypepopup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row d-flex mb-2">
          <div class="col-md-12">
            <button style="margin-left: 50px;" value="Online-Appointment" (click)="SetOnlineAppointment()">Online Appointment</button>
            <button style="margin-left: 25px;" value="Telemedicine" (click)="SetTelemedicineAppointment()">Telemedicine</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer></footer>