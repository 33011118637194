export class Problem {
  public ProblemId: string;
  public Fever: boolean;
  public Cough: boolean;
  public BreathingDifficulty: boolean;
  public Tiredness: boolean;
  public SoreThroat: boolean;
  public Bodyache: boolean;
  public ChestPain: boolean;
  public Diarrhea: boolean;
  public AnyOtherSymptoms: string;
  public HighBloodPressure: boolean ;
  public Diabetes: boolean ;
  public Copd: boolean ;
  public Transplant: boolean;
  public RecentTravel: boolean;
  public Cancer: boolean ;
  public Exposure: boolean;
  public OtherPertientInformation: string;
  public HeartDisease: boolean;
  public VisitId: string;
  public Height : string;
  public Weight : string;
  public Temperature: string;
  public BP : string;
  public BMI : string;
  public Bodypain : string;
  public Painscale : string;

  public History: string;
  public ChiefComplaints : string;
  public Investigation : string;
  public Remarks : string;
  public FinalDiagnosis : string;

}
