import { Patient, Doctor } from './app.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Visit } from './models/visit/visit.model';
import { Injectable } from "@angular/core";

@Injectable()
export class Global {
    patientObj: Patient = null;
    doctorObj: Doctor = null;
    IsDoctor: boolean = false;
    DoctorId: string = null;
    HospitalId: string = null;
    DepartmentId: string = null;
    BookingTime: string = null;
    VisitDate: Date = null;
    ApiUrl: string = "Home/";
    config: any = null;
    TimerValue: number = 4000;
    apiLogout: boolean = false;
    constructor() {
        this.patientObj = new Patient();
        this.doctorObj = new Doctor();
    }


}
