import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { ChatService } from 'src/app/services/ChatNotification/chat-notification.service';
import { Patient } from 'src/app/models/patient/patient.model';
import { DoctorService } from 'src/app/services/doctor/doctor.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Global } from 'src/app/app.global';

@Component({
  templateUrl: './app.admin_helpdesk.html'
})
export class AdminHelpDeskComponent {

  public hospitalId: any;
  ChatForm: FormGroup;
  public patInfo: Patient = new Patient();
  public receiverId: string;
  ChatUsers: Array<any> = new Array<any>();
  AllUserChats: any;
  ChatReceivedMessages: Array<any> = new Array<any>();
  token: any;
  viewMessage: boolean = false;
  allChatMessages: any = [];
  selectedPatientId: any;
  constructor(
    private alertService: AlertService,
    public routing: Router, private authenticationService: AuthenticationService,
    public patService: PatientService, public notificationService: ChatService
  ) {
    this.token = this.authenticationService.currentUserValue;
    this.getPatientName();
    this.initForm();
    this.notificationService.Connect();
    this.notificationService.EventChatMessage.subscribe(chatData => {
      this.OnChatUserChange();

      const chatMsg = { Name: chatData.Name, Message: chatData.Message, PatientId: chatData.SenderId, Class: 'receiver-msg', IsSeen: false };

      this.allChatMessages.push(chatMsg);
      this.RefreshNotification();

      const chatUser = { Name: chatData.Name, PatientId: chatData.SenderId, UnseenMessageCount: 1 };
      if (this.ChatUsers.length != 0) {
        var userAvailable = this.ChatUsers.find(u => u.PatientId == chatUser.PatientId);
        if (userAvailable != null) {
          if (userAvailable.PatientId != this.selectedPatientId)
            userAvailable.UnseenMessageCount++;
        }
        else {

          this.ChatUsers.push(chatUser);
        }
      }
      else {
        this.ChatUsers.push(chatUser);
      }

      this.pushChatMsgUserwise(chatData.Name, chatMsg);

    });
  }

  pushChatMsgUserwise(user, messageObj) {
    try {
      if (!this.AllUserChats.hasOwnProperty(user)) {
        this.AllUserChats[user] = new Array<any>();
      }
      this.AllUserChats[user].push(messageObj);
    } catch (e) { }
  }
  onChatUserClicked(userPatientId) {
    this.selectedPatientId = userPatientId;
    var selectedChatUser = this.ChatUsers.find(a => a.PatientId == this.selectedPatientId);
    selectedChatUser.UnseenMessageCount = 0;
    this.RefreshNotification()
  }
  public BackDashBoard() {
    this.routing.navigate(['/admin'], { queryParams: { id: this.hospitalId } });
  }
  private initForm() {
    this.ChatForm = new FormGroup(
      {
        chatMessage: new FormControl('', [Validators.required])
      });
  }
  hasError(typeofvalidator: string, controlname: string): boolean {
    const control = this.ChatForm.controls[controlname];
    return control.hasError(typeofvalidator) && control.dirty;
  }
  SendChatMsg(event?) {
    if (event && event.keyCode !== 13)
      return;
    try {
      if (this.chatMessageToSend.value)
        this.chatMessageToSend.setValue(this.chatMessageToSend.value.trim());

      for (const i in this.ChatForm.controls) {
        this.ChatForm.controls[i].markAsDirty();
        this.ChatForm.controls[i].updateValueAndValidity();
      }

      if (this.ChatForm.valid) {
        const chatMsg = {
          SenderId: this.token.unique_name,
          ReceiverId: this.selectedPatientId,
          Name: this.token.UserType,
          Message: this.chatMessageToSend.value
        };
        const chatmsgObj = {
          Name: 'Me',
          Message: chatMsg.Message,
          Class: 'sender-msg',
          ReceiverId: chatMsg.ReceiverId
        };
        this.allChatMessages.push(chatmsgObj);

        this.notificationService.SendChatMessage(chatMsg);
        this.chatMessageToSend.reset();
      }

    } catch (e) { }
  }

  private get chatMessageToSend() {
    return this.ChatForm.get("chatMessage") as FormControl;
  }
  OnChatUserChange() {
    try {
      const selUser = this.ChatForm.controls['Id'].value;
      if (this.AllUserChats.hasOwnProperty(selUser)) {
        this.ChatUsers = this.AllUserChats[selUser].slice();
        this.ChatReceivedMessages = this.AllUserChats[selUser].slice();
      } else {
        this.ChatUsers = new Array<any>();
        this.ChatReceivedMessages = new Array<any>();
      }
    } catch (e) { }
  }

  public getPatientName() {
    this.patService.getPatient(this.token.PatientIdentifier,this.token.UserType).subscribe(res => {
      this.patInfo = res;
    },
      error => {
        this.alertService.error(error);
      });
  }

  getAdminId() {
    this.patService.getAdminId()
      .subscribe(res => this.SuccessGetAdminId(res),
        res => this.Error(res));
  }
  SuccessGetAdminId(res) {
    let temp = res;

  }
  Error(res) {

  }

  routeToPage(patientId, index) {
    this.viewMessage = true;
    this.selectedPatientId = patientId;
  }

  RefreshNotification() {
    this.viewMessage = true;
  }
}

