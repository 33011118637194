import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../app.global';
import { PatientService } from '../../services/patient/patient.service';
import { Visit } from '../../models/visit/visit.model';
import { NotificationService } from '../../services/notification.service';
import { AlertService } from '../../services/alert.service';
import { DoctorService } from '../../services/doctor/doctor.service';
import { PatientDoc, Patient } from '../../models/patient/patient.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChatService } from 'src/app/services/ChatNotification/chat-notification.service';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
@Component({
  //selector: 'app-root',
  templateUrl: './app.waitingroom.html', 
  styleUrls: ['./app.waitingroom.css']
})
export class WaitingRoom implements OnInit {
  public isCollapsed = false;
  timer1: any = timer(3000, 2000);
  public patientName: string;
  token: any;
  status: any;
  VisitId: any;
  HospitalId: any;
  public doctorId: string;
  public receiverId: string;
  public index: number;
  visitObj: Visit = new Visit();
  patients: Array<PatientDoc> = new Array<PatientDoc>();
  public patInfo: Patient = new Patient();
  patlist: any = null;
  loginVal: any;
  ChatForm: FormGroup;
  ChatMessages: Array<any> = new Array<any>();
  ChatReceivedMessages: Array<any> = new Array<any>();
  AllUserChats: any;
  docName: any;
  public doctor: Array<any> = new Array<any>();
  apiStatusDestroy = false;
  apiPatDocDestroy = false;
  redUrl: string;
  start = Date.now();
  istimer = "";
  patientWaitingRoomSubscription = new Subscription();
  public hideMeetingButton: boolean = false;
  public FamilyMemberName: string;
  constructor(
    public httpClient: HttpClient,
    private alertService: AlertService,
    private notifyService: NotificationService,
    patservice: PatientService,
    private route: ActivatedRoute,
    public docService: DoctorService,
    public routing: Router,
    private authenticationService: AuthenticationService,
    public formBuilder: FormBuilder,
    public global: Global,
    public patService: PatientService,
    public notificationService: ChatService,
    private ngZone: NgZone
  ) {
    this.token = this.authenticationService.currentUserValue;
    this.getPatientName();
    this.global.apiLogout = false;
    this.VisitId = this.route.snapshot.queryParamMap.get('vid');
    this.HospitalId = this.route.snapshot.queryParamMap.get('id');
    this.redUrl = this.route.snapshot.queryParamMap.get('redirectUrl');
    this.doctorId = this.route.snapshot.queryParamMap.get('did')
    this.initForm();
    this.notificationService.Connect();
    this.notificationService.EventChatMessage.subscribe(chatData => {
      this.OnChatUserChange();
      const chatMsg = { Name: chatData.Name, Message: chatData.Message, Class: 'receiver-msg' };
      this.ChatMessages.push(chatMsg);
      this.pushChatMsgUserwise(chatData.Name, chatMsg);
    });

    this.AddVisitToSingleton(this.VisitId)
    this.GetEntrytoDoctorRoom();
    this.GetDoc();

  }
  ngOnInit() {

    this.GetStatusByPatientId();


  }

  AddVisitToSingleton(visitId) {
    this.patService.postVisitToSingleton(visitId).subscribe(res =>
      this.SuccessPostToSingleton(res),
      res => this.Error(res));
  }

  SuccessPostToSingleton(res) {
    console.log(res);
  }
  private initForm() {
    this.ChatForm = this.formBuilder.group({
      chatMessage: ['', Validators.required]
    });
  }

  hasError(typeofvalidator: string, controlname: string): boolean {
    const control = this.ChatForm.controls[controlname];
    return control.hasError(typeofvalidator) && control.dirty;
  }

  GetEntrytoDoctorRoom() {

    this.patientWaitingRoomSubscription.add(this.timer1.pipe(
      takeWhile(x => this.apiStatusDestroy === false)
    ).subscribe(
      () => {
        console.log(this.redUrl);
        if (this.redUrl === "PatientUrl") {
          this.patService.getVisitFromWaitingRoom(this.VisitId).subscribe(res =>
            this.SuccessStatusVisitId(res),
            res => this.Error(res));
        }
        else if (this.redUrl === "DoctorUrl") {
          this.patService.getVisitFromWaitingRoom(this.VisitId).subscribe(res =>
            this.SuccessStatusVisitId(res),
            res => this.Error(res));
        }

        else {
          this.patService.getVisitFromWaitingRoom(this.VisitId).subscribe(res =>
            this.SuccessStatusVisitId(res),
            res => this.Error(res));
        }

      }
    ));


  }

  showZoomJoiningButton: boolean = false;
  SuccessStatusVisitId(res) {
    if(res){
      this.status = res;
      this.FamilyMemberName = res.FamilyMemberName;
      if (this.status != null) {
        console.log(this.status.Status);
        if (this.status.Status == "ongoing" && this.status.IsActive == true) {
          this.patService.VisitId = this.status.VisitId;
          // this.apiStatusDestroy = true;
          //this.routing.navigate(['/DoctorRoom'], { queryParams: { id: this.VisitId, did: this.doctorId } });
          this.showZoomJoiningButton = true;

        }
        else if (this.status.Status == "initiated" && this.status.IsActive == true) {
          // this.showZoomJoiningButton = true;
          this.apiStatusDestroy = false;
          var end = Date.now();
          var timeOut = end - this.start;    //In miliseconds
          if (timeOut >= 900000) {
            this.WaitingTimeOut();
          }

        }
        else if (this.status.Status === "completed" || (this.status.Status === "initiated" && this.status.IsActive == false)) {
          this.apiStatusDestroy = true;
          this.showZoomJoiningButton = false;
          this.routing.navigate(['/PatDashboard']);
          window.location.reload();
        }
        else {
          this.apiStatusDestroy = true;
        }
      }
      else {
        this.apiStatusDestroy = true;
      }
    }else{
      this.apiStatusDestroy = true;
      this.showZoomJoiningButton = false;
      this.routing.navigate(['/PatDashboard']);
      window.location.reload();

    }

  }

  WaitingTimeOut() {
    this.routing.navigate(['/PatDashboard']);
  }

  GetStatusByPatientId() {
    this.patService.getStatusByPatientId(this.VisitId).subscribe(res =>
      this.SuccessResponse(res),
      res => this.Error(res));
  }
  SuccessResponse(res) {
    this.status = res;
    this.patientName = this.status.PatientName;
    if (this.VisitId == null) {
      var visitid = this.status.VisitId;
      this.patService.VisitId = this.status.VisitId;
      this.patService.getPatientByVisitId(visitid)
        .subscribe(response => this.SuccessPatientDetail(response, visitid),
          error => this.Error(error));
    }
    else {
      this.patService.getPatientByVisitId(this.VisitId)
        .subscribe(response => this.SuccessPatientDetail(response, this.VisitId),
          error => this.Error(error));
    }

  }
  SuccessPatientDetail(res, visitid) {
    this.visitObj = res;
    this.notifyService.showInfo("Please wait, Your Doctor will call you shortly ", "Welcome to Waiting Room")
  }

  GetPatientDoctorList() {
    this.patientWaitingRoomSubscription.add(this.timer1.pipe(
      takeWhile(x => this.apiPatDocDestroy === false)
    ).subscribe(
      () => {
        //this.timer1.subscribe(
        //  () => {
        this.loginVal = this.authenticationService.loginValue;
        if (this.loginVal === "patient") {
          this.patService.getPatientDocList(this.doctorId)
            .subscribe(res => this.SuccessPatientList(res),
              res => this.Error(res))
        };
      }));
  }


  SuccessPatientList(res) {
    this.patients = res;
    this.index = this.patients.findIndex(x => x.VisitId === this.VisitId);
    this.apiPatDocDestroy = true;
  }

  Error(res) {
    console.log(res);
  }
  GetDoc() {
    this.docService.getDoctorName(this.doctorId).subscribe(res => this.Success(res),
      res => this.Error(res));
  }
  Success(res) {
    this.doctor = res;
    this.receiverId = res.IdentityUserId;
    this.docName = `${res.FirstName} ${res.MiddleName ? res.MiddleName : ""} ${res.LastName}`;

  }

  public getPatientName() {
    this.patService.getPatient(this.token.PatientIdentifier,this.token.UserType).subscribe(res => {
      this.patInfo = res;
    },
      error => {
        this.alertService.error(error);
      });
  }
  SendChatMsg() {
    try {
      for (const i in this.ChatForm.controls) {
        this.ChatForm.controls[i].markAsDirty();
        this.ChatForm.controls[i].updateValueAndValidity();
      }

      if (this.ChatForm.valid) {
        const chatMsg = {
          SenderId: this.patInfo.IdentityUserId,
          ReceiverId: this.receiverId,
          Name: `${this.patInfo.FirstName} ${this.patInfo.MiddleName ? this.patInfo.MiddleName : ""} ${this.patInfo.LastName}`,
          Message: this.ChatForm.controls['chatMessage'].value
        };
        const chatmsgObj = { Name: 'Me', Message: chatMsg.Message, Class: 'sender-msg' };
        this.ChatMessages.push(chatmsgObj);

        this.notificationService.SendChatMessage(chatMsg);
        this.ChatForm.reset();
      }
    } catch (e) { }
  }


  OnChatUserChange() {
    try {
      const selUser = this.ChatForm.controls['Id'].value;
      if (this.AllUserChats.hasOwnProperty(selUser)) {
        this.ChatMessages = this.AllUserChats[selUser].slice();
        this.ChatReceivedMessages = this.AllUserChats[selUser].slice();
      } else {
        this.ChatMessages = new Array<any>();
        this.ChatReceivedMessages = new Array<any>();
      }
    } catch (e) { }
  }

  onChatEnter(event) {
    if (event.keyCode === 13) {
      this.SendChatMsg();
    }
  }
  pushChatMsgUserwise(user, messageObj) {
    try {
      if (!this.AllUserChats.hasOwnProperty(user)) {
        this.AllUserChats[user] = new Array<any>();
      }
      this.AllUserChats[user].push(messageObj);
    } catch (e) { }
  }
  ngOnDestroy() {
    this.patientWaitingRoomSubscription.unsubscribe();
  }



  client = ZoomMtgEmbedded.createClient();
  // meetingNumber = "2278567480";
  // apiKey = "OkIXXMhwSbaiZnvWsy_rVQ";
  // apiSecret = "tUmpngwqqL9KqHTDC8HmKghFBe41Er8k";
  // password = "i9iXuV";
  getZoomSignature(): void{
    //call to pat and pass jitsi room name
    this.patService.getZoomSignature(this.doctorId).subscribe(
      (res) => this.startMeeting(res?.Results),
      (res) => console.log(res)
    )}

    startMeeting(signature): void {
      let meetingSDKElement = document.getElementById('meetingSDKElement');
      const [headerEncoded, payloadEncoded, jwtSignature] = signature.split('.');
      const payload = JSON.parse(atob(payloadEncoded));
      this.ngZone.runOutsideAngular(async () => {
        try {
          await this.client.init({ zoomAppRoot: meetingSDKElement, language: 'en-US' });
          await this.client.join({
            signature: signature,
            sdkKey: payload.appKey, // Fixed: Use apiKey instead of sdkKey
            meetingNumber: payload.mn,
            password: payload.password,
            userName: this.FamilyMemberName ? this.FamilyMemberName : this.patInfo.FirstName,
            tk: "",
            zak: ""
          });

          console.log('Joined successfully');
          this.hideMeetingButton = true;
          const endMeetingButton = document.querySelector('button[title="Leave"]') as HTMLElement;
          endMeetingButton.style.display = 'none';
          this.client.on('connection-change', (status) => {
            console.log('Meeting status changed:', status);
          });
     
        } catch (error) {
          await this.client.leaveMeeting(); 
          console.log('Left the meeting successfully');
          console.error(error);
        }
      });
      this.client.leaveMeeting
    }


}
