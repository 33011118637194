export class User {
  userId: string ="";
  userName: string ="";
  password: string = "";
  firstName: string = "";
  lastName: string = "";
  token: string = "";
  isPatient: boolean;
  noToken: string = "";
  identityUserId: string = "";
}

export class Login {
  PhoneNumber: string;
  Password: string;
}
