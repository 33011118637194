import { FormGroup, FormBuilder, Validators } from '@angular/forms';

export class PatientFilesUploadModel {

  public PatientFileId: string ;
  public PatientId: string;
  public VisitId: string;
  public FileType: string = "";
  public Title: string = "";
  public FilePath: string = "";
  public Comment: string = "";

}
