<app></app>
<div class="back-bg">
  <button (click)="Scheduling()" class="back-btn-doc-room">
    Back To Admin Scheduling
  </button>
</div>
<br />
<div>
  <!-- <div class="modal-dialog modal-lg"> -->
  <div class="modal-content" style="background-color: transparent; border: none">

    <!-- Modal body -->
    <div class="row">
      <div class="col-md-6">
        <h1 class="card-title" style="width: 100%; font-size: 20px">
          Please Add Scheduling Time for <strong>Dr.{{ doctor }}</strong>
          <p class="d-block text-small" style="margin-top: 5px">
            Please select Start date and End date
          </p>
          <!-- <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button> -->
        </h1>
      </div>
      <!-- <div class="col-md-2">
        <label>Appointment Type<span class="required-sign">*</span></label>
        <select
          class="form-control"
          placeholder="Select type"
          [(ngModel)]="appointmentType">
          <option value="Online Appointment">
            Online Appointment
          </option>
          <option value="Telemedicine">
            Telemedicine
          </option>
        </select>
      </div>  -->
      <div class="col-md-2">
        <label>Appointment Type<span class="required-sign">*</span></label>
        <input type="text" name="" value="" [(ngModel)]="appointmentType" disabled />
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <ngb-datepicker #d1 (dateSelect)="onDateSelection($event)" [minDate]="minDate" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
        </ngb-datepicker>

        <ng-template #t let-date let-focused="focused" let-disabled="disabled">
          <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
        <!-- <div class="row" style="margin-top: 10px;">
              <div class="col-md-6">
                <label>Start Time</label>
                <ngb-timepicker [(ngModel)]="start" [meridian]="meridian"></ngb-timepicker>
  
              </div>
              <div class="col-md-6">
                <label>End Time</label>
                <ngb-timepicker [(ngModel)]="end" [meridian]="meridian"></ngb-timepicker>
              </div>
            </div> -->
        <table class="table table-striped table-hover table-no-padding" style="position: relative; z-index: 999">
          <thead>
            <tr class="table-tr-style">
              <th style="border: none">
                <label>Start Time</label>
              </th>
              <th style="border: none"></th>
              <th style="border: none"><label>End Time</label></th>
              <th style="border: none"></th>
              <th style="border: none">
                <label>Accomodated Patient<span class="required-sign">*</span></label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style="background-color: #fff !important" *ngFor="let schedule of schedulingList; let i = index">
              <td style="height: 80px; border-top: none">
                <ngb-timepicker [(ngModel)]="schedulingList[i].StartTime" [meridian]="meridian"></ngb-timepicker>
              </td>
              <td style="border-top: none"></td>
              <td style="height: 80px; border-top: none">
                <ngb-timepicker [(ngModel)]="schedulingList[i].EndTime" [meridian]="meridian"></ngb-timepicker>
              </td>
              <td style="border-top: none"></td>
              <td style="height: 20px; border-top: none; vertical-align: middle">
                <input type="number" name="accomodatedpatient" value="" [(ngModel)]="schedulingList[i].AccomodatedPatient" />
              </td>
              <td style="
                  border-top: none;
                  vertical-align: middle;
                  padding-right: 7px;
                ">
                <a (click)="addRow(i)" class="round-btn btn-primary text-white">+</a>
                <a style="margin-left: 4px" (click)="deleteRow(i)" class="round-btn btn-danger text-white">x</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-4">
        <table class="table sc-table table-striped sc-r" style="margin-bottom: 10px !important">
          <thead>
            <tr style="
                background-color: #fff !important;
                color: #fff;
                font-size: 14px;
              ">
              <th width="30%" align="center" valign="top">SN</th>
              <th width="30%" align="center" valign="top">Date</th>
              <th width="30%" align="center" valign="top">Doctor Available</th>
              <!-- <th width="40%" align="center" valign="top">End Time </th> -->
              <th style="display: none"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let list of scheduleDataList; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ list.Date }}</td>
              <td>
                <span class="btn-schedule">{{ list.StartTime }} - {{ list.EndTime }}</span>
              </td>
              <!-- <td> {{list.EndTime}}  </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="print-action mt-5 text-center m-auto d-block mb-5" style="margin: 15px auto !important">
      <input type="button" value="Submit" (click)="Submit()" />
    </div>
  </div>
  <!-- </div> -->
</div>