<meta name="viewport" content="width=device-width, initial-scale=1" />
<style>
  .telehighlight [type="checkbox"]:checked+label {
    border: 1px solid #009688;
    width: auto;
    padding: 8px 15px;
    color: #009688;
    border-radius: 30px;
    background-color: #009688;
    color: #fff;
  }

  .telehighlight [type="checkbox"]+label {
    border: 1px solid #009688;
    width: auto;
    padding: 8px 15px;
    color: #009688;
    border-radius: 30px;
  }

  .telehighlight label:before,
  .telehighlight label:after {
    visibility: hidden;
  }

  .telehighlight {
    min-width: auto;
  }

  /*.telehighlight input[ng-reflect-model=true]+label{
      color: #fff !important;
      background-color: #2196F3;
  }

  .telehighlight [type="checkbox"] + label {
    border: 1px solid #2196F3;
    width: auto;
    padding: 8px 15px;
    color: #2196F3;
    border-radius: 30px;
  }*/

  .telehighlight label:before,
  .telehighlight label:after {
    visibility: hidden;
  }

  .telehighlight {
    min-width: auto;
  }

  .tem-action {
    padding-left: 0px;
    margin: 25px auto;
    text-align: center;
  }

  .tem-action li {
    list-style: none;
    display: inline-block;
    margin: auto 5px;
  }

  .control-label {
    font-size: 14px;
  }

  .form-control {
    font-size: 14px;
  }

  .mat-form-field-infix {
    display: block;
    position: relative;
    flex: auto;
    min-width: 0;
    width: 250px !important;
    height: 50px;
    padding: 18px 0px !important;
    font-size: 16px;
    font-weight: 400;
  }

  /*div.col-md-7 {
    padding: 0px;
  }

  div.col-md-5 {
    padding: 0px;
  }

  div.col-md-4 {
    padding: 0px;
  }

  div.col-md-3 {
    padding: 0px;
  }*/
  .mt-checkbox {
    font-size: 14px;
  }

  div.col-xs-12 {
    padding-left: 10px;
    margin-left: 25px;
    margin-right: 15px;
  }

  /*This class for background border red */
  .bg-on {
    font-weight: bold;
    border: solid 1px red;
  }

  /*This class for */
  .bg-off {
    font-weight: bold;
  }

  #bookappttable {
    border-collapse: collapse;
    background-color: #66e0ff;
    width: 100%;
    /* bookappttable; */
  }

  #bookappttable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #00b3b3;
    color: white;
  }

  #bookappttable tr:nth-child(even) {
    background-color: #ccffff;
  }

  #bookappttable tr:hover {
    background-color: #e6ffff;
  }

  /*New stylesheet*/

  .payment-section {
    border-radius: 10px;
    width: 100%;
    margin: 15px auto;
    border: 1px solid #eee;
    padding: 25px;
    box-shadow: 0px 0px 20px #eee;
    background: #fff;
  }

  .select-status {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    flex-direction: row;
  }

  .one-third {
    display: inline-block;
    width: 30%;
    margin: 10px auto;
  }

  .select-status .one-third label {
    margin-bottom: 5px;
    display: block;
    color: #333;
    font-weight: 600;
  }

  .select-status-main .select-title {
    position: absolute;
    top: -15px;
    left: 15px;
    background: #fff;
    border: 1px solid #333;
    padding: 4px 10px;
    border-radius: 23px;
    font-weight: 700;
    letter-spacing: 0.3px;
    color: #333;
    font-size: 14px;
  }

  .select-status-main {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 7px;
    padding: 30px 20px;
    padding-bottom: 10px;
    margin: 30px auto;
    background: #fff;
  }

  .select-status-main .select-status .radio-button {
    margin-bottom: 1rem;
  }

  .select-status-main .select-status .radio-button__label-wrapper {
    border-radius: 50%;
    cursor: pointer;
    display: block;
    padding-left: 2.5rem;
    position: relative;
    top: 0.25rem;
  }

  .select-status-main .select-status .radio-button__label-wrapper:hover .radio-button__input~.radio-button__custom-indicator {
    border-color: black;
  }

  .select-status-main .select-status .radio-button__label-title {
    margin-bottom: 0.25rem;
    font-size: 14px;
    font-weight: 600;
    margin-top: 0;
  }

  .select-status-main .select-status .radio-button__label-subtext {
    display: block;
    font-size: 0.75rem;
  }

  .select-status-main .select-status .radio-button__custom-indicator {
    background-color: white;
    border: 1px solid #999;
    border-radius: 50%;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
  }

  .select-status-main .select-status .radio-button__custom-indicator::after {
    background-color: mediumseagreen;
    border-radius: 50%;
    content: "";
    display: none;
    height: 16px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 16px;
  }

  .select-status-main .select-status .radio-button__input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .select-status-main .select-status .radio-button__input:focus~.radio-button__custom-indicator {
    border-color: black;
  }

  .select-status-main .select-status .radio-button__input:checked~.radio-button__custom-indicator::after {
    display: block;
  }

  .select-status-main .select-status .radio-button__input:disabled~.radio-button__custom-indicator {
    opacity: 0.6;
    pointer-events: none;
  }

  .one-one.ml-42 {
    margin-left: 42px;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  select,
  input[type="number"] {
    height: 30px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #bbb;
    background: #fff;
    margin-bottom: 7px;
    display: block;
    max-width: 100%;
    padding: 0 2px;
  }

  .cc-selector input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .visa {
    background-image: url("./assets/img/visacard.jpg");
  }

  .mastercard {
    background-image: url("./assets/img/mastercard.jpg");
  }

  .paypal {
    background-image: url("./assets/img/paypal.jpg");
  }

  .american-express {
    background-image: url("./assets/img/americanexpress.jpg");
  }

  .cc-selector input:active+.drinkcard-cc {
    opacity: 0.5;
  }

  .cc-selector input:checked+.drinkcard-cc {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  .drinkcard-cc {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block !important;
    width: 50px;
    height: 40px;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: opacity(0.7);
    -moz-filter: opacity(0.7);
    filter: opacity(0.7);
  }

  .drinkcard-cc:hover {
    -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
    -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
    filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  }

  .mat-form-field-flex {
    background-color: white;
  }

  @media (max-width: 768px) {
    .payment-section {
      width: 100%;
      padding: 15px;
    }

    .select-status {
      flex-direction: column;
    }

    .select-status .one-third {
      width: 90%;
    }
  }

  /*New Stylesheet End*/
</style>

<app></app>
<bookingnavigator></bookingnavigator>
<button (click)="bookAppointmentforSelf()" [style.background-color]="bookforOther ? '#009688' : '#007bff'">Book Appointment For Patient* </button>
<button (click)="BookAppointmentforOther()" [style.background-color]="bookforOther ? '#007bff' : '#009688'">Book Appointment For Patient Family*</button>


<div *ngIf="this.docId == null || this.docId == '' " class="col-md-3">
  <div class="row">
    <label class="control-label col-md-12">Select Doctor:<span class="required-sign">*</span></label>
    <div class="col-md-12 select-doc-white">
      <mat-form-field class="mat-form-field-fluid select-input" appearance="outline">
        <mat-select placeholder="Select Doctor" (focusin)="DoctorDepartmentChanged()" (selectionChange)="SelectDoctorForViewDetail($event)">
          <mat-option *ngFor="let row of hospitalDoctor" [value]="row.DoctorId">{{row.DoctorName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="payment-section">
  <div class="full-width">
    <h4 class="title"><i class="fa fa-calendar" aria-hidden="true"></i>Book New Visit</h4>

    <div *ngIf="bookforOther && patFamilyList != null" class="select-status-main">
      <span class="select-title"><i class="fa fa-users" aria-hidden="true"></i>Patient</span>
      <label class="control-label mr-2">Select Family Member</label>
      <mat-form-field class="mat-form-field-fluid select-input" appearance="outline">
        <mat-select [(ngModel)]="selectedFamilyMember" (selectionChange)="SelectedMember($event)">
          <mat-option *ngFor="let p of patFamilyList" [value]="p">{{p.FirstName}} {{p.LastName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="appointDetail  " class="select-status-main">
      <span class="select-title"><i class="fa fa-calendar" aria-hidden="true"></i>Appointment</span>
      <div class="row select-status">
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Appointment Type</label>
            <div class="col-md-12">
              <mat-card class="no-shadow">{{AppointmentType}}</mat-card>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">
              Hospital<span class="required-sign">*</span></label>
            <div class="col-md-12">
              <mat-card class="no-shadow">{{hospital.HospitalName}}</mat-card>
              <!--<input type="text" name="HospitalName" value="" size="25" [(ngModel)]="hospital.HospitalName" disabled="disabled" />-->
            </div>
          </div>
        </div>
        <!--<div *ngIf="this.depId == null || this.depId == '' " class="col-md-3">
            <div class="row">
              <label class="control-label col-md-12"> Department:</label>
              <div class="col-md-12">
                <mat-form-field class="mat-form-field-fluid select-input" appearance="outline">
                  <mat-select placeholder="Select Department"
                              [(ngModel)]="selDepartment" (focusout)="DoctorDepartmentChanged()"
                              (focusin)="DoctorDepartmentChanged()" formControlName="DepartmentId">
                    <mat-option *ngFor="let list of hospitalDepartment"
                                [value]="list.DepartmentId">{{list.DepartmentName}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <span class="error-msg" *ngIf="visitObj.IsDirty('DepartmentId')" [hidden]="visitObj.IsValidCheck('DepartmentId','required')">Department is required</span>
              </div>
            </div>
          </div>-->
        <div *ngIf="this.depId!= null" class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">
              Department<span class="required-sign">*</span></label>
            <div class="col-md-12">
              <mat-card>{{deptName}}</mat-card>
            </div>
          </div>
        </div>
        <!--<div *ngIf="this.docId == null || this.docId == '' " class="col-md-3">
            <div class="row">
              <label class="control-label col-md-12">Doctor:</label>
              <div class="col-md-12">
                <mat-form-field class="mat-form-field-fluid select-input" appearance="outline">
                  <mat-select placeholder="Select Doctor"
                              [(ngModel)]="selDoctor" (focusout)="DoctorDepartmentChanged()" formControlName="DoctorId">
                    <mat-option *ngFor="let list of hospitalDoctor"
                                [value]="list.DoctorId">{{list.DoctorName}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <span class="error-msg" *ngIf="visitObj.IsDirty('DoctorId')" [hidden]="visitObj.IsValidCheck('DoctorId','required')">Doctor is required</span>
              </div>
            </div>
          </div>-->
        <div *ngIf="this.docId!= null" class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">
              Doctor<span class="required-sign">*</span></label>
            <div class="col-md-12">
              <mat-card>{{doctor.DoctorName}}</mat-card>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">

            <label class="control-label col-md-12">Booking Date</label>
            <div class="col-md-12">
              <input matInput [min]="todayDate" [matDatepicker]="disableTyping" [(ngModel)]="visitObj.VisitDate" disabled />
            </div>
          </div>
        </div>
        <!-- <div *ngIf="this.global.BookingTime != null" class="col-md-3">
            <div class="row">
              [formGroup]="visitObj.PatientValidator">
               <label class="control-label col-md-12"
                >Booking Date<span class="required-sign">*</span></label
              >
              <div class="col-md-12">
                <mat-form-field class="example-full-width">
                  <mat-label>Choose a Appointment Date</mat-label>
                  <input matInput [min]="todayDate" [matDatepicker]="picker" [(ngModel)]="visitObj.VisitDate" formControlName="VisitDate" (dateChange)="GetVisitTime($event)">
                  <input
                    matInput
                    [min]="todayDate"
                    [matDatepicker]="disableTyping"
                    [(ngModel)]="visitObj.VisitDate"
                    formControlName="VisitDate"
                    (dateChange)="GetVisitTime($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="disableTyping"
                    readonly
                  ></mat-datepicker-toggle>
                  <mat-datepicker #disableTyping></mat-datepicker>
                </mat-form-field>
                <span
                  class="error-msg"
                  *ngIf="visitObj.IsDirty('VisitDate')"
                  [hidden]="visitObj.IsValidCheck('VisitDate','required')"
                >
                  VisitDate is required</span
                >
                <span
                  class="error-msg"
                  *ngIf="visitObj.IsDirty('VisitDate')"
                  [hidden]="visitObj.IsValidCheck('VisitDate','invalidDate')"
                  >Enter Valid Date</span
                >
                <input type="date" name="DOB" [min]="todayDate" [(ngModel)]="visitObj.VisitDate" />
              </div>
            </div>
          </div> -->
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-3" *ngFor="let time of appointmentTime">
              <div class="example-button-row">
                <button mat-raised-button color="primary" (click)="setAppontmentTime(time)">
                  {{time.StartTime}} {{time.StartMeridian}} - {{time.EndTime}}
                  {{time.EndMeridian}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
        <p class="col-md-9" *ngIf="visitObj.BookingTime!=''">
          Selected Appointment Time: &nbsp;
          <mat-chip class="btn-sm btn-success" style="border-radius: 22px; margin-top: 5px; display: inline-block;"><strong style="font-size: 12px; color: #fff">{{visitObj.BookingTime}}</strong></mat-chip>
        </p>
      </div>
    </div>
    <!-- start Patient details-->
    <div class="select-status-main" *ngIf="!bookforOther">
      <span class="select-title"><i class="fa fa-calendar"></i>Patient Details</span>
      <div class="row select-status">
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">First Name</label>
            <div class="col-md-12">
              <!-- <mat-card>{{patinfo.FirstName}}</mat-card> -->
              <input type="text" name="FirstName" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="patinfo.FirstName" disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Middle Name</label>
            <div class="col-md-12">
              <input type="text" name="MiddleName" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="patinfo.MiddleName" disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Last Name</label>
            <div class="col-md-12">
              <input type="text" name="LastName" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="patinfo.LastName" disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Age</label>
            <div class="col-md-12">
              <input type="text" name="Age" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="patinfo.Age" disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Gender</label>
            <div class="col-md-12">
              <input type="text" name="Gender" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="patinfo.Gender" disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Address</label>
            <div class="col-md-12">
              <input type="text" name="Address" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="patinfo.Address" disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Contact Number</label>
            <div class="col-md-12">
              <input type="text" name="ContactNumber" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="patinfo.ContactNumber" disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="col-md-3" *ngIf="!bookforOther">
          <div class="row">

          </div>
        </div>
      </div>
    </div>

    <!--end Patient details -->
    <!-- start Family details  -->
    <div class="select-status-main" *ngIf="bookforOther">
      <span class="select-title"><i class="fa fa-calendar"></i>Patient Details</span>
      <div class="row select-status">
        <form [formGroup]="familyForm" style="display: contents;">
          <div class="col-md-3" *ngIf="patFamilydetailenable">
            <div class="row">
              <label class="control-label col-md-12">Relationship<span class="required-sign">*</span></label>
              <div class="col-md-12">
                <mat-form-field class="mat-form-field-fluid select-input" appearance="outline">
                  <mat-select placeholder="Relationship" [(ngModel)]="familyMember.Relationship" formControlName="Relationship">
                    <mat-option *ngFor="let r of Relationship" [value]="r.relation">{{r.relation}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-3" *ngIf="!patFamilydetailenable">
            <div class="row">
              <label class="control-label col-md-12">Relationship</label>
              <div class="col-md-12">
                <input type="text" name="FirstName" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="familyMember.Relationship" disabled="disabled" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <label class="control-label col-md-12">First Name<span class="required-sign">*</span></label>
              <div class="col-md-12">
                <input type="text" name="FirstName" value="" size="25" formControlName="FirstName" [(ngModel)]="familyMember.FirstName" [disabled]="!patFamilydetailenable" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <label class="control-label col-md-12">Middle Name</label>
              <div class="col-md-12">
                <input type="text" name="MiddleName" value="" size="25" formControlName="MiddleName" [(ngModel)]="familyMember.MiddleName" [disabled]="!patFamilydetailenable" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <label class="control-label col-md-12">Last Name<span class="required-sign">*</span></label>
              <div class="col-md-12">
                <input type="text" name="LastName" value="" size="25" formControlName="LastName" [(ngModel)]="familyMember.LastName" [disabled]="!patFamilydetailenable" />
              </div>
            </div>
          </div>
          <div class="col-md-3" *ngIf="patFamilydetailenable">
            <div class="row">
              <label class="control-label col-md-12">Date of Birth<span class="required-sign">*</span></label>
              <div class="col-md-12">
                <input [maxDate]="todayDate" placeholder="Datepicker" formControlName="DOB" name="DOB" [(ngModel)]="familyMember.DateOfBirth" bsDatepicker #d="bsDatepicker" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <div class="input-group-append">
                  <button class="fa fa-calendar" (click)="d.toggle()" [attr.aria-expanded]="d.isOpen" type="button"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3" *ngIf="patFamilydetailenable">
            <div class="row">
              <label class="control-label col-md-12">Gender<span class="required-sign">*</span></label>
              <div class="col-md-12">
                <input type="radio" value="Male" name="Gender" [(ngModel)]="familyMember.Gender" formControlName="Gender"> M<span class="hide-sm">ale &nbsp;&nbsp;</span>
                <input type="radio" value="Female" name="Gender" [(ngModel)]="familyMember.Gender" formControlName="Gender"> F<span class="hide-sm">emale&nbsp;&nbsp;</span>
                <input type="radio" value="Other" name="Gender" [(ngModel)]="familyMember.Gender" formControlName="Gender"> O<span class="hide-sm">ther&nbsp;&nbsp;</span>
              </div>
            </div>
          </div>
          <div class="col-md-3" *ngIf="!patFamilydetailenable">
            <div class="row">
              <label class="control-label col-md-12">Date of Birth<span class="required-sign">*</span></label>
              <div class="col-md-12">
                <input type="text" name="DOB" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="familyMember.DateOfBirth" [disabled]="!patFamilydetailenable" />
              </div>
            </div>
          </div>
          <div class="col-md-3" *ngIf="!patFamilydetailenable">
            <div class="row">
              <label class="control-label col-md-12">Gender<span class="required-sign">*</span></label>
              <div class="col-md-12">
                <input type="text" name="Gender" [ngModelOptions]="{standalone: true}" [(ngModel)]="familyMember.Gender" [disabled]="!patFamilydetailenable" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <label class="control-label col-md-12">Address</label>
              <div class="col-md-12">
                <input type="text" name="Address" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="familyMember.Address" [disabled]="!patFamilydetailenable" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <label class="control-label col-md-12">Contact Number<span class="required-sign">*</span></label>
              <div class="col-md-12">
                <input type="number" name="ContactNumber" value="" formControlName="ContactNumber" [(ngModel)]="familyMember.ContactNumber" [disabled]="!patFamilydetailenable" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="AppointmentType == 'Telemedicine'" class="select-status-main">
      <span class="select-title">
        <i class="fa fa-calendar" aria-hidden="true"></i>Vitals</span>
      <div class="row select-status">
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Height</label>
            <div class="col-md-12">
              <input type="text" name="FirstName" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="visitObj.Problem.Height" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Weight</label>
            <div class="col-md-12">
              <input type="text" name="MiddleName" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="visitObj.Problem.Weight" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Temperature</label>
            <div class="col-md-12">
              <input type="text" name="LastName" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="visitObj.Problem.Temperature" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">BP</label>
            <div class="col-md-12">
              <input type="text" name="Age" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="visitObj.Problem.BP" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">BMI</label>
            <div class="col-md-12">
              <input type="text" name="Gender" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="visitObj.Problem.BMI" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Body Pain</label>
            <div class="col-md-12">
              <input type="text" name="Address" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="visitObj.Problem.Bodypain" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="control-label col-md-12">Pain Scale</label>
            <div class="col-md-12">
              <input type="text" name="ContactNumber" value="" size="25" [ngModelOptions]="{standalone: true}" [(ngModel)]="visitObj.Problem.Painscale" />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">

          </div>
        </div>
      </div>
    </div>
    <div *ngIf="AppointmentType == 'Telemedicine'" class="select-status-main">
      <span class="select-title">
        <i class="fa fa-heartbeat" aria-hidden="true"></i>Medical History</span>
      <div class="select-status">
        <div class="one-one">
          <div class="problem">
            <table aria-label="History of Patient">
              <tr>
                <td colspan="3">
                  <label class="head-sm">Common Symptoms :</label>
                  <div class="form-group text-left">
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.Fever" type="checkbox" name="" id="fever" [ngModelOptions]="{standalone: true}" />
                      <label for="fever">Fever</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.Cough" type="checkbox" name="" id="cough" ng-reflect-model="true" [ngModelOptions]="{standalone: true}" />
                      <label for="cough">Cough</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.BreathingDifficulty" type="checkbox" name="" id="breath" [ngModelOptions]="{standalone: true}" />
                      <label for="breath">Breathing Difficulty</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.Tiredness" type="checkbox" name="" id="tiredness" [ngModelOptions]="{standalone: true}" />
                      <label for="tiredness">Tiredness</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.SoreThroat" type="checkbox" name="" id="soreThroat" [ngModelOptions]="{standalone: true}" />
                      <label for="soreThroat">Sore Throat</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.Bodyache" type="checkbox" name="" id="bodyache" [ngModelOptions]="{standalone: true}" />
                      <label for="bodyache">Bodyache</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.ChestPain" type="checkbox" name="" id="Chestpain" [ngModelOptions]="{standalone: true}" />
                      <label for="Chestpain">Chest Pain</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.Diarrhea" type="checkbox" name="" id="diarrhea" [ngModelOptions]="{standalone: true}" />
                      <label for="diarrhea">Diarrhoea</label>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <label>Any other symptoms :</label>
                  <textarea [(ngModel)]="visitObj.Problem.AnyOtherSymptoms" [ngModelOptions]="{standalone: true}" cols="50" rows="1" name=""></textarea>
                </td>
              </tr>
              <tr>
                <!-- <p class="fever-checkbox telehighlight">
                        <input [(ngModel)]="patientObj.Exposure" type="checkbox" name="" id="exposure"
                            [ngModelOptions]="{standalone: true}">
                        <label for="exposure">Exposure</label>
                    </p> -->
                <td colspan="3">
                  <label class="head-sm">Medical Issues :</label>
                  <div class="form-group text-left">
                    <!--  Fever :- <input [(ngModel)]="patientObj.Fever" type="checkbox" name="" id=""><br>
                            Cough:- <input [(ngModel)]="patientObj.Cough" type="checkbox" name="" id=""><br>
                            Breathing:- <input [(ngModel)]="patientObj.Breathing" type="checkbox" name="" id=""><br>
                        TiredNess <input [(ngModel)]="patientObj.TiredNess" type="checkbox" name="" id=""><br> -->
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.HeartDisease" type="checkbox" name="" id="heartDisease" [ngModelOptions]="{standalone: true}" />
                      <label for="heartDisease">Heart Disease</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.HighBloodPressure" type="checkbox" name="" id="highBloodPressure" [ngModelOptions]="{standalone: true}" />
                      <label for="highBloodPressure">High Blood Pressure</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.Diabetes" type="checkbox" name="" id="diabetes" [ngModelOptions]="{standalone: true}" />
                      <label for="diabetes">Diabetes</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.Copd" type="checkbox" name="" id="copd" [ngModelOptions]="{standalone: true}" />
                      <label for="copd">COPD/Asthma</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.Transplant" type="checkbox" name="" id="transplant" [ngModelOptions]="{standalone: true}" />
                      <label for="transplant">Transplant</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.Cancer" type="checkbox" name="" id="cancer" [ngModelOptions]="{standalone: true}" />
                      <label for="cancer">Cancer</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.RecentTravel" type="checkbox" name="" id="recentTravel" [ngModelOptions]="{standalone: true}" />
                      <label for="recentTravel"> Recent Travel</label>
                    </p>
                    <p class="fever-checkbox telehighlight">
                      <input [(ngModel)]="visitObj.Problem.Exposure" type="checkbox" name="" id="exposure" [ngModelOptions]="{standalone: true}" />
                      <label for="exposure"> Exposure to Covid Patient</label>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <!--  <td valign="top">
                        Any specific problems :-
                    </td> -->
                <td colspan="3">
                  <label>Other Pertinent Information:</label>
                  <textarea [(ngModel)]="visitObj.Problem.OtherPertientInformation" [ngModelOptions]="{standalone: true}" cols="50" rows="1"></textarea>
                </td>
              </tr>
              <!-- <tr>
                      <td colspan="3">
                          <label>Other Patient Information :</label>
                          <textarea [(ngModel)]="patientObj.OtherPatientInformation" [ngModelOptions]="{standalone: true}" cols="50" rows="1" name="OtherPatientInformation"
                              [ngModelOptions]="{standalone: true}"></textarea>
                      </td>
                  </tr>-->
            </table>
          </div>
        </div>
      </div>
    </div>

    <table aria-label="File Upload for Patient" class="table table-striped table-hover for-mobile" style="background-color: initial; margin-top: 3px">
      <thead class="d-sm-none d-md-block">
        <tr class="table-tr-style">
          <th>File Type</th>
          <th>Title</th>
          <th>File</th>
          <th>Comment</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let filePat of patFiles;let i = index;">
          <td>
            <label class="d-none d-sm-block d-md-none">File Type:</label>

            <select class="form-control" [(ngModel)]="patFiles[i].FileType" placeholder="File Type" [ngModelOptions]="{standalone: true}">
              <option *ngFor="let type of fileType" [ngValue]="type.name">{{type.name}} </option>
            </select>
          </td>
          <td>
            <label class="d-none d-sm-block d-md-none">Title:</label>
            <input type="text" class="form-control" [(ngModel)]="patFiles[i].Title" placeholder="File Title" [ngModelOptions]="{standalone: true}" />
          </td>
          <td>
            <label class="d-none d-sm-block d-md-none">File:</label>
            <input #file type="file" accept=".jpg,.JPG,.png,.PNG,.doc,.DOC,.docx,.DOCX,.pdf,.PDF" multiple (change)="upload(file.files, i)" name="fileName" [(ngModel)]="patFiles[i].FilePath" [ngModelOptions]="{standalone: true}" />
            <br />

            <span style="font-weight: bold; color: green" *ngIf="progress > 0 && progress < 100">
              {{progress}}%
            </span>
            <span style="font-weight: bold; color: green" *ngIf="index == i && message">
              {{message}}
            </span>
          </td>
          <td>
            <label class="d-none d-sm-block d-md-none">Comment:</label>
            <input type="text" class="form-control" [(ngModel)]="patFiles[i].Comment" placeholder="Comment" [ngModelOptions]="{standalone: true}" />
          </td>

          <td>
            <a (click)="addRow(i)" class="round-btn btn-primary text-white">+</a>
            <a style="margin-left: 10px" (click)="deleteRow(i)" class="round-btn btn-danger text-white">x</a>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="print-action mt-5">
      <input type="button" value="Submit" (click)="PostProblem()" class="btn" style="color: #fff" />
    </div>
  </div>
</div>
<footer></footer>

<style>
  .example-form {
    min-width: 150px;
    max-width: 500px;
    width: 100%;
  }

  .example-full-width {
    width: 100%;
  }
</style>