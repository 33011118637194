<app></app>

<div class="add-product-container">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="productForm">
      <div class="modal-header">
        <h5 class="modal-title">What product do you want to add?</h5>
        <button type="button" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="productForm">
          <ng-container [ngSwitch]="currentPageInForm">
            <h6><u>{{currentPageInForm}} Section</u></h6>
            <div *ngSwitchCase="pagesInForm.catalogAndSales">
              <div formGroupName="catalogGroup" class="form-group mt-2">
                <div class="d-grid">
                  <div class="product-image">
                    <div *ngIf="previewImgSrc">
                      <img alt="product-image" [src]="previewImgSrc" height="100" />
                    </div>
                    <div>
                      <input type="file" class="d-none" #file placeholder="Choose file" (change)="uploadFileLocally(file.files)" name="fileName">
                      <button type="button" *ngIf="!imageUploaded && !productImageExist" class="btn btn-sm btn-primary mx-1" (click)="file.click()"><em class="fa fa-upload"></em> Upload Image</button>
                      <button type="button" *ngIf="imageUploaded || productImageExist" class="btn btn-sm btn-primary mx-1" (click)="file.click()"><em class="fa fa-upload"></em> Change Image</button>
                      <button type="button" *ngIf="imageUploaded" class="btn btn-sm btn-danger mx-1" (click)="ClearImage()"><em class="fa fa-times"></em> Remove Image</button>
                      <div class="col-md-10 d-inline">
                        <span class="upload" *ngIf="progress > 0">
                          {{progress}}%
                        </span>
                        <span class="upload" *ngIf="message">
                          {{message}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="d-grid d-grid-row">
                    <div>
                      <label for="productName">Name of the product<span class="required-sign">*</span></label>
                      <input type="text" class="form-control" id="productName" aria-describedby="productName" formControlName="productName" [ngClass]="{'is-invalid': (productNameControl.touched || productNameControl.dirty) && productNameControl.errors }">
                      <div class="invalid-feedback" *ngIf="(productNameControl.touched || productNameControl.dirty)">
                        <span *ngIf="productNameControl.errors">Enter the Product Name.</span>
                      </div>
                    </div>
                    <div>
                      <label for="sku">Sku<span class="required-sign">*</span></label>
                      <input type="text" class="form-control" id="sku" aria-describedby="sku" formControlName="sku" [ngClass]="{'is-invalid': (skuControl.touched || skuControl.dirty) && skuControl.errors }">
                      <div class="invalid-feedback" *ngIf="(skuControl.touched || skuControl.dirty)">
                        <span *ngIf="skuControl.errors">Enter the Sku.</span>
                      </div>
                    </div>
                    <div>
                      <label for="manufacturerPartNo">Manufacturer Part No.</label>
                      <input type="text" class="form-control" id="manufacturerPartNo" aria-describedby="manufacturerPartNo" formControlName="manufacturerPartNo">
                    </div>
                    <div>
                      <input type="checkbox" class="form-check-input" id="isRefundable" formControlName="isRefundable">
                      <label class="form-check-label" for="isRefundable">Refundable</label>
                    </div>
                  </div>
                  <div class="d-grid d-grid-row">
                    <div>
                      <label for="productType">Product Type<span class="required-sign">*</span></label>
                      <select class="custom-select" id="productType" aria-describedby="productType" formControlName="productType">
                        <option *ngFor="let list of productService.ProductTypesList" [value]="list.Type"> {{list.Type}}</option>
                      </select>
                    </div>
                    <div>
                      <label for="groupingLabel">Grouping Label</label>
                      <input type="text" class="form-control" id="groupingLabel" aria-describedby="groupingLabel" formControlName="groupingLabel">
                    </div>
                    <div>
                      <label for="productTag">Product Tag</label>
                      <input type="text" class="form-control" id="productTag" aria-describedby="tagHelp" formControlName="productTag">
                      <small id="tagHelp" class="form-text text-muted">Separate tags with comma ','</small>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div formGroupName="salesGroup" class="form-group mt-2 mx-5">
                <div class="d-grid d-sales-grid">
                  <div>
                    <label for="newPrice">New Price<span class="required-sign">*</span></label>
                    <input type="number" #newPrice (wheel)="newPrice.blur();" class="form-control" id="newPrice" aria-describedby="newPrice" formControlName="newPrice" [ngClass]="{'is-invalid': (newPriceControl.touched || newPriceControl.dirty) && newPriceControl.errors }">
                    <div class="invalid-feedback" *ngIf="(newPriceControl.touched || newPriceControl.dirty)">
                      <span *ngIf="newPriceControl.errors">Enter the newPrice.</span>
                    </div>
                  </div>
                  <div>
                    <label for="oldPrice">Old Price</label>
                    <input type="number" #oldPrice (wheel)="oldPrice.blur();" class="form-control" id="oldPrice" aria-describedby="oldPrice" formControlName="oldPrice">
                  </div>
                </div>
              </div>
              <hr>
              <div formGroupName="catalogGroup" class="form-group mt-2">

                <div class="d-grid d-grid-row mt-5 mx-5">
                  <div>
                    <label for="shortDescription">Short Description</label>
                    <textarea name="shortDescription" class="form-control" id="shortDescription" formControlName="shortDescription" cols="10" rows="1"></textarea>
                  </div>
                  <div>
                    <label for="fullDescription">Full Description</label>
                    <textarea name="fullDescription" class="form-control" id="fullDescription" formControlName="fullDescription" cols="10" rows="4"></textarea>
                  </div>
                  <div>
                    <label for="adminComments">Admin Comments</label>
                    <textarea name="adminComments" class="form-control" id="adminComments" formControlName="adminComments" cols="10" rows="1"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="pagesInForm.display">
              <div formGroupName="displayGroup" class="form-group mt-2 mx-5">
                <div class="d-grid mb-2">
                  <div>
                    <div class="mb-2">
                      <input type="checkbox" class="form-check-input" id="visibleIndividually" formControlName="visibleIndividually">
                      <label class="form-check-label" for="visibleIndividually">Visible Individually</label>
                    </div>
                    <div class="mb-2">
                      <input type="checkbox" class="form-check-input" id="showProductOnHomePage" formControlName="showProductOnHomePage">
                      <label class="form-check-label" for="showProductOnHomePage">Show Product On HomePage</label>
                    </div>
                    <div class="mb-2">
                      <input type="checkbox" class="form-check-input" id="disableBuyButton" formControlName="disableBuyButton">
                      <label class="form-check-label" for="disableBuyButton">Disable Buy Button</label>
                    </div>
                    <div class="mb-2">
                      <input type="checkbox" class="form-check-input" id="markAsNew" formControlName="markAsNew">
                      <label class="form-check-label" for="markAsNew">Mark As New</label>
                    </div>
                    <div class="d-grid mb-2" *ngIf="productForm.get('displayGroup.markAsNew').value">
                      <div>
                        <label class="form-check-label" for="markAsNewStartDate">Mark As New Start Date<span class="required-sign">*</span></label>
                        <input type="date" id="markAsNewStartDate" formControlName="markAsNewStartDate">
                        <div class="invalid-feedback" *ngIf="(productForm.get('displayGroup.markAsNewStartDate').touched || productForm.get('displayGroup.markAsNewStartDate').dirty)">
                          <span *ngIf="productForm.get('displayGroup.markAsNewStartDate').errors">Enter the Start Date.</span>
                        </div>
                      </div>
                      <div>
                        <label class="form-check-label" for="markAsNewEndDate">Mark As New End Date<span class="required-sign">*</span></label>
                        <input type="date" id="markAsNewEndDate" formControlName="markAsNewEndDate">
                        <div class="invalid-feedback" *ngIf="(productForm.get('displayGroup.markAsNewEndDate').touched || productForm.get('displayGroup.markAsNewEndDate').dirty)">
                          <span *ngIf="productForm.get('displayGroup.markAsNewEndDate').errors">Enter the End Date.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="displayOrder">Display Order</label>
                      <input type="number" #displayOrder (wheel)="displayOrder.blur();" class="form-control" id="displayOrder" aria-describedby="displayOrder" formControlName="displayOrder">
                    </div>
                  </div>
                </div>
                <hr>
                <!-- Child Products Section -->
                <div class="d-grid d-grid-row" *ngIf="productTypeControl.value == 'Grouped'">
                  <h3>Child Product Section</h3>
                  <div>
                    <input matInput
                      type="text"
                      class="form-control"
                      id="childProductSearchKeyword"
                      aria-label="childProductSearchKeyword"
                      placeholder="Search to add child products."
                      formControlName="childProductSearchKeyword"
                      [matAutocomplete]="childProducts"
                      [ngClass]="{'is-invalid': (productForm.get('displayGroup.childProductSearchKeyword').touched || productForm.get('displayGroup.childProductSearchKeyword').dirty) && productForm.get('displayGroup.childProducts').errors }"
                    />
                    <mat-autocomplete #childProducts="matAutocomplete"
                      [displayWith]="childProductDisplayFunction"
                      (optionSelected)="addChildProduct($event)"
                    >
                      <mat-option *ngFor="let option of filteredProductsForMatAutocomplete | async" [value]="option">
                        {{option.Name }} - SKU : {{option.Sku}} - Price: {{option.Price}}
                      </mat-option>
                    </mat-autocomplete>
                    <div class="invalid-feedback" *ngIf="(productForm.get('displayGroup.childProductSearchKeyword').touched || productForm.get('displayGroup.childProductSearchKeyword').dirty)">
                      <span *ngIf="productForm.get('displayGroup.childProducts')?.errors">To add a grouped product, please insert child products in display section.</span>
                    </div>
                  </div>

                  <div class="child-product-table-container">
                    <table #childProductTable mat-table cdkDropList
                      class="childProductTable"
                      aria-describedby="childProducts"
                      [dataSource]="childProductsValues"
                      cdkDropList
                      [cdkDropListData]="childProductsValues"
                      (cdkDropListDropped)="onListDrop($event)"
                    >
                      <ng-container matColumnDef="sku">
                        <th scope="col" mat-header-cell *matHeaderCellDef> Sku </th>
                        <td mat-cell *matCellDef="let product">
                          {{product.sku}}
                          <em cdkDragHandle class="fa fa-bars"></em>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="name">
                        <th scope="col" mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let product">
                          {{product.name}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="price">
                        <th scope="col" mat-header-cell *matHeaderCellDef> Price </th>
                        <td mat-cell *matCellDef="let product"> {{product.price}} </td>
                      </ng-container>
                      <ng-container matColumnDef="action">
                        <th scope="col" mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let product; let i = index"> <em title="RemoveItem" class="removeChildProductIcon fa fa-times" (click)="removeChildProductAt(i)"></em> </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="['sku','name','price','action']"></tr>
                      <tr id="row-{{product.name}}" mat-row *matRowDef="let product; columns: ['sku','name','price','action'];" cdkDrag [cdkDragData]="product"></tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="pagesInForm.seo">
              <div formGroupName="seoGroup" class="form-group mt-2 mx-5">
                <div class="d-grid d-seo-grid">
                  <div>
                    <label for="permalink">Permalink<span class="required-sign">*</span></label>
                    <input type="text" class="form-control" id="permalink" aria-describedby="permalink" formControlName="permalink" [ngClass]="{'is-invalid': (permalinkControl.touched || permalinkControl.dirty) && permalinkControl.errors }">
                    <div class="invalid-feedback" *ngIf="(permalinkControl.touched || permalinkControl.dirty)">
                      <span *ngIf="permalinkControl.errors">Enter the Product Name.</span>
                    </div>
                  </div>
                  <div>
                    <label for="metaTitle">Meta Title</label>
                    <input type="text" class="form-control" id="metaTitle" aria-describedby="metaTitle" formControlName="metaTitle">
                  </div>
                  <div>
                    <label for="metaKeyword">Meta Keyword</label>
                    <input type="text" class="form-control" id="metaKeyword" aria-describedby="metaKeyword" formControlName="metaKeyword">
                  </div>
                  <div>
                    <label for="metaDescription">Meta Description</label>
                    <input type="text" class="form-control" id="metaDescription" aria-describedby="metaDescription" formControlName="metaDescription">
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="goToPreviousPage()">{{currentPageInForm == pagesInForm.catalogAndSales ? 'Close' : 'Go Back'}}</button>
        <button type="button" class="btn btn-primary" (click)="goToNextPage()">{{currentPageInForm == pagesInForm.seo ? 'Save' : 'Next'}}</button>
        <button type="button" class="btn btn-primary" (click)="addProduct()" *ngIf="(currentPageInForm == pagesInForm.catalogAndSales) && productTypeControl.value == 'Single'">Quick Save</button>
      </div>
    </div>
  </div>
</div>






<div class="powered-section">
  <span>Powered By</span>
  <div class="login-brand">
    <img alt="imark logo" src="../assets/img/imark-powered.png">
  </div>
</div>
