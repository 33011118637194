<app></app>
<button [routerLink]="['/PatDashboard']">Back To DashBoard </button>
<div *ngFor="let row of patients" class="wait-section" style="height: auto;">
	<div class="report-title" id="Report">
		<div class="flex-report-title">
			<div class="img-d" style="margin: auto; text-align:center; margin-bottom: 15px;">
				<img src="assets/img/hospital.png" height="50">
			</div>
			<table align="center" style="width: 100%;">
				<tr>
					<td align="center" style="font-size: 20px">Report of : {{row.PatientName}}</td>
				</tr>
				<tr>
					<td align="center" style="font-size: 20px">Ref Doctor : {{row.DoctorName}}</td>
				</tr>

			</table>
		</div>
		<h4 class="modal-title w-100 font-weight-bold" style="text-align: center; margin-bottom: 20px; font-size: 17px;">Basic Information</h4>
		<div class="card report-card">
			<table style="width: 100%;">
				<tr>
					<td align="left">Patient Name : {{row.PatientName}}</td>
					<td align="center">Age : {{row.Age}}</td>
					<td align="right">Sex : {{row.Gender}}</td>
				</tr>
				<tr>
					<td align="left">Email : {{row.MailingAddress}}</td>
					<td align="center">Mobile Number : {{row.ContactNumber}}</td>
					<td align="right">NMC Number of Doctor: {{row.NMC}}</td>
				</tr>
			</table>
			<!-- <div class="card-body">
			<div class="row">
				<div class="col-md-4 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Patient Name : {{row.PatientName}}</div>
				<div class="col-md-4 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Age : {{row.Age}}</div>
				<div class="col-md-4 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Sex : {{row.Gender}}</div>
				<div class="col-md-4 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Email : {{row.MailingAddress}}</div>
				<div class="col-md-4 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Mobile Number : {{row.ContactNumber}}</div>
				<div class="col-md-4 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>NMC Number of Doctor: {{row.NMC}}</div>
			</div>
		</div> -->
		</div>
		<div *ngIf="!IsDanpheCare" class="card report-card">

			<div class="card-body">
				<div class="row">
					<div class="col-md-12 notes-prescription">
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">Medical Status</legend>
							<table style="width: 100%;">
								<tr>
									<td *ngIf="row.Fever == true" align="left">Fever : {{row.Fever| yesNoPipe}}</td>
									<td *ngIf="row.Cough == true" align="center">Cough : {{row.Cough| yesNoPipe}}</td>
									<td *ngIf="row.BreathingDifficulty == true" align="right">BreathingDifficulty : {{row.BreathingDifficulty| yesNoPipe}}</td>
								</tr>
								<tr>
									<td *ngIf="row.Tiredness == true" align="left">Tiredness : {{row.Tiredness| yesNoPipe}}</td>
									<td *ngIf="row.SoreThroat == true" align="center">Sore Throat : {{row.SoreThroat| yesNoPipe}}</td>
									<td *ngIf="row.Bodyache == true" align="right">Bodyache : {{row.Bodyache| yesNoPipe}}</td>
								</tr>
								<tr>
									<td *ngIf="row.ChestPain == true" align="left">ChestPain : {{row.ChestPain| yesNoPipe}}</td>
									<td *ngIf="row.Diarrhea == true" align="center">Diarrhea : {{row.Diarrhea| yesNoPipe}}</td>
									<td *ngIf="row.HeartDisease == true" align="right">Heart Disease : {{row.HeartDisease| yesNoPipe}}</td>
								</tr>
								<tr>
									<td *ngIf="row.Diabetes == true" align="left">Diabetes : {{row.Diabetes| yesNoPipe}}</td>
									<td *ngIf="row.Copd == true" align="center">Copd/Asthma : {{row.Copd| yesNoPipe}}</td>
									<td *ngIf="row.Transplant == true" align="right">Transplant : {{row.Transplant| yesNoPipe}}</td>
								</tr>
								<tr>
									<td *ngIf="row.RecentTravel == true" align="left">Recent Travel : {{row.RecentTravel| yesNoPipe}}</td>
									<td *ngIf="row.Cancer == true" align="center">Cancer : {{row.Cancer| yesNoPipe}}</td>
									<td *ngIf="row.Exposure == true" align="right">Exposure to Covid patients : {{row.Exposure| yesNoPipe}}</td>
								</tr>
							</table>
						</fieldset>
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">Any Others Symptoms</legend>
							<p>{{row.AnyOtherSymptoms}}</p>
						</fieldset>
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">Other Pertinent Information</legend>
							<p>{{row.OtherPertientInformation}}</p>
						</fieldset>
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">Treatment Advice given by doctor</legend>
							<p>{{row.TreatmentAdvice}}</p>
						</fieldset>
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">Medication given by doctor</legend>
							<p>{{row.Medication}}</p>
						</fieldset>
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">FollowUp</legend>
							<p>{{row.FollowUp}}</p>
						</fieldset>

					</div>
					<!-- <div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Fever : {{row.Fever| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Cough : {{row.Cough| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>BreathingDifficulty : {{row.BreathingDifficulty| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Tiredness : {{row.Tiredness| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Sore Throat : {{row.SoreThroat| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Bodyache : {{row.Bodyache| yesNoPipe}} </div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>ChestPain : {{row.ChestPain| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Diarrhea : {{row.Diarrhea| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Heart Disease : {{row.HeartDisease| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>High Blood Pressure : {{row.HighBloodPressure| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Diabetes : {{row.Diabetes| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Copd/Asthma : {{row.Copd| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Transplant : {{row.Transplant| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Recent Travel : {{row.RecentTravel| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Cancer : {{row.Cancer| yesNoPipe}}</div>
				<div class="col-md-3 col-sm-6 col-xs-12 mt-3"><i class="fa fa-info-circle"></i>Exposure to Covid patients : {{row.Exposure| yesNoPipe}}</div> -->
				</div>
			</div>
		</div>

		<div *ngIf="IsDanpheCare" class="card report-card">

			<div class="card-body">
				<div class="row">
					<div class="col-md-12 notes-prescription">
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">Any Others Symptoms</legend>
							<p>{{row.AnyOtherSymptoms}}</p>
						</fieldset>
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">Other Pertinent Information</legend>
							<p>{{row.OtherPertientInformation}}</p>
						</fieldset>
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">Treatment Advice given by doctor</legend>
							<p>{{row.TreatmentAdvice}}</p>
						</fieldset>
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">Medication given by doctor</legend>
							<p>{{row.Medication}}</p>
						</fieldset>
						<fieldset style="margin-bottom: 25px;">
							<legend style="font-weight: 600;">FollowUp</legend>
							<p>{{row.FollowUp}}</p>
						</fieldset>
						</div>
					</div>
				</div>

						<!-- <div class="card report-card">
						<div class="card-title">
							<span class="">Any Others Symptoms</span>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-xs-12">{{row.AnyOtherSymptoms}}</div>

							</div>
						</div>
					</div> -->
						<!-- <div class="card report-card">
						<div class="card-title">
							<span class="">Other Pertinent Information</span>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-xs-12">{{row.OtherPertientInformation}}</div>

							</div>
						</div>
					</div> -->
						<!-- <div class="card report-card">
						<div class="card-title">
							<span class="">Treatment Advice given by doctor</span>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-xs-12">{{row.TreatmentAdvice}}</div>

							</div>
						</div>
					</div> -->
						<!-- <div class="card report-card">
						<div class="card-title">
							<span class="">Medication given by doctor</span>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-xs-12">{{row.Medication}}</div>

							</div>
						</div>
					</div> -->
						<!-- <div class="card report-card">
						<div class="card-title">
							<span class="">FollowUp</span>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-12 col-sm-12 col-xs-12">{{row.FollowUp}}</div>

							</div>
						</div>
					</div> -->

					</div>
					<div class="print-action">
						<input type="button" value="Print Report" (click)="Print()">
					</div>
				</div>
	</div>
