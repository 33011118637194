<style>
  .scheduleList:hover {
    background-color: #721bb9 !important;
  }

  .doctorAvailableListClass {
    border-radius: 100%;
    background-color: #86dfd6;
    color: white;
  }
</style>

<mat-dialog-actions align="left">
  <button mat-button color="primary" style="position: absolute; right: -27px; top: -15px;font-size: 20px;" matDialogClose><i class="fa fa-times"></i></button>
</mat-dialog-actions>
<div class="alert alert-default" style="margin: 25px; margin-top: 0">
  <h4><strong><i class="fa fa-exclamation-circle"></i>Your Step by Step Guide</strong></h4>
  <ul style="padding-left: 15px; font-size: 13px;">
    <li>
      First select the Appointment Type.
    </li>
    <li>
      Select the Date you want Schedule from Calander.
    </li>
    <li>
      After that choose the Date you want Schedule to the Patient.
    </li>
    <li>
      Choose the respective time-slot for appointment.
    </li>
  </ul>
</div>
<div class="col-12">
  <form [formGroup]="visitObj.PatientValidator">
    <div class="row">
      <div class="col-md-3">
        <label>Appointment Type</label>
        <mat-form-field class="mat-form-flud select-input" appearance="outline" style="width: 100%;">
          <mat-select placeholder="Select Appointment Type" [(ngModel)]="selAppointmentType" [ngModelOptions]="{standalone:true}" (selectionChange)="AssignAppointmentType($event)">
            <mat-option value="Telemedicine">Telemedicine</mat-option>
            <mat-option value="Online Appointment">Online Appointment</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <label>Doctor:</label>
        <mat-form-field class="mat-form-field-fluid select-input" appearance="outline" style="width: 100%;">
          <mat-select placeholder="Select Doctor" [(ngModel)]="selDoctor" [ngModelOptions]="{standalone: true}" (selectionChange)="AssignDoctor($event)">
            <mat-option *ngFor="let doctor of filteredDoctorList" [value]="doctor.DoctorId">{{doctor.DoctorName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <label>Hospital:</label>
        <mat-form-field class="mat-form-field-fluid select-input" appearance="outline" style="width: 100%;">
          <mat-select placeholder="Select Hospital" [(ngModel)]="selHospitalId" [ngModelOptions]="{standalone: true}" (selectionChange)="AssignHospital($event)">
            <mat-option *ngFor="let hospital of docHospitalList" [value]="hospital.HospitalId">{{hospital.HospitalName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <label>Department:</label>
        <mat-form-field class="mat-form-field-fluid select-input" appearance="outline" style="width: 100%;">
          <mat-select placeholder="Select Department" (selectionChange)="AssignDepartment($event)">
            <mat-option *ngFor="let department of filterdeptList" [value]="department.DepartmentId">{{department.DepartmentName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <!-- <div class="col-md-8"> -->
        <label class="control-label col-md-8">Booking Date:</label>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Choose a Appointment Date</mat-label>
            <input matInput [min]="todayDate" [matDatepicker]="picker" [(ngModel)]="visitObj.VisitDate" formControlName="VisitDate" (dateChange)="GetVisitTime($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker [dateClass]="doctorAvailableListClass" #picker></mat-datepicker>
          </mat-form-field>
          <span class="error-msg" *ngIf="visitObj.IsDirty('VisitDate')" [hidden]="visitObj.IsValidCheck('VisitDate','required')"> VisitDate is required</span>
          <span class="error-msg" *ngIf="visitObj.IsDirty('VisitDate')" [hidden]="visitObj.IsValidCheck('VisitDate','invalidDate')">Enter Valid Date</span>
        </div>
      </div>
      <div class="col-md-8">
        <div *ngIf="!isSchedulingAvailable" style="color: rgb(231, 100, 100);">
          The Doctor is not available in the current department.
        </div>

      </div>
      <!-- KC</div> -->
    </div>
  </form>
</div>
<!-- <div class="col-12"  >
          <p > Please! Select Appointment Time Slot</p>
        </div> -->
<div *ngFor="let time of appointmentTime" style="display:flex; flex-direction: row;">
  <div class="example-button-row">
    <button *ngIf="showIntervalButton" mat-raised-button color="success" (click)="checkTimeSlot(time.SchedulingId)" style="font-size: 12px; width: auto;margin: 7px auto; border-radius: 30px"><i class="fa fa-clock-o"></i>Schedule
      Interval: {{time.StartTime}} - {{time.EndTime}} </button><br>
    <div *ngFor="let list of scheduleIntervalList" style="display: inline-flex;align-items: center;justify-content: space-around; margin: 2px">
      <div *ngIf="showIntervalSlot  && time.SchedulingId == list.SchedulingId" class="example-button-row" style="width: 80px">
        <button class="scheduleList" *ngIf="list.IsBooked == false && list.selectedTime == false" mat-raised-button color="primary" (click)="setAppointmentTime(list)" style="font-size: 12px; width: 100%;margin: 7px auto; border-radius: 30px; font-weight:bold">
          {{list.StartTime}} </button>
        <button *ngIf="list.selectedTime == true && list.IsBooked == false" mat-raised-button color="primary" (click)="setAppointmentTime(list)" style="font-size: 12px; width: 100%;margin: 7px auto; border-radius: 30px; font-weight:bold; background-color: #721bb9 !important; ">
          {{list.StartTime}} </button>
        <button *ngIf="list.IsBooked == true" mat-raised-button color="warn" (click)="setAppointmentTime(list)" style="font-size: 12px;  width: 100%;margin: 7px auto; border-radius: 30px ; font-weight:bold">{{list.StartTime}}
        </button>
      </div>
    </div>
  </div>
</div>


<mat-dialog-actions align="end">
  <div *ngIf="showRescheduleBtn" style="margin: 15px auto !important;">
    <div *ngIf="showIntervalSlot">Selected time interval: {{visitObj.BookingTime}}</div>
    <div *ngIf="!showIntervalSlot">Selected Appointment Date {{visitObj.VisitDate | date}}</div>
    <input style="margin-right: 70px;" type="button" value="Reschedule" (click)="Reschedule()">
  </div>

</mat-dialog-actions>