import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndpointFactory } from '../endpoint-factory.service';
import { catchError } from 'rxjs/operators';
import { ProductModel } from 'src/app/models/Product/product.model';
import _ from 'lodash';
import { Observable, throwError } from 'rxjs';


@Injectable()
export class ProductEndPoint extends EndpointFactory {

    private readonly _getAllProductList: string = "/api/product/GetProductsList";
    private readonly _getProductDetailsById: string = "/api/product/GetProductById";
    private readonly _addNewProduct: string = "/api/product/AddProduct";
    private readonly _addProductType: string = "/api/product/AddProductType";
    private readonly _addProductTag: string = "/api/product/AddProductTag";
    private readonly _editProduct: string = "/api/product/EditProduct/";
    private readonly _activateDeactivateProduct: string = "/api/product/ActivateDeactivateProduct/"
    private readonly _getProductImageById: string = "/api/product/GetProductPictureById";
    private readonly _getAllProductTypes: string = "/api/product/GetAllProductTypes"
    private readonly _getAllProductTags: string = "/api/product/GetAllProductTags"

    constructor(http: HttpClient, injector: Injector) {
        super(http, injector);
    }
    getProductByIdEndpoint<T>(ProductId: number): Observable<T> {
        let endpointUrl = `${this._getProductDetailsById}/${ProductId}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
            catchError(error => {
                return throwError(this.handleError(error, () => this.getProductByIdEndpoint(ProductId)));
            }));
    }

    getAllProducts<T>(): Observable<T> {

        let endpointUrl = `${this._getAllProductList}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
            catchError(error => {
                return throwError(this.handleError(error, () => this.getAllProducts()));
            }));
    }

    AddProduct<T>(formData: FormData): Observable<T> {
        let endpointUrl = `${this._addNewProduct}`;
        // let header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        return this.http.post<T>(endpointUrl, formData).pipe(
            catchError(error => {
                return throwError(this.handleError(error, () => this.AddProduct(formData)));
            })
        );
    }
    AddProductType<T>(formData: FormData): Observable<T> {
        let endpointUrl = `${this._addProductType}`;
        // let header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        return this.http.post<T>(endpointUrl, JSON.stringify(formData)).pipe(
            catchError(error => {
                return throwError(this.handleError(error, () => this.AddProductType(formData)));
            })
        );
    }
    AddProductTag<T>(formData: FormData): Observable<T> {
        let endpointUrl = `${this._addProductTag}`;
        // let header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        return this.http.post<T>(endpointUrl, JSON.stringify(formData)).pipe(
            catchError(error => {
                return throwError(this.handleError(error, () => this.AddProductTag(formData)));
            })
        );
    }

    getAllProductTypes<T>(): Observable<T> {
        let endpointUrl = `${this._getAllProductTypes}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
            catchError(err => {
                return throwError(this.handleError(err, () => this.getAllProductTypes()));
            }));
    }
    getProductImageByIdEndpoint<T>(productImageId): Observable<T> {
        let endpointUrl = `${this._getProductImageById}/${productImageId}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
            catchError(error => {
                return throwError(this.handleError(error, () => this.getProductByIdEndpoint(productImageId)));
            }));
    }

    getAllProductTags<T>(): Observable<T> {

        let endpointUrl = `${this._getAllProductTags}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe(
            catchError(err => {
                return throwError(this.handleError(err, () => this.getAllProductTags()));
            })
        );
    }

    activateDeactivateProductEndpoint<T>(productId: number, AdminId: string): Observable<T> {
        let endpointUrl = `${this._activateDeactivateProduct}${productId}/${AdminId}`;
        return this.http.put<T>(endpointUrl, this.getRequestHeaders()).pipe(
            catchError(err => {
                return throwError(this.handleError(err, () => this.activateDeactivateProductEndpoint(productId,AdminId)));
            })

        );
    }

    updateProductEndPoint<T>(formData: FormData, productId): Observable<T> {
        let endpointUrl = `${this._editProduct}${productId}`;
        return this.http.put<T>(endpointUrl, formData).pipe(
            catchError(err => {
                return throwError(this.handleError(err, () => this.updateProductEndPoint(formData, productId)));
            })

        );
    }

}
