import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { PatientService } from '../../services/patient/patient.service';
import { AuthenticationService } from '../../services/authentication.service';
import { HospitalService } from '../../services/hospital/hospital.service';
import { Global } from '../../app.global';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { PatientMedicalRecord } from '../../models/patient/Pat-medicalRecord.model';
import { AlertService } from 'src/app/services/alert.service';
import { ChatService } from 'src/app/services/ChatNotification/chat-notification.service';
import { Patient } from 'src/app/models/patient/patient.model';
import { DoctorService } from 'src/app/services/doctor/doctor.service';

@Component({
  templateUrl: './app.patient_helpdesk.html'
})
export class PatientHelpDeskComponent {

  public hospitalId: any;
  ChatForm: FormGroup;
  public patInfo: Patient = new Patient();
  public receiverId: string;
  ChatMessages: Array<any> = new Array<any>();
  AllUserChats: any;
  ChatReceivedMessages: Array<any> = new Array<any>();
  token: any;
  public adminId: any;
  constructor(
    public httpClient: HttpClient, private alertService: AlertService, public docService: DoctorService,
    public routing: Router, private authenticationService: AuthenticationService,
    public patService: PatientService, public notificationService: ChatService
  ) {
    this.token = this.authenticationService.currentUserValue;
    this.getPatientName();
    this.getAdminId();
    this.initForm();
    this.notificationService.Connect();
    this.notificationService.EventChatMessage.subscribe(chatData => {
      this.OnChatUserChange();

      const chatMsg = { Name: chatData.Name, Message: chatData.Message, Class: 'receiver-msg' };
      this.ChatMessages.push(chatMsg);
      this.pushChatMsgUserwise(chatData.Name, chatMsg);
    });
  }

  pushChatMsgUserwise(user, messageObj) {
    try {
      if (!this.AllUserChats.hasOwnProperty(user)) {
        this.AllUserChats[user] = new Array<any>();
      }
      this.AllUserChats[user].push(messageObj);
    } catch (e) { }
  }

  public BackDashBoard() {
    this.routing.navigate(['/PatDashboard'], { queryParams: { id: this.hospitalId } });
  }
  private initForm() {
    this.ChatForm = new FormGroup(
      {
        chatMessage: new FormControl('', [Validators.required])
      });
  }
  hasError(typeofvalidator: string, controlname: string): boolean {
    const control = this.ChatForm.controls[controlname];
    return control.hasError(typeofvalidator) && control.dirty;
  }
  SendChatMsg() {
    try {
      if (this.chatMessageToSend.value)
        this.chatMessageToSend.setValue(this.chatMessageToSend.value.trim());

      for (const i in this.ChatForm.controls) {
        this.ChatForm.controls[i].markAsDirty();
        this.ChatForm.controls[i].updateValueAndValidity();
      }

      if (this.ChatForm.valid) {
        const chatMsg = {
          SenderId: this.patInfo.IdentityUserId,
          ReceiverId: this.adminId,
          Name: `${this.patInfo.FirstName} ${this.patInfo.MiddleName ? this.patInfo.MiddleName : ""} ${this.patInfo.LastName}`,
          Message: this.chatMessageToSend.value
        };
        const chatmsgObj = { Name: 'Me', Message: chatMsg.Message, Class: 'sender-msg' };
        this.ChatMessages.push(chatmsgObj);
        this.notificationService.SendChatMessage(chatMsg);
        this.ChatForm.reset();
      }
    } catch (e) { }
  }

  private get chatMessageToSend() {
    return this.ChatForm.get("chatMessage") as FormControl;
  }

  OnChatUserChange() {
    try {
      const selUser = this.ChatForm.controls['Id'].value;
      if (this.AllUserChats.hasOwnProperty(selUser)) {
        this.ChatMessages = this.AllUserChats[selUser].slice();
        this.ChatReceivedMessages = this.AllUserChats[selUser].slice();
      } else {
        this.ChatMessages = new Array<any>();
        this.ChatReceivedMessages = new Array<any>();
      }
    } catch (e) { }
  }

  onChatEnter(event) {
    if (event.keyCode === 13) {
      this.SendChatMsg();
    }
  }
  public getPatientName() {
    //var id = this.token.PatientIdentifier;
    this.patService.getPatient(this.token.PatientIdentifier,this.token.UserType).subscribe(res => {
      this.patInfo = res;
    },
      error => {
        this.alertService.error(error);
      });
  }

  getAdminId() {
    this.patService.getAdminId()
      .subscribe(res => this.SuccessGetAdminId(res),
        res => this.Error(res));
  }
  SuccessGetAdminId(res) {
    this.adminId = res;
  }
  Error(res) {

  }
}

