import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Medication } from 'src/app/models/Medications/Medications.model';
import { PatientMedicalRecord } from 'src/app/models/patient/Pat-medicalRecord.model';
import { Router } from '@angular/router';
import { Visit } from 'src/app/models/visit/visit.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  templateUrl: './app.Add_OP_summary.html'
})

  export class AddOPSummaryComponent implements OnInit {
    public editModal: boolean = false;
    public OPdata : any;
    public Vistdata :Visit = new Visit();

    public token: any;
    public opsummarydata : any;
    medicationlist : Array<Medication> = new Array<Medication>();
    public patId: any;
    public VisitId : any;
    
    // patmedicalrecordlist : Array<PatientMedicalRecord> = new Array<PatientMedicalRecord>();

    medication : Medication = new Medication ();
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,public authenticationService: AuthenticationService,public routing: Router,
    public patservice: PatientService, private notifyService: NotificationService){

      this.token = this.authenticationService.currentUserValue;
     this.patId = this.patservice.PatientId;
     this.VisitId = this.patservice.VisitId;
     this.OPdata = { PatientId: "", PatientName: "",Age: "",Gender: "",Address: "", ContactNumber:"", VisitDate: "", BookingTime: "", DepartmentName: "", DoctorName: "", LongSignature: "",NMC: ""};
     this.medication = { MedicationId: "", PatientId: "", VisitId: "", DrugName: "", Dose: "", Frequency: "", Remarks: "" };
     this.medicationlist.push(this.medication);
      
    }

    ngOnInit(){
      this.getOPSummarydata();
    }

    
    // public getOPSummarydata() {
    //   this.patservice.getOPSummary(this.token.PatientIdentifier,this.data.visitId). subscribe(res => this.Success(res),
    //   res => this.Error(res)); }
  
    //   Success(res){
    //    this.opsummarydata = res;
    
    //     }
   
      
    //   Error(res){
  
    //   }
   
    Download(){
      var data = document.getElementById('convertToPdf');
      html2canvas(data).then(canvas => {
        var imgWidth = 190;
        var imgHeight = canvas.height * imgWidth / canvas.width;
  
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
        var position = 10;
        pdf.addImage(contentDataURL, 'PNG', 10, position, imgWidth, imgHeight);
        //pdf.addPage();
        pdf.save('OPD_Summary.pdf'); // Generated PDF  
  
      });
    }

    addRow(index) {
      this.medication = { MedicationId: "", PatientId: "", VisitId: "", DrugName: "", Dose: "", Frequency: "", Remarks: "" };
      this.medicationlist.push(this.medication);
      return true;
    }
  
    deleteRow(index) {
      if (this.medicationlist.length == 1) {
        return false;
      } else {
        this.medicationlist.splice(index, 1);
        return true;
      }
    }

    getOPSummarydata(){
   
      this.patservice.getOPSummary(this.patId , this.VisitId). subscribe(res => this.Successsummary(res),
      res => this.ErrorSuccesssummary(res)); 
    }
  
    Successsummary(data){
      if(data && data.IsSuccess && data.Results){
        this.editModal = true ;
        this.OPdata =data.Results;
        this.Vistdata.VisitId = this.OPdata.VisitId;
        this.Vistdata.PatientId = this.OPdata.PatientId;
        this.Vistdata.Problem.ProblemId =this.OPdata.ProblemId; 
      }
    }
  
    ErrorSuccesssummary(res){
  
    }

    hide(){
      this.editModal = false;
      this.routing.navigate(['/DailyVisit']);
    }

    AddOPdSUmmary(){
      if(this.Vistdata.Problem.History != null){
        for(let i=0 ; i < this.medicationlist.length ; i++){
          this.medicationlist[i].VisitId = this.Vistdata.VisitId;
          this.Vistdata.medications.push(this.medicationlist[i]);
        }
        this.patservice.addOPSummary(this.Vistdata,this.Vistdata.VisitId). subscribe(res => this.Successaddsummary(res),
        res => this.ErrorSuccessaddsummary(res));
      }
      else{
        this.notifyService.showError("Error","Please fill required fields");
        
      }
    }

    Successaddsummary(data){
    this.patservice.PatientId =null;
    this.patservice.VisitId = null;
    this.routing.navigate(['/DailyVisit']);    
    }
  
    ErrorSuccessaddsummary(res){
  
    }
  }
