import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Scheduling, SchedulingList, ScheduleInterval } from 'src/app/models/scheduling/scheduling.model';
import { SchedulingService } from 'src/app/services/scheduling/scheduling.service';
import * as moment from 'moment/moment'
import { NotificationService } from 'src/app/services/notification.service';
// import { analyzeAndValidateNgModules } from '@angular/compiler';
import { DoctorService } from 'src/app/services/doctor/doctor.service';

@Component({
  templateUrl: 'add-appointment.component.html',
  styleUrls: ['./doctor-scheduling.component.css']
})
export class AddAppointmentComponent implements OnInit {
  public showModal: boolean;
  public scheduling: Scheduling = new Scheduling();
  selectedValue: any;
  public doctorList: any;
  public schedulingTime: any;
  public start: any;
  public minDate: any;
  public starttime: any;
  public endtime: any;
  public end: any;
  meridian = true;
  public index: number = 0;
  public patpopulation: number = 0;
  public editModal: boolean;
  public exceptionOnTime: boolean;
  showView: any;
  public mapId: any;
  public doctor: any;
  hoveredDate: NgbDate | null = null;
  public startTime = { hour: 0, minute: 0 };
  public endTime = { hour: 0, minute: 0 };
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  hosDocMapId: any;
  public StartTime = { hour: 0, minute: 0 };
  public EndTime = { hour: 0, minute: 0 };
  public schedulingList: Array<SchedulingList> = new Array<SchedulingList>();
  public schedulingArray: Array<any> = new Array<any>();
  public showSchedule: boolean;
  public scheduleData: Array<any> = [];
  public scheduleDataList: Array<any> = [];
  public DoctorAvailable: Array<any> = [];
  date: any;
  dateSelect: boolean = false;
  public newSchedulingList: SchedulingList = { StartTime: 0, EndTime: 0, StartHours: 0, StartMinutes: 0, StartSeconds: 0, EndHours: 0, EndMinutes: 0, EndSeconds: 0, AccomodatedPatient: 0 };
  public newScheduling: Scheduling = {
    SchedulingId: "", Date: null, StartDate: null, HospitalDoctorMapId: "", EndDate: null, StartTime: 0, EndTime: 0, DepartmentId: "", HospitalId: "", DoctorId: ""
    , DepatmentName: "", HospitalName: "", DoctorName: "", StartHours: 0, StartMinutes: 0, StartSeconds: 0, EndHours: 0, EndMinutes: 0, EndSeconds: 0, NewScheduleList: [], ScheduleInterval: [], AppointmentType: "", AdminId: ""
  };
  appointmentType: any;
  DocmapDetail: any;
  todayDate = moment();
  constructor(public routing: Router, public schedulingService: SchedulingService, public docService: DoctorService,
    public calendar: NgbCalendar, public notifyService: NotificationService, private route: ActivatedRoute) {
    //this.showModal = true;
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.hosDocMapId = this.route.snapshot.queryParamMap.get('id');
    this.doctor = this.route.snapshot.queryParamMap.get('doc');
    this.Setappointmenttype();
  }
  ngOnInit() {
    this.newSchedulingList = { StartTime: 0, EndTime: 0, StartHours: 0, StartMinutes: 0, StartSeconds: 0, EndHours: 0, EndMinutes: 0, EndSeconds: 0, AccomodatedPatient: 0 };
    this.schedulingList.push(this.newSchedulingList);
  }
  public Scheduling() {
    this.routing.navigate(['/scheduling']);
  }
  public hide() {
    this.showModal = false;
  }

  public Submit() {
    this.exceptionOnTime = false;
    let mdtFrom = moment([this.fromDate.year, this.fromDate.month - 1, this.fromDate.day]);
    if (this.toDate == null) {
      this.toDate = this.fromDate;
    }
    let mdtTo = moment([this.toDate.year, this.toDate.month - 1, this.toDate.day]);
    this.scheduling.StartDate = new Date(mdtFrom.format('YYYY-MM-DD'));
    this.scheduling.EndDate = new Date(mdtTo.format('YYYY-MM-DD'));
    this.scheduling.HospitalDoctorMapId = this.hosDocMapId;
    this.scheduling.NewScheduleList = Object.assign(this.scheduling.NewScheduleList, this.schedulingList);
    if (this.appointmentType != null) {
      this.scheduling.AppointmentType = this.appointmentType;
    }
    else {
      this.notifyService.showError('Error', 'Please select Appointment Type');
      return;
    }
    for (let i = 0; i < this.schedulingList.length; i++) {
      if (this.schedulingList[i].StartTime != null && this.schedulingList[i].EndTime != null) {
        if (this.schedulingList[i].AccomodatedPatient <= 0) {
          this.notifyService.showError("Error", "Accomodated Patient must be greater than 0");
          return;
        }
        if (this.schedulingList.length == 1) {
          this.scheduling.NewScheduleList[i].StartHours = this.schedulingList[i].StartTime.hour;
          this.scheduling.NewScheduleList[i].StartMinutes = this.schedulingList[i].StartTime.minute;
          this.scheduling.NewScheduleList[i].StartSeconds = this.schedulingList[i].StartTime.second;
          this.scheduling.NewScheduleList[i].EndHours = this.schedulingList[i].EndTime.hour;
          this.scheduling.NewScheduleList[i].EndMinutes = this.schedulingList[i].EndTime.minute;
          this.scheduling.NewScheduleList[i].EndSeconds = this.schedulingList[i].EndTime.second;
        }
        else {
          for (let j = i + 1; j <= this.schedulingList.length; j++) {
            if (j == this.schedulingList.length) {
              this.scheduling.NewScheduleList[i].StartHours = this.schedulingList[i].StartTime.hour;
              this.scheduling.NewScheduleList[i].StartMinutes = this.schedulingList[i].StartTime.minute;
              this.scheduling.NewScheduleList[i].StartSeconds = this.schedulingList[i].StartTime.second;
              this.scheduling.NewScheduleList[i].EndHours = this.schedulingList[i].EndTime.hour;
              this.scheduling.NewScheduleList[i].EndMinutes = this.schedulingList[i].EndTime.minute;
              this.scheduling.NewScheduleList[i].EndSeconds = this.schedulingList[i].EndTime.second;
            }
            else {
              if (this.schedulingList[i].StartTime.hour != this.schedulingList[j].StartTime.hour && this.schedulingList[i].EndTime.hour != this.schedulingList[j].EndTime.hour) {
                this.scheduling.NewScheduleList[i].StartHours = this.schedulingList[i].StartTime.hour;
                this.scheduling.NewScheduleList[i].StartMinutes = this.schedulingList[i].StartTime.minute;
                this.scheduling.NewScheduleList[i].StartSeconds = this.schedulingList[i].StartTime.second;
                this.scheduling.NewScheduleList[i].EndHours = this.schedulingList[i].EndTime.hour;
                this.scheduling.NewScheduleList[i].EndMinutes = this.schedulingList[i].EndTime.minute;
                this.scheduling.NewScheduleList[i].EndSeconds = this.schedulingList[i].EndTime.second;
              }
              else {
                this.notifyService.showError('Error', "Can't select same time");
                return;
              }
            }
          }
        }

        if (this.schedulingList[i].EndTime.hour <= this.schedulingList[i].StartTime.hour) {
          this.exceptionOnTime = true;
        }
        var currenthr = this.todayDate.format('HH:mm z');
        var currentmin = this.todayDate.format('mm z');

        var currenttimeHr = parseInt(currenthr);
        var currenttimeMin = parseInt(currentmin);
        if (this.scheduling.StartDate <= this.todayDate.toDate() && currenttimeHr > this.schedulingList[i].StartHours && currenttimeMin > this.schedulingList[i].EndMinutes) {
          this.exceptionOnTime = true;
        }

        if (this.schedulingList[i].AccomodatedPatient > 0) {
          this.scheduling.NewScheduleList[i].AccomodatedPatient = this.schedulingList[i].AccomodatedPatient;
        } else {
          this.scheduling.NewScheduleList[i].AccomodatedPatient = 1;
        }
      }
      else {
        this.notifyService.showError('Error', 'Please select Appropriate Time');
        return;
      }
    }
    if (this.exceptionOnTime == false) {
      for (var j = 0; j < this.schedulingList.length; j++) {
        var sttime = this.scheduling.NewScheduleList[j].StartHours + ":" + this.scheduling.NewScheduleList[j].StartMinutes + ":" + this.scheduling.NewScheduleList[j].StartSeconds;
        var startformatted = moment(sttime, "HH:mm:ss").format("hh:mm A");
        var endtime = this.scheduling.NewScheduleList[j].EndHours + ":" + this.scheduling.NewScheduleList[j].EndMinutes + ":" + this.scheduling.NewScheduleList[j].EndSeconds;
        var endformatted = moment(endtime, "HH:mm:ss").format("hh:mm A");
        var totalhr = this.scheduling.NewScheduleList[j].EndHours - this.scheduling.NewScheduleList[j].StartHours;
        var totalmin = this.scheduling.NewScheduleList[j].EndMinutes - this.scheduling.NewScheduleList[j].StartMinutes;
        var totalhrconertomin = totalhr * 60;
        var totalfinalmin = totalhrconertomin + totalmin;
        var AccomodatePatientlimit = totalfinalmin / 5;

        if (AccomodatePatientlimit >= this.scheduling.NewScheduleList[j].AccomodatedPatient) {
          var date1 = moment([this.fromDate.year, this.fromDate.month - 1, this.fromDate.day]).format("YYYY-MM-DD");
          if (this.toDate == null) {
            this.toDate = this.fromDate;
          }
          var date2 = moment([this.toDate.year, this.toDate.month - 1, this.toDate.day]).format("YYYY-MM-DD");
          var momentObj = moment(date1 + startformatted, 'YYYY-MM-DDLT');
          var startdateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');
          var momentObj = moment(date1 + endformatted, 'YYYY-MM-DDLT');
          var enddateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');
          var startingtime = moment(startdateTime, 'YYYY-MM-DD HH:mm A');
          var endingtime = moment(enddateTime, 'YYYY-MM-DD HH:mm A');
          var diffmin = endingtime.diff(startingtime, 'minutes');
          this.patpopulation = this.schedulingList[j].AccomodatedPatient;
          var eachpattym = diffmin / this.patpopulation;
          while (date1 <= date2) {
            temp = null;
            var momentObj = moment(date1 + startformatted, 'YYYY-MM-DDLT');
            var startdateTime = momentObj.format('YYYY-MM-DD HH:mm A');
            var startingtime = moment(startdateTime, 'YYYY-MM-DD HH:mm A');
            for (var k = 0; k < this.patpopulation; k++) {
              var temp = moment(startingtime, 'YYYY-MM-DD HH:mm A')
                .add(eachpattym, 'minutes')
                .format('YYYY-MM-DD HH:mm A');
              this.schedulingArray.push({
                SchedulingId: '',
                StartTime: startdateTime,
                HospitalDoctorMapId: this.hosDocMapId,
                Date: new Date(moment(date1, 'YYYY-MM-DD').format('YYYY-MM-DD')),
                EndTime: temp,
                IsBooked: false,
                IsActive: false,
                VisitId: '',
                AppointmentType: this.appointmentType
              });
              startingtime = moment(temp, 'YYYY-MM-DDTHH:mm:s');
              startdateTime = startingtime.format('YYYY-MM-DD HH:mm A');
            }
            date1 = moment(date1, 'YYYY-MM-DD')
              .add(1, 'days')
              .format('YYYY-MM-DD');
          }
          this.scheduling.ScheduleInterval = this.schedulingArray;
          this.schedulingService.addScheduling(this.scheduling).subscribe(res => this.Success(res), res => this.Error(res));
        }
        else {
          this.notifyService.showError('Error', 'Please Enter valid Accomodated Patient minimum 5 minutes per Patient.');
        }

      }


    } else {
      this.notifyService.showError('Error', 'Select Appropriate Time Interval');
      for (let i = 0; i < this.schedulingList.length; i++) {
        this.schedulingList[i].StartTime = null;
        this.schedulingList[i].EndTime = null;
        this.schedulingList[i].AccomodatedPatient = null;
      }
      for (let j = 0; j < this.schedulingList.length; j++) {
        this.deleteRow(j);
      }
    }
  }
  Success(res) {
    this.routing.navigate(['/addAppointment']);
    for (let i = 0; i < this.schedulingList.length; i++) {
      this.schedulingList[i].StartTime = null;
      this.schedulingList[i].EndTime = null;
      this.schedulingList[i].AccomodatedPatient = null;
    }
    for (let j = 0; j < this.schedulingList.length; j++) {
      this.deleteRow(j);
    }
    this.notifyService.showSuccess('Successful', 'Schedule added Successfully');
    this.onDateSelection(this.fromDate);
  }
  Error(res) {
    this.notifyService.showError("Error", "Schedule already available for same date!!")
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.date = moment([this.fromDate.year, this.fromDate.month - 1, this.fromDate.day]).format('L');
    this.showSchedule = true;
    this.schedulingService.getSchedulingOfDate(this.hosDocMapId).subscribe(res => this.SuccessSchedule(res),
      res => this.Error(res));
  }

  SuccessSchedule(res) {
    this.scheduleData = res;
    for (let index = 0; index < this.scheduleData.length; index++) {
      this.scheduleData[index].Date = moment(res[index].Date).format('L');
    }

    for (var c = 0; c < this.scheduleData.length; c++) {
      var date = moment(this.scheduleData[c].Date).format('YYYY/MM/DD');
      var timeextract = this.scheduleData[c].StartTime;
      var bookedStartTime = moment(date + ' ' + timeextract, 'YYYY/MM/DD HH:mm A');
      var momentObj = moment(bookedStartTime, 'YYYY-MM-DDLT');
      var currentdateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');
      var formatedBookedDateTime = moment(currentdateTime, 'YYYY-MM-DD HH:mm A');
      this.scheduleData[c].StartTimeDetail = formatedBookedDateTime;
    }

    for (let k = 0; k < this.scheduleData.length - 1; k++) {
      for (let l = 0; l < this.scheduleData.length - k - 1; l++) {
        var diffmin = this.scheduleData[l + 1].StartTimeDetail.diff((this.scheduleData[l].StartTimeDetail), 'minutes')
        if (diffmin < 0) {
          let swap = this.scheduleData[l];
          this.scheduleData[l] = this.scheduleData[l + 1];
          this.scheduleData[l + 1] = swap;
        }
      }
    }

    this.scheduleDataList = this.scheduleData;
    this.scheduleDataList = this.scheduleData.filter(x => x.Date == this.date);
    for (let index = 0; index < this.scheduleDataList.length; index++) {
      this.DoctorAvailable[index] = this.scheduleDataList[index].StartTime + "" + "-" + "" + this.scheduleDataList[index].EndTime;

    }
  }





  isHovered(date: NgbDate) {

    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }
  toggleMeridian() {
    this.meridian = !this.meridian;
  }
  deleteRow(index) {
    if (this.schedulingList.length == 1) {
      return false;
    } else {
      this.schedulingList.splice(index, 1);
      return true;
    }
  }
  addRow(i) {
    this.newSchedulingList = { StartTime: 0, EndTime: 0, StartHours: 0, StartMinutes: 0, StartSeconds: 0, EndHours: 0, EndMinutes: 0, EndSeconds: 0, AccomodatedPatient: 0 };
    this.schedulingList.push(this.newSchedulingList);
    console.log(this.schedulingList);
    return true;
  }

  Setappointmenttype() {
    this.docService.getDoctorMapDetail(this.hosDocMapId).subscribe((res: any) => this.SuccessMapdetail(res?.Results),
      res => this.Error(res));

  }

  SuccessMapdetail(res) {
    if (res) {
      this.DocmapDetail = res;
      if (this.DocmapDetail.ConsultationType == "Online Appointment") {
        this.appointmentType = "Online Appointment";
      }
      else if (this.DocmapDetail.ConsultationType == "Telemedicine") {
        this.appointmentType = "Telemedicine";
      }
      else {
        this.appointmentType = null;
      }
    }

  }
}
