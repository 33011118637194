import { HospitalDoctorMap } from '../HospitalDoctorMap/app.hospitaldoctormap.model';
import { Qualification } from '../Qualification/qualification.model';

export class Meeting {
  public ZoomMeetingId: string;
  public MeetingNumber: string;
  public ApiKey: string;
  public ApiSecret: string;
  public Password: string;
  public DoctorId: string;
  public IsActive: boolean;
}