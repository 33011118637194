import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { DoctorEndpoint } from './doctor-endpoint.service';
import { Doctor } from '../../models/doctor/doctor.model';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Patient } from '../../models/patient/patient.model';
import { Departement } from 'src/app/models/Department/app.department.model';
import { HospitalDoctorMap } from 'src/app/models/HospitalDoctorMap/app.hospitaldoctormap.model';
import { Qualification } from 'src/app/models/Qualification/qualification.model';
import { PatientFilesUploadModel } from 'src/app/models/patient/pat-fileupload.model';
import { DepartementResponse } from 'src/app/models/Shared/DepartementResponse.model';
import { TelemedicineResponse } from 'src/app/models/Shared/TelemedicineResponse.model';
@Injectable()
export class DoctorService {
  constructor(private router: Router, private http: HttpClient, private doctorEndPoint: DoctorEndpoint) {
  }
  public _DoctorId: string;
  get DoctorId(): string {
    return this._DoctorId;
  }
  set DoctorId(DoctorId: string) {
    this._DoctorId = DoctorId;
  }
  public _ScheduleIntervalId: string = null;
  get ScheduleIntervalId(): string {
    return this._ScheduleIntervalId;
  }
  set ScheduleIntervalId(ScheduleIntervalId: string) {
    this._ScheduleIntervalId = ScheduleIntervalId;
  }
  public _SchedulingId: string = null;
  get SchedulingId(): string {
    return this._SchedulingId;
  }
  set SchedulingId(SchedulingId: string) {
    this._SchedulingId = SchedulingId;
  }

  getDoctor(doctorId?: string) {
    return this.doctorEndPoint.getDoctorByIdEndpoint<Doctor>(doctorId);
  }
  getDoctorName(doctorId?: string) {
    return this.doctorEndPoint.getDoctorNameByIdEndpoint<Doctor>(doctorId);
  }
  getDoc(doctorId?: string, hospitalId?: string, visitType?: string) {
    return this.doctorEndPoint.getDocByIdEndpoint<Doctor>(doctorId, hospitalId, visitType);
  }
  getDoctorHospital(doctorId?: string) {
    return this.doctorEndPoint.getDoctorHospitalByIdEndpoint<Doctor>(doctorId);
  }
  getPatientListByProviderId(doctorId?: string) {
    return this.doctorEndPoint.getPatientListByProviderIdEndpoint<TelemedicineResponse<Patient[]>>(doctorId); 
  }
  getPatientListForStatusChange(doctorId?: string) {
    return this.doctorEndPoint.getPatientListForStatusChangeEndpoint<Patient>(doctorId);
  }
  getPatientList(doctorId?: string) {
    return this.doctorEndPoint.getPatientList<Patient>(doctorId);
  }

  getPatientListByAdmin() {
    return this.doctorEndPoint.getPatientListByAdminEndPoint<TelemedicineResponse<Patient[]>>();
  }

  getCancelledAppointmentPatientByAdmin() {
    return this.doctorEndPoint.getCancelledAppointmentPatientByAdminEndPoint<Patient[]>();
  }
  getAppointmentsBookedOverPhone(fromDate, toDate) {
    return this.doctorEndPoint.getAppointmentsBookedOverPhone<any>(fromDate, toDate);
  }

  getDoctorList() {
    return this.doctorEndPoint.getDoctorListEndpoint<Doctor[]>();
  }
  getAvailableDoctorList() {
    return this.doctorEndPoint.getAvailableDoctorListEndpoint<Doctor[]>();
  }
  getHospitalDoctorMapList(hospitalId?: string) {
    return this.doctorEndPoint.getHospitalDoctorMapListEndpoint<HospitalDoctorMap>(hospitalId);
  }
  getDepartmentList() {
    return this.doctorEndPoint.getDepartmentListEndpoint<Departement[]>();
  }
  getDoctorMapDetail(hosDocMapId?: string) {
    return this.doctorEndPoint.getDoctorMapDetailEndpoint<HospitalDoctorMap>(hosDocMapId);
  }
  registerDoctor(doctor: Doctor) {
    return this.doctorEndPoint.registerDoctorEndpoint<Doctor>(doctor);
  }
  registerPatient(patient: Patient) {
    return this.doctorEndPoint.registerPatientEndpoint<Patient>(patient);
  }
  loginDoctor(doctor: Doctor) {
    return this.doctorEndPoint.loginDoctorEndpoint<Doctor>(doctor);
  }
  updateDoctor(doctor: Doctor) {
    if (doctor.IdentityUserId) {
      return this.doctorEndPoint.UpdateDoctorEndpoint(doctor, doctor.IdentityUserId);
    }
    else {
      return this.doctorEndPoint.getDoctorByIdEndpoint<Doctor>(doctor.IdentityUserId).pipe(
        mergeMap(doctorUser => {
          doctor.IdentityUserId = doctorUser.IdentityUserId;
          return this.doctorEndPoint.UpdateDoctorEndpoint(doctor, doctor.IdentityUserId)
        }));
    }
  }

  deleteDoctor(doctorId: string | Doctor): Observable<Doctor> {

    if (typeof doctorId === 'string' || doctorId instanceof String) {
      return this.doctorEndPoint.getDeleteDoctorEndpoint<Doctor>(<string>doctorId);
    }
    else {

      if (doctorId.DoctorId) {
        return this.deleteDoctor(doctorId.DoctorId);
      }
      else {
        return this.doctorEndPoint.getDoctorByIdEndpoint<Doctor>(doctorId.UserName).pipe(
          mergeMap(doctor => this.deleteDoctor(doctor.DoctorId)));
      }
    }
  }
  deleteHospitalMap(HospitalDoctorMapId: string) {
    return this.doctorEndPoint.deleteHospitalMapEndpoint<HospitalDoctorMap>(HospitalDoctorMapId);
  }

  updateHosDocMap(map: HospitalDoctorMap) {
    if (map.HospitalDoctorMapId) {
      return this.doctorEndPoint.UpdateHosDocMapEndpoint(map, map.HospitalDoctorMapId);
    }
    else {
      return this.doctorEndPoint.UpdateHosDocMapEndpoint<HospitalDoctorMap>(map, map.HospitalDoctorMapId).pipe(
        mergeMap(map => {
          map.HospitalDoctorMapId = map.HospitalDoctorMapId;
          return this.doctorEndPoint.UpdateHosDocMapEndpoint(map, map.HospitalDoctorMapId)
        }));
    }
  }
  updateQualification(qualification: Qualification) {
    if (qualification.QualificationId) {
      return this.doctorEndPoint.updateQualificationEndpoint(qualification, qualification.QualificationId);
    }
    else {
      return this.doctorEndPoint.updateQualificationEndpoint<Qualification>(qualification, qualification.QualificationId).pipe(
        mergeMap(map => {
          qualification.QualificationId = qualification.QualificationId;
          return this.doctorEndPoint.updateQualificationEndpoint(qualification, qualification.QualificationId)
        }));
    }
  }
  deactivateDoctor(doc: Doctor) {
    if (doc.DoctorId) {
      return this.doctorEndPoint.deactivateDoctorEndpoint(doc, doc.DoctorId);
    }
    else {
      return this.doctorEndPoint.deactivateDoctorEndpoint<Doctor>(doc, doc.DoctorId).pipe(
        mergeMap(map => {
          doc.DoctorId = doc.DoctorId;
          return this.doctorEndPoint.deactivateDoctorEndpoint(doc, doc.DoctorId)
        }));
    }
  }
  uploadPrescription(pres: PatientFilesUploadModel) {
    return this.doctorEndPoint.uploadPrescriptionEndpoint<PatientFilesUploadModel>(pres);

  }

  getDoctorMapList() {
    return this.doctorEndPoint.getDoctorMapListEndpoint<Doctor[]>();
  }

  getDoctorByDepartment(DepartementId?: string) {
    return this.doctorEndPoint.getDoctorByDepartmentEndPoint<DepartementResponse<Doctor[]>>(DepartementId);
  }

}
