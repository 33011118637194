export class PatientFamily {

  public FamilyMemberId: string;
  public PatientId: string;
  public Relationship: string;
  public FirstName: string;
  public MiddleName: string;
  public LastName: string;
  public DateOfBirth: string;
  public Gender: string;
  public Email: string;
  public ContactNumber: string;
  public Address: string;
  public BloodGroup: string;

}