<app></app>

<div class="back-bg">
  <button (click)="Admin()" class="back-btn-doc-room">Back To Admin</button>
</div>

<div class="card">
  <div class="card-body">
    <h1 class="card-title mb-4">
      <span class="select-title"><i class="fa fa-calendar"></i>Doctor Scheduling</span>
    </h1>
    <div class="row searchbar-card text-center" style="margin-top: 0">
      <div class="col-md-6 col-sm-8 m-auto d-flex">
        <div class="searchbar">
          <input class="search_input" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Doctor..">
          <a class="search_icon text-white"><i class="fa fa-search"></i>Search</a>
        </div>
      </div>
    </div>
    <div *ngIf="searchText == ''">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">SN</th>
            <th scope="col">HospitalName</th>
            <th scope="col">DoctorName</th>
            <th scope="col">Department</th>
            <th scope="col">Appointment Type</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>


          <tr *ngFor="let data of doctorList | paginate: { itemsPerPage: 10, currentPage: p  } ;let i = index  ">

            <td>{{i+1}}</td>
            <td *ngIf="data.HospitalName != null" style="vertical-align: middle">


              {{ data.HospitalName }}
            </td>
            <td *ngIf="data.HospitalName == null" style="vertical-align: middle">
              N/A
            </td>
            <td style="vertical-align: middle">{{ data.DoctorName }}</td>
            <td *ngIf="data.DepartmentName != null" style="vertical-align: middle">
              {{ data.DepartmentName }}
            </td>
            <td *ngIf="data.DepartmentName == null" style="vertical-align: middle">
              N/A
            </td>
            <!--<td *ngIf="data.ConsultationType != null"
      style="vertical-align: middle">
    {{ data.ConsultationType }}
  </td>-->
            <td style="vertical-align: middle">
              {{ data.ConsultationType }}
            </td>
            <td *ngIf="data.DepartmentName != null && data.HospitalName != null">
              <a (click)="
                  View(
                    data.HospitalDoctorMapId,
                    data.HospitalName,
                    data.DepartmentName
                  )
                " class="btn btn-primary btn-sm text-white">
                <i class="fa fa-eye"></i>View
              </a>
              <a (click)="
                  Add(
                    data.HospitalDoctorMapId,
                    data.HospitalName,
                    data.DepartmentName,
                    data.DoctorName
                  )
                " class="btn btn-info btn-sm text-white">
                <i class="fa fa-plus"></i>Add
              </a>
              <a (click)="
                  Edit(
                    data.HospitalDoctorMapId,
                    data.HospitalName,
                    data.DepartmentName
                  )
                " class="btn btn-success btn-sm text-white">
                <i class="fa fa-pencil"></i>Edit
              </a>
            </td>
            <td *ngIf="data.DepartmentName == null && data.HospitalName == null">
              <a (click)="UpdateProfile(data)" class="btn btn-info btn-sm text-white">
                <i class="fa fa-plus"></i>Add Doctor Profile
              </a>
            </td>

          </tr>
        </tbody>
      </table>
      <pagination-controls style="font-size: 20px" (pageChange)="p = $event"></pagination-controls>
    </div>
    <div *ngIf="searchText != ''">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">SN</th>
            <th scope="col">HospitalName</th>
            <th scope="col">DoctorName</th>
            <th scope="col">Department</th>
            <th scope="col">Appointment Type</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>


          <tr *ngFor="let data of doctorList | filter: searchText ;let i = index  ">

            <td>{{i+1}}</td>
            <td *ngIf="data.HospitalName != null" style="vertical-align: middle">


              {{ data.HospitalName }}
            </td>
            <td *ngIf="data.HospitalName == null" style="vertical-align: middle">
              N/A
            </td>
            <td style="vertical-align: middle">{{ data.DoctorName }}</td>
            <td *ngIf="data.DepartmentName != null" style="vertical-align: middle">
              {{ data.DepartmentName }}
            </td>
            <td *ngIf="data.DepartmentName == null" style="vertical-align: middle">
              N/A
            </td>
            <td *ngIf="data.ConsultationType != null" style="vertical-align: middle">
              {{ data.ConsultationType }}
            </td>
            <td *ngIf="data.DepartmentName != null && data.HospitalName != null">
              <a (click)="
                  View(
                    data.HospitalDoctorMapId,
                    data.HospitalName,
                    data.DepartmentName
                  )
                " class="btn btn-primary btn-sm text-white">
                <i class="fa fa-eye"></i>View
              </a>
              <a (click)="
                  Add(
                    data.HospitalDoctorMapId,
                    data.HospitalName,
                    data.DepartmentName,
                    data.DoctorName
                  )
                " class="btn btn-info btn-sm text-white">
                <i class="fa fa-plus"></i>Add
              </a>
              <a (click)="
                  Edit(
                    data.HospitalDoctorMapId,
                    data.HospitalName,
                    data.DepartmentName
                  )
                " class="btn btn-success btn-sm text-white">
                <i class="fa fa-pencil"></i>Edit
              </a>
            </td>
            <td *ngIf="data.DepartmentName == null && data.HospitalName == null">
              <a (click)="UpdateProfile(data)" class="btn btn-info btn-sm text-white">
                <i class="fa fa-plus"></i>Add Doctor Profile
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal" id="myModal" tabindex="-1" role="dialog" [style.display]="editModal ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="background-color: transparent; border: none">
      <!-- Modal body -->
      <div class="doc-schedule">
        <h1 class="card-title" style="width: 100%">
          Edit Doctor Scheduling Time
          <button type="button" class="close" data-dismiss="modal" (click)="hide()">
            &times;
          </button>
        </h1>
        <div class="doc-schedule-list">
          <!-- <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 table-responsive"> -->
          <table class="table sc-table" width="100%" cellspacing="0" cellpadding="3" style="margin-bottom: 10px !important">
            <thead>
              <tr>
                <th width="10%" align="center" valign="top">SN</th>
                <th width="20%" align="center" valign="top">Availability Type</th>
                <th width="20%" align="center" valign="top">Date</th>
                <th width="20%" align="center" valign="top">
                  Doctor Available
                </th>
                <th width="20%" align="center" valign="top">Action</th>
                <th style="display: none"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of schedulingTime; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ list.AppointmentType }}</td>
                <td>{{ list.Date }}</td>
                <td>
                  {{ list.StartTime }} &nbsp; {{ list.StartMeridian }} -
                  {{ list.EndTime }} &nbsp; {{ list.EndMeridian }}
                </td>
                <td>
                  <i class="fa fa-trash-o text-danger" style="font-size: 16px" (click)="DeleteAppointment(list.SchedulingId)"></i>
                  &nbsp;&nbsp;
                  <i class="fa fa-pencil text-primary" style="font-size: 16px" (click)="editScheduling(list.SchedulingId)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="myModal" tabindex="-1" role="dialog" [style.display]="showView ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block">
  <div class="modal-dialog" style="max-width: 600px">
    <div class="modal-content" style="background-color: transparent; border: none">
      <!-- Modal body -->
      <div class="doc-schedule">
        <div class="">
          <h1 class="card-title mb-4" style="width: 100%">
            View Doctor Scheduling Time
            <button type="button" class="close" data-dismiss="modal" (click)="hide()">
              &times;
            </button>
          </h1>
        </div>
        <!-- <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 table-responsive"> -->
        <div class="doc-schedule-list">
          <table class="table sc-table" width="100%" cellspacing="0" cellpadding="3" style="margin-bottom: 10px !important">
            <thead>
              <tr>
                <th width="10%" align="center" valign="top">SN</th>
                <th width="30%" align="center" valign="top">Availability Type</th>
                <th width="20%" align="center" valign="top">Date</th>
                <th width="20%" align="center" valign="top">
                  Doctor Available
                </th>
                <th width="20%" align="center" valign="top">Action</th>
                <!-- <th width="40%" align="center" valign="top">End Time </th> -->
                <th style="display: none"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of schedulingTime; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ list.AppointmentType }}</td>
                <td>{{ list.Date }}</td>
                <td>
                  <span class="btn-schedule">{{ list.StartTime }} &nbsp; {{ list.StartMeridian }} -
                    {{ list.EndTime }} &nbsp; {{ list.EndMeridian }}</span>
                </td>
                <td>
                  <i class="fa fa-trash-o text-danger" style="font-size: 16px" (click)="DeleteAppointment(list.SchedulingId)"></i>
                  &nbsp;&nbsp;
                  <i class="fa fa-pencil text-primary" style="font-size: 16px" (click)="editScheduling(list.SchedulingId)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal" *ngIf="editSchduleModal" id="newModal" width="100%" tabindex="-1" role="dialog" [style.display]="editSchdule ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block">
  <div class="modal-dialog" role="document" style="max-width: 52rem; margin: -0.25rem auto;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Edit Schedule</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="editSchdule=!editSchdule" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <input [attr.disabled]="true" class="form-control" placeholder="Datepicker" name="DOB" [(ngModel)]="schedule.StartDate" bsDatepicker #d="bsDatepicker" />
          <div class="input-group-append">
            <button class="fa fa-calendar" [attr.disabled]="true" (click)="d.toggle()" type="button"></button>
          </div>
          <div class="col-md-6">
            <label>Appointment Type:</label>
            <select class="form-control" [attr.disabled]="true" [(ngModel)]="schedule.AppointmentType" placeholder="Type" [ngModelOptions]="{standalone: true}">
              <option value="Online Appointment">
                Online Appointment
              </option>
              <option value="Telemedicine">
                Telemedicine
              </option>
            </select>
          </div>

          <div class="row col-md-6" style="margin-top: 10px">
            <table style="width: 10rem;">
              <thead>
                <tr class="table-tr-style">
                  <th>
                    <b><label>Start Time</label></b>
                  </th>

                  <th><label>End Time</label></th>

                  <th><label>Accomodated Patient</label></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="height: 80px">
                    <ngb-timepicker [(ngModel)]="startTime" [meridian]="meridian"></ngb-timepicker>
                  </td>

                  <td style="height: 80px">
                    <ngb-timepicker [(ngModel)]="endTime" [meridian]="meridian"></ngb-timepicker>
                  </td>

                  <td>
                    <input type="number" name="accomodatedpatient" value="" [(ngModel)]="accomodatedPatient" />
                  </td>
                  <br />
                  <!-- <td let i = index;>
                      <a (click)="addRow(i)" class="round-btn btn-primary">+</a>
                      <a style="margin-left:10px" (click)="deleteRow(i)" class="round-btn btn-danger">x</a>
                    </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <!-- </div> -->
          <div class="print-action mt-5 text-center m-auto d-block mb-5" style="margin: 15px auto !important">
            <input type="button" value="Update" (click)="Update()" />
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-primary">Save changes</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
  </div> -->
</div>
<div class="modal" id="myModal" tabindex="-1" role="dialog" [style.display]="editProfileModal ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="background-color: transparent; border: none">
      <doctor-update-profile #doctorModalEditor></doctor-update-profile>
    </div>
  </div>
</div>


<div class="modal" id="deleteScheduleModal" tabindex="-1" role="dialog" [style.display]="hasAppointment ? 'block' : 'none'" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block">
  <div class="modal-dialog" style="max-width: 600px">
    <div class="modal-content" style="background-color: transparent; border: none">
      <!-- Modal body -->
      <div class="doc-schedule">
        <div class="">
          <button type="button" class="close" data-dismiss="modal" (click)="CancelDelete()">
            &times;
          </button>
          <h1 class="card-title mb-4" style="width: 100%">
            Following Patients have appointment with Dr. {{doctorName}} in {{hospitalName}} on {{appointmentDate | date}}
          </h1>
        </div>
        <!-- <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 table-responsive"> -->
        <div class="doc-schedule-list">
          <table class="table sc-table" width="100%" cellspacing="0" cellpadding="3" style="margin-bottom: 10px !important; margin-left: 10px;">
            <thead>
              <tr>
                <th width="5%" align="center" valign="top">SN</th>
                <th width="45%" align="center" valign="top">Patient Name</th>
                <th width="35%%" align="center" valign="top">Booking Time</th>
                <th width="10%" align="center" valign="top">Visit Charge</th>
                <th width="5%" align="center" valign="top">Payment Status</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of appointedPatientList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ list.patientName }}</td>
                <td>{{ list.appointmentTime }}</td>
                <td style="padding-left: 10px;" align="center">{{list.visitCharge}} </td>
                <td style="padding-left: 8px;" align="center">{{list.paymentStatus}}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="modal-footer">
            <div class="col-md-12">
              <div *ngIf="!editSchdule && hasAppointment" class="col-md-10">
                <p><b> Are you sure to delete anyway</b></p>
                <!-- <button *ngIf="isDocEditor == false" class="btn btn-success" (click)="CancelUpdate()">Cancel</button> &nbsp;&nbsp; -->
                <button class="col-md-3 btn btn-danger" style="margin-left: 1rem;" (click)="deleteScheduling()">Delete</button>
                <button class="col-md-3 btn btn-secondary" style="margin-left: 4rem;" (click)="CancelDelete()">Cancel</button>
              </div>
              <div *ngIf="editSchdule && hasAppointment" class="col-md-10">
                <p><b> Are you sure to Edit anyway</b></p>
                <!-- <button *ngIf="isDocEditor == false" class="btn btn-success" (click)="CancelUpdate()">Cancel</button> &nbsp;&nbsp; -->
                <button class="col-md-3 btn btn-danger" style="margin-left: 1rem;" (click)="EditScheduling()">Edit</button>
                <button class="col-md-3 btn btn-secondary" style="margin-left: 4rem;" (click)="CancelDelete()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>


<!--<div class="row">

  <button class="ml-2" mat-raised-button matTooltip="Create new Appointment"
          (click)="goToCalendar()">
    Calendar View
  </button>
  <button class="ml-2" mat-raised-button color="primary" matTooltip="Create new Appointment"
          (click)="goToTimeline()">
    Timeline View
  </button>
  <button class="ml-2" mat-raised-button matTooltip="Create new Appointment" color="primary"
          (click)="goToTimeGrid()">
    Grid View
  </button>
  <button class="new-appointment ml-2" mat-raised-button matTooltip="Create new Appointment"
          (click)="createAppointment('','')">
    <i class="la la-plus"></i> Add Appointment
  </button>
</div>

<full-calendar #calendar
               defaultView="dayGridMonth"
               [header]="{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        }"
               [eventTimeFormat]="{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: true
         }"
               eventLimit=true,
               views=" {
           timeGrid: {
             eventLimit: 6
           }
         }"
               [plugins]="calendarPlugins"
               [weekends]="calendarWeekends"
               [events]="calendarEvents"
               (dateClick)="handleDateClick($event)"
               (eventClick)="eventClick($event)"
               (datesRender)="handleDatesRender($event)"
               (eventRender)="calendarEventRender($event)"></full-calendar>-->