import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SchedulingService } from 'src/app/services/scheduling/scheduling.service';
import { NotificationService } from 'src/app/services/notification.service';
import { HospitalService } from 'src/app/services/hospital/hospital.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ContactInformation } from 'src/app/models/contactInfo/contactinformation.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
//import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  templateUrl: './contact_information.html',
  styleUrls: ['./contact_information.css']
})
export class ContactInfoComponent implements OnInit {
  public edit: boolean = false;
  public editModal: boolean = false;
  token: any;
  public ContactInfo: ContactInformation = new ContactInformation();
  
  public addeditcontactInfo: ContactInformation = new ContactInformation();
  public showaddbtn : boolean = true;
  continfoForm: FormGroup;
 
  constructor(public routing: Router, public http: HttpClient, public hospitalService: HospitalService, public schedulingService: SchedulingService, public notifyService: NotificationService, public formBuilder: FormBuilder, public authService: AuthenticationService, private authenticationService: AuthenticationService) {
    this.token = this.authService.currentUserValue;

  }
  ngOnInit() {
    this.continfoForm = this.formBuilder.group({
      ContactNumber: ['', [Validators.required]],
      Email:['', [Validators.required, Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$')]]
    });

    this.getcontactInformation();
  }

  public Admin() {
    this.routing.navigate(['/admin']);
  }
  getcontactInformation() {
    this.hospitalService.getcontactInformation().subscribe(res => this.SuccessgetcontactInfo(res),
      res => this.Error(res));
  }
  SuccessgetcontactInfo(res) {
    
    if(res !=null){
      this.ContactInfo = res;
      this.showaddbtn = false;
    }
  }
  Error(res) {
    this.notifyService.showError("Error", "Internal Error")
  }
  ErrorAdd(res) {
    this.notifyService.showError("Error", "Internal Error")
  }
  ErrorEdit(res) {
    this.notifyService.showError("Error", "Internal Error")
  }
  

  AddContactInfo(form) {
   
    this.edit = false;
    if (form.invalid) {
      if(form.controls.ContactNumber.invalid){
        if(form.controls.ContactNumber.errors.required){
          this.notifyService.showError("", "Contact number is required");
        }
        else
        this.notifyService.showError("", "Contact number should be 10 digits");
      }
      if(form.controls.Email.invalid){
        if(form.controls.Email.errors.required){
          this.notifyService.showError("", "Email is required");
        }
        else
        this.notifyService.showError("", "Email should be in correct format");
      }
      return;
    }
      this.hospitalService.addContactInfo(this.ContactInfo).subscribe(res => this.Success(res),
        res => this.ErrorAdd(res));
  

  }
  Success(res) {
    window.location.reload();
    this.notifyService.showSuccess('', 'Information Added Successfully!')

  }
  EditContactInfo() {
    this.edit = true;
    this.editModal = true ;
    this.addeditcontactInfo = Object.assign(this.addeditcontactInfo, this.ContactInfo)
  }
  
  UpdateContactInfo(form) {
   
    if (form.invalid) {
      if(form.controls.ContactNumber.invalid){
        if(form.controls.ContactNumber.errors.required){
          this.notifyService.showError("", "Contact number is required");
        }
        else
        this.notifyService.showError("", "Contact number should be 10 digits");
      }
      if(form.controls.Email.invalid){
        if(form.controls.Email.errors.required){
          this.notifyService.showError("", "Email is required");
        }
        else
        this.notifyService.showError("", "Email should be in correct format");
      }
      return;
    }
    const token = this.authenticationService.currentUserValue;
    if (token) {
     this.addeditcontactInfo.AdminId = token.unique_name;
    }
    this.hospitalService.updateContactInfo(this.addeditcontactInfo).subscribe(res => this.SuccessUpdate(res),
      res => this.ErrorEdit(res));
  }
  SuccessUpdate(res) {
    this.getcontactInformation();
    this.notifyService.showSuccess('', 'Successfully Updated');
    window.location.reload();
  }

  DeleteHospital(id) {
    this.hospitalService.deleteHospital(id).subscribe(res => this.SuccessDelete(res),
      res => this.Error(res));
  }
  SuccessDelete(res) {
    this.getcontactInformation();
    this.notifyService.showSuccess('', 'Successfully Deleted!');
    window.location.reload();
  }
  hide(){
    this.editModal = false;
    this.getcontactInformation();
  }

  cleardata(){
    this.ContactInfo = new ContactInformation();
  }
}
