import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { DanphecareModel } from '../../models/danphecare/danphecare.model';
import { Observable } from 'rxjs';
import { DanphecareEndpoint } from '../../services/danphecare/danphecare-endpoint.service';



@Injectable()
export class DanphecareService {
  constructor(private router: Router, private http: HttpClient, private danphecareEndPoint: DanphecareEndpoint) {
  }

  GetAppliedUsrlist(){
    return this.danphecareEndPoint.GetAppliedUsrlist<DanphecareModel[]>();
  }

  applytoIsolation(danphecare: DanphecareModel) {
    return this.danphecareEndPoint.applytoIsolation<DanphecareModel>(danphecare);
  }
}
