<div class="back-bg">
  <button
    *ngIf="this.token.PatientIdentifier != undefined"
    (click)="BackDashBoard()"
    class="back-btn-doc-room"
  >
    Back To DashBoard
  </button>
  <button
    *ngIf="this.token.PatientIdentifier == undefined"
    [routerLink]="['/HospitalList']"
    class="back-btn-doc-room"
  >
    Back
  </button>
</div>
