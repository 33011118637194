import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {  Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EndpointFactory } from '../endpoint-factory.service';

@Injectable({ providedIn: 'root' })
export class LabReportUploadEndpointService extends EndpointFactory {
  baseUrl: string = 'api/LabReport'


  constructor(private _http: HttpClient, injector: Injector) {
    super(_http, injector);
  }
  GetAllLabReports<T>(): Observable<T> {
    return this._http.get<T>(`${this.baseUrl}`).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetAllLabReports()));
      }));
  }
  GetLabReportPreview<T>(patientFileId: string): Observable<T> {
    return this._http.get<T>(`${this.baseUrl}/GetLabReportPreview?patientFileId=${patientFileId}`).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetLabReportPreview(patientFileId)));
      }));
  }
  IsPhoneNumberRegistered<T>(phoneNumber: string): Observable<T> {
    return this._http.get<T>(`${this.baseUrl}/IsPhoneNumberRegistered?PhoneNumber=${phoneNumber}`).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.IsPhoneNumberRegistered(phoneNumber)));
      }));
  }
  uploadFile<T>(patient: any, formData: FormData,AdminId: string): Observable<T> {
    return this._http.post<T>(`${this.baseUrl}/${patient.firstName}/${patient.lastName}/${patient.phoneNumber}/${patient.email}/${AdminId}`, formData).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.uploadFile(patient, formData,AdminId)));
      }));
  }

}

