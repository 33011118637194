import { Component, Input, OnInit } from '@angular/core';
import { Hospital } from 'src/app/models/hospital/hospital.model';
import { ProductModel } from 'src/app/models/Product/product.model';
import { ProductType } from 'src/app/models/Product/productType.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {
  public ProductDetails: ProductModel = new ProductModel();
  public editMode: boolean = false;
  public previewImgSrc: any;
  public productImageExist: boolean = false;
  @Input('ProductId') public productId: number;

  public hospitalName: any;
  public productTag: string = null;
  public productType; string = null;
  public HospitalDetails: Hospital = new Hospital();
  public ProductTypeDetails: ProductType = new ProductType();
  public ProductTagDetails: any;


  constructor(public productService: ProductService, public notificationService: NotificationService) {
    this.productService.GetAllHospital();
    this.productService.getAllProductTypes();
    this.ProductTagDetails = this.productService.ProductTagsList;
  }

  ngOnInit() {
    if (this.productId != 0) {
      this.Preview();
    }
  }

  public Preview() {
    if (this.productService.hospitalList.length != 0 && this.productService.ProductTypesList.length != 0 && this.productService.ProductTagsList.length != 0) {
      this.productService.getProduct(this.productId).subscribe(
        res => {
          if (res) {
            this.ProductDetails = new ProductModel();
            this.productImageExist = false;
            this.ProductDetails.Name = res.Name;
            this.ProductDetails.NotReturnable = res.NotReturnable;
            this.ProductDetails.OldPrice = res.OldPrice;
            this.ProductDetails.PermaLink = res.PermaLink;
            this.ProductDetails.PictureId = res.PictureId;
            this.ProductDetails.Price = res.Price;
            this.ProductDetails.ProductCost = res.ProductCost;
            this.ProductTagDetails = this.ProductTagDetails.find(a => a.Id = res.ProductTagId);
            this.ProductTypeDetails = res.ProductType;
            this.ProductDetails.ProductType = this.ProductTypeDetails.Type;
            this.ProductDetails.ShortDescription = res.ShortDescription;
            this.ProductDetails.ShowOnHomepage = res.ShowOnHomepage;
            this.ProductDetails.Sku = res.Sku;
            this.ProductDetails.UpdatedBy = res.UpdatedBy;
            this.ProductDetails.VisibleIndividually = res.VisibleIndividually;
            this.ProductDetails.AdminComment = res.AdminComment;
            this.ProductDetails.DisableBuyButton = res.DisableBuyButton;
            this.ProductDetails.DisplayOrder = res.DisplayOrder;
            this.ProductDetails.FullDescription = res.FullDescription;
            this.ProductDetails.HospitalId = res.HospitalId;
            this.HospitalDetails = res.Hospital;
            this.hospitalName = this.HospitalDetails.HospitalName;
            this.ProductDetails.MetaDescription = res.MetaDescription;
            this.ProductDetails.ManufacturerPartNumber = res.ManufacturerPartNumber;
            this.ProductDetails.MetaKeywords = res.MetaKeywords;
            this.ProductDetails.MetaTitle = res.MetaTitle;
            if (this.ProductDetails.PictureId) {
              this.productImageExist = true;
              this.GetProductImage(this.ProductDetails.PictureId);
            }

          }
        }

      );
    }
    else {
      this.notificationService.showError('Somenting went Wrong', 'Error!!');
    }
  }


  public GetProductImage(productImageId) {
    this.productService.getProductImage(productImageId).subscribe(
      res => {
        if (res) {
          this.previewImgSrc = 'data:image/jpeg;base64,' + res;
        }

      },
      err => {
        alert(err);
      }
    );
  }
}


