import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { HospitalService } from './services/hospital/hospital.service';
import { Hospital } from './models/hospital/hospital.model';
import { DoctorService } from './services/doctor/doctor.service';
import { Doctor } from './models/doctor/doctor.model';
import { Global } from './app.global';
import { PatientService } from './services/patient/patient.service';
import { AuthenticationService } from './services/authentication.service';
import { Patient } from './models/patient/patient.model';
import { AlertService } from './services/alert.service';
import { AutoLogoutService } from './services/autologout.service';



@Component({
  templateUrl: "./app.hospitalspecificdoclist.html",
})
export class HospitalSpecificDoctorListComponent {
  public HospitalId: any;
  public doctordId: any;
  hospitalList: any;
  docHospitalList: Array<any>;
  doctorList: Array <Doctor> = new Array<Doctor>();
  qualification: any;
  public searchText: any;
  isDoctor: boolean = false;
  isAdmin: boolean = false;
  doctorId: any;
  showModal: boolean;
  token: any;
  public patInfo: Patient = new Patient();
  public showHospital: boolean = false;
  constructor(public routing: Router,
    private route: ActivatedRoute,
    public global: Global, public patservice: PatientService, public authservice: AuthenticationService,
    public hospitalService: HospitalService, public alertService: AlertService,
    public docService: DoctorService) {
    this.token = this.authservice.currentUserValue;
    this.HospitalId = this.route.snapshot.queryParamMap.get('id');
    this.GetHospitalList();
    this.GetDoctorList();
    this.getPatientName();
  }

  public getPatientName() {
    this.patservice.getPatient(this.token.PatientIdentifier,this.token.UserType).subscribe(res => {
      this.patInfo = res;
    },
      error => {
        this.alertService.error(error);
      });
  }

  GetHospitalList() {
    this.hospitalService.getHospitalList()
      .subscribe(res => this.SuccessGetHospitalList(res),
        res => this.Error(res));
  }
  SuccessGetHospitalList(res) {
    this.hospitalList = res;
  }
  GetDoctorList() {
    this.docService.getHospitalDoctorMapList(this.HospitalId)
      .subscribe(res => this.SuccessDoctor(res),
        res => this.Error(res));
  }
  SuccessDoctor(res) {
    
    let doclist;
    doclist = res;
    doclist.forEach(doc => {
      doc.ConsultationType.forEach(element => {
        if (element == this.patservice._VisitType)
        {
          
          this.doctorList.push(doc);

        }
      });
      
    });
    
  }
  Error(res) {

  }
  GoToHospital(id) {
    this.hospitalService.HospitalId = id;
    //this.routing.navigate(['/PatDashboard'], { queryParams: { id: this.hospitalService.HospitalId } });
    this.routing.navigate(['/PatBookAppointment'], { queryParams: { id: this.hospitalService.HospitalId } });
  }
  GoToDoctor(id1) {
    this.doctorId = id1;
    this.routing.navigate(['/DocProfile'], { queryParams: { id1: this.doctorId } });
  }
}
