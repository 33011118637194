<app></app>
<button *ngIf="this.token.PatientIdentifier != undefined" [routerLink]="['/PatDashboard']" class="back-btn-doc-room">
  Back To Dashboard
</button>
<button *ngIf="this.token.PatientIdentifier == undefined" [routerLink]="['/admin']" class="back-btn-doc-room">
  Back To Admin
</button>
<div class="row">
  <div class="col-md-12">
    <div *ngIf="this.token.PatientIdentifier != undefined" className="bg-gray">
      <p class="text-center mt-4 mb-1" style="font-size: 16px">
        Hello!
        <label class="text-primary-green"><strong>
            <b *ngIf="patInfo.Gender=='Female'"> Ms. </b>
            <b *ngIf="patInfo.Gender=='Male'"> Mr. </b>{{patInfo.FirstName + ' '
            + patInfo.LastName}}
          </strong>
        </label>
        Who are you looking for?
      </p>
      <!--<div class="col-md-12 text-center mb-2">
        <div class="d-inline">
          <input
            style="width: 20px; height: 20px"
            id="doctor"
            type="radio"
            class=""
            [value]="false"
            [(ngModel)]="isDoctor"
            name="search"
            checked
          />
          <label for="doctor" class="radio-custom-label mt-0"
            ><h5 class="font-weight-bold">Doctor</h5></label
          >
        </div>
        &nbsp;&nbsp;&nbsp;
        <div class="d-inline">
          <input
            style="width: 20px; height: 20px"
            id="hospital"
            type="radio"
            class=""
            [value]="true"
            [(ngModel)]="isDoctor"
            name="search"
            checked
          />
          <label for="hospital" class="radio-custom-label"
            ><h5 class="font-weight-bold">Hospital</h5></label
          >
        </div>
      </div>-->
    </div>
    <div *ngIf="this.token.PatientIdentifier == undefined">
      <h4 class="text-center mt-4">
        Hello!
        <label class="text-primary">
          <strong> <b> Mr. </b> Admin </strong>
        </label>
        Who are you looking for?
      </h4>
      <!--<div class="col-md-12 text-center mb-2">
        <div class="d-inline">
          <input
            id="doctor"
            type="radio"
            class=""
            [value]="false"
            [(ngModel)]="isDoctor"
            name="search"
            checked
          />
          <label for="doctor" class="radio-custom-label">Doctor</label>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div class="d-inline">
          <input
            id="hospital"
            type="radio"
            class=""
            [value]="true"
            [(ngModel)]="isDoctor"
            name="search"
            checked
          />
          <label for="hospital" class="radio-custom-label">Hospital</label>
        </div>
      </div>-->
    </div>
    <div *ngIf="isDoctor">
      <div class="row searchbar-card text-center" style="margin-top: 0">
        <div class="col-md-6 col-sm-8 m-auto d-flex">
          <div class="searchbar">
            <input class="search_input" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Hospital.." />
            <a class="search_icon text-white"><i class="fa fa-search"></i>Search</a>
          </div>
        </div>
      </div>
      <div class="row d-flex">
        <div *ngFor=" let list of  hospitalList | filter: searchText" class="col-md-4 col-sm-6">
          <div class="hospital-grid" (click)="GoToHospital(list.HospitalId)">
            <div class="hospital-image">
              <img class="pic-1" src="{{list.ImagePath}}" alt="{{list.HospitalName}}" />
            </div>
            <div class="hospital-content">
              <a class="btn text-primary" style="font-size: 17px">{{list.HospitalName}}</a>
              <a class="btn"><i class="fa fa-map-marker"></i>{{list.Location}}</a>
              <a class="btn"><i class="fa fa-phone"></i>Your Phone Number</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isDoctor">
      <div class="row searchbar-card text-center" style="margin-top: 0">
        <div class="col-md-6 col-sm-8 m-auto d-flex">
          <div class="searchbar">
            <input class="search_input" *ngIf="isOnlineAppointment" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Department..." />
            <input class="search_input" *ngIf="!isOnlineAppointment" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Doctor..." />
            <a class="search_icon text-white"><i class="fa fa-search"></i>Search</a>
          </div>
        </div>
      </div>
      <div *ngIf="isOnlineAppointment">
        <div class="doc-schedule">
          <h5>Select Department.</h5>
          <div class="department_container">
            <div class="row" *ngFor="let department of deptList | filter: searchText">
              <button class="department_btn" (click)="selectDepartment(department.DepartmentId)">{{department.DepartmentName}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex" *ngIf="isDoctorAvailable">
        <div *ngFor=" let list of doctorList| filter: searchText" class="col-md-4 col-sm-6">
          <div class="hospital-grid" (click)="GoToDoctor(list.DoctorId, list.DepartmentId)">
            <div class="hospital-image">
              <img class="pic-1" src="{{list.FilePath}}" (error)="list.FilePath = 'assets/img/docProfile.png'"> 
        <!-- Added a default doctor avatar if his/her avatar is not being rendered -->
            </div>
            <div class="hospital-content">
              <a class="btn text-primary" style="font-size: 17px">{{list.DoctorName}}</a>
              <a class="btn"><i class="fa fa-map-marker"></i>{{list.LongSignature}}</a>
              <a class="btn"><i class="fa fa-id-card"></i>NMC No:{{list.NMC}}</a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isDoctorAvailable">
        <i class="fa-regular fa-face-frown"></i>
        <p style="text-align: center; margin-top: 10rem; margin-bottom: 1rem; font-size: 2rem; color: rgb(51, 47, 47);">
          Sorry! No Department Available For {{this.VisitType}} at the Moment.

        </p>
      </div>
    </div>
    <!-- <div class=" s-container">
          <img class="photo-slider" src="./assets/img/slider1.jpg" alt="" />
          <img class="photo-slider" src="./assets/img/slider2.jpg" alt="" />
          <img class="photo-slider" src="./assets/img/slider1.jpg" alt="" />
          <img class="photo-slider" src="./assets/img/slider2.jpg" alt="" />
      </div> -->
  </div>
</div>