import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Problem } from './models/problem/problem.model';

export class Patient {
  PatientId: number;
  PatientName: string = "";
  Age: string = "";
  Sex: string = "";
  Email: string = "";
  MobileNumber: string = "";
  Address: string = "";
  prob: Array<Problem> = [];
  Fever: boolean = false;
  Cough: boolean = false;
  BreathingDifficulty: boolean = false;
  Tiredness: boolean = false;
  SoreThroat: boolean = false;
  Bodyache: boolean = false;
  ChestPain: boolean = false;
  Diarrhea: boolean = false;
  AnyOtherSymptoms: string = "";

  HeartDisease: boolean = false;
  HighBloodPressure: boolean = false;
  Diabetes: boolean = false;
  Copd: boolean = false;
  Transplant: boolean = false;
  RecentTravel: boolean = false;
  Cancer: boolean = false;
  Exposure: boolean = false;
  PatientMedicalSymptoms: string = "";
  OtherPertientInformation: string = "";


  Status: number = 0;
  Medication: string = "";
  LastUpdatedTime: Date;
}
export class Doctor {
  DoctorName: string = "";
  Password: string = "";
}
export class Prescription {
  FileType: string = "";
  FileExtention: string = "";
  FileTypeprescriptions: string = "";
  FilePath: any;
}
