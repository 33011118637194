import { ChangeDetectorRef, Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { PatientService } from '../../services/patient/patient.service';
import * as moment from 'moment/moment';
import { AlertService } from '../../services/alert.service';
import { HospitalService } from '../../services/hospital/hospital.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  templateUrl: './app.mybooklist.html',
  styleUrls: ['./app.mybookinglist.css']

})
export class MyBooKListComponent {
  public token: any;
  config: any;
  public patBookList: any;
  public patbook: boolean;
  public printDetails: any;
  public hospitalId: any;
  public showPrint: boolean = false;
  public showActions: boolean = true;
  public reportHeader:any;
  public reportFooter:any;
  collection = { count: 60, data: [] };
  p: number = 1;
  todayDate = moment();

  public isBookingAvailable: boolean = false;

  constructor(public routing: Router, private route: ActivatedRoute,
    public authService: AuthenticationService,
    public patService: PatientService, public alertService: AlertService,
    public hospitalService: HospitalService,
    public changeDetector: ChangeDetectorRef,
    public notifyService: NotificationService,

  ) {
    this.token = this.authService.currentUserValue;
    this.ShowBookList();
    this.hospitalId = this.route.snapshot.queryParamMap.get('id');
  }
  public ShowBookList() {
    this.patbook = true;
    this.patService.getPatientBookingList(this.token.PatientIdentifier).subscribe(res => {
      if (res && res.IsSuccess && res.Results) {
        this.patBookList = res.Results;

        for (var i = 0; i < this.patBookList.length; i++) {
          // this.patBookList[i].VisitDate = moment(this.patBookList[i].VisitDate).format('ll');
          // this.patBookList[i].VisitStartTime = moment(this.patBookList[i].VisitStartTime).format('LT');
          var currentDateTime = this.todayDate.tz("Asia/Kathmandu").format('YYYY/MM/DD HH:mm z');

          this.patBookList[i].VisitDate = moment(this.patBookList[i].VisitDate, 'YYYY/MM/DD hh:mm').format('YYYY/MM/DD');
        }
        this.collection = { count: this.patBookList.length, data: [] }

        for (var i = 0; i < this.collection.count; i++) {
          this.collection.data.push(
            {
              id: i + 1,
              VisitDate: this.patBookList[i].VisitDate,
              VisitTime: this.patBookList[i].BookingTime,
              PatientName: this.patBookList[i].PatientName,
              PatientFamilyName: this.patBookList[i].PatientFamilyName,
              HospitalName: this.patBookList[i].HospitalName,
              DoctorName: this.patBookList[i].DoctorName,
              DepartmentName: this.patBookList[i].DepartmentName,
              VisitType: this.patBookList[i].VisitType,
              PaymentStatus: this.patBookList[i].PaymentStatus,
              VisitId: this.patBookList[i].VisitId,
              Showpay: this.patBookList[i].VisitDate + ' ' + this.patBookList[i].DocEndTime >= currentDateTime && this.patBookList[i].PaymentStatus != 'paid' && this.patBookList[i].PaymentStatus != 'free' && this.patBookList[i].PaymentStatus == 'unpaid' ? true : false,
              Showpaid: this.patBookList[i].VisitDate + ' ' + this.patBookList[i].DocEndTime <= currentDateTime && this.patBookList[i].PaymentStatus != 'paid' && this.patBookList[i].PaymentStatus != 'free' && this.patBookList[i].PaymentStatus == 'unpaid' ? true : false,
            }
          );
        }
        if (this.collection.data.length > 0) {
          this.isBookingAvailable = true;
        }
        else {
          this.isBookingAvailable = false;
        }
        for (var i = 0; i < this.patBookList.length; i++) {
          this.patBookList[i].VisitDate = moment(this.patBookList[i].VisitDate).format('ll');
          this.patBookList[i].VisitStartTime = moment(this.patBookList[i].VisitStartTime).format('LT');
        }
      }
      else{
        this.notifyService.showError("", "Error Retriving Data")
      }
    },
      error => {
        this.alertService.error(error);
      });

  }

  PrintTxnTable(datarow) {
    try {
      this.showPrint = false;
      this.printDetails = null;
      this.showActions = false;
      this.changeDetector.detectChanges();
      this.showPrint = true;
      this.printDetails = `Your appointment at Lumbini Province Hospital is Confirmed for ${datarow.VisitDate} with Dr. ${datarow.DoctorName} in the ${datarow?.DepartmentName} department.`;
      this.print();
    } catch (ex) {
      this.ShowCatchErrMessage(ex);
    }
  }
  public ShowCatchErrMessage(exception) {
    if (exception) {
      let ex: Error = exception;
      this.notifyService.showError("error", ["Check error in Console log !"]);
      console.log("Error Messsage =>  " + ex.message);
      console.log("Stack Details =>   " + ex.stack);
    }
  }
 print() {
    var contents = this.printDetails;
    var iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    this.reportHeader = "My Booking List";
    this.reportFooter = "Kindly attend the hospital on the schedule date."

    let documentContent = "<html><head>";
    // documentContent += '<link rel="stylesheet" type="text/css" media="print" href="../../../css/PrintStyle.css"/>';
    documentContent += '<link rel="stylesheet" type="text/css" href="../../../../lib/bootstrap/dist/css/bootstrap.min.css"/>';
    // documentContent += '<h5 style="text-align: center;"><b>' + this.reportHeader + '</b></h5></br></head>';
    documentContent += '</br></br></head>';
    documentContent += '<body onload="window.print()">'
    documentContent += '<div style="text-align: center;">';
    documentContent += '<img src="./assets/img/lphlogo.png" alt="logo" id="logo" style="max-width: 45%; max-height: 45%;"/>';
    documentContent += '</div> </br></br></br>';
    documentContent += '<div>';
    documentContent += '<p style="font-size: 22px;">' +  contents + '</p>';
    documentContent += '<p style="font-size: 22px;">' +  this.reportFooter + '</p>';
    documentContent += '</div>';
    documentContent +=  '</body></html>'
    var htmlToPrint = '' + '<style type="text/css">' + '.table_data {' + 'border-spacing:0px' + '}' + '</style>';
    htmlToPrint += documentContent;
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(htmlToPrint);
    iframe.contentWindow.document.close();

    setTimeout(function () {
      document.body.removeChild(iframe);
    }, 500);

    //this.notifyService.showSuccess('Success','Printed Successfully!!');
    this.showActions = true;
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  public paymentselection(data) {
    this.routing.navigate(['/PaymentSelection'], { queryParams: { vid: data.VisitId, charge: data.Charge, id: this.hospitalId } });
  }

  public BookNewVisit() {
    this.routing.navigate(['/PatBookAppointment'], { queryParams: { id: this.hospitalId } });
  }

  public MyBookList() {
    this.routing.navigate(['/PatBookList'], { queryParams: { id: this.hospitalId } });

  }

  public MyPaidBooking() {
    this.routing.navigate(['/PatPaidBookingList'], { queryParams: { id: this.hospitalId } });
  }

  public Payment() {
    this.routing.navigate(['/PatDashboard/PaymentSelection']);
  }
}
