import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { User } from '../models/user/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from './notification.service';
import { Global } from '../app.global';
import { JwtHelper } from '../models/JwtHelper';
import { IdToken } from '../models/IdToken';



@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  userType: string;
  jwtToken: any;
  loginVal: any;
  result: boolean;
  visitId: any;
  constructor(private http: HttpClient, private route: ActivatedRoute,
    private router: Router, private notifyService: NotificationService, private global: Global) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  get isUserAuthenticated(): boolean {
    return this.currentUserValue != null;
  }
  public get loginValue() {
    const token = sessionStorage.getItem('jwtToken');
    const loginP = localStorage.getItem('loginPat');
    const loginD = localStorage.getItem('loginDoc');
    let jwtHelper = new JwtHelper();
    let decodedIdToken = <IdToken>jwtHelper.decodeToken(token);
    this.userType = decodedIdToken.UserType
    if (loginP !== null || loginD !== null) {
      if (this.userType === "Patient") {
        this.loginVal = loginP;
      }
      else if (this.userType === "Doctor") {
        this.loginVal = loginD;
      }
    }
    return this.loginVal
  }
  public get visitid() {
    const vid = localStorage.getItem('vid');
    this.visitId = vid;
    return this.visitId
  }
  public get currentUserValue(): IdToken {
    const token = sessionStorage.getItem('jwtToken');
    if (token != null || token != undefined) {
      let jwtHelper = new JwtHelper();
      let decodedIdToken = <IdToken>jwtHelper.decodeToken(token);

      this.setUserType(decodedIdToken.UserType);

      return this.jwtToken = decodedIdToken;
    }
    else {
      this.router.navigate(['/login']);

    }
  }

  setUserType(userType: string) {
    this.userType = userType;
  }

  login(phonenumber: string, password: string, isPatient: boolean) {
    return this.http.post<any>(`/api/account/login`, { phonenumber, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          sessionStorage.removeItem('jwtToken');
          //localStorage.setItem('currentUser', JSON.stringify(user));
          sessionStorage.setItem('jwtToken', user.token);
          const token = user.token;
          const decoded = jwt_decode(token);
          this.jwtToken = decoded;
          this.setUserType(decoded.UserType);
          if (this.userType == "Patient") {
            localStorage.setItem('loginPat', "patient");
            this.router.navigate(['/HospitalList']);
            //this.currentUserSubject.next(user);
          }
          else if (this.userType == "Doctor") {
            localStorage.setItem('loginDoc', "doctor");
            this.router.navigate(['/DocDashboard']);
            //this.currentUserSubject.next(user);
          }

          else if (this.userType == "Admin") {
            this.router.navigate(['/admin']);
            this.notifyService.showInfo("Info", "Admin Page")
          }
          //else {
          //  decoded.UserType = "admin"
          //  this.jwtToken = decoded;
          //  this.router.navigate(['/register']);
          //  this.notifyService.showInfo("Info", "Registration for new Doctor")
          //}

        }
        return;
      }));
  }

  logout() {
    // remove user from session storage to log user out

    const token = sessionStorage.getItem('jwtToken');
    const loginP = localStorage.getItem('loginPat');
    const loginD = localStorage.getItem('loginDoc');
    const decoded = jwt_decode(token);
    this.userType = decoded.UserType;
    sessionStorage.removeItem('jwtToken');

    //const vid = localStorage.getItem('vid');
    //const decoded = jwt_decode(token);
    //this.userType = decoded.UserType;
    //if (this.userType === "Patient" || this.userType === "Doctor") {
    //  this.patService.removeVisitIdFromLogout(vid).subscribe(
    //    (res) =>

    //      this.notifyService.showInfo("INFO", "Patient unable to connect due to network problem"),
    //    (res) => this.Error(res)
    //  );
    //}
    if (loginP !== null || loginD !== null) {
      if (this.userType === "Patient") {
        localStorage.removeItem('loginPat');
        localStorage.setItem('loginPat', "logoutPat");

        localStorage.removeItem('vid');
      }
      else if (this.userType === "Doctor") {
        localStorage.removeItem('loginDoc');
        localStorage.removeItem('vid');

      }
    }

    this.currentUserSubject.next(null);
  }

  visitIdToken(vid) {
    localStorage.removeItem('vid');
    localStorage.setItem('vid', `${vid}`);
  }

  public get IsTokenValid(): Observable<any> {

    return this.http.get<any>('/api/account/ValidUserCheck');
    //  .subscribe(res => {
    //    debugger;
    //    this.result = true;
    //    console.log(res)
    //  },
    //    (err: HttpErrorResponse) => {
    //      if (err.status == 401) {
    //        debugger;
    //        this.result = false;
    //      }
    //    },
    //    () => console.log("Request Complete"));
    //return this.result;
  }
  //  validateSession() {
  //  let seassionVal = sessionStorage.getItem('jwtToken');
  //  if (seassionVal !== null) {
  //    let sessionObj = JSON.parse(seassionVal);
  //    let expiredAt = new Date(value.expiredAt);
  //    if (expiredAt > new Date()) { // Validate expiry date.
  //      return sessionObj.value;
  //    } else {
  //      sessionStorage.removeItem(key);
  //    }
  //  }
  //  return null;
  //}

  resetPassword(userId: string, password: string, oldPassword? : string) {
    return this.http.post<any>(`/api/account/resetPassword`, { userId, password, oldPassword })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        this.notifyService.showInfo("Info", "Reset Password successfully")
        return;
      }));
  }
}
