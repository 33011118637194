export class Medication {

  public MedicationId: string;
  public PatientId: string;
  public VisitId: string;
  public DrugName: string;
  public Dose: string;
  public Frequency: string;
  public Remarks: string;

}