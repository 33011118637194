import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { HospitalService } from './services/hospital/hospital.service';
import { Hospital } from './models/hospital/hospital.model';
import { DoctorService } from './services/doctor/doctor.service';
import { Doctor } from './models/doctor/doctor.model';
import { Global } from './app.global';
import { PatientService } from './services/patient/patient.service';
import { AuthenticationService } from './services/authentication.service';
import { Patient } from './models/patient/patient.model';
import { AlertService } from './services/alert.service';
import { AutoLogoutService } from './services/autologout.service';
import { Departement } from './models/Department/app.department.model';



@Component({
  templateUrl: "./app.hospital.html",
  styleUrls: ["app.hospitalcomponent.css"],

})
export class HospitalComponent {
  public HospitalId: any;
  public doctordId: any;
  hospitalList: any;
  docHospitalList: Array<any>;
  doctorList: Array<Doctor> = new Array<Doctor>();
  qualification: any;
  public searchText: any;
  isDoctor: boolean = false;
  isAdmin: boolean = false;
  doctorId: any;
  patientId: any;
  showModal: boolean;
  token: any;
  VisitType: any;
  public patInfo: Patient = new Patient();
  public showHospital: boolean = false;
  public isDoctorAvailable: boolean = true;
  public isOnlineAppointment: boolean = false;
  public deptList: Array<Departement> = new Array<Departement>();
  public deptId : string;

  constructor(public routing: Router,
    private route: ActivatedRoute,
    public global: Global, public patservice: PatientService, public authservice: AuthenticationService,
    public hospitalService: HospitalService, public alertService: AlertService,
    public docService: DoctorService) {
    this.token = this.authservice.currentUserValue;
    this.GetDepartmentList();
    this.GetHospitalList();
    this.getPatientName();
    this.VisitType = this.route.snapshot.queryParamMap.get('VisitType');
    this.checkVisitType();
  }

  public getPatientName() {
    if (this.route.snapshot.queryParamMap.get('routefrom') == "admin")
      this.patientId = this.patservice.PatientId;
    else
      this.patientId = this.token.PatientIdentifier;

    this.patservice.getPatient(this.patientId, this.token.UserType).subscribe(res => {
      this.patInfo = res;
    },
      error => {
        this.alertService.error(error);
      });
  }
  checkVisitType(){
    if(this.VisitType === "Telemedicine"){
      this.GetDoctorList();
      this.isOnlineAppointment = false
    }
  }
  
  GetDepartmentList() {
    this.docService.getDepartmentList().subscribe((res: any) => {
      if (res && res.IsSuccess && res.Results) {
        this.deptList = res?.Results;
        if(this.VisitType === "Online Appointment"){
          this.isOnlineAppointment = true;
        }
      }
    },
      error => {
        this.alertService.error(error);
      });
  }
  selectDepartment(DepartementId) {
    this.docService.getDoctorByDepartment(DepartementId).subscribe(res => {
      if (res.success && res.data) {
        this.getDoctorByDepartment(res.data),
          res => this.Error(res)
      }
    }
    );
  }
  getDoctorByDepartment(res){
    let doclist;
    doclist = res;    
    this.doctorList = [];
    doclist.forEach(doc => {
      for (var i = 0; i < doc.AppointmentType.length; i++) {
        if (doc.AppointmentType === this.VisitType || doc.AppointmentType[i] === this.patservice._VisitType) {
          this.isDoctorAvailable = true;
          this.doctorList.push(doc);
          break;
        }
        else{
          this.alertService.error("No Doctor Found.")
        }
      }
    });
    if (this.doctorList.length < 1) {
      this.isDoctorAvailable = false;
    }
  }

  GetHospitalList() {
    this.hospitalService.getHospitalList()
      .subscribe(res => this.SuccessGetHospitalList(res),
        res => this.Error(res));
  }
  SuccessGetHospitalList(res) {
    this.hospitalList = res;

  }
  GetDoctorList() {
    this.docService.getAvailableDoctorList()
      .subscribe(res => this.SuccessDoctor(res),
        res => this.Error(res));
  }
  SuccessDoctor(res) {
    let doclist;
    doclist = res?.Results;
    doclist.forEach(doc => {
      for (var i = 0; i < doc.ConsultationType.length; i++) {
        if (doc.ConsultationType[i] == this.VisitType || doc.ConsultationType[i] == this.patservice._VisitType) {
          this.isDoctorAvailable = true;
          this.doctorList.push(doc);
          break;
        }
      }
      //  doc.ConsultationType.forEach(element => {
      //    if (element == this.patservice._VisitType)
      //    {
      //     this.doctorList.push(doc);
      //    }
      // });
    });
    if (this.doctorList.length < 1) {
      this.isDoctorAvailable = false;
    }
  }
  Error(res) {

  }
  GoToHospital(id) {
    this.hospitalService.HospitalId = id;
    //this.routing.navigate(['/PatDashboard'], { queryParams: { id: this.hospitalService.HospitalId } });
    this.routing.navigate(['/HospitalSpecificDoctorList'], { queryParams: { id: this.hospitalService.HospitalId, VisitType: this.VisitType } });
    // this.routing.navigate(['/PatBookAppointment'], { queryParams: { id: this.hospitalService.HospitalId} });
  }
  GoToDoctor(id1, deptId) {
    this.doctorId = id1;
    this.deptId = deptId;
    if(this.isOnlineAppointment){
      this.routing.navigate(['/DocProfile'], { queryParams: { id1: this.doctorId, deptId: this.deptId, VisitType: this.VisitType } });
    }else{
      this.routing.navigate(['/DocProfile'], { queryParams: { id1: this.doctorId, VisitType: this.VisitType } });
    }
  }
}
